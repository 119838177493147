import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, number, string } from "prop-types";
import React from "react";
import FormattedAmount from "../FormattedAmount";

const InfoCardBig = ({
    title,
    currency,
    quantity,
    tooltipText,
    tooltipPosition,
    isDesktop,
    highlighted,
    noCurrency,
    withBorder,
    className,
}) => (
    <Box
        component="article"
        display="flex"
        alignX="between"
        alignY="center"
        fullHeight
        fullWidth
        wrap
        background={highlighted ? "heading-color" : "white"}
        borderRadius="default"
        {...(!highlighted && isDesktop && { border: "inset-background-divider" })}
        {...(withBorder && { border: "inset-background-divider" })}
        className={className}>
        <Box display="flex" alignY="center">
            <Text labelKey={title} color={highlighted ? "inverse" : "heading"} size={highlighted ? "5" : "6"} />
            {tooltipText ? (
                <Tooltip
                    text={tooltipText}
                    {...(highlighted && { inverseIconColor: true })}
                    position={tooltipPosition}
                />
            ) : null}
        </Box>
        {currency ? (
            <FormattedAmount
                size={highlighted ? "3" : "5"}
                bold
                color={highlighted ? "inverse" : "heading"}
                className="data-amount content-data-strong"
                currency={currency}
                quantity={quantity}
                noCurrency={noCurrency}
            />
        ) : (
            <Text size={highlighted ? "3" : "5"} bold color={highlighted ? "inverse" : "heading"}>
                {quantity}
            </Text>
        )}
    </Box>
);

InfoCardBig.propTypes = {
    title: string,
    currency: string,
    quantity: number,
    tooltipText: string,
    tooltipPosition: string,
    isDesktop: bool,
    highlighted: bool,
    noCurrency: bool,
    withBorder: bool,
    className: string,
};

InfoCardBig.defaultProps = {
    title: null,
    currency: null,
    quantity: null,
    tooltipText: null,
    tooltipPosition: "bottom-centered",
    isDesktop: false,
    highlighted: false,
    noCurrency: null,
    withBorder: false,
    className: "py-7 px-5 mb-0",
};

export default resizableRoute(InfoCardBig);

import React, { Component, Fragment } from "react";
import { Switch, Route as RouteDom, Redirect } from "react-router-dom";

import AdministrationAdvancedChannels from "pages/administration/advanced/Channels";
import AdministrationAdvancedChannelsConfirm from "pages/administration/advanced/confirmations/ChannelsConfirm";
import AdministrationAdvancedChannelsTicket from "pages/administration/advanced/tickets/ChannelsTicket";
import AdministrationAdvancedGroup from "pages/administration/advanced/Group";
import AdministrationAdvancedGroupFormContainer from "pages/administration/advanced/GroupFormContainer";
import AdministrationAdvancedGroupsOfUser from "pages/administration/advanced/GroupsOfUser";
import AdministrationAdvancedGroupsOfUserConfirm from "pages/administration/advanced/confirmations/GroupsOfUserConfirm";
import AdministrationAdvancedGroupsOfUserTicket from "pages/administration/advanced/tickets/GroupsOfUserTicket";
import AdministrationAdvancedGroupFormDataTicket from "pages/administration/advanced/tickets/GroupFormDataTicket";
import AdministrationAdvancedSignatureConfirm from "pages/administration/advanced/confirmations/SignatureConfirm";
import AdministrationAdvancedSignatureTicket from "pages/administration/advanced/tickets/SignatureTicket";
import AdministrationGroups from "pages/administration/advanced/Groups";
import AdministrationMediumChannels from "pages/administration/medium/Channels";
import AdministrationMediumChannelsConfirm from "pages/administration/medium/confirmations/ChannelsConfirm";
import AdministrationMediumChannelsTicket from "pages/administration/medium/tickets/ChannelsTicket";
import AdministrationMediumDetails from "pages/administration/medium/Details";
import AdministrationAdvancedDetails from "pages/administration/advanced/Details";
import AdministrationMediumPermissions from "pages/administration/medium/Permissions";
import AdministrationMediumPermissionsConfirm from "pages/administration/medium/confirmations/PermissionsConfirm";
import AdministrationMediumPermissionsTicket from "pages/administration/medium/tickets/PermissionsTicket";
import AdministrationMediumSignatureConfirm from "pages/administration/medium/confirmations/SignatureConfirm";
import AdministrationMediumPermissionConfirm from "pages/administration/medium/confirmations/PermissionConfirm";
import AdministrationSimplePermissions from "pages/administration/simple/Permissions";
import AdministrationSimplePermissionsConfirm from "pages/administration/simple/PermissionsConfirm";
import AdministrationSimplePermissionsTicket from "pages/administration/simple/PermissionsTicket";
import AdministrationDispatcherConfirm from "pages/administration/_components/DispatcherConfirm";
import AdministrationPermissionsConfigDetails from "pages/administration/_components/AdministrationPermissionsConfigDetails";
import AdministrationUsers from "pages/administration/Users";
import GroupActionConfirmation from "pages/administration/advanced/GroupActionConfirmation";
import CreateGroupSignTransaction from "pages/administration/advanced/CreateGroupSignTransaction";
import GroupActionTicket from "pages/administration/advanced/GroupActionTicket";
import UserActionTicket from "pages/administration/UserActionTicket";
import UserActionConfirmation from "pages/administration/UserActionConfirmation";
import UserInviteTicket from "pages/administration/UserInviteTicket";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Restrictions from "pages/administration/restrictions/Restrictions";
import RestrictionsDeleteConfirmation from "pages/administration/restrictions/confirmations/RestrictionsDeleteConfirmation";
import RestrictionsManageConfirmation from "pages/administration/restrictions/confirmations/RestrictionsManageConfirmation";
import RestrictionsManageTicket from "pages/administration/restrictions/tickets/RestrictionsManageTicket";
import RestrictionsUserDeleteTicket from "pages/administration/restrictions/tickets/RestrictionsUserDeleteTicket";
import TokenConfirm from "pages/administration/token/TokenConfirm";
import { bool, node } from "prop-types";
import RevelockPositionProvider from "providers/RevelockPositionProvider";

const Route = (props) => {
    const { component: ReceivedComponent, ...rest } = props;
    return (
        <RevelockPositionProvider {...props}>
            <RouteDom
                {...rest}
                render={(matchProps) =>
                    (props?.children && props.children(matchProps)) || <ReceivedComponent {...matchProps} />
                }
            />
        </RevelockPositionProvider>
    );
};

Route.propTypes = {
    component: node,
    children: node,
};

Route.defaultProps = {
    component: null,
    children: null,
};

class Administration extends Component {
    static propTypes = {
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
    };

    renderTicketRoutes = () => {
        const ticketRoutes = [
            <Route
                exact
                path="/administration/simple/permissions/:idTransaction/ticket"
                key="simple-permissions"
                component={AdministrationSimplePermissionsTicket}
            />,
            <Route
                exact
                path="/administration/medium/permissions/:idTransaction/ticket"
                key="medium-permissions"
                component={AdministrationMediumPermissionsTicket}
                defaultValidationPosition={false}
            />,
            <Route
                exact
                path="/administration/medium/channels/:idTransaction/ticket"
                key="medium-channels"
                component={AdministrationMediumChannelsTicket}
                defaultValidationPosition={false}
            />,
            <Route
                exact
                path="/administration/users/actions/:idTransaction/ticket"
                key="users-actions"
                component={UserActionTicket}
                defaultValidationPosition={false}
            />,
            <Route
                exact
                path="/administration/groups/actions/:idTransaction/ticket"
                key="groups-actions"
                component={GroupActionTicket}
            />,
            <Route
                exact
                path="/administration/medium/userInvite/:idTransaction/ticket"
                key="medium-userInvite"
                component={UserInviteTicket}
                defaultValidationPosition={false}
            />,
            <Route
                exact
                path="/administration/advanced/signature/:idTransaction/ticket"
                key="advanced-signature"
                component={AdministrationAdvancedSignatureTicket}
            />,
            <Route
                exact
                path="/administration/advanced/channels/:idTransaction/ticket"
                key="advanced-channels"
                component={AdministrationAdvancedChannelsTicket}
            />,
            <Route
                exact
                path="/administration/advanced/groupsOfUser/:idTransaction/ticket"
                key="advanced-groupsOfUser"
                component={AdministrationAdvancedGroupsOfUserTicket}
            />,
            <Route
                exact
                path="/administration/advanced/groupFormData/:idTransaction/ticket"
                key="advanced-groupFormData"
                component={AdministrationAdvancedGroupFormDataTicket}
            />,
        ];
        return ticketRoutes;
    };

    render() {
        const { isDesktop } = this.props;
        const ticketRoutes = this.renderTicketRoutes();

        if (!isDesktop) {
            return (
                <Fragment>
                    <Notification scopeToShow="administration" />
                    <Switch>
                        {ticketRoutes.map((element) => element)}
                        <Redirect to="/desktop" />
                    </Switch>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Notification scopeToShow="administration" />

                <Switch>
                    {ticketRoutes.map((element) => element)}
                    <Route path="/administration/simple/permissions/:id" defaultValidationPosition={false}>
                        {(props) => (
                            <AdministrationSimplePermissions
                                {...props}
                                confirmRoute={
                                    <Route
                                        exact
                                        path="/administration/simple/permissions/:id/confirm"
                                        component={AdministrationSimplePermissionsConfirm}
                                    />
                                }
                            />
                        )}
                    </Route>
                    <Route exact path="/administration/users" component={AdministrationUsers} />
                    <Route exact path="/administration/confirmUserAction" component={UserActionConfirmation} />
                    {/* end of simple scheme routes */}
                    {/* medium scheme routes */}
                    <Route
                        exact
                        path="/administration/medium/details/:id"
                        component={AdministrationMediumDetails}
                        defaultValidationPosition={false}
                    />
                    <Route path="/administration/medium/permissions/:id" defaultValidationPosition={false}>
                        {(props) => (
                            <AdministrationMediumPermissions
                                {...props}
                                confirmRoute={
                                    <Route
                                        exact
                                        path="/administration/medium/permissions/:id/confirm"
                                        component={AdministrationMediumPermissionsConfirm}
                                        defaultValidationPosition={false}
                                    />
                                }
                            />
                        )}
                    </Route>
                    <Route
                        exact
                        path="/administration/medium/details/:id/permissions/:sectionId/configure"
                        key="medium-permissions"
                        component={AdministrationPermissionsConfigDetails}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/medium/details/:id/permissions/:sectionId/onlyRead"
                        key="medium-permissions"
                        component={AdministrationPermissionsConfigDetails}
                        defaultValidationPosition={false}
                    />
                    {/* <Route
                        exact
                        path="/administration/medium/details/:id/permissions/paymentsAndReloads/configure"
                        key="medium-permissions"
                        component={AdministrationPermissionsConfigDetails}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/medium/details/:id/permissions/paymentsAndReloads/onlyRead"
                        key="medium-permissions"
                        component={AdministrationPermissionsConfigDetails}
                        defaultValidationPosition={false}
                    /> */}
                    <Route
                        exact
                        path="/administration/medium/channels/:id"
                        component={AdministrationMediumChannels}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/medium/channels/:id/confirm"
                        component={AdministrationMediumChannelsConfirm}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/medium/signature/:id/confirm"
                        component={AdministrationMediumSignatureConfirm}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/medium/approve/:id/confirm"
                        component={AdministrationMediumPermissionConfirm}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/medium/create/:id/confirm"
                        component={AdministrationMediumPermissionConfirm}
                        defaultValidationPosition={false}
                    />
                    {/* end of medium scheme routes */}
                    {/* advanced scheme routes */}
                    <Route
                        exact
                        path="/administration/advanced/group/:id/details"
                        component={AdministrationAdvancedGroup}
                    />
                    {/* end of advanced scheme routes */}
                    <Route
                        exact
                        path="/administration/users/dispatcher/:id/confirm"
                        component={AdministrationDispatcherConfirm}
                    />
                    <Route
                        exact
                        path="/administration/users/actions/:idTransaction/ticket"
                        component={UserActionTicket}
                    />
                    <Route
                        exact
                        path="/administration/groups/actions/:idTransaction/ticket"
                        component={GroupActionTicket}
                    />
                    <Route
                        exact
                        path="/administration/medium/userInvite/:idTransaction/ticket"
                        component={UserInviteTicket}
                    />
                    <Route exact path="/administration/groups" component={AdministrationGroups} />
                    <Route exact path="/administration/confirmGroupAction" component={GroupActionConfirmation} />
                    <Route
                        path="/administration/advanced/group/create"
                        component={AdministrationAdvancedGroupFormContainer}
                    />
                    <Route
                        exact
                        path="/administration/advanced/group/sign/create"
                        component={CreateGroupSignTransaction}
                    />
                    <Route
                        path="/administration/advanced/group/:id"
                        component={AdministrationAdvancedGroupFormContainer}
                    />
                    {/* end of advanced scheme routes */}
                    {/* advanced scheme routes */}
                    <Route
                        exact
                        path="/administration/advanced/details/:id"
                        component={AdministrationAdvancedDetails}
                    />
                    <Route
                        exact
                        path="/administration/advanced/signature/:id/confirm"
                        component={AdministrationAdvancedSignatureConfirm}
                    />
                    <Route
                        exact
                        path="/administration/advanced/signature/:idTransaction/ticket"
                        component={AdministrationAdvancedSignatureTicket}
                    />
                    <Route
                        exact
                        path="/administration/advanced/channels/:id"
                        component={AdministrationAdvancedChannels}
                        defaultValidationPosition={false}
                        issingleroute
                    />
                    <Route
                        exact
                        path="/administration/advanced/channels/:id/confirm"
                        component={AdministrationAdvancedChannelsConfirm}
                    />
                    <Route
                        exact
                        path="/administration/advanced/channels/:idTransaction/ticket"
                        component={AdministrationAdvancedChannelsTicket}
                    />
                    <Route
                        exact
                        path="/administration/advanced/groupsOfUser/:id"
                        component={AdministrationAdvancedGroupsOfUser}
                    />
                    <Route
                        exact
                        path="/administration/advanced/groupsOfUser/:id/confirm"
                        component={AdministrationAdvancedGroupsOfUserConfirm}
                    />
                    <Route
                        exact
                        path="/administration/advanced/groupsOfUser/:idTransaction/ticket"
                        component={AdministrationAdvancedGroupsOfUserTicket}
                    />
                    <Route
                        exact
                        path="/administration/advanced/groupFormData/:idTransaction/ticket"
                        component={AdministrationAdvancedGroupFormDataTicket}
                    />
                    <Route exact path="/administration/restrictions" component={Restrictions} />
                    <Route
                        exact
                        path="/administration/restrictions/:userId"
                        component={Restrictions}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/restrictions/delete/:userId"
                        component={RestrictionsDeleteConfirmation}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/restrictions/manage/confirmation"
                        component={RestrictionsManageConfirmation}
                    />
                    <Route
                        exact
                        path="/administration/restrictions/manage/:idTransaction/ticket/"
                        component={RestrictionsManageTicket}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/restrictions/user/delete/:idTransaction/ticket/"
                        component={RestrictionsUserDeleteTicket}
                        defaultValidationPosition={false}
                    />
                    <Route
                        exact
                        path="/administration/token/:userId/confirm"
                        component={TokenConfirm}
                        defaultValidationPosition={false}
                    />
                    <Redirect to="/desktop" />
                </Switch>
            </Fragment>
        );
    }
}

export default resizableRoute(Administration);

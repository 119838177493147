import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";
import { func, shape, string } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as checksActions } from "reducers/checks";
import * as i18n from "util/i18n";

const StopChecksEditForm = ({
    onChangeStatus,
    checksToSuspend,
    productId,
    type,
    quantityChecks,
    dispatch,
    checkTypeValue,
}) => {
    const [reason, setReason] = useState();
    const optionSelect = [
        {
            value: "1",
            label: i18n.get("checks.reason.suspend.1"),
        },
        {
            value: "3",
            label: i18n.get("checks.reason.suspend.3"),
        },
    ];

    const handlerOnCLickSuspend = () => {
        dispatch(
            checksActions.setdataCheckToSuspend({
                idAccount: productId,
                reasonSuspend: reason,
                checksSuspend: checksToSuspend,
                type,
                quantityChecks,
                checkTypeValue,
            }),
        );
        onChangeStatus();
        if (type === "checks") {
            dispatch(push("/formCustom/stopChecks"));
        }
        if (type === "checkbooks") {
            dispatch(push("/formCustom/stopCheckbooks"));
        }
    };

    return (
        <Box display="flex" column fullWidth fullHeight>
            <Box className="form-group form-group--select">
                <FieldLabel labelKey="forms.checks.suspend.reason.label" labelNoMarginTop />
                <Box className="input-group ">
                    <Box flex1>
                        <Select
                            clearable={false}
                            searchable={false}
                            onChange={setReason}
                            options={optionSelect}
                            value={reason}
                            placeholder={i18n.get("forms.placeholder.select")}
                        />
                    </Box>
                </Box>
            </Box>
            <Box display="flex" className="mt-9 pb-9" column>
                <Button
                    bsStyle="primary"
                    onClick={handlerOnCLickSuspend}
                    disabled={!reason}
                    label="global.suspend"
                    block
                />
                <Button bsStyle="outline" onClick={onChangeStatus} label="global.cancel" block />
            </Box>
        </Box>
    );
};

StopChecksEditForm.propTypes = {
    checksToSuspend: shape({}).isRequired,
    productId: string.isRequired,
    onChangeStatus: func.isRequired,
    type: string.isRequired,
    dispatch: func.isRequired,
    quantityChecks: string.isRequired,
    checkTypeValue: string.isRequired,
};

export default connect()(StopChecksEditForm);

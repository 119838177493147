import { Field, Form, withFormik } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DateField from "pages/_components/fields/DateField";
import Row from "pages/_components/Row";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { actions as creditCardsActions } from "reducers/creditCard";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { resizableRoute } from "pages/_components/Resizable";

const FORM_ID = "creditCard.movements.filters.period";

class CreditCardPeriodFilter extends Component {
    static propTypes = {
        isSubmitting: bool,
        isDesktop: bool,
        values: shape([]),
        resetFilters: bool,
        handleResetDateFilters: func,
        handleSubmit: func,
    };

    static defaultProps = {
        isSubmitting: false,
        isDesktop: false,
        values: [],
        resetFilters: false,
        handleResetDateFilters: null,
        handleSubmit: null,
    };

    state = {
        selectedDateFrom: null,
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    render() {
        const { isSubmitting, values, resetFilters, handleResetDateFilters, handleSubmit, isDesktop } = this.props;
        const { selectedDateFrom } = this.state;

        return (
            <Form className="full-width">
                <Box className="mb-12" display="flex" alignY="center" fullWidthx>
                    <Row>
                        <Col xs={12} md={6}>
                            <Box>
                                <Field
                                    component={DateField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="dateFrom"
                                    endDate={moment(values.dateTo)}
                                    handleChange={this.handleChangeDateFrom}
                                    resetFilters={resetFilters}
                                    handleResetDateFilters={handleResetDateFilters}
                                />
                            </Box>
                        </Col>
                        <Col xs={12} md={6}>
                            <Box>
                                <Field
                                    component={DateField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="dateTo"
                                    endDate={moment(values.dateTo)}
                                    minDate={selectedDateFrom || moment().add(-6, "months")}
                                    {...(isDesktop && {
                                        popperPlacement: "bottom-left",
                                        popperModifiers: {
                                            flip: {
                                                behavior: ["bottom-left"],
                                            },
                                        },
                                    })}
                                    resetFilters={resetFilters}
                                    handleResetDateFilters={handleResetDateFilters}
                                />
                            </Box>
                        </Col>
                        <Col className="mt-12">
                            <Button
                                bsStyle="primary"
                                label="global.search"
                                loading={isSubmitting}
                                type="submit"
                                block
                                image="images/search.svg"
                                onClick={handleSubmit}
                            />
                        </Col>
                    </Row>
                </Box>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        dateFrom: null,
        dateTo: null,
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .max(values.dateTo, i18n.get("creditCards.movements.filters.from.error"))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get("creditCards.movements.filters.to.error"))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId, onCloseModal } = formikBag.props;

        dispatch(creditCardsActions.movementsRequest(productId, filters, formikBag));

        if (!isDesktop) {
            const selectedPeriod = [filters.dateFrom, filters.dateTo];
            dispatch(
                push({
                    pathname: `/creditCards/${productId}/filters/results`,
                    state: { filters: { filter: "period", selectedPeriod, ...filters } },
                }),
            );
        }
        onCloseModal();
    },
})(resizableRoute(CreditCardPeriodFilter));

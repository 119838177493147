import { push, replace, routerActions } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { takeLatest, call, put, select } from "redux-saga/effects";
import * as softtokenApi from "middleware/softToken/softToken";
import {
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_MORPHOLOGY_FAILED,
    USER_TOKEN_STATUS_PENDING,
    USER_TOKEN_STATUS_PENDING_APROVE,
} from "util/userToken.util";
import { softTokenTypes, actions as softTokenActions } from "reducers/softToken";
import { buildEntrustKey, buildEnvironmentKey, deleteIdentity, ENT000, registerIdentity } from "util/softToken.util";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import { types as enrollmentTypes } from "reducers/enrollment";
import { crashLogData } from "util/crashReport/crashReport.util";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const ACTIVATE_MESSAGE_SEVERITY_MAP = new Map([
    [USER_TOKEN_STATUS_PENDING, { message: "token.entrust.saveToken.pending.message", severity: "warning" }],
    [USER_TOKEN_STATUS_MORPHOLOGY_FAILED, { message: "token.entrust.saveToken.pending.message", severity: "warning" }],
    [USER_TOKEN_STATUS_PENDING_APROVE, { message: "token.entrust.saveToken.pending.message", severity: "warning" }],
]);

function* showGenericErrorSaveToken(redirectError, scopeError, messageError) {
    if (redirectError) {
        yield put(push(redirectError));
    }

    yield put(
        notificationActions.showNotification(
            i18n.get(messageError || "token.entrust.saveToken.error.message"),
            "error",
            scopeError || [],
            false,
        ),
    );
}

function* setSaveTokenSuccess(redirectSuccess, scopeSuccess, messageSuccess, severity) {
    yield put({
        type: softTokenTypes.SAVE_TOKEN_SUCCESS,
    });
    if (redirectSuccess) {
        yield put(push(redirectSuccess));
    }

    yield put(
        notificationActions.showNotification(
            i18n.get(messageSuccess || "token.entrust.saveToken.success.message"),
            severity || "success",
            scopeSuccess || [],
        ),
    );
}

function* saveToken({ saveRequest }) {
    const {
        redirectSuccess,
        redirectError,
        scopeSuccess,
        scopeError,
        enableActivate = true,
        exchangeToken,
        credentials,
    } = saveRequest;

    const deviceUUID = window?.app?.getDeviceUUID() || "";
    const deviceModel = window?.app?.getDeviceModel() || "";
    const deviceBrand = window?.app?.getDeviceManufacturer() || "";

    if (!deviceUUID || deviceUUID === "") {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);
    const saveTokenResponse = yield call(
        isLoggedIn ? softtokenApi.saveUserToken : softtokenApi.saveUserTokenPublic,
        deviceUUID,
        credentials,
        deviceModel,
        deviceBrand,
        exchangeToken,
    );
    if (!saveTokenResponse) {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }

    const { type, data } = saveTokenResponse;
    if (!type || !data) {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }

    /**
     * validate code error
     */

    if (type === "W") {
        // TODO: validate response codes

        const errorResponseMessage = yield call(getCodeErrorSaveToken, data?.code);

        yield call(showGenericErrorSaveToken, redirectError, scopeError, errorResponseMessage);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }
    const { serialNumber, activateCode, userToken } = data.data || { serialNumber: undefined, activateCode: undefined };
    if (!serialNumber || !activateCode) {
        yield call(showGenericErrorSaveToken, redirectError, scopeError);
        yield call(putSaveTokenFailure);
        yield;
        return;
    }

    if (!enableActivate || enableActivate !== true) {
        yield call(setSaveTokenSuccess, redirectSuccess, scopeSuccess);
        yield;
        return;
    }

    yield call(generateSoftTokenLocal, {
        activateRequest: {
            serialNumber,
            activateCode,
            deviceUuid: deviceUUID,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
            isLoggedIn,
            exchangeToken,
            userToken,
        },
    });
}

const RETURN_CODE_ERROR_ENTRUST_MAP = new Map([
    ["API571W", "entrust.save.token.authenticate.otp.invalid"],
    ["API572W", "entrust.save.token.authenticate.otp.expire"],
    ["API573W", "entrust.save.token.authenticate.otp.used"],
]);

function* getCodeErrorSaveToken(code) {
    if (!code) {
        yield;
        return undefined;
    }

    yield;
    return RETURN_CODE_ERROR_ENTRUST_MAP.get(code);
}

function* generateSoftTokenLocal({ activateRequest }) {
    try {
        const {
            serialNumber,
            activateCode,
            deviceUuid,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
            isLoggedIn,
            exchangeToken,
            userToken,
        } = activateRequest;

        let loggedUser = yield select((state) => sessionSelectors.getUser(state));
        loggedUser = loggedUser || { user: "Undefined" };
        const entrustKey = buildEntrustKey(userToken);
        const environmentType = buildEnvironmentKey(userToken);
        const responseGenerateIdentity = yield call(registerIdentity, serialNumber, activateCode, entrustKey, {
            environmentType,
        });
        crashLogData({
            title: "respSDK",
            user: loggedUser?.idUser || "",
            deviceUuid,
            responseSDK: responseGenerateIdentity,
        });

        if (!responseGenerateIdentity) {
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(putActivateTokenFailure);
            yield call(
                isLoggedIn ? softtokenApi.resetActivateToken : softtokenApi.resetActivateTokenPublic,
                deviceUuid,
                exchangeToken,
            );
            yield;
            return;
        }

        const { code, data } = JSON.parse(responseGenerateIdentity);
        if (!code || !data || code !== ENT000) {
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(putActivateTokenFailure);
            yield call(
                isLoggedIn ? softtokenApi.resetActivateToken : softtokenApi.resetActivateTokenPublic,
                deviceUuid,
                exchangeToken,
            );
            yield;
            return;
        }

        const { registrationCode } = JSON.parse(data);
        if (!registrationCode || registrationCode === "") {
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(putActivateTokenFailure);
            yield call(
                isLoggedIn ? softtokenApi.resetActivateToken : softtokenApi.resetActivateTokenPublic,
                deviceUuid,
                exchangeToken,
            );
            yield;
            return;
        }

        /**
         * Activate user token in entrust
         */

        crashLogData({ title: "respSDK", user: loggedUser?.idUser || "", deviceUuid, step: "pre request activate" });

        const { fingerPrintToken } = yield call(
            getFpTokenbyDevice,
            isLoggedIn ? "token.entrust.activateUserToken" : "token.entrust.activateUserToken.public",
        );

        const responseActivateToken = yield call(
            isLoggedIn ? softtokenApi.activateUserToken : softtokenApi.activateUserTokenPublic,
            deviceUuid,
            registrationCode,
            exchangeToken,
            fingerPrintToken,
        );

        crashLogData({
            title: "respSDK2",
            user: loggedUser?.idUser || "",
            deviceUuid,
            responseActivate: responseActivateToken,
        });

        if (!responseActivateToken) {
            yield call(resetIdentityGenerated);
            yield call(putActivateTokenFailure);
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(
                isLoggedIn ? softtokenApi.resetActivateToken : softtokenApi.resetActivateTokenPublic,
                deviceUuid,
                exchangeToken,
            );
            yield;
            return;
        }
        const { data: dataActivate, type } = responseActivateToken;
        if (!type) {
            yield call(resetIdentityGenerated);
            yield call(putActivateTokenFailure);
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(
                isLoggedIn ? softtokenApi.resetActivateToken : softtokenApi.resetActivateTokenPublic,
                deviceUuid,
                exchangeToken,
            );
            yield;
            return;
        }

        if (type === "W") {
            yield call(resetIdentityGenerated);
            yield call(putActivateTokenFailure);
            yield call(showGenericErrorSaveToken, redirectError, scopeError);
            yield call(
                isLoggedIn ? softtokenApi.resetActivateToken : softtokenApi.resetActivateTokenPublic,
                deviceUuid,
                exchangeToken,
            );
            yield;
            return;
        }

        const messageSeverityActivate = ACTIVATE_MESSAGE_SEVERITY_MAP.get(dataActivate?.data?.tokenStatus || "");

        yield call(
            setSaveTokenSuccess,
            redirectSuccess,
            scopeSuccess,
            messageSeverityActivate?.message,
            messageSeverityActivate?.severity,
        );
    } catch (e) {
        crashLogData({ title: "SDK error", e });
    }
}

function* resetIdentityGenerated() {
    const entrustKey = buildEntrustKey();
    yield call(deleteIdentity, entrustKey, buildEnvironmentKey());
}

function* putSaveTokenFailure() {
    yield put({
        type: softTokenTypes.SAVE_TOKEN_FAILURE,
    });
}

function* putActivateTokenFailure() {
    yield put({
        type: softTokenTypes.ACTIVATE_TOKEN_FAILURE,
    });
}

function* showGenericErrorValidateTokenStatus() {
    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.validateStatus.error.message"),
            "error",
            ["desktop", "menu"],
            false,
        ),
    );
    yield put(replace("/desktop"));
}

function* validateTokenStatus() {
    const deviceUUID = window?.app?.getDeviceUUID();
    if (!deviceUUID || deviceUUID === "") {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }
    const validateStatusTokenResponse = yield call(softtokenApi.validateStatusToken, deviceUUID);
    if (!validateStatusTokenResponse) {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }
    const { data, type } = validateStatusTokenResponse;
    if (!type || !data) {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }

    /**
     * validate code error
     */

    if (type === "W") {
        // TODO: validate response codes

        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }

    const { tokenStatus } = data.data || { tokenStatus: undefined };
    if (!tokenStatus) {
        yield call(showGenericErrorValidateTokenStatus);
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_INACTIVE) {
        yield put(replace("/auth/tokenActivationStep1"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
        yield put(replace("/authenticateSofttoken"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
        yield put(replace("/migrateSoftTokenLocal"));
        yield;
        return;
    }
    if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
        yield put(push("/migrateSoftTokenEntrust"));
        yield;
        return;
    }
    yield call(showGenericErrorValidateTokenStatus);
    yield;
}

function* getStatusToken() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateStatusToken, deviceUuid);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    const { tokenStatus } = data?.data;
    if (!tokenStatus) {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    yield put(softTokenActions.getStatusTokenSuccess(tokenStatus));
}

function* migrateEntrustTokenFailure() {
    yield put({
        type: softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_FAILURE,
    });

    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.migrateEntrust.validate.error.message"),
            "error",
            ["tokenActivation"],
            false,
        ),
    );
}

function* redirectBiometricValidation(callBackSelfie) {
    yield put({ type: enrollmentTypes.SET_CALLBACK_DATA_SELFIE, callBackSelfie });
    yield put(routerActions.replace("/auth/tokenActivationStep1"));
    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.migrateEntrust.validate.error.message"),
            "error",
            ["tokenActivation"],
            false,
        ),
    );
}

function* migrateEntrustTokenPre({ validateData }) {
    const { callBackSelfie } = validateData;
    const deviceUuid = window?.app?.getDeviceUUID();
    if (!deviceUuid || deviceUuid === "") {
        yield call(migrateEntrustTokenFailure);
        yield;
        return;
    }

    const response = yield call(softtokenApi.migrateEntrustTokenPre, deviceUuid);
    if (!response?.type || !response?.data?.data) {
        yield call(migrateEntrustTokenFailure);
        yield;
        return;
    }

    const {
        type,
        data: { data: dataResponse },
    } = response;
    if (type === "W") {
        yield call(migrateEntrustTokenFailure);
        yield;
        return;
    }

    const { isValidDeviceUuid } = dataResponse;
    if (isValidDeviceUuid === undefined || isValidDeviceUuid !== true) {
        redirectBiometricValidation(callBackSelfie);
        yield;
        return;
    }

    yield put({
        type: softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS,
    });
}

function* setResultResendOtpAuthenticate(type, message, scope) {
    yield put({
        type,
    });
    yield put(notificationActions.showNotification(i18n.get(message), scope, ["authenticateSofttoken", "menu"], false));
}

function* resendOtpAuthenticate() {
    const response = yield call(softtokenApi.resendOtpAuthenticate);
    if (!response?.type || response.type === "W") {
        yield call(
            setResultResendOtpAuthenticate,
            softTokenTypes.RESEND_OTP_AUTHENTICATE_FAILURE,
            "token.entrust.resendOtpAuthenticate.error.message",
            "error",
        );
        yield;
        return;
    }
    yield call(
        setResultResendOtpAuthenticate,
        softTokenTypes.RESEND_OTP_AUTHENTICATE_SUCCESS,
        "token.entrust.resendOtpAuthenticate.success.message",
        "success",
    );
}

const sagas = [
    takeLatest(softTokenTypes.VALIDATE_TOKEN_STATUS_REQUEST, validateTokenStatus),
    takeLatest(softTokenTypes.SAVE_TOKEN_REQUEST, saveToken),
    takeLatest(softTokenTypes.ACTIVATE_TOKEN_REQUEST, generateSoftTokenLocal),
    takeLatest(softTokenTypes.GET_STATUS_TOKEN_REQUEST, getStatusToken),
    takeLatest(softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_REQUEST, migrateEntrustTokenPre),
    takeLatest(softTokenTypes.RESEND_OTP_AUTHENTICATE_REQUEST, resendOtpAuthenticate),
];
export default sagas;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { string, func, node, bool, shape, oneOfType } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as formSelectors } from "reducers/form";

import { resizableRoute } from "pages/_components/Resizable";
import Header from "pages/_components/header/Header";
import Button from "pages/_components/Button";
import DownloadDropdown from "pages/_components/DownloadDropdown";
import ViewTitle from "pages/_components/header/components/ViewTitle";
import { Helmet } from "react-helmet";
import * as utilsI18n from "util/i18n";

class TicketHead extends Component {
    static propTypes = {
        // i18n head title
        title: node,
        // specify a translated text instead a i18n key
        titleText: string,
        dispatch: func.isRequired,
        // back link url
        backLinkTo: string,
        // close link url
        closeLinkTo: string,
        // head logo
        logo: node,
        // onClose function link, ignored if closeLinkTo is specified
        onClose: func,
        // called right after button with closeLinkTo is clicked
        onCloseClick: func,
        handleClick: func,
        fetchingDownloadTicket: bool,
        isMobile: bool,
        hasActiveSession: bool.isRequired,
        hasCenterContent: bool,
        accessibilityTextId: string,
        children: shape(),
        replace: oneOfType([shape({}), string]),
    };

    static defaultProps = {
        title: null,
        titleText: "",
        backLinkTo: "",
        closeLinkTo: "",
        logo: null,
        onClose: null,
        onCloseClick: () => {},
        handleClick: null,
        fetchingDownloadTicket: false,
        isMobile: false,
        hasCenterContent: false,
        accessibilityTextId: "",
        replace: null,
        children: null,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    componentDidUpdate() {
        if (this.titleRef.current) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    back = () => {
        const { dispatch, backLinkTo } = this.props;

        dispatch(routerActions.replace({ pathname: backLinkTo, state: { transition: "transition-drill-out" } }));
    };

    close = () => {
        const { onCloseClick, dispatch, closeLinkTo } = this.props;

        onCloseClick();
        dispatch(routerActions.replace({ pathname: closeLinkTo, state: { transition: "transition-flow-close" } }));
    };

    getLeftOption = () => {
        const { hasActiveSession, isMobile, handleClick } = this.props;
        if (hasActiveSession && isMobile && window.plugins && window.plugins.socialsharing) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-left shareIcon">
                    <Button
                        className="toolbar-btn view-close"
                        onClick={() => handleClick("shareTicket")}
                        image="images/send-up.svg"
                        label="global.share"
                    />
                </div>
            );
        }

        return null;
    };

    getCenterContent = () => {
        const { children, logo, replace, title, titleText, hasCenterContent, accessibilityTextId } = this.props;

        return (
            (title || titleText || logo || hasCenterContent) && (
                <Fragment>
                    <Helmet>
                        <title>
                            {// eslint-disable-next-line no-nested-ternary
                            utilsI18n.get(accessibilityTextId || title) !== "*undefined*"
                                ? `Banesco - ${utilsI18n.get(accessibilityTextId || title)}`
                                : titleText !== ""
                                ? `Banesco - ${titleText}`
                                : "Banesco"}
                        </title>
                    </Helmet>
                    {(title || titleText) && (
                        <ViewTitle
                            id={title}
                            accessibilityTextId={accessibilityTextId}
                            replace={replace}
                            defaultValue={titleText}
                        />
                    )}
                    {children}
                </Fragment>
            )
        );
    };

    getRightOption = () => {
        const { closeLinkTo, isMobile, onClose, handleClick, fetchingDownloadTicket } = this.props;

        if (isMobile && (closeLinkTo || onClose)) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                    <Button
                        className="toolbar-btn view-close"
                        onClick={closeLinkTo ? this.close : onClose}
                        image="images/cross.svg"
                        label="global.close"
                        bsStyle="link"
                    />
                </div>
            );
        }

        return (
            <Fragment>
                <div className="toolbar-item toolbar-item--fixed" key="exportList">
                    <DownloadDropdown
                        fetching={fetchingDownloadTicket}
                        formats={["pdf"]}
                        onDownloadClick={() => handleClick("downloadTicketPdf")}
                    />
                </div>

                <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                    <Button
                        onClick={closeLinkTo ? this.close : onClose}
                        image="images/cross.svg"
                        label="global.close"
                        bsStyle="outline"
                    />
                </div>
            </Fragment>
        );
    };

    render() {
        return (
            <Header>
                {this.getLeftOption()}
                {
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    <div className="toolbar-item view-title" tabIndex="0" ref={this.titleRef}>
                        {this.getCenterContent()}
                    </div>
                }
                {this.getRightOption()}
            </Header>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    fetchingDownloadTicket: formSelectors.isFetchingDownloadTicket(state),
});

export default connect(mapStateToProps)(resizableRoute(TicketHead));

import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { createReducer, makeActionCreator } from "util/redux";

import globalTypes from "reducers/types/global";

export const types = {
    CHECKBOOKS_LIST_REQUEST: "checks/CHECKBOOKS_LIST_REQUEST",
    CHECKBOOKS_LIST_FAILURE: "checks/CHECKBOOKS_LIST_FAILURE",
    CHECKBOOKS_LIST_SUCCESS: "checks/CHECKBOOKS_LIST_SUCCESS",
    CHECKS_LIST_REQUEST: "checks/CHECKS_LIST_REQUEST",
    CHECKS_LIST_FAILURE: "checks/CHECKS_LIST_FAILURE",
    CHECKS_LIST_SUCCESS: "checks/CHECKS_LIST_SUCCESS",
    ALL_CHECKS_LIST_REQUEST: "checks/ALL_CHECKS_LIST_REQUEST",
    ALL_CHECKS_LIST_FAILURE: "checks/ALL_CHECKS_LIST_FAILURE",
    ALL_CHECKS_LIST_SUCCESS: "checks/ALL_CHECKS_LIST_SUCCESS",
    URL_CHECK_REQUEST: "checks/URL_CHECK_REQUEST",
    URL_CHECK_CLEAR: "checks/URL_CHECK_CLEAR",
    DATA_CHECK_SUSPEND: "checks/DATA_CHECK_SUSPEND",
};

export const INITIAL_STATE = {
    checkbooks: [],
    checks: [],
    currentCheckbook: {},
    accountNumber: "",
    fetching: false,
    initialCheckNumber: 0,
    finalCheckNumber: 0,
    fetchCheckUrl: false,
    dataFormToSuspend: {},
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: () => ({
        ...INITIAL_STATE,
    }),
    [types.CHECKBOOKS_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CHECKBOOKS_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CHECKBOOKS_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checkbooks: action.checkbooks,
        accountNumber: action.accountNumber,
        fetching: false,
    }),
    [types.CHECKS_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CHECKS_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CHECKS_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checks: action.checks,
        accountNumber: action.accountNumber,
        initialCheckNumber: action.initialCheckNumber,
        finalCheckNumber: action.finalCheckNumber,
        currentCheckbook: action.currentCheckbook,
        fetching: false,
    }),
    [types.ALL_CHECKS_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.ALL_CHECKS_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.ALL_CHECKS_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checks: action.checks,
        accountNumber: action.accountNumber,
        initialCheckNumber: 0,
        finalCheckNumber: 0,
        currentCheckbook: {},
        fetching: false,
    }),
    [types.URL_CHECK_REQUEST]: (state) => ({
        ...state,
        fetchCheckUrl: true,
    }),
    [types.URL_CHECK_CLEAR]: (state) => ({
        ...state,
        fetchCheckUrl: false,
    }),
    [types.DATA_CHECK_SUSPEND]: (state, action) => ({
        ...state,
        dataFormToSuspend: action.data,
    }),
});

export default persistReducer(
    {
        key: "checks",
        storage: storageSession,
        blacklist: ["fetching", "fetchCheckUrl"],
    },
    reducer,
);

export const actions = {
    listCheckbooksRequest: makeActionCreator(types.CHECKBOOKS_LIST_REQUEST, "idAccount"),
    listCheckbooksFailure: makeActionCreator(types.CHECKBOOKS_LIST_FAILURE),
    listCheckbooksSuccess: makeActionCreator(types.CHECKBOOKS_LIST_SUCCESS, "checkbooks", "accountNumber"),
    listChecksRequest: makeActionCreator(types.CHECKS_LIST_REQUEST, "idAccount", "idCheckbook"),
    listChecksFailure: makeActionCreator(types.CHECKS_LIST_FAILURE),
    listChecksSuccess: makeActionCreator(
        types.CHECKS_LIST_SUCCESS,
        "checks",
        "accountNumber",
        "initialCheckNumber",
        "finalCheckNumber",
        "currentCheckbook",
    ),
    listAllChecksRequest: makeActionCreator(types.ALL_CHECKS_LIST_REQUEST, "idAccount"),
    listAllChecksFailure: makeActionCreator(types.ALL_CHECKS_LIST_FAILURE),
    listAllChecksSuccess: makeActionCreator(types.ALL_CHECKS_LIST_SUCCESS, "checks", "accountNumber"),
    getUrlCheckRequest: makeActionCreator(types.URL_CHECK_REQUEST, "idAccount", "checkNumber"),
    getUrlCheckClear: makeActionCreator(types.URL_CHECK_CLEAR),
    setdataCheckToSuspend: makeActionCreator(types.DATA_CHECK_SUSPEND, "data"),
};

export const selectors = {
    getFetching: ({ checks }) => checks.fetching,
    checkbooks: ({ checks }) => checks.checkbooks,
    checks: ({ checks }) => checks.checks,
    accountNumber: ({ checks }) => checks.accountNumber,
    initialCheckNumber: ({ checks }) => checks.initialCheckNumber,
    finalCheckNumber: ({ checks }) => checks.finalCheckNumber,
    currentCheckbook: ({ checks }) => checks.currentCheckbook,
    isFetchingCheckUrl: ({ checks }) => checks.fetchCheckUrl,
    getDataCheckToSuspend: ({ checks }) => checks.dataFormToSuspend,
};

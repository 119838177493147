import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { node, shape, string, bool } from "prop-types";
import React, { Children, Component } from "react";
import { Col } from "react-bootstrap";
import { withContentRect } from "react-measure";
import { Tab, TabList, TabPanel, Tabs as RTTabs } from "react-tabs";

class Tabs extends Component {
    static propTypes = {
        components: node.isRequired,
        contentRect: shape({}).isRequired,
        measureRef: shape({}).isRequired,
        background: string,
        borderRadius: string,
        wally: bool,
        idProduct: string,
    };

    static defaultProps = {
        background: null,
        borderRadius: null,
        wally: false,
        idProduct: null,
    };

    state = {
        selectedIndex: 0,
    };

    componentDidUpdate(prevProps) {
        const { idProduct } = this.props;

        if (idProduct && idProduct !== prevProps.idProduct) {
            this.clearSelectedIndex();
        }
    }

    clearSelectedIndex = () => {
        this.setState({
            selectedIndex: 0,
        });
    };

    handleTabSelect = (index) => {
        this.setState({
            selectedIndex: index,
        });
    };

    render() {
        const { components, contentRect, measureRef, background, borderRadius, wally } = this.props;
        const { selectedIndex } = this.state;

        return Children.count(components) === 1 ? (
            <div className="containerDetails container-fluid" ref={measureRef}>
                <Row className="justify-content-center">
                    <Col className="col-12">
                        {React.cloneElement(Children.only(components), {
                            containerBounds: contentRect.bounds,
                        })}
                    </Col>
                </Row>
            </div>
        ) : (
            <RTTabs
                className={classNames("container--layout flex-grow-1 flex-column mb-0 mt-0", { wally })}
                selectedIndex={selectedIndex}
                onSelect={this.handleTabSelect}>
                <Box background={background} borderRadius={borderRadius}>
                    <TabList>
                        {Children.map(components, (child) => {
                            if (child) {
                                const {
                                    keyLabel,
                                    iconLabel,
                                    colorIconLabel,
                                    customKeyLabel,
                                    showIconAllways,
                                    keyLabelColor,
                                    isDesktop,
                                } = child.props;
                                return (
                                    <Tab>
                                        {iconLabel && (isDesktop || showIconAllways) && (
                                            <Image color={colorIconLabel} src={iconLabel} />
                                        )}
                                        {customKeyLabel ? (
                                            <span>{keyLabel}</span>
                                        ) : (
                                            <Text labelKey={keyLabel} className={keyLabelColor} />
                                        )}
                                    </Tab>
                                );
                            }
                            return null;
                        })}
                    </TabList>
                </Box>

                <Box className="containerDetails container-fluid" ref={measureRef}>
                    <Box className="justify-content-center px-0">
                        {Children.map(components, (child) => {
                            if (child) {
                                return (
                                    <TabPanel>
                                        {React.cloneElement(child, {
                                            containerBounds: contentRect.bounds,
                                        })}
                                    </TabPanel>
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Box>
            </RTTabs>
        );
    }
}

export default withContentRect("bounds")(Tabs);

import classNames from "classnames";
import Box from "pages/_components/Box";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";
import { bool, element, func, number, shape, string } from "prop-types";
import React from "react";
import * as i18nUtils from "util/i18n";

const SelectSimpleField = (props) => {
    const {
        clearable,
        containerClassName,
        disabled,
        disabledInput,
        error,
        field,
        form,
        inputName,
        inputOnChange,
        inputPattern,
        inputPlaceholder,
        inputType,
        inputValue,
        isRequired,
        labelKey,
        maxLength,
        name,
        onChange,
        options,
        pattern,
        placeholder,
        searchable,
        textUpperCase,
        tooltipPosition,
        tooltipText,
        value,
        valueKey,
    } = props;

    const fieldHasError = (subfield) => {
        if (error) {
            return true;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;
            return field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`];
        }
        return null;
    };

    const renderError = (subfield) => {
        if (error) {
            return <FieldError error={error} />;
        }
        if (Object.keys(form).length > 0) {
            const { errors } = form;

            if (fieldHasError(subfield)) {
                return (
                    <FieldError
                        error={field.name === subfield ? errors[`${field.name}`] : errors[`${field.name}.${subfield}`]}
                    />
                );
            }
        }
        return null;
    };

    return (
        <>
            <Box
                className={classNames("form-group", {
                    "has-error": fieldHasError(name),
                })}>
                {labelKey && (
                    <FieldLabel
                        optional={isRequired ? "" : i18nUtils.get("forms.optional")}
                        idField={name}
                        labelKey={labelKey}
                        tooltipText={tooltipText}
                        tooltipPosition={tooltipPosition}
                        labelNoMarginTop
                        htmlFor={`react-select-${name}-input`}
                    />
                )}
                <Box className="input-group">
                    <Select
                        id={name}
                        field={field}
                        form={form}
                        containerClassName={containerClassName}
                        name={Object.keys(field).length > 0 ? `${field.name}_${name}` : name}
                        placeholder={placeholder}
                        valueKey={valueKey}
                        {...(value && { value })}
                        {...(onChange && { onChange: (event) => onChange(event, name) })}
                        maxLength={maxLength}
                        pattern={pattern}
                        disabled={disabled}
                        options={options}
                        searchable={searchable}
                        clearable={clearable}
                    />
                    {inputName && (
                        <input
                            id={name}
                            className={`form-control ${textUpperCase ? "text-uppercase" : ""}`}
                            type={inputType}
                            value={inputValue}
                            name={inputName}
                            onChange={inputOnChange}
                            placeholder={inputPlaceholder}
                            pattern={inputPattern}
                            disabled={disabledInput}
                        />
                    )}
                </Box>
                {renderError(name)}
            </Box>
        </>
    );
};

SelectSimpleField.propTypes = {
    clearable: bool,
    containerClassName: string,
    disabled: bool,
    disabledInput: bool,
    error: string,
    field: shape({}),
    form: shape({}),
    inputName: string,
    inputOnChange: func,
    inputPattern: string,
    inputPlaceholder: string,
    inputType: string,
    inputValue: string,
    isRequired: bool,
    labelKey: string,
    maxLength: number,
    name: string.isRequired,
    onChange: func,
    options: element.isRequired,
    pattern: string,
    placeholder: string,
    searchable: bool,
    textUpperCase: bool,
    tooltipPosition: string,
    tooltipText: string,
    value: shape({}),
    valueKey: string,
};

SelectSimpleField.defaultProps = {
    clearable: false,
    containerClassName: null,
    disabled: false,
    disabledInput: false,
    error: null,
    field: {},
    form: {},
    inputName: null,
    inputOnChange: null,
    inputPattern: null,
    inputPlaceholder: "",
    inputType: "text",
    inputValue: null,
    isRequired: true,
    labelKey: null,
    maxLength: null,
    onChange: null,
    pattern: null,
    placeholder: null,
    searchable: false,
    textUpperCase: false,
    tooltipPosition: null,
    tooltipText: null,
    value: null,
    valueKey: "value",
};

export default SelectSimpleField;

import classNames from "classnames";
import { REGION_USA } from "constants.js";
import { Field } from "formik";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import SwitchField from "pages/_components/fields/SwitchField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { selectors as sessionSelectors } from "reducers/session";
import SelectSimpleField from "../_customFields/SelectSimpleField";

import TextSimpleField from "../_customFields/TextSimpleField";

export const generateIdField = (name, idComponent) => {
    if (!idComponent || idComponent === "") {
        return name;
    }
    return `${idComponent}_${name}`;
};

const SwitfBankSelector = ({
    form,
    field,
    handleChangeSelector,
    recipientAccountCodeMapOptions,
    handleChangeText,
    showIntermediaryBank,
    showSave,
    idComponent,
    mode,
    genericProps,
    handleChangeCheckBox,
    idForm,
    titleHeader,
    dispatch,
    bankDescriptionData,
    countryList,
    isFetchingBank,
    directoryStyle,
    loadBeneficiary,
    clearBeneficiary,
    isDesktop,
    handleChangeSwitch,
    beneficiaryManagePermission,
    value,
}) => {
    const [fetchingComponent, setFetchingComponent] = useState(false);

    useEffect(() => {
        if (!isFetchingBank) {
            setFetchingComponent(false);
        }
    }, [isFetchingBank]);

    const BANK_SELECTOR_CUENTA = "CUENTA";
    const getNameBankByCode = (code) => {
        if (!code) {
            return "";
        }

        if (!countryList) {
            return code;
        }

        const resultBank = countryList.find((item) => item.id === code);
        return resultBank?.label || code;
    };

    const onSearchBank = () => {
        if (!form || !form.values || !form.setFieldValue || !form.values.beneficiary) {
            return;
        }
        const { beneficiary } = form.values;
        let swiftCode = beneficiary[generateIdField("swiftCode", idComponent)];
        const bankSelector = beneficiary[generateIdField("bankSelector", idComponent)];
        if (!swiftCode || !bankSelector) {
            const errors = {};
            errors[`beneficiary.${generateIdField("bankSelector", idComponent)}`] = i18n.get(DEFAULT_REQUIRED);
            form.setErrors(errors);
            return;
        }
        const bankType = field?.value ? field?.value[generateIdField("bankSelector", idComponent)]?.id || "" : "";
        const codeBank = field?.value ? field?.value[generateIdField("swiftCode", idComponent)] || "" : "";

        if (bankType === "SWIFT") {
            const minLengthValidCodeSWIFT = configUtils.getInteger("bankselector.validCodeSWIFT.field.length.min");
            const maxLengthValidCodeSWIFT = configUtils.getInteger("bankselector.validCodeSWIFT.field.length.max");
            if (codeBank.length < minLengthValidCodeSWIFT || codeBank.length > maxLengthValidCodeSWIFT) {
                const errors = {};
                errors[`beneficiary.${generateIdField("bankSelector", idComponent)}`] = i18n.get(
                    "forms.bankselector.noRecordsFound.field",
                );
                form.setErrors(errors);
                return;
            }

            swiftCode = swiftCode.padEnd(maxLengthValidCodeSWIFT, "X");
            beneficiary[generateIdField("swiftCode", idComponent)] = swiftCode;
            form.setFieldValue("beneficiary", beneficiary);
        }

        const { id: bankId } = bankSelector;
        if (!bankId) {
            return;
        }
        setFetchingComponent(true);
        dispatch(formActions.bankDescriptionRequest(swiftCode.toUpperCase(), bankId, idComponent));
    };

    const setBankData = () => {
        if (!bankDescriptionData) {
            return;
        }
        const { name, postAddr, idComponent: idComp } = bankDescriptionData;
        if (!form || !form.setFieldValue) {
            return;
        }
        form.setFieldValue(`beneficiary.${generateIdField("bankName", idComp)}`, name || "");

        if (postAddr) {
            const { addr1, city, country } = postAddr;
            form.setFieldValue(`beneficiary.${generateIdField("bankAddress", idComp)}`, addr1 || "");
            form.setFieldValue(`beneficiary.${generateIdField("bankCity", idComp)}`, city || "");
            form.setFieldValue(`beneficiary.${generateIdField("bankCountry", idComp)}`, country || "");
            form.setFieldValue(
                `beneficiary.${generateIdField("bankCountryName", idComp)}`,
                getNameBankByCode(country) || "",
            );
        }
    };

    useEffect(() => {
        if (
            bankDescriptionData &&
            bankDescriptionData.idComponent !== undefined &&
            bankDescriptionData.idComponent === idComponent
        ) {
            setBankData();
        }
    }, [bankDescriptionData]);

    const [showTooltipCountry, setShowTooltipCountry] = useState(false);
    const validateLocationBank = () => {
        if (!idComponent || idComponent === "" || !form || !form.values || !form.values.beneficiary) {
            setShowTooltipCountry(false);
            return;
        }

        const { bankCountry, intermediary_bankCountry: intermediaryBankCountry } = form.values.beneficiary;
        const show =
            bankCountry &&
            intermediaryBankCountry &&
            bankCountry === intermediaryBankCountry &&
            bankCountry === REGION_USA;
        setShowTooltipCountry(show);
    };

    useEffect(() => {
        validateLocationBank();
    }, [form.values?.beneficiary]);

    const generateLabelBank = () => {
        const codeBank = field?.value ? field?.value[generateIdField("bankSelector", idComponent)]?.id || "" : "";
        return `${i18n.get(`forms.${idForm}.beneficiary.bankSelector.label_preview`)} ${codeBank}`;
    };

    const getFieldPreviewValue = (fieldName) => {
        if (field && field.value && field.value.beneficiary) {
            return field?.value[generateIdField(fieldName, idComponent)];
        }
        if (value) {
            return value[generateIdField(fieldName, idComponent)];
        }
        return "";
    };

    const isCuentaNotSelected = () => {
        if (idComponent === "intermediary") {
            return field?.value ? field?.value?.intermediary_bankSelector?.id !== BANK_SELECTOR_CUENTA : false;
        }
        return field?.value ? field?.value?.bankSelector?.id !== BANK_SELECTOR_CUENTA : false;
    };

    const onChangeCountry = (target, name) => {
        handleChangeSelector(target, name);
        form.setFieldValue(`beneficiary.${generateIdField("bankCountry", idComponent)}`, target?.id);
        form.setFieldValue(`beneficiary.${generateIdField("bankCountryName", idComponent)}`, target?.label);
    };

    const onChangeBankSelector = (target, name) => {
        handleChangeSelector(target, name);

        if (target?.id === BANK_SELECTOR_CUENTA && handleChangeCheckBox) {
            form.setFieldValue("addIntermediaryBank", true);
            handleChangeCheckBox(true, generateIdField("addIntermediaryBank", idComponent));
        }

        form.setFieldValue(`beneficiary.${generateIdField("swiftCode", idComponent)}`, "");
        form.setFieldValue(`beneficiary.${generateIdField("bankName", idComponent)}`, "");
        form.setFieldValue(`beneficiary.${generateIdField("bankAddress", idComponent)}`, "");
        form.setFieldValue(`beneficiary.${generateIdField("bankCity", idComponent)}`, "");
        form.setFieldValue(`beneficiary.${generateIdField("bankCountry", idComponent)}`, "");
        form.setFieldValue(`beneficiary.${generateIdField("bankCountryName", idComponent)}`, "");
    };

    const renderDetailView = () => (
        <Box display="flex" fullWidth column>
            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={getFieldPreviewValue("swiftCode")}
                labelCustom={generateLabelBank()}
                cleanText
                {...(directoryStyle && isDesktop && { mode: "preview" })}
            />

            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={getFieldPreviewValue("bankName")}
                label={`forms.${idForm}.beneficiary.bankName.label_preview`}
                {...(directoryStyle && isDesktop && { mode: "preview" })}
            />

            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={getFieldPreviewValue("bankAddress")}
                label={`forms.${idForm}.beneficiary.bankAddress.label_preview`}
                {...(directoryStyle && isDesktop && { mode: "preview" })}
            />
            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={getFieldPreviewValue("bankCity")}
                label={`forms.${idForm}.beneficiary.bankCity.label_preview`}
                {...(directoryStyle && isDesktop && { mode: "preview" })}
            />
            <FormFieldsComponents.ReadTextCustom
                {...genericProps}
                value={getFieldPreviewValue("bankCountryName")}
                label={`forms.${idForm}.beneficiary.bankCountry.label_preview`}
                {...(directoryStyle && isDesktop && { mode: "preview" })}
            />
        </Box>
    );

    const content = () => (
        <Box
            className={classNames("pt-8 pb-9 pb-md-10 mx-n-5 mb-7", {
                "pl-5 pl-lg-10 pr-5 pr-lg-10": !directoryStyle,
                "px-5 px-md-0": directoryStyle,
            })}>
            <Row className="mb-8">
                <Box display="flex" alignY="center" fullWidth className="mb-5">
                    <Text size="5" color="primary" className="mr-2">
                        {idComponent === "intermediary" && i18n.get("forms.transfers.foreign.intermediaryBank.label")}
                        {idComponent !== "intermediary" && i18n.get("forms.transfers.foreign.switfBank.title")}
                    </Text>
                </Box>
                <Col xs={12} md={6}>
                    <Box display="flex">
                        {/* BANK SELECTOR */}
                        <SelectSimpleField
                            containerClassName="bank-selector"
                            disabled={loadBeneficiary}
                            disabledInput={loadBeneficiary}
                            form={form}
                            field={field}
                            labelKey={`forms.${idForm}.beneficiary.bankSelector.label`}
                            name={generateIdField("bankSelector", idComponent)}
                            placeholder=""
                            valueKey="id"
                            value={field?.value ? field?.value[generateIdField("bankSelector", idComponent)] || "" : ""}
                            onChange={(target, name) => {
                                onChangeBankSelector(target, name);
                            }}
                            options={recipientAccountCodeMapOptions}
                            inputValue={
                                field?.value ? field?.value[generateIdField("swiftCode", idComponent)] || "" : ""
                            }
                            inputName={generateIdField("swiftCode")}
                            inputOnChange={(e) => handleChangeText(e, generateIdField("swiftCode", idComponent))}
                            inputPattern="^[a-zA-Z0-9]*$"
                            tooltipText="forms.transfers.foreign.beneficiary.field.bankCode.tooltip"
                            tooltipPosition="bottom-centered"
                            textUpperCase
                        />
                        {isCuentaNotSelected() && (
                            <Box display="flex" className="mx-7">
                                <Button
                                    className="mt-auto btn-primary"
                                    loading={isFetchingBank && fetchingComponent}
                                    onClick={() => {
                                        onSearchBank(idComponent);
                                    }}
                                    bsStyle="only-icon"
                                    image="images/search.svg"
                                    disabled={loadBeneficiary}
                                />
                            </Box>
                        )}
                        {/* BANK SELECTOR */}
                    </Box>
                </Col>
                <Col xs={12} md={6}>
                    {/* BANK NAME */}

                    <TextSimpleField
                        form={form}
                        field={field}
                        labelKey={`forms.${idForm}.beneficiary.bankName.label`}
                        name={generateIdField("bankName", idComponent)}
                        placeholder=""
                        value={field?.value ? field?.value[generateIdField("bankName", idComponent)] || "" : ""}
                        onChange={handleChangeText}
                        pattern="^[a-zA-Z0-9 ]*$"
                        disabled={isCuentaNotSelected() || loadBeneficiary}
                    />
                    {/* BANK NAME */}
                </Col>
                <Col xs={12} md={6}>
                    {/* BANK ADDRESS */}

                    <TextSimpleField
                        form={form}
                        field={field}
                        labelKey={`forms.${idForm}.beneficiary.bankAddress.label`}
                        name={generateIdField("bankAddress", idComponent)}
                        placeholder=""
                        value={field?.value ? field?.value[generateIdField("bankAddress", idComponent)] || "" : ""}
                        onChange={handleChangeText}
                        pattern="^[a-zA-Z0-9 ]*$"
                        disabled={isCuentaNotSelected() || loadBeneficiary}
                    />
                    {/* BANK ADDRESS */}
                </Col>
                <Col xs={12} md={6}>
                    <Row>
                        <Col xs={12} md={6}>
                            {/* BANK CITY */}

                            <TextSimpleField
                                form={form}
                                field={field}
                                labelKey={`forms.${idForm}.beneficiary.bankCity.label`}
                                name={generateIdField("bankCity", idComponent)}
                                placeholder=""
                                value={field?.value ? field?.value[generateIdField("bankCity", idComponent)] || "" : ""}
                                onChange={handleChangeText}
                                pattern="^[a-zA-Z0-9 ]*$"
                                disabled={isCuentaNotSelected() || loadBeneficiary}
                            />
                            {/* BANK CITY */}
                        </Col>
                        <Col xs={12} md={6}>
                            {/* BANK COUNTRY */}

                            {isCuentaNotSelected() ? (
                                <TextSimpleField
                                    form={form}
                                    field={field}
                                    labelKey={`forms.${idForm}.beneficiary.bankCountry.label`}
                                    name={generateIdField("bankCountry", idComponent)}
                                    placeholder=""
                                    value={
                                        field?.value
                                            ? getNameBankByCode(
                                                  field?.value[generateIdField("bankCountry", idComponent)] || "",
                                              )
                                            : ""
                                    }
                                    onChange={handleChangeText}
                                    pattern="^[a-zA-Z0-9 ]*$"
                                    disabled={isCuentaNotSelected() || loadBeneficiary}
                                />
                            ) : (
                                <SelectSimpleField
                                    form={form}
                                    field={field}
                                    labelKey={`forms.${idForm}.beneficiary.bankCountry.label`}
                                    name={generateIdField("bankCountrySel", idComponent)}
                                    placeholder={i18n.get("forms.placeholder.select")}
                                    valueKey="id"
                                    value={
                                        field?.value
                                            ? getNameBankByCode(
                                                  field?.value[generateIdField("bankCountrySel", idComponent)] || "",
                                              )
                                            : ""
                                    }
                                    onChange={(target, name) => {
                                        if (handleChangeSelector) {
                                            onChangeCountry(target, name);
                                        }
                                    }}
                                    options={countryList}
                                    menuOuter100
                                    disabled={loadBeneficiary}
                                    searchable
                                />
                            )}
                            {/* BANK COUNTRY */}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Box display="flex" fullWidth alignX="between" alignY="center">
                {showIntermediaryBank && !loadBeneficiary && (
                    <Box display="flex" alignY="center">
                        <Field
                            name={generateIdField("addIntermediaryBank", idComponent)}
                            component={Checkbox}
                            className="mb-0"
                            formGroup
                            labelColor="primary"
                            idForm="forms.transfers.foreign"
                            onChange={(checkedInt) => {
                                if (handleChangeCheckBox) {
                                    handleChangeCheckBox(
                                        checkedInt,
                                        generateIdField("addIntermediaryBank", idComponent),
                                    );
                                }
                            }}
                        />
                        <Tooltip text={`forms.${idForm}.beneficiary.intermediary.tooltip`} />
                    </Box>
                )}

                <Box display="flex" alignX="flex-end" className="ml-md-auto">
                    {showSave && (
                        <>
                            {!loadBeneficiary && beneficiaryManagePermission && (
                                <SwitchField
                                    {...field}
                                    {...form}
                                    name="saveBeneficiary"
                                    idForm="forms.beneficiary"
                                    value={field?.value?.saveBeneficiary}
                                    onChange={handleChangeSwitch}
                                    error={false}
                                />
                            )}
                            {loadBeneficiary && (
                                <Button
                                    label="frequentDestination.clearBeneficiary"
                                    onClick={clearBeneficiary}
                                    image="images/icons/circleCross.svg"
                                    bsStyle="link"
                                />
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
    return (
        <>
            {mode === "edit" && (
                <Box
                    background="white"
                    borderRadius="default"
                    {...(directoryStyle && { className: "mb-3 mb-md-7 mx-n-5 px-5 px-md-0" })}>
                    {showTooltipCountry && (
                        <Box
                            display="flex"
                            alignX="flex-start"
                            alignY="center"
                            background="warning"
                            border="warning-1"
                            className="p-5"
                            borderRadius="top-default">
                            <Image wrapperClassName="form-confirmation-info-icon mr-3" src="images/icons/warning.svg" />

                            <Text size="7" color="text-boton">
                                <b>{i18n.get("global.warning")}</b> {i18n.get("bankSelector.warning.intermediary.bank")}
                            </Text>
                        </Box>
                    )}
                    {directoryStyle ? (
                        <Row>
                            <Col md={10} mdOffset={1}>
                                {content()}
                            </Col>
                        </Row>
                    ) : (
                        content()
                    )}
                </Box>
            )}

            {mode === "preview" && isDesktop && (
                <Box className="ticket-info-wrapper">
                    <Accordion grayBox>
                        <Accordion.Item title={titleHeader}>{renderDetailView()}</Accordion.Item>
                    </Accordion>
                </Box>
            )}
            {mode === "preview" && !isDesktop && (
                <Box className="ticket-info-wrapper">
                    <Text component="h4" color="primary" size="5" bold labelKey={titleHeader} className="mt-0 mb-4" />
                    {renderDetailView()}
                </Box>
            )}
            {mode === "view" && renderDetailView()}
        </>
    );
};

SwitfBankSelector.propTypes = {
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    handleChangeSelector: func.isRequired,
    recipientAccountCodeMapOptions: arrayOf(shape({})).isRequired,
    handleChangeText: func.isRequired,
    showIntermediaryBank: bool.isRequired,
    showSave: bool.isRequired,
    idComponent: string.isRequired,
    onSearchBank: func.isRequired,
    mode: string.isRequired,
    genericProps: shape({}).isRequired,
    setFieldValue: func.isRequired,
    onChangeIntermediary: func.isRequired,
    handleChangeCheckBox: func.isRequired,
    beneficiaryManagePermission: bool,
    idForm: string.isRequired,
    titleHeader: string,
    bankDescriptionData: shape({}),
    dispatch: func,
    countryList: arrayOf(shape({})).isRequired,
    isFetchingBank: bool,
    directoryStyle: bool,
    loadBeneficiary: bool,
    clearBeneficiary: func,
    handleChangeSwitch: func.isRequired,
    isDesktop: bool,
    value: shape({}),
};

SwitfBankSelector.defaultProps = {
    titleHeader: "",
    bankDescriptionData: {},
    dispatch: () => {},
    isFetchingBank: false,
    directoryStyle: false,
    isDesktop: false,
    loadBeneficiary: false,
    beneficiaryManagePermission: false,
    clearBeneficiary: () => {},
    value: {},
};

const mapStateToProps = (state) => ({
    bankDescriptionData: formSelectors.getBankDescriptionData(state),
    isFetchingBank: formSelectors.isFetchingBank(state),
    beneficiaryManagePermission: sessionSelectors.hasPermissions(state, ["beneficiaryManage"]),
});

export default connect(mapStateToProps)(resizableRoute(SwitfBankSelector));

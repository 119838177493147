import React from "react";
import { selectors as notificationSelectors, actions as notificationActions } from "reducers/notification";
import { connect } from "react-redux";
import { arrayOf, bool, func, number, string, shape, node } from "prop-types";
import NotificationSystem from "react-notification-system";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { isMobileNativeFunc } from "util/device";

/*
Por customizacion ver, ver: https://github.com/igorprado/react-notification-system
*/
class Notification extends React.Component {
    static propTypes = {
        clearNotifications: bool,
        currentLang: string,
        dispatch: func.isRequired,
        errors: shape({}),
        level: string,
        message: string,
        metadata: shape({
            idForm: string,
            version: number,
            enabled: bool,
            category: string,
            type: string,
            adminOption: string,
            idActivity: string,
            fieldList: arrayOf(
                shape({
                    idField: string.isRequired,
                    labelMap: shape({}).isRequired,
                    requiredErrorMap: shape({}).isRequired,
                }),
            ).isRequired,
        }),
        scopes: arrayOf(string).isRequired,
        scopeToShow: string.isRequired, // what scope this notification component must listen
        timeOut: number,
        getRightComponent: node,
        isDesktop: string,
    };

    static defaultProps = {
        clearNotifications: true,
        currentLang: undefined,
        errors: {},
        level: null,
        message: null,
        metadata: undefined,
        getRightComponent: undefined,
        timeOut: undefined,
        isDesktop: false,
    };

    constructor(props) {
        super(props);
        this.notificationRef = React.createRef();

        this.state = {
            notificationSystem: null,
        };
    }

    componentDidMount() {
        const { current } = this.notificationRef;
        this.setState({ notificationSystem: current });
    }

    componentDidUpdate() {
        const { message, scopes, scopeToShow } = this.props;

        if (message && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    removeNotificationAction = () => {
        const { dispatch, timeOut, isDesktop } = this.props;
        if ((isMobileNativeFunc() || !isDesktop) && timeOut) {
            setTimeout(() => {
                dispatch(notificationActions.removeNotification());
            }, timeOut);
            return;
        }
        dispatch(notificationActions.removeNotification());
    };

    addNotification = () => {
        const { notificationSystem } = this.state;
        const { clearNotifications, currentLang, errors, level, message, metadata, getRightComponent } = this.props;

        let allFormFieldsInfo;
        let errorFieldsKeys;
        let errorFieldsInfo;

        if (metadata) {
            allFormFieldsInfo = metadata.fieldList;
            errorFieldsKeys = Object.keys(errors);
            errorFieldsInfo = allFormFieldsInfo.filter((field) => errorFieldsKeys.includes(field.idField));
        }

        if (clearNotifications) {
            notificationSystem.clearNotifications();
        }

        notificationSystem.addNotification({
            message:
                typeof message === "object" ? (
                    message
                ) : (
                    <Box display="flex" column>
                        <Text defaultValue={message} />
                        {getRightComponent && getRightComponent()}
                    </Box>
                ),
            level,
            position: "tc",
            autoDismiss: "5",
            dismissible: true,
            children: (
                <>
                    <Box position="absolute" top="50" className="notification-icon">
                        <Image src={`images/icons/sb-${level}.svg`} />
                    </Box>

                    <div className="visually-hidden">
                        {errorFieldsInfo &&
                            errorFieldsInfo.map((field) => (
                                <div>{`${field.labelMap[currentLang]} : ${field.requiredErrorMap[currentLang]}`}</div>
                            ))}
                    </div>
                </>
            ),
        });

        // Scroll to notification
        const { current } = this.notificationRef;
        if (current) {
            const scrollableContent = document.querySelector(".scrollable-content");

            if (scrollableContent) {
                scrollableContent.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        }

        this.removeNotificationAction();
    };

    render() {
        // ignoring warning because the only way to remove all the styles from the component is to pass style as false
        return (
            <div aria-live="polite">
                <NotificationSystem
                    ref={this.notificationRef}
                    // eslint-disable-next-line react/style-prop-object
                    style={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    message: notificationSelectors.getMessage(state),
    level: notificationSelectors.getLevel(state),
    scopes: notificationSelectors.getScopes(state),
    clearNotifications: notificationSelectors.getClearNotifications(state),
    timeOut: notificationSelectors.getTimeOut(state),
    getRightComponent: notificationSelectors.getRightComponent(state),
});

export default connect(mapStateToProps)(Notification);

import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import moment from "moment";
import { bool, shape, instanceOf, func } from "prop-types";

import { actions as accountsActions } from "reducers/accounts";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";

import * as i18n from "util/i18n";

const FORM_ID = "accounts.movements.filters.yearAndMonth";

const getYearOptions = (minDate) => {
    const currentYear = moment().year();

    const yearOptions = [];
    for (let i = minDate.year(); i <= currentYear; i++) {
        yearOptions.push({
            value: i.toString(10),
            label: i.toString(10),
        });
    }
    return yearOptions;
};

class YearAndMonthFilter extends Component {
    constructor(props) {
        super(props);

        const minDate = moment(props.minDate);

        this.state = {
            minDate,
            yearOptions: getYearOptions(minDate),
        };
    }

    componentDidMount() {
        const { setFieldValue, advancedFilter } = this.props;

        if (setFieldValue && advancedFilter?.year && advancedFilter?.month) {
            setFieldValue("year", advancedFilter.year);
            setFieldValue("month", advancedFilter.month);
        }
    }

    getMonthOptions = () => {
        const {
            values: { year },
        } = this.props;
        const { minDate } = this.state;
        const today = moment();

        let minMonth = 0;
        if (year && parseInt(year, 10) === minDate.year()) {
            minMonth = minDate.month();
        }

        let maxMonth = 11;
        if (year && parseInt(year, 10) === today.year()) {
            maxMonth = today.month();
        }

        const months = i18n.getArray("global.months");
        const monthOptions = months
            .map((month, idx) => ({
                value: idx.toString(10),
                label: month,
            }))
            .filter((month, idx) => idx >= minMonth && idx <= maxMonth);

        return monthOptions;
    };

    handleChangeYear = (year) => {
        const {
            values: { month },
            setFieldValue,
        } = this.props;
        const { minDate } = this.state;
        const today = moment();

        if (
            year &&
            ((parseInt(year, 10) === minDate.year() && month < minDate.month()) ||
                (parseInt(year, 10) === today.year() && month > today.month()))
        ) {
            setFieldValue("month", "");
        }
    };

    render() {
        const { isSubmitting } = this.props;
        const { yearOptions } = this.state;

        return (
            <Form style={{ display: "contents" }}>
                <Col xs={6} md={3}>
                    <Field
                        component={Selector}
                        options={yearOptions}
                        idForm={FORM_ID}
                        name="year"
                        placeholder=""
                        handleChange={this.handleChangeYear}
                    />
                </Col>
                <Col xs={6} md={3}>
                    <Field
                        component={Selector}
                        options={this.getMonthOptions()}
                        idForm={FORM_ID}
                        name="month"
                        placeholder=""
                    />
                </Col>
                <Col xs={12} md={2} className="pr-md-7 pt-3 pt-md-0 full-height">
                    <Box display="flex" alignY="flex-end" fullHeight>
                        <Button
                            bsStyle="primary"
                            className="mt-auto"
                            label="product.filters.filter"
                            loading={isSubmitting}
                            type="submit"
                            block
                        />
                    </Box>
                </Col>
            </Form>
        );
    }
}

YearAndMonthFilter.propTypes = {
    isSubmitting: bool.isRequired,
    values: shape({}).isRequired,
    minDate: instanceOf(Date).isRequired,
    setFieldValue: func.isRequired,
    advancedFilter: shape({}),
};

YearAndMonthFilter.defaultProps = {
    advancedFilter: null,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => {
        const today = moment();
        return {
            year: today.year().toString(10),
            month: today.month().toString(10),
        };
    },
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;
        const { year, month } = filters;

        const partial = moment({ year: parseInt(year, 10), month: parseInt(month, 10), day: 1 });

        const today = moment().startOf("day");

        const dateFrom = moment(partial).startOf("month");
        let dateTo = moment(partial).endOf("month");
        if (dateTo.isAfter(today)) {
            dateTo = today;
        }

        const newFilters = {
            ...filters,
            advanced: true,
            dateFrom: dateFrom.toDate(),
            dateTo: dateTo.toDate(),
            filterType: "yearAndMonth",
        };

        if (isDesktop) {
            dispatch(accountsActions.details(productId, { ...newFilters }, formikBag));
        } else {
            dispatch(
                accountsActions.setMobileFilter({
                    filter: "yearAndMonth",
                    ...newFilters,
                    year,
                    month,
                }),
            );
            dispatch(push(`/accounts/${productId}/filters/results`));
        }
    },
})(YearAndMonthFilter);

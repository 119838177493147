import { format } from "date-fns";
import { bool, func, instanceOf, number as numberType, oneOfType, string } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";

import ListItem from "pages/_components/listItem/ListItem";

class DepositsListItem extends Component {
    static propTypes = {
        amountLabel: string.isRequired,
        completeFavorites: bool,
        currency: string,
        dispatch: func.isRequired,
        dueDate: instanceOf(Date),
        hideAmountLabel: bool,
        idProduct: string,
        label: string,
        number: string.isRequired,
        productAlias: string,
        productType: string.isRequired,
        rate: string,
        setFavoriteDeposit: func,
        showLink: bool,
        showStar: bool,
        totalBalance: oneOfType([string, numberType]),
    };

    static defaultProps = {
        completeFavorites: false,
        currency: null,
        dueDate: null,
        hideAmountLabel: false,
        idProduct: null,
        label: "",
        productAlias: null,
        rate: "",
        setFavoriteDeposit: () => {},
        showLink: true,
        showStar: false,
        totalBalance: null,
    };

    // onClickItem = (dueDateStr, totalBalance, currency) => {
    //     const { dispatch } = this.props;
    //     dispatch(productActions.setSelectProduct({ dueDateStr, totalBalance, currency }));
    // };

    render() {
        const {
            productAlias,
            label,
            number,
            amountLabel = i18nUtils.get("deposits.availableBalance"),
            idProduct,
            hideAmountLabel,
            showLink,
            dueDate,
            rate,
            setFavoriteDeposit,
            completeFavorites,
            totalBalance,
            currency,
            showStar,
            ...props
        } = this.props;
        const productName = productAlias || `${i18nUtils.get("deposit.alias.list.label")} ${number}`;
        const dueDateStr = dueDate ? format(dueDate, "DD/MM/YYYY") : null;
        const reference = dueDateStr ? `${i18nUtils.get("deposit.dueDate.list.label")} ${dueDateStr}` : null;
        const productTypeTitle = i18nUtils.get("desktop.widgets.deposit");

        return (
            <ListItem
                {...props}
                idProduct={idProduct}
                productTypeTitle={productTypeTitle}
                name={productName}
                reference={reference}
                amount={totalBalance}
                amountLabel={!hideAmountLabel && amountLabel}
                path={showLink ? `/deposits/${idProduct}` : null}
                rate={rate}
                showProductIcon
                showStar={showStar}
                setFavoriteAccount={setFavoriteDeposit}
                completeFavorites={completeFavorites}
                currency={currency}
                // onClick={() => {
                //     this.onClickItem(dueDateStr, totalBalance, currency);
                // }}
            />
        );
    }
}

export default DepositsListItem;

import { takeLatest, call, put, select } from "redux-saga/effects";

import { push } from "react-router-redux";

import { types, selectors } from "reducers/recoveryPassword";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";

import * as recovery from "middleware/recoveryPassword";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const sagas = [
    takeLatest(types.RECOVERY_PASS_BACK_TO_STEP_1, handleGoToStep1),
    takeLatest(types.RECOVERY_PASS_STEP1_REQUEST, handleRecoveryPassStep1),
    takeLatest(types.RECOVERY_PASS_STEP2_REQUEST, handleRecoveryPassStep2),
    takeLatest(types.RECOVERY_PASS_STEP3_REQUEST, handleRecoveryPassStep3),
    takeLatest(types.RECOVERY_PASS_STEP4_REQUEST, handleRecoveryPassStep4),
];

export default sagas;

function* handleGoToStep1() {
    yield put(push("/recoveryPassword/step1"));
}

function* handleRecoveryPassStep1({ username, notificationType, formikBag }) {
    const response = yield call(recovery.recoveryPassStep1, username, notificationType);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        let notificationScope = "recoveryPassword";
        switch (response.data.code) {
            case "COR020W":
                break;
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
                // exchangeToken expired, restart flow (esto no pasa)
                yield put({ type: types.CLEAN });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        yield put({ type: types.RECOVERY_PASS_STEP1_SUCCESS, username, notificationType, preData: response.data.data });
        yield put(push("/recoveryPassword/step2"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep2({ documentCountry, documentType, documentNumber, formikBag }) {
    const username = yield select(selectors.getUsername);
    const notificationType = yield select(selectors.getNotificationType);
    const response = yield call(
        recovery.recoveryPassStep2,
        username,
        notificationType,
        documentCountry,
        documentType,
        documentNumber,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        let notificationScope = "recoveryPassword";
        switch (response.data.code) {
            case "COR020W":
                break;
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
                // exchangeToken expired, restart flow (esto no pasa)
                yield put({ type: types.CLEAN });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        const { _exchangeToken, showCaptcha, contact } = response.data.data;

        yield put({
            type: types.RECOVERY_PASS_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
            showCaptcha,
            contact,
            idTransaction: response.data.idTransaction,
        });
        yield put(push("/recoveryPassword/step3"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep3({ verificationCode, captcha, exchangeToken, formikBag }) {
    const response = yield call(recovery.recoveryPassStep3, verificationCode, captcha, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        let notificationScope = "recoveryPassword";
        switch (response.data.code) {
            case "COR020W":
                if (response.data.data.captcha) {
                    yield put({ type: types.RECOVERY_PASS_STEP3_FAILURE_REQUIRE_CAPTCHA });
                }
                break;
            case "API523W":
            case "API527W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            case "API525W":
            case "API526W":
                yield put({ type: types.RECOVERY_PASS_STEP3_FAILURE_REQUIRE_CAPTCHA });
                break;
            case "API528W":
            case "API529W":
                break;
            default:
                // exchangeToken expired, restart flow (no pasa esto)
                yield put({ type: types.CLEAN });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        const { _exchangeToken } = response.data.data;

        yield put({ type: types.RECOVERY_PASS_STEP3_SUCCESS, exchangeToken: _exchangeToken });
        yield put(push("/recoveryPassword/step4"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep4({ newPassword, newPasswordRepeat, exchangeToken, formikBag }) {
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "session.recoverPassword.step4");
    const response = yield call(
        recovery.recoveryPassStep4,
        newPassword,
        newPasswordRepeat,
        exchangeToken,
        fingerPrintToken,
    );
    yield put(notificationActions.removeNotification());

    if (response.type === "W") {
        let notificationScope = "recoveryPassword";
        let notificationErrorMessage = response.data.message;
        switch (response.data.code) {
            case "COR048W":
                notificationErrorMessage = response.data.data._newPassword;
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                break;
            case "COR020W":
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                break;
            default:
                // exchangeToken expired, restart flow (no pasa esto)
                yield put({ type: types.CLEAN });
                yield put(push("/recoveryPassword/step1"));
                notificationScope = "externalLayout";
        }

        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(notificationErrorMessage, "error", [notificationScope]));
        }
    } else {
        yield put({ type: types.RECOVERY_PASS_STEP4_SUCCESS });
        yield put(push("/loginStep1"));

        yield put(
            notificationActions.showNotification(i18n.get("recoveryPassword.step4.confirmation"), "success", [
                "externalLayout",
            ]),
        );
    }

    formikBag.setSubmitting(false);
}

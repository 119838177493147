import classNames from "classnames";
import { Field } from "formik";
import Box from "pages/_components/Box";
import { routerActions, replace } from "react-router-redux";
import moment from "moment";
import Disclaimer from "pages/_components/Disclaimer";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { AMOUNT_TYPE } from "pages/forms/_components/_fields/ReadText";
import { bool, func, shape, string } from "prop-types";
// import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";

import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { validationAccount, validationText } from "util/validationSchemaUtil";
import * as Yup from "yup";
import { calculateShortLabel } from "util/account";
import { capitalizeFirstLetter } from "util/string";
import { actions as notificationActions } from "reducers/notification";
import FormTransition from "../_components/FormTransition";
import { usePreferredBranchLabel, useCheckBookTypesLabel } from "./hooks/TransferCustomHooks";
import { useDebitAccountOrigin, useGenericProps } from "./hooks/TransferInternalHooks";
import { PDFCheckbookRequestError, PDFCheckbookRequestField, PDFTextField } from "./PDFTicket";

const ID_FORM = "requests.checkBooks";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "forms.requests.checkBooks.tittle";

const RequestsCheckBooks = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        isDesktop,
        loggedUser,
        transaction,
        activeEnvironment,
        errorsForm,
    } = props;

    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    // eslint-disable-next-line no-unused-vars
    const [debitAccountOrigin] = useDebitAccountOrigin(location);
    const [preferredBranch] = usePreferredBranchLabel(preDataForm?.preferredBranchList);
    const [preferredBranchEdit, setPreferredBranchEdit] = useState(preferredBranch);
    const [checkBookTypeList] = useCheckBookTypesLabel(preDataForm?.checkbookTypesList);

    const deliveryTime = config.getInteger("requests.checkBooks.deliveryTime");
    const deliveryTimeText = i18n.get(`forms.requests.checkBooks.deliveryText`, null, { DELIVERY_TIME: deliveryTime });
    const CHECKBOOK_WITH_COPY_COST = config.get("requests.checkBooks.checkBookWithCopyCost");
    const CHECKBOOK_WITHOUT_COPY_COST = config.get("requests.checkBooks.checkBookWithoutCopyCost");
    const CHECKS_PER_CHECKBOOKS = config.getInteger("requests.checkBooks.checksPerCheckbooks");

    const internationalBranchList = [
        { id: "572", label: i18n.get("forms.requests.checkBooks.preferredBranch.international.option") },
        { id: "0572", label: i18n.get("forms.requests.checkBooks.preferredBranch.anotherCountry.option") },
    ];

    useEffect(() => {
        if (mode === "edit") {
            if (!preDataForm?.debitAccountList) {
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
            }
        }
    }, [dispatch, mode, location]);

    useEffect(() => {
        const { bankType } = loggedUser;
        if (preferredBranchEdit.length === 0) {
            if (bankType === "local") {
                setPreferredBranchEdit(preferredBranch.filter((branch) => branch.id !== "572"));
            } else {
                setPreferredBranchEdit(internationalBranchList);
            }
        }
    }, [preferredBranch]);

    const setClientTypeValue = (setFieldValue) => {
        const { bankType } = loggedUser;
        setFieldValue("clientType", bankType);
    };

    // eslint-disable-next-line no-unused-vars
    const preData = preDataForm || {
        debitAccountList: [],
    };

    const rejectRequestIntent = () => {
        dispatch(
            notificationActions.showNotification(i18n.get("forms.requests.account.euroexclude"), "error", ["desktop"]),
        );
        dispatch(routerActions.replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
    };

    const selectorOptions = () => {
        let finalAccountList;
        const accountList = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
                disabled: acc.isDisabled,
            })),
        };
        finalAccountList = accountList;
        if (accountList?.options?.length === 1) {
            if (accountList.options[0].currency === "EUR") {
                rejectRequestIntent();
            }
        } else {
            // limpiamos las cuentas de euro
            const euroLess = accountList.options?.filter((preacc) => preacc.currency !== "EUR");
            finalAccountList = { options: euroLess };
        }
        // sino encontramos alguna activa, rechazamos
        if (finalAccountList?.options?.filter((account) => !account.disabled).length === 0) {
            rejectRequestIntent();
        }
        return finalAccountList;
    };

    const validationSchema = () =>
        Yup.object().shape({
            debitAccount: validationAccount(null, [...(preDataForm?.debitAccountList || [])]),
            checkBookType: validationText(),
            preferredBranch: validationText(),
        });

    const calculateTotalCost = (checkBookTypeId, numberOfCheckbooks, setFieldValue) => {
        if (checkBookTypeId) {
            let costValue = 0;
            switch (checkBookTypeId) {
                case "CSSC":
                    costValue = CHECKBOOK_WITHOUT_COPY_COST * numberOfCheckbooks;
                    break;
                case "CSCC":
                    costValue = CHECKBOOK_WITH_COPY_COST * numberOfCheckbooks;
                    break;
                default:
                    break;
            }
            setFieldValue("totalCost", { currency: "USD", quantity: Number(costValue.toFixed(2)) });
        }
    };

    const handleTotalCost = (e, fieldName, values, setFieldValue) => {
        if (values === null && (fieldName !== "checkBookType" || fieldName !== "numberOfCheckbooks")) {
            return;
        }

        let checkBookTypeId = null;

        if (values?.checkBookType) {
            checkBookTypeId = values?.checkBookType[0]?.id;
        }

        let numberOfCheckbooks = values?.numberOfCheckbooks;
        if (fieldName === "checkBookType") {
            checkBookTypeId = e[0]?.id;
        } else if (fieldName === "numberOfCheckbooks") {
            numberOfCheckbooks = e;
        }
        calculateTotalCost(checkBookTypeId, numberOfCheckbooks, setFieldValue);
    };

    const handleOnCHangeGeneric = (e, setFieldValue, fieldName, values = null) => {
        setFieldValue(fieldName, e);
        setClientTypeValue(setFieldValue);
        handleTotalCost(e, fieldName, values, setFieldValue);
    };

    const cancelAction = () => {
        let pathnameValue = "/desktop";
        if (debitAccountOrigin) {
            pathnameValue = `/accounts/${debitAccountOrigin}`;
        }
        dispatch(
            replace({
                pathname: pathnameValue,
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const renderTicket = (values) => {
        const ticketFinished = transaction?.idTransactionStatus === "FINISHED";
        return (
            <>
                {ticketFinished ? (
                    <PDFCheckbookRequestField
                        idForm={ID_FORM}
                        value={`${activeEnvironment.name} - CTA. ${values?.debitAccountData?.number}`}
                        label={i18n.get("form.requests.checkBooks")}
                    />
                ) : (
                    <PDFCheckbookRequestError
                        idForm={ID_FORM}
                        value={`${activeEnvironment.name} - CTA. ${values?.debitAccountData?.number}`}
                        label={i18n.get("form.requests.checkBooks")}
                    />
                )}

                {ticketFinished && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={values?.requestCode}
                        label={i18n.get("forms.requests.checkBooks.requestCode.label")}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={moment(transaction?.creationDateTime).format("DD/MM/YYYY")}
                    label={i18n.get(`${ID_FORM}.dateField.label`)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get("form.requests.checkBooks")}
                    label={i18n.get("forms.requests.checkBooks.typeOperation.label")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={calculateShortLabel(values?.debitAccountData?.productType, values?.debitAccountData?.number)}
                    name="debitAccountPreview"
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={capitalizeFirstLetter((values?.checkBookType[0].label).toLowerCase())}
                    label={i18n.get("forms.requests.checkBooks.checkBookType.label")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.numberOfCheckbooks}
                    label={i18n.get(`${ID_FORM}.numberOfCheckbooks.label`)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.numberOfChecks}
                    label={i18n.get(`${ID_FORM}.numberOfChecks.label`)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.totalCost?.quantity}
                    label={i18n.get(`${ID_FORM}.totalCost.label`)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.preferredBranch[0].label}
                    label={i18n.get("forms.requests.checkBooks.preferredBranchPreview.label")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={deliveryTimeText}
                    label={i18n.get(`${ID_FORM}.deliveryTime.label`)}
                />
            </>
        );
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors, setErrors) => {
        if (Object.keys(errorsForm || {}).length > 0 && Object.keys(errors || {}).length === 0) {
            setErrors(errorsForm);
        }

        if (!values?.numberOfChecks) {
            setFieldValue("numberOfChecks", CHECKS_PER_CHECKBOOKS);
        }

        const radioOptions = [
            {
                id: "1",
                label: "1",
            },
            {
                id: "2",
                label: "2",
            },
        ];

        const dataAccount = values?.debitAccountData ? `CTA. ${values?.debitAccountData?.number}` : "";
        const accountInput = (idField) => (
            <>
                <Field
                    {...genericProps}
                    labelNoMarginTop
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={selectorOptions()}
                    key="debitAccount"
                    name="debitAccount"
                    renderAs="combo"
                    value={values?.debitAccount || debitAccountOrigin}
                    idField={idField}
                    isFocused
                    autoFocus
                />
            </>
        );

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Row gapY={isDesktop ? "5" : "3"}>
                            <Col xs={12} md={6}>
                                <Box display="flex" column gap={isDesktop ? "5" : "3"}>
                                    <Box
                                        display="flex"
                                        column
                                        background="component-background"
                                        borderRadius="default"
                                        className="mt-3 mt-md-0 px-5 pt-5 pb-9 px-md-9 pt-md-9 pb-md-12 mx-n-5">
                                        {accountInput("debitAccount")}
                                    </Box>

                                    <Box
                                        display="flex"
                                        column
                                        background="component-background"
                                        borderRadius="default"
                                        className="px-5 pt-5 pb-5 px-md-9 pt-md-5 pb-md-5 mx-n-5"
                                        gap="2">
                                        <Text
                                            component="h2"
                                            className="m-0"
                                            color="primary"
                                            size="5"
                                            bold
                                            labelKey="forms.requestsCheckBooks.checkbooksName.info.label"
                                        />
                                        <Box display="flex" column>
                                            <Text component="label" className="m-0" color="heading-color" size="5" bold>
                                                {activeEnvironment.name}
                                            </Text>
                                            <Text
                                                component="label"
                                                className="m-0"
                                                color="text-color"
                                                size={isDesktop ? "7" : "6"}>
                                                {dataAccount}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box display="flex" column gap={isDesktop ? "5" : "3"} fullHeight>
                                    <Box
                                        display="flex"
                                        column
                                        background="component-background"
                                        borderRadius="default"
                                        className="px-5 pt-5 pb-9 px-md-9 pt-md-9 pb-md-12 mx-n-5">
                                        <Field
                                            {...genericProps}
                                            idForm={ID_FORM}
                                            component={FormFieldsComponents.Selector}
                                            key="checkBookType"
                                            name="checkBookType"
                                            idField="checkBookType"
                                            labelIdField="react-select-checkBookType-input"
                                            optionList={checkBookTypeList || []}
                                            customPlaceholder={i18n.get("forms.placeholder.select")}
                                            renderAs="combo"
                                            labelNoMarginTop
                                            onChange={(e) => {
                                                handleOnCHangeGeneric(e, setFieldValue, "checkBookType", values);
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        display="flex"
                                        column
                                        background="component-background"
                                        borderRadius="default"
                                        className="px-5 pt-5 pb-5 px-md-9 pt-md-5 pb-md-5 mx-n-5"
                                        fullHeight>
                                        <Box display="flex" column gap="4">
                                            <FieldLabel
                                                labelKey="forms.requests.checkBooks.numberOfCheckbooks.label"
                                                labelClassName="color-heading mb-0"
                                                dataLabelSpecialMb={false}
                                            />
                                            <Field
                                                {...genericProps}
                                                labelNoMarginTop
                                                component={FormFieldsComponents.RadioButtonGroupCustom}
                                                key="numberOfCheckbooks"
                                                name="numberOfCheckbooks"
                                                className={classNames("d-flex", { "flex-row": true })}
                                                radioLabelClassName="mr-12"
                                                inLineControl={false}
                                                options={radioOptions}
                                                value={values.numberOfCheckbooks || "1"}
                                                onChange={(e) => {
                                                    handleOnCHangeGeneric(
                                                        e,
                                                        setFieldValue,
                                                        "numberOfCheckbooks",
                                                        values,
                                                    );
                                                    setFieldValue("numberOfChecks", e * CHECKS_PER_CHECKBOOKS);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box
                                    display="flex"
                                    column
                                    background="component-background"
                                    borderRadius="default"
                                    className="px-5 pt-5 pb-9 px-md-9 pt-md-5 pb-md-12 mx-n-5"
                                    gap="6"
                                    fullHeight>
                                    <Text
                                        component="h2"
                                        className="m-0"
                                        color="primary"
                                        size="5"
                                        bold
                                        labelKey="forms.requests.checkBooks.preferredBranch.info.label"
                                    />
                                    <Field
                                        {...genericProps}
                                        idForm={ID_FORM}
                                        component={FormFieldsComponents.Selector}
                                        key="preferredBranch"
                                        name="preferredBranch"
                                        idField="preferredBranch"
                                        labelIdField="react-select-preferredBranch-input"
                                        optionList={preferredBranchEdit || []}
                                        customPlaceholder={i18n.get("forms.placeholder.select")}
                                        renderAs="combo"
                                        labelNoMarginTop
                                        onChange={(e) => {
                                            handleOnCHangeGeneric(e, setFieldValue, "preferredBranch");
                                        }}
                                    />
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box display="flex" column gap={isDesktop ? "5" : "3"}>
                                    <Box
                                        component="ul"
                                        display="flex"
                                        column
                                        background="secondary-background-color"
                                        borderRadius="default"
                                        className="px-5 pt-5 pb-5 px-md-9 pt-md-7 pb-md-7"
                                        gap="2">
                                        <Box component="li" display="flex" gap="4">
                                            <Box display="flex" className="list-styled">
                                                <Text
                                                    color="heading-color"
                                                    size="6"
                                                    labelKey="forms.requests.checkBooks.pocket.noCopy.label"
                                                />
                                            </Box>
                                            <FormattedAmount
                                                quantity={CHECKBOOK_WITHOUT_COPY_COST}
                                                currency="USD"
                                                size="6"
                                                color="heading-color"
                                                bold
                                            />
                                        </Box>
                                        <Box component="li" display="flex" gap="4">
                                            <Box display="flex" className="list-styled">
                                                <Text
                                                    color="heading-color"
                                                    size="6"
                                                    labelKey="forms.requests.checkBooks.pocket.copy.label"
                                                />
                                            </Box>
                                            <FormattedAmount
                                                quantity={CHECKBOOK_WITH_COPY_COST}
                                                currency="USD"
                                                size="6"
                                                color="heading-color"
                                                bold
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignX="between"
                                        alignY="center"
                                        background="heading-color"
                                        borderRadius="default"
                                        className="px-5 pt-5 pb-5 px-md-9 pt-md-7 pb-md-7"
                                        gap="2">
                                        <Text
                                            component="label"
                                            className="m-0"
                                            color="text-inverse-color"
                                            size="5"
                                            lineHeight125
                                            regular
                                            labelKey="requests.checkBooks.totalCost.label"
                                        />
                                        <FormattedAmount
                                            quantity={values?.totalCost?.quantity || 0}
                                            currency="USD"
                                            size="big"
                                            color="text-inverse-color"
                                            bold
                                        />
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box
                            display="flex"
                            column
                            alignY="center"
                            fullWidth
                            className={classNames("mb-9 pb-4", {
                                "pt-2 pt-md-3": mode === "preview",
                                "pt-4": mode === "view",
                            })}
                            {...(mode === "view" &&
                                transaction?.idTransactionStatus === "FINISHED" && {
                                    background: "primary-static-color",
                                })}
                            gap={mode === "view" ? "2" : "0"}>
                            <Text
                                color={
                                    transaction?.idTransactionStatus === "FINISHED"
                                        ? "text-inverse-color"
                                        : "heading-color"
                                }
                                labelKey="form.requests.checkBooks"
                            />
                            {isDesktop && mode !== "view" ? (
                                <Text
                                    {...(mode === "view" && { className: "px-7" })}
                                    size={mode === "view" ? "3" : "2"}
                                    color={
                                        transaction?.idTransactionStatus === "FINISHED"
                                            ? "text-inverse-color"
                                            : "heading-color"
                                    }
                                    bold
                                    align="center">
                                    {`${activeEnvironment.name} - ${dataAccount}`}
                                </Text>
                            ) : (
                                <Box display="flex" column alignY="center">
                                    <Text
                                        size="2"
                                        color={
                                            transaction?.idTransactionStatus === "FINISHED"
                                                ? "text-inverse-color"
                                                : "heading-color"
                                        }
                                        bold
                                        align="center">
                                        {activeEnvironment.name}
                                    </Text>
                                    <Text
                                        size="3"
                                        color={
                                            transaction?.idTransactionStatus === "FINISHED"
                                                ? "text-inverse-color"
                                                : "heading-color"
                                        }
                                        bold
                                        align="center">
                                        {dataAccount}
                                    </Text>
                                </Box>
                            )}
                        </Box>
                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {mode === "view" && transaction?.idTransactionStatus === "FINISHED" && (
                                <>
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={values?.requestCode}
                                        label="forms.requests.checkBooks.requestCode.label"
                                    />
                                </>
                            )}
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={moment().format("DD/MM/YYYY")}
                                label={`${ID_FORM}.dateField.label`}
                            />
                            {mode === "view" && (
                                <>
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={i18n.get("form.requests.checkBooks")}
                                        label="forms.requests.checkBooks.typeOperation.label"
                                    />
                                </>
                            )}
                            {accountInput("debitAccountPreview")}
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    values?.checkBookType
                                        ? capitalizeFirstLetter((values?.checkBookType[0].label).toLowerCase())
                                        : ""
                                }
                                label="forms.requests.checkBooks.checkBookType.label"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.numberOfCheckbooks}
                                label={`${ID_FORM}.numberOfCheckbooks.label`}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.numberOfChecks}
                                label={`${ID_FORM}.numberOfChecks.label`}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                label={`${ID_FORM}.totalCost.label`}
                                amount={{ currency: "USD", quantity: values?.totalCost?.quantity }}
                                type={AMOUNT_TYPE}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.preferredBranch ? values?.preferredBranch[0].label : ""}
                                label="forms.requests.checkBooks.preferredBranchPreview.label"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={deliveryTimeText}
                                label={`${ID_FORM}.deliveryTime.label`}
                            />
                            {mode === "view" && (
                                <Box display="flex" className="mb-7">
                                    <Disclaimer
                                        labelKey={
                                            values?.preferredBranch[0].id === "0572"
                                                ? "forms.requests.checkBooks.ticket.disclaimer.text.2"
                                                : "forms.requests.checkBooks.ticket.disclaimer.text.1"
                                        }
                                        textColor="primary-hover-color"
                                        textSize={isDesktop ? "small" : "7"}
                                        padding="p-0"
                                    />
                                </Box>
                            )}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        showSchedulerMessage: false,
        topDisclaimer: "forms.requestsCheckBooks.preview.disclaimer.text",
        topDisclaimerTextSize: "6",
        topDisclaimerTextColor: "text-boton",
        showFilterChips: false,
        cancelAction,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    transaction: formSelectors.getTransaction(state),
    loggedUser: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    errorsForm: formSelectors.getErrors(state),
});

RequestsCheckBooks.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    loggedUser: shape({
        bankType: string,
    }),
    activeEnvironment: shape({
        name: string,
    }).isRequired,
    transaction: shape({}),
    errorsForm: shape({}),
};
RequestsCheckBooks.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    location: {},
    fromTransaction: false,
    loggedUser: null,
    transaction: { idTransactionStatus: "FINISHED" },
    errorsForm: {},
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(RequestsCheckBooks));

import * as API from "middleware/api";

export const activateKuara = (accountId) =>
    API.executeWithAccessToken("/enroll.kuara.send", {
        accountId,
    });

export const desactivateKuara = () => API.executeWithAccessToken("/setting.kuara.deleteAccount");

export const updateKuara = (accountId) =>
    API.executeWithAccessToken("/setting.kuara.changeAccount", {
        accountId,
    });

export const readKuaraUser = () => API.executeWithAccessToken("/setting.kuara.pre", {});

export const validateKuaraUser = () => API.executeWithAccessToken("/enroll.kuara.validate", {});

export const listBeneficiariesCommerceKuara = (countryCode, start, end) =>
    API.executeWithAccessToken("/list.beneficiaries.commerce.wally", {
        countryCode,
        start,
        end,
    });

/*
export const listBeneficiariesPeopleKuara = (phoneNumbers) =>
    API.executeWithAccessToken("/list.affiliations.phone.wally", {
        phoneNumbers,
    }); */

export const validateNumbersKuara = (phoneNumbers) =>
    API.executeWithAccessToken("/transfer.kuara.validateNumber", {
        phoneNumbers,
    });

export const favoriteKuara = (phoneNumber, type, isFavorite) =>
    API.executeWithAccessToken("/update.beneficiary.wally.favorite", {
        phoneNumber,
        type,
        isFavorite: !isFavorite,
    });

export const listMovementsKuara = () => API.executeWithAccessToken("/movements.kuara.list", {});

export const listMovementsPendingKuara = () => API.executeWithAccessToken("/movements.kuara.listPending", {});
export const listAccountKuara = () => API.executeWithAccessToken("/enroll.kuara.pre", {});
export const deleteMovementPendingKuara = (movement) =>
    API.executeWithAccessToken("/transfers.reject.wally.send", { ...movement });

export const getPendingTransactionsKuaraQuantity = () =>
    API.executeWithAccessToken("/transactionsKuara.get.pending.quantity", {});

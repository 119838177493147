import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace } from "react-router-redux";

// Comentado en linea 144
// import { NO_TRANSITION } from "constants.js";
import * as accountsMiddleware from "middleware/accounts";
import { types, selectors, actions } from "reducers/accounts";
import { actions as notificationActions } from "reducers/notification";
import {
    downloadPdf,
    downloadXls,
    downloadCSVFile,
    CONTENT_TYPE_PDF,
    CONTENT_TYPE_XLS,
    downloadMobileFile,
} from "util/download";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import { unifyListParams } from "util/account";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";
import { openLink } from "util/browser";

const sagas = [
    takeLatest(types.ACCOUNT_READ_REQUEST, accountRead),
    takeLatest(types.ACCOUNT_DETAILS_REQUEST, accountDetails),
    takeLatest(types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    takeLatest(types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovements),
    takeLatest(types.ACCOUNT_MOVEMENT_DETAILS_REQUEST, movementDetails),
    takeLatest(types.EDIT_MOVEMENT_NOTE_REQUEST, editMovementNote),
    takeLatest(types.LIST_ACCOUNT_STATEMENTS_REQUEST, listStatements),
    takeLatest(types.LIST_ACCOUNTS_REQUEST, listAccountsRequest),
    takeLatest(types.LIST_ACCOUNTS_WALLY_REQUEST, listAccountsWallyRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.DOWNLOAD_MOVEMENTS_BLOCKED_REQUEST, downloadMovementsBlocked),
    takeLatest(types.ACCOUNT_FETCH_MOVEMENTS_REQUEST, fetchMovements),
    takeLatest(types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_REQUEST, getMovementsBlocked),
    takeLatest(types.GET_TRACKING_TRANSFER_URL_REQUEST, getTrackingUrlTransfer),
];

export default sagas;

function* accountDetails({ idSelectedAccount, formikBag }) {
    const filters = yield select(selectors.getFilters);
    const { advanced } = filters;
    const response = yield call(accountsMiddleware.listMovements, idSelectedAccount, filters);

    if (response) {
        if (response.type === "W" && formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.status === 200) {
            yield put({
                type: types.SET_IS_ADVANCED,
                isAdvanced: advanced,
            });
            yield put({
                type: types.ACCOUNT_DETAILS_SUCCESS,
                ...unifyListParams(response),
            });
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* accountRead({ idAccount }) {
    const response = yield call(accountsMiddleware.readAccount, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_READ_SUCCESS,
            account: response.data.data ? response.data.data.account : response.data,
            totalFavorites: response.data.data.totalFavorites,
        });
    }
}

function* downloadStatement({ idAccount, idStatement }) {
    const response = yield call(accountsMiddleware.downloadStatement, idAccount, idStatement);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        downloadPdf(fileName, content);

        yield put({
            type: types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS,
        });
    }
}

function* editMovementNote({ accountId, movementId, note, onFinish }) {
    const response = yield call(accountsMiddleware.editMovementNote, accountId, movementId, note);

    if (response && response.status === 200) {
        const confirmationMessage = i18n.get("accounts.movement.detail.noteSaved");
        yield put(actions.editMovementNoteSuccess(note));
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["movementDetail"]));
    } else {
        const errorMessage = i18n.get("accounts.movement.detail.noteUnsaved");

        yield put(notificationActions.showNotification(errorMessage, "error", ["movementDetail"]));
    }

    onFinish();
}

function* fetchMovements({ accountId, filters }) {
    const response = yield call(accountsMiddleware.listMovements, accountId, filters);
    const { advanced } = filters;
    if (response && response.status === 200) {
        yield put({
            type: types.SET_IS_ADVANCED,
            isAdvanced: advanced,
        });
        yield put({
            type: types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS,
            ...unifyListParams(response),
        });
    }
}

function* fetchMoreMovements({ accountId, filters }) {
    const response = yield call(accountsMiddleware.listMovements, accountId, filters);
    const { advanced } = filters;
    if (response && response.status === 200) {
        yield put({
            type: types.SET_IS_ADVANCED,
            isAdvanced: advanced,
        });
        yield put({
            type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS,
            ...unifyListParams(response),
        });
    }
}

function* listAccountsRequest({ currencyFilterList = null }) {
    const response = yield call(accountsMiddleware.listAccounts, currencyFilterList);

    if (response && response.status === 200) {
        // Comentado en linea 152
        // const { accounts } = response.data.data;

        yield put({
            type: types.LIST_ACCOUNTS_SUCCESS,
            ...response.data.data,
        });

        // if (accounts.length === 1) {
        //     yield put(
        //         replace({
        //             pathname: `/accounts/${accounts[0].idProduct}`,
        //             state: { transition: NO_TRANSITION },
        //         }),
        //     );
        // }
    }
}

function* listStatements({ idAccount }) {
    const response = yield call(accountsMiddleware.listStatements, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNT_STATEMENTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* movementDetails({ idAccount, idStatement }) {
    const response = yield call(accountsMiddleware.movementDetails, idAccount, idStatement);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadMovements({ idAccount, format, onFinish }) {
    const filters = yield select(selectors.getFilters);
    const { type, data } = yield call(accountsMiddleware.downloadMovements, idAccount, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
        const { email } = data.data;
        if (onFinish) {
            onFinish();
        }

        if (email) {
            yield put(
                notificationActions.showNotification(
                    i18n.get("account.corporate.download.info.success", null, {
                        EMAIL: email,
                    }),
                    "success",
                    ["account/details"],
                ),
            );
            return;
        }

        const { content, fileName, contentType } = data.data;

        if (isMobileNativeFunc()) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName, contentType);
            yield;
            return;
        }

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else if (format === "csv") {
            downloadCSVFile(fileName, content);
        } else {
            yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
            );
        }
    }
}

function* getMovementsBlocked({ idAccount }) {
    const response = yield call(accountsMiddleware.listBlockStatements, idAccount);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_FAILURE });
        } else {
            yield put({
                type: types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_SUCCESS,
                movementBlockedList: response?.data?.data?.statements || [],
            });
        }
    }
}

function* downloadMovementsBlocked({ idAccount, format, onFinishDownload }) {
    const { type, data } = yield call(accountsMiddleware.downloadBlockMovements, idAccount, format.toLowerCase());

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
        yield put(replace("/desktop"));
        yield put({ type: types.DOWNLOAD_MOVEMENTS_BLOCKED_FAILURE });
    } else {
        yield put({
            type: types.DOWNLOAD_MOVEMENTS_BLOCKED_SUCCESS,
        });
        if (onFinishDownload) {
            onFinishDownload();
        }
        if (!data?.data) {
            yield;
            return;
        }
        const { content, fileName, contentType = "" } = data.data;
        if (isMobileNativeFunc()) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName, contentType);
            yield;
            return;
        }
        if (format === CONTENT_TYPE_PDF) {
            downloadPdf(fileName, content);
        }
        if (format === CONTENT_TYPE_XLS) {
            downloadXls(fileName, content);
        }
    }
}

function* listAccountsWallyRequest() {
    const response = yield call(accountsMiddleware.listAccountsWally);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNTS_WALLY_SUCCESS,
            accountsForWally: response?.data?.data?.accounts,
        });
    } else {
        yield put({
            type: types.LIST_ACCOUNTS_WALLY_FAILURE,
        });
    }
}

function* showGenericErrorTrackingUrl() {
    yield put(
        notificationActions.showNotification(
            i18n.get("transfer.foreign.tracking.getUrl.error.message"),
            "error",
            ["account/details", "InternationalTransferTracking"],
            false,
        ),
    );
    yield put({
        type: types.GET_TRACKING_TRANSFER_URL_FAILURE,
    });
}

function* getTrackingUrlTransfer({ accountId, transactionRef }) {
    const response = yield call(accountsMiddleware.getTrackingUrlTransfer, accountId, transactionRef);
    if (!response?.type || !response?.data?.data) {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }

    if (response?.type === "W") {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }

    const { data } = response.data;
    const { trackingUrl } = data;
    if (!trackingUrl || trackingUrl === "") {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }

    yield put({
        type: types.GET_TRACKING_TRANSFER_URL_SUCCESS,
    });

    if (isMobileNativeFunc()) {
        yield call(openLink, trackingUrl);
        yield;
        return;
    }

    yield call(window.open, trackingUrl, "_blank", "noreferrer");
}

import { format } from "date-fns";
import * as API from "middleware/api";
import * as configUtils from "util/config";
import moment from "moment";

export const detailsRequest = (id) => API.executeWithAccessToken("/creditCards.read", { idCreditCard: id });

export const changeCardStatus = (id, blocked) =>
    API.executeWithAccessToken("/creditCards.blockunblock", { idCreditCard: id, blocked });

export const movementsRequest = (id, filters = {}) => {
    let { dateFrom, dateTo } = filters;
    const { minAmount, maxAmount, pageNumber, offset, quantity, concept } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    const params = {
        idCreditCard: id,
        currency: configUtils.get("core.masterCurrency"),
        dateFrom,
        dateTo,
        maxAmount,
        minAmount,
        pageNumber,
        offset,
        quantity,
        concept,
    };

    return API.executeWithAccessToken("creditCards.listStatements", params);
};

export const downloadMovements = (idCreditCard, filters, fileFormat) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/creditCards.downloadMovements", {
        idCreditCard,
        format: fileFormat,
        currency: configUtils.get("core.masterCurrency"),
        ...filters,
        dateFrom,
        dateTo,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
    });
};
export const getMovementsTransit = (productId) =>
    API.executeWithAccessToken("/creditCards.movement.transit.get", {
        productId,
    });

export const getFinancingPlans = (productId) =>
    API.executeWithAccessToken("/creditCards.financingPlans", {
        productId,
    });

export const downloadMovementsTransit = (productId, contentType) =>
    API.executeWithAccessToken("/creditCards.downloadMovements.transit", {
        productId,
        contentType,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
    });

import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";

import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { routerActions } from "react-router-redux/actions";
import TransactionFilterProvider from "providers/TransactionFilterProvider";
import ListScheduledTransactions from "./_components/ListScheduledTransactions";

class ScheduledTransactionsMigration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        fetching: bool.isRequired,
        migrationTransactions: shape([]).isRequired,
    };

    state = {
        defaultFilters: null,
        ascending: false,
    };

    componentDidMount = () => {
        const { dispatch, migrationTransactions } = this.props;
        if (!migrationTransactions || !migrationTransactions.length) {
            dispatch(transactionsActions.loadMigrationTransactionListRequest());
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    handleOrder = () => {
        const { ascending } = this.state;
        this.setState({ ascending: !ascending });
    };

    resetHandleOrder = () => {
        this.setState({ ascending: false });
    };

    render() {
        const { isDesktop, activeEnvironment, fetching } = this.props;
        const { defaultFilters, ascending } = this.state;
        const isCorporateGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;

        return (
            <TransactionFilterProvider>
                <Notification scopeToShow="transactionMigration" />
                <Head title="scheduled.transactions.migration.list.title" onBack={this.handleBack} />
                <MainContainer showLoader={fetching} showChildrenWithLoader className="px-0">
                    <div className="above-the-fold">
                        <ListScheduledTransactions
                            isDesktop={isDesktop}
                            showSearchButton
                            defaultFilters={defaultFilters}
                            showEnvironment={isCorporateGroup}
                            itemsAreClickeable={!isCorporateGroup}
                            handleOrder={this.handleOrder}
                            ascending={ascending}
                        />
                    </div>
                </MainContainer>
            </TransactionFilterProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    isFirstFetching: transactionsSelectors.getFirstFetch(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    migrationTransactions: transactionsSelectors.getMigrationTransactions(state),
});

export default connect(mapStateToProps)(ScheduledTransactionsMigration);

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, string } from "prop-types";
import React, { useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";

const Summary = (props) => {
    const { dispatch, isDesktop } = props;
    const [creating, setCreating] = useState(false);

    const cancel = () => {
        dispatch(push("/enrollment"));
    };

    const onFinish = (ok) => {
        setCreating(false);

        if (ok) {
            dispatch(push("/enrollment/finishAssociate"));
        } else {
            dispatch(push("/enrollment"));
        }
    };

    const create = () => {
        setCreating(true);
        dispatch(enrollmentActions.finishAssociate(onFinish));
    };

    const summaryCard = () => {
        const { userFullName, documentCountry, documentType, documentNumber, invitationKind, environments } = props;
        return (
            <>
                <Box background="heading-color" borderRadius="xxl" className="py-7 px-5" fullWidth>
                    <Box display="flex" column>
                        <Box className="mb-2">
                            <Text labelKey="enrollment.summary.data.name" color="inverse" />
                            <Text color="inverse">: {userFullName} </Text>
                        </Box>
                        <Box className="mb-2">
                            <Text labelKey="enrollment.summary.data.document" color="inverse" />
                            <Text color="inverse">
                                : {documentNumber} ({documentType}, {documentCountry}){" "}
                            </Text>
                        </Box>

                        {invitationKind === "retail" && (
                            <Box display="flex">
                                <Box fullHeight>
                                    <Text labelKey="enrollment.summary.data.retailEnvironment" color="inverse" noWrap />
                                    <Text color="inverse" className="mr-2" noWrap>
                                        :
                                    </Text>
                                </Box>
                                <Box display="flex" fullHeight column>
                                    <ul>
                                        {environments?.map((environment) => (
                                            <li>
                                                <Text color="inverse">{environment}</Text>
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                {invitationKind !== "retail" && (
                    <Box className="py-7" fullWidth>
                        <Box display="flex" alignX="center" fullWidth className="mb-4">
                            <Text
                                labelKey="enrollment.summary.data.corporateEnvironment"
                                align="center"
                                color="heading"
                            />
                            <Text color="heading">: </Text>
                        </Box>
                        <Box display="flex" alignX="center">
                            <Box
                                display="flex"
                                gap="3"
                                wrap
                                alignX="center"
                                alignY="center"
                                fullWidth
                                className="enrollment-columns">
                                {environments.map((environment) => (
                                    <Box
                                        display="flex"
                                        alignY="center"
                                        background="grey"
                                        className="px-4 py-2 enviroment-box"
                                        borderRadius="xxl">
                                        <Text color="heading-color" ellipsis>
                                            {environment}
                                        </Text>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </>
        );
    };

    return (
        <>
            <HeaderEnrollment title="enrollment.summary.title" subtitle="enrollment.summary.subtitle" />
            <Box className="mb-5 mb-md-8">
                <Row gapY="8" className="height-auto">
                    <Col xs={12} md={8} mdOffset={2}>
                        <Box background="white" borderRadius="xxl" className="white-boxed pt-9 pt-md-10 pb-10 pb-md-8">
                            {summaryCard()}
                        </Box>
                    </Col>
                </Row>
            </Box>
            <Box {...(!isDesktop && { className: "mt-auto" })}>
                <Row gapY={isDesktop ? "8" : "3"}>
                    <Col xs={12} md={3} mdOffset={3}>
                        <Button bsStyle="outline" label="global.cancel" type="button" onClick={cancel} block />
                    </Col>
                    <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            bsStyle="primary"
                            label="global.confirm"
                            loading={creating}
                            type="button"
                            onClick={create}
                            block
                        />
                    </Col>
                </Row>
            </Box>
        </>
    );
};

Summary.propTypes = {
    dispatch: func.isRequired,
    invitationKind: bool.isRequired,
    userFullName: string.isRequired,
    documentCountry: string.isRequired,
    documentType: string.isRequired,
    documentNumber: string.isRequired,
    environments: arrayOf(string).isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    userFullName: enrollmentSelectors.getUserFullName(state),
    documentCountry: enrollmentSelectors.getDocumentCountry(state),
    documentType: enrollmentSelectors.getDocumentType(state),
    documentNumber: enrollmentSelectors.getDocumentNumber(state),
    environments: enrollmentSelectors.getEnvironments(state),
    invitationKind: enrollmentSelectors.getInvitationKind(state),
});

export default connect(mapStateToProps)(Summary);

import React, { useState, useEffect } from "react";
import * as i18nUtils from "util/i18n";
import { connect } from "react-redux";
import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";
import FieldLabel from "pages/_components/fields/FieldLabel";
import ReactPhoneInput from "react-phone-input-2";
import classNames from "classnames";
import { shape, string, bool } from "prop-types";
import es from "react-phone-input-2/lang/es.json";
import pt from "react-phone-input-2/lang/pt.json";
import FieldError from "./FieldError";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";

const PhoneInput = (props) => {
    const {
        mobilePhone,
        country,
        idForm,
        form,
        field,
        hideLabel,
        activeLanguage,
        configs,
        preferredCountries,
        labelNoMarginTop,
        disabled,
        disableDropdown,
        optional,
        openUp,
        onlyCountries = [],
        excludeCountries = [],
    } = props;
    const [value, setValue] = useState(
        // eslint-disable-next-line no-nested-ternary
        mobilePhone && mobilePhone.length > 0
            ? mobilePhone
            : field.value?.value
            ? field.value.prefix + field.value.areaCode + field.value.value
            : "",
    );
    const [valueData, setValueData] = useState({
        dialCode: field.value?.value ? field.value.prefix.replace("-", "") : "1",
    });
    let { idField } = props;
    if (!idField) {
        idField = `${idForm}.${field.name}`;
    }

    useEffect(() => {
        if (field.value?.value) {
            setValueData({
                dialCode: field.value?.value ? field.value.prefix.replace("-", "") : "1",
            });
        }
    }, [field]);

    useEffect(() => {
        if (mobilePhone && mobilePhone.length > 0) {
            setValue(mobilePhone);
            if (form) {
                const [part1, part2] = mobilePhone.split("-");
                form.setFieldValue(field.name, { prefix: `${part1}-`, areaCode: "", value: part2 });
            }
        }
    }, [mobilePhone]);

    const masks = () => {
        const dbMasks = Object.keys(configs).filter((config) => config.includes("phone.mask"));
        const customMasks = {};
        dbMasks.map((mask) => {
            const countryMask = mask.split(".")[2].toLowerCase();
            if (countryMask) {
                customMasks[countryMask] = configs[mask];
            }
            return customMasks;
        });
        return customMasks;
    };

    const location = () => {
        if (activeLanguage === "es") {
            return es;
        }
        if (activeLanguage === "pt") {
            return pt;
        }
        return undefined;
    };

    const setHyphenToPrefixPhone = (data, dialCode) => {
        const { format } = data;
        let prefix = "";
        const indexWhiteSpace = format.indexOf(" ") - 1;

        prefix = `${dialCode.slice(0, indexWhiteSpace)}-${dialCode.slice(indexWhiteSpace)}`;
        return prefix;
    };

    const handleOnChange = (valueChanged, data, e, formattedValue) => {
        const { dialCode: previousDialCode } = valueData;
        const { dialCode } = data;
        let phoneValue = valueChanged;
        setValue(phoneValue);
        setValueData(data);
        if (form) {
            let areaCode = "";
            if (previousDialCode === dialCode && formattedValue.includes("(")) {
                areaCode = formattedValue.split("(")[1].split(")")[0];
                if (dialCode.includes(areaCode)) {
                    areaCode = "";
                }
            }
            phoneValue = phoneValue.substring(dialCode.length + areaCode.length);
            const modifiedPrefix = setHyphenToPrefixPhone(data, dialCode);
            form.setFieldValue(field.name, { prefix: modifiedPrefix, areaCode, value: phoneValue });

            const errors = { ...form.errors };
            delete errors[field.name];
            form.setErrors(errors);
        }
    };

    const handleOnMount = (valueChanged, data) => {
        setValueData(data);
        if (form) {
            const phoneValue = valueChanged.substring(data.dialCode.length);
            form.setFieldValue(field.name, { prefix: data.dialCode, areaCode: "", value: phoneValue });
        }
    };

    const handleOnKeyDown = (e) => {
        const { dialCode } = valueData;
        const { value: targetValue } = e.currentTarget;
        const extraCharacter = targetValue.includes("(") ? 3 : 1;
        if (
            dialCode.length + extraCharacter > e.currentTarget.selectionStart &&
            targetValue.length === e.currentTarget.selectionEnd
        ) {
            e.currentTarget.selectionStart = dialCode.length + extraCharacter;
        } else if (
            dialCode.length + extraCharacter > e.currentTarget.selectionStart &&
            e.currentTarget.selectionStart === e.currentTarget.selectionEnd
        ) {
            e.currentTarget.selectionStart = targetValue.length;
        }
        if (e.keyCode === 8 && dialCode && dialCode.length > 0 && dialCode.length === value.length) {
            e.preventDefault();
        }
    };

    const handleErrors = (errors) => {
        if (typeof errors === "object") {
            return Object.keys(errors).map((error) => (
                <div key={`phoneInput${errors[error]}`} className="form-group has-error">
                    <FieldError error={errors[error]} />
                </div>
            ));
        }
        return (
            <div key={`phoneInput${errors}`} className="form-group has-error">
                <FieldError error={errors} />
            </div>
        );
    };

    const { errors } = form;
    const hasError = errors && errors[field.name];
    if (!idField) {
        idField = `${idForm}.${field.name}`;
    }

    return (
        <div
            className={classNames("form-group", {
                "has-error": hasError,
            })}>
            <FieldLabel
                idField={`${idField}`}
                labelKey={`${idForm}.${field.name}.label`}
                labelNoMarginTop={labelNoMarginTop}
                hideLabel={hideLabel}
                optional={optional}
            />
            <ReactPhoneInput
                inputProps={{ id: idField }}
                searchStyle={{ width: "92%" }}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onMount={handleOnMount}
                searchPlaceholder={i18nUtils.get("settings.changePhone.mobilePhone.search.placeholder")}
                searchNotFound={i18nUtils.get("settings.changePhone.mobilePhone.search.countryNotFound")}
                country={country}
                localization={location()}
                value={value}
                masks={masks()}
                preferredCountries={preferredCountries}
                enableAreaCodes
                enableAreaCodeStretch
                enableSearch
                disableDropdown={disableDropdown}
                containerClass={classNames("container-phone-input", { "disabled-flag-dropdown": disableDropdown })}
                buttonClass={classNames("button-phone-input", { "is-disabled": disabled })}
                dropdownClass={classNames("dropdown-phone-input", { "bottom-100": openUp })}
                searchClass="search-phone-input"
                disabled={disabled}
                onlyCountries={onlyCountries}
                excludeCountries={excludeCountries}
                {...props}
            />
            {hasError && handleErrors(errors[field.name])}
        </div>
    );
};

PhoneInput.propTypes = {
    idForm: string.isRequired,
    form: shape({}).isRequired,
    idField: string.isRequired,
    field: shape({}).isRequired,
    mobilePhone: string.isRequired,
    activeLanguage: string.isRequired,
    configs: shape({}).isRequired,
    country: string,
    hideLabel: bool,
    preferredCountries: string,
    labelNoMarginTop: bool,
    disabled: bool,
    disableDropdown: bool,
    optional: string,
    openUp: string,
    onlyCountries: shape([]),
    excludeCountries: shape([]),
};

PhoneInput.defaultProps = {
    country: "us",
    hideLabel: false,
    preferredCountries: "",
    labelNoMarginTop: false,
    disabled: false,
    disableDropdown: false,
    optional: null,
    openUp: null,
    onlyCountries: [],
    excludeCountries: [],
};

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    configs: configSelectors.getConfig(state),
});

export default connect(mapStateToProps)(PhoneInput);

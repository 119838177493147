import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import NavigationBarOptions from "pages/_components/NavigationBarOptions";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from "prop-types";
import React from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { selectors as communicationsSelectors } from "reducers/communications";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import { get as getMessage } from "util/i18n";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";
import ToggleSidebar from "./menu/ToggleSidebar";

export const PATH_NAME_MASK = ["/desktop"];

class NavigationBarDesktop extends React.Component {
    static propTypes = {
        activeEnvironment: shape({
            administrationScheme: string,
            forms: shape({}),
            id: number,
            name: string,
            permissions: shape({
                accounts: bool,
                creditcards: bool,
                loans: bool,
                payCreditCard: bool,
                payCreditCardThird: bool,
                payCreditCardOther: bool,
                payLoan: bool,
                payLoanOther: bool,
                payLoanThird: bool,
                payThirdPartiesCreditCard: bool,
                payThirdPartiesLoan: bool,
                requestTransactionCancellation: bool,
                rechargeCreditCardThird: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
                requestCheckbook: bool,
                referenceLetterSend: bool,
                transferForeignTracking: bool,
                requestAndClaimHistorial: bool,
            }),
            type: string,
        }),
        dispatch: func.isRequired,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        isDashboard: bool,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: oneOfType([number, string]),
            userFullName: string,
        }),
        location: shape({}),
        maskAmount: bool,
        unreadCommunications: number,
    };

    static defaultProps = {
        activeEnvironment: null,
        environments: null,
        isDashboard: false,
        location: {},
        loggedUser: null,
        maskAmount: false,
        unreadCommunications: 0,
    };

    state = {
        communications: 0,
    };

    /**
     * Update mask amount
     */

    updateMaskAmount = () => {
        const { dispatch, maskAmount } = this.props;
        dispatch(sessionActions.maskAmountUpdateRequest(!maskAmount));
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(statusActions.showSessionCloseModal());
    };

    goToEnvironment = (idEnvironment) => {
        const { dispatch, environments } = this.props;
        if (environments[idEnvironment].allowedToAccess === "true") {
            dispatch(push("/desktop"));
            dispatch(sessionActions.changeEnvironment(idEnvironment, false, null));
        } else {
            dispatch(
                notificationActions.showNotification(
                    getMessage("administration.restrictions.unavailableEnvironment"),
                    "error",
                    ["desktop"],
                ),
            );
            dispatch(push("/desktop"));
        }
    };

    getLastLoginInfo = () => {
        const { loggedUser } = this.props;
        const { previousLoginInfo } = loggedUser;

        if (previousLoginInfo && previousLoginInfo.length) {
            return (
                <React.Fragment>
                    <div className="last-login">
                        <I18n id="settings.lastLogin.date" />
                        :&nbsp;
                        {getLastLoginDate(previousLoginInfo[0])}&nbsp;-&nbsp;
                        {/* LINK (last connection) */}
                        {/* <Link to="/settings/lastLogin">
                            <Text color="secondary" underlined>
                                {getLastLoginPlace(previousLoginInfo[0])}
                            </Text> 
                        </Link> */}
                        <Text>{getLastLoginPlace(previousLoginInfo[0])}</Text>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    onMailboxAnimationEnd = () => {
        const { unreadCommunications } = this.props;
        this.setState({ communications: unreadCommunications });
    };

    render() {
        const {
            activeEnvironment,
            environments,
            isDashboard,
            location,
            loggedUser,
            maskAmount,
            unreadCommunications,
        } = this.props;
        const { communications } = this.state;
        const hasToAnimateMailBox = communications === unreadCommunications;

        /**
         * Validate render mask amount
         */
        const { pathname } = location;
        const showMask = PATH_NAME_MASK.includes(pathname);
        return (
            <>
                {/* TODO (LABEL a11y) */}
                <Box className="navbar-header">
                    <div className="navbar-header-wrapper">
                        <div className="d-flex items-center">
                            <ToggleSidebar />
                            <Navbar.Brand>
                                {!isDashboard ? (
                                    <Link className="navbar-brand" to="/desktop">
                                        <Image src="images/logo-banesco.svg" ariaLabel="global.companyName" />
                                    </Link>
                                ) : (
                                    <Box>
                                        <Image src="images/logo-banesco.svg" ariaLabel="global.companyName" />
                                    </Box>
                                )}
                            </Navbar.Brand>
                            {this.getLastLoginInfo()}
                        </div>

                        <NavigationBarOptions
                            activeEnvironment={activeEnvironment}
                            environments={environments}
                            getLastLoginInfo={this.getLastLoginInfo}
                            goToEnvironment={this.goToEnvironment}
                            hasToAnimateMailBox={hasToAnimateMailBox}
                            loggedUser={loggedUser}
                            logOut={this.logOut}
                            maskAmount={maskAmount}
                            showMask={showMask}
                            unreadCommunications={unreadCommunications}
                            updateMaskAmount={this.updateMaskAmount}
                        />
                    </div>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    lang: i18nSelectors.getLang(state),
    loggedUser: sessionSelectors.getUser(state),
    unreadCommunications: communicationsSelectors.getUnreadCommunications(state),
    maskAmount: sessionSelectors.getMaskAmountUpdate(state),
});

export default withRouter(connect(mapStateToProps)(NavigationBarDesktop));

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { node } from "prop-types";
import React, { useEffect } from "react";
import Slider from "react-slick";
import * as i18nUtils from "util/i18n";

const ArrowLeft = (props) => (
    <Button
        {...props}
        image="images/icons/sliderArrowLeft.svg"
        bsStyle="link"
        aria-label={i18nUtils.get("global.prev.a11y")}
    />
);
const ArrowRight = (props) => (
    <Button
        {...props}
        image="images/icons/sliderArrowRight.svg"
        bsStyle="link"
        aria-label={i18nUtils.get("global.next.a11y")}
    />
);

const CreditCard = ({ children }) => {
    const sliderRef = React.useRef();

    const updateTabIndex = () => {
        if (sliderRef.current) {
            const slides = sliderRef.current.innerSlider.list.querySelectorAll(".slick-slide");

            slides.forEach((slide) => {
                const button = slide.querySelector("button");
                const switchToggle = slide.querySelector(".switch-toggle");
                const dropdown = slide.querySelector(".dropdown button");

                if (slide.getAttribute("aria-hidden") === "true") {
                    if (switchToggle) {
                        switchToggle.setAttribute("tabIndex", "-1");
                    }
                    if (button) {
                        button.setAttribute("tabIndex", "-1");
                    }
                    if (dropdown) {
                        dropdown.setAttribute("tabIndex", "-1");
                    }
                } else {
                    if (switchToggle) {
                        switchToggle.setAttribute("tabIndex", "0");
                    }
                    if (button) {
                        button.removeAttribute("tabIndex");
                    }
                    if (dropdown) {
                        dropdown.removeAttribute("tabIndex");
                    }
                }
            });
        }
    };

    const settings = {
        arrows: true,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        infinite: false,
        dots: true,
        slidesToShow: 2,
        lazyLoad: false,
        slidesToScroll: 2,
        draggable: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        afterChange: updateTabIndex,
    };
    useEffect(updateTabIndex, []);

    return (
        <Box component="li" noList className="cards-widget-slider fade-in">
            <Slider ref={sliderRef} {...settings}>
                {children}
            </Slider>
        </Box>
    );
};

CreditCard.propTypes = {
    children: node.isRequired,
};

export default CreditCard;

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";
import { detailsActions } from "reducers/administration/advanced";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import SignatureConfirm from "pages/administration/_components/confirmations/SignatureConfirm";

const mapStateToProps = (state) => ({
    user: detailsSelectors.getUser(state),
    signatureLevels: configUtils
        .getArray("administration.signatures.signatureLevels")
        .map((value) => ({
            id: value,
            label: value,
        }))
        .concat({
            id: "N",
            label: i18nUtils.get("administration.users.edit.signatureLevel.dontSign"),
        }),
    signatureLevel: detailsSelectors.getSignatureLevel(state),
    idTransaction: detailsSelectors.getIdTransaction(state),
    idActivity: detailsSelectors.getIdActivity(state),
    credentialGroups: detailsSelectors.getCredentialGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(detailsActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    schemeName: "advanced",
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureConfirm);

export const shouldKeepFormState = ({ state, pathname }, prevRoute) => {
    if (
        (prevRoute.pathname === "/salaryPayment/request" && pathname === "/salaryPayment/request/processDetail") ||
        (pathname === "/salaryPayment/request" && prevRoute.pathname === "/salaryPayment/request/processDetail") ||
        (prevRoute.pathname === "/suppliersPayment/request" &&
            pathname === "/suppliersPayment/request/processDetail") ||
        (pathname === "/suppliersPayment/request" && prevRoute.pathname === "/suppliersPayment/request/processDetail")
    ) {
        return true;
    }
    if (state) {
        return !state.shouldLoadForm;
    }
    if (!prevRoute.pathname.includes(pathname)) {
        return false;
    }
    return false;
};

export default { shouldKeepFormState };

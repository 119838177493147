import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/usersInvite";

const UserInviteStep4 = (props) => {
    const { customerNames, isDesktop, confirmationParams } = props;

    return (
        <Box>
            <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto">
                <Col xs={12} md={8} mdOffset={2}>
                    <Box
                        display="flex"
                        column
                        alignY="center"
                        background="white"
                        borderRadius="xxl"
                        className="white-boxed mt-9 mt-md-12 pt-7 pt-md-12 pb-10 pb-md-12 px-12">
                        <Text
                            component="h2"
                            color="heading"
                            size="1"
                            className="mt-0 mb-11"
                            align="center"
                            bold
                            labelKey="administration.users.invite.message1"
                        />
                        <Box display="flex" className="mb-11">
                            <Image src="images/icons/bigCheck.svg" />
                        </Box>
                        <Text
                            component="p"
                            color="heading"
                            size="5"
                            className="mb-9 px-3"
                            align="center"
                            labelKey="administration.users.invite.message2"
                            textParams={{
                                CLIENT_NAME: `${confirmationParams?.firstName} ${confirmationParams?.lastName}`,
                            }}
                        />
                        <Box display="flex" alignX="center" alignY="center" className="mx-n-9 px-5" gap="3" wrap>
                            {customerNames.map((name) => (
                                <Box
                                    display="flex"
                                    alignY="center"
                                    background="menu-background"
                                    className="px-5"
                                    borderRadius="xxl"
                                    gap="3">
                                    <Text color="heading-color" className="line-height-125">
                                        {name}
                                    </Text>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Col>
            </Row>
        </Box>
    );
};

UserInviteStep4.propTypes = {
    customerNames: arrayOf(shape({})).isRequired,
    confirmationParams: shape({
        firstName: string,
        lastName: string,
    }).isRequired,
    isDesktop: bool.isRequired,
};

UserInviteStep4.defaultProps = {};

const mapStateToProps = (state) => ({
    customerNames: selectors.getCustomerNames(state),
    confirmationParams: selectors.getConfirmationParams(state),
});

export default connect(mapStateToProps)(UserInviteStep4);

import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";

import { routerActions } from "react-router-redux/actions";
import { push } from "react-router-redux";
import { actions as beneficiaryActions } from "reducers/frequentDestination/frequentDestination";
import { parse } from "query-string";
import Notification from "pages/_components/Notification";
import { useRevelockPositionProvider } from "providers/RevelockPositionProvider";
import BeneficiaryForeignForm from "pages/frequentDestination/TransferForeign/BeneficiaryForeignForm";
import BeneficiaryTransferLocalForm from "pages/frequentDestination/TransferLocal/BeneficiaryTransferLocalForm";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import {
    FOREIGN_TRANSFER,
    INTERNAL_TRANSFER,
    LOCAL_TRANSFER,
    validateTransferLocalForm,
    validateTransferForeignForm,
} from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import * as configUtils from "util/config";
import FormTransition from "pages/forms/_components/FormTransition";
import { useStartSensorDataCapture } from "pages/forms/customForms/hooks/useStartSensorDataCapture";

const CreateBeneficiaryNew = (props) => {
    const { preDataForm, location, mode, dispatch, match, isDesktop } = props;

    const ID_FORM = match.params?.beneficiaryId ? "beneficiary.update" : "beneficiary.create";
    const ID_ACTIVITY = `${ID_FORM}.send`;
    const TITLE_FORM = `beneficiary.create.${match.params?.beneficiaryId ? "editMode" : "createMode"}`;
    const EDIT_BENEFICIARY_PATH = "editFrequentDestination";
    const titleForm = TITLE_FORM;

    const renderTicket = () => null;
    const beneficiaryTypeList = [
        {
            label: i18n.get("beneficiary.type.internal_transfer.label"),
            value: INTERNAL_TRANSFER,
        },
        {
            label: i18n.get("beneficiary.type.local_transfer.label"),
            value: LOCAL_TRANSFER,
        },
        {
            label: i18n.get("beneficiary.type.foreign_transfer.label"),
            value: FOREIGN_TRANSFER,
        },
    ];

    const { setLocationCustom } = useRevelockPositionProvider();

    const [beneficiaryIdData, setBeneficiaryIdData] = useState(undefined);
    const [beneficiaryType, setBeneficiaryType] = useState(undefined);
    const [isEditMode, setIsEditMode] = useState(false);
    const [showFormAcions, setShowFormAcions] = useState(false);

    const { beneficiaryId, type } = match.params;
    const { pathname } = location;
    const { type: typeDefault, referencePage } = parse(location.search);

    const validateEditForm = () => {
        const pathComponents = pathname ? pathname.split("/") : [];
        if (!pathComponents || !pathComponents.includes(EDIT_BENEFICIARY_PATH)) {
            return false;
        }
        const typeRef = beneficiaryTypeList.find((item) => item.value === type);
        if (!beneficiaryId || beneficiaryId === "" || !typeRef) {
            dispatch(routerActions.replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
            return false;
        }
        setBeneficiaryIdData(beneficiaryId);
        setBeneficiaryType(typeRef);
        setIsEditMode(true);
        return true;
    };

    const getValidationSchema = () => {
        let schema = null;
        if (!beneficiaryType) {
            return schema;
        }
        if (beneficiaryType.value === FOREIGN_TRANSFER) {
            schema = (values) => validateTransferForeignForm(values, i18n, configUtils);
        } else {
            schema = (values) =>
                validateTransferLocalForm(
                    values,
                    i18n.get(DEFAULT_REQUIRED),
                    i18n.get("administration.users.emailIncorrectFormat"),
                    i18n.get("administration.users.numberMaxLengthExceeded"),
                    i18n.get("beneficiary.create.validation.creditCard.notFound"),
                );
        }
        return schema;
    };

    const validateDefaultType = () => {
        if (!typeDefault) {
            return;
        }
        const benType = beneficiaryTypeList.find((beneficiary) => beneficiary.value === typeDefault);
        if (!benType) {
            return;
        }

        setBeneficiaryType(benType);
    };

    const validateParameters = () => {
        const isEditForm = validateEditForm();

        if (isEditForm) {
            return;
        }

        validateDefaultType();
    };

    useStartSensorDataCapture(mode);

    useEffect(() => {
        validateParameters();
        return () => {
            dispatch(beneficiaryActions.clearPreDataForm());
        };
    }, [location]);

    useEffect(() => {
        setLocationCustom(match, ID_FORM);
    }, []);

    const onChangeBeneficiaryType = (beneficiarySelect, setFieldValue) => {
        if (beneficiarySelect.value !== beneficiaryType?.value) {
            setBeneficiaryType(beneficiarySelect);
            setFieldValue("beneficiaryType", beneficiarySelect.value);
            setFieldValue("beneficiary", {});
        }
    };

    const renderBeneficiaryForm = (values, setFieldValue, errors) => {
        if (!beneficiaryType) {
            return <div />;
        }

        return (
            <>
                {beneficiaryType.value === LOCAL_TRANSFER && (
                    <BeneficiaryTransferLocalForm
                        beneficiaryId={beneficiaryIdData}
                        beneficiaryType={LOCAL_TRANSFER}
                        directoryStyle
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />
                )}
                {beneficiaryType.value === FOREIGN_TRANSFER && (
                    <BeneficiaryForeignForm
                        beneficiaryId={beneficiaryIdData}
                        directoryStyle
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />
                )}
                {beneficiaryType.value === INTERNAL_TRANSFER && (
                    <BeneficiaryTransferLocalForm
                        beneficiaryId={beneficiaryIdData}
                        beneficiaryType={INTERNAL_TRANSFER}
                        directoryStyle
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                    />
                )}
            </>
        );
    };

    useEffect(() => {
        if (beneficiaryType) {
            renderBeneficiaryForm(null, null);
            setShowFormAcions(true);
        }
    }, [beneficiaryType]);

    const renderHeaderSelector = (setFieldValue, values, errors) => {
        if (beneficiaryType && setFieldValue && !values.beneficiaryType) {
            setFieldValue("beneficiaryType", beneficiaryType.value);
        }
        if (beneficiaryId && setFieldValue && !values.beneficiaryId) {
            setFieldValue("beneficiaryId", beneficiaryId);
        }
        if (mode === "edit") {
            return (
                <Box background="white" className="mb-3 mb-md-7 mt-5 mt-md-0 " borderRadius="default">
                    <Row>
                        <Col xs={12} md={5} mdOffset={1}>
                            <Box className="form-group form-group--select pt-8 pb-10 px-5 px-md-0">
                                <Box className="form-group-text">
                                    <Text
                                        htmlFor={`react-select-${ID_FORM}-beneficiary-input`}
                                        className="control-label"
                                        labelKey="beneficiary.create.type.selector.title"
                                    />
                                </Box>
                                <Box className="input-group">
                                    <Select
                                        id={`${ID_FORM}-beneficiary`}
                                        name="beneficiaryTypeSelect"
                                        idForm={ID_FORM}
                                        clearable={false}
                                        searchable={false}
                                        onChange={(beneficiarySelect) =>
                                            onChangeBeneficiaryType(beneficiarySelect, setFieldValue)
                                        }
                                        options={beneficiaryTypeList}
                                        {...(beneficiaryType && { value: beneficiaryType?.value })}
                                        disabled={isEditMode}
                                        placeholder={i18n.get("global.select")}
                                        errors={errors}
                                        labelKey="label"
                                        valueKey="value"
                                    />
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>
            );
        }
        if (mode === "preview" && beneficiaryType.value !== "FOREIGN_TRANSFER") {
            return (
                <Box {...(isDesktop ? { className: "data-wrapper" } : { className: "data-wrapper px-5" })}>
                    <FieldLabel idField="type" labelKey="beneficiary.create.type.selector.title" mode="view" />
                    <Text labelKey={`beneficiary.type.${(values?.beneficiaryType || "").toLowerCase()}.label`} />
                </Box>
            );
        }
        return null;
    };

    const handlerCancelAction = () => {
        if (typeDefault === LOCAL_TRANSFER) {
            dispatch(push("/formCustom/transferLocal"));
        } else if (typeDefault === FOREIGN_TRANSFER) {
            dispatch(push("/formCustom/transferForeign"));
        } else if (typeDefault === INTERNAL_TRANSFER) {
            dispatch(push("/formCustom/transferInternal"));
        } else {
            dispatch(push("/desktop"));
        }
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors) => (
        <>
            <Notification scopeToShow="createFrequentDestination" />
            <Box className="mx-n-5">
                {renderHeaderSelector(setFieldValue, values, errors)}
                <Box
                    display="flex"
                    className="form-content loader-container mb-8 px-5 px-md-0"
                    position="relative"
                    borderRadius="default"
                    fullWidth>
                    {renderBeneficiaryForm(values, setFieldValue, errors)}
                </Box>
            </Box>
        </>
    );

    const validate = getValidationSchema();
    const cancelAction = handlerCancelAction;

    const formProps = {
        title: titleForm,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: true,
            idActivity: ID_ACTIVITY,
            idForm: ID_FORM,
        },
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validate,
        showSchedulerMessage: false,
        showFilterChips: false,
        submitButtonLabel: "global.save",
        cancelAction,
        showFormAcions,
        data: { referencePage },
    };
    return <FormTransition {...props} {...formProps} />;
};

CreateBeneficiaryNew.propTypes = {
    preDataForm: shape({}),
    location: shape({}),
    isDesktop: bool,
    dispatch: func,
    mode: string,
    transaction: shape({}),
    currentLang: string,
    match: shape({}),
};
CreateBeneficiaryNew.defaultProps = {
    dispatch: () => {},
    mode: "",
    preDataForm: {},
    location: {},
    isDesktop: false,
    transaction: null,
    currentLang: "",
    match: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: false,
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(CreateBeneficiaryNew));

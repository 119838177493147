import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import SideBarModal from "pages/_components/modal/SideBarModal";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import Notification from "pages/_components/Notification";
import SwitchToggle from "pages/_components/SwitchToggle/SwitchToggle";
import Text from "pages/_components/Text";
import { array, bool, func, oneOf, oneOfType, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push, routerActions } from "react-router-redux/actions";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelector,
} from "reducers/authenticateHandler";
import {
    AUTHENTICATOR_TOKEN,
    BIOMETRIC_CALLBACK_SAVE_TOKEN,
    BIOMETRIC_CALLBACK_UNLOCK_TOKEN,
} from "util/biometric.util";
import { isMobileNative, isMobileNativeFunc } from "util/device";
import * as i18nUtils from "util/i18n";
import {
    TOKEN_STATUS_AS_PENDING_LIST,
    USER_AUTHENTICATOR_PASSWORD,
    USER_AUTHENTICATOR_TOKENRO,
    USER_TOKEN_STATUS_ACTIVE,
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_LOCKED_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_PENDING,
    TOKEN_STATUS_AS_UNAVAILABLE,
    USER_TOKEN_STATUS_STARTED,
} from "util/userToken.util";
import { actions as enrollmentActions } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";
import { startSensorDataCapture } from "util/monitor/monitorDbf.utils";
import TokeStatusQR from "./TokenStatusQR";

export const MODAL_TEXT_MIGRATE = "migrate";
export const MODAL_TEXT_ACTIVATE = "activate";
export const MODAL_TEXT_UNLOCK = "unlock";
export const MODAL_TEXT_INFO = "info";

// const MODAL_TEXT_UNLOCK = "unlock";

class AuthenticatorHandler extends Component {
    static propTypes = {
        fetching: func.isRequired,
        dispatch: func.isRequired,
        unlockingAuthenticator: func.isRequired,
        isDesactivating: func.isRequired,
        showDesactivationTokenModal: func.isRequired,
        isMobile: bool.isRequired,
        listUserAuthenticators: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        passwordStatus: string,
        tokenStatus: string,
        pinCode: bool.isRequired,
        serialToken: string,
    };

    static defaultProps = {
        listUserAuthenticators: null,
        tokenStatus: "",
        passwordStatus: "",
        serialToken: "",
    };

    state = {
        showSideBarTokenModal: false,
        tokenStatusAction: "",
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(authenticateHandlerActions.getTokenStatusRequest());
        dispatch(authenticateHandlerActions.getPasswordStatusRequest(USER_AUTHENTICATOR_PASSWORD));
        startSensorDataCapture();
    }

    onClickTokenActionHandler(tokenStatusAction) {
        this.setState({ tokenStatusAction });
        this.setState({ showSideBarTokenModal: true });
    }

    isPendingStatus = (tokenStatus) => tokenStatus && TOKEN_STATUS_AS_PENDING_LIST.some((item) => item === tokenStatus);

    activateTokenAction = () => {
        const { dispatch } = this.props;
        if (isMobileNativeFunc()) {
            const callBackSelfie = {
                type: BIOMETRIC_CALLBACK_SAVE_TOKEN,
                data: {
                    deviceUuid: window?.app?.getDeviceUUID() || "",
                    deviceModel: window?.app?.getDeviceModel() || "",
                    deviceBrand: window?.app?.getDeviceManufacturer() || "",
                },
                redirectSuccess: "/settings/authenticatorHandler",
                redirectError: "/settings/authenticatorHandler",
                scopeSuccess: ["settings/authenticatorHandler"],
                scopeError: ["settings/authenticatorHandler"],
                redirectAbort: "/settings/authenticatorHandler",
            };

            dispatch(enrollmentActions.setCallbackDataSelfie(callBackSelfie));

            dispatch(
                routerActions.push({
                    pathname: "/auth/tokenActivationStep1",
                }),
            );

            return;
        }

        this.onClickTokenActionHandler(MODAL_TEXT_UNLOCK);
    };

    unlockTokenAction = () => {
        const { dispatch } = this.props;
        if (isMobileNativeFunc()) {
            const callBackSelfie = {
                type: BIOMETRIC_CALLBACK_UNLOCK_TOKEN,
                data: { authenticatorType: AUTHENTICATOR_TOKEN, deviceUuid: window?.app?.getDeviceUUID() || "" },
                redirectSuccess: "/settings/authenticatorHandler",
                redirectError: "/settings/authenticatorHandler",
                scopeSuccess: ["settings/authenticatorHandler"],
                scopeError: ["settings/authenticatorHandler"],
                redirectDocument: "/settings/authenticatorHandler",
            };

            dispatch(enrollmentActions.setCallbackDataSelfie(callBackSelfie));

            dispatch(
                routerActions.push({
                    pathname: "/auth/tokenActivationStep1",
                }),
            );

            return;
        }

        this.onClickTokenActionHandler(MODAL_TEXT_UNLOCK);
    };

    columnTemplates = () => {
        const { isMobile } = this.props;
        return isMobile ? "1fr 1fr" : "10rem 1fr 1fr";
    };

    handleToggle = () => {
        const { pinCode, dispatch } = this.props;
        if (pinCode) {
            dispatch(authenticateHandlerActions.deleteTokenPinRequest());
        } else {
            dispatch(
                routerActions.push({
                    pathname: "/createTokenPinForm",
                }),
            );
        }
    };

    isTokenStatusAvailable = (tokenStatus) => tokenStatus && !TOKEN_STATUS_AS_UNAVAILABLE.includes(tokenStatus);

    showDesactivationTokenModal() {
        const { dispatch } = this.props;

        dispatch(authenticateHandlerActions.showDesactivationTokenModal());
    }

    hideDesactivationTokenModal() {
        const { dispatch } = this.props;

        dispatch(authenticateHandlerActions.hideDesactivationTokenModal());
    }

    unlockPassword() {
        const { dispatch } = this.props;

        dispatch(authenticateHandlerActions.unlockAuthenticator(USER_AUTHENTICATOR_PASSWORD));
    }

    desactivateHandleFuncion() {
        const { dispatch } = this.props;

        dispatch(authenticateHandlerActions.desactivateSoftTokenRequest());
    }

    handleTokenAction() {
        const { dispatch, tokenStatus } = this.props;
        if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
            return isMobileNative
                ? dispatch(
                      routerActions.push({
                          pathname: "/migrateSoftTokenEntrust",
                          state: { isFromAuthenticateHandler: true },
                      }),
                  )
                : this.onClickTokenActionHandler(MODAL_TEXT_MIGRATE);
        }
        if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
            if (isMobileNativeFunc()) {
                return this.activateTokenAction();
            }
            return this.onClickTokenActionHandler(MODAL_TEXT_MIGRATE);
        }
        if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
            return isMobileNative
                ? dispatch(push("/authenticateSofttoken"))
                : this.onClickTokenActionHandler(MODAL_TEXT_ACTIVATE);
        }

        const ACTIVE_ACTION_STATUS = [USER_TOKEN_STATUS_INACTIVE, USER_TOKEN_STATUS_STARTED];
        if (ACTIVE_ACTION_STATUS.includes(tokenStatus)) {
            return isMobileNativeFunc()
                ? this.activateTokenAction()
                : this.onClickTokenActionHandler(MODAL_TEXT_ACTIVATE);
        }

        if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID) {
            if (isMobileNativeFunc()) {
                dispatch(
                    notificationActions.showNotification(
                        i18nUtils.get("token.entrust.migrateEntrust.validate.error.message"),
                        "error",
                        ["tokenActivation"],
                        false,
                    ),
                );
                return this.activateTokenAction();
            }

            return this.onClickTokenActionHandler(MODAL_TEXT_MIGRATE);
        }

        if (tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
            return this.unlockTokenAction();
        }

        return null;
    }

    // eslint-disable-next-line class-methods-use-this
    renderAuthenticatorStatusLabel(authenticatorStatusEnum, authenticatorType) {
        if (authenticatorType === USER_AUTHENTICATOR_TOKENRO) {
            if (authenticatorStatusEnum !== null && authenticatorStatusEnum !== "") {
                const inactiveStatusList = [
                    USER_TOKEN_STATUS_INACTIVE,
                    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
                    USER_TOKEN_STATUS_AUTHENTICATE,
                    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
                    USER_TOKEN_STATUS_STARTED,
                ];

                const activeStatusList = [USER_TOKEN_STATUS_MIGRATE_LOCAL, USER_TOKEN_STATUS_ACTIVE];

                if (inactiveStatusList.includes(authenticatorStatusEnum)) {
                    return i18nUtils.get(`softToken.status.${USER_TOKEN_STATUS_INACTIVE.toLowerCase()}`);
                }
                if (activeStatusList.includes(authenticatorStatusEnum)) {
                    return i18nUtils.get(`softToken.status.${USER_TOKEN_STATUS_ACTIVE.toLowerCase()}`);
                }

                if (authenticatorStatusEnum === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
                    return i18nUtils.get(`softToken.status.locked`);
                }
                if (this.isPendingStatus(authenticatorStatusEnum)) {
                    return i18nUtils.get(`softToken.status.${USER_TOKEN_STATUS_PENDING.toLowerCase()}`);
                }

                return i18nUtils.get(`softToken.status.${authenticatorStatusEnum.toLowerCase()}`);
            }
        } else if (authenticatorType === USER_AUTHENTICATOR_PASSWORD) {
            const lockedStatusList = [USER_TOKEN_STATUS_LOCKED_ENTRUST];
            if (lockedStatusList.includes(authenticatorStatusEnum)) {
                return i18nUtils.get(`softToken.status.locked`);
            }

            return i18nUtils.get(`softToken.status.${authenticatorStatusEnum.toLowerCase()}`);
        }

        return "";
    }

    renderTokenActions() {
        const { tokenStatus, isMobile } = this.props;
        if (
            tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST ||
            tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL ||
            tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID
        ) {
            return (
                <Button
                    type="submit"
                    bsStyle="link"
                    label="settings.options.authenticateHandler.migrate.button"
                    image="images/icons/unlock.svg"
                    {...(!isMobile && { small: true })}
                    onClick={() => this.handleTokenAction()}
                />
            );
        }
        const INACTIVE_STATUS_LIST = [
            USER_TOKEN_STATUS_STARTED,
            USER_TOKEN_STATUS_AUTHENTICATE,
            USER_TOKEN_STATUS_INACTIVE,
        ];
        if (INACTIVE_STATUS_LIST.includes(tokenStatus)) {
            return (
                <Button
                    type="submit"
                    bsStyle="link"
                    label="settings.options.authenticateHandler.activate.button"
                    image="images/icons/unlock.svg"
                    {...(!isMobile && { small: true })}
                    onClick={() => this.handleTokenAction()}
                />
            );
        }
        if (tokenStatus === USER_TOKEN_STATUS_ACTIVE) {
            return (
                <>
                    <Button
                        type="submit"
                        bsStyle="link"
                        image="images/icons/circleCross16.svg"
                        label="settings.options.authenticateHandler.desactivate.button"
                        {...(!isMobile && { small: true })}
                        onClick={() => {
                            this.showDesactivationTokenModal();
                        }}
                    />
                </>
            );
        }

        if (tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
            return (
                <>
                    <Button
                        type="submit"
                        bsStyle="link"
                        label="settings.options.authenticateHandler.unlock.button"
                        image="images/icons/unlock.svg"
                        {...(!isMobile && { small: true })}
                        onClick={() => this.handleTokenAction()}
                    />
                    <Button
                        type="submit"
                        bsStyle="link"
                        image="images/icons/circleCross16.svg"
                        label="settings.options.authenticateHandler.desactivate.button"
                        {...(!isMobile && { small: true })}
                        onClick={() => {
                            this.showDesactivationTokenModal();
                        }}
                    />
                </>
            );
        }

        return undefined;
    }

    renderPasswordActions() {
        const { passwordStatus, unlockingAuthenticator, isMobile } = this.props;

        if (passwordStatus === USER_TOKEN_STATUS_ACTIVE) {
            return (
                <Button
                    type="submit"
                    bsStyle="link"
                    label="global.modify"
                    image="images/icons/editPen16.svg"
                    href="/settings/changePassword"
                    {...(!isMobile && { small: true })}
                />
            );
        }
        if (passwordStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
            return (
                <Button
                    type="submit"
                    bsStyle="link"
                    label="settings.options.authenticateHandler.unlock.button"
                    image="images/icons/unlock.svg"
                    loading={unlockingAuthenticator}
                    {...(!isMobile && { small: true })}
                    onClick={() => {
                        this.unlockPassword();
                    }}
                />
            );
        }
        return null;
    }

    renderPasswordStatus = (passwordStatus, isMobile) => (
        <GridTable.Container
            columnsTemplate={this.columnTemplates()}
            {...(isMobile && {
                margin: "mb-3",
                padding: "py-6 px-5",
                borderRadius: "default",
                border: null,
            })}>
            <GridTable.Data columnStart={1} alignX="flex-start">
                <Box display="flex" column gap="2">
                    <Box display="flex" alignY="center">
                        <Box
                            className="p-2 mt-1 mr-3"
                            background={passwordStatus === USER_TOKEN_STATUS_ACTIVE ? "success-color" : "error-color"}
                            borderRadius="circle"
                        />
                        <Text
                            size={isMobile ? "5" : "6"}
                            color={passwordStatus === USER_TOKEN_STATUS_ACTIVE ? "success-color" : "error"}
                            uppercase>
                            {this.renderAuthenticatorStatusLabel(passwordStatus, USER_AUTHENTICATOR_PASSWORD)}
                        </Text>
                    </Box>
                    {isMobile && (
                        <Text size="5" color="heading" labelKey="authenticator.password.label" className="ml-5" />
                    )}
                </Box>
            </GridTable.Data>
            {!isMobile && (
                <GridTable.Data columnStart={2} alignX="center">
                    <Text size={isMobile ? "5" : "6"} color="heading" labelKey="authenticator.password.label" />
                </GridTable.Data>
            )}
            <GridTable.Data
                className="mr-md-9"
                columnStart={isMobile ? 2 : 3}
                alignX="center"
                {...(isMobile ? { rowWidth: 2, alignX: "flex-end" } : { alignX: "center" })}>
                {this.renderPasswordActions()}
            </GridTable.Data>
        </GridTable.Container>
    );

    renderTokenStatus = (tokenStatus, isMobile, pinCode, serialToken) => (
        <>
            <GridTable.Container
                columnsTemplate={this.columnTemplates()}
                {...(isMobile && {
                    padding: "pt-6 pb-5 px-5",
                    borderRadius: "default",
                    border: null,
                })}
                removeBottomSpacing>
                {!this.isTokenStatusAvailable(tokenStatus) && (
                    <GridTable.Data columnStart={1} columnWidth={2} alignX="flex-start">
                        <Text size="5" color="heading" align="center">
                            {i18nUtils.get("token.entrust.status.unavailable.message")}
                        </Text>
                    </GridTable.Data>
                )}
                {this.isTokenStatusAvailable(tokenStatus) && (
                    <>
                        <GridTable.Data columnStart={1} alignX="flex-start">
                            <Box display="flex" column gap="2">
                                <Box display="flex" alignY="center">
                                    <Box
                                        className="p-2 mt-1 mr-3"
                                        background={
                                            // eslint-disable-next-line no-nested-ternary
                                            tokenStatus === USER_TOKEN_STATUS_ACTIVE ||
                                            tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL
                                                ? "success-color"
                                                : this.isPendingStatus(tokenStatus)
                                                ? "warning-color"
                                                : "error-color"
                                        }
                                        borderRadius="circle"
                                    />
                                    <Text
                                        size={isMobile ? "5" : "6"}
                                        color={
                                            // eslint-disable-next-line no-nested-ternary
                                            tokenStatus === USER_TOKEN_STATUS_ACTIVE ||
                                            tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL
                                                ? "success-color"
                                                : this.isPendingStatus(tokenStatus)
                                                ? "warning-color"
                                                : "error"
                                        }
                                        uppercase>
                                        {this.renderAuthenticatorStatusLabel(tokenStatus, USER_AUTHENTICATOR_TOKENRO)}
                                    </Text>
                                </Box>
                                {isMobile && (
                                    <Text
                                        size="5"
                                        color="heading"
                                        labelKey="authenticator.tokenro.label"
                                        className="ml-5"
                                        textParams={{
                                            SERIAL_TOKEN: serialToken ? `(${serialToken})` : "",
                                        }}
                                    />
                                )}
                            </Box>
                        </GridTable.Data>
                        {!isMobile && (
                            <GridTable.Data columnStart={2} alignX="center">
                                <Text
                                    size="6"
                                    color="heading"
                                    labelKey="authenticator.tokenro.label"
                                    textParams={{
                                        SERIAL_TOKEN: serialToken ? `(${serialToken})` : "",
                                    }}
                                />
                            </GridTable.Data>
                        )}
                        <GridTable.Data
                            className="mr-md-9"
                            columnStart={isMobile ? 2 : 3}
                            alignX="center"
                            {...(isMobile ? { alignX: "flex-end" } : { alignX: "center" })}>
                            <Box
                                display="flex"
                                {...(isMobile
                                    ? { column: true, alignX: "center", alignY: "flex-end" }
                                    : { alignX: "flex-end" })}>
                                {this.renderTokenActions()}
                            </Box>
                        </GridTable.Data>
                    </>
                )}
                {isMobile && (
                    <GridTable.Data
                        className="mr-md-9 mx-n-5 mt-6"
                        columnStart={1}
                        columnWidth={2}
                        alignX="flex-start"
                        alignY="center">
                        <Box border="top-secondary-background-color" fullWidth className="pt-5 px-5">
                            <SwitchToggle
                                switchIsOn={pinCode}
                                size="5"
                                fixLabelKey="token.pin.menu.text"
                                handleToggle={this.handleToggle}
                                disabled={!tokenStatus || tokenStatus !== USER_TOKEN_STATUS_ACTIVE}
                            />
                        </Box>
                    </GridTable.Data>
                )}
            </GridTable.Container>
        </>
    );

    renderAuthenticatorsContent() {
        const { passwordStatus, isMobile, tokenStatus, pinCode, serialToken } = this.props;
        const { showSideBarTokenModal, tokenStatusAction } = this.state;

        return (
            <>
                <Box {...(!isMobile && { background: "white", borderRadius: "default" })} className="pb-md-10">
                    <Box
                        display="flex"
                        className="px-7 py-6"
                        fullWidth
                        {...(isMobile ? { alignX: "center" } : { border: "bottom-background-secondary" })}>
                        <Text
                            color="heading"
                            labelKey="settings.authenticatorHandler.title"
                            {...(isMobile && { align: "center" })}
                        />
                    </Box>

                    <GridTable>
                        {!isMobile ? (
                            <GridTable.Header>
                                <GridTable.Container columnsTemplate={this.columnTemplates()} isInHeader>
                                    <GridTable.Data columnStart={1} alignX="flex-start">
                                        <Text labelKey="tableHeader.state" size="6" color="text-boton" />
                                    </GridTable.Data>
                                    <GridTable.Data columnStart={2} alignX="center">
                                        <Text labelKey="tableHeader.kind" size="6" color="text-boton" />
                                    </GridTable.Data>
                                    <GridTable.Data className="mr-md-9" columnStart={3} alignX="center">
                                        <Text labelKey="tableHeader.action" size="6" color="text-boton" />
                                    </GridTable.Data>
                                </GridTable.Container>
                            </GridTable.Header>
                        ) : null}
                        <GridTable.Body>
                            {passwordStatus && this.renderPasswordStatus(passwordStatus, isMobile)}
                            {tokenStatus && this.renderTokenStatus(tokenStatus, isMobile, pinCode, serialToken)}
                        </GridTable.Body>
                    </GridTable>
                </Box>
                <SideBarModal
                    show={showSideBarTokenModal}
                    onClose={() => {
                        this.setState({ showSideBarTokenModal: false });
                    }}
                    title={`token.drawer.${tokenStatusAction.toLowerCase()}.title`}>
                    <TokeStatusQR tokenStatus={tokenStatusAction.toLowerCase()} />
                </SideBarModal>
            </>
        );
    }

    render() {
        const { fetching, isDesactivating, showDesactivationTokenModal } = this.props;

        return (
            <>
                <Head title="settings.options.authenticateHandler" backLinkTo="/settings" />
                <Notification scopeToShow="settings/authenticatorHandler" />
                <TextAndButtonsModal
                    modalShow={showDesactivationTokenModal}
                    acceptFunction={() => {
                        this.desactivateHandleFuncion();
                    }}
                    cancelFunction={() => {
                        this.hideDesactivationTokenModal();
                    }}
                    confirmLabel="token.drawer.desactivate.button.accept"
                    headingText={i18nUtils.get("token.drawer.desactivate.title")}
                    text={i18nUtils.get("token.drawer.desactivate.text")}
                    loading={isDesactivating}
                    modalId="widgets.update.data.modal"
                />
                <MainContainer showLoader={fetching}>{this.renderAuthenticatorsContent()}</MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    tokenStatus: authenticateHandlerSelector.getTokenStatus(state),
    pinCode: authenticateHandlerSelector.getPinCode(state),
    passwordStatus: authenticateHandlerSelector.getAuthenticatorStatus(state),
    isDesactivating: authenticateHandlerSelector.isDesactivating(state),
    showDesactivationTokenModal: authenticateHandlerSelector.showDesactivationTokenModal(state),
    fetching: authenticateHandlerSelector.isFetching(state),
    unlockingAuthenticator: authenticateHandlerSelector.isUnlockingAuthenticator(state),
    serialToken: authenticateHandlerSelector.getSerialToken(state),
});

export default connect(mapStateToProps)(AuthenticatorHandler);

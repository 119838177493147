import SideBarModal from "pages/_components/modal/SideBarModal";
import UpdateCompanyDataStepZero from "pages/settings/companyDataUpdate/UpdateCompanyDataStepZero";
import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
    actions as updateCompanyDataActions,
    selectors as updateCompanyDataSelectors,
} from "reducers/updateCompanyData/updateCompanyData.reducer";

const PAGE_ID = "corporateData.update.drawer";

const UpdateCompanyDataStepZeroModal = ({ dispatch, isShowUpdateCompanyDataStepZero }) => {
    const hideUpdateCompanyDataStepZero = () => {
        dispatch(updateCompanyDataActions.hideUpdateCompanyDataStepZero());
    };

    return (
        <SideBarModal
            show={isShowUpdateCompanyDataStepZero}
            onClose={hideUpdateCompanyDataStepZero}
            modalId={PAGE_ID}
            title="administration.companyDataUpdate.title">
            <UpdateCompanyDataStepZero cancelHandler={hideUpdateCompanyDataStepZero} />
        </SideBarModal>
    );
};

UpdateCompanyDataStepZeroModal.propTypes = {
    dispatch: func,
    isShowUpdateCompanyDataStepZero: bool,
};

UpdateCompanyDataStepZeroModal.defaultProps = {
    dispatch: () => {},
    isShowUpdateCompanyDataStepZero: false,
};

const mapStateToProps = (state) => ({
    isShowUpdateCompanyDataStepZero: updateCompanyDataSelectors.isShowUpdateCompanyDataStepZero(state),
});

export default connect(mapStateToProps)(UpdateCompanyDataStepZeroModal);

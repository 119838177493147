import React from "react";
import Button from "pages/_components/Button";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import { func, string } from "prop-types";

const ButtonChipFilterContext = (props) => {
    const { className, onClick, image, label, key, setStatus } = props;
    return (
        <Button
            bsStyle="chip"
            className={className}
            key={key}
            label={label}
            onClick={() => {
                if (onClick) {
                    onClick(setStatus);
                }
            }}
            image={image}
        />
    );
};

ButtonChipFilterContext.propTypes = {
    className: string,
    onClick: func,
    image: string,
    label: string,
    key: string,
    setStatus: func,
};
ButtonChipFilterContext.defaultProps = {
    className: "",
    onClick: () => {},
    image: "",
    label: "",
    key: "",
    setStatus: () => {},
};

export default withTransactionFilterContext(ButtonChipFilterContext);

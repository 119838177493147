import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, shape, string, func } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const userStatusToAction = { blocked: "block", active: "unblock", delete: "delete" };
const PAGE_IDENTIFIER = "administration.userAction.ticket";

class UserActionTicket extends Component {
    static propTypes = {
        transactionData: shape({
            data: shape({
                userNameList: arrayOf(string),
                newStatus: string,
            }),
        }).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    componentDidMount() {
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    render() {
        const { transactionData } = this.props;

        if (transactionData.data.userNameList) {
            const userAction = transactionData.data.newStatus || "delete";
            const textKey = `administration.users.action.confirmation.${userStatusToAction[userAction]}`;
            const ticketText = transactionData.data.userNameList.map((userName, index) => (
                <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    display="flex"
                    background="grey"
                    borderRadius="md"
                    className="py-2 px-3"
                    fitWidth
                    gap="2">
                    <Box display="flex">
                        <Image src="images/icons/userIcon.svg" color="text" />
                    </Box>
                    <Text>{userName}</Text>
                </Box>
            ));

            return (
                <Box className="mt-7">
                    <Text component="h4" color="primary" size="5" bold labelKey={textKey} />
                    <Box display="flex" alignY="center" gap="3" wrap>
                        {ticketText}
                    </Box>
                </Box>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});
export default connect(mapStateToProps)(withRevelockPositionContext(UserActionTicket));

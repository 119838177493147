import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import Selector from "pages/_components/fields/formik/Selector";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import {
    actions as preApprovedProductActions,
    selectors as preApprovedProductSelectors,
} from "reducers/preApprovedProduct";
import * as Yup from "yup";

import { preApprovedProductsStatus } from "pages/desktop/Desktop";

const FORM_ID = "preApprovedProduct.drawer";

const PreApprovedProductModal = (props) => {
    const {
        handleCloseModal,
        isDesktop,
        isFetching,
        showModal,
        productType,
        dispatch,
        salaryRangeList,
        creditCardIssuerList,
        productStatusCode,
    } = props;

    const [salaryRangeOptions, setSalaryRangeOptions] = useState([]);
    const [creditCardIssuerOptions, setCreditCardIssuerOptions] = useState([]);
    const [apcChecking, setApcChecking] = useState(false);
    const [dataTreatmentCheck, setDataTreatmentCheck] = useState(false);

    useEffect(() => {
        dispatch(preApprovedProductActions.preApprovedProductPreForm());
    }, []);

    const setListOptions = () => {
        if (creditCardIssuerList) {
            setCreditCardIssuerOptions(
                creditCardIssuerList.map((creditCardIssuer) => ({
                    value: creditCardIssuer.idIssuer,
                    label: i18n.get(`${FORM_ID}.TC.${creditCardIssuer.idIssuer}`),
                })),
            );
        }

        if (salaryRangeList) {
            setSalaryRangeOptions(
                salaryRangeList.map((salaryRange) => ({
                    value: salaryRange.id,
                    label: i18n.get(`${FORM_ID}.salaryRange.${salaryRange.id}`),
                })),
            );
        }
    };

    useEffect(() => {
        setListOptions();
    }, [salaryRangeList, creditCardIssuerList]);

    useEffect(() => {
        if (!showModal) {
            setTimeout(() => {
                setApcChecking(false);
                setDataTreatmentCheck(false);
            }, 1000);
        }
    }, [showModal]);

    const onChangeApcCheckBox = () => {
        setApcChecking(!apcChecking);
    };

    const onChangeDataTreatmentCheckBox = () => {
        setDataTreatmentCheck(!dataTreatmentCheck);
    };

    const handleSubmit = (values) => {
        const { salary, workPlace, cardType } = values;

        dispatch(
            preApprovedProductActions.preApprovedProductSendRequest(
                productType,
                salary,
                workPlace,
                cardType,
                productStatusCode,
            ),
        );
    };

    const declinePreApprovedProduct = (declineStatusCode) => {
        dispatch(preApprovedProductActions.preApprovedProductDecline(productType, declineStatusCode, true));
    };

    const validationSchema = () =>
        Yup.object().shape({
            cardType:
                productType === "TDC" ? Yup.string().required(i18n.get(`${FORM_ID}.cardType.required`)) : Yup.string(),
            salary: Yup.string().required(i18n.get(`${FORM_ID}.salary.required`)),
            workPlace: Yup.string().required(i18n.get(`${FORM_ID}.workPlace.required`)),
        });

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={handleCloseModal}
            show={showModal && productType}
            className={classNames({ drawer: true })}>
            <div className="modal-container">
                <Modal.Header closeButton className="mb-7">
                    <Box display="flex" alignX="center" column>
                        <Text
                            id="modalTitleID"
                            component="h4"
                            labelKey={`${FORM_ID}.modal.title.${productType}`}
                            className="mt-0 mb-3"
                            size="3"
                            color="heading-color"
                            bold
                            align="center"
                        />
                        <Text
                            component="h5"
                            labelKey={`${FORM_ID}.modal.text`}
                            color="heading-color"
                            className="m-0"
                            align="center"
                            regular
                            size="5"
                        />
                    </Box>
                </Modal.Header>
                <Modal.Body>
                    <Box
                        display="flex"
                        column
                        alignX="between"
                        className="scrollable-content px-0 px-md-9"
                        fullWidth
                        fullHeight>
                        <Formik
                            validationSchema={validationSchema(productType)}
                            onSubmit={handleSubmit}
                            initialValues={{
                                salary: "",
                                workPlace: "",
                                cardType: "",
                            }}>
                            <Form className="full-height">
                                <Box display="flex" column alignX="between" className="mb-auto" fullHeight>
                                    <Box display="flex" column className="mb-3">
                                        <Field
                                            idForm={FORM_ID}
                                            component={Selector}
                                            name="salary"
                                            placeholder={i18n.get(
                                                "preApprovedProduct.drawer.salary.select.placeholder",
                                            )}
                                            options={salaryRangeOptions}
                                            className="mb-7"
                                        />
                                        <Field
                                            idForm={FORM_ID}
                                            component={TextField}
                                            name="workPlace"
                                            placeholder=""
                                            labelNoMarginTop
                                            className="mb-7"
                                        />
                                        {productType === "TDC" && (
                                            <Field
                                                idForm={FORM_ID}
                                                component={Selector}
                                                name="cardType"
                                                placeholder={i18n.get(
                                                    "preApprovedProduct.drawer.cardType.select.placeholder",
                                                )}
                                                options={creditCardIssuerOptions}
                                                labelNoMarginTop
                                                className="mb-7"
                                            />
                                        )}
                                    </Box>

                                    <Box display="flex" className="pb-9 pb-md-12" column>
                                        <Box display="flex" column className="mb-0 mb-md-8">
                                            <Box className="my-5" display="flex" alignX="center">
                                                <Field
                                                    idForm={FORM_ID}
                                                    name="apcReview"
                                                    component={Checkbox}
                                                    label={i18n.get("preApprovedProduct.drawer.apcReview.label")}
                                                    checked={apcChecking}
                                                    block={false}
                                                    onChange={onChangeApcCheckBox}
                                                    labelSize={isDesktop ? "7" : "6"}
                                                    labelColor="heading-color"
                                                />
                                            </Box>

                                            <Box className="mb-5" display="flex" alignX="flex-start">
                                                <Field
                                                    idForm={FORM_ID}
                                                    name="dataTreatment"
                                                    component={Checkbox}
                                                    label={i18n.get("preApprovedProduct.drawer.dataTreatment.label")}
                                                    checked={dataTreatmentCheck}
                                                    block={false}
                                                    onChange={onChangeDataTreatmentCheckBox}
                                                    labelSize={isDesktop ? "7" : "6"}
                                                    labelColor="heading-color"
                                                />
                                            </Box>
                                        </Box>
                                        <Button
                                            block
                                            type="submit"
                                            label="global.request"
                                            bsStyle="primary"
                                            loading={isFetching}
                                            disabled={!apcChecking || !dataTreatmentCheck}
                                        />
                                        <Button
                                            block
                                            label="global.decline"
                                            bsStyle="outline"
                                            loading={isFetching}
                                            onClick={() => {
                                                declinePreApprovedProduct(preApprovedProductsStatus.REJECTED);
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Form>
                        </Formik>
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

PreApprovedProductModal.propTypes = {
    handleCloseModal: func,
    isDesktop: bool,
    isFetching: bool,
    showModal: bool,
    productType: string.isRequired,
    dispatch: func.isRequired,
    salaryRangeList: shape({}).isRequired,
    creditCardIssuerList: shape({}).isRequired,
    productStatusCode: string.isRequired,
};

PreApprovedProductModal.defaultProps = {
    handleCloseModal: null,
    isDesktop: false,
    isFetching: false,
    showModal: false,
};

const mapStateToProps = (state) => ({
    isFetching: preApprovedProductSelectors.isFetching(state),
    salaryRangeList: preApprovedProductSelectors.getSalaryRangeList(state),
    creditCardIssuerList: preApprovedProductSelectors.getCreditCardIssuerList(state),
    showModal: preApprovedProductSelectors.showModal(state),
});

export default connect(mapStateToProps)(resizableRoute(PreApprovedProductModal));

import { GENERAL_CONDITIONS_CATEGORY_DEFAULT } from "constants.js";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { actions as generalCondActions, selectors as generalCondSelectors } from "reducers/generalConditions";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as Yup from "yup";

const ACTIVITY_ID = "generalCondition.activity";
const GeneralConditionEnvironment = ({ category, dispatch, isSubmitting, isDesktop, values }) => (
    <>
        <Head title="global.termAndConditions" hideMenu />
        <MainContainer>
            <Form className="full-height">
                <Box display="flex" column fullHeight>
                    <Box
                        display="flex"
                        alignX="center"
                        column
                        className="mb-0 mb-md-9 py-9 terms-and-condition-box"
                        background="white"
                        borderRadius="xl">
                        <Box display="flex" column alignY="center" fullHeight>
                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    dispatch(generalCondActions.downloadGeneralConditions({ category }));
                                }}
                                image="images/icons/download.svg"
                                label="global.download.termandconditions"
                                className="mt-auto"
                            />

                            <Box className="mt-auto mt-md-auto">
                                <Field name="acceptConditions" idForm={ACTIVITY_ID} component={Checkbox} formGroup />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="mt-auto spacing-bottom">
                        <Row heightAuto>
                            <Col sm={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <Row gapY="3">
                                    <Col sm={12} md={6}>
                                        <Button
                                            type="button"
                                            label="global.cancel"
                                            bsStyle="outline"
                                            onClick={() => {
                                                dispatch(
                                                    replace({
                                                        pathname: "/desktop",
                                                        state: { transition: "transition-flow-close" },
                                                    }),
                                                );
                                            }}
                                            block
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className={!isDesktop ? "grid-first-item" : null}>
                                        <Button
                                            type="submit"
                                            loading={isSubmitting}
                                            label="global.continue"
                                            bsStyle="primary"
                                            block
                                            disabled={!values.acceptConditions}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </Form>
        </MainContainer>
    </>
);

GeneralConditionEnvironment.propTypes = {
    category: string,
    dispatch: func,
    isSubmitting: bool,
    isDesktop: bool.isRequired,
    values: shape({}).isRequired,
};

GeneralConditionEnvironment.defaultProps = {
    dispatch: () => {},
    isSubmitting: false,
    category: GENERAL_CONDITIONS_CATEGORY_DEFAULT,
};

const mapStateToProps = (state) => ({
    getSubmitGeneralCondition: generalCondSelectors.getSubmitGeneralCondition(state),
    getSubmitParamGeneralCondition: generalCondSelectors.getSubmitParamGeneralCondition(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ acceptConditions: false }),
        validationSchema: () =>
            Yup.object().shape({
                acceptConditions: Yup.boolean().oneOf(
                    [true],
                    i18nUtils.get(`${ACTIVITY_ID}.acceptConditions.required`),
                ),
            }),
        handleSubmit: (formikBag) => {
            if (formikBag.props.getSubmitGeneralCondition) {
                formikBag.props.dispatch(
                    formikBag.props.getSubmitGeneralCondition({ ...formikBag.props.getSubmitParamGeneralCondition }),
                );
                return;
            }
            if (formikBag.props.onSubmitCondition) {
                formikBag.props.onSubmitCondition();
            }
        },
    }),
)(GeneralConditionEnvironment);

import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, node, string, oneOfType, element } from "prop-types";

import * as i18nUtils from "util/i18n";

import Container from "pages/_components/Container";

class HeadingData extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        return <div className="content-data-wrapper col-md-4 col-sm-12">{this.props.children}</div>;
    }
}

class HeadingDataGroup extends Component {
    static propTypes = {
        data: oneOfType([string, element, arrayOf(element)]).isRequired,
        label: string,
        containerClassName: string,
        dataClassName: string,
    };

    static defaultProps = {
        label: "",
        containerClassName: "data-wrapper",
        dataClassName: "data-desc",
    };

    renderContent = () => {
        const { label, data, dataClassName } = this.props;

        if (label === "") {
            return <span className={dataClassName}>{data}</span>;
        }

        return (
            <Fragment>
                <span className="data-label">{i18nUtils.get(label)}</span> <span className={dataClassName}>{data}</span>
            </Fragment>
        );
    };

    render() {
        return <div className={this.props.containerClassName}>{this.renderContent()}</div>;
    }
}

class Heading extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    static Data = HeadingData;

    static DataGroup = HeadingDataGroup;

    render() {
        return (
            <div className="view-morphing theme-product-detail">
                <Container
                    className="container--layout items-center section-content-heading"
                    gridClassName="container-fluid">
                    <Col className="col-12">{this.props.children}</Col>
                </Container>
            </div>
        );
    }
}

export default Heading;

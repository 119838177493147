import { Field } from "formik";
import moment from "moment";
import DateField from "pages/_components/fields/DateField";
import LapseSubOption from "pages/forms/_components/_scheduler/LapseSubOption";
import { arrayOf, bool, objectOf, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";

const FORM_ID = "scheduler";

class CommonFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool),
        errors: objectOf(string),
        nonWorkingDays: arrayOf(bool),
        lapse: shape({
            day: string,
        }).isRequired,
        frequency: string.isRequired,
        startsOn: oneOfType([string, shape({})]).isRequired,
        disabled: bool,
    };

    static defaultProps = {
        errors: null,
        enabledWeekDays: [],
        nonWorkingDays: [],
        disabled: false,
    };

    render() {
        const { frequency, lapse, startsOn, enabledWeekDays, nonWorkingDays, errors, disabled } = this.props;

        return (
            <>
                <Col sm={12} md={12} className="col-12">
                    {frequency !== schedulerUtils.ONCE ? (
                        <Field
                            component={DateField}
                            idForm={FORM_ID}
                            name="startsOn"
                            minDate={moment(new Date())}
                            cssDisabledToday
                            maxDate={null}
                            dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                            idField="startsOn.date"
                            customLabel={i18nUtils.get("scheduler.startsOn.label")}
                        />
                    ) : (
                        <Field
                            component={DateField}
                            idForm={FORM_ID}
                            name="startsOn"
                            minDate={moment(new Date())}
                            cssDisabledToday
                            maxDate={null}
                            dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                            idField="futureDate.date"
                            customLabel={i18nUtils.get("scheduler.futureDate.label")}
                        />
                    )}
                </Col>
                {frequency !== schedulerUtils.ONCE && (
                    <div className="scheduler-frequency-suboptions">
                        <Field
                            name="lapse"
                            component={LapseSubOption}
                            enabledWeekDays={enabledWeekDays}
                            errors={errors}
                            firstWorkingDate={startsOn}
                            nonWorkingDays={nonWorkingDays}
                            value={lapse}
                            frequency={frequency}
                            disabled={disabled}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CommonFrequencySubOption;

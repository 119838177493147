import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import SideBarModal from "pages/_components/modal/SideBarModal";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/lib/Modal";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import { selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";

import { bool, func, shape, string } from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import BeneficiarySelector from "pages/frequentDestination/components/BeneficiarySelector";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import {
    // FOREIGN_TRANSFER,
    INTERNAL_TRANSFER,
    LOCAL_TRANSFER,
    // PRODUCT_TYPE_TC,
    // PRODUCT_TYPE_TP,
} from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import { connect } from "react-redux";
import useCustomLocationSetter from "hooks/useLocationSetter";
import { withRouter } from "react-router-dom";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import * as maskUtils from "util/mask";
// import { useDataAmount } from "./hooks/TransferInternalHooks";

const FORM_ID = "role.payment";
export const LOCAL_BANK = "localBank";
const BANESCO_BANK_CODE = "BANSPAPA";

const ModalManualRole = ({
    onClose,
    addItem,
    isVisible,
    optionBankList,
    genericProps,
    preDataForm,
    itemToEdit,
    editItem,
    match,
    currentRevelockPosition,
    ...props
}) => {
    const [showBeneficiarySelector, setShowBeneficiarySelector] = useState(false);
    const [isLoadedBeneficiary, setIsLoadedBeneficiary] = useState(false);
    // const [dataAmount] = useDataAmount(preDataForm);

    const [beneficiaryType, setBeneficiaryType] = useState(undefined);

    const productTypeList = [
        { id: "CA", label: i18n.get("forms.transfers.local.productType.other.CA") },
        { id: "CC", label: i18n.get("forms.transfers.local.productType.other.CC") },
        { id: "TC", label: i18n.get("forms.transfers.local.productType.other.TC") },
        { id: "PR", label: i18n.get("forms.transfers.local.productType.other.PR") },
    ];

    // const cleanScheduler = (debitAccount, amountCurrency, setFieldValue) => {
    //     const debitAccountData = preDataForm?.debitAccountList?.find((da) => da.idProduct === debitAccount);
    //     if (debitAccountData?.currency !== amountCurrency) {
    //         setFieldValue("scheduler", null);
    //     }
    // };

    const { decimalSeparator, thousandSeparator } = numberFormat();
    const getDataAmount = (amount) => ({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [
            {
                id: amount?.currency,
                label: amount?.currency ? i18n.get(`currency.label.${amount?.currency}`) : "",
            },
        ],
    });

    const onBeneficiaryChange = (formikProps) => {
        const { setFieldValue, values } = formikProps;
        const { amount } = values;
        if (!amount) {
            setFieldValue("amount", { currency: "USD", quantity: "" });
        }

        // cleanScheduler(debitAccount, "USD", formikProps.setFieldValue);
    };

    // TODO(BENEFICIARIO)
    const clearForm = (resetForm) => {
        resetForm({
            bankId: null,
            beneficiaryName: "",
            accountNumber: "",
            amount: { currency: "USD", quantity: "" },
            productNumber: "",
            productType: null,
        });

        setIsLoadedBeneficiary(false);

        // form.setFieldValue("addIntermediaryBank", false);
        // setIsLoadedBeneficiary(false);
        // clearAllErrors();
    };

    const pageId = match.url.includes("salaryPayment") ? "role.payment.drawer" : "suppliers.payment.drawer";

    useCustomLocationSetter(isVisible, currentRevelockPosition, pageId);

    useEffect(() => {
        if (itemToEdit?.beneficiary?.load && !isLoadedBeneficiary) {
            setIsLoadedBeneficiary(true);
        }
    }, [itemToEdit]);

    const onSelectBeneficiary = (beneficiary, extraData = null, form) => {
        const { setFieldValue, setErrors, resetForm } = form;
        setShowBeneficiarySelector(false);
        clearForm(resetForm);
        setIsLoadedBeneficiary(true);
        if ((!beneficiary && !extraData) || JSON.stringify(extraData) === "{}") {
            return;
        }
        let extraDataMap;
        if (extraData) {
            extraDataMap = extraData;
        } else {
            extraDataMap = beneficiary.extraDataMap;
        }

        Object.keys(extraDataMap).forEach((key) => {
            setFieldValue(`beneficiary.${key}`, extraDataMap[key]);
        });

        onBeneficiaryChange(form);
        setErrors({});

        const bankSelected = optionBankList.find((b) => b.code === beneficiary.bankCode);

        let realProductType = beneficiary.productType;
        if (beneficiary.extraDataMap?.verifiedProductType) {
            realProductType = beneficiary.extraDataMap?.verifiedProductType;
        }
        const productType = productTypeList.find((prodType) => prodType.id === realProductType);

        setFieldValue("bank", [bankSelected]);
        setFieldValue("bankId", bankSelected.id);
        setFieldValue("beneficiaryName", beneficiary.name);
        setFieldValue("accountNumber", beneficiary.productNumber);
        setFieldValue("amount", { currency: "USD", quantity: null });
        setFieldValue("productType", [productType]);
        if (beneficiary.email) {
            setFieldValue("email", beneficiary.email);
        }
        setFieldValue("beneficiary.load", true);
    };

    const hideModal = (resetForm) => {
        clearForm(resetForm);
        onClose();
    };

    // const getInitialValues = () => {

    //     const initValues = itemToEdit ||
    //     {
    //         bank: null,
    //         beneficiaryName: itemToEdit?.beneficiaryName || "",
    //         accountNumber: "",
    //         amount: { currency: "USD", quantity: null },
    //         productType: null,
    //     }

    // }

    const getNumberPattern = (formikProps) => {
        const val = formikProps.values || {};
        const productType = val.productType?.id;

        if (productType !== "TC" && productType !== "TP") {
            return "^[0-9]*$";
        }

        return "(^$|^[345][0-9\\s]*$)";
    };

    const getNumberMaxLength = (formikProps) => {
        const val = formikProps.values || {};
        const { accountNumber } = val;
        const productType = val.productType ? val.productType[0].id : null;
        const bankId = val.bank ? val.bank[0].code : null;

        if (productType === "TC" || productType === "TP") {
            // si empieza con 3, largo 15, se aumentan el valor de los espacios en blanco (17)
            // si empieza con 4 o 5, largo 16, se aumentan el valor de los espacios en blanco (19)
            if (accountNumber) {
                switch (accountNumber.charAt(0)) {
                    case "3":
                        return 17;
                    default:
                        return 19;
                }
            }
        }

        if ((productType === "CA" || productType === "PR" || productType === "CC") && bankId === BANESCO_BANK_CODE) {
            // si es cuenta banesco, largo 12
            return 12;
        }

        // si es cuenta otro banco, largo 20
        return 20;
    };

    const handleProductSelector = (formikProps, fieldName) => {
        const { setFieldValue } = formikProps;
        if (fieldName === "productType") {
            setFieldValue("accountNumber", "");
        }
        if (fieldName === "bank") {
            setFieldValue("productType", null);
            setFieldValue("accountNumber", "");
        }
    };

    return (
        <>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
                initialValues={
                    itemToEdit
                        ? {
                              ...itemToEdit,
                              bank: [itemToEdit.bank],
                              productType: [itemToEdit.productType],
                          }
                        : {
                              bank: null,
                              beneficiaryName: "",
                              accountNumber: "",
                              amount: { currency: "USD", quantity: null },
                              productType: null,
                          }
                }
                validate={(values) => {
                    const errors = {};
                    if (!values.bank || !values.bank.length) {
                        errors.bank = i18n.get(DEFAULT_REQUIRED);
                    }
                    if (!values.beneficiaryName) {
                        errors.beneficiaryName = i18n.get(DEFAULT_REQUIRED);
                    }
                    if (!values.amount || !values.amount.quantity) {
                        errors.amount = i18n.get(DEFAULT_REQUIRED);
                    }
                    if (!values.accountNumber) {
                        errors.accountNumber = i18n.get(DEFAULT_REQUIRED);
                    }
                    if (!values.productType) {
                        errors.productType = i18n.get(DEFAULT_REQUIRED);
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    if (itemToEdit) {
                        const newValues = {
                            ...values,
                            id: itemToEdit.id,
                            bank: values.bank[0],
                            bankId: values.bank[0].id,
                            productType: values.productType[0],
                        };
                        editItem(newValues);
                    } else {
                        const newValues = {
                            ...values,
                            id: uuidv4(),
                            bank: values.bank[0],
                            bankId: values.bank[0].id,
                            productType: values.productType[0],
                        };
                        addItem(newValues);
                    }
                    clearForm(resetForm);
                }}>
                {(formikProps) => (
                    <>
                        <Modal
                            onHide={() => hideModal(formikProps.resetForm)}
                            show={isVisible}
                            dialogClassName="modal-90w"
                            className="drawer">
                            <Form className="modal-container">
                                <Modal.Header closeButton>
                                    <Text
                                        component="h4"
                                        labelKey="forms.role.payment.addAccount.title"
                                        className="mb-7"
                                        align="center"
                                        size="3"
                                        color="heading-color"
                                        bold
                                    />
                                </Modal.Header>
                                <Modal.Body>
                                    <Box
                                        display="flex"
                                        column
                                        fullWidth
                                        className="px-0 pb-10 px-md-9 modal-scrollable-content"
                                        flex1>
                                        <Box display="flex" alignX="flex-end">
                                            <Button
                                                label="frequentDestination.searchFrequentDestination"
                                                onClick={() => {
                                                    setShowBeneficiarySelector(true);
                                                    setBeneficiaryType([INTERNAL_TRANSFER, LOCAL_TRANSFER]);
                                                }}
                                                image="images/icons/directory.svg"
                                                bsStyle="link"
                                            />
                                        </Box>
                                        <Box display="flex" column gap="5" className="mb-11" position="relative">
                                            <Field
                                                {...genericProps}
                                                component={FormFieldsComponents.Selector}
                                                key="bank"
                                                name="bank"
                                                idField="bank"
                                                idForm={FORM_ID}
                                                labelIdField="role.payment.bank.label"
                                                labelKey="role.payment.bank.label"
                                                value={formikProps.values.bank}
                                                optionList={optionBankList}
                                                // defaultValue={optionBankList?.length ? [optionBankList[0]] : null}
                                                renderAs="combo"
                                                labelNoMarginTop
                                                disabled={isLoadedBeneficiary}
                                                isRequired
                                                autoFocus
                                                delayAutoFocus={350}
                                                selectRef={React.createRef()}
                                                onChange={() => handleProductSelector(formikProps, "bank")}
                                            />
                                            <Field
                                                {...genericProps}
                                                idForm={FORM_ID}
                                                component={FormFieldsComponents.Selector}
                                                key="productType"
                                                name="productType"
                                                idField="productType"
                                                labelIdField="role.payment.productType.label"
                                                value={formikProps.values.productType}
                                                optionList={productTypeList}
                                                renderAs="combo"
                                                labelNoMarginTop
                                                isFocused={false}
                                                disabled={isLoadedBeneficiary}
                                                isRequired
                                                onChange={() => handleProductSelector(formikProps, "productType")}
                                            />
                                            {formikProps.values.productType &&
                                            (formikProps?.values?.productType[0]?.id === "TC" ||
                                                formikProps?.values?.productType[0]?.id === "TP") ? (
                                                <Field
                                                    autoComplete="off"
                                                    component={MaskedTextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    mask={maskUtils.creditCardFormat(formikProps.values.accountNumber)}
                                                    name="accountNumber"
                                                    labelNoMarginTop
                                                    pattern={getNumberPattern(formikProps)}
                                                    maxLength={getNumberMaxLength(formikProps)}
                                                />
                                            ) : (
                                                <Field
                                                    idForm={FORM_ID}
                                                    {...genericProps}
                                                    name="accountNumber"
                                                    value={formikProps.values.accountNumber}
                                                    component={TextField}
                                                    type="text"
                                                    pattern={getNumberPattern(formikProps)}
                                                    maxLength={getNumberMaxLength(formikProps)}
                                                    placeholder=""
                                                    labelNoMarginTop
                                                    disabled={isLoadedBeneficiary}
                                                />
                                            )}
                                            <Field
                                                idForm={FORM_ID}
                                                {...genericProps}
                                                name="beneficiaryName"
                                                component={TextField}
                                                type="text"
                                                maxLength="35"
                                                hidePlaceholder
                                                labelNoMarginTop
                                                pattern="^[a-zA-Z0-9 ]*$"
                                                disabled={isLoadedBeneficiary}
                                            />
                                            <Field
                                                idForm={FORM_ID}
                                                {...genericProps}
                                                name="email"
                                                component={TextField}
                                                type="email"
                                                optional={i18n.get("forms.optional")}
                                                labelNoMarginTop
                                                disabled={isLoadedBeneficiary}
                                                hidePlaceholder
                                            />

                                            <Field
                                                {...genericProps}
                                                component={FormFieldsComponents.Amount}
                                                data={getDataAmount(formikProps.values?.amount)}
                                                // value={formikProps.values.amount}
                                                key="amount"
                                                name="amount"
                                                idField="amount"
                                                customPlaceholderCurrency="USD"
                                                customPlaceholderQuantity="0.00"
                                                quantityTextAlign="text-left"
                                                disabledCurrency
                                                labelNoMarginTop
                                            />
                                            {isLoadedBeneficiary && (
                                                <Box
                                                    display="flex"
                                                    alignX="flex-end"
                                                    position="absolute"
                                                    className="pt-3"
                                                    right="0"
                                                    top="100"
                                                    fullWidth>
                                                    <Button
                                                        label="frequentDestination.clearBeneficiary"
                                                        onClick={() => clearForm(formikProps.resetForm)}
                                                        image="images/icons/circleCross.svg"
                                                        bsStyle="link"
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box className="mt-auto">
                                            <Button
                                                type="submit"
                                                label="forms.role.payment.addAccount.save.button.label"
                                                bsStyle="primary"
                                                block
                                            />
                                            <Button onClick={onClose} label="global.cancel" bsStyle="outline" block />
                                        </Box>
                                    </Box>
                                </Modal.Body>
                            </Form>
                        </Modal>

                        <SideBarModal
                            show={showBeneficiarySelector}
                            onClose={() => {
                                setShowBeneficiarySelector(false);
                            }}
                            title="beneficiary.selector.title">
                            <BeneficiarySelector
                                {...props}
                                onSelect={(beneficiary) => onSelectBeneficiary(beneficiary, null, formikProps)}
                                beneficiaryType={beneficiaryType}
                                hideNewBeneficiaryButton
                                // {...(isCreditCardProducDefault &&
                                //     !isCreditCardRechargeForm && { productType: PRODUCT_TYPE_TC })}
                                // {...(isCreditCardRechargeForm && { productType: PRODUCT_TYPE_TP })}
                            />
                        </SideBarModal>
                    </>
                )}
            </Formik>
        </>
    );
};

const mapStateToProps = (state) => ({
    currentRevelockPosition: revelockSelectors.getPosition(state),
});

ModalManualRole.propTypes = {
    onClose: func.isRequired,
    addItem: func.isRequired,
    isVisible: bool.isRequired,
    optionBankList: shape({}).isRequired,
    genericProps: shape({}),
    preDataForm: shape({}),
    values: shape({}).isRequired,
    itemToEdit: shape({}),
    editItem: shape({}),
    currentRevelockPosition: string.isRequired,
    match: shape({}).isRequired,
};
ModalManualRole.defaultProps = {
    genericProps: {},
    preDataForm: {},
    itemToEdit: null,
    editItem: null,
};

export default connect(mapStateToProps)(withRouter(ModalManualRole));

import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { resizableRoute } from "pages/_components/Resizable";
import { bool } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as settingsActions } from "reducers/settings";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { startSensorDataCapture } from "util/monitor/monitorDbf.utils";

const FORM_ID = "settings.changePassword";

export const getPasswordHelp = () => (
    <ul>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule1" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule2" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule3" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule4" />
        </li>
        <li>
            <Text labelKey="settings.changePassword.passwordHelp.rule5" />
        </li>
    </ul>
);

class ChangePassword extends Component {
    componentDidMount() {
        startSensorDataCapture();
    }

    render() {
        const { isSubmitting, isDesktop } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <>
                <Notification scopeToShow="changePassword" />
                <Head title="settings.changePassword" backLinkTo="/settings/authenticatorHandler" />
                <MainContainer className="pt-0" fullHeight>
                    <Form className="full-height">
                        <Box display="flex" column fullWidth fullHeight>
                            <Box
                                background="white"
                                className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8", {
                                    "mx-n-5 full-height": !isDesktop,
                                })}
                                borderRadius="xl">
                                <Row fullHeight>
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <Box display="flex" column alignX="between" fullHeight>
                                            <Text
                                                {...(!isDesktop && { align: "center" })}
                                                {...(!isDesktop ? { size: "3" } : { size: "5" })}
                                                labelKey="settings.changePassword.textInfo"
                                                className="my-0 mx-auto"
                                                component="h2"
                                                align="center"
                                            />

                                            <Box display="flex" column fullWidth className="my-auto pt-md-8 pb-md-9">
                                                <Field
                                                    name="password"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    maxLength={maxLength}
                                                    placeholder=""
                                                />

                                                <Field
                                                    name="newPassword"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    maxLength={maxLength}
                                                    placeholder=""
                                                    tooltip={getPasswordHelp()}
                                                />

                                                <Field
                                                    name="newPasswordConfirmation"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    maxLength={maxLength}
                                                    placeholder=""
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>

                            <Box {...(!isDesktop && { className: "mt-auto" })}>
                                <Row>
                                    <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                        <Button
                                            label="global.modify"
                                            loading={isSubmitting}
                                            type="submit"
                                            bsStyle="primary"
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

ChangePassword.propTypes = {
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
};

export default compose(
    connect(null),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            password: "",
            newPassword: "",
            newPasswordConfirmation: "",
        }),

        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                newPasswordConfirmation: Yup.string().required(i18n.get(`${FORM_ID}.newPasswordConfirmation.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changePassword(
                    values.password,
                    values.newPassword,
                    values.newPasswordConfirmation,
                    formikBag,
                ),
            );
        },
    }),
)(ChangePassword);

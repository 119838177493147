import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import moment from "moment";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, shape, func } from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { push } from "react-router-redux";
import * as i18nUtils from "util/i18n";

const CreditLinesList = ({ list, isDesktop, dispatch }) => (
    <>
        {list.length > 0 && (
            <Box component="nav" fullWidth>
                <Box component="ul" noList>
                    {list.map((item) => (
                        <Box
                            key={item.id}
                            component="li"
                            display="flex"
                            alignX="between"
                            alignY="center"
                            borderRadius="default"
                            background="component-background"
                            backgroundHover="background-secondary"
                            border="background-divider"
                            noList
                            className="mb-3">
                            <Link
                                className={classNames("prod-item-link full-width")}
                                to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(push(`/creditLines/${item.id}`));
                                }}>
                                <Box
                                    display="flex"
                                    alignX="between"
                                    alignY="center"
                                    gap="3"
                                    className="pl-5 pl-md-7 pr-4 pr-md-6 py-5">
                                    <Box display="flex" alignX="center" column fullHeight flex1>
                                        <Text color="secondary" size="5" bold breakWord>
                                            {item.secondaryTypeEnum
                                                ? i18nUtils.get(
                                                      `creditLine.list.creditLineType.${item.secondaryTypeEnum?.toLowerCase()}`,
                                                  )
                                                : i18nUtils.get(
                                                      `creditLine.list.creditLineType.${item.typeEnum?.toLowerCase()}`,
                                                  )}
                                        </Text>
                                        <Text color="heading" size={isDesktop ? "6" : "6"} breakWord>
                                            {item.id}
                                        </Text>
                                        <Box display="flex">
                                            <Text
                                                color="heading"
                                                size={isDesktop ? "6" : "6"}
                                                addColon
                                                labelKey="creditLine.expirationDate"
                                            />
                                            <Text color="heading" size={isDesktop ? "6" : "6"}>
                                                {moment(item.expirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                            </Text>
                                        </Box>
                                    </Box>

                                    <Box display="flex" alignX="flex-end" fullHeight flex1>
                                        <Box display="flex" column alignX="center" alignY="flex-end">
                                            <Box
                                                display="flex"
                                                alignX="flex-end"
                                                alignY={isDesktop ? "center" : "flex-end"}
                                                className="mb-2"
                                                {...(!isDesktop && {
                                                    column: true,
                                                })}>
                                                <Text
                                                    color="heading"
                                                    size="5"
                                                    {...(isDesktop && {
                                                        className: "mr-3",
                                                    })}
                                                    labelKey="creditLine.total.balance"
                                                />
                                                <FormattedAmount
                                                    currency={item.onlineAmount?.currency}
                                                    quantity={item.onlineAmount?.quantity}
                                                    color="heading"
                                                    size="4"
                                                    bold
                                                />
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignX="flex-end"
                                                alignY={isDesktop ? "center" : "flex-end"}
                                                {...(!isDesktop && {
                                                    column: true,
                                                })}>
                                                <Text
                                                    color="heading"
                                                    size="5"
                                                    {...(isDesktop && {
                                                        className: "mr-3",
                                                    })}
                                                    labelKey="creditLine.available.balance"
                                                />
                                                <FormattedAmount
                                                    currency={item.availableAmount?.currency}
                                                    quantity={item.availableAmount?.quantity}
                                                    color="heading"
                                                    size="4"
                                                    bold
                                                />
                                            </Box>
                                        </Box>

                                        <Box display="flex" alignY="center" className="ml-4 pl-md-6">
                                            {item.childs.length > 0 && (
                                                <Image
                                                    src="images/icons/chevronRight.svg"
                                                    height="5"
                                                    {...(!isDesktop && { wrapperWidth: "3" })}
                                                    color="text-color"
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Link>
                        </Box>
                    ))}
                </Box>
            </Box>
        )}
    </>
);

CreditLinesList.propTypes = {
    list: shape({}).isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
};

export default connect()(resizableRoute(withRouter(CreditLinesList)));

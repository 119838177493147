import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string, arrayOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";

import { getMessageRequired, validationAccount, validationAmountCreditCard } from "util/validationSchemaUtil";
import * as Yup from "yup";
import * as creditCardsUtil from "util/creditCards";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import FormTransition from "../_components/FormTransition";
import { useCreditCardPayment, useCreditCardPaySelectorOptions, OTHER_AMOUNT } from "./hooks/PayCreditCardHooks";
import { useDataAmount, useGenericProps, useMetaData } from "./hooks/TransferInternalHooks";
import { PDFAmountField, PDFAmountFieldError, PDFTextField } from "./PDFTicket";

const ID_FORM = "creditCard.payment.creditCardLocal";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = "activities.redeem.miles.cash.send.title";

const RedeemMilesCash = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        transaction,
        currentLang,
        fetchingDetail,
        creditCardList,
        fetchingList,
        usesJointAccount,
    } = props;

    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [dataAmount] = useDataAmount(preDataForm);

    const creditCardOptions = useCreditCardPaySelectorOptions(creditCardList);

    const [creditCardPayment] = useCreditCardPayment(location);
    const [idCreditCardSelected, setIdCreditCardSelected] = useState("");
    const [amountDebitAccount, setAmountDebitAccount] = useState({ currency: "", quantity: 0 });
    const titleForm = TITLE_FORM;

    const validationSchema = () =>
        Yup.object().shape({
            amount: validationAmountCreditCard(`forms.${ID_FORM}.amount.required`),
            debitAccount: validationAccount(`forms.${ID_FORM}.debitAccount.invalid`, [
                ...(preDataForm?.debitAccountList || []),
            ]),
            creditCard: validationAccount(
                `forms.${ID_FORM}.creditCard.invalid`,
                creditCardsUtil.getNonPrepaidCreditCardsWithAdditionals(creditCardList),
            ),
        });

    const validateCustom = (values) => {
        if (values.paymentType !== OTHER_AMOUNT) {
            if (
                amountDebitAccount?.currency === values.amount?.currency &&
                amountDebitAccount?.quantity < values.amount?.quantity
            ) {
                return { NO_FIELD: getMessageRequired(`forms.${ID_FORM}.amount.insufficient`) };
            }
            if (!values.amount?.quantity || values.amount?.quantity <= 0 || !values.amount?.currency) {
                return { NO_FIELD: getMessageRequired(`forms.${ID_FORM}.amount.required`) };
            }
        }

        return {};
    };

    const getDetailCreditCard = (idCreditCard) => {
        if (idCreditCard && !fetchingDetail && idCreditCard !== idCreditCardSelected) {
            const creditCardRef = creditCardOptions?.options?.find(
                (item) => item?.idProduct && item.idProduct === idCreditCard,
            );

            dispatch(
                creditCardActions.detailNoMovementsRequest(
                    idCreditCard,
                    creditCardRef?.relationType,
                    creditCardRef?.hasOwnLimit,
                    ID_FORM,
                ),
            );
        }
    };

    useEffect(() => {
        if (creditCardOptions?.options && creditCardOptions.options.length > 0 && creditCardPayment) {
            getDetailCreditCard(creditCardPayment);
            setIdCreditCardSelected(creditCardPayment);
        }

        return () => dispatch(creditCardActions.detailStopFetching());
    }, [creditCardOptions]);

    function formatNumberWithSeparator(number, separator = ",") {
        if (typeof number !== "number") {
            return "0";
        }

        // Convertir el número a string y dividirlo en partes
        const parts = number.toString().split(".");

        // Utilizar una expresión regular para agregar separadores de miles
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);

        // Reunir las partes del número y devolver el resultado
        return parts.join(".");
    }

    const renderTicket = (values) => {
        const isMiles = !!transaction?.data?.miles;
        const isLifeMiles = isMiles && transaction?.data?.miles === "Lifemiles";

        const labelViewMapMiles = isLifeMiles ? "redeem.miles.lifemiles" : "redeem.miles.connect";

        const renderTicketMiles = isMiles ? (
            <PDFAmountField
                idForm={ID_FORM}
                name="amount"
                value={`${formatNumberWithSeparator(parseFloat(values.points))}`}
                label={i18n.get(labelViewMapMiles)}
                noCurrency
            />
        ) : (
            <PDFAmountField idForm={ID_FORM} name="amount" values={values} label={i18n.get(`redeem.miles.cash`)} />
        );

        return (
            <>
                {transaction?.idTransactionStatus === "FINISHED" ? (
                    renderTicketMiles
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name={`${isMiles ? "poinsTotal" : "amount"}`}
                        values={values}
                        showCurrency={!isMiles}
                        label={isMiles ? i18n.get(labelViewMapMiles) : i18n.get(`redeem.miles.cash`)}
                        value={isMiles ? values?.pointsTotal?.quantity : null}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        transaction?.idParentTransaction
                            ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                            : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                    }
                    label={i18n.get("forms.creditCard.payment.date.label_preview")}
                />

                <PDFTextField
                    value={`${transaction?.programed ? i18n.get("activities.redeem.miles.cash.send") : ""}${i18n.get(
                        "activities.redeem.miles.cash.send",
                    )}`}
                    label={i18n.get("forms.creditCard.payment.typeOperation.label")}
                />

                <PDFTextField value={transaction?.data?.cardNumber} label={i18n.get("redeem.miles.cardNumber.label")} />

                <PDFTextField
                    value={formatNumberWithSeparator(parseFloat(transaction?.data?.points))}
                    label={i18n.get("redeem.miles.points.label")}
                />
                {transaction?.data?.travelNumber && (
                    <PDFTextField
                        value={transaction?.data?.travelNumber}
                        label={i18n.get("redeem.miles.travelNumber.label")}
                    />
                )}
                {transaction?.data?.caseNumber && (
                    <PDFTextField
                        value={transaction?.data?.caseNumber}
                        label={i18n.get("redeem.miles.caseNumber.label")}
                    />
                )}
            </>
        );
    };

    const renderFields = (values) => {
        if (mode !== "view" && (!preDataForm || fetchingDetail || fetchingList || creditCardOptions.length === 0)) {
            return <PageLoading loading classicStyle={false} />;
        }

        if (values?.debitAccount && mode === "edit") {
            const debitAccount = preDataForm?.debitAccountList?.find((acc) => acc.idProduct === values?.debitAccount);
            const currency = debitAccount?.currency;
            const balance = debitAccount?.balance;

            if (amountDebitAccount?.currency !== currency || amountDebitAccount?.quantity !== balance) {
                setAmountDebitAccount({ currency: debitAccount?.currency, quantity: debitAccount?.balance });
            }
        }

        const isMiles = !!transaction?.data?.miles;
        const isLifeMiles = isMiles && transaction?.data?.miles === "Lifemiles";

        const labelViewMapMiles = isLifeMiles ? "redeem.miles.lifemiles" : "redeem.miles.connect";

        const dataAmountMiles = { ...dataAmount, decimalSeparator: " ", precision: 0, thousandsSeparator: "," };

        return (
            <>
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            {isMiles ? (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={dataAmountMiles}
                                    key="pointsTotal"
                                    name="pointsTotal"
                                    idField="amount"
                                    bigDataAmount
                                    noCurrency
                                    noDecimalsWhenRound
                                    label_viewMap={
                                        transaction?.idTransactionStatus === "FINISHED" ||
                                        (transaction?.idTransactionStatus === "FAILED" && isMiles)
                                            ? { [currentLang]: i18n.get(labelViewMapMiles) }
                                            : { [currentLang]: i18n.get(`redeem.miles.cash`) }
                                    }
                                />
                            ) : (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={dataAmount}
                                    key="amount"
                                    name="amount"
                                    idField="amount"
                                    bigDataAmount
                                    label_viewMap={
                                        transaction?.idTransactionStatus === "FINISHED" && isMiles
                                            ? { [currentLang]: i18n.get(labelViewMapMiles) }
                                            : { [currentLang]: i18n.get(`redeem.miles.cash`) }
                                    }
                                />
                            )}
                        </Box>

                        <Box className={classNames("mt-1", { "mt-1 mx-7": mode === "view" })}>
                            {TooltipProcessingComponent}
                        </Box>

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {transaction?.data?.backendReference && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.backendReference}
                                    label="forms.creditCard.payment.voucherReference.label"
                                    shouldRender={
                                        mode === "view" &&
                                        transaction &&
                                        (transaction?.idTransactionStatus === "PENDING" ||
                                            transaction?.idTransactionStatus === "PROCESSING" ||
                                            transaction?.idTransactionStatus === "REJECTED")
                                    }
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    // eslint-disable-next-line no-nested-ternary
                                    mode === "preview"
                                        ? moment().format("DD/MM/YYYY")
                                        : transaction?.idParentTransaction
                                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                                }
                                label="forms.creditCard.payment.date.label_preview"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={`${
                                    transaction?.programed ? i18n.get("activities.redeem.miles.cash.send") : ""
                                }${i18n.get("activities.redeem.miles.cash.send")}`}
                                label="forms.creditCard.payment.typeOperation.label"
                                shouldRender={mode === "view"}
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={transaction?.data?.cardNumber}
                                label="redeem.miles.cardNumber.label"
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={formatNumberWithSeparator(parseFloat(transaction?.data?.points))}
                                label="redeem.miles.points.label"
                            />

                            {transaction?.data?.travelNumber && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.travelNumber}
                                    label="redeem.miles.travelNumber.label"
                                />
                            )}
                            {transaction?.data?.caseNumber && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.caseNumber}
                                    label="redeem.miles.caseNumber.label"
                                />
                            )}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: titleForm,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        validate: validateCustom,
        showSchedulerMessage: false,
        invalidateCache: true,
        usesJointAccount,
    };
    return <FormTransition {...props} {...formProps} />;
};

RedeemMilesCash.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    payCreditCardPermission: bool.isRequired,
    creditCardDetail: shape({}),
    fetchingDetail: bool,
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    usesJointAccount: bool,
    data: arrayOf(shape({})),
};
RedeemMilesCash.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: shape({}),
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    creditCardDetail: {},
    fetchingDetail: false,
    fetchingList: false,
    creditCardList: [],
    usesJointAccount: false,
    data: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    payCreditCardPermission: sessionSelectors.hasPermissions(state, ["payCreditCard"]),
    creditCardDetail: creditCardSelectors.getDetailByForm(state, ID_FORM),
    fetchingDetail: creditCardSelectors.getFetching(state),
    fetchingList: creditCardSelectors.isFetching(state),
    creditCardList: creditCardSelectors.getList(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(RedeemMilesCash));

/*
 * API middleware to interact with the legacy API
 */
import { CORPORATE_ENVIRONMENT_TYPE, RETAIL_ENVIRONMENT_TYPE } from "constants.js";
import getAxiosObject from "./axiosUtils";
import { store } from "../store";

const apiAxios = getAxiosObject(window.API_URL);

const cachedEtags = new Map();
const methodsWithoutBody = ["head", "get"];

const HTTP_CODE_SERVICE_UNAVAILABLE = 503;
const API_CODE_SERVICE_UNAVAILABLE = "COR097E";

export const channel = () => {
    const { isAssistantLogin } = store.getState().assistant;

    if (isAssistantLogin) {
        return "assistant";
    }
    return window.cordova ? "phonegap" : "frontend";
};

apiAxios.interceptors.request.use((request) => {
    let urlKey;

    if (request.url.startsWith("/")) {
        urlKey = request.baseURL + request.url;
    } else {
        urlKey = request.url;
    }

    if (!methodsWithoutBody.includes(request.method)) {
        const { activeEnvironment, isActiveCorporate } = store.getState().session;
        if (activeEnvironment) {
            request.data.idEnvironment = activeEnvironment.id;
        }

        if (!request.data.lang) {
            const { lang } = store.getState().i18n;
            request.data.lang = lang;
        }
        request.data.channel = channel();
        request.data.environmentType = isActiveCorporate ? CORPORATE_ENVIRONMENT_TYPE : RETAIL_ENVIRONMENT_TYPE;
    }

    if (cachedEtags.has(urlKey)) {
        request.headers["If-None-Match"] = cachedEtags.get(urlKey);
    }
    return request;
});

apiAxios.interceptors.response.use(
    (response) => {
        if (response.headers.etag) {
            cachedEtags.set(response.request.responseURL, response.headers.etag);
        }

        if (response.status === 204) {
            return response;
        }

        // cubre el caso de un download de un archivo
        if (response.status === 200 && response.request.responseType === "blob") {
            return response;
        }

        if (!response.data.code || response.data.code.endsWith("E")) {
            throw response;
        }
        // dejamos en type el tipo del error (I - Info, W - Warning, E - Error)
        response.type = response.data.code.slice(-1);
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 304) {
                return error.response;
            }
        }

        /**
         * Assign error code COR097E to response when api return 503 code
         */
        if (error.response?.status && error.response.status === HTTP_CODE_SERVICE_UNAVAILABLE) {
            // eslint-disable-next-line no-param-reassign
            error.httpError = true;
            // eslint-disable-next-line no-param-reassign
            error.data = { ...(error?.data || {}), code: API_CODE_SERVICE_UNAVAILABLE };
            throw error;
        }

        // se agrega el atributo para saber que es un error de saga
        // eslint-disable-next-line
        error.httpError = true;

        throw error;
    },
);

export const setAuthToken = (token) => {
    apiAxios.defaults.headers.common.Authorization = `bearer ${token}`;
};

export const executeWithAccessToken = (idActivity, params) => {
    if (apiAxios.defaults.headers.common.Authorization) {
        return apiAxios.post(idActivity, {
            ...params,
        });
    }
    return apiAxios.post(
        `/${idActivity}`,
        {
            ...params,
        },
        {
            baseURL: window.DAS_URL,
        },
    );
};

export const downloadWithAccessToken = (idActivity, idFile) =>
    apiAxios({
        url: `${idActivity}/${idFile}`,
        method: "GET",
        responseType: "blob",
        headers: {
            channel: channel(),
        },
    });

export const executeWithExchangeToken = (idActivity, params, exchangeToken) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            headers: {
                Authorization: `exchange ${exchangeToken}`,
            },
        },
    );

export const executeWithExchangeTokenAndCancel = (idActivity, params, exchangeToken, cancelToken) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            headers: {
                Authorization: `exchange ${exchangeToken}`,
            },
            cancelToken,
        },
    );

export const executeAnonymous = (idActivity, params) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            headers: {
                Authorization: null,
            },
        },
    );

export const executeAnonymousWithCancel = (idActivity, params, cancelToken) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            headers: {
                Authorization: null,
            },
            cancelToken,
        },
    );

export const executeAnonymousWithTimeout = (idActivity, params, timeout) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            timeout,
            headers: {
                Authorization: null,
            },
        },
    );

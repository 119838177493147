import { types as loginTypes } from "reducers/login";
import { types as settingsTypes } from "reducers/settings";
import globalTypes from "reducers/types/global";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import * as configUtils from "util/config";
import { makeActionCreator } from "util/redux";

export const types = {
    CHANGE_ENVIRONMENT_REQUEST: "session/CHANGE_ENVIRONMENT_REQUEST",
    CHANGE_ENVIRONMENT_FAILURE: "session/CHANGE_ENVIRONMENT_FAILURE",
    CHANGE_ENVIRONMENT_SUCCESS: "session/CHANGE_ENVIRONMENT_SUCCESS",

    LOGOUT_REQUEST: "session/LOGOUT_REQUEST",

    SET_TOKENS: "session/SET_TOKENS",

    SET_ENABLED_ASSISTANT: "session/SET_ENABLED_ASSISTANT",

    EXTEND: "session/EXTEND",
    EXTEND_SUCCESS: "session/EXTEND_SUCCESS",
    EXPIRE: "session/EXPIRE",

    LOCATION_REQUESTED: "session/LOCATION_REQUESTED",
    UPDATE_PENDINGACTIONS_PEP: "session/PENDING_ACTIONS_PEP",
    UPDATE_PENDINGACTIONS_IRS: "session/PENDING_ACTIONS_IRS",
    MASK_AMOUNT_UPDATE: "session/MASK_AMOUNT_UPDATE",
    MASK_AMOUNT_UPDATE_REQUEST: "session/MASK_AMOUNT_UPDATE_REQUEST",

    MAIL_UPDATE_SUCCESS: "settings/MAIL_UPDATE_SUCCESS",
    SET_IS_ACTIVE_CORPORATE: "session/SET_IS_ACTIVE_CORPORATE",

    SET_SIGNATURE_LEVEL: "session/SET_SIGNATURE_LEVEL",
};

export const INITIAL_STATE = {
    activeEnvironment: null,
    environments: {},
    user: null,
    fetching: false,
    isLocationRequested: false,
    enabledAssistant: false,
    maskAmount: false,
    isActiveCorporate: false,
    signatureLevel: null,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOCATION_REQUESTED:
            return {
                ...state,
                isLocationRequested: true,
            };
        case globalTypes.CLEAN_UP:
        case loginTypes.INIT_LOGIN_FLOW:
        case types.LOGOUT_REQUEST:
            return {
                ...INITIAL_STATE,
                isActiveCorporate: state.isActiveCorporate,
            };

        case loginTypes.LOGIN_SUCCESS:
            return {
                ...state,
                activeEnvironment: action.environment,
                environments: action.environments,
                user: action.user,
                isAdministrator: action.isAdministrator,
            };
        case types.SET_ENABLED_ASSISTANT:
            return {
                ...state,
                enabledAssistant: action.enabledAssistant,
            };
        case types.UPDATE_PENDINGACTIONS_PEP:
            return {
                ...state,
                user: {
                    ...state.user,
                    pepCompleted: true,
                },
            };
        case types.UPDATE_PENDINGACTIONS_IRS:
            return {
                ...state,
                user: {
                    ...state.user,
                    irsCompleted: true,
                },
            };
        case loginTypes.MARK_ENVIRONMENTS_DISABLED:
            return {
                ...state,
                environments: action.environments,
            };
        case loginTypes.FINGERPRINT_LOGIN_PRE_SUCCESS:
            return { ...state, activeEnvironment: action.environment };
        case types.CHANGE_ENVIRONMENT_REQUEST:
            return { ...state, fetching: true };
        case types.CHANGE_ENVIRONMENT_FAILURE:
            return { ...state, fetching: false };
        case types.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                activeEnvironment: action.environment,
                environments: action.environments,
                isAdministrator: action.isAdministrator,
                signatureLevel: action.signatureLevel,
            };
        case types.SET_TOKENS:
            return { ...state, accessToken: action.accessToken, refreshToken: action.refreshToken };
        case settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    securitySeal: action.securitySeal,
                },
            };
        case settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    idDefaultEnvironment: action.idDefaultEnvironment,
                },
            };
        case settingsTypes.UPDATE_PERMISSION_LOCAL: {
            if (!action.permissions) {
                return state;
            }
            const { activeEnvironment } = state;
            const newEnviroment = { ...activeEnvironment, permissions: action.permissions };
            return {
                ...state,
                activeEnvironment: newEnviroment,
            };
        }
        case types.MASK_AMOUNT_UPDATE: {
            return { ...state, maskAmount: action.maskAmount };
        }
        case types.MAIL_UPDATE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.emailChange,
                },
            };
        case types.SET_IS_ACTIVE_CORPORATE:
            return {
                ...state,
                isActiveCorporate: action.isActive,
            };
        case types.SET_SIGNATURE_LEVEL:
            return {
                ...state,
                signatureLevel: action.signatureLevel,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "session",
        storage: storageSession,
        blacklist: ["showCaptcha"],
    },
    reducer,
);

export const actions = {
    logout: () => ({
        type: types.LOGOUT_REQUEST,
    }),
    changeEnvironment: (idEnvironment, rememberEnvironment, formikBag) => ({
        type: types.CHANGE_ENVIRONMENT_REQUEST,
        idEnvironment,
        rememberEnvironment,
        formikBag,
    }),
    extend: () => ({
        type: types.EXTEND,
    }),
    expire: (lastHref) => ({
        type: types.EXPIRE,
        lastHref,
    }),
    setTokens: makeActionCreator(types.SET_TOKENS, "accessToken", "refreshToken"),
    maskAmountUpdateRequest: (maskAmount) => ({ type: types.MASK_AMOUNT_UPDATE_REQUEST, maskAmount }),
    maskAmountUpdate: (maskAmount) => ({ type: types.MASK_AMOUNT_UPDATE, maskAmount }),

    emailUpdateSuccess: (emailChange) => ({ type: types.MAIL_UPDATE_SUCCESS, emailChange }),

    isActiveCorporate: makeActionCreator(types.SET_IS_ACTIVE_CORPORATE, "isActive"),
};

export const selectors = {
    getAccessToken: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user ? session.user.accessToken : null;
        }
        return session.accessToken;
    },
    isLoggedIn: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user && !!session.user.accessToken;
        }
        return !!session.accessToken;
    },
    getUser: ({ session }) => session.user,
    getUsername: ({ session }) => (session.user ? session.user.username : null),
    getUserFullName: ({ session }) => (session.user ? session.user.userFullName : ""),
    getEnvironments: ({ session }) => session.environments,
    getEnabledAssistant: ({ session }) => session.enabledAssistant,
    getActiveEnvironment: ({ session }) => session.activeEnvironment,
    getActiveEnvironmentType: ({ session }) => session.activeEnvironment?.type,
    getUserSecuritySeal: ({ session }) => (session.user ? session.user.securitySeal : null),
    isFetching: ({ session }) => session.fetching,
    isAdministrator: ({ session }) => session.isAdministrator,
    getAdministrationScheme: ({ session }) => session.activeEnvironment.administrationScheme,
    hasPermissions: ({ session }, permissions) =>
        !permissions ||
        !permissions.find(
            (permission) => Object.keys(session.activeEnvironment.permissions).indexOf(permission) === -1,
        ),
    getMaskAmountUpdate: ({ session }) => session.maskAmount || false,
    isActiveCorporate: ({ session }) => session.isActiveCorporate,
    getSignatureLevel: ({ session }) => session.signatureLevel,
};

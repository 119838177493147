import Box from "pages/_components/Box";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, func, shape } from "prop-types";
import React from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const KuaraListContacts = ({ contacts, onSelectBeneficiary }) => (
    <>
        {contacts.length > 0 ? (
            <>
                <GridTable>
                    <GridTable.Header tableHeaderBackground="transparent">
                        <GridTable.Container columns={1} rows={1} padding="p-5">
                            <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                <Box display="flex" fullWidth>
                                    <Text labelKey="wally.list.contacts" size={4} color="heading" bold capitalize />
                                </Box>
                            </GridTable.Data>
                        </GridTable.Container>
                    </GridTable.Header>
                    <GridTable.Body>
                        {contacts.map((element, key) => (
                            <GridTable.Container
                                columnsTemplate="1.5rem 1fr"
                                // eslint-disable-next-line react/no-array-index-key
                                key={`phone-${element.phoneNumber}${key}`}
                                rows={2}
                                onClick={() => onSelectBeneficiary(element)}
                                className="pl-5 pr-2 py-3">
                                <GridTable.Data className="p-0" columnStart={1} rowWidth={2} alignX="flex-start">
                                    <Image
                                        src="images/contact-wally.svg"
                                        height="7"
                                        wrapperWidth="7"
                                        wrapperHeight="7"
                                        color="kuara-secondary"
                                    />
                                </GridTable.Data>
                                <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                                    <Text bold size="4" color="heading">
                                        {element.displayName || element.fullName}
                                    </Text>
                                </GridTable.Data>
                                <GridTable.Data className="p-0" columnStart={2} rowStart={2} alignX="flex-start">
                                    <Text size="6">
                                        {formatPhoneNumberIntl(`+${element.phoneNumber}`) || `+${element.phoneNumber}`}
                                    </Text>
                                </GridTable.Data>
                            </GridTable.Container>
                        ))}
                    </GridTable.Body>
                </GridTable>
            </>
        ) : (
            <Box display="flex" column alignY="center" alignX="center" className="py-12 px-5" fullWidth>
                <Image src="images/icons/wallyNoContacts.svg" className="pb-4" />
                <Text color="text-secondary-color" labelKey="kuara.list.without.contacts" />
            </Box>
        )}
    </>
);

KuaraListContacts.propTypes = {
    contacts: arrayOf(shape({})),
    onSelectBeneficiary: func.isRequired,
};

KuaraListContacts.defaultProps = {
    contacts: [],
};

export default KuaraListContacts;

import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import { bool, shape, string } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import React from "react";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";

export const TEXT_TYPE = "text_type";
export const AMOUNT_TYPE = "amount_type";
export const SCHEDULER_DATE = "scheduler_date";

const ReadText = ({
    amount,
    bigMessageStyle,
    centerBlock,
    idActivity,
    idField,
    isDesktop,
    label,
    labelCustom,
    lang,
    mode,
    regularMessageStyle,
    shouldRender,
    type = TEXT_TYPE,
    value,
    wally,
    kuara,
}) => {
    const labelText = mode === "preview" ? "label_preview" : "label";

    const getRenderChild = () => {
        const titleKind = getTransactionKind(idActivity);
        return (
            <Text align="right">
                {schedulerUtils.getScheduleMessageStartDate(value, titleKind)}. {schedulerUtils.getMessagePay(value)}.
            </Text>
        );
    };

    const getRenderField = () => {
        if (type === TEXT_TYPE && value) {
            return (
                <Text
                    {...(mode === "preview" && { align: "right", breakWord: true })}
                    {...((wally || kuara) && { align: "center" })}>
                    {value}
                </Text>
            );
        }

        if (type === AMOUNT_TYPE && amount) {
            return (
                <>
                    {mode === "view" || (mode === "preview" && !isDesktop) ? (
                        <Box display="inline-block">
                            <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                        </Box>
                    ) : (
                        <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                    )}
                </>
            );
        }
        if (type === SCHEDULER_DATE && value) {
            return getRenderChild();
        }

        return <div />;
    };

    if (shouldRender) {
        return (
            <>
                <Box
                    {...(mode === "preview" && isDesktop
                        ? { display: "flex", alignX: "between" }
                        : { display: "block" })}
                    className={classNames("mb-4", {
                        wally: wally || kuara,
                        "data-wrapper": wally || kuara,
                        "big-message-style px-5": bigMessageStyle,
                        "regular-message-style": regularMessageStyle,
                        "display-block-i text-align-center": centerBlock,
                    })}>
                    {idField ? (
                        <Text
                            component="label"
                            bold
                            addColon
                            color="heading"
                            {...(wally || kuara ? { size: "3" } : { noWrap: true })}
                            className={classNames("mb-0", {
                                "data-label-wally": wally || kuara,
                            })}>
                            {labelCustom || i18nUtils.getI18Component(labelText, lang, idActivity, idField)}
                        </Text>
                    ) : (
                        <Text
                            component="label"
                            bold
                            addColon
                            color="heading"
                            {...(wally || kuara ? { size: "3" } : { noWrap: true })}
                            className={classNames("mb-0", {
                                "data-label-wally": wally || kuara,
                            })}>
                            {labelCustom || i18nUtils.get(label)}
                        </Text>
                    )}

                    {getRenderField()}
                </Box>
            </>
        );
    }
    return null;
};

ReadText.propTypes = {
    amount: shape({}),
    bigMessageStyle: bool,
    centerBlock: bool,
    idActivity: string,
    idField: string,
    isDesktop: bool.isRequired,
    label: string,
    labelCustom: string,
    lang: string,
    mode: string,
    regularMessageStyle: bool,
    shouldRender: bool,
    type: string,
    value: string,
    wally: bool,
    kuara: bool,
};
ReadText.defaultProps = {
    amount: {},
    bigMessageStyle: false,
    centerBlock: false,
    idActivity: "",
    idField: "",
    label: "",
    labelCustom: undefined,
    lang: "",
    mode: "",
    regularMessageStyle: false,
    shouldRender: true,
    type: TEXT_TYPE,
    value: "",
    wally: false,
    kuara: false,
};

export default resizableRoute(ReadText);

import EnrollmentStepIndicator, { Step } from "pages/enrollment/_components/EnrollmentStepIndicator";
import I18n from "pages/_components/I18n";
import { bool, number, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as enrollmentSelectors } from "reducers/enrollment";

const EnrollmentStepper = ({ currentStep, className, userExists, completed }) => (
    <EnrollmentStepIndicator className={className}>
        <Step currentStep={currentStep} stepNumber={1} completed={completed}>
            <I18n id="enrollment.steps.requestCode" />
        </Step>
        <Step currentStep={currentStep} stepNumber={2} completed={completed}>
            <I18n id="enrollment.steps.validateEmail" />
        </Step>
        {!userExists && (
            <Step currentStep={currentStep} stepNumber={3} completed={completed}>
                <I18n id="enrollment.steps.createUser" />
            </Step>
        )}
        {userExists && (
            <Step currentStep={currentStep} stepNumber={3} completed={completed}>
                <I18n id="enrollment.steps.validateUser" />
            </Step>
        )}
        {userExists && (
            <Step currentStep={currentStep} stepNumber={4} completed={completed}>
                <I18n id="enrollment.steps.summary" />
            </Step>
        )}
    </EnrollmentStepIndicator>
);

EnrollmentStepper.propTypes = {
    currentStep: number.isRequired,
    completed: number,
    className: string,
    userExists: bool,
};

EnrollmentStepper.defaultProps = {
    completed: null,
    className: undefined,
    userExists: false,
};

const mapStateToProps = (state) => ({
    userExists: enrollmentSelectors.isUserExists(state),
});

export default connect(mapStateToProps)(EnrollmentStepper);

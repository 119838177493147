import classNames from "classnames";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import GridTable from "pages/_components/GridTable/GridTable";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import AdditionalCardItem from "./AdditionalCardItem";

class AdditionalsCardsList extends Component {
    state = { accordionTitle: "creditCard.additionalsList.closed" };

    setTitle = (isActive) => {
        this.setState({
            accordionTitle: isActive ? "creditCard.additionalsList.closed" : "creditCard.additionalsList.opened",
        });
    };

    onClickItem = (creditCard) => {
        const { onClickItemCustom } = this.props;
        if (onClickItemCustom) {
            onClickItemCustom(creditCard);
        }
    };

    render() {
        const { isDesktop, additionals, activeEnvironment } = this.props;
        const { accordionTitle } = this.state;
        return (
            <Box className={classNames("container pb-md-5 px-0 px-md-7 px-lg-0")}>
                <Accordion whiteBox accordionBorderRadius="xl" customHandlerClick={this.setTitle}>
                    <Accordion.Item
                        title={accordionTitle}
                        titleColor="secondary-hover-color"
                        titleSize={isDesktop ? "6" : "5"}
                        arrowColor="heading-color"
                        contentClassName={null}
                        buttonLiClassName="px-7"
                        heightMd>
                        <GridTable>
                            <GridTable.Body>
                                {additionals.map((item) => (
                                    <GridTable.Container
                                        className="product-data"
                                        padding="py-0 py-md-2 px-5"
                                        columnsTemplate={isDesktop ? "2.5rem repeat(3, 1fr)" : "2.5rem 1fr auto 1.5rem"}
                                        rows={isDesktop ? 1 : 2}
                                        border="top-background-border-disabled"
                                        onClick={() => {
                                            this.onClickItem(item);
                                        }}
                                        key={item.idProduct}
                                        removeBottomSpacing>
                                        <AdditionalCardItem additional={item} activeEnvironment={activeEnvironment} />
                                    </GridTable.Container>
                                ))}
                            </GridTable.Body>
                        </GridTable>
                    </Accordion.Item>
                </Accordion>
            </Box>
        );
    }
}

AdditionalsCardsList.propTypes = {
    isDesktop: bool,
    additionals: shape({}),
    onClickItemCustom: func,
    activeEnvironment: shape({}),
};

AdditionalsCardsList.defaultProps = {
    isDesktop: true,
    additionals: null,
    onClickItemCustom: () => {},
    activeEnvironment: null,
};

export default resizableRoute(AdditionalsCardsList);

/* eslint-disable */
import { createReducer, makeActionCreator } from "util/redux";

export const authenticateHandlerTypes = {
    GET_TOKEN_STATUS_REQUEST: "authenticateHandler/GET_TOKEN_STATUS_REQUEST",
    GET_TOKEN_STATUS_SUCCESS: "authenticateHandler/GET_TOKEN_STATUS_SUCCESS",
    GET_PASSWORD_STATUS_REQUEST: "authenticateHandler/GET_PASSWORD_STATUS_REQUEST",
    GET_PASSWORD_STATUS_SUCCESS: "authenticateHandler/GET_PASSWORD_STATUS_SUCCESS",
    DESACTIVATE_TOKEN_REQUEST: "authenticateHandler/DESACTIVATE_TOKEN_REQUEST",
    DESACTIVATE_TOKEN_SUCCESS: "authenticateHandler/DESACTIVATE_TOKEN_SUCCESS",
    DESACTIVATE_TOKEN_FAILED: "authenticateHandler/DESACTIVATE_TOKEN_FAILED",
    GET_TOKEN_INFO_REQUEST: "authenticateHandler/GET_TOKEN_INFO_REQUEST",
    GET_TOKEN_INFO_SUCCESS: "authenticateHandler/GET_TOKEN_INFO_SUCCESS",
    SHOW_DESACTIVATION_TOKEN_MODAL: "authenticateHandler/SHOW_DESACTIVATION_TOKEN_MODAL",
    HIDE_DESACTIVATION_TOKEN_MODAL: "authenticateHandler/HIDE_DESACTIVATION_TOKEN_MODAL",
    UNLOCK_AUTHENTICATOR_REQUEST: "authenticateHandler/UNLOCK_AUTHENTICATOR_REQUEST",
    UNLOCK_AUTHENTICATOR_SUCCESS: "authenticateHandler/UNLOCK_AUTHENTICATOR_SUCCESS",
    DELETE_TOKEN_PIN_REQUEST: "authenticateHandler/DELETE_TOKEN_PIN_REQUEST",
    DELETE_TOKEN_PIN_SUCCES: "authenticateHandler/DELETE_TOKEN_PIN_SUCCES",
    VALIDATE_TOKEN_PIN_REQUEST: "authenticateHandler/VALIDATE_TOKEN_PIN_REQUEST",
    VALIDATE_TOKEN_PIN_SUCCES: "authenticateHandler/VALIDATE_TOKEN_PIN_SUCCES",
    PRE_FORM_READY: "authenticateHandler/PRE_FORM_READY",
    GET_TOKEN_INFO_STATUS_REQUEST: "authenticateHandler/GET_TOKEN_INFO_STATUS_REQUEST",
    GET_TOKEN_INFO_STATUS_SUCCESS: "authenticateHandler/GET_TOKEN_INFO_STATUS_SUCCESS",
    GET_TOKEN_INFO_STATUS_FAILURE: "authenticateHandler/GET_TOKEN_INFO_STATUS_FAILURE",
    GET_TOKEN_LIST_REQUEST: "authenticateHandler/GET_TOKEN_LIST_REQUEST",
    GET_TOKEN_LIST_SUCCESS: "authenticateHandler/GET_TOKEN_LIST_SUCCESS",
    GET_TOKEN_LIST_FAILURE: "authenticateHandler/GET_TOKEN_LIST_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    unlockingAuthenticator: false,
    tokenStatus: "",
    isDesactivating: false,
    showDesactivationTokenModal: false,
    authenticatorType: "",
    authenticatorStatus: "",
    redirect: "",
    isFromMigrateScreen: false,
    pinCode: null,
    pinTries: null,
    showPinValidationModal: false,
    tokenValidateStatus: undefined,
    serialToken: "",
    listTokens: [],
};

export default createReducer(INITIAL_STATE, {
    [authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [authenticateHandlerTypes.GET_TOKEN_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        tokenStatus: action.tokenStatus,
        pinCode: action.pinCode,
        pinTries: action.pinTries,
        serialToken: action.serialToken,
        fetching: false,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST]: (state, action) => {
        const listTokensTmp = [...state.listTokens];
        listTokensTmp.forEach((el) => {
            if (el.entrustKey === action.entrustKey) {
                el.fetching = true;
            }
        });
        return {
            ...state,
            listTokens: listTokensTmp,
        };
    },
    [authenticateHandlerTypes.GET_TOKEN_INFO_SUCCESS]: (state, action) => ({
        ...state,
        listTokens: action.list,
    }),
    [authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST]: (state, authenticatorType) => ({
        ...state,
        authenticatorType,
    }),
    [authenticateHandlerTypes.GET_PASSWORD_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        authenticatorStatus: action.authenticatorStatus,
    }),
    [authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST]: (state, redirect, isFromMigrateScreen) => ({
        ...state,
        isDesactivating: true,
        redirect,
        isFromMigrateScreen,
    }),
    [authenticateHandlerTypes.DESACTIVATE_TOKEN_SUCCESS]: (state) => ({
        ...state,
        isDesactivating: false,
        showDesactivationTokenModal: false,
    }),
    [authenticateHandlerTypes.DESACTIVATE_TOKEN_FAILED]: (state) => ({
        ...state,
        isDesactivating: false,
        showDesactivationTokenModal: false,
    }),
    [authenticateHandlerTypes.SHOW_DESACTIVATION_TOKEN_MODAL]: (state) => ({
        ...state,
        showDesactivationTokenModal: true,
    }),
    [authenticateHandlerTypes.HIDE_DESACTIVATION_TOKEN_MODAL]: (state) => ({
        ...state,
        showDesactivationTokenModal: false,
    }),
    [authenticateHandlerTypes.CLEAN]: () => ({
        INITIAL_STATE,
    }),
    [authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST]: (state, authenticatorType) => ({
        ...state,
        unlockingAuthenticator: true,
        authenticatorType,
    }),
    [authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_SUCCESS]: (state) => ({
        ...state,
        unlockingAuthenticator: false,
    }),
    [authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [authenticateHandlerTypes.DELETE_TOKEN_PIN_SUCCES]: (state) => ({
        ...state,
        fetching: false,
        pinCode: false,
        pinTries: 0,
    }),
    [authenticateHandlerTypes.PRE_FORM_READY]: () => ({
        fetching: false,
    }),
    [authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST]: (state, action) => {
        const listTokensTmp = [...state.listTokens];
        listTokensTmp.forEach((el) => {
            if (el.entrustKey === action.entrustKey) {
                el.fetching = true;
            }
        });
        return {
            ...state,
            fetching: false,
            listTokens: listTokensTmp,
        };
    },
    [authenticateHandlerTypes.VALIDATE_TOKEN_PIN_SUCCES]: (state, action) => ({
        ...state,
        fetching: false,
        showPinValidationModal: false,
        listTokens: action.list,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST]: (state, action) => {
        const listTokensTmp = [...state.listTokens];
        listTokensTmp.forEach((el) => {
            if (el.entrustKey === action.entrustKey) {
                el.fetchingTokenValidateStatus = true;
            }
        });
        return {
            ...state,
            listTokens: listTokensTmp,
        };
    },
    [authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        listTokens: action.list,
    }),
    [authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_FAILURE]: (state, action) => ({
        ...state,
        listTokens: action.list,
    }),

    [authenticateHandlerTypes.GET_TOKEN_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [authenticateHandlerTypes.GET_TOKEN_LIST_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [authenticateHandlerTypes.GET_TOKEN_LIST_FAILURE]: (state) => ({
        ...state,
        listTokens: [],
        fetching: false,
    }),
});

export const actions = {
    getTokenStatusRequest: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST),
    getTokenStatusSuccess: makeActionCreator(
        authenticateHandlerTypes.GET_TOKEN_STATUS_SUCCESS,
        "tokenStatus",
        "pinCode",
        "pinTries",
        "serialToken",
    ),
    getTokenInfoRequest: makeActionCreator(
        authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST,
        "environmentType",
        "entrustKey",
    ),
    getTokenInfoSuccess: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_SUCCESS, "list"),
    validateTokenPinRequest: makeActionCreator(
        authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST,
        "pinCode",
        "environmentType",
        "entrustKey",
        "handleCloseModal",
    ),
    validateTokenPinSucces: makeActionCreator(authenticateHandlerTypes.VALIDATE_TOKEN_PIN_SUCCES, "list"),
    deleteTokenPinRequest: makeActionCreator(authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST),
    deleteTokenPinSucces: makeActionCreator(authenticateHandlerTypes.DELETE_TOKEN_PIN_SUCCES),
    preFormReady: makeActionCreator(authenticateHandlerTypes.PRE_FORM_READY),
    getPasswordStatusRequest: makeActionCreator(
        authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST,
        "authenticatorType",
    ),
    getPasswordStatusSuccess: makeActionCreator(
        authenticateHandlerTypes.GET_PASSWORD_STATUS_SUCCESS,
        "authenticatorStatus",
    ),
    desactivateSoftTokenRequest: makeActionCreator(
        authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST,
        "redirect",
        "isFromMigrateScreen",
    ),
    showDesactivationTokenModal: makeActionCreator(authenticateHandlerTypes.SHOW_DESACTIVATION_TOKEN_MODAL),
    hideDesactivationTokenModal: makeActionCreator(authenticateHandlerTypes.HIDE_DESACTIVATION_TOKEN_MODAL),
    unlockAuthenticator: makeActionCreator(authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST, "authenticatorType"),
    getTokenInfoStatusRequest: makeActionCreator(
        authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST,
        "onFinish",
        "environmentType",
        "entrustKey",
    ),
    getTokenInfoStatusSuccess: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_SUCCESS, "list"),
    getTokenInfoStatusFailure: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_FAILURE, "list"),
    getTokenListRequest: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_LIST_REQUEST, "onFinish"),
    getTokenListSuccess: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_LIST_SUCCESS),
    getTokenListFailure: makeActionCreator(authenticateHandlerTypes.GET_TOKEN_LIST_FAILURE),
};

export const selectors = {
    getTokenStatus: ({ authenticateHandler }) => authenticateHandler.tokenStatus,
    getPinCode: ({ authenticateHandler }) => authenticateHandler.pinCode,
    getPinTries: ({ authenticateHandler }) => authenticateHandler.pinTries,
    getSerialToken: ({ authenticateHandler }) => authenticateHandler.serialToken,
    getAuthenticatorStatus: ({ authenticateHandler }) => authenticateHandler.authenticatorStatus,
    isFetching: ({ authenticateHandler }) => authenticateHandler.fetching || false,
    isDesactivating: ({ authenticateHandler }) => authenticateHandler.isDesactivating,
    showDesactivationTokenModal: ({ authenticateHandler }) => authenticateHandler.showDesactivationTokenModal,
    showPinValidationModal: ({ authenticateHandler }) => authenticateHandler.showPinValidationModal,
    isUnlockingAuthenticator: ({ authenticateHandler }) => authenticateHandler.unlockingAuthenticator,

    isPinCodeValid: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.isPinCodeValid,
    isPinProtectionValidated: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.isPinProtectionValidated,
    isPinProtectionValidatedCount: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.isPinProtectionValidatedCount,

    getTokenValidateStatusByList: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.tokenValidateStatus,
    isFetchingTokenValidateStatusByList: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el.entrustKey === entrustKey)?.fetchingTokenValidateStatus ||
        false,
    getListTokens: ({ authenticateHandler }) => authenticateHandler.listTokens || [],
    getPinCodeByList: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.pinCode || false,
    getPinTriesByList: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.pinTries || 0,
    getUserNameByList: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el?.entrustKey === entrustKey)?.userName,
    isFetchingByList: ({ authenticateHandler }, entrustKey) =>
        authenticateHandler.listTokens?.find((el) => el.entrustKey === entrustKey)?.fetchingTokenValidateStatus ||
        false,
};

import Box from "pages/_components/Box";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import classNames from "classnames";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import { CONTENT_TYPE_PDF, CONTENT_TYPE_XLS } from "util/download";
import { resizableRoute } from "pages/_components/Resizable";

export const ID_ACTIVITY = `account.movements.blocked`;

const MovementsBlocked = (props) => {
    const [download, setDownload] = useState(false);
    const { productId, dispatch, isFetchingMovements, movementBlockedList, isDesktop } = props;
    useEffect(() => {
        if (productId) {
            dispatch(accountsActions.listMovementBlocked(productId));
        }
    }, [productId]);

    const onFinishDownload = () => {
        setDownload(false);
    };

    const downloadMovementsBlocked = (contentType) => {
        setDownload(true);
        dispatch(accountsActions.downloadMovementsBlocked(productId, contentType, onFinishDownload));
    };

    const renderHeaderSection = () => (
        <Box display="flex" background="white" fullWidth {...(isDesktop && { className: "py-5" })} rowReverse>
            <Box display="flex" alignX="flex-end" alignY="center" className="mr-5">
                {download ? (
                    <Button label="downloading" bsStyle="only-icon" loading className="mr-5" />
                ) : (
                    <Dropdown
                        image="images/download.svg"
                        label="global.download"
                        labelClassName="visually-hidden"
                        bsStyle="only-icon"
                        pullRight>
                        <Button
                            key="accounts.pdfFile"
                            label="accounts.pdfFile"
                            onClick={() => downloadMovementsBlocked(CONTENT_TYPE_PDF)}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                        <Button
                            key="accounts.xlsFile"
                            label="accounts.xlsFile"
                            onClick={() => downloadMovementsBlocked(CONTENT_TYPE_XLS)}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    </Dropdown>
                )}
            </Box>
        </Box>
    );

    const getColumnsTemplate = () => (isDesktop ? "1fr 1.5fr minmax(auto, 1fr) 1fr" : "2fr 1fr");
    const renderTableHeader = () => (
        <GridTable.Header>
            <GridTable.Container columnsTemplate={getColumnsTemplate()} className={isDesktop ? "gapX-9" : "gapX-3"}>
                {isDesktop ? (
                    <>
                        <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                            <I18n id={`${ID_ACTIVITY}.transactionDate`} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX="flex-start" inHeader>
                            <I18n id={`${ID_ACTIVITY}.description`} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={isDesktop ? 3 : 1} alignX="flex-end" inHeader>
                            <I18n id={`${ID_ACTIVITY}.amount`} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={isDesktop ? 4 : 2} alignX="flex-end" inHeader>
                            <I18n id={`${ID_ACTIVITY}.expirationDate`} />
                        </GridTable.Data>
                    </>
                ) : (
                    <>
                        <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                            <I18n id={`${ID_ACTIVITY}.dateDescription`} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX="flex-end" inHeader>
                            <I18n id={`${ID_ACTIVITY}.amountVto`} />
                        </GridTable.Data>
                    </>
                )}
            </GridTable.Container>
        </GridTable.Header>
    );

    const renderItem = (movement) => {
        const { availDt, origDt, concept, amountStatement } = movement;
        return (
            <GridTable.Container
                className={classNames("product-data", { "gapX-9": isDesktop, "gapX-3": !isDesktop })}
                columnsTemplate={getColumnsTemplate()}
                key="movements.blocked"
                rows={isDesktop ? 1 : 2}>
                {isDesktop ? (
                    <>
                        <GridTable.Data className="p-0" columnStart={1} alignX="flex-start">
                            {origDt && <FormattedDate date={origDt} />}
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={isDesktop ? 2 : 1}>
                            <Text ellipsis {...(!isDesktop && { bold: true })}>
                                {concept || ""}
                            </Text>
                        </GridTable.Data>

                        <GridTable.Data
                            className="p-0"
                            columnStart={isDesktop ? 3 : 2}
                            rowWidth={isDesktop ? 1 : 2}
                            alignY="center"
                            alignX="flex-end">
                            {amountStatement && amountStatement.currency && amountStatement.quantity && (
                                <FormattedAmount
                                    className={classNames("justify-content-end", {
                                        "is-negative": amountStatement.quantity < 0,
                                    })}
                                    noCurrency
                                    quantity={amountStatement.quantity}
                                />
                            )}
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={4} alignX="flex-end">
                            {availDt && <FormattedDate date={availDt} />}
                        </GridTable.Data>
                    </>
                ) : (
                    <>
                        {concept && (
                            <GridTable.Data className="text-bold mr-4" columnStart={1} rowStart={1} alignX="flex-start">
                                <Text ellipsis>{concept}</Text>
                            </GridTable.Data>
                        )}
                        <GridTable.Data
                            className="mr-4"
                            columnStart={1}
                            rowStart={concept ? 2 : 1}
                            rowWidth={concept ? 1 : 2}
                            alignX="flex-start">
                            {origDt && <FormattedDate date={origDt} />}
                        </GridTable.Data>

                        <GridTable.Data columnStart={2} rowStart={1} alignX="flex-end">
                            {amountStatement && amountStatement.currency && amountStatement.quantity && (
                                <FormattedAmount
                                    size="4"
                                    className={classNames("data-amount justify-content-end text-bold", {
                                        "is-negative": amountStatement.quantity < 0,
                                    })}
                                    quantity={amountStatement.quantity}
                                    noCurrency
                                />
                            )}
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} rowStart={2} alignX="flex-end">
                            {availDt && <FormattedDate date={availDt} />}
                        </GridTable.Data>
                    </>
                )}
            </GridTable.Container>
        );
    };

    const renderBody = () => {
        if (movementBlockedList.length === 0) {
            return (
                <Box display="flex" fullWidth alignX="center" className="no-more-data mt-10" key="noMoreMovements">
                    <Text
                        labelKey="creditCards.blocked.noMoreMovements"
                        component="p"
                        align="center"
                        bold
                        size="6"
                        color="text"
                        className="px-5"
                    />
                </Box>
            );
        }
        return movementBlockedList.map((movement) => renderItem(movement));
    };

    const renderList = () => (
        <Container className="container--layout scrollable movements-container">
            <GridTable>
                {renderTableHeader()}
                <GridTable.Body className="pb-10">{renderBody()}</GridTable.Body>
            </GridTable>
        </Container>
    );

    return (
        <Box className="min-height-10rem">
            <PageLoading loading={isFetchingMovements}>
                <>
                    {renderHeaderSection()}
                    {renderList()}
                </>
            </PageLoading>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    movementBlockedList: accountsSelectors.getMovementsBlocked(state),
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
});

MovementsBlocked.propTypes = {
    productId: string.isRequired,
    isDesktop: string.isRequired,
    isFetchingMovements: bool,
    movementBlockedList: arrayOf(shape({})),
    dispatch: func,
};

MovementsBlocked.defaultProps = {
    isFetchingMovements: false,
    movementBlockedList: [],
    dispatch: () => {},
};

export default connect(mapStateToProps)(resizableRoute(MovementsBlocked));

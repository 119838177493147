/* eslint-disable */
import { MODAL_TEXT_INFO } from "pages/settings/AuthenticatorHandler";
import TokeStatusQR from "pages/settings/TokenStatusQR";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, string } from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    actions as softTokenActions,
    selectors as softTokenSelectors,
} from "reducers/softTokenDisclaimer/softTokenDisclaimer.reducer";
import { isMobileNativeFunc } from "util/device";
import {
    CONFIG_SHOW_TOKEN_AUTHENTICATE_MOBILE,
    CONFIG_SHOW_TOKEN_AUTHENTICATE_WEB,
    CONFIG_SHOW_TOKEN_INACTIVE_MOBILE,
    CONFIG_SHOW_TOKEN_INACTIVE_WEB,
    CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_MOBILE,
    CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_WEB,
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
} from "util/userToken.util";
import * as config from "util/config";

const EntrustDisclaimerComponent = ({
    dispatch,
    tokenStatus,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
    isDesktop,
    redirectAbort,
    isFetchingTokenAction,
}) => {
    const [disclaimerData, setDisclaimerData] = useState();
    const [showDialogToken, setShowDialogToken] = useState(false);
    const [modalTextDrawer, setModalTextDrawer] = useState();

    const fetchValidateSoftTokenStatus = () => {
        dispatch(softTokenActions.validateTokenStatusRequest());
    };

    const showDisclaimer = useMemo(() => {
        const configShowKey = isMobileNativeFunc() ? disclaimerData?.configShowMobile : disclaimerData?.configShow;
        if (!configShowKey || configShowKey === "") {
            return false;
        }
        const configShow = config.get(configShowKey);
        return configShow && (configShow === "true" || configShow === true);
    }, [disclaimerData]);

    useEffect(() => {
        fetchValidateSoftTokenStatus();
    }, []);

    const redirectToTokenStatusFlow = () => {
        if (!isMobileNativeFunc()) {
            setModalTextDrawer(MODAL_TEXT_INFO);
            setShowDialogToken(true);
            return;
        }
        dispatch(
            softTokenActions.tokenStatusAction(redirectSuccess, redirectError, scopeSuccess, scopeError, redirectAbort),
        );
    };

    const getTokenStatusDisclaimer = (tokenStatusResponse) => {
        const disclaimerStatusTokenList = [
            USER_TOKEN_STATUS_INACTIVE,
            USER_TOKEN_STATUS_MIGRATE_LOCAL,
            USER_TOKEN_STATUS_AUTHENTICATE,
        ];

        if (!tokenStatusResponse || !disclaimerStatusTokenList.includes(tokenStatusResponse)) {
            return;
        }

        let configShowByStatus = null;
        let configShowMobileByStatus = null;

        switch (tokenStatusResponse) {
            case USER_TOKEN_STATUS_INACTIVE:
                configShowByStatus = CONFIG_SHOW_TOKEN_INACTIVE_WEB;
                configShowMobileByStatus = CONFIG_SHOW_TOKEN_INACTIVE_MOBILE;
                break;
            case USER_TOKEN_STATUS_MIGRATE_LOCAL:
                configShowByStatus = CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_WEB;
                configShowMobileByStatus = CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_MOBILE;
                break;
            case USER_TOKEN_STATUS_AUTHENTICATE:
                configShowByStatus = CONFIG_SHOW_TOKEN_AUTHENTICATE_WEB;
                configShowMobileByStatus = CONFIG_SHOW_TOKEN_AUTHENTICATE_MOBILE;
                break;
            default:
                break;
        }

        const disclaimerMap = {
            severity: "warning",
            content: "entrust.token.disclaimer.inactive.content",
            contentMobile: "entrust.token.disclaimer.inactive.content.mobile",
            leftIcon: <Image src="images/errorShield.svg" color="warning" {...(!isDesktop && { width: "6" })} />,
            configShow: configShowByStatus,
            configShowMobile: configShowMobileByStatus,
        };

        setDisclaimerData(disclaimerMap);
    };
    useEffect(() => {
        getTokenStatusDisclaimer(tokenStatus);
    }, [tokenStatus]);

    const renderDisclaimer = (disclaimer) => (
        <>
            {showDisclaimer && (
                <Box
                    display="flex"
                    alignY="center"
                    borderRadius="default"
                    {...(disclaimer?.severity && { border: `${disclaimer.severity}-1` })}
                    {...(disclaimer?.severity && { background: disclaimer.severity })}
                    gapX={5}
                    className="pl-4 pr-6 px-md-7 py-4 mb-5 entrust-disclaimer">
                    <Box display="flex" className="pt-1">
                        {disclaimer?.leftIcon && disclaimer.leftIcon}
                    </Box>
                    <Box display="flex" flex1={1}>
                        <Text
                            size="6"
                            color="text-boton"
                            className="entrust-disclaimer-text mr-md-7"
                            labelKey={
                                isMobileNativeFunc() ? disclaimer?.contentMobile || "" : disclaimer?.content || ""
                            }
                        />
                    </Box>
                    <Box display="flex">
                        <Button
                            label={
                                isMobileNativeFunc()
                                    ? "entrust.token.disclaimer.inactive.action.label"
                                    : "entrust.token.disclaimer.knowMore.action.label"
                            }
                            type="button"
                            loading={isFetchingTokenAction || false}
                            bsStyle={isDesktop ? "outline" : "link"}
                            {...(!isDesktop ? { small: true, className: "px-0" } : { className: "px-7" })}
                            onClick={() => {
                                redirectToTokenStatusFlow();
                            }}
                        />
                    </Box>
                </Box>
            )}
        </>
    );

    return (
        <>
            {disclaimerData && renderDisclaimer(disclaimerData)}
            {modalTextDrawer && (
                <SideBarModal
                    show={showDialogToken}
                    onClose={() => {
                        setShowDialogToken(false);
                    }}
                    title={`token.drawer.${modalTextDrawer.toLowerCase()}.title`}>
                    <TokeStatusQR tokenStatus={modalTextDrawer.toLowerCase()} />
                </SideBarModal>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    tokenStatus: softTokenSelectors.getTokenStatus(state),
    isFetchingTokenAction: softTokenSelectors.isFetchingTokenAction(state),
});
EntrustDisclaimerComponent.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    tokenStatus: string.isRequired,
    redirectSuccess: string,
    redirectError: string,
    scopeSuccess: arrayOf(string),
    scopeError: arrayOf(string),
    redirectAbort: string,
    isFetchingTokenAction: bool,
};
EntrustDisclaimerComponent.defaultProps = {
    redirectSuccess: undefined,
    redirectError: undefined,
    scopeSuccess: [],
    scopeError: [],
    redirectAbort: undefined,
    isFetchingTokenAction: false,
};

export default connect(mapStateToProps)(resizableRoute(withRouter(EntrustDisclaimerComponent)));

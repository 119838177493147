/* eslint-disable */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
    authenticateHandlerTypes,
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelectors,
} from "reducers/authenticateHandler";
import { selectors as sessionSelectors } from "reducers/session";
import * as softtokenApi from "middleware/softToken/softToken";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { replace } from "react-router-redux";
import { buildEntrustKey, buildEnvironmentKey, deleteIdentity, getEntrustKeys } from "util/softToken.util";
import { USER_AUTHENTICATOR_PASSWORD } from "util/userToken.util";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const sagas = [
    takeEvery(authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST, getTokenStatusRequest),
    takeEvery(authenticateHandlerTypes.GET_TOKEN_INFO_REQUEST, getTokenInfoRequest),
    takeEvery(authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST, getAuthenticatorStatusRequest),
    takeEvery(authenticateHandlerTypes.DESACTIVATE_TOKEN_REQUEST, desactivateSoftToken),
    takeEvery(authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_REQUEST, unlockAuthenticator),
    takeEvery(authenticateHandlerTypes.DELETE_TOKEN_PIN_REQUEST, deleteTokenPinRequest),
    takeEvery(authenticateHandlerTypes.VALIDATE_TOKEN_PIN_REQUEST, validateTokenPinRequest),
    takeEvery(authenticateHandlerTypes.GET_TOKEN_INFO_STATUS_REQUEST, getTokenInfoStatusRequest),
    takeEvery(authenticateHandlerTypes.GET_TOKEN_LIST_REQUEST, getTokenListRequest),
];

export default sagas;

// Valida estado del token por user_id
function* getTokenStatusRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateStatusToken, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { tokenStatus, pinCode, pinTries, serialToken } = response.data.data;
        yield put(authenticateHandlerActions.getTokenStatusSuccess(tokenStatus, pinCode, pinTries, serialToken));
    }
}

// Devuelve la info del token y actualiza el listado
function* getTokenInfoRequest({ environmentType, entrustKey }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const entrustKeyArray = entrustKey ? entrustKey.split("_") : [];
    const entrustKeyResult = entrustKey.length > 0 ? entrustKeyArray[0] : "";
    const response = yield call(softtokenApi.getTokenInfo, deviceUuid, environmentType, entrustKeyResult);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure([]));
    } else {
        const { pinCode, pinTries, tokenStatus, userName } = response.data.data;
        const listTokens = yield select(authenticateHandlerSelectors.getListTokens);
        const listResult = [...listTokens];
        const newToken = {
            entrustKey,
            environmentType,
            tokenValidateStatus: tokenStatus,
            pinCode,
            pinTries,
            fetching: false,
            userName,
            isPinCodeValid: false,
            isPinProtectionValidatedCount: 0,
            isPinProtectionValidated: false,
        };
        if (listTokens.findIndex((el) => el.entrustKey === entrustKey) === -1) {
            listResult.push(newToken);
        } else {
            listResult.forEach((ele) => {
                if (ele.entrustKey === entrustKey) {
                    ele.tokenValidateStatus = newToken.tokenValidateStatus;
                    ele.pinCode = newToken.pinCode;
                    ele.pinTries = newToken.pinTries;
                    ele.fetching = false;
                    ele.userName = newToken.userName;
                }
            });
        }
        yield put(authenticateHandlerActions.getTokenInfoSuccess(listResult));
    }
}

// elimina pin de token
function* deleteTokenPinRequest() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.deleteTokenPinRequest, deviceUuid);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("token.pin.delete.message"), "success", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
        yield put(authenticateHandlerActions.deleteTokenPinSucces());
    }
}

// validacion de pin del token y retorna listado
function* validateTokenPinRequest({ pinCode, environmentType, entrustKey, handleCloseModal }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const entrustKeyArray = entrustKey ? entrustKey.split("_") : [];
    const entrustKeyResult = entrustKey.length > 0 ? entrustKeyArray[0] : "";
    const response = yield call(
        softtokenApi.validateTokenPinRequest,
        deviceUuid,
        pinCode,
        environmentType,
        entrustKeyResult,
    );

    if (typeof handleCloseModal === "function") {
        handleCloseModal(false);
    }

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["externalLayout"]),
        );
    } else {
        const { isPinCodeValid, pinTries } = response.data.data;
        const listTokens = yield select(authenticateHandlerSelectors.getListTokens);
        const listResult = [...listTokens];
        listResult.forEach((ele) => {
            if (ele.entrustKey === entrustKey) {
                ele.isPinCodeValid = isPinCodeValid;
                ele.pinTries = pinTries;
                ele.isPinProtectionValidatedCount += 1;
                ele.isPinProtectionValidated = !isPinCodeValid;
                ele.fetching = false;
            }
        });

        yield put(authenticateHandlerActions.validateTokenPinSucces(listResult));

        if (!isPinCodeValid) {
            yield put(
                notificationActions.showNotification(i18n.get("token.pin.pinCode.noValid"), "error", [
                    "externalLayout",
                ]),
            );
        }
    }
}

function* getAuthenticatorStatusRequest({ authenticatorType }) {
    const response = yield call(softtokenApi.validateStatusAuthenticator, authenticatorType);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        const { authenticatorStatus } = response.data.data;
        yield put(authenticateHandlerActions.getPasswordStatusSuccess(authenticatorStatus));
    }
}

function* desactivateSoftToken({ redirect, isFromMigrateScreen }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "token.entrust.desactivateToken");
    const response = yield call(softtokenApi.desactivateUserToken, deviceUuid, fingerPrintToken);
    if (response.type === "W") {
        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_FAILED });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.error.message"), "error", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
    } else {
        if (window?.app?.getDeviceUUID()) {
            const entrustKey = buildEntrustKey() || "";
            const enviromentType = buildEnvironmentKey() || "";
            try {
                yield call(deleteIdentity, entrustKey, enviromentType);
            } catch (error) {
                console.log("error", error);
            }
        }
        yield put({ type: authenticateHandlerTypes.DESACTIVATE_TOKEN_SUCCESS });
        yield put({ type: authenticateHandlerTypes.GET_TOKEN_STATUS_REQUEST });
        yield put(
            notificationActions.showNotification(i18n.get("token.desactivate.success.message"), "success", [
                "settings/authenticatorHandler",
                "/desktop",
            ]),
        );
        if (isFromMigrateScreen) {
            if (redirect) {
                yield put(replace(redirect ?? "/desktop"));
            }
        }
    }
}

function* unlockAuthenticator({ authenticatorType }) {
    const response = yield call(softtokenApi.unlockUserAuthenticator, authenticatorType);

    if (response && response.type === "I") {
        yield put({ type: authenticateHandlerTypes.UNLOCK_AUTHENTICATOR_SUCCESS });
        yield put({
            type: authenticateHandlerTypes.GET_PASSWORD_STATUS_REQUEST,
            authenticatorType: USER_AUTHENTICATOR_PASSWORD,
        });
        yield put(
            notificationActions.showNotification(i18n.get("authenticator.password.unlock.success.message"), "success", [
                "settings/authenticatorHandler",
            ]),
        );
    } else {
        yield put({
            type: authenticateHandlerTypes.CLEAN,
        });
        yield put(
            notificationActions.showNotification(i18n.get("authenticator.password.unlock.error.message"), "error", [
                "settings",
            ]),
        );
        yield put(replace("/settings"));
    }
}

// devuelve estado del token y actualiza listado de tokens
function* getTokenInfoStatusRequest({ onFinish, environmentType, entrustKey }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const entrustKeyArray = entrustKey ? entrustKey.split("_") : [];
    const entrustKeyResult = entrustKey.length > 0 ? entrustKeyArray[0] : "";
    const response = yield call(softtokenApi.getTokenInfo, deviceUuid, environmentType, entrustKeyResult);

    const listTokens = yield select(authenticateHandlerSelectors.getListTokens);
    const listResult = [...listTokens];

    if (typeof onFinish === "function") {
        onFinish();
    }

    if (!response?.type || !response?.data?.data) {
        listResult.forEach((el) => {
            if (el.entrustKey === entrustKey) {
                el.fetchingTokenValidateStatus = false;
            }
        });
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure(listResult));
        yield;
        return;
    }

    if (response.type === "W") {
        listResult.forEach((el) => {
            if (el.entrustKey === entrustKey) {
                el.fetchingTokenValidateStatus = false;
            }
        });
        yield put(authenticateHandlerActions.getTokenInfoStatusFailure(listResult));
        yield;
        return;
    }

    const { tokenStatus, userName, pinCode, pinTries } = response.data.data;
    const newToken = {
        entrustKey,
        environmentType,
        tokenValidateStatus: tokenStatus,
        pinCode,
        pinTries,
        fetchingTokenValidateStatus: false,
        userName,
        isPinCodeValid: false,
        isPinProtectionValidatedCount: 0,
        isPinProtectionValidated: false,
    };
    if (listTokens.findIndex((el) => el.entrustKey === entrustKey) === -1) {
        listResult.push(newToken);
    } else {
        listResult.forEach((ele) => {
            if (ele.entrustKey === entrustKey) {
                ele.tokenValidateStatus = newToken.tokenValidateStatus;
                ele.pinCode = newToken.pinCode;
                ele.pinTries = newToken.pinTries;
                ele.fetchingTokenValidateStatus = false;
                ele.userName = newToken.userName;
            }
        });
    }
    yield put(authenticateHandlerActions.getTokenInfoStatusSuccess(listResult));
}

// devuelve listado de token actual, compara con listado telefono y elimina cuando no existe
function* getTokenListRequest({ onFinish }) {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const isActiveCorporate = yield select(sessionSelectors.isActiveCorporate);
    const environmentType = isActiveCorporate ? "CORPORATE" : "RETAIL";
    const response = yield call(softtokenApi.getTokenList, deviceUuid, environmentType);

    if (!response?.type || !response?.data?.data) {
        yield put(authenticateHandlerActions.getTokenListFailure());
        yield;
        return;
    }

    if (response.type === "W") {
        yield put(authenticateHandlerActions.getTokenListFailure());
        yield;
        return;
    }

    const { listToken } = response.data.data;

    //compara tokens existentes
    try {
        const { corporateKeys, retailKeys } = yield call(getEntrustKeys);
        let listTokenRes = [...listToken];
        let keys = "";
        let showDetails = false;
        let corporateKeysRes = [];

        if (isActiveCorporate) {
            let keyDelete = [];
            corporateKeysRes = corporateKeys.filter((el) => {
                if (!listTokenRes.some((elem) => el?.key.includes(elem?.userId))) {
                    if (window?.app?.getDeviceUUID()) {
                        keyDelete.push({ entrustKey: el?.key || "", enviromentType: el?.environmentType || "" });
                    }
                } else {
                    return el;
                }
            });

            for (const element of keyDelete) {
                try {
                    yield call(deleteIdentity, element.entrustKey, element.enviromentType);
                } catch (error) {
                    console.log("error", error);
                }
            }
        }
        //asigno con los token filtrados
        if (!isActiveCorporate) {
            keys = retailKeys[0];
        }
        if (isActiveCorporate && corporateKeysRes.length === 1) {
            keys = corporateKeysRes[0];
        }
        if (isActiveCorporate && corporateKeysRes.length > 1) {
            keys = corporateKeysRes[0];
            showDetails = true;
        }
        if (typeof onFinish === "function") {
            onFinish(keys, showDetails);
        }
    } catch (error) {
        yield put(authenticateHandlerActions.getTokenListFailure());
    }
    yield put(authenticateHandlerActions.getTokenListSuccess());
}

import React from "react";
import { arrayOf, bool, func, oneOfType, string, shape, number, array } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import CheckboxGroup from "pages/forms/_components/_fields/_commons/CheckboxGroup";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Select from "pages/forms/_components/_fields/Select";

class Selector extends React.Component {
    static propTypes = {
        optionList: arrayOf(shape({ id: oneOfType([string, number]), label: string })).isRequired,
        idField: string.isRequired,
        renderAs: string.isRequired,
        defaultValue: oneOfType([string, shape({}), array]),
        setValue: func.isRequired,
        editing: bool.isRequired,
        value: oneOfType([string, shape({}), array]),
        placeholder: string,
        name: string.isRequired,
        onChange: func,
        customPlaceholder: string,
        radioButtonGroupFlex: bool,
        disabled: bool,
        alwaysDown: bool,
        autoFocus: bool,
        selectRef: shape({}),
        delayAutoFocus: number,
    };

    static defaultProps = {
        value: undefined,
        placeholder: "",
        onChange: () => {},
        customPlaceholder: "",
        defaultValue: undefined,
        radioButtonGroupFlex: false,
        disabled: false,
        alwaysDown: false,
        autoFocus: false,
        selectRef: null,
        delayAutoFocus: null,
    };

    componentDidMount() {
        const { defaultValue, value, setValue, selectRef, autoFocus, delayAutoFocus } = this.props;
        if (defaultValue && !value) {
            setValue([defaultValue]);
        } else if (!value) {
            setValue(null);
        }
        if (autoFocus && delayAutoFocus) {
            setTimeout(() => {
                selectRef.current.focus();
            }, delayAutoFocus);
        }
    }

    componentDidUpdate() {
        const { defaultValue, value, setValue } = this.props;
        if (defaultValue && !value) {
            setValue([defaultValue]);
        } else if (!value) {
            setValue(null);
        }
    }

    handleChange = (newValue) => {
        const { setValue, onChange } = this.props;

        const selectedValues = [newValue];

        setValue(selectedValues);

        onChange(selectedValues);
    };

    viewAsCheck() {
        const { optionList, value } = this.props;
        const checkValue = value || [];

        return <CheckboxGroup options={optionList} values={checkValue} onChange={this.handleChange} mode="view" />;
    }

    renderAsCombo() {
        const {
            optionList,
            value,
            placeholder,
            idField,
            customPlaceholder,
            disabled,
            alwaysDown,
            delayAutoFocus,
            autoFocus,
            selectRef,
        } = this.props;
        const comboValue = value ? value[0] : "";
        const placeHol = customPlaceholder || placeholder;

        return (
            <div className="input-group">
                <Select
                    {...(selectRef && { selectRef })}
                    id={idField}
                    placeholder={placeHol}
                    value={comboValue?.id}
                    disabled={disabled}
                    clearable={false}
                    searchable={false}
                    onChange={this.handleChange}
                    valueKey="id"
                    labelKey="label"
                    options={optionList}
                    className="flex-container slideFromBottom"
                    alwaysDown={alwaysDown}
                    {...(!delayAutoFocus && { autoFocus })}
                />
            </div>
        );
    }

    renderAsCheck() {
        const { optionList, value, name } = this.props;
        const checkValue = value || [];

        return <CheckboxGroup options={optionList} values={checkValue} onChange={this.handleChange} name={name} />;
    }

    renderAsRadio() {
        const { optionList, value, idField, name, radioButtonGroupFlex } = this.props;
        const radioValue = value ? value[0] : "";

        return (
            <RadioButtonGroup
                value={radioValue}
                selectorId={idField}
                options={optionList}
                onChange={this.handleChange}
                name={name}
                radioButtonGroupFlex={radioButtonGroupFlex}
            />
        );
    }

    renderEditMode() {
        const { renderAs } = this.props;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }
        if (renderAs === "check") {
            return this.renderAsCheck();
        }
        return this.renderAsRadio();
    }

    renderViewMode() {
        const { value, renderAs } = this.props;

        if (renderAs === "check") {
            return this.viewAsCheck();
        }

        return (
            <ul>
                <li key={value.id}>{value.label}</li>
            </ul>
        );
    }

    render() {
        const { editing } = this.props;
        if (editing) {
            return this.renderEditMode();
        }
        return this.renderViewMode();
    }
}

const options = () => {
    const { renderAs } = this.props;
    const asLegend = renderAs === "radio";

    return {
        formClass: "form-group--select",
        isEmptyValue: (value) => value.length === 0,
        isValidValue: (value) => Array.isArray(value),
        renderLegend: asLegend,
    };
};

export default formField(options)(Selector);

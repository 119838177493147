import { COMPANY_NAME } from "constants.js";
import ListItem from "pages/_components/listItem/ListItem";
import { bool, func, number as numberType, shape, string } from "prop-types";
import React, { Component } from "react";
import { isMobileNativeFunc } from "util/device";
import { shareSocialText } from "util/download";
import * as i18nUtils from "util/i18n";
import { connect } from "react-redux";
import { actions as notificationActions } from "reducers/notification";
import { push } from "react-router-redux";

class AccountsListItem extends Component {
    static propTypes = {
        amountLabel: string,
        balance: numberType.isRequired,
        client: shape({}),
        completeFavorites: bool,
        hideAmountLabel: bool,
        idProduct: string,
        number: string.isRequired,
        numberElements: numberType,
        productAlias: string,
        productType: string.isRequired,
        setFavoriteAccount: func,
        showLink: bool,
        showProductIcon: bool,
        showStar: bool,
        showStatusIcon: bool,
        wally: bool,
        status: string.isRequired,
        dispatch: func.isRequired,
        kuara: bool,
    };

    static defaultProps = {
        amountLabel: "accounts.availableBalance",
        client: {},
        completeFavorites: false,
        hideAmountLabel: false,
        idProduct: null,
        numberElements: null,
        productAlias: null,
        setFavoriteAccount: () => {},
        showLink: true,
        showProductIcon: true,
        showStar: false,
        showStatusIcon: false,
        wally: false,
        kuara: false,
    };

    productType = () => {
        const { productType } = this.props;

        if (productType === "CA") {
            return i18nUtils.get("accounts.productType.savings");
        }

        return i18nUtils.get("accounts.productType.checking");
    };

    getAccountShare = () => {
        const { client, number, productType } = this.props;
        if (!client || !client.name || !number || !productType) {
            return undefined;
        }
        return `${client.name}\n${COMPANY_NAME}\n${i18nUtils.get(`productType.${productType}`)} ${number}`;
    };

    render() {
        const {
            productAlias,
            number,
            balance,
            amountLabel,
            idProduct,
            hideAmountLabel,
            showLink,
            showProductIcon,
            showStar,
            setFavoriteAccount,
            completeFavorites,
            showStatusIcon,
            numberElements,
            wally,
            kuara,
            status,
            dispatch,
            ...props
        } = this.props;
        const productName = productAlias || number;
        const reference = `${number}`;
        const productTypeTitle = i18nUtils.get("desktop.widgets.account");

        const transferHref = numberElements > 1 ? "/formCustom/transferInternal" : "/formCustom/transferLocal";

        const contextOptions = [
            {
                label: "contextMenu.accounts.widget.options.transfer",
                onClick: () => {
                    if (status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["desktop"],
                            ),
                        );
                        return;
                    }
                    dispatch(push(transferHref));
                },
            },
            {
                label: "contextMenu.accounts.widget.options.pay.service",
                onClick: () => {
                    if (status !== "ACTIVA") {
                        dispatch(
                            notificationActions.showNotification(
                                i18nUtils.get("accounts.buttons.inactiveAccount"),
                                "error",
                                ["desktop"],
                            ),
                        );
                        return;
                    }
                    dispatch(push("/servicePayments"));
                },
            },
        ];

        if (isMobileNativeFunc()) {
            contextOptions.push({
                label: "contextMenu.accounts.widget.options.share",
                onClick: () => {
                    const textShared = this.getAccountShare();
                    if (textShared && textShared.length > 0) {
                        shareSocialText(textShared);
                    }
                },
            });
        }
        return (
            <ListItem
                {...props}
                idProduct={idProduct}
                productTypeTitle={productTypeTitle}
                title={this.productType()}
                name={productName}
                reference={reference}
                amount={balance}
                amountLabel={!hideAmountLabel && i18nUtils.get(amountLabel)}
                path={showLink ? `/accounts/${idProduct}` : null}
                contextOptions={showProductIcon ? contextOptions : null}
                showProductIcon={showProductIcon}
                showStar={showStar}
                setFavoriteAccount={setFavoriteAccount}
                completeFavorites={completeFavorites}
                showStatusIcon={showStatusIcon}
                wallyProduct={wally}
                kuaraProduct={kuara}
                hideChevron
                status={status}
            />
        );
    }
}

export default connect()(AccountsListItem);

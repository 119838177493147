import classNames from "classnames";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import Image from "pages/_components/Image";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";

class Checkbox extends Component {
    static propTypes = {
        checked: oneOfType([bool, number]),
        name: string,
        labelText: string,
        onChange: func,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: bool,
        }),
        idForm: string,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }),
        labelTextProps: shape({}),
        labelColor: string,
        labelSize: string,
        className: string,
        noLabel: bool,
        disabled: bool,
        topFixed: bool,
        gap: string,
        relativeStyleError: bool,
    };

    static defaultProps = {
        onChange: () => {},
        checked: false,
        name: "",
        labelText: "",
        field: null,
        idForm: "",
        form: null,
        labelTextProps: {},
        labelColor: "",
        labelSize: null,
        className: null,
        noLabel: false,
        disabled: false,
        topFixed: false,
        gap: "3",
        relativeStyleError: false,
    };

    render() {
        const {
            checked,
            onChange,
            name,
            labelText,
            field,
            form,
            idForm,
            labelTextProps,
            className,
            noLabel,
            labelColor,
            disabled,
            labelSize,
            topFixed,
            relativeStyleError,
            gap,
        } = this.props;

        const controlName = field ? field.name : name;
        let hasError = false;
        let error = "";
        if (form) {
            const { touched, errors } = form;
            hasError = touched[field.name] && errors[field.name];
            error = errors[field.name];
        }

        return (
            <div
                className={classNames(
                    "c-control",
                    "c-control--has-icon c-control--checkbox display-flex align-items-center position-relative",
                    className,
                    {
                        "has-error": hasError,
                        "top-fixed": topFixed,
                        [`gap-${gap}`]: gap,
                    },
                )}>
                <input
                    id={`checkbox_${controlName}`}
                    className="c-control-input"
                    type="checkbox"
                    name={controlName}
                    checked={field ? field.value : checked}
                    onChange={() => {
                        if (field && form) {
                            form.setFieldValue(field.name, !field.value);
                        }
                        onChange(field ? !field.value : !checked);
                    }}
                    disabled={disabled}
                />
                <label className="c-control-label m-0" htmlFor={`checkbox_${controlName}`}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {!noLabel && (
                        <div className="c-control-text">
                            {labelText && (
                                <Text {...labelTextProps} labelKey={labelText} color={labelColor} size={labelSize} />
                            )}
                            {!labelText && field && (
                                <Text labelKey={`${idForm}.${field?.name}.label`} color={labelColor} size={labelSize} />
                            )}
                        </div>
                    )}
                </label>
                {hasError && <FieldError error={error} className="top-100" relativeStyleError={relativeStyleError} />}
            </div>
        );
    }
}

export default Checkbox;

import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    products: [],
    permissions: null,
    futurePermissions: null,
    groups: [],
    fetching: true,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    permissionConfigurationDetails: [],
    trasnfersEditionResult: [],
    paymentsEditionResult: [],
    transfersInitialPermissions: [],
    paymentsInitialPermissions: [],
    otherPermissionsEditionResult: null,
};

const parsePermissionUI = (permissionList, currentValue) => {
    if (!permissionList.length) {
        return currentValue;
    }
    const listCopy = JSON.parse(JSON.stringify(permissionList));
    let i;
    for (i = 0; i < listCopy.length; i++) {
        listCopy[i].maxAutonomyAmount.quantity = listCopy[i].maxAutonomyAmount.quantity.toFixed(2);
        listCopy[i].maxSignatureAmount.quantity = listCopy[i].maxSignatureAmount.quantity.toFixed(2);
    }
    return listCopy;
};

export const selectors = {
    getProducts: ({ permissions }) => permissions.products,
    getPermissions: ({ permissions }) => permissions.permissions,
    getFuturePermissions: ({ permissions }) => permissions.futurePermissions,
    getGroups: ({ permissions }) => permissions.groups,
    isFetching: ({ permissions }) => permissions.fetching,
    getIdTransaction: ({ permissions }) => permissions.idTransaction,
    getIdActivity: ({ permissions }) => permissions.idActivity,
    getCredentialGroups: ({ permissions }) => permissions.credentialGroups,
    // getPermissionConfigurationDetails: ({ permissions }) => permissions.permissionConfigurationDetails,
    getPermissionConfigurationDetails: ({ permissions }) =>
        (permissions.trasnfersEditionResult || []).concat(permissions.paymentsEditionResult || []),
    getTrasnfersEditionResult: ({ permissions }) => permissions.trasnfersEditionResult,
    getPaymentsEditionResult: ({ permissions }) => permissions.paymentsEditionResult,
    getTransfersInitialPermissions: ({ permissions }) => permissions.transfersInitialPermissions,
    getPaymentsInitialPermissions: ({ permissions }) => permissions.paymentsInitialPermissions,
    getOtherPermissionsEditionResult: ({ permissions }) => permissions.otherPermissionsEditionResult,
};

export default (name) => {
    const types = {
        LOAD_PERMISSIONS_REQUEST: `${name}/LOAD_PERMISSIONS_REQUEST`,
        LOAD_PERMISSIONS_FAILURE: `${name}/LOAD_PERMISSIONS_FAILURE`,
        LOAD_PERMISSIONS_SUCCESS: `${name}/LOAD_PERMISSIONS_SUCCESS`,
        LOAD_PERMISSIONS_DETAIL_REQUEST: `${name}/LOAD_PERMISSIONS_DETAIL_REQUEST`,
        LOAD_PERMISSIONS_DETAIL_FAILURE: `${name}/LOAD_PERMISSIONS_DETAIL_FAILURE`,
        LOAD_PERMISSIONS_DETAIL_SUCCESS: `${name}/LOAD_PERMISSIONS_DETAIL_SUCCESS`,
        UPDATE_PERMISSIONS_PREVIEW: `${name}/UPDATE_PERMISSIONS_PREVIEW`,
        UPDATE_PERMISSIONS_PREVIEW_SUCCESS: `${name}/UPDATE_PERMISSIONS_PREVIEW_SUCCESS`,
        UPDATE_PERMISSIONS_REQUEST: `${name}/UPDATE_PERMISSIONS_REQUEST`,
        UPDATE_PERMISSIONS_REQUEST_SUCCESS: `${name}/UPDATE_PERMISSIONS_REQUEST_SUCCESS`,
        SAVE_LOCAL_PERMISSIONS_EDITION: `${name}/SAVE_LOCAL_PERMISSIONS_EDITION`,
        RESET_LOCAL_PERMISSIONS_EDITION: `${name}/RESET_LOCAL_PERMISSIONS_EDITION`,
        LOAD_PERMISSIONS_TICKET_REQUEST: `${name}/LOAD_PERMISSIONS_TICKET_REQUEST`,
        LOAD_PERMISSIONS_TICKET_SUCCESS: `${name}/LOAD_PERMISSIONS_TICKET_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_PERMISSIONS_TICKET_REQUEST]: (state) => ({ ...state, fetching: true }),
            [types.LOAD_PERMISSIONS_TICKET_SUCCESS]: (state, { data }) => ({
                ...state,
                fetching: false,
                trasnfersEditionResult: data.data.permissionsUi.transfers,
                paymentsEditionResult: data.data.permissionsUi.payments,
                transfersInitialPermissions: data.data.permissionsUi.transfers,
                paymentsInitialPermissions: data.data.permissionsUi.payments,
            }),
            [types.LOAD_PERMISSIONS_REQUEST]: (state) => ({ ...state, fetching: true, futurePermissions: null }),
            [types.LOAD_PERMISSIONS_FAILURE]: (state) => ({ ...state, fetching: false }),

            [types.LOAD_PERMISSIONS_SUCCESS]: (state, { data }) => ({
                ...state,
                permissions: data.permissions,
                products: data.products,
                groups: data.groups,
                fetching: false,
            }),
            [types.LOAD_PERMISSIONS_DETAIL_REQUEST]: (state) => ({
                ...state,
                fetching: true,
                trasnfersEditionResult: [],
                paymentsEditionResult: [],
                otherPermissionsEditionResult: null,
                transfersInitialPermissions: [],
                paymentsInitialPermissions: [],
            }),
            [types.LOAD_PERMISSIONS_DETAIL_FAILURE]: (state) => ({ ...state, fetching: false }),

            [types.LOAD_PERMISSIONS_DETAIL_SUCCESS]: (state, { data }) => ({
                ...state,
                trasnfersEditionResult: parsePermissionUI(
                    [...data.permissionsUI.transfers],
                    state.trasnfersEditionResult,
                ),
                transfersInitialPermissions: parsePermissionUI(
                    [...data.permissionsUI.transfers],
                    state.transfersInitialPermissions,
                ),
                paymentsEditionResult: parsePermissionUI([...data.permissionsUI.payments], state.paymentsEditionResult),
                paymentsInitialPermissions: parsePermissionUI(
                    [...data.permissionsUI.payments],
                    state.paymentsInitialPermissions,
                ),
                fetching: false,
            }),
            [types.UPDATE_PERMISSIONS_PREVIEW_SUCCESS]: (
                state,
                { permissions, credentialGroups, idActivity, idTransaction },
            ) => ({
                ...state,
                futurePermissions: permissions,
                idActivity,
                idTransaction,
                credentialGroups,
            }),
            [types.UPDATE_PERMISSIONS_REQUEST_SUCCESS]: (state) => ({
                ...state,
                permissions: state.futurePermissions,
                fetching: false,
                futurePermissions: null,
            }),
            [types.SAVE_LOCAL_PERMISSIONS_EDITION]: (state, action) => ({
                ...state,
                futurePermissions: null,
                trasnfersEditionResult: action.sectionId === "transfers" ? action.values : state.trasnfersEditionResult,
                paymentsEditionResult: action.sectionId === "payments" ? action.values : state.paymentsEditionResult,
                otherPermissionsEditionResult:
                    action.sectionId === "other" ? action.values : state.otherPermissionsEditionResult,
            }),
            [types.RESET_LOCAL_PERMISSIONS_EDITION]: (state) => ({
                ...state,
                trasnfersEditionResult: [],
                paymentsEditionResult: [],
                transfersInitialPermissions: [],
                paymentsInitialPermissions: [],
                otherPermissionsEditionResult: null,
            }),
        }),
        actions: {
            loadPermissionsRequest: makeActionCreator(types.LOAD_PERMISSIONS_REQUEST, "id"),
            loadPermissionsFailure: makeActionCreator(types.LOAD_PERMISSIONS_FAILURE),
            loadPermissionsSuccess: makeActionCreator(types.LOAD_PERMISSIONS_SUCCESS, "data"),
            loadPermissionsDetailsRequest: makeActionCreator(types.LOAD_PERMISSIONS_DETAIL_REQUEST, "id", "sectionId"),
            loadPermissionsDetailsSuccess: makeActionCreator(types.LOAD_PERMISSIONS_DETAIL_SUCCESS, "data"),
            loadPermissionsDetailsFailure: makeActionCreator(types.LOAD_PERMISSIONS_DETAIL_FAILURE),
            updatePermissionsPreview: makeActionCreator(types.UPDATE_PERMISSIONS_PREVIEW, "data", "setSubmitting"),
            updatePermissionsPreviewSuccess: makeActionCreator(
                types.UPDATE_PERMISSIONS_PREVIEW_SUCCESS,
                "permissions",
                "credentialGroups",
                "idTransaction",
                "idActivity",
            ),
            updatePermissionsRequest: makeActionCreator(types.UPDATE_PERMISSIONS_REQUEST, "data", "formikBag"),
            updatePermissionsRequestSuccess: makeActionCreator(types.UPDATE_PERMISSIONS_REQUEST_SUCCESS),
            saveLocalPermissionEdition: makeActionCreator(types.SAVE_LOCAL_PERMISSIONS_EDITION, "values", "sectionId"),
            resetLocalPermissionEdition: makeActionCreator(types.RESET_LOCAL_PERMISSIONS_EDITION),
        },
    };
};

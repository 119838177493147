import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import ChipsButtonsGroup from "pages/_components/ChipsButtonsGroup";
import Dropdown from "pages/_components/Dropdown";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import BanescoCheckbox from "pages/_components/fields/BanescoCheckbox";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/users";
import { actions as userInviteActions } from "reducers/administration/usersInvite";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as invitationsAction, selectors as invitationsSelector } from "reducers/administration/invitations";
import * as i18n from "util/i18n";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import {
    USER_TOKEN_STATUS_ACTIVE,
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_STARTED,
    USER_TOKEN_STATUS_LOCKED_ENTRUST,
    USER_TOKEN_STATUS_PENDING_APROVE,
    USER_TOKEN_STATUS_LOCKED_LOCAL,
} from "util/userToken.util";
import { push } from "react-router-redux";

// NO BORRAR (IMPLEMENTACION DE PRODUCTO)
// import I18n from "pages/_components/I18n";
// import Checkbox from "pages/_components/fields/Checkbox";
// import FeatureFlag from "pages/_components/FeatureFlag";
// import Table from "rc-table";

// NO BORRAR (IMPLEMENTACION DE PRODUCTO)
// const UserName = ({ name, dispatcher, massiveEnabled }) => (
//     <div className="data-wrapper data-wrapper-flex">
//         <span className="data-desc">{name}</span>
//         {!massiveEnabled && <Image src="images/administrator.svg" className="svg-icon svg-caret" />}
//         <FeatureFlag id="feature.signatureSchema.dispatchControl">
//             {dispatcher && <Image src="images/dispatcher.svg" className="svg-icon svg-caret" />}
//         </FeatureFlag>
//     </div>
// );

// NO BORRAR (IMPLEMENTACION DE PRODUCTO)
// UserName.propTypes = {
//     name: string.isRequired,
//     dispatcher: bool.isRequired,
//     massiveEnabled: bool.isRequired,
// };

class AdministrationUsers extends Component {
    contextOptions = [
        {
            id: "1",
            label: "administration.users.list.pending.resendInvitation",
            onClick: (invitation) => {
                this.setState({
                    showResendDeleteModal: true,
                    invitationSelected: invitation,
                    invitationOptionSelected: "resend",
                });
            },
        },
        {
            id: "2",
            label: "administration.users.list.pending.editInvitation",
            onClick: (invitation) => {
                this.onEditClick(invitation);
                this.setState({
                    invitationOptionSelected: "edit",
                });
            },
        },
        {
            id: "3",
            label: "administration.users.list.pending.deleteInvitation",
            onClick: (invitation) => {
                this.setState({
                    showResendDeleteModal: true,
                    invitationSelected: invitation,
                    invitationOptionSelected: "delete",
                });
            },
        },
    ];

    static propTypes = {
        dispatch: func.isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        users: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
        usersInfo: objectOf(
            shape({
                status: string.isRequired,
                signatureLevel: string,
            }),
        ).isRequired,
        currentPage: number,
        totalPages: number,
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreUsers: bool,
        hasMoreData: bool,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        invitations: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
        fetchingInvitations: bool,
        invCurrentPage: number,
        invTotalPages: number,
        fetchingMoreInvitations: bool,
        invHasMoreData: bool,
    };

    static defaultProps = {
        currentPage: 0,
        totalPages: 0,
        fetching: false,
        fetchingExport: false,
        fetchingMoreUsers: false,
        hasMoreData: false,

        invCurrentPage: 0,
        invTotalPages: 0,
        fetchingInvitations: false,
        fetchingMoreInvitations: false,
        invHasMoreData: false,
    };

    state = {
        selectedUsers: new Map(),
        selectAllChecked: false,
        blockedQuantity: 0,
        unblockedQuantity: 0,
        selectedButtonId: "users",
        fetchingAction: false,
        showResendDeleteModal: false,
        invitationSelected: {},
        invitationOptionSelected: undefined,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.loadListRequest());
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch } = this.props;
        const { selectedButtonId } = this.state;

        if (selectedButtonId !== prevState.selectedButtonId && selectedButtonId !== "users" && selectedButtonId) {
            dispatch(invitationsAction.loadListRequest());
        }
    }

    getColumnsTemplate = () => {
        const { selectedButtonId } = this.state;
        if (selectedButtonId === "users") {
            return "1fr 1fr 1fr 1fr 5rem 1fr 1.5rem";
        }
        return "minmax(max-content, 6rem) 1fr 1fr 1fr 1fr 2.5rem";
    };

    onRowClick = (record) => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;
        const path = {
            simple: "simple/permissions",
            medium: "medium/details",
            advanced: "advanced/details",
        };

        dispatch(routerActions.push(`/administration/${path[administrationScheme]}/${record.idUser}`));
    };

    onEditClick = (invitation) => {
        const { dispatch } = this.props;
        dispatch(userInviteActions.setUserInvitationToEdit(invitation));
    };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // generateTableColumns = (massiveDissabledQuantity) => {
    //     const { selectAllChecked, selectedUsers } = this.state;
    //     const { activeEnvironment, users } = this.props;
    //     const columnArray = [
    //         {
    //             key: "check",
    //             dataIndex: "check",
    //             title: (
    //                 <Checkbox
    //                     hideLabel
    //                     onChange={this.handleSelectAll}
    //                     name="selectAllUsers"
    //                     checked={
    //                         selectAllChecked ||
    //                         (users.length === selectedUsers.size + massiveDissabledQuantity && selectedUsers.size !== 0)
    //                     }
    //                     disabled={users.length === massiveDissabledQuantity}
    //                 />
    //             ),
    //             width: 20,
    //             onCell: () => ({
    //                 onClick(e) {
    //                     e.stopPropagation();
    //                 },
    //             }),
    //         },
    //         {
    //             key: "userName",
    //             dataIndex: "userName",
    //             title: i18n.get("administration.users.list.username"),
    //             width: 150,
    //         },
    //         {
    //             className: "text-ellipsis",
    //             key: "email",
    //             dataIndex: "email",
    //             title: i18n.get("administration.users.list.email"),
    //             width: 300,
    //         },
    //         {
    //             key: "signature",
    //             dataIndex: "signature",
    //             title: i18n.get("administration.users.list.signature"),
    //             width: 50,
    //         },
    //         {
    //             className: "text-center",
    //             key: "lastLogin",
    //             dataIndex: "lastLogin",
    //             title: i18n.get("administration.users.list.lastLogin"),
    //             width: 150,
    //         },
    //         {
    //             className: "text-center",
    //             key: "status",
    //             dataIndex: "status",
    //             title: i18n.get("administration.users.list.status"),
    //             width: 100,
    //         },
    //     ];
    //     if (activeEnvironment.administrationScheme !== "simple") {
    //         return columnArray;
    //     }
    //     const auxArray = columnArray.slice(1, columnArray.length);
    //     auxArray.splice(2, 1);
    //     return auxArray;
    // };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // populateUsersData = (massiveDissabledQuantity) => {
    //     const { activeEnvironment, users, usersInfo } = this.props;
    //     const { selectedUsers, selectAllChecked } = this.state;

    //     return users.map((user) => ({
    //         key: user.idUser,
    //         check: activeEnvironment.administrationScheme !== "simple" && (
    //             <Checkbox
    //                 onChange={() => this.handleCheckClick(user.idUser, user.fullName, massiveDissabledQuantity)}
    //                 hideLabel
    //                 id={user.idUser}
    //                 name={user.idUser}
    //                 checked={
    //                     usersInfo[user.idUser].massiveEnabled && (selectAllChecked || selectedUsers.has(user.idUser))
    //                 }
    //                 disabled={!usersInfo[user.idUser].massiveEnabled}
    //             />
    //         ),
    //         idUser: user.idUser,
    //         userName: (
    //             <UserName
    //                 name={user.fullName}
    //                 massiveEnabled={usersInfo[user.idUser].massiveEnabled}
    //                 dispatcher={usersInfo[user.idUser].dispatcher}
    //             />
    //         ),
    //         email: user.email,
    //         signature: this.signatureToLabel(usersInfo[user.idUser].signatureLevel),
    //         lastLogin: user.lastLoginAsMediumString,
    //         status: (
    //             <div className="data-wrapper data-wrapper-flex">
    //                 <div
    //                     className={`data-tag ${usersInfo[user.idUser].status === "blocked" ? "data-tag-blocked" : ""}`}>
    //                     <span> {i18n.get(`user.status.${usersInfo[user.idUser].status}`)} </span>
    //                 </div>
    //             </div>
    //         ),
    //     }));
    // };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    renderPageHeader = () => {
        const {
            // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
            // activeEnvironment,
            fetchingExport,
        } = this.props;

        return (
            <Head
                title="administration.users.list.title"
                exportList
                onBack={this.handleBack}
                // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
                // handleClick={this.handleClickDownload}
                // handleInviteClick={
                //     activeEnvironment.administrationScheme !== "simple" ? this.handleInviteUserClcik : null
                // }
                isFetchingExport={fetchingExport}
                rightChildren={
                    <Box display="flex" gap="7">
                        <Button
                            bsStyle="primary"
                            className="btn-width-lg"
                            label="administration.users.list.addUser"
                            href="/administration/users/invite"
                            image="images/icons/plus-circle.svg"
                        />
                    </Box>
                }
            />
        );
    };

    handleCheckClick = (idUserChecked, nameUserChecked, massiveDissabledQuantity) => {
        const { users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;

        if (!selectedUsers.has(idUserChecked)) {
            selectedUsers.set(idUserChecked, nameUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }

            if (selectedUsers.size === users.length - massiveDissabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedUsers.delete(idUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedUsers,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { users, usersInfo } = this.props;
        const newSelectedUsers = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            users.forEach((user) => {
                if (!usersInfo[user.idUser].isAdmin && user.idUserStatus !== "blocked") {
                    newSelectedUsers.set(user.idUser, user.fullName);
                    if (usersInfo[user.idUser].status === "blocked") {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }

        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedUsers: newSelectedUsers,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage, invCurrentPage } = this.props;
        const { selectedButtonId } = this.state;

        if (selectedButtonId === "users") {
            this.setState({ selectAllChecked: false });
            dispatch(actions.loadMoreRequest({ pageNumber: currentPage + 1 }));
        } else {
            dispatch(invitationsAction.loadMoreRequest({ pageNumber: invCurrentPage + 1 }));
        }
    };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // handleClickDownload = (format) => {
    //     const { dispatch } = this.props;

    //     dispatch(actions.exportListRequest(format));
    // };

    handleActionClick = (action) => {
        const { dispatch } = this.props;
        const { selectedUsers } = this.state;

        this.setState({ fetchingAction: true });

        dispatch(actions.changeUserStatusPreview([...selectedUsers.keys()], [...selectedUsers.values()], action));
    };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO
    // handleInviteUserClcik = () => {
    //     const { dispatch } = this.props;

    //     dispatch(routerActions.push("/administration/users/invite"));
    // };

    // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
    // renderTableFooter = () => {
    //     const { totalPages, hasMoreData, fetchingMoreUsers } = this.props;

    //     return (
    //         <div>
    //             {totalPages > 1 &&
    //                 (hasMoreData ? (
    //                     <div className="text-center no-more-data" key="noMoreUsers">
    //                         <Button
    //                             className="btn btn-link"
    //                             onClick={this.handleMoreDataClick}
    //                             loading={fetchingMoreUsers}
    //                             image="images/show.svg"
    //                             label="administration.users.list.more"
    //                         />
    //                     </div>
    //                 ) : (
    //                     <div className="text-center no-more-data" key="noMoreUsers">
    //                         <p className="text-lead">
    //                             <I18n id="administration.users.list.more.noMoreData" />
    //                         </p>
    //                     </div>
    //                 ))}
    //         </div>
    //     );
    // };

    renderLoadMore = () => {
        const {
            fetchingMoreUsers,
            totalPages,
            currentPage,
            hasMoreData,
            fetchingMoreInvitations,
            invTotalPages,
            invCurrentPage,
            invHasMoreData,
        } = this.props;
        const { selectedButtonId } = this.state;

        if (
            (selectedButtonId === "users" && totalPages > currentPage && hasMoreData) ||
            (selectedButtonId !== "users" && invTotalPages > invCurrentPage && invHasMoreData)
        ) {
            return (
                <Box
                    display="flex"
                    fullWidth
                    alignX="center"
                    className="no-more-data no-border-radius background-transparent mt-auto">
                    <Button
                        bsStyle="link"
                        onClick={this.handleMoreDataClick}
                        image="images/arrow-down.svg"
                        imageRight
                        loading={selectedButtonId === "users" ? fetchingMoreUsers : fetchingMoreInvitations}
                        label="administration.users.list.administratorUser.loadMore"
                        className="btn-small"
                    />
                </Box>
            );
        }
        return null;
    };

    renderUser = (user) => {
        const { usersInfo, loggedUser } = this.props;
        const { selectedButtonId, selectedUsers } = this.state;
        const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => userInfo.isAdmin).length;
        const extendedInfo = usersInfo[user.idUser];
        const { approveEnabled, tokenStatus, loading } = extendedInfo || {};
        const isLoggedUser = loggedUser?.userId === user.idUser;
        const inactiveStatusList = [
            USER_TOKEN_STATUS_INACTIVE,
            USER_TOKEN_STATUS_MIGRATE_ENTRUST,
            USER_TOKEN_STATUS_AUTHENTICATE,
            USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
            USER_TOKEN_STATUS_STARTED,
        ];

        if (!user) {
            return (
                <Box display="flex" alignX="center" alignY="center" fullHeight>
                    <Text labelKey="administration.users.list.empty" />
                </Box>
            );
        }

        const defaultChecked = selectedUsers.has(user.idUser);
        let userEnvironmentStatus = usersInfo[user.idUser]?.status;

        if (selectedButtonId !== "users") {
            userEnvironmentStatus = user.status === "EXPIRED" ? "expired" : "pending";
        }

        const userStatus = user.idUserStatus;

        if (userStatus === "blocked") {
            userEnvironmentStatus = "blocked_bo";
        }

        const status = () => {
            if (selectedButtonId !== "users") {
                return "warning";
            }

            if (userStatus === "blocked") {
                return "error";
            }
            if (userEnvironmentStatus === "active") {
                return "success";
            }
            if (userEnvironmentStatus === "blocked") {
                return "error";
            }
            if (userEnvironmentStatus === "pending") {
                return "warning";
            }
            return null;
        };

        const userTokenColor = () => {
            let color = "warning-color";
            if (tokenStatus === USER_TOKEN_STATUS_ACTIVE) {
                color = "success-color";
            }
            if (inactiveStatusList.includes(tokenStatus)) {
                color = "error";
            }
            if (tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST || tokenStatus === USER_TOKEN_STATUS_LOCKED_LOCAL) {
                color = "error";
            }
            return color;
        };

        const userTokenLavel = () => {
            let label = tokenStatus;
            if (inactiveStatusList.includes(tokenStatus)) {
                label = USER_TOKEN_STATUS_INACTIVE;
            }
            if (tokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
                label = "locked";
            }
            return `softToken.status.${label?.toLowerCase()}`;
        };

        return (
            <GridTable.Container
                key={user.idUser}
                columnsTemplate={this.getColumnsTemplate()}
                onClick={() => {
                    if (selectedButtonId === "users") {
                        this.onRowClick(user);
                    }
                }}
                background="transparent">
                <GridTable.Data columnStart={1} alignX="flex-start">
                    <Box display="flex" alignY="center" gap="2" fullWidth>
                        {selectedButtonId === "users" && (
                            <BanescoCheckbox
                                name={`checkbox-${user.idUser}`}
                                value="testing"
                                defaultChecked={defaultChecked}
                                onChange={() => {
                                    this.handleCheckClick(user.idUser, user.fullName, massiveDissabledQuantity);
                                }}
                                hideLabel
                                disabled={usersInfo[user.idUser]?.isAdmin || userStatus === "blocked"}
                            />
                        )}
                        <Sticker
                            labelKey={`global.${userEnvironmentStatus}`}
                            uppercase={false}
                            bold
                            status={status()}
                            textSize="7"
                            addPaddingY
                        />
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={2} alignX="flex-start">
                    {!isLoggedUser && extendedInfo?.isAdmin ? (
                        <Text color="text-disabled-color" labelKey="administration.users.list.hiddenToken" />
                    ) : (
                        <Text breakWord>
                            {selectedButtonId !== "users" ? `${user.firstName} ${user.lastName}` : user.username}
                        </Text>
                    )}
                </GridTable.Data>
                {selectedButtonId === "users" && (
                    <GridTable.Data columnStart={3} alignX="flex-start">
                        <Box display="flex" alignY="center" gap="3">
                            {extendedInfo.isAdmin && (
                                <Box display="flex">
                                    <Image src="images/icons/key.svg" />
                                </Box>
                            )}
                            <Text>{user.fullName}</Text>
                        </Box>
                    </GridTable.Data>
                )}
                <GridTable.Data columnStart={selectedButtonId === "users" ? 4 : 3} alignX="flex-start">
                    <Text>{user.documentNumber}</Text>
                </GridTable.Data>
                {selectedButtonId === "users" ? (
                    <>
                        <GridTable.Data columnStart={5} alignX="center">
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="center"
                                background={approveEnabled ? "success-color" : "error-border-color"}
                                borderRadius="xxl"
                                className="p-2 min-width-2-5rem">
                                <Text
                                    color="inverse"
                                    bold
                                    labelKey={approveEnabled ? "global.yes" : "global.no"}
                                    uppercase
                                />
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={6} alignX="flex-end">
                            <Box display="flex" alignX="center" alignY="center" gap="5">
                                {!isLoggedUser && extendedInfo.isAdmin ? (
                                    <Text
                                        color="text-disabled-color"
                                        labelKey="administration.users.list.hiddenToken"
                                    />
                                ) : (
                                    <>
                                        {(tokenStatus === USER_TOKEN_STATUS_ACTIVE ||
                                            tokenStatus === USER_TOKEN_STATUS_PENDING_APROVE) &&
                                        !loading ? (
                                            <Button
                                                bsStyle="link"
                                                loading
                                                label="token"
                                                style={{ color: `var(--text-disabled-color)` }}
                                            />
                                        ) : (
                                            <>
                                                <Box display="flex">
                                                    <Image src="images/icons/bullet.svg" color={userTokenColor()} />
                                                </Box>
                                                <Text color={userTokenColor()} labelKey={userTokenLavel()} uppercase />
                                            </>
                                        )}
                                    </>
                                )}
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={7} alignX="center">
                            <Box display="flex">
                                <Image src="images/icons/chevronRight.svg" />
                            </Box>
                        </GridTable.Data>
                    </>
                ) : (
                    <>
                        <GridTable.Data columnStart={4} alignX="flex-start">
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="center"
                                borderRadius="xxl"
                                className="p-2 min-width-2-5rem">
                                <Text ellipsis>{user.email}</Text>
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={5} alignX="flex-end">
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="center"
                                borderRadius="xxl"
                                className="p-2 min-width-2-5rem">
                                <FormattedDate date={user.creationDate} anotherFormat="DD/MM/YY" />
                            </Box>
                        </GridTable.Data>
                        <GridTable.Data columnStart={6} alignX="center" overflow="visible">
                            <Dropdown
                                image="images/contextualMenu.svg"
                                buttonClass="p-2 icon-big btn-context"
                                bsStyle="only-icon"
                                ariaLabel="global.contextMenu.a11y"
                                pullLeft
                                pullCenter>
                                {this.contextOptions.map((item) => (
                                    <Button
                                        key={item.label}
                                        label={item.label}
                                        ariaLabel={item.label}
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                        href={item.href}
                                        onClick={(e) => {
                                            if (item.onClick) {
                                                e.preventDefault();
                                                item.onClick(user);
                                            }
                                        }}
                                    />
                                ))}
                            </Dropdown>
                        </GridTable.Data>
                    </>
                )}
            </GridTable.Container>
        );
    };

    render() {
        const {
            usersInfo,
            totalPages,
            fetching,
            users,
            currentPage,
            fetchingInvitations,
            invitations,
            dispatch,
        } = this.props;
        const {
            selectedUsers,
            selectedButtonId,
            selectAllChecked,
            unblockedQuantity,
            blockedQuantity,
            fetchingAction,
            showResendDeleteModal,
            invitationOptionSelected,
            invitationSelected,
        } = this.state;

        const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => userInfo.isAdmin).length;

        // NO BORRAR (IMPLEMENTACION DE PRODUCTO)
        // const columns = this.generateTableColumns(massiveDissabledQuantity);

        const adminUsers = users?.filter((user) => usersInfo[user.idUser].isAdmin);
        const secondaryUsers = users?.filter((user) => !usersInfo[user.idUser].isAdmin);

        const buttonsGroup = [
            { id: "users", label: "administration.users.index.title" },
            { id: "pending", label: "administration.users.list.pending.tittle" },
        ];

        const buttonsWithSelection = buttonsGroup.map((button) => ({
            ...button,
            selected: button.id === selectedButtonId,
        }));

        const chipBtnHandler = (buttonId) => {
            this.setState({
                selectedButtonId: buttonId,
            });
        };

        return (
            <>
                <Notification scopeToShow="administrationUsers" />
                {this.renderPageHeader()}
                <TextAndButtonsModal
                    modalShow={showResendDeleteModal}
                    acceptFunction={() => {
                        if (invitationOptionSelected === "resend") {
                            dispatch(invitationsAction.resendRequest(invitationSelected.id));
                        }
                        if (invitationOptionSelected === "delete") {
                            dispatch(invitationsAction.deleteRequest(invitationSelected.id));
                        }
                        this.setState({
                            showResendDeleteModal: false,
                        });
                    }}
                    cancelFunction={() => {
                        this.setState({
                            showResendDeleteModal: false,
                        });
                    }}
                    headingText={i18n.get(`administration.users.invite.modal.title.${invitationOptionSelected}`)}
                    text={i18n.get(`administration.users.invite.modal.body.${invitationOptionSelected}`)}
                    loading={false}
                    modalId="productRequest.drawer.account.noLimitBalance"
                    textClassName="mx-md-0"
                />
                <MainContainer
                    className="main-container"
                    showLoader={fetching || fetchingAction || fetchingInvitations}>
                    <Box
                        display="flex"
                        alignX="center"
                        borderRadius="top-default"
                        background="component-background"
                        className="mx-n-5 p-5 mt-3 mt-md-0 mb-3 mb-md-0"
                        fullWidth>
                        <ChipsButtonsGroup
                            buttonsGroup={buttonsWithSelection}
                            onClickHandler={chipBtnHandler}
                            buttonsGap="6"
                        />
                    </Box>
                    <Box display="flex" column background="component-background" borderRadius="bottom-default">
                        {selectedUsers.size !== 0 && (
                            <Box display="flex" alignY="center" gap="5" className="px-7 mb-2">
                                <Text component="h4" size="6" className="m-0 line-height-125 ">
                                    {selectedUsers.size} {i18n.get("administration.users.list.selected.quantity")}
                                </Text>
                                {unblockedQuantity > 0 && (
                                    <Button
                                        key="block"
                                        bsStyle="link"
                                        label="administration.block"
                                        onClick={() => this.handleActionClick("block")}
                                    />
                                )}
                                {blockedQuantity > 0 && (
                                    <Button
                                        key="unblock"
                                        bsStyle="link"
                                        label="administration.unblock"
                                        onClick={() => this.handleActionClick("unblock")}
                                    />
                                )}
                                <Button
                                    key="delete"
                                    bsStyle="link"
                                    label="administration.delete"
                                    onClick={() => this.handleActionClick("delete")}
                                />
                            </Box>
                        )}

                        <GridTable>
                            <GridTable.Header>
                                <GridTable.Container columnsTemplate={this.getColumnsTemplate()}>
                                    <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                                        <Box display="flex" alignY="center" gap="1">
                                            {selectedButtonId === "users" && (
                                                <Box display="flex">
                                                    <BanescoCheckbox
                                                        name="AllCheckbox"
                                                        defaultChecked={selectAllChecked}
                                                        onChange={() => {
                                                            this.handleSelectAll();
                                                        }}
                                                        hideLabel
                                                        disabled={users.length === massiveDissabledQuantity}
                                                    />
                                                </Box>
                                            )}

                                            <Text labelKey="administration.users.list.header.status" />
                                        </Box>
                                    </GridTable.Data>
                                    {selectedButtonId === "users" && (
                                        <GridTable.Data columnStart={2} alignX="flex-start" size="7" inHeader>
                                            <Text labelKey="administration.users.list.header.username" />
                                        </GridTable.Data>
                                    )}
                                    <GridTable.Data
                                        columnStart={selectedButtonId === "users" ? 3 : 2}
                                        alignX="flex-start"
                                        size="7"
                                        inHeader>
                                        <Text labelKey="administration.users.list.header.name" />
                                    </GridTable.Data>
                                    <GridTable.Data
                                        columnStart={selectedButtonId === "users" ? 4 : 3}
                                        alignX="flex-start"
                                        size="7"
                                        inHeader>
                                        <Text labelKey="administration.users.list.header.identification" />
                                    </GridTable.Data>
                                    <GridTable.Data
                                        columnStart={selectedButtonId === "users" ? 5 : 4}
                                        alignX={selectedButtonId === "users" ? "center" : "flex-start"}
                                        size="7"
                                        inHeader>
                                        <Text
                                            labelKey={
                                                selectedButtonId === "users"
                                                    ? "global.approve"
                                                    : "administration.users.list.header.email"
                                            }
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data
                                        columnStart={selectedButtonId === "users" ? 6 : 5}
                                        alignX="flex-end"
                                        size="7"
                                        inHeader>
                                        <Text
                                            labelKey={
                                                selectedButtonId === "users"
                                                    ? "administration.users.list.header.token"
                                                    : "administration.users.list.header.invitationDate"
                                            }
                                        />
                                    </GridTable.Data>
                                </GridTable.Container>
                            </GridTable.Header>
                            <GridTable.Body
                                className={classNames("min-height-10rem", { "pb-9": totalPages === currentPage })}>
                                {selectedButtonId === "users" && (!users || users?.length === 0) && (
                                    <Box display="flex" alignX="center" alignY="center" flex1>
                                        <Text labelKey="administration.users.list.empty" />
                                    </Box>
                                )}
                                {selectedButtonId !== "users" && (!invitations || invitations?.length === 0) && (
                                    <Box display="flex" alignX="center" alignY="center" flex1>
                                        <Text labelKey="administration.users.list.pending.empty" />
                                    </Box>
                                )}
                                {adminUsers?.length > 0 && selectedButtonId === "users" && (
                                    <>
                                        <Box
                                            background="menu-background"
                                            border="secondary-background-color"
                                            className="px-7 py-4">
                                            <Text
                                                component="h3"
                                                className="m-0"
                                                color="heading-color"
                                                size="4"
                                                bold
                                                labelKey="administration.users.list.administratorUser.label"
                                            />
                                        </Box>
                                        {adminUsers.map((user) => this.renderUser(user))}
                                    </>
                                )}
                                {secondaryUsers?.length > 0 && selectedButtonId === "users" && (
                                    <>
                                        <Box
                                            background="menu-background"
                                            border="secondary-background-color"
                                            className="px-7 py-4">
                                            <Text
                                                component="h3"
                                                className="m-0"
                                                color="heading-color"
                                                size="4"
                                                bold
                                                labelKey="administration.users.list.secondaryUser.label"
                                            />
                                        </Box>
                                        {secondaryUsers.map((user) => this.renderUser(user))}
                                        {this.renderLoadMore()}
                                    </>
                                )}
                                {selectedButtonId !== "users" && (
                                    <>
                                        {/* TODO (pendingUsers) */}
                                        {invitations.map((user) => this.renderUser(user))} {this.renderLoadMore()}
                                    </>
                                )}
                            </GridTable.Body>
                        </GridTable>
                        {/* NO BORRAR (IMPLEMENTACION DE PRODUCTO) */}
                        {/* <Table
                            columns={columns}
                            data={this.populateUsersData(massiveDissabledQuantity)}
                            rowKey={(record) => record.key}
                            emptyText={i18n.get("administration.users.list.noRecords")}
                            onRow={(record) => ({
                                onClick: () => this.onRowClick(record),
                            })}
                            footer={totalPages > 1 ? this.renderTableFooter : null}
                        /> */}
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    users: selectors.getUsers(state),
    usersInfo: selectors.getUsersInfo(state),
    currentPage: selectors.getCurrentPage(state),
    totalPages: selectors.getTotalPages(state),
    fetchingExport: selectors.isFetchingExport(state),
    fetching: selectors.isFetching(state),
    fetchingMoreUsers: selectors.isFetchingMoreUsers(state),
    hasMoreData: selectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    loggedUser: sessionSelectors.getUser(state),

    invitations: invitationsSelector.getInvitations(state),
    fetchingInvitations: invitationsSelector.isFetching(state),
    invCurrentPage: invitationsSelector.getCurrentPage(state),
    invTotalPages: invitationsSelector.getTotalPages(state),
    fetchingMoreInvitations: invitationsSelector.isFetchingMoreInvitations(state),
    invHasMoreData: invitationsSelector.getHasMoreData(state),
});

AdministrationUsers.propTypes = {
    dispatch: func.isRequired,
    activeEnvironment: shape({ administrationScheme: string }).isRequired,
    users: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
    usersInfo: objectOf(
        shape({
            status: string.isRequired,
            signatureLevel: string,
            hasSignature: bool,
        }),
    ).isRequired,
    currentPage: number,
    totalPages: number,
    fetching: bool,
    fetchingExport: bool,
    fetchingMoreUsers: bool,
    hasMoreData: bool,
};

AdministrationUsers.defaultProps = {
    currentPage: 0,
    totalPages: 0,
    fetching: false,
    fetchingExport: false,
    fetchingMoreUsers: false,
    hasMoreData: false,
};

export default connect(mapStateToProps)(AdministrationUsers);

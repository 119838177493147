import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { Redirect } from "react-router-dom";
import { actions, selectors } from "reducers/administration/usersInvite";
import { actions as formActions } from "reducers/form";

const UserInviteStep3 = (props) => {
    const handleSubmit = (credentials, formikBag) => {
        const { dispatch, confirmationParams, idTransaction, idActivity } = props;
        if (!idTransaction) {
            dispatch(actions.userInviteConfirm(confirmationParams, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    const handleBack = () => {
        const { dispatch } = props;
        dispatch(routerActions.goBack());
    };

    const renderMediumSchemeConfiguration = () => {
        const { confirmationParams } = props;
        return (
            <Box display="flex" column gap="4" className="mb-6">
                <Box display="flex" alignX="between" fullWidth>
                    <Text
                        component="label"
                        labelKey="administration.users.invite.role.label"
                        className="m-0"
                        color="heading-color"
                        size="5"
                        bold
                        addColon
                    />
                    <Text
                        color="heading-color"
                        size="5"
                        labelKey={`invitation.permissions.role.${confirmationParams?.role}`}
                    />
                </Box>

                <Box display="flex" alignX="between" fullWidth>
                    <Text
                        component="label"
                        labelKey="administration.users.invite.create.transactions"
                        className="m-0"
                        color="heading-color"
                        size="5"
                        bold
                        addColon
                    />
                    <Text
                        labelKey={`administration.users.invite.${confirmationParams?.role}.createApprove`}
                        color="heading-color"
                        size="5"
                        uppercase
                    />
                </Box>

                <Box display="flex" alignX="between" fullWidth>
                    <Text
                        component="label"
                        labelKey="administration.users.invite.approve.transactions"
                        className="m-0"
                        color="heading-color"
                        size="5"
                        bold
                        addColon
                    />
                    <Text
                        labelKey={`administration.users.invite.${confirmationParams?.role}.createApprove`}
                        color="heading-color"
                        size="5"
                        uppercase
                    />
                </Box>
            </Box>
        );
    };

    const {
        // isDesktop,
        credentialGroups,
        confirmationParams,
    } = props;

    // if (!isDesktop) {
    //     return <Redirect to="/desktop" />;
    // }

    if (!confirmationParams) {
        return <Redirect to="/administration/users/invite" />;
    }

    return (
        <>
            <Notification scopeToShow="administrationUsersInvite" />
            <Head title="administration.users.invite.title" onBack={handleBack} />
            <MainContainer>
                <Row>
                    <Col xs={8} mdOffset={2}>
                        <Box
                            display="flex"
                            column
                            background="component-background"
                            borderRadius="xxl"
                            className="pt-8 pb-10 px-80"
                            fullHeight>
                            <Box display="flex" className="pb-5 mb-8" border="bottom-border-base-color-1">
                                <Text
                                    component="h2"
                                    labelKey="administration.users.invite.subtitle"
                                    className="m-0"
                                    size="5"
                                    color="heading"
                                    bold
                                />
                            </Box>
                            <Box display="flex" column gap="4" className="mb-6">
                                <Box display="flex" alignX="between" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.users.invite.firstName.label"
                                        className="m-0"
                                        color="heading-color"
                                        size="5"
                                        bold
                                        addColon
                                    />
                                    <Text color="heading-color" size="5">
                                        {confirmationParams?.firstName}
                                    </Text>
                                </Box>

                                <Box display="flex" alignX="between" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.users.invite.lastName.label"
                                        className="m-0"
                                        color="heading-color"
                                        size="5"
                                        bold
                                        addColon
                                    />
                                    <Text color="heading-color" size="5">
                                        {confirmationParams?.lastName}
                                    </Text>
                                </Box>

                                <Box display="flex" alignX="between" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.users.invite.email.label"
                                        className="m-0"
                                        color="heading-color"
                                        size="5"
                                        bold
                                        addColon
                                    />
                                    <Text color="heading-color" size="5">
                                        {confirmationParams?.email}
                                    </Text>
                                </Box>

                                <Box display="flex" alignX="between" fullWidth>
                                    <Text
                                        component="label"
                                        labelKey="administration.users.invite.mobilePhone.label"
                                        className="m-0"
                                        color="heading-color"
                                        size="5"
                                        bold
                                        addColon
                                    />
                                    <Text color="heading-color" size="5">
                                        {confirmationParams?.prefix ? `+${confirmationParams?.prefix}` : ""}
                                        {confirmationParams?.mobilePhone}
                                    </Text>
                                </Box>
                            </Box>

                            <Box display="flex" className="mb-5">
                                <Text
                                    component="h3"
                                    labelKey="administration.users.invite.initial.configuration.subtitle"
                                    className="m-0"
                                    size="5"
                                    color="primary"
                                    bold
                                />
                            </Box>
                            {renderMediumSchemeConfiguration()}
                            <AdministrationFormConfirmation
                                credentialGroups={credentialGroups}
                                onSubmit={handleSubmit}
                            />
                        </Box>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

UserInviteStep3.propTypes = {
    dispatch: func.isRequired,
    credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
    confirmationParams: shape({
        docCountry: string,
        docNumber: string,
        docType: string,
        document: string,
        email: string,
        firstName: string,
        lastName: string,
        mobilePhone: string,
        role: string,
        signatureLevel: string,
    }).isRequired,
    idTransaction: string,
    idActivity: string,
    // isDesktop: bool.isRequired,
};

UserInviteStep3.defaultProps = {
    idTransaction: null,
    idActivity: null,
};

const mapStateToProps = (state) => ({
    confirmationParams: selectors.getConfirmationParams(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserInviteStep3);

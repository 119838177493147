import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, shape } from "prop-types";
import React from "react";
import Slider from "react-slick";
import * as i18n from "util/i18n";

const AvailableAmountSection = ({ list, isDesktop }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 200,
        slidesToShow: 2,
        arrows: false,
    };
    return (
        <>
            {isDesktop ? (
                <Box
                    component="article"
                    display="flex"
                    column
                    alignX="center"
                    background="heading-color"
                    borderRadius="default"
                    className="px-7 py-4 min-height-7rem"
                    fullHeight>
                    <Box display="flex" alignX="between" alignY="center" className="mb-5" fullWidth>
                        <Text color="inverse" size="6" uppercase className="mr-3" labelKey="creditLine.product.type" />
                        <Text color="inverse" size="5" bold labelKey="creditLine.type" />
                    </Box>
                    {list.length > 0 &&
                        list.map((availableAmount, indx) => (
                            <Box
                                key={`AvailableAmount${availableAmount.currency}`}
                                display="flex"
                                alignX="between"
                                alignY="center"
                                fullWidth
                                className={classNames({
                                    "mb-5": list.length !== indx + 1,
                                })}>
                                <Text color="inverse" size="6" uppercase className="mr-3">
                                    {i18n.get("creditLine.available.balance")} ({availableAmount.currency})
                                </Text>
                                <FormattedAmount
                                    currency={availableAmount.currency}
                                    quantity={availableAmount.quantity}
                                    color="inverse"
                                    size="5"
                                    bold
                                />
                            </Box>
                        ))}
                </Box>
            ) : (
                <Slider {...settings} className="slider-gap-box">
                    <Box
                        component="article"
                        display="flex"
                        column
                        alignX="center"
                        background="heading-color"
                        borderRadius="default"
                        className="p-4"
                        fullHeight>
                        <Box display="flex" column alignY="flex-start" fullWidth>
                            <Text color="inverse" size="6" align="left" labelKey="creditLine.product.type" />
                            <Text color="inverse" size="5" align="left" bold labelKey="creditLine.type" />
                        </Box>
                    </Box>

                    {list.length > 0 &&
                        list.map((availableAmount) => (
                            <Box
                                key={`AvailableAmount${availableAmount.currency}`}
                                component="article"
                                display="flex"
                                column
                                alignX="center"
                                background="heading-color"
                                borderRadius="default"
                                className="p-4"
                                fullHeight>
                                <Box display="flex" column alignY="flex-start" fullWidth>
                                    <Text color="inverse" size="6" align="left" className="mr-3">
                                        {i18n.get("creditLine.available.balance")} ({availableAmount.currency})
                                    </Text>
                                    <FormattedAmount
                                        currency={availableAmount.currency}
                                        quantity={availableAmount.quantity}
                                        color="inverse"
                                        size="5"
                                        align="left"
                                        bold
                                    />
                                </Box>
                            </Box>
                        ))}
                </Slider>
            )}
        </>
    );
};

AvailableAmountSection.propTypes = {
    list: shape({}).isRequired,
    isDesktop: bool.isRequired,
};

export default resizableRoute(AvailableAmountSection);

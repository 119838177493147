import * as API from "middleware/api";

export const updateUserDataPreRequest = (otp) =>
    API.executeWithAccessToken("/settings.userData.update.pre", { _otp: otp });

export const updateUserDataStep1PreData = (exchangeToken) =>
    API.executeWithAccessToken("/settings.userData.update.step1.pre", { exchangeToken });

export const updateUserDataStep2PreData = (exchangeToken) =>
    API.executeWithAccessToken("/settings.userData.update.step2.pre", { exchangeToken });

export const updateUserDataStep3PreData = (exchangeToken) =>
    API.executeWithAccessToken("/settings.userData.update.step3.pre", { exchangeToken });

export const updateUserDataStep4PreData = (exchangeToken) =>
    API.executeWithAccessToken("/settings.userData.update.step4.pre", { exchangeToken });

export const updateUserDataStep5PreData = (exchangeToken) =>
    API.executeWithAccessToken("/settings.userData.update.step5.pre", { exchangeToken });

export const updateUserDataStep6PreData = (exchangeToken) =>
    API.executeWithAccessToken("/settings.userData.update.step6.pre", { exchangeToken });

export const saveUserDataStep = (activityId, updateUserData, exchangeToken, fingerPrintToken) =>
    API.executeWithAccessToken(activityId, { updateUserData, exchangeToken, fingerPrintToken });

export const saveUserDataStep6 = (activityId, updateUserData, exchangeToken, fingerPrintToken) =>
    API.executeWithAccessToken(activityId, { _updateUserData: updateUserData, exchangeToken, fingerPrintToken });

export const getCatalogListByParentCatalogName = (catalogName, parentCatalogName) =>
    API.executeWithAccessToken("/settings.catalog.list.pre", { catalogName, parentCatalogName });

export const validateDocument = (documentElement, identityDocument) =>
    API.executeWithAccessToken("/settings.document.validation.pre", { documentElement, identityDocument });

export const validateClientRemediation = () => API.executeWithAccessToken("/update.user.data.pre");

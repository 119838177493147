import isEmpty from "lodash/isEmpty";
import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import AccountsList from "pages/accounts/List";
import EquivalentTotalBalance from "pages/accounts/_components/EquivalentTotalBalance";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as productsActions } from "reducers/products";
import * as i18n from "util/i18n";

import { actions as productRequestActions } from "reducers/productRequest";

class Accounts extends Component {
    static propTypes = {
        accounts: arrayOf(shape({ length: number })),
        dispatch: func.isRequired,
        fetching: bool,
        equivalentTotalBalance: string,
        showProductIcon: bool,
        completeFavorites: bool,
    };

    static defaultProps = {
        fetching: false,
        accounts: [],
        equivalentTotalBalance: "",
        showProductIcon: false,
        completeFavorites: false,
    };

    state = {
        showNoLimitAccountRequestModal: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.listAccounts());
    }

    componentDidUpdate;

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    displayProductRequestModal = () => {
        const { dispatch } = this.props;
        dispatch(productRequestActions.modalShow("AC"));
    };

    renderHeader = () => (
        <Head
            onBack={this.handleBack}
            title="menu.accounts"
            onAdd={this.displayProductRequestModal}
            addLinkToLabel="accounts.new"
        />
    );

    setFavoriteAccount = (idProduct, productType, favorite) => {
        const { dispatch } = this.props;
        dispatch(productsActions.toggleFavorite(idProduct, productType, favorite, null, true));
    };

    showAccountRequestModal = () => {
        this.setState({
            showNoLimitAccountRequestModal: true,
        });
    };

    render() {
        const { accounts, equivalentTotalBalance, fetching, showProductIcon, completeFavorites } = this.props;
        const { showNoLimitAccountRequestModal } = this.state;
        const isLoading = fetching && !accounts.length;
        return (
            <>
                <Notification scopeToShow="accounts" />
                <TextAndButtonsModal
                    modalShow={showNoLimitAccountRequestModal}
                    acceptFunction={() => {
                        this.setState({
                            showNoLimitAccountRequestModal: false,
                        });
                    }}
                    headingText={i18n.get("productRequest.drawer.account.noLimit.title")}
                    text={i18n.get("productRequest.drawer.account.noLimit.text")}
                    loading={false}
                    modalId="productRequest.drawer.account.noLimitBalance"
                    textClassName="mx-md-0"
                />
                {!isLoading && this.renderHeader()}
                <MainContainer showLoader={isLoading}>
                    <Row className="mt-5 mt-md-0">
                        <Col xs={12} md={8} mdOffset={2}>
                            {accounts.length ? (
                                <>
                                    {!isEmpty(accounts) && equivalentTotalBalance && (
                                        <EquivalentTotalBalance {...this.props} />
                                    )}
                                    <AccountsList
                                        accounts={accounts}
                                        setFavoriteAccount={this.setFavoriteAccount}
                                        showProductIcon={showProductIcon}
                                        completeFavorites={completeFavorites}
                                    />
                                </>
                            ) : (
                                <EmptyTextBox text="product.empty.text.accounts" footerLink />
                            )}
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    equivalentTotalBalance: accountsSelectors.getEquivalentTotalBalance(state),
    fetching: accountsSelectors.getFetching(state),
    completeFavorites: accountsSelectors.getCompleteFavorites(state),
});

export default connect(mapStateToProps)(Accounts);

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormSignatures from "pages/forms/_components/FormSignatures";
import { bool, func, node, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions, selectors } from "reducers/administration/common/administrationTicket";
import { actions as formActions } from "reducers/form";
import * as i18n from "util/i18n";
import { getTransactionKind } from "util/transaction";
import { permissionsSelectors } from "reducers/administration";
import { permissionsTicketActions } from "reducers/administration/medium";

class AdministrationTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }).isRequired,
        transactionData: shape({
            data: shape({}),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ idTransaction: string }),
        }).isRequired,
        fetching: bool.isRequired,
        children: node.isRequired,
        isCancelling: bool,
        hideSignatures: bool,
    };

    static defaultProps = {
        isCancelling: false,
        hideSignatures: false,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const {
            params: { idTransaction },
            url,
        } = match;
        if (url.includes("permissions")) {
            dispatch(permissionsTicketActions.loadPermissionsTicketRequest(idTransaction));
        } else {
            dispatch(actions.loadAdministrationTicketRequest(idTransaction));
        }
    }

    onClick = () => {
        const { dispatch, transactionData } = this.props;

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                ticketData: transactionData.data,
            }),
        );
    };

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    getViewButtons = () => {
        const { fetching, transactionData } = this.props;

        if (transactionData) {
            const { idTransactionStatus } = transactionData;
            if (idTransactionStatus === "PENDING") {
                return (
                    <Button
                        onClick={() => this.onClick("signTransaction")}
                        label="forms.signTransaction.link"
                        bsStyle="primary"
                        loading={fetching}
                    />
                );
            }
        }
        return null;
    };

    formatBrowserTime = (dateTimeString) => {
        if (!dateTimeString) {
            return "";
        }

        const formattedDateTime = dateTimeString.replace(/([-+]\d{2})$/, "$1:00");
        const submitDateTime = new Date(formattedDateTime);
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const formattedSubmitDateTime = submitDateTime.toLocaleString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone,
        });

        return formattedSubmitDateTime;
    };

    render() {
        const { fetching, transactionData, children, isCancelling, hideSignatures } = this.props;
        const viewButtons = this.getViewButtons();

        const transaction = transactionData;
        const browserTrxDate = this.formatBrowserTime(transactionData.submitDateTime);
        const statusIcon = () => {
            let icon = "";
            if (transactionData.idTransactionStatus === "FINISHED") {
                icon = "success";
            } else if (transactionData.idTransactionStatus === "PARCIAL") {
                icon = "partial";
            } else if (transactionData.idTransactionStatus === "SCHEDULED") {
                icon = "success-scheduled";
            } else if (
                transactionData.idTransactionStatus === "PROCESSING" ||
                transactionData.idTransactionStatus === "DRAFT"
            ) {
                icon = "processing";
            } else if (transactionData.idTransactionStatus === "PENDING") {
                icon = "pending";
            } else {
                icon = "error";
            }
            return icon;
        };
        const titleKind = getTransactionKind(transaction?.idActivity);
        let labelKeyHeader = `forms.transaction.ticket.status.${transaction.idTransactionStatus}.${titleKind}`;

        // const labelCancelDetail = "forms.transfers.confirmation.cancel.detail";

        if (isCancelling) {
            labelKeyHeader = "forms.transfers.confirmation.cancel.title";
        }

        if (transactionData.data) {
            const { idTransaction } = transactionData;
            return (
                <>
                    <Notification scopeToShow="administration" />
                    <Head titleText={transactionData.activityName} onBack={this.handleBack} />
                    <MainContainer showLoader={fetching}>
                        <Box className={classNames("ticket-wrapper")}>
                            <Row gapY="7">
                                <Col xs={12}>
                                    <Box component="article" className="ticket">
                                        <Box
                                            component="header"
                                            display="flex"
                                            alignX="center"
                                            alignY="center"
                                            className={classNames(`ticket-header  ${statusIcon()}`)}>
                                            <Box display="flex" alignY="center" className="ticket-header-info">
                                                <Image
                                                    src={`images/icons/tickets/${statusIcon()}.svg`}
                                                    wrapperClassName="ticket-header-image"
                                                />

                                                <Text className="ticket-header-text ml-4" labelKey={labelKeyHeader} />
                                            </Box>
                                        </Box>
                                        <Box
                                            className={classNames("default-ticket-content zig-zag-border", {
                                                "success-ticket":
                                                    statusIcon() === "success" || statusIcon() === "success-scheduled",
                                                "partial-ticket": statusIcon() === "partial",
                                            })}
                                            fullWidth
                                            background="white"
                                            position="relative">
                                            <Box display="flex" column className="px-7 pt-9 pb-11">
                                                <Box display="flex" column>
                                                    <Box display="block" className="mb-4">
                                                        <Text
                                                            component="label"
                                                            labelKey="forms.transaction.ticket.date"
                                                            addColon
                                                            className="m-0"
                                                        />
                                                        <Text>{browserTrxDate}</Text>
                                                    </Box>
                                                    <Box display="block" className="mb-4">
                                                        <Text
                                                            component="label"
                                                            labelKey="global.operationType"
                                                            addColon
                                                            className="m-0"
                                                        />
                                                        <Text>{transactionData.activityName}</Text>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" column className="mb-7">
                                                    {children}
                                                </Box>
                                                {!hideSignatures && (
                                                    <Box display="flex" column className="mb-3">
                                                        <FormSignatures transaction={transactionData} isInTicket />
                                                    </Box>
                                                )}
                                                {viewButtons && (
                                                    <Box display="flex" column className="mb-7">
                                                        {viewButtons}
                                                    </Box>
                                                )}
                                                <Box className="mt-5 mb-7">
                                                    <Text
                                                        size="7"
                                                        color="heading"
                                                        labelKey="forms.transaction.ticket.scheduled.idTransaction.label"
                                                    />
                                                    <Text size="7" color="heading">
                                                        {idTransaction}
                                                    </Text>
                                                </Box>
                                                <Box display="flex" alignX="center" alignY="center" fullWidth>
                                                    <Button
                                                        bsStyle="social-icon"
                                                        image="images/socialIcons/linkedin.svg"
                                                        externalHref="https://www.linkedin.com/company/banesco-panama"
                                                        ariaLabel={i18n.get("transactions.ticket.social.linkedin")}
                                                    />
                                                    <Button
                                                        bsStyle="social-icon"
                                                        image="images/socialIcons/facebook.svg"
                                                        externalHref="https://www.facebook.com/BanescoPanama"
                                                        ariaLabel={i18n.get("transactions.ticket.social.facebook")}
                                                    />
                                                    <Button
                                                        bsStyle="social-icon"
                                                        image="images/socialIcons/twitter.svg"
                                                        externalHref="https://twitter.com/BanescoPanama"
                                                        ariaLabel={i18n.get("transactions.ticket.social.twitter")}
                                                    />
                                                    <Button
                                                        bsStyle="social-icon"
                                                        image="images/socialIcons/instagram.svg"
                                                        externalHref="https://www.instagram.com/banescopanama/"
                                                        ariaLabel={i18n.get("transactions.ticket.social.instagram")}
                                                    />
                                                    <Button
                                                        bsStyle="social-icon"
                                                        image="images/socialIcons/youtube.svg"
                                                        externalHref="https://www.youtube.com/user/BanescoPanama"
                                                        ariaLabel={i18n.get("transactions.ticket.social.youtube")}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Col>
                            </Row>
                        </Box>
                    </MainContainer>
                </>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.isFetching(state) || permissionsSelectors.isFetching(state),
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(AdministrationTicket);

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape } from "prop-types";
import React from "react";

const DownloadDocs = (props) => {
    const { downloading, documents, onDownload } = props;
    if (!downloading) {
        return (
            <Dropdown
                image="images/download.svg"
                label="global.download"
                labelClassName="visually-hidden"
                bsStyle="only-icon"
                pullRight>
                {documents.map((item) => (
                    <Button
                        key={`creditCards.download.${item}.file`}
                        label={`forms.product.downloadStatement${item?.toUpperCase()}`}
                        onClick={() => onDownload(item)}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    }
    return <Button label="downloading" bsStyle="only-icon" loading />;
};

// DownloadDocs.propTypes
DownloadDocs.propTypes = {
    documents: shape({}),
    downloading: bool,
    onDownload: func.isRequired,
};
// DownloadDocs.defaultProps
DownloadDocs.defaultProps = {
    documents: null,
    downloading: false,
};

const StatementsCreditCardActions = (props) => {
    const { documentDownload, modalActions, isDesktop } = props;

    return (
        <Box>
            <Box display="flex" background="white" fullWidth className="py-0 py-md-3">
                <Box className="ml-5 mr-auto" display="flex" alignY="center">
                    <Image wrapperClassName="fit-content" className="mr-3 mt-2" src="images/icons/tooltip.svg" />
                    <Text size="7" className="pr-8 my-3 my-md-0" labelKey="creditCard.statements.disclaimer.text" />
                </Box>
                {modalActions && (
                    <Box>
                        <Button
                            className={classNames({
                                "is-active": true,
                            })}
                            bsStyle="link"
                            labelClassName={classNames({ "visually-hidden": false })}
                            label={isDesktop ? "creditCards.movements.filters.searchBy" : null}
                            onClick={() => modalActions.showModalSearch()}
                            image="images/search.svg"
                        />
                    </Box>
                )}
                {documentDownload && (
                    <Box className="mr-5" alignY="center">
                        <DownloadDocs {...documentDownload} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

// StatementsFilter.propTypes
StatementsCreditCardActions.propTypes = {
    documentDownload: shape({
        documents: shape({}),
        downloading: bool,
    }),
    modalActions: shape({
        showModalSearch: func,
    }),
    isDesktop: bool,
};
// StatementsFilter.defaultProps
StatementsCreditCardActions.defaultProps = {
    documentDownload: null,
    modalActions: null,
    isDesktop: null,
};

export default resizableRoute(StatementsCreditCardActions);

import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, func, oneOf, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Redirect from "react-router-dom/Redirect";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/users";
import { actions as formActions } from "reducers/form";

class UserActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        usersToApplyAction: arrayOf(string).isRequired,
        usersNamesToApplyAction: arrayOf(string).isRequired,
        userAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const {
            dispatch,
            usersToApplyAction,
            usersNamesToApplyAction,
            userAction,
            idTransaction,
            idActivity,
        } = this.props;

        if (!idTransaction) {
            dispatch(
                actions.changeUserStatusConfirmation(
                    usersToApplyAction,
                    usersNamesToApplyAction,
                    userAction,
                    credentials,
                    formikBag,
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { usersToApplyAction, usersNamesToApplyAction, userAction, credentialGroups } = this.props;

        if (!usersToApplyAction || usersToApplyAction.length === 0) {
            return <Redirect to="/administration/users" />;
        }

        const usersText = usersNamesToApplyAction.map((userName) => <span key={userName}> {userName} </span>);
        const titleKey = `administration.users.action.title.${userAction}`;
        const subtitleKey = `administration.users.action.subtitle.${userAction}`;

        return (
            <>
                <Head title={titleKey} onBack={this.handleBack} />
                <MainContainer>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="xxl"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-12" border="bottom-border-base-color-1">
                                    <Text
                                        component="h2"
                                        labelKey={subtitleKey}
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>

                                <Box display="flex" alignX="center" className="mb-12" fullWidth>
                                    <Text color="primary" className="display-flex flex-column gap-3" bold>
                                        {usersText}
                                    </Text>
                                </Box>

                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    onClickCancel={this.handleBack}
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userAction: selectors.getUserAction(state),
    usersToApplyAction: selectors.getUsersToApplyAction(state),
    usersNamesToApplyAction: selectors.getUsersNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserActionConfirmation);

import { Field } from "formik";
import Box from "pages/_components/Box";
import Checkbox from "pages/_components/Checkbox";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import BitwiseDays from "pages/_components/fields/BitwiseDays";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import Select from "pages/_components/fields/Select";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import * as i18n from "util/i18n";
import * as maskUtils from "util/mask";
import classNames from "classnames";

const FORM_ID = "administration.restrictions.user";

class CalendarRestriction extends Component {
    static propTypes = {
        timeZones: arrayOf(string),
        idUser: string,
        values: shape({}),
        calendarEnabled: bool.isRequired,
        setFieldValue: func.isRequired,
        days: string.isRequired,
        calendarRestrictionId: number.isRequired,
    };

    static defaultProps = {
        timeZones: [],
        idUser: null,
        values: {},
    };

    allDaysOnCLick = (val) => {
        const { setFieldValue } = this.props;
        if (val) {
            setFieldValue("days", 127);
        } else {
            setFieldValue("days", 0);
        }
    };

    onDaysValueChange = (val) => {
        const { setFieldValue } = this.props;
        setFieldValue("allDays", val === 127);
    };

    setBackground = () => {
        const { values, calendarEnabled, idUser, calendarRestrictionId } = this.props;

        if ((idUser && !calendarRestrictionId) || (!calendarEnabled && values?.calendarEnabled && !idUser)) {
            return "success";
        }

        if (
            (calendarEnabled && values?.calendarEnabled) ||
            (calendarEnabled && values?.calendarEnabled) ||
            (idUser && !!calendarRestrictionId)
        ) {
            return "warning";
        }
        return "transparent";
    };

    setRestrictions = () => {
        const { values, calendarEnabled, days, idUser, calendarRestrictionId } = this.props;

        if (idUser && !calendarRestrictionId) {
            return i18n.get("administration.restrictions.disclaimer.userWithoutRestriction");
        }
        if (idUser && !!calendarRestrictionId) {
            return i18n.get("administration.restrictions.disclaimer.userWithRestriction");
        }

        if (!calendarEnabled && values?.calendarEnabled) {
            return i18n.get("administration.restrictions.disclaimer.corporateWithoutRestriction");
        }
        if (days > 0 && calendarEnabled && values?.calendarEnabled) {
            return i18n.get("administration.restrictions.disclaimer.corporateWithRestriction");
        }
        return null;
    };

    render() {
        const { timeZones, idUser, values, calendarEnabled } = this.props;

        const tzOptions = timeZones.map((tz) => ({
            value: tz,
            label: tz,
        }));

        return (
            <Box display="flex" column fullWidth>
                <Box
                    display="flex"
                    alignY="center"
                    background={this.setBackground()}
                    borderRadius={values.calendarEnabled ? "top-default" : "default"}
                    className="px-9 py-8"
                    fullWidth>
                    <Box display="flex" alignY="center">
                        {!idUser ? (
                            <>
                                <Field name="calendarEnabled" idForm={FORM_ID} component={SwitchField} size="6" />
                                <Tooltip
                                    className="pl-2 pr-2"
                                    position="left-centered"
                                    text="administration.restrictions.environment.switch.info"
                                />
                            </>
                        ) : (
                            <Text
                                labelKey="administration.restrictions.calendarEnabled.label"
                                color="heading-color"
                                size="4"
                                bold
                            />
                        )}
                    </Box>

                    {(values?.calendarEnabled || (calendarEnabled && !values?.calendarEnabled)) && (
                        <Box display="flex" alignY="center" gap="3" className="ml-9">
                            <Text size="6" color="heading-color">
                                {this.setRestrictions()}
                            </Text>
                        </Box>
                    )}
                </Box>
                {(values.calendarEnabled || !!idUser) && (
                    <Box
                        display="flex"
                        column
                        className={classNames("pt-5 pb-8", { "px-80": !idUser, "px-9": !!idUser })}>
                        <Row>
                            <Col xs={5}>
                                <Box component="fieldset" display="flex" gap="4" column>
                                    <Box display="flex" gap="4" column>
                                        <Text
                                            component="legend"
                                            labelKey={`${FORM_ID}.perDay`}
                                            color="heading-color"
                                            bold
                                        />
                                        <Box display="flex" gap="10" className="mb-4">
                                            <Row>
                                                <Col xs={12} md={12} lg={6}>
                                                    <Field
                                                        name="allDays"
                                                        component={Checkbox}
                                                        idForm={FORM_ID}
                                                        onChange={this.allDaysOnCLick}
                                                    />
                                                </Col>
                                            </Row>
                                        </Box>

                                        <Field
                                            name="days"
                                            component={BitwiseDays}
                                            idForm={FORM_ID}
                                            hideLabel
                                            onChange={this.onDaysValueChange}
                                            disabled={values?.allDays}
                                        />
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={7}>
                                <Box component="fieldset">
                                    <Box display="flex" gap="4" column>
                                        <Text
                                            component="legend"
                                            labelKey={`${FORM_ID}.perHour`}
                                            color="heading-color"
                                            bold
                                        />

                                        <Row>
                                            <Col xs={12}>
                                                <Select
                                                    name="timeZone"
                                                    label={i18n.get(`${FORM_ID}.timeZone.label`)}
                                                    options={tzOptions}
                                                    searchable
                                                    labelNoMarginTop
                                                />
                                            </Col>

                                            <Col xs={6}>
                                                <Field
                                                    autoComplete="off"
                                                    component={MaskedTextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    mask={maskUtils.timeCodeMask()}
                                                    name="startTime"
                                                    labelNoMarginTop
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Field
                                                    autoComplete="off"
                                                    component={MaskedTextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    mask={maskUtils.timeCodeMask()}
                                                    name="endTime"
                                                    labelNoMarginTop
                                                />
                                            </Col>
                                        </Row>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                )}
            </Box>
        );
    }
}

export default CalendarRestriction;

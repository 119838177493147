import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import * as Yup from "yup";

import { actions as accountsActions } from "reducers/accounts";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import AmountField from "pages/_components/fields/formik/AmountField";

import * as i18n from "util/i18n";
import { bool, string, func, shape } from "prop-types";

const FORM_ID = "accounts.movements.filters.amount";

class AmountFilter extends Component {
    componentDidMount() {
        const { setFieldValue, advancedFilter } = this.props;
        if (setFieldValue && advancedFilter?.maxAmount && advancedFilter?.minAmount) {
            setFieldValue("minAmount", { amount: advancedFilter.minAmount, currency: "" });
            setFieldValue("maxAmount", { amount: advancedFilter.maxAmount, currency: "" });
        }
    }

    render() {
        const { currency, isSubmitting } = this.props;

        return (
            <Form style={{ display: "contents" }}>
                <Col xs={6} md={3}>
                    <Field
                        component={AmountField}
                        data={{ options: [{ id: 1, label: i18n.get(`currency.label.${currency}`) }] }}
                        disableSelect
                        idForm={FORM_ID}
                        name="minAmount"
                    />
                </Col>
                <Col xs={6} md={3}>
                    <Field
                        component={AmountField}
                        data={{ options: [{ id: 1, label: i18n.get(`currency.label.${currency}`) }] }}
                        disableSelect
                        idForm={FORM_ID}
                        name="maxAmount"
                    />
                </Col>
                <Col xs={12} md={2} className="pr-md-7 pt-3 pt-md-0 full-height">
                    <Box display="flex" alignY="flex-end" fullHeight>
                        <Button
                            bsStyle="primary"
                            className="mt-auto"
                            label="product.filters.filter"
                            loading={isSubmitting}
                            type="submit"
                            block
                        />
                    </Box>
                </Col>
            </Form>
        );
    }
}

AmountFilter.propTypes = {
    currency: string.isRequired,
    isSubmitting: bool.isRequired,
    setFieldValue: func.isRequired,
    advancedFilter: shape({}),
};

AmountFilter.defaultProps = {
    advancedFilter: null,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        minAmount: {
            amount: "",
            currency: "",
        },
        maxAmount: {
            amount: "",
            currency: "",
        },
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                minAmount: Yup.object().shape({
                    // eslint-disable-next-line no-nested-ternary
                    amount: Number.isInteger(values.minAmount.amount)
                        ? Number.isInteger(values.maxAmount.amount)
                            ? Yup.number().max(values.maxAmount.amount, i18n.get(`${FORM_ID}.minAmount.error`))
                            : Yup.number()
                        : Yup.string().nullable(),
                }),
                maxAmount: Yup.object().shape({
                    // eslint-disable-next-line no-nested-ternary
                    amount: Number.isInteger(values.maxAmount.amount)
                        ? Number.isInteger(values.minAmount.amount)
                            ? Yup.number().min(values.minAmount.amount, i18n.get(`${FORM_ID}.maxAmount.error`))
                            : Yup.number()
                        : Yup.string().nullable(),
                }),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;
        const { maxAmount, minAmount } = filters;

        if (isDesktop) {
            dispatch(
                accountsActions.details(
                    productId,
                    {
                        ...filters,
                        advanced: true,
                        maxAmount: maxAmount.amount,
                        minAmount: minAmount.amount,
                        filterType: "amount",
                    },
                    formikBag,
                ),
            );
        } else {
            dispatch(
                accountsActions.setMobileFilter({
                    filter: "amount",
                    ...filters,
                    advanced: true,
                    maxAmount: maxAmount.amount,
                    minAmount: minAmount.amount,
                }),
            );
            dispatch(push(`/accounts/${productId}/filters/results`));
        }
    },
})(AmountFilter);

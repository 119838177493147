import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import ActiveTokenModal from "pages/enrollment/_components/ActiveTokenModal";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as enrollmentSelectors, actions as enrollmentActions } from "reducers/enrollment";
import { isMobileNativeFunc } from "util/device";
import { BIOMETRIC_CALLBACK_SAVE_TOKEN } from "util/biometric.util";

class Finish extends Component {
    state = {
        isDisplayed: false,
    };

    activateToken = () => {
        const { dispatch, invitationKind } = this.props;

        if (isMobileNativeFunc()) {
            let redirectSuccess = "/tokenActivationSuccess";
            let redirectDocument = "/tokenActivationPending";

            if (invitationKind === "secondary") {
                redirectSuccess = "/tokenActivationPendingSecondary";
                redirectDocument = "/tokenActivationPendingSecondary";
            }
            const callbackDataSelfie = {
                type: BIOMETRIC_CALLBACK_SAVE_TOKEN,
                data: {
                    deviceUuid: window?.app?.getDeviceUUID() || "",
                    deviceModel: window?.app?.getDeviceModel() || "",
                    deviceBrand: window?.app?.getDeviceManufacturer() || "",
                },
                redirectSuccess,
                redirectError: "/tokenEntrustActivationFailed",
                scopeError: [],
                scopeSuccess: [],
                redirectDocument,
                redirectAbort: "/",
            };

            dispatch(enrollmentActions.setCallbackDataSelfie(callbackDataSelfie));

            dispatch(push("/tokenActivationStep1"));
            return;
        }

        this.setState((state) => ({ ...state, isDisplayed: true }));
    };

    handleCloseModal = () => {
        this.setState((state) => ({ ...state, isDisplayed: false }));
    };

    goToLogin = () => {
        const { dispatch } = this.props;
        dispatch(push("/loginStep1"));
    };

    getConfirmationMessage = () => {
        const { userExists, invitationKind, environments } = this.props;

        switch (invitationKind) {
            case "retail":
                if (userExists) {
                    return (
                        <Text labelKey="enrollment.finish.associated.retail.message" align="center" color="heading" />
                    );
                }
                return <Text labelKey="enrollment.finish.created.retail.message" align="center" color="heading" />;

            case "admin":
                if (userExists) {
                    return (
                        <Text
                            labelKey="enrollment.finish.associated.corporate.message"
                            align="center"
                            color="heading"
                        />
                    );
                    // puede ser mas de una empresa, no se le dice nada al usuario
                }
                return (
                    <Box display="flex" column alignY="center" fullWidth>
                        <Text
                            labelKey="enrollment.finish.created.corporate.message"
                            align="center"
                            color="heading"
                            className="mb-9"
                        />
                        <Box display="flex" alignX="center" fullWidth className="mb-4">
                            <Text
                                labelKey="enrollment.finish.created.corporate.environments"
                                align="center"
                                color="heading"
                            />
                            <Text color="heading">: </Text>
                        </Box>
                        <Box display="flex" alignX="center">
                            <Box
                                display="flex"
                                gap="3"
                                wrap
                                alignX="center"
                                alignY="center"
                                fullWidth
                                className="enrollment-columns">
                                {environments.map((environment) => (
                                    <Box
                                        key={environment}
                                        display="flex"
                                        alignY="center"
                                        background="grey"
                                        className="px-4 py-2 enviroment-box"
                                        borderRadius="sm">
                                        <Text color="heading-color" ellipsis>
                                            {environment}
                                        </Text>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                );

            case "secondary":
                if (userExists) {
                    return (
                        <Text
                            labelKey="enrollment.finish.associated.secondary.message"
                            align="center"
                            color="heading"
                        />
                    );
                }
                return (
                    <Box display="flex" column alignY="center" fullWidth>
                        <Text
                            labelKey="enrollment.finish.created.secondary.message"
                            align="center"
                            color="heading"
                            className="mb-9"
                        />
                        <Box display="flex" alignX="center" fullWidth className="mb-4">
                            <Text
                                labelKey="enrollment.finish.created.corporate.environments"
                                align="center"
                                color="heading"
                            />
                            <Text color="heading">: </Text>
                        </Box>
                        <Box display="flex" alignX="center">
                            <Box
                                display="flex"
                                gap="3"
                                wrap
                                alignX="center"
                                alignY="center"
                                fullWidth
                                className="enrollment-columns">
                                {environments.map((environment) => (
                                    <Box
                                        key={environment}
                                        display="flex"
                                        alignY="center"
                                        background="grey"
                                        className="px-4 py-2 enviroment-box"
                                        borderRadius="sm">
                                        <Text color="heading-color" ellipsis>
                                            {environment}
                                        </Text>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                );
            default:
        }
        return null;
    };

    render() {
        const { name, isDesktop } = this.props;
        const { isDisplayed } = this.state;

        return (
            <>
                <ActiveTokenModal handleCloseModal={this.handleCloseModal} isDisplayed={isDisplayed} drawer />
                <HeaderEnrollment />
                <Box>
                    <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Box
                                display="flex"
                                column
                                alignY="center"
                                background="white"
                                borderRadius="xxl"
                                className="white-boxed mt-9 mt-md-12 pt-7 pt-md-12 pb-10 pb-md-12">
                                <Text
                                    component="h1"
                                    className="mb-9 mb-md-11"
                                    labelKey="enrollment.finish.congratulations"
                                    name={name}
                                    size="1"
                                    color="heading"
                                    bold
                                    align="center"
                                />
                                <Box display="flex" alignX="center" fullWidth className="mb-7 mb-md-8">
                                    <Image src="images/icons/successBig.svg" />
                                </Box>
                                <Box display="flex" alignX="center" fullWidth>
                                    {this.getConfirmationMessage()}
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>
                <Box {...(!isDesktop && { className: "mt-auto" })}>
                    <Row gapY={isDesktop ? "8" : "3"}>
                        <Col xs={12} md={4} mdOffset={2}>
                            <Button
                                bsStyle="outline"
                                label="enrollment.finish.activateToken"
                                type="button"
                                onClick={this.activateToken}
                                block
                            />
                        </Col>
                        <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                            <Button
                                bsStyle="primary"
                                label="enrollment.finish.enter"
                                type="button"
                                onClick={this.goToLogin}
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </>
        );
    }
}

Finish.propTypes = {
    dispatch: func.isRequired,
    userExists: bool.isRequired,
    invitationKind: string.isRequired,
    name: string.isRequired,
    environments: arrayOf(string).isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    name: enrollmentSelectors.getName(state),
    userExists: enrollmentSelectors.isUserExists(state),
    invitationKind: enrollmentSelectors.getInvitationKind(state),
    environments: enrollmentSelectors.getEnvironments(state),
});

export default connect(mapStateToProps)(Finish);

import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, bool, func, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withRouter } from "react-router-dom";

const FORM_ID = "administration.advanced.signature.modify";

class PermissionConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        actions: objectOf(func).isRequired,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        userInfo: shape({ hasSignature: bool.isRequired }).isRequired,
        setLocationCustomPath: func.isRequired,
        location: shape({}),
    };

    static defaultProps = {
        routerActions: null,
        fetching: false,
        location: {},
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", FORM_ID);
        }
    };

    handleSubmit = (user, permission, hasPermission, data, formikBag) => {
        const { ...credentials } = data;
        const { actions } = this.props;
        if (hasPermission) {
            actions.userPermissionDeactivatorConfirmation(
                user.idUser,
                user.fullName,
                permission,
                credentials,
                formikBag,
            );
        } else {
            actions.userPermissionActivatorConfirmation(user.idUser, user.fullName, permission, credentials, formikBag);
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const {
            fetching,
            user,
            credentialGroups,
            userInfo: { hasPermissionApprove, hasPermissionCreate },
            location,
        } = this.props;

        const permissionsList = ["create", "approve"];
        const permission = permissionsList.find((p) => location.pathname.includes(p));

        let hasPermission = false;
        hasPermission = permission === "create" ? hasPermissionCreate : hasPermission;
        hasPermission = permission === "approve" ? hasPermissionApprove : hasPermission;
        return (
            <>
                <Head
                    title={`administration.permission.confirm.${permission}.${
                        hasPermission ? "deactivate" : "activate"
                    }.header`}
                    onBack={this.handleBack}
                />

                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-12" border="bottom-border-base-color-1">
                                    <Text
                                        component="h2"
                                        labelKey={`administration.permission.confirm.${permission}.${
                                            hasPermission ? "deactivate" : "activate"
                                        }.title`}
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>

                                <Box display="flex" alignX="center" className="mb-12 py-5" fullWidth>
                                    <Text labelKey="administration.channels.user" color="primary" bold addColon />
                                    <Text color="primary">{user.fullName}</Text>
                                </Box>

                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={(data, formikBag) =>
                                        this.handleSubmit(user, permission, hasPermission, data, formikBag)
                                    }
                                    cancelUrl={`/administration/medium/details/${user.idUser}`}
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

export default withRevelockPositionContext(withRouter(PermissionConfirm));

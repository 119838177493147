import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as creditCardsActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { CONTENT_TYPE_PDF_EXTENSION, CONTENT_TYPE_XLS_EXTENSION } from "util/download";
import { selectors as sessionSelectors } from "reducers/session";

export const ID_ACTIVITY = `creditcard.movements.transit`;
const MovementsTransit = (props) => {
    const {
        productId,
        isDesktop,
        isFetchingMovements,
        movementTransitList,
        dispatch,
        setMovementTransitSize,
        activeEnvironment,
    } = props;

    const fetchMovementTransitList = () => {
        if (productId) {
            dispatch(creditCardsActions.movementsTransitRequest(productId));
        }
    };

    useEffect(() => {
        fetchMovementTransitList();
    }, [productId]);

    useEffect(() => {
        if (movementTransitList && setMovementTransitSize) {
            setMovementTransitSize(movementTransitList.length || 0);
        }
    }, [movementTransitList]);

    const downloadMovementTransitList = (contentType) =>
        dispatch(creditCardsActions.downloadMovementsTransitRequest(productId, contentType));

    const renderHeaderSection = () => (
        <Box display="flex" background="white" fullWidth className="py-0 py-md-3">
            <Box className="ml-3 ml-md-5 mr-auto" display="flex" alignY="center">
                <Image wrapperClassName="fit-content" className="mr-3 mt-2" src="images/icons/tooltip.svg" />
                {activeEnvironment.type === "corporate" ? (
                    <Text
                        size="7"
                        className="pr-3 pr-md-8 my-3 my-md-0"
                        labelKey={`${ID_ACTIVITY}.header.info.corporate`}
                    />
                ) : (
                    <Text size="7" className="pr-3 pr-md-8 my-3 my-md-0" labelKey={`${ID_ACTIVITY}.header.info`} />
                )}
            </Box>

            <Box display="flex" alignX="flex-end" alignY="center" className="mr-md-5">
                <Dropdown
                    image="images/download.svg"
                    label="global.download"
                    labelClassName="visually-hidden"
                    bsStyle="only-icon"
                    pullRight>
                    <Button
                        key="accounts.pdfFile"
                        label="accounts.pdfFile"
                        onClick={() => downloadMovementTransitList(CONTENT_TYPE_PDF_EXTENSION)}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                    <Button
                        key="accounts.xlsFile"
                        label="accounts.xlsFile"
                        onClick={() => downloadMovementTransitList(CONTENT_TYPE_XLS_EXTENSION)}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                </Dropdown>
            </Box>
        </Box>
    );

    const getColumnsTemplate = () => (isDesktop ? "1fr 1fr 2fr 1fr" : "2fr 1fr");
    const renderTableHeader = () => (
        <GridTable.Header>
            <GridTable.Container columnsTemplate={getColumnsTemplate()} className={isDesktop ? "gapX-9" : "gapX-3"}>
                {isDesktop && (
                    <>
                        <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                            <I18n id={`${ID_ACTIVITY}.transactionData`} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX="center" inHeader>
                            <I18n id={`${ID_ACTIVITY}.numAuthorization`} />
                        </GridTable.Data>
                    </>
                )}
                <GridTable.Data columnStart={isDesktop ? 3 : 1} alignX="flex-start" inHeader>
                    <I18n id={`${ID_ACTIVITY}.description`} />
                </GridTable.Data>
                <GridTable.Data columnStart={isDesktop ? 4 : 2} alignX="flex-end" inHeader>
                    <I18n id={`${ID_ACTIVITY}.debitAmount`} />
                </GridTable.Data>
            </GridTable.Container>
        </GridTable.Header>
    );

    const renderItem = (movement) => {
        const { date, authCode, concept, amountStatement } = movement;
        return (
            <GridTable.Container
                className={classNames("product-data", { "gapX-9": isDesktop, "gapX-3": !isDesktop })}
                columnsTemplate={getColumnsTemplate()}
                key="movements.transit"
                rows={isDesktop ? 1 : 2}>
                {isDesktop ? (
                    <>
                        <GridTable.Data className="p-0" columnStart={1} alignX="flex-start">
                            {date && <FormattedDate date={date} />}
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={2} alignX="center">
                            <Text align="center" ellipsis>
                                {authCode || ""}
                            </Text>
                        </GridTable.Data>
                    </>
                ) : (
                    <GridTable.Data className="p-0" columnStart={1} rowStart={2} alignX="flex-start">
                        <Text>{authCode || ""} </Text> {authCode && date ? ` - ` : <span>&nbsp;&nbsp;</span>}
                        {date && <FormattedDate date={date} />}
                    </GridTable.Data>
                )}

                <GridTable.Data className="p-0" columnStart={isDesktop ? 3 : 1}>
                    <Text ellipsis {...(!isDesktop && { bold: true })}>
                        {concept || ""}
                    </Text>
                </GridTable.Data>

                <GridTable.Data
                    className="p-0"
                    columnStart={isDesktop ? 4 : 2}
                    rowWidth={isDesktop ? 1 : 2}
                    alignY="center"
                    alignX="flex-end">
                    {amountStatement && amountStatement.currency && amountStatement.quantity && (
                        <FormattedAmount
                            size={isDesktop ? "6" : "4"}
                            className={classNames("justify-content-end", {
                                "is-negative": amountStatement.quantity < 0,
                            })}
                            noCurrency
                            quantity={amountStatement.quantity}
                        />
                    )}
                </GridTable.Data>
            </GridTable.Container>
        );
    };

    const renderBody = () => {
        if (movementTransitList.length === 0) {
            return (
                <Box
                    display="flex"
                    fullWidth
                    alignX="center"
                    className="no-more-data min-height-7-5rem"
                    key="noMoreMovements">
                    <Text
                        labelKey="creditCards.transitMovements.noMoreMovements"
                        component="p"
                        align="center"
                        size="5"
                        color="text"
                        className="px-5"
                    />
                </Box>
            );
        }
        return movementTransitList.map((movement) => renderItem(movement));
    };

    const renderList = () => (
        <Container className="container--layout scrollable movements-container">
            <GridTable>
                {renderTableHeader()}
                <GridTable.Body>{renderBody()}</GridTable.Body>
            </GridTable>
        </Container>
    );

    return (
        <div className="min-height-10rem">
            <PageLoading loading={isFetchingMovements}>
                <>
                    {renderHeaderSection()}
                    {renderList()}
                </>
            </PageLoading>
        </div>
    );
};

const mapStateToProps = (state) => ({
    movementTransitList: creditCardSelectors.getMovementTransitList(state),
    isFetchingMovements: creditCardSelectors.isFetchingMovements(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

MovementsTransit.propTypes = {
    productId: string.isRequired,
    isDesktop: string.isRequired,
    isFetchingMovements: bool,
    movementTransitList: arrayOf(shape({})),
    dispatch: func,
    setMovementTransitSize: func,
    activeEnvironment: shape({}),
};

MovementsTransit.defaultProps = {
    isFetchingMovements: false,
    movementTransitList: [],
    dispatch: () => {},
    setMovementTransitSize: () => {},
    activeEnvironment: { permissions: [] },
};

export default connect(mapStateToProps)(resizableRoute(MovementsTransit));

import classNames from "classnames";
import MessageHeader from "pages/communications/_components/MessageHeader";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import FormattedDate from "pages/_components/FormattedDate";
import ChatAttachment from "./ChatAttachment";

function MessageSender(props) {
    const { direction, date, idCommunicationTray } = props;

    if (direction === "BANK_TO_CUSTOMER") {
        return (
            <div>
                <Text labelKey={`communications.tray.${idCommunicationTray}`} color="secondary" size="6" bold />
                <Text size="6">
                    {" "}
                    -
                    <FormattedDate date={date} anotherFormat="DD/MM/YYYY HH:mm" />
                </Text>
            </div>
        );
    }
    return (
        <div>
            <Text labelKey="communication.thread.message.me" color="secondary" size="6" bold />
            <Text size="6">
                {" "}
                -
                <FormattedDate date={date} anotherFormat="DD/MM/YYYY HH:mm" />
            </Text>
        </div>
    );
}

MessageSender.propTypes = {
    direction: string.isRequired,
    date: string.isRequired,
    idCommunicationTray: number.isRequired,
};

class ChatLayout extends Component {
    static propTypes = {
        attachments: arrayOf(
            shape({
                idCommunication: number,
                files: arrayOf(
                    shape({
                        idFile: number,
                        fileName: string,
                    }),
                ),
            }),
        ),
        detail: arrayOf(
            shape({
                body: string,
                communicationTray: shape({
                    name: string,
                }),
                idCommunication: number,
                subject: string,
                sentDateAsString: string,
            }),
        ),
        scrollToBottom: func.isRequired,
        handleDownloadAttachment: func.isRequired,
        title: string.isRequired,
        onClose: func,
        isDesktop: bool.isRequired,
        createButtonOrText: func.isRequired,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
        onClose: null,
    };

    componentDidMount() {
        const { scrollToBottom } = this.props;
        scrollToBottom();
    }

    drawChatBubbles = () => {
        const {
            detail,
            attachments,
            handleDownloadAttachment,
            title,
            onClose,
            isDesktop,
            createButtonOrText,
        } = this.props;

        return (
            <Box component="ul" display="flex" column fullWidth>
                {detail.map((obj, i = 0) => (
                    <Box
                        component="li"
                        noList
                        className={classNames("messageContainer px-7 mb-3 pt-5", obj.direction, {
                            "pb-5": !attachments[i].fileList,
                            "pb-3":
                                attachments &&
                                attachments[i] &&
                                attachments[i].idCommunication === obj.idCommunication &&
                                attachments[i].fileList,
                        })}
                        key={i}
                        background="white"
                        {...(!isDesktop && { border: "secondary-background-color " })}
                        borderRadius="default">
                        {i === 0 && isDesktop && <MessageHeader title={title} onClose={onClose} />}
                        <Box key={i}>
                            <MessageSender
                                direction={obj.direction}
                                date={obj.sentDate}
                                idCommunicationTray={obj.communicationTray.idCommunicationTray}
                            />
                            {createButtonOrText(obj.body)}

                            {attachments &&
                                attachments[i] &&
                                attachments[i].idCommunication === obj.idCommunication &&
                                attachments[i].fileList && (
                                    <ChatAttachment
                                        attachment={attachments[i]}
                                        handleDownloadAttachment={handleDownloadAttachment}
                                        communicationType={detail[0]?.communicationType}
                                    />
                                )}
                        </Box>
                    </Box>
                ))}
            </Box>
        );
    };

    render() {
        return this.drawChatBubbles();
    }
}
export default resizableRoute(ChatLayout);

import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import CapsList from "pages/administration/_components/CapsList";
import { arrayOf, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

const PAGE_IDENTIFIER = "administration.channel.confirm";

class ChannelsConfirm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(
            shape({
                idCredentialGroup: string,
            }),
        ).isRequired,
        user: shape({
            idUser: string,
            idUserStatus: string,
            firstName: string,
            lastName: string,
        }).isRequired,
        routerActions: shape({
            goBack: func,
        }).isRequired,
        actions: shape({
            updateChannelsRequest: func,
        }).isRequired,
        formActions: shape({
            signTransaction: func,
        }).isRequired,
        caps: shape({}).isRequired,
        idTransaction: string,
        idActivity: string,
        setLocationCustomPath: func.isRequired,
    };

    static defaultProps = {
        idTransaction: "",
        idActivity: "",
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, caps, user, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            actions.updateChannelsRequest(
                {
                    caps: { ...caps },
                    idUser: user.idUser,
                    status: user.idUserStatus,
                    credentials,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { user, credentialGroups } = this.props;

        return (
            <>
                <Head title="administration.limits.title" onBack={this.handleBack} />

                <MainContainer showLoader={!Object.keys(user).length}>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="xxl"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-8" border="bottom-border-base-color-1">
                                    <Text
                                        component="h2"
                                        labelKey="administration.forms.confirm.credentials"
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>

                                <Box display="flex" alignX="center" className="mb-9" fullWidth>
                                    <Text labelKey="administration.channels.user" color="primary" bold addColon />
                                    <Text color="primary">{user?.fullName}</Text>
                                </Box>
                                <Box className="mb-10">
                                    <CapsList hasDefaultLabel isConfirmationStep />
                                </Box>

                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    cancelUrl={`/administration/medium/details/${user.idUser}`}
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

export default withRevelockPositionContext(ChannelsConfirm);

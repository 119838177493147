import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import PermissionsList from "pages/administration/_components/PermissionsList";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { permissionsSelectors } from "reducers/administration";

const PAGE_IDENTIFIER = "administration.permissions.ticket";

class AdministrationPermissionsTicket extends Component {
    static propTypes = {
        user: shape({}).isRequired,
        isFetching: bool.isRequired,
        setLocationCustomPath: func.isRequired,
    };

    componentDidMount() {
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    render() {
        const { user, isFetching } = this.props;

        return (
            <Box display="flex" column>
                <Box display="flex" className="mb-4">
                    <Text component="label" labelKey="administration.permissions.user" addColon className="m-0" />
                    <Text>{`${user.firstName} ${user.lastName}`}</Text>
                </Box>

                <Box columnClass="list-wrapper">
                    <PageLoading loading={isFetching}>
                        <PermissionsList
                            onlyRead
                            idUser={user.idUser}
                            classNameContainer="px-0"
                            contentClassName="px-0"
                            childrenListULClassName="full-width"
                            buttonLiClassName="px-0 border-bottom-background-divider-1">
                            {(list) => (
                                <>
                                    {list ? (
                                        <>
                                            <Text
                                                component="h4"
                                                className="mt-7 mb-4"
                                                labelKey="administration.permissions.permissionsApplied"
                                                color="primary"
                                                size="5"
                                                bold
                                            />
                                            {list}
                                        </>
                                    ) : (
                                        <Text
                                            component="h4"
                                            className="mt-7 mb-4"
                                            labelKey="administration.permissions.empty"
                                            color="primary"
                                            size="5"
                                            bold
                                        />
                                    )}
                                </>
                            )}
                        </PermissionsList>
                    </PageLoading>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: permissionsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(AdministrationPermissionsTicket));

import { differenceInDays, format, isToday, isYesterday } from "date-fns";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en";
import capitalize from "lodash/capitalize";

import { get as getText, getLang } from "util/i18n";
import { get as getConfig } from "util/config";

const locale = { es, en };

export const date = (dateToFormat, formatDate = null, lang = null) => {
    const currentLang = getLang();

    const shortDateFormat = getConfig("frontend.shortDateFormat");
    return format(dateToFormat, formatDate || shortDateFormat, { locale: locale[lang || currentLang] });
};

export const naturalDate = (dateToFormat) => {
    const wordBeforeTime = getText("settings.lastLogin.beforeTime");

    if (isToday(dateToFormat)) {
        const wordToday = getText("scheduler.executionToday");

        return capitalize(`${wordToday} ${wordBeforeTime} ${date(dateToFormat, "HH:mm")}`);
    }
    if (isYesterday(dateToFormat)) {
        const wordYesterday = getText("scheduler.executionYesterday");

        return capitalize(`${wordYesterday} ${wordBeforeTime} ${date(dateToFormat, "HH:mm")}`);
    }
    if (differenceInDays(new Date(), dateToFormat) < 7) {
        return capitalize(date(dateToFormat, `dddd [${wordBeforeTime}] HH:mm`));
    }

    return capitalize(date(dateToFormat, getText("datepicker.format")));
};

export const formatNumber = (number, maximumFractionDigits = 6, minimumFractionDigits = 2) => {
    if (number !== null) {
        return number.toLocaleString("en-US", {
            maximumFractionDigits,
            minimumFractionDigits,
        });
    }
    return null;
};

export const maskEmail = (email = "") => {
    const [name, domain] = email.split("@");
    let [beforeDot, afterDot] = domain.split(".");
    beforeDot = "***";
    afterDot = `.${afterDot}`;
    const { length: len } = name;
    let maskedName = "";
    let ast = "*";
    if (len > 3) {
        ast = "*".repeat(len - 2);
    }
    maskedName = `${name[0]}${ast}${name[len - 1]}`;
    const maskedEmail = `${maskedName}@${beforeDot}${afterDot}`;
    return maskedEmail.toUpperCase();
};

export const maskPhoneNumber = (phoneNumber = "") => {
    if (!phoneNumber) {
        return "";
    }
    const hasAreaCode = phoneNumber.split("-").length > 1;
    let [areaCode, number] = phoneNumber.split("-");

    if (!number) {
        number = phoneNumber;
    }

    areaCode = hasAreaCode ? `+${areaCode} ` : "";
    const { length: len } = number;

    let maskedNumber = "";
    let ast = "*";
    let nonMakedNumber = "";
    if (len > 5) {
        ast = "*".repeat(len - 4);
        nonMakedNumber = number.substring(len - 4);
    } else {
        ast = "**";
        nonMakedNumber = number.substring(len - 1);
    }

    maskedNumber = `${areaCode}${ast}${nonMakedNumber}`;
    return maskedNumber;
};

export default {};

import { Form, withFormik } from "formik";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import {
    actions as updateCompanyDataActions,
    selectors as updateCompanyDataSelectors,
} from "reducers/updateCompanyData/updateCompanyData.reducer";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const ACTIVITY_ID = "administration.corporateData.update.step0.send";
const UpdateCompanyDataStepZero = ({
    credentials = [],
    dispatch,
    setFieldValue,
    isSubmitting,
    cancelHandler,
    activeEnvironment,
    dateLastUpdateCorporate,
    stateUpdateCorporate,
    isFetching,
}) => {
    useEffect(() => {
        dispatch(updateCompanyDataActions.getCredentialGroupsRequest({ activityId: ACTIVITY_ID }));
    }, []);

    const getStatus = (state) => {
        let statusClass = "";
        switch (state) {
            case "updated":
                statusClass = "success";
                break;
            case "inReview":
                statusClass = "warning";
                break;
            case "outdated":
                statusClass = "error";
                break;
            default:
                break;
        }
        return statusClass;
    };

    return (
        <PageLoading loading={isSubmitting || isFetching}>
            <Box display="flex" column alignX="between" className="scrollable-content px-0" fullWidth fullHeight>
                <Form className="full-height">
                    <Box display="flex" column alignX="between" alignY="center" fullWidth fullHeight>
                        <Text
                            key="text"
                            component="p"
                            className="m-0"
                            color="heading"
                            align="center"
                            labelKey="token.drawer.text"
                        />

                        <Box display="flex" column alignX="center" alignY="center" className="mb-0" gap="2" fullWidth>
                            <Box display="flex" column className="mb-8" gap="3">
                                <Box display="flex">
                                    <Image key="src" src="images/icons/corporateBuildingBig.svg" />
                                </Box>
                                <Text color="heading-color" bold>
                                    {activeEnvironment.name}
                                </Text>
                            </Box>
                            <Box display="flex">
                                <Text component="label" className="m-0" regular addColon>
                                    {i18n.get("global.status.label")}
                                </Text>
                                <Sticker
                                    labelKey={`global.status.${stateUpdateCorporate}.label`}
                                    uppercase={false}
                                    bold
                                    textSize="7"
                                    status={getStatus(stateUpdateCorporate)}
                                />
                            </Box>
                            <Box display="flex">
                                <Text component="label" className="m-0" regular addColon>
                                    {i18n.get("global.lastUpdate.label")}
                                </Text>
                                <Text size="5" bold color="heading-color">
                                    {dateLastUpdateCorporate}
                                </Text>
                            </Box>
                        </Box>

                        <Box display="flex" column alignX="flex-end" fullWidth>
                            <CredentialTokenComponent
                                credentials={credentials}
                                onChangeToken={(tokenCode) => {
                                    if (setFieldValue) {
                                        setFieldValue("otp", tokenCode);
                                    }
                                }}
                                relativeStyleError
                            />
                        </Box>
                        <Box className="mt-7 pb-9 pb-md-12" fullWidth>
                            {stateUpdateCorporate !== "inReview" && (
                                <Button
                                    block
                                    type="submit"
                                    label="global.confirm"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    disabled={isSubmitting}
                                />
                            )}
                            <Button
                                block
                                type="button"
                                bsStyle="outline"
                                onClick={cancelHandler}
                                disabled={isSubmitting}
                                label="global.cancel"
                            />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </PageLoading>
    );
};

UpdateCompanyDataStepZero.propTypes = {
    credentials: shape(string),
    dispatch: func,
    setFieldValue: func.isRequired,
    isSubmitting: bool,
    isFetching: bool,
    cancelHandler: func,
    activeEnvironment: shape({ name: string }),
    dateLastUpdateCorporate: string.isRequired,
    stateUpdateCorporate: string.isRequired,
};

UpdateCompanyDataStepZero.defaultProps = {
    dispatch: () => {},
    isSubmitting: false,
    isFetching: false,
    cancelHandler: null,
    activeEnvironment: null,
    credentials: [],
};

const mapStateToProps = (state) => ({
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(updateCompanyDataSelectors.getCredentialGroups(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    dateLastUpdateCorporate: updateCompanyDataSelectors.getDateLastUpdateCorporate(state),
    stateUpdateCorporate: updateCompanyDataSelectors.getStateUpdateCorporate(state),
    isFetching: updateCompanyDataSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("form.credential.otp.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(updateCompanyDataActions.updateCompanyDataRequest(otp, formikBag));
        },
    }),
)(UpdateCompanyDataStepZero);

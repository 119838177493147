import Container from "pages/_components/Container";
import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import CreditCardsList from "pages/creditCards/List";
import { arrayOf, bool, date, func, number, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as creditCardsActions, selectors as creditCardsSelectors } from "reducers/creditCard";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import { actions as productRequestActions } from "reducers/productRequest";

class CreditCards extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isRequestAvailable: bool,
        isMobile: bool.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        creditCards: arrayOf(
            shape({
                productAlias: string,
                availableBalance: number,
                balance: number,
                number: oneOfType([number, string]),
                shortLabel: string,
                availableBalanceCurrency: string,
                expirationDate: date,
                label: string,
            }),
        ).isRequired,
        loggedUser: shape({
            bankType: string,
        }),
        activeEnvironment: shape({
            type: string,
        }),
        nonFilterCreditCards: arrayOf(shape({})),
    };

    static defaultProps = {
        isRequestAvailable: false,
        loggedUser: null,
        activeEnvironment: {},
        nonFilterCreditCards: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(creditCardsActions.listRequest("/creditCards"));
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    displayProductRequestModal = () => {
        const { dispatch } = this.props;
        dispatch(productRequestActions.modalShow("TC"));
    };

    renderHeader = () => {
        const { isRequestAvailable, isMobile, loggedUser, nonFilterCreditCards } = this.props;
        const { bankType } = loggedUser;
        const requestCard = config.get(`card.requestCard.byBankType.${bankType}`);
        const hasCreditCards = nonFilterCreditCards.length >= 1;

        if (!isRequestAvailable) {
            return (
                <Head
                    onBack={this.handleBack}
                    title="menu.creditcards"
                    {...(!hasCreditCards &&
                        !isMobile && {
                            onAdd: this.displayProductRequestModal,
                            addLinkToLabel: requestCard ? "creditCard.new" : undefined,
                        })}
                />
            );
        }

        if (isMobile) {
            return <Head title="menu.creditcards" />;
        }

        return (
            <Head
                onBack={this.handleBack}
                title="menu.creditcards"
                {...(!hasCreditCards &&
                    !isMobile && {
                        onAdd: this.displayProductRequestModal,
                        addLinkToLabel: requestCard ? "creditCard.new" : undefined,
                    })}
            />
        );
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/form/creditCardRequest/"));
    };

    render() {
        const { fetching, creditCards, isDesktop, isRequestAvailable, activeEnvironment } = this.props;
        const isLoading = fetching && !creditCards.length;

        return (
            <>
                <Notification scopeToShow="creditcards" />
                {!isLoading && this.renderHeader()}

                <MainContainer showLoader={isLoading}>
                    <Row fullHeight>
                        <Col xs={12} md={10} mdOffset={1} lg={8} lgOffset={2} className="py-7 py-md-0">
                            {creditCards.length ? (
                                <>
                                    <CreditCardsList creditCards={creditCards} isDesktop={isDesktop} />
                                    {!isDesktop && isRequestAvailable && (
                                        <Container className="container--layout items-center">
                                            <Container.Column className="col-12">
                                                <Link
                                                    to="/form/creditCardRequest/"
                                                    className="btn btn-outline btn-block">
                                                    <Image src="images/plus.svg" />
                                                    <I18n id="creditCard.new" />
                                                </Link>
                                            </Container.Column>
                                        </Container>
                                    )}
                                </>
                            ) : (
                                (() => {
                                    if (activeEnvironment && activeEnvironment.type === "corporate") {
                                        return (
                                            <Box display="flex" column alignX="between" fullHeight>
                                                <EmptyTextBox text="product.empty.text.cards.corporate" />
                                                {!isDesktop && (
                                                    <Button
                                                        bsStyle="primary"
                                                        label="creditCard.new"
                                                        block
                                                        image="images/icons/circlePlusEvenOdd.svg"
                                                        onClick={this.displayProductRequestModal}
                                                    />
                                                )}
                                            </Box>
                                        );
                                    }
                                    return (
                                        <Box display="flex" column alignX="between" fullHeight>
                                            <EmptyTextBox text="product.empty.text.cards" footerLink />
                                            {!isDesktop && (
                                                <Button
                                                    bsStyle="primary"
                                                    label="creditCard.new"
                                                    block
                                                    image="images/icons/circlePlusEvenOdd.svg"
                                                    onClick={this.displayProductRequestModal}
                                                />
                                            )}
                                        </Box>
                                    );
                                })()
                            )}
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: creditCardsSelectors.isFetching(state),
    creditCards: creditCardsSelectors.getList(state)?.filter((creditCard) => creditCard.isVisibleInWidget),
    loggedUser: sessionSelectors.getUser(state),
    nonFilterCreditCards: creditCardsSelectors.getList(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(CreditCards);

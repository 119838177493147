/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable prefer-const */
import * as configUtils from "util/config";
import { isMobileNativeFunc } from "util/device";
import { crashLogData } from "../crashReport/crashReport.util";

const isAvailablePlugin = () => window?.cordova?.plugins?.MonitorDbfPlugin;

export const startSensorDataCapture = () =>
    new Promise((resolve) => {
        if (!isAvailablePlugin()) {
            resolve(undefined);
            return;
        }

        window.cordova.plugins.MonitorDbfPlugin.startSensorDataCapture(
            (response) => {
                resolve(response);
            },
            (error) => {
                crashLogData({ sdkError: error });
                resolve(error);
            },
        );
    });

export const generateMobileFingerPrintToken = (idActivity) =>
    new Promise((resolve) => {
        if (!isAvailablePlugin()) {
            resolve(undefined);
            return;
        }

        window.cordova.plugins.MonitorDbfPlugin.generateFingerPrintToken(
            idActivity,
            (response) => {
                resolve(JSON.parse(response)?.data);
            },
            (error) => {
                crashLogData({ sdkError: error });
                resolve(error);
            },
        );
    });

export const getIpLocation = async () => {
    const proIpUrl = configUtils.get("monitorDbf.proIp.api.url", "https://pro.ip-api.com/json");
    const proIpApiKey = configUtils.get("monitorDbf.proIp.api.key");

    try {
        const response = await fetch(
            `${proIpUrl}/?fields=status,message,continent,continentCode,country,countryCode,countryCode3,region,regionName,city,district,zip,lat,lon,timezone,offset,currentTime,currency,callingCode,isp,org,as,asname,reverse,mobile,proxy,hosting,query&key=${proIpApiKey}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
        crashLogData({ title: "GetIpLocation error", error });
        return {};
    }
};

export const generateWebFPTokenWithGeoip = async () => {
    const fingerprint = await window.MPFingerprint.getData(true, true);
    try {
        const ipLocation = await getIpLocation();
        fingerprint.Geoip = ipLocation; // concatenate ip information
        return JSON.stringify(fingerprint);
    } catch (error) {
        console.log(error);
        crashLogData({ title: "GetIpLocation error", error });
        return JSON.stringify(fingerprint); // return only the fingerprint if there is an error
    }
};

export const getFpTokenbyDevice = async (idActivity) => {
    const fingerPrintToken = isMobileNativeFunc()
        ? await generateMobileFingerPrintToken(idActivity)
        : await generateWebFPTokenWithGeoip();

    return { fingerPrintToken };
};

export const nonTransactionalActivities = ["beneficiary.update.send", "beneficiary.create.send"];

import * as API from "middleware/api";

export const recoveryPassStep1 = (username, notificationType) =>
    API.executeAnonymous("/session.recoverPassword.step1", {
        _usernameToReset: username,
        _notificationType: notificationType,
    });

export const recoveryPassStep2 = (username, notificationType, documentCountry, documentType, documentNumber) =>
    API.executeAnonymous("/session.recoverPassword.step2", {
        _usernameToReset: username,
        _notificationType: notificationType,
        _documentCountry: documentCountry,
        _documentType: documentType,
        _documentNumber: documentNumber,
    });

export const recoveryPassStep3 = (verificationCode, captcha, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step3",
        {
            _verificationCode: verificationCode,
            _captcha: captcha,
        },
        exchangeToken,
    );

export const recoveryPassStep4 = (newPassword, newPasswordRepeat, exchangeToken, fingerPrintToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step4",
        {
            _newPassword: newPassword,
            _newPasswordRepeat: newPasswordRepeat,
            fingerPrintToken,
        },
        exchangeToken,
    );

import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { func, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "administration.restrictions.detete.ticket";

class RestrictionsUserDeleteTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    componentDidMount() {
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    render() {
        const { transactionData } = this.props;
        if (transactionData.data) {
            return (
                <Box display="flex" column>
                    <Text
                        component="h3"
                        labelKey="administration.restrictions.user.deleted.all"
                        color="primary"
                        className="m-o"
                        size="5"
                        bold
                    />

                    {transactionData.data.userName}
                </Box>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(RestrictionsUserDeleteTicketData));

import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Credential from "pages/_components/fields/credentials/Credential";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";

export const DEFAULT_REQUIRED = "program.date.blank";
class AdministrationFormConfirmation extends Component {
    static propTypes = {
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        dispatch: func.isRequired,
        onSubmit: func.isRequired,
        otherFieldFunction: func,
        otherInitialValues: shape({}),
        renderOutOfFormik: bool,
        cancelUrl: string,
        onClickCancel: func,
        fetching: bool.isRequired,
        labelCancel: string,
    };

    static defaultProps = {
        otherFieldFunction: undefined,
        otherInitialValues: {},
        renderOutOfFormik: false,
        cancelUrl: "/administration/users",
        onClickCancel: null,
        labelCancel: "global.cancel",
    };

    renderCredentials = (credentialGroupsFiltered) =>
        credentialGroupsFiltered.map((credential) => (
            <div key={credential}>
                <Field idForm="form.credential" name={credential} component={Credential} type={credential} autoFocus />
            </div>
        ));

    renderCredentialsToken = (credentialGroupsFiltered, propsForm) => (
        <>
            {credentialGroupsFiltered && (
                <CredentialTokenComponent
                    credentials={credentialGroupsFiltered}
                    propsForm={propsForm}
                    onChangeToken={(tokenCode) => {
                        if (propsForm?.setFieldValue) {
                            propsForm.setFieldValue("otp", tokenCode);
                        }
                    }}
                />
            )}
        </>
    );

    render() {
        const {
            credentialGroups,
            dispatch,
            onSubmit,
            otherFieldFunction,
            otherInitialValues,
            renderOutOfFormik,
            cancelUrl,
            onClickCancel,
            fetching,
            labelCancel,
        } = this.props;
        const credentialGroupsFiltered = compose(
            (array) => array.filter((item) => item !== "accessToken"),
            removeDuplicateItems,
            flattenArray,
            (array) => array.map(({ credentials }) => credentials),
        )(credentialGroups);
        const initialValues = credentialGroupsFiltered.reduce(
            (values, credential) => ({ ...values, [credential]: "" }),
            {},
        );

        if (!Object.keys(initialValues).length) {
            return null;
        }

        if (renderOutOfFormik) {
            return this.renderCredentials(credentialGroupsFiltered);
        }

        return (
            <Formik
                initialValues={{ ...initialValues, ...otherInitialValues }}
                validationSchema={() => {
                    const requiredMessage = i18n.get(DEFAULT_REQUIRED);
                    return Yup.object().shape({
                        otp: Yup.string().required(requiredMessage),
                    });
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onSubmit}>
                {(propsForm) => (
                    <Form className="position-relative">
                        {otherFieldFunction && otherFieldFunction()}
                        <section className="container--layout items-center">
                            {this.renderCredentialsToken(credentialGroupsFiltered, propsForm)}
                        </section>
                        <Box component="section" position="absolute" top="11rem" fullWidth>
                            <Row className="justify-content-center">
                                <Col xs={6}>
                                    <Button
                                        bsStyle="outline"
                                        label={labelCancel}
                                        onClick={() => {
                                            if (onClickCancel) {
                                                onClickCancel();
                                            } else {
                                                dispatch(push(cancelUrl));
                                            }
                                        }}
                                        block
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        type="submit"
                                        label="global.confirm"
                                        bsStyle="primary"
                                        loading={propsForm?.isSubmitting || fetching}
                                        block
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default connect()(AdministrationFormConfirmation);

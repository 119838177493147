import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { element, func, shape, string } from "prop-types";
import { maskEmail } from "util/format";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class UserLink extends Component {
    static propTypes = {
        children: element,
        onSelect: func,
        loggedUser: shape({
            userFullName: string.isRequired,
            email: string.isRequired,
        }).isRequired,
        dispatch: func.isRequired,
        actionDone: func,
    };

    static defaultProps = {
        children: null,
        onSelect: null,
        actionDone: () => {},
    };

    goToSettings = () => {
        const { actionDone, onSelect, dispatch } = this.props;
        actionDone();
        if (onSelect) {
            onSelect();
        }

        dispatch(push("/settings"));
    };

    render() {
        const {
            children,
            loggedUser: { userFullName, email },
        } = this.props;

        return (
            <>
                <Box display="flex" className="popup-top" alignY="center" column>
                    <Text color="text-boton" size="3" bold>
                        {userFullName}
                    </Text>
                    <Text color="text" size="6">
                        {maskEmail(email)}
                    </Text>
                    <Button bsStyle="link" onClick={this.goToSettings} className="config-btn px-0" secondary block>
                        {i18n.get("settings.index")}
                    </Button>
                </Box>

                {children}
            </>
        );
    }
}

export default connect()(UserLink);

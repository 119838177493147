import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";

const HeaderEnrollment = ({ title, subtitle, isDesktop }) => (
    <Box component="header" display="flex" className="pt-0 pb-5 py-md-7 " fullWidth column>
        <Row>
            <Col xs={12} md={8} mdOffset={2}>
                {title ? (
                    <Box display="flex" {...(!isDesktop && { alignX: "center" })}>
                        <Text
                            component="h1"
                            color="heading"
                            {...(!isDesktop && { className: "mt-0" })}
                            bold
                            labelKey={title}
                            {...(!isDesktop && { align: "center" })}
                        />
                    </Box>
                ) : null}

                {subtitle ? (
                    <Box display="flex" fullWidth {...(!isDesktop && { alignX: "center" })}>
                        <Text
                            component="h2"
                            className="m-0"
                            size="5"
                            color="heading"
                            labelKey={subtitle}
                            {...(!isDesktop && { align: "center" })}
                        />
                    </Box>
                ) : null}
            </Col>
        </Row>
    </Box>
);

HeaderEnrollment.propTypes = {
    isDesktop: bool.isRequired,
    title: string,
    subtitle: string,
};

HeaderEnrollment.defaultProps = {
    title: null,
    subtitle: null,
};

export default resizableRoute(HeaderEnrollment);

import classNames from "classnames";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import {
    actions as creditCardMovementsActions,
    selectors as creditCardMovementsSelectors,
} from "reducers/creditCardMovementDetails";
import { getLocationBasePath } from "util/revelock";
import { insertLinebreaks } from "util/string";
import { selectors as productSelectors } from "reducers/products";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import Head from "pages/_components/Head";
import { CreditCardFiantialReliefStatus, CreditCardStatus } from "../../_components/CreditCard/CreditCard";

const PAGE_IDENTIFIER = "creditCards.movementDetails";
class CreditrCardMovementDetails extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        movement: shape({
            idProduct: string,
            idStatement: number,
            note: string,
        }).isRequired,
        isDesktop: bool,
        match: shape().isRequired,
        setLocationCustomPath: func,
        getSelectProduct: shape({}).isRequired,
        creditCardDetail: shape({}).isRequired,
    };

    static defaultProps = {
        isDesktop: null,
        setLocationCustomPath: () => {},
    };

    state = {
        note: {
            value: "",
            isEditing: true,
        },
        saving: false,
        isEditAvailable: false,
    };

    componentDidMount() {
        const { movement } = this.props;
        this.setState({ note: { value: movement?.note || "", isEditing: !movement?.note } });
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleChange = (event) => {
        const { value } = event.target;
        this.setState({ note: { value, isEditing: true } });
    };

    handleClick = () => {
        const { note } = this.state;

        const newValue = note?.value?.replace(/\n+/g, "\n");

        const { dispatch, movement } = this.props;

        this.setState({
            saving: true,
        });

        dispatch(
            creditCardMovementsActions.updateNoteRequest(
                movement.idProduct,
                movement.idStatement,
                insertLinebreaks(newValue),
                this.onFinish,
            ),
        );
    };

    onFinish = () => {
        const {
            note: { value },
        } = this.state;
        this.setState({ saving: false, note: { isEditing: false, value } });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(creditCardMovementsActions.closeOptions());
        dispatch(goBack());
    };

    render() {
        const {
            note: { value },
            // eslint-disable-next-line no-unused-vars
            saving,
            isEditAvailable,
        } = this.state;

        const { movement, isDesktop, dispatch, getSelectProduct, creditCardDetail } = this.props;

        const noFinancialRelief =
            !creditCardDetail?.financialRelief ||
            creditCardDetail?.financialRelief === CreditCardFiantialReliefStatus.N;
        const activeCard = getSelectProduct.statusCode === CreditCardStatus.Actived;
        const showInstallmentButton =
            activeCard &&
            noFinancialRelief &&
            movement?.installmentPurchaseAvailable &&
            !movement?.hasInstallmentPurchase;

        return (
            <>
                <Head
                    onBack={this.handleBack}
                    title="creditCards.movement.detail.title"
                    {...(showInstallmentButton &&
                        isDesktop && {
                            actionButton: () => {
                                dispatch(push("/formCustom/installmentPurchase"));
                            },
                            actionButtonLabel: "installment.purchase.movementDetail.header.label",
                            actionButtonBsStyle: "primary",
                        })}
                    subtitle={getSelectProduct.description}
                />

                <Notification scopeToShow="movementDetail" />

                <MainContainer>
                    {movement && (
                        <Box display="flex" column gap="8">
                            <Box
                                background="white"
                                borderRadius="default"
                                className="p-5 mt-5 mt-md-0 pb-7 pb-md-0 mx-n-5">
                                <form className="above-the-fold pb-0 pb-md-8">
                                    <Row>
                                        <Col xs={12} md={10} mdOffset={1}>
                                            <Row alignY="center">
                                                <Col xs={12}>
                                                    <Box
                                                        border="bottom-background-divider-1"
                                                        className="pb-5 pt-md-5 pb-md-7">
                                                        <Text
                                                            bold
                                                            size={isDesktop ? "5" : "3"}
                                                            color="heading"
                                                            labelKey="creditCards.movement.detail.date"
                                                        />
                                                        <Text size={isDesktop ? "5" : "3"} color="heading">
                                                            {": "}
                                                            <FormattedDate date={movement?.valueDate} />
                                                        </Text>
                                                    </Box>
                                                </Col>

                                                <Col xs={12} md={6}>
                                                    <Box component="ul" className="data-list pt-0 pt-md-7">
                                                        <Box component="li" className="mb-3">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="creditCards.movement.detail.dateprocess"
                                                            />
                                                            <Text size={isDesktop ? "5" : "3"} color="heading">
                                                                {": "}
                                                                <FormattedDate date={movement?.date} />
                                                            </Text>
                                                        </Box>
                                                        <Box component="li" className="mb-3">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="creditCards.movement.detail.description"
                                                            />
                                                            <Text size={isDesktop ? "5" : "3"} color="heading">
                                                                {": "}
                                                                {movement?.concept}
                                                            </Text>
                                                        </Box>
                                                        <Box display="flex" component="li">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="creditCards.movement.detail.amount"
                                                            />
                                                            <FormattedAmount
                                                                color={movement?.sourceAmount < 0 ? "error" : "heading"}
                                                                size={isDesktop ? "5" : "3"}
                                                                currency={`: ${movement?.sourceAmountCurrency}`}
                                                                quantity={movement?.sourceAmount}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Col>

                                                <Col xs={12} md={6} className="full-height">
                                                    <Box display="flex" column fullHeight className="form-group">
                                                        <label htmlFor="movement.detail" className="control-label mb-0">
                                                            <Box display="flex" column>
                                                                <Text
                                                                    bold
                                                                    size={isDesktop ? "5" : "3"}
                                                                    color="heading"
                                                                    labelKey="creditCards.movement.detail.notes"
                                                                    className="data-label-special-mb"
                                                                />

                                                                <Box fullHeight className="input-group">
                                                                    <textarea
                                                                        id="movement.detail"
                                                                        {...(!isEditAvailable && {
                                                                            tabIndex: "-1",
                                                                            readOnly: true,
                                                                        })}
                                                                        className={classNames(
                                                                            "form-control full-height min-height-7rem",
                                                                            { "no-focusable": !isEditAvailable },
                                                                        )}
                                                                        value={insertLinebreaks(value)}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </label>
                                                    </Box>
                                                </Col>

                                                <Box display="flex" alignX="flex-end">
                                                    {!isEditAvailable ? (
                                                        <Button
                                                            className="btn-link"
                                                            image="images/editPen.svg"
                                                            label="global.edit"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isEditAvailable: !isEditAvailable,
                                                                });
                                                            }}
                                                        />
                                                    ) : (
                                                        <Box display="flex" gap="5">
                                                            <Button
                                                                bsStyle="only-icon"
                                                                image="images/icons/circleCross.svg"
                                                                className="widget-edit-close"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isEditAvailable: false,
                                                                    });
                                                                    this.setState({
                                                                        note: {
                                                                            value: movement?.note || "",
                                                                            isEditing: !movement?.note,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                            <Button
                                                                bsStyle="primary"
                                                                image="images/check-circle.svg"
                                                                className="widget-edit-close"
                                                                onClick={() => {
                                                                    this.handleClick();
                                                                    this.setState({
                                                                        isEditAvailable: false,
                                                                    });
                                                                }}
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                            </Box>

                            <Row gapY="3">
                                {!isDesktop && showInstallmentButton && (
                                    <Col xs={12} md={4} mdOffset={4}>
                                        <Button
                                            bsStyle="primary"
                                            label="installment.purchase.movementDetail.header.label"
                                            href="/formCustom/installmentPurchase"
                                            block
                                        />
                                    </Col>
                                )}
                                <Col xs={12} md={4} mdOffset={4}>
                                    <Button bsStyle="outline" label="global.back" onClick={this.handleBack} block />
                                </Col>
                            </Row>
                        </Box>
                    )}
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;

    return {
        movement: creditCardMovementsSelectors.getSelectedMovement(state),
        getSelectProduct: productSelectors.getSelectProduct(state),
        creditCardDetail: creditCardSelectors.getDetail(state, id),
    };
};

export default connect(mapStateToProps)(resizableRoute(withRevelockPositionContext(CreditrCardMovementDetails)));

import React, { Component } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { bool, number, func } from "prop-types";
import classNames from "classnames";

import { history } from "store";

import { MAX_FAILED_TIMES } from "constants.js";
import MainContainer from "pages/_components/MainContainer";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as configSelectors } from "reducers/config";
import { actions as themeActions } from "reducers/theme";
import { actions as sessionActions } from "reducers/session";
import Routes from "routes/Routes";
import * as configUtils from "util/config";
import { resizableRoute } from "pages/_components/Resizable";
import { isMobileNativeFunc } from "util/device";
import TagManager from "react-gtm-module";

/* eslint-disable react/jsx-filename-extension */
class App extends Component {
    static propTypes = {
        dispatch: func,
        loading: bool,
        timesConfigFailed: number,
        timesI18nFailed: number,
    };

    static defaultProps = {
        dispatch: null,
        loading: false,
        timesConfigFailed: 0,
        timesI18nFailed: 0,
    };
    // DESCOMENTAR PARA OCULTAR ERRORES TIPO WARNING DE CONSOLA
    // constructor(props) {
    //     super(props);
    //     this.suppressConsoleMessages("warn", [/Warning:/]);
    //     this.suppressConsoleMessages("error", [/Warning:/]);
    // }

    // suppressConsoleMessages(methodName, regexList) {
    //     const originalMethod = console[methodName];
    //     console[methodName] = function(message, ...args) {
    //         if (typeof message === "string" && regexList.some((regex) => regex.test(message))) {
    //             return;
    //         }
    //         originalMethod.apply(console, [message, ...args]);
    //     };
    // }

    componentDidMount() {
        document.addEventListener("display-changed", (e) => {
            if (
                (e.detail.display === "mobile" && e.detail.newDisplay === "tablet") ||
                (e.detail.display === "tablet" && e.detail.newDisplay === "mobile")
            ) {
                window.location.reload();
            }
        });
        this.loadRevelockScript();
        this.themeAssignment();
    }

    componentDidUpdate() {
        this.initGoogleTagManager();
        this.themeAssignment();
    }

    themeAssignment = () => {
        const { dispatch } = this.props;
        const urlCorporate = configUtils.get("corporate.baseUrl");
        const isCorporate = window.location.href?.includes(urlCorporate);
        // Local empresas (no borrar)
        // const isCorporate = urlCorporate?.includes("empresa");

        dispatch(sessionActions.isActiveCorporate(isCorporate));
        dispatch(themeActions.changeLoadTheme(isCorporate ? "corporate" : "retail"));
    };

    initGoogleTagManager = () => {
        const gtmId = configUtils.get("google.tag.manager.id.web");
        if (!gtmId) {
            // eslint-disable-next-line no-console
            console.log("Google tag manager id, not found");
            return;
        }

        // eslint-disable-next-line no-console
        console.log("GTM initialized successfully");

        TagManager.initialize({
            gtmId,
            events: {
                sendUserInfo: "userInfo",
            },
        });
    };

    loadRevelockScript = () => {
        if (isMobileNativeFunc()) {
            return;
        }

        const script = document.createElement("script");
        script.id = "revelock-post-login";
        script.src = "scripts/revelock/loader_postlogin.js";
        script.async = true;
        document.body.appendChild(script);

        // eslint-disable-next-line no-console
        script.addEventListener("load", () => console.log(`Revelock post login script loaded successfully`));
        // eslint-disable-next-line no-console
        script.addEventListener("error", (e) => console.log(`Revelock post login script could not load ${e}`));
    };

    render() {
        const { loading, timesConfigFailed, timesI18nFailed } = this.props;

        const serverError = timesConfigFailed >= MAX_FAILED_TIMES || timesI18nFailed >= MAX_FAILED_TIMES;

        if (loading && !serverError) {
            return (
                <div className="view-page">
                    <div className="view-content">
                        <MainContainer showLoader className={classNames("main-container")} />
                    </div>
                </div>
            );
        }

        return (
            <ConnectedRouter history={history}>
                <Routes />
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = ({ isLoading, ...state }) => ({
    loading: isLoading || !i18nSelectors.getMessages(state) || !configSelectors.getConfig(state),
    timesConfigFailed: configSelectors.getTimesFailed(state),
    timesI18nFailed: i18nSelectors.getTimesFailed(state),
});

export default connect(mapStateToProps)(resizableRoute(App));

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as settingsActions } from "reducers/settings";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import * as i18n from "util/i18n";
import { formatAccount } from "../hooks/KuaraHooks";

const EnrollmentKuaraFinish = (props) => {
    const { fetching, dispatch, mobilePhoneMask, account, countryNumber } = props;

    useEffect(() => {
        dispatch(settingsActions.getUserData());
        dispatch(kuaraActions.userKuaraRequest());
    }, []);

    const handleBack = () => {
        dispatch(push("/desktop"));
    };

    const redirectToKuara = () => {
        dispatch(push("/kuara"));
    };

    return (
        <PageLoading loading={fetching} classicStyle={false}>
            <Head onBack={handleBack} title="kuara.header.activation2" additionalClassName="kuara-header" />

            <MainContainer>
                <Box display="flex" column fullWidth fullHeight>
                    <Box display="flex" column alignX="between" alignY="center" className="pt-8">
                        <Image
                            src="images/rocket.svg"
                            isMobileNative
                            height="12"
                            className="mb-7"
                            color="kuara-secondary"
                        />
                        <Text
                            labelKey="kuara.finish.title"
                            bold
                            size="1"
                            align="center"
                            color="heading"
                            className="mb-3"
                        />
                        <Box display="inline-flex" alignY="center" alignX="center" fullWidth>
                            <Text size="4" color="heading-color" align="center" labelKey="kuara.finish.subtitle" />
                            <Box display="inline-flex" className="ml-2">
                                <Image src="images/logos/kuaraHorizontalSmall.svg" />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" column alignX="center" alignY="center" fullWidth className="mt-8">
                        <Box
                            background="white"
                            display="flex"
                            alignX="flex-start"
                            alignY="center"
                            fullWidth
                            className="py-6 pl-6 pr-1"
                            borderRadius="xl">
                            <Box display="flex" className="mr-3">
                                <Image src="images/check-circle.svg" color="kuara-secondary" />
                            </Box>
                            <Text size="3" bold color="heading">
                                Celular:{" "}
                            </Text>
                            <Text size="3">
                                {" "}
                                +{countryNumber}
                                {mobilePhoneMask}
                            </Text>
                        </Box>
                        <Box
                            background="white"
                            display="flex"
                            alignX="flex-start"
                            alignY="center"
                            fullWidth
                            className=" my-3 py-6 pl-6 pr-1"
                            borderRadius="xl">
                            <Box display="flex" className="mr-3">
                                <Image src="images/check-circle.svg" color="kuara-secondary" />
                            </Box>
                            <Text size="3" bold color="heading">
                                {account?.productAlias}{" "}
                            </Text>
                            <Text size="3">{formatAccount(account)}</Text>
                        </Box>
                    </Box>
                    <Box display="flex" column alignX="center" alignY="center" className="px-5 pt-7 mb-9">
                        <Text labelKey="kuara.finish.tip.title" color="heading-color" align="center" size="4" bold />
                        <span className="size-4 align-center color-heading-color">
                            {`${i18n.get("kuara.finish.tip")} `}
                            <Text labelKey="kuara.finish.tip.path" color="kuara-primary" bold size="4" />
                        </span>
                    </Box>
                    <Box display="flex" fullWidth className="mt-auto pb-10">
                        <Button
                            label="kuara.finish.button"
                            bsStyle="primary"
                            className="kuara-color"
                            block
                            onClick={() => redirectToKuara()}
                        />
                    </Box>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    mobilePhoneMask: kuaraSelectors.getMobilePhoneMaskKuara(state) || "",
    countryNumber: kuaraSelectors.getCountryNumberKuara(state) || "",
    fetching: kuaraSelectors.isFetching(state),
    account: kuaraSelectors.getAccountKuara(state),
});

EnrollmentKuaraFinish.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    mobilePhoneMask: string,
    account: shape({}).isRequired,
    countryNumber: string,
};

EnrollmentKuaraFinish.defaultProps = { fetching: false, mobilePhoneMask: "", countryNumber: "" };

export default withRouter(connect(mapStateToProps)(resizableRoute(EnrollmentKuaraFinish)));

import classNames from "classnames";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import MenuDropdownContext from "pages/_components/menu/MenuDropdownContext";
import { bool, func, number, string } from "prop-types";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import withRouter from "react-router-dom/withRouter";
import { actions as desktopActions } from "reducers/desktop";
import { selectors as i18nSelectors } from "reducers/i18n";

const MenuLink = ({
    childTitleKey,
    className,
    component,
    dispatch,
    exact,
    image,
    linkTo,
    notificationCount,
    notificationCountTextID,
    onClick,
    titleKey,
    id,
}) => {
    const { isOpen, isInsideDropdown } = useContext(MenuDropdownContext);

    return (
        <Box
            {...(id && { id })}
            component={component}
            onClick={(e) => {
                e.stopPropagation();
                if (window.innerWidth < 1232) {
                    dispatch(desktopActions.changeStatusSidebarCollapsed(true));
                }
                if (onClick) {
                    onClick();
                }
            }}
            className={classNames("nav-menu-item-wrapper", className)}>
            {linkTo ? (
                <NavLink
                    exact={exact}
                    className="nav-menu-item"
                    to={linkTo}
                    activeClassName="is-active"
                    {...(isInsideDropdown && !isOpen && { tabIndex: "-1" })}>
                    {image && <Image wrapperClassName="nav-menu-item-img" src={image} width="6" />}
                    <Text align="left" className="nav-menu-item-text" labelKey={titleKey}>
                        {childTitleKey}
                    </Text>
                    {notificationCount > 0 && (
                        <>
                            <Badge count={notificationCount} />
                            <Text labelKey={notificationCountTextID} className="visually-hidden" />
                        </>
                    )}
                </NavLink>
            ) : (
                <Box role="button" tabIndex="0" className="nav-menu-item" activeClassName="is-active">
                    {image && <Image wrapperClassName="nav-menu-item-img" src={image} width="6" />}
                    <Text align="left" className="nav-menu-item-text" labelKey={titleKey}>
                        {childTitleKey}
                    </Text>
                    {notificationCount > 0 && (
                        <>
                            <Badge count={notificationCount} />
                            <Text labelKey={notificationCountTextID} className="visually-hidden" />
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

MenuLink.propTypes = {
    childTitleKey: string,
    className: string,
    component: string,
    dispatch: func.isRequired,
    exact: bool,
    image: string,
    linkTo: string,
    notificationCount: number,
    notificationCountTextID: string,
    onClick: func,
    titleKey: string,
    id: string,
};

MenuLink.defaultProps = {
    childTitleKey: null,
    className: null,
    component: "li",
    exact: false,
    image: null,
    linkTo: null,
    notificationCount: 0,
    notificationCountTextID: "",
    onClick: null,
    titleKey: null,
    id: null,
};

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default withRouter(connect(mapStateToProps)(MenuLink));

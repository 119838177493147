import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";

import { bool, func } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import Button from "pages/_components/Button";
import { routerActions } from "react-router-redux/actions";

const KuaraModal = ({ onClose, show, dispatch }) => {
    const redirectToKuara = () => {
        onClose();
        dispatch(routerActions.push("/sendKuara"));
    };

    return (
        <div className="modal-container">
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={() => {
                    onClose();
                }}
                show={show}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Box display="flex" column fullWidth>
                        <Box display="flex" alignX="center" alignY="center" gap="3" className="pt-8" fullWidth>
                            <Box display="flex">
                                <Image src="images/kuaraModal.svg" />
                            </Box>
                        </Box>
                        <Box display="flex" fullWidth flex1>
                            <Box display="flex" column gap="7" className="pb-8 px-7" fullWidth>
                                <Text component="p" size="6" align="center" labelKey="kuara.modal.description.1" />
                                <Image src="images/kuaraDescription.svg" />

                                <Box display="flex" scrollable>
                                    <Button
                                        label="global.continue"
                                        bsStyle="primary"
                                        className="kuara-color mt-4"
                                        block
                                        onClick={redirectToKuara}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal.Body>
            </Modal>
        </div>
    );
};

KuaraModal.propTypes = {
    onClose: func,
    show: bool,
    dispatch: func,
};

KuaraModal.defaultProps = {
    onClose: () => {},
    show: false,
    dispatch: () => {},
};

export default resizableRoute(KuaraModal);

import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import SelectAccount from "pages/_components/fields/SelectAccount/SelectAccount";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import Notification from "pages/_components/Notification";
import { actions as notificationActions } from "reducers/notification";
import { get as getMessage } from "util/i18n";
import * as config from "util/config";

import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";

const EnrollmentKuaraSelectAccount = (props) => {
    const { dispatch, history, fetching, accounts } = props;

    const [fetchingSubmit, setFetchingSubmit] = useState(false);

    useEffect(() => {
        if (accounts.length === 0) {
            dispatch(push("/kuara"));
            dispatch(
                notificationActions.showNotification(
                    <div>
                        {getMessage("kuara.validate.NoAccount")}
                        <Button
                            label="kuara.validate.NoAccount.link"
                            externalHref={config.get("accessBar.requests.url")}
                            bsStyle="link"
                            className="p-0 btn-link"
                        />
                        .
                    </div>,
                    "error",
                    ["kuara"],
                    true,
                ),
            );
        }
    }, []);

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const onFinish = () => {
        setFetchingSubmit(false);
    };

    const accountStatus = {
        Actived: "ACTIVA",
        Inactived: "INACTIVA",
        NoActived: "NOACTIVA",
        NoAccount: "NOACCOUNT",
        IsMancomunate: "MANCOMUNADA",
        IsPhoneValid: "PHONE",
    };

    const showInvalidSubProductStatus = () => {
        dispatch(
            notificationActions.showNotification(
                <div>
                    {getMessage("kuara.validate.invalid.status")}
                    <Button
                        label="kuara.validate.invalid.status.link"
                        externalHref={config.get("accessBar.requests.url")}
                        bsStyle="link"
                        className="p-0 btn-link"
                    />
                    .
                </div>,
                "error",
                ["enrollmentKuaraSelectAccount"],
                true,
            ),
        );
    };

    const isValidAccounts = (account) => {
        /**
         * validate status sub product account
         */
        const invalidAccountStatus = config.getArray("kuara.account.subproduct.status.invalid", []);
        if (!account?.subProduct || invalidAccountStatus.some((item) => item === account.subProduct)) {
            showInvalidSubProductStatus();
            return false;
        }

        let valid = true;
        let validateAccount = null;
        const accountsValidate = [account];

        validateAccount = accountsValidate.some((accountItem) => accountItem.status === accountStatus.Inactived)
            ? accountStatus.Inactived
            : validateAccount;

        validateAccount = accountsValidate.some((accountItem) => accountItem.status === accountStatus.NoActived)
            ? accountStatus.NoActived
            : validateAccount;

        if (validateAccount) {
            valid = false;
        }
        switch (validateAccount) {
            case accountStatus.NoAccount:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {getMessage("kuara.validate.NoAccount")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                            .
                        </div>,
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
                break;
            case accountStatus.Inactived:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: getMessage("kuara.validate.Inactived") }} />
                            <Button
                                label="kuara.validate.Inactived.link"
                                externalHref={config.get("accessBar.chat.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
                break;
            case accountStatus.NoActived:
                dispatch(
                    notificationActions.showNotification(
                        getMessage("kuara.validate.NoActived"),
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
                break;
            case accountStatus.IsMancomunate:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {getMessage("kuara.validate.IsMancomunate")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
                break;

            case accountStatus.IsPhoneValid:
                dispatch(
                    notificationActions.showNotification(
                        getMessage("kuara.validate.IsPhoneValid"),
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
                break;

            default:
                break;
        }
        return valid;
    };

    const handleSubmit = (values) => {
        const accountSelected = accounts?.find((item) => item.idProduct === values.account);
        if (isValidAccounts(accountSelected) && accountSelected && !fetchingSubmit) {
            setFetchingSubmit(true);
            dispatch(kuaraActions.activateRequest(accountSelected, onFinish));
        }
    };

    return (
        <PageLoading loading={fetching} classicStyle={false}>
            <Head onBack={handleBack} title="kuara.header.activation2" additionalClassName="kuara-header" />
            <Notification scopeToShow="enrollmentKuaraSelectAccount" />
            <MainContainer>
                <Box display="flex" column className="pt-8">
                    <Box display="flex" alignX="center" fullWidth className="mb-7">
                        <Image src="images/phone-cash.svg" color="kuara-secondary" />
                    </Box>
                    <Text
                        labelKey="kuara.select.account.title"
                        bold
                        size="1"
                        align="center"
                        color="heading"
                        className="mb-3"
                    />
                    <Box display="flex" className="px-7 mb-5">
                        <Text size="4" color="heading-color" align="center">
                            {getMessage("kuara.select.account.subtitle")}
                            <Box display="inline-flex" className="ml-2" position="relative">
                                <Box position="absolute" className="min-width-5rem" top="n-4">
                                    <Image
                                        src="images/logos/kuaraHorizontalSmall.svg"
                                        fullWidth={false}
                                        isMobileNative
                                        height="5"
                                    />
                                </Box>
                            </Box>
                        </Text>
                    </Box>
                </Box>
                <Formik onSubmit={handleSubmit} initialValues={{ account: "" }} enableReinitialize>
                    {(form) => (
                        <Form className="full-height">
                            <Box display="flex" column fullHeight>
                                <Field
                                    name="account"
                                    component={SelectAccount}
                                    options={accounts}
                                    kuaraSecondaryColor
                                />
                                <Box display="flex" column fullWidth className="mt-auto">
                                    <Button
                                        label="kuara.select.button.confirm"
                                        bsStyle="primary"
                                        className="kuara-color"
                                        block
                                        disabled={fetchingSubmit || !form?.values?.account}
                                        loading={fetchingSubmit}
                                        type="submit"
                                    />
                                    <Button
                                        label="kuara.select.button.cancel"
                                        bsStyle="outline"
                                        disabled={fetchingSubmit}
                                        block
                                        onClick={handleCancel}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accounts: kuaraSelectors.getAccountListKuara(state),
    fetching: kuaraSelectors.isFetching(state),
});

EnrollmentKuaraSelectAccount.propTypes = {
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    fetching: bool,
    accounts: arrayOf(shape({ length: number })),
};

EnrollmentKuaraSelectAccount.defaultProps = { fetching: false, accounts: [] };

export default withRouter(connect(mapStateToProps)(resizableRoute(EnrollmentKuaraSelectAccount)));

import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "administration.signatureScheme.ticket";

class AdministrationChannelsTicket extends Component {
    static propTypes = {
        actions: shape({
            loadSignatureTicketRequest: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
        hasSignatureEnabled: bool.isRequired,
        signatureLevel: string,
        user: shape({
            firstName: string.isRequired,
            lastName: string.isRequired,
        }).isRequired,
        schemeName: string.isRequired,
        setLocationCustomPath: func.isRequired,
    };

    static defaultProps = { signatureLevel: null };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadSignatureTicketRequest(match.params.idTransaction);
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    renderMediumTicket = () => {
        const { hasSignatureEnabled } = this.props;

        return (
            <Text
                component="h3"
                labelKey={`administration.signature.ticket.change.${!hasSignatureEnabled}`}
                className="mx-0 mt-7 mb-3"
                size="4"
                color="primary"
                bold
            />
        );
    };

    renderAdvancedTicket = () => {
        const { signatureLevel } = this.props;

        if (signatureLevel) {
            return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
        }

        return <I18n id="administration.signature.ticket.change.true" />;
    };

    render() {
        const { user, schemeName } = this.props;

        return (
            <Box display="flex" column>
                <Box display="flex" className="mb-4">
                    <Text component="label" labelKey="administration.signature.user" addColon className="m-0" />
                    <Text>{`${user.firstName} ${user.lastName}`}</Text>
                </Box>
                <Box className="list-wrapper">
                    {schemeName === "medium" ? this.renderMediumTicket() : this.renderAdvancedTicket()}
                </Box>
            </Box>
        );
    }
}

export default withRevelockPositionContext(AdministrationChannelsTicket);

import { ID_ACTIVITY_DOWNLOAD_STATE, ID_ACTIVITY_DOWNLOAD_STATE_PRE } from "constants.js";
import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";

export const changeProductAlias = (alias, idProduct) =>
    API.executeWithAccessToken("/core.product.changeAlias", {
        alias,
        idProduct,
    });

export const toggleFavorite = (idProduct, productType, favorite) =>
    API.executeWithAccessToken("/products.toggleFavorite", {
        idProduct,
        productType,
        favorite,
    });
export const getMetadataDownloadState = () => API.executeWithAccessToken(ID_ACTIVITY_DOWNLOAD_STATE_PRE, {});

export const downloadStateProduct = (month, year, productId) =>
    API.executeWithAccessToken(ID_ACTIVITY_DOWNLOAD_STATE, {
        month,
        year,
        productId,
    });

export const exchangePointsProductCash = (productId, points, moneyValue, totalPoints) =>
    API.executeWithAccessToken("redeem.miles.cash.send", {
        productId,
        points,
        moneyValue,
        totalPoints,
    });

export const exchangePointsProductMiles = (productId, points, miles, travelNumber) =>
    API.executeWithAccessToken("redeem.miles.connect.send", {
        productId,
        points,
        miles,
        travelNumber,
    });

export const changeProductStatus = (idActivity, data, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    return API.executeWithAccessToken(`/${idActivity}`, {
        ...data,
        ...credentialsWithUnderscore,
    });
};

export const SyncEnvironmentProducts = (shouldGetLastSynchronization = false) =>
    API.executeWithAccessToken("session.login.legacy.synchronization", {
        shouldGetLastSynchronization,
    });

export const sendRequestProduct = (product, productType, contactType, message, checkingAccount, savingAccount) =>
    API.executeWithAccessToken("/productRequest.send", {
        product,
        productType,
        contactType,
        message,
        checkingAccount,
        savingAccount,
    });

export const preLoadRedeemData = (productId) =>
    API.executeWithAccessToken("redeem.miles.pre", {
        productId,
    });

export const exchangePointsValidTravelNumber = () => API.executeWithAccessToken("redeem.miles.validate.travel.number");

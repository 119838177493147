import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelectors,
} from "reducers/authenticateHandler";
import { bool, func } from "prop-types";
import TokenButtonWrapper from "./TokenButtonWrapper";

// Contenedor de boton principal de token
const TokenButtonWrapperContainer = ({ isStep0, dispatch }) => {
    const [entrustKey, setEntrustKey] = useState("");
    const [isShowDetails, setIsShowDetails] = useState(false);

    const getActualKeys = (keys, showDetails) => {
        setEntrustKey(keys);
        setIsShowDetails(showDetails);
    };

    useEffect(() => {
        dispatch(authenticateHandlerActions.getTokenListRequest(getActualKeys));
    }, []);
    return <TokenButtonWrapper isStep0={isStep0} entrustKey={entrustKey} isShowDetails={isShowDetails} />;
};

const mapStateToProps = (state) => ({
    fetching: authenticateHandlerSelectors.isFetching(state),
});

TokenButtonWrapperContainer.propTypes = {
    dispatch: func.isRequired,
    isStep0: bool,
};
TokenButtonWrapperContainer.defaultProps = {
    isStep0: false,
};

export default connect(mapStateToProps)(TokenButtonWrapperContainer);

import * as i18n from "util/i18n";

export const unifyListParams = (response) => {
    const { data } = response;
    if (data && data.body) {
        return {
            moreStatements: !(data.body.last || data.body.numberOfElements < data.body.size),
            pageNumber: data.body.pageable.pageNumber + 1,
            totalCount: data.body.totalElements,
            statements: data.body.content,
        };
    }
    return data.data;
};

export const calculateShortLabel = (productType, number, otherLabel) => {
    let typeI18n = "";
    if (productType === "CA" || productType === "CC") {
        typeI18n = i18n.get(`productType.shortLabel.${productType}`);
        return `${typeI18n} *${number.substring(number.length - 4)}`;
    }
    if (productType === "TC") {
        return `${otherLabel || ""} **** ${number.substring(number.length - 4)}`;
    }
    if (productType === "PA") {
        typeI18n = i18n.get(`productType.shortLabel.${productType}`);
    }
    if (productType === "PF") {
        typeI18n = i18n.get(`productType.shortLabel.${productType}`);
    }
    return `${productType} ${number}`;
};

export const SWIFT_RECEIVED_ACCOUNT_MOVEMENT_CODE = "220";

export default unifyListParams;

export const LOAN_OVERDUE_STATUS_NAB = "NAB";

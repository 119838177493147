/* eslint-disable */
import { PANAMA_COUNTRY_NAME, REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import SwitchToggle from "pages/_components/SwitchToggle";
import Text from "pages/_components/Text";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { compose } from "redux";
import { requestWritePermission, saveContact } from "util/contact.util";
import * as i18n from "util/i18n";
import * as config from "util/config";
import * as Yup from "yup";
import { formatAccount } from "./hooks/KuaraHooks";

const SendKuaraAddContact = (props) => {
    const { dispatch, history, fetching, accountKuara, activeRegion, beneficiariesPeople } = props;
    const isRequestAddContact =
        location.hash === "#/sendKuaraRequestAddContact" || location.pathname === "/sendKuaraRequestAddContact";
    const [isActived, setIsActived] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [responseWriteRequest, setResponseWriteRequest] = useState(false);
    const [listPeople, setListPeople] = useState([]);

    let country = REGION_USA;
    if (activeRegion !== REGION_REST_OF_LATAM) {
        country = activeRegion.toLowerCase();
    }
 
    useEffect(() => {
        requestSavePhone();
        setListPeople(beneficiariesPeople);
    }, []);

   const onFinish = (contactPhone,contactName) => {
       if (isActived && responseWriteRequest) {
           const isSaved = listPeople.some((el) => {
               const contactPhoneClear = contactPhone.replace("-", "");
               return el.phoneNumber === contactPhoneClear;
           });
           if (!isSaved) {
               handleSavePhone({
                   firstName: contactName,
                   familyName: "",
                   organizationName: "",
                   phoneNumbers: [
                       {
                           type: "mobile",
                           value: contactPhone,
                       },
                   ],
               });
           }
       }
       dispatch(kuaraActions.setSelectedBeneficiary({ contactPhone, contactName }));
       dispatch(push(isRequestAddContact ? "/sendKuaraRequestTransaction" : "/sendKuaraTransaction"));

       setIsSearch(false);
   };

    const requestSavePhone = async () => {
        const responseWriteRequest = await requestWritePermission();
        setResponseWriteRequest(responseWriteRequest);
    };

    const handleSavePhone = async (contact) => {
        saveContact(contact);
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleSubmit = ({ cellPhone, name }) => {
        setIsSearch(true);
        const phone = cellPhone.prefix + cellPhone.areaCode + cellPhone.value;
        let newName = name !== "" ? name : phone;
        setContactName(newName);
        setContactPhone(phone);
        dispatch(kuaraActions.validateNumbersRequest([phone],()=>onFinish(phone,newName)));
    };

    const validationSchema = () =>
        Yup.lazy(() =>
            Yup.object().shape({
                cellPhone: Yup.object()
                    .shape({
                        value: Yup.string().required(i18n.get("kuara.cellPhone.error.empty")),
                    })
                    .test("is-pa", i18n.get("kuara.transfers.invalid.number"), (dataNumber) => {
                        const prefixComplete = dataNumber?.prefix;
                        const prefix = prefixComplete ? prefixComplete.split("-")[0] : "";
                        const value = dataNumber?.value;
                        const countriesValidations = config.getArray("kuara.countries.numbers.validation");
                        if (prefix && prefix !== "") {
                            const countriesValidate = countriesValidations.filter((country) =>
                                country.includes(prefix + ":"),
                            );
                            if (countriesValidate.length === 0) {
                                return true;
                            }
                            const country = countriesValidate[0];
                            const countryNumberLength = country.split(":")[1];
                            return parseInt(countryNumberLength) === value.length;
                        }
                        return true;
                    }),
            }),
        );

    return (
        <PageLoading loading={fetching}>
            <Head
                title={isRequestAddContact ? "kuara.forms.requestTransfers.title" : "forms.transfers.kuara.title"}
                subtitlePrefix={accountKuara?.productAlias}
                subtitle={formatAccount(accountKuara)}
                onBack={handleBack}
                additionalClassName="kuara-header"
            />
            <Notification scopeToShow="sendKuaraAddContact" />
            <MainContainer>
                <Box display="flex" column fullWidth fullHeight className="pt-7">
                    <Box display="flex" column alignX="center" alignY="center" className="pb-6">
                        <Text bold size="2" align="center" color="heading">
                            {i18n.get("kuara.add.contact")}
                        </Text>
                    </Box>
                    <Formik
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={validationSchema}
                        initialValues={{ cellPhone: { prefix: "", areaCode: "", value: "" }, name: "" }}
                        onSubmit={handleSubmit}>
                        <Form className="full-height">
                            <Box display="flex" column fullWidth fullHeight>
                                <Box background="white" className="pt-1 pb-9 px-5 mx-n-5 mb-3" borderRadius="default">
                                    <Field
                                        idForm="wally"
                                        name="cellPhone"
                                        component={PhoneInput}
                                        country={country}
                                        placeholder=""
                                        onlyCountries={[PANAMA_COUNTRY_NAME]}
                                    />
                                </Box>
                                <Box
                                    background="white"
                                    display="flex"
                                    column
                                    className="px-5 mb-9 mx-n-5"
                                    borderRadius="default">
                                    <Box className="pb-7">
                                        <Field
                                            name="name"
                                            component={TextField}
                                            optional={i18n.get("kuara.name.optional")}
                                            placeholder=""
                                            labelText={i18n.get("kuara.name.label")}
                                        />
                                    </Box>
                                    <Box fullWidth className="pb-7">
                                        <SwitchToggle
                                            switchIsOn={isActived}
                                            handleToggle={() => setIsActived(!isActived)}
                                            labelOnKey="kuara.switch.contact.label"
                                            labelOffKey="kuara.switch.contact.label"
                                            switchColor="kuara-secondary"
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" column fullWidth className="mt-auto">
                                    <Button bsStyle="primary" className="kuara-color" block type="submit">
                                        {i18n.get("kuara.continue.button")}
                                    </Button>
                                    <Button
                                        label="kuara.select.button.cancel"
                                        onClick={handleCancel}
                                        bsStyle="outline"
                                        block
                                    />
                                </Box>
                            </Box>
                        </Form>
                    </Formik>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accountKuara: kuaraSelectors.getAccountKuara(state),
    activeRegion: loginSelectors.getRegion(state),
    beneficiariesPeople: kuaraSelectors.getBeneficiariesPeople(state),
    fetching: kuaraSelectors.isFetching(state),
});

SendKuaraAddContact.propTypes = {
    dispatch: func.isRequired,
    accountKuara: shape({}),
    beneficiariesPeople: arrayOf(shape({})),
    history: shape({}).isRequired,
    fetching: bool,
    activeRegion: string,
};

SendKuaraAddContact.defaultProps = {
    beneficiariesPeople: [],
    fetching: false,
    accountKuara: {},
    activeRegion: "",
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SendKuaraAddContact));

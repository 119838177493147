import classNames from "classnames";
import { bool, func, node, number, string } from "prop-types";
import React, { useEffect, useRef } from "react";
import Box from "pages/_components/Box";

const GridTableContainer = ({
    accordionChildren,
    background,
    border,
    borderRadius,
    children,
    className,
    columns,
    columnsTemplate,
    isAccordionOpen,
    isInHeader,
    isLastElement,
    margin,
    onClick,
    padding,
    removeBottomSpacing,
    rows,
    rowsTemplate,
    ...props
}) => {
    const ref = useRef(null);

    const inlineStyle = {
        gridTemplateColumns: columnsTemplate || `repeat(${columns}, minmax(auto, 1fr))`,
        gridTemplateRows: rowsTemplate || `repeat(${rows}, minmax(auto, 1fr))`,
    };

    useEffect(() => {
        let isFocused = false;

        const handleKeyDown = (e) => {
            if (!isFocused || !ref.current) {
                return;
            }

            let element;
            switch (e.key) {
                case "ArrowUp":
                    element = ref.current.previousSibling;
                    break;
                case "ArrowDown":
                    element = ref.current.nextSibling;
                    break;
                case "ArrowRight":
                    element = ref.current;
                    if (element) {
                        const childrenArray = Array.from(element.children);
                        element = childrenArray.find((child) => child.tabIndex !== "-1");
                    }
                    break;
                default:
                    return;
            }

            if (element && element.tabIndex !== -1) {
                element.focus();
            }
        };

        const handleFocus = () => {
            isFocused = true;
            ref.current.addEventListener("keydown", handleKeyDown);
        };

        const handleBlur = () => {
            isFocused = false;
            ref.current.removeEventListener("keydown", handleKeyDown);
        };

        if (ref.current) {
            ref.current.addEventListener("focus", handleFocus);
            ref.current.addEventListener("blur", handleBlur);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("focus", handleFocus);
                ref.current.removeEventListener("blur", handleBlur);
                ref.current.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [ref]);

    return (
        <>
            <tr
                ref={ref}
                style={inlineStyle}
                border={border}
                className={classNames("grid-table-row", className, padding, margin, {
                    [`background-${background}`]: background && !isInHeader,
                    [`border-radius-${borderRadius}`]: borderRadius,
                    [`border-${border}`]: border,
                    "grid-table-row-clickable": onClick,
                })}
                {...props}
                {...(onClick && { onClick, tabIndex: "0" })}>
                {children}
            </tr>
            {accordionChildren && (
                <Box className="menu-dropdown">
                    <Box className={classNames("menu-dropdown-content", { "is-opened": isAccordionOpen })}>
                        <Box
                            component="ul"
                            display="flex"
                            column
                            background="component-background"
                            fullWidth
                            border={border}
                            className="menu-dropdown-ul">
                            {accordionChildren}
                        </Box>
                    </Box>
                </Box>
            )}
            {isLastElement && !removeBottomSpacing && (
                <tr className="pb-9 pb-md-10 background-component-background border-radius-bottom-default" />
            )}
        </>
    );
};

GridTableContainer.propTypes = {
    accordionChildren: node,
    background: string,
    border: string,
    borderRadius: string,
    children: node.isRequired,
    className: string,
    columns: number,
    columnsTemplate: string,
    isAccordionOpen: bool,
    isInHeader: bool,
    isLastElement: bool,
    margin: string,
    onClick: func,
    padding: string,
    removeBottomSpacing: bool,
    rows: number,
    rowsTemplate: string,
};

GridTableContainer.defaultProps = {
    accordionChildren: null,
    background: "component-background",
    border: "bottom-background-border-disabled",
    borderRadius: "0",
    className: null,
    columns: 1,
    columnsTemplate: null,
    isAccordionOpen: false,
    isInHeader: false,
    isLastElement: false,
    margin: "mb-0",
    onClick: null,
    padding: "py-2 px-5",
    removeBottomSpacing: false,
    rows: 1,
    rowsTemplate: null,
};

export default GridTableContainer;

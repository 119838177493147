import * as API from "middleware/api";

export const loadListRequest = ({ pageNumber = 1, orderBy = "last_login DESC" } = {}) =>
    API.executeWithAccessToken("/administration.users.list", {
        pageNumber,
        orderBy,
    });

export const exportListRequest = ({ format = "xls", orderBy = "last_login DESC" } = {}) =>
    API.executeWithAccessToken("/administration.users.export", {
        format,
        orderBy,
    });

export const changeUsersStatusPreview = (params) =>
    API.executeWithAccessToken("/administration.users.blockunblock.preview", params);

export const changeUsersStatusConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.users.blockunblock.send", {
        ...params,
        ...credentials,
    });

export const userPermissionActivatorConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.users.activate.permission.send", { ...params, ...credentials });

export const userPermissionDeactivatorConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.users.deactivate.permission.send", { ...params, ...credentials });

export const deleteUsersPreview = (params) =>
    API.executeWithAccessToken("/administration.users.delete.preview", params);

export const deleteUsersConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.users.delete.send", {
        ...params,
        ...credentials,
    });

export const updateDispacther = (params) => API.executeWithAccessToken("/administration.users.update", params);

export const getUserToken = (idUserToken) =>
    API.executeWithAccessToken("/administration.users.get.token", { idUserToken });

export const activateUserToken = (idUser, fullName, credentials) =>
    API.executeWithAccessToken("/token.entrust.activeEnvironmentsToken", {
        idUserToken: idUser,
        fullName,
        ...credentials,
    });

export const rejectUserToken = (idUser, fullName) =>
    API.executeWithAccessToken("/token.entrust.rejectUserToken", { idUserToken: idUser, fullName });

import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    MODAL_SHOW: "productRequest/MODAL_SHOW",
    MODAL_CLOSE: "productRequest/MODAL_CLOSE",
    SEND_REQUEST_PRODUCT: "productRequest/SEND_REQUEST_PRODUCT",
    SEND_REQUEST_PRODUCT_SUCCESS: "productRequest/SEND_REQUEST_PRODUCT_SUCCESS",
};

export const INITIAL_STATE = {
    showModal: false,
    isFetching: false,
    productDefault: null,
    product: "",
    productType: "",
    contactType: "",
    pathname: "",
};

export default createReducer(INITIAL_STATE, {
    [types.MODAL_SHOW]: (state, action) => ({ ...state, showModal: true, productDefault: action.productDefault }),
    [types.MODAL_CLOSE]: () => INITIAL_STATE,
    [types.SEND_REQUEST_PRODUCT]: (state, action) => ({
        ...state,
        isFetching: true,
        product: action.product,
        productType: action.productType,
        contactType: action.contactType,
        pathname: action.pathname,
    }),
    [types.SEND_REQUEST_PRODUCT_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
        showModal: false,
    }),
});

export const actions = {
    modalShow: makeActionCreator(types.MODAL_SHOW, "productDefault"),
    modalHide: makeActionCreator(types.MODAL_CLOSE),
    sendRequestProduct: makeActionCreator(
        types.SEND_REQUEST_PRODUCT,
        "product",
        "productType",
        "contactType",
        "message",
        "checkingAccount",
        "savingAccount",
        "pathname",
    ),
};

export const selectors = {
    getShowModal: ({ productRequest }) => productRequest.showModal,
    isFetching: ({ productRequest }) => productRequest.isFetching,
    getProductDefault: ({ productRequest }) => productRequest.productDefault,
};

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { selectors as selectorsEnrollment } from "reducers/enrollment";
import { selectors as sessionSelectors } from "reducers/session";

class TokenActivationPending extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: string.isRequired,
        isSecondary: bool.isRequired,
    };

    close = () => {
        const { dispatch, hasActiveSession } = this.props;
        dispatch(replace(hasActiveSession ? "/settings/authenticatorHandler" : "/"));
    };

    render() {
        const { isSecondary } = this.props;
        return (
            <>
                <Head title="token.activation.title" hideNavbarInMobile additionalClassName="background-primary" />
                <MainContainer classNameMainWrapper="background-menu-background" showLoader={false}>
                    <Box
                        display="flex"
                        column
                        alignY="center"
                        background="white"
                        borderRadius="md"
                        className="mt-8 pb-7"
                        fullWidth>
                        <Text
                            component="h2"
                            size="1"
                            bold
                            align="center"
                            color="heading"
                            className="mx-5 my-8"
                            labelKey="token.activation.pending.subtitle"
                        />
                        <Image className="mb-9" src="images/icons/clockShield.svg" />
                        <Text
                            component="p"
                            align="center"
                            size="6"
                            color="heading"
                            className="mx-5"
                            labelKey="token.activation.pending.text1"
                        />
                        {!isSecondary && (
                            <Text
                                component="p"
                                align="center"
                                size="6"
                                color="heading"
                                className="mx-5 mb-12"
                                labelKey="token.activation.pending.text2"
                            />
                        )}
                    </Box>
                    <Box display="flex" column className="mt-auto" fullWidth>
                        <Button
                            label="token.activation.pending.button.login"
                            bsStyle="primary"
                            onClick={this.close}
                            block
                        />
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activateSoftToken: selectorsEnrollment.activateSoftToken(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(TokenActivationPending);

import classNames from "classnames";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { number, string } from "prop-types";
import React from "react";

const UsedBalance = ({ className, usedPercent }) => (
    <Box display="flex" alignY="center" className="mx-3 mx-lg-5">
        <Box position="relative" className={classNames("used-balance", className)}>
            <Text size="7" color="heading" bold className="percent-text">
                {Math.round(usedPercent)}%
            </Text>
            <div className="progress-circle" style={{ "--percent": `${(usedPercent * 360) / 100}deg` }} />
        </Box>

        <Text className="ml-2" color="heading" size="small" labelKey="global.used" />
    </Box>
);

UsedBalance.propTypes = {
    className: string,
    usedPercent: number,
};

UsedBalance.defaultProps = {
    className: "",
    usedPercent: null,
};

export default UsedBalance;

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import { bool, func, number, shape } from "prop-types";
import React, { Component } from "react";

class Trigger extends Component {
    static propTypes = {
        disabled: bool,
        editableMode: bool,
        enableQantity: number,
        field: shape({}),
        form: shape({
            errors: shape({}),
        }),
        isAccordionOpen: bool,
        onClick: func,
        totalQuantity: number,
        values: shape({}),
    };

    static defaultProps = {
        disabled: false,
        editableMode: false,
        enableQantity: 0,
        field: null,
        form: null,
        isAccordionOpen: false,
        onClick: null,
        totalQuantity: 0,
        values: {},
    };

    render() {
        const {
            disabled,
            editableMode,
            enableQantity,
            field,
            form,
            isAccordionOpen,
            onClick,
            totalQuantity,
        } = this.props;

        const { errors } = form;

        const hasError = editableMode ? errors[field.name] && editableMode : errors[field.name];

        return (
            <Box display="flex" column>
                <Box display="flex" {...(hasError && { className: "has-error-box" })}>
                    <Button
                        bsStyle="link"
                        onClick={onClick}
                        image="images/icons/chevronDown.svg"
                        {...(isAccordionOpen && {
                            imageClassName: "svg-wrapper rotate-180",
                        })}
                        imageColor="primary-color"
                        imageXs
                        disabled={disabled}>
                        <Text color="text-disabled-color" size="7" regular>
                            ({enableQantity}/{totalQuantity})
                        </Text>
                    </Button>
                </Box>

                {hasError && (
                    <Box position="relative" className="max-width-5rem">
                        <FieldError error={errors[field.name]} />
                    </Box>
                )}
            </Box>
        );
    }
}

export default Trigger;

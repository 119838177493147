import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { func, bool } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";

class TokenActivationFailed extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        showDocumentButton: bool.isRequired,
        isLoggedIn: bool.isRequired,
    };

    continueValidation = () => {
        const { dispatch, isLoggedIn } = this.props;
        dispatch(push(isLoggedIn ? "/auth/tokenActivationStep2" : "/tokenActivationStep2"));
    };

    handleOnBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    tryAgain = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    render() {
        const { showDocumentButton } = this.props;
        return (
            <>
                <Head
                    title="token.activation.title"
                    onBack={this.handleOnBack}
                    additionalClassName="background-primary"
                />
                <MainContainer classNameMainWrapper="background-menu-background" showLoader={false}>
                    <Box
                        display="flex"
                        column
                        alignY="center"
                        background="white"
                        borderRadius="md"
                        className="mt-8"
                        fullWidth>
                        <Text
                            component="p"
                            size="1"
                            bold
                            align="center"
                            color="heading"
                            className="mx-5 mt-8 mb-0"
                            labelKey="token.activation.failed.text1"
                        />
                        <Text
                            component="p"
                            size="1"
                            bold
                            align="center"
                            color="heading"
                            className="mx-5 mb-8 mt-0"
                            labelKey="token.activation.failed.text2"
                        />
                        <Image className="mb-9" src="images/icons/userToken.svg" />
                        <Text
                            component="p"
                            align="center"
                            size="6"
                            color="heading"
                            className="mx-9 mb-12"
                            labelKey="token.activation.failed.text3"
                        />
                    </Box>
                    <Box display="flex" column className="mt-auto" fullWidth>
                        {showDocumentButton === "1" && (
                            <Button
                                label="token.activation.failed.button.continue"
                                bsStyle="primary"
                                onClick={this.continueValidation}
                                block
                            />
                        )}
                        <Button
                            label="token.activation.failed.button.tryAgain"
                            type="button"
                            className="btn-outline"
                            onClick={this.tryAgain}
                            disabled={false}
                            block
                        />
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(TokenActivationFailed);

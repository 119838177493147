import Box from "pages/_components/Box";
import InfoCard from "pages/_components/InfoCard";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import Row from "pages/_components/Row";
import { bool, shape, string } from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import { format } from "date-fns";

const DetailHeadInfo = ({ isDesktop, product, dueDateStr, twoColumns }) => {
    const openDateStr = product.openDate ? format(product.openDate, "DD/MM/YYYY") : null;
    const dueDateStrDetail = product.dueDate ? format(product.dueDate, "DD/MM/YYYY") : null;

    const detailArray = [
        {
            title: "deposit.detail.fields.open.date.label",
            message: openDateStr,
        },
        {
            title: "deposit.detail.fields.due.date.label",
            message: dueDateStr || dueDateStrDetail,
        },
        {
            title: "deposit.detail.fields.rate.label",
            tooltip: "deposit.detail.fields.rate.tooltip",
            message: `${product.rate}%`,
        },
        {
            title: "deposit.detail.fields.pay.frequency.label",
            message: product.proposedTerm,
        },
    ];

    const settings = {
        dots: true,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        arrows: false,
    };

    return (
        <Box component="section" className="detail-head-info pt-0">
            <Row className="px-0">
                {isDesktop ? (
                    <Col xs={12} md={4} className="height-auto">
                        <RemarkableProductInfo
                            currency={product.currency}
                            quantity={product.balance}
                            status={product.status}
                            isDesktop={isDesktop}
                            productType={product.productType}
                            showSwitch={false}
                            tooltipText="deposit.detail.fields.open.amount.label"
                        />
                    </Col>
                ) : null}

                <Col xs={12} md={8}>
                    {isDesktop ? (
                        <Row gapX={twoColumns ? "7" : "3"} gapY="3">
                            {detailArray.map((el) => {
                                let elementRender = null;
                                if (el.title) {
                                    elementRender = (
                                        <Col
                                            xs={6}
                                            md={twoColumns ? 6 : 4}
                                            className="height-auto"
                                            key={el.quantity + el.title}>
                                            <InfoCard
                                                title={el.title}
                                                {...(el.date && { date: el.date })}
                                                {...(el.currency && { currency: el.currency, quantity: el.quantity })}
                                                {...(el.message && { message: el.message })}
                                                tooltip={el.tooltip}
                                                isDesktop={isDesktop}
                                                inlineStyle
                                            />
                                        </Col>
                                    );
                                }
                                return elementRender;
                            })}
                        </Row>
                    ) : (
                        <div className="slick-slider-wrapper">
                            <Slider {...settings}>
                                {detailArray
                                    .reduce((acc, el, i) => {
                                        const index = Math.floor(i / 4);

                                        if (!acc[index]) {
                                            acc[index] = [];
                                        }

                                        acc[index].push(el);

                                        return acc;
                                    }, [])
                                    .map((el) => (
                                        <Row className="d-grid-i" gapX="5" gapY="3" key={el[0].quantity + el[0].title}>
                                            {el.map((accountOne, idx) => {
                                                let elementRender = null;
                                                if (accountOne.title) {
                                                    elementRender = (
                                                        <Col
                                                            xs={6}
                                                            className="height-auto"
                                                            key={accountOne.quantity + accountOne.title}>
                                                            <InfoCard
                                                                title={accountOne.title}
                                                                {...(accountOne.date && { date: accountOne.date })}
                                                                {...(accountOne.currency && {
                                                                    currency: accountOne.currency,
                                                                    quantity: accountOne.quantity,
                                                                })}
                                                                {...(accountOne.message && {
                                                                    message: accountOne.message,
                                                                })}
                                                                tooltipPosition={
                                                                    idx % 2 ? "bottom-right" : "bottom-left"
                                                                }
                                                                tooltip={accountOne.tooltip}
                                                                plus={accountOne.plus}
                                                            />
                                                        </Col>
                                                    );
                                                }
                                                return elementRender;
                                            })}
                                        </Row>
                                    ))}
                            </Slider>
                        </div>
                    )}
                </Col>
            </Row>
        </Box>
    );
};

DetailHeadInfo.propTypes = {
    product: shape({}).isRequired,
    isDesktop: bool.isRequired,
    dueDateStr: string.isRequired,
    twoColumns: bool,
};

DetailHeadInfo.defaultProps = {
    twoColumns: false,
};

export default DetailHeadInfo;

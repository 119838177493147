export const USER_TOKEN_STATUS_AUTHENTICATE = "AUTHENTICATE";
export const USER_TOKEN_STATUS_ACTIVE = "ACTIVE";
export const USER_TOKEN_STATUS_MIGRATE_LOCAL = "MIGRATE_LOCAL";
export const USER_TOKEN_STATUS_MIGRATE_ENTRUST = "MIGRATE_ENTRUST";
export const USER_TOKEN_STATUS_INACTIVE = "INACTIVE";
export const USER_TOKEN_STATUS_LOCKED_ENTRUST = "LOCKED_ENTRUST";
export const USER_TOKEN_STATUS_LOCKED_LOCAL = "LOCKED_LOCAL";
export const USER_TOKEN_STATUS_PENDING = "PENDING";
export const USER_TOKEN_STATUS_PENDING_APROVE = "PENDING_APROVE";
export const USER_TOKEN_STATUS_ERROR = "ERROR";
export const USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID = "MIGRATE_DIFERENT_UUID";
export const USER_TOKEN_STATUS_MORPHOLOGY_FAILED = "MORPHOLOGY_FAILED";
export const USER_TOKEN_STATUS_MORPHOLOGY_TIMEOUT = "MORPHOLOGY_TIMEOUT";
export const USER_TOKEN_STATUS_SERVICE_ERROR = "SERVICE_ERROR";
export const USER_TOKEN_STATUS_PENDING_UNLOCK = "PENDING_UNLOCK";
export const USER_TOKEN_STATUS_MORPHOLOGY_TIMEOUT_UNLOCK = "MORPHOLOGY_TIMEOUT_UNLOCK";
export const USER_TOKEN_STATUS_MORPHOLOGY_FAILED_UNLOCK = "MORPHOLOGY_FAILED_UNLOCK";
export const USER_TOKEN_STATUS_ALTERNATE_ERROR = "ALTERNATE_ERROR";
export const USER_TOKEN_STATUS_ALTERNATE_ERROR_UNLOCK = "ALTERNATE_ERROR_UNLOCK";
export const USER_TOKEN_STATUS_STARTED = "STARTED";
export const USER_TOKEN_STATUS_UNDEFINED = "UNDEFINED";

export const DOCUMENT_TYPE_CIP_TOKEN = "CIP";
export const DOCUMENT_TYPE_PAS_TOKEN = "PAS";
export const DOCUMENT_TYPE_CED_TOKEN = "CED";
export const DOCUMENT_TYPE_MULTIPLE_TOKEN = "MULTIPLE";

export const DOCUMENT_TYPE_IMAGE_FRONT = "DOCUMENTFRONT";
export const DOCUMENT_TYPE_IMAGE_BACK = "DOCUMENTBACK";
export const DOCUMENT_TYPE_IMAGE_SELFIE = "SELFIE";

export const USER_AUTHENTICATOR_PASSWORD = "PASSWORD";
export const USER_AUTHENTICATOR_TOKENRO = "TOKENRO";

export const TOKEN_STATUS_AS_PENDING_LIST = [
    USER_TOKEN_STATUS_PENDING,
    USER_TOKEN_STATUS_PENDING_APROVE,
    USER_TOKEN_STATUS_MORPHOLOGY_FAILED,
    USER_TOKEN_STATUS_MORPHOLOGY_TIMEOUT,
    USER_TOKEN_STATUS_PENDING_UNLOCK,
    USER_TOKEN_STATUS_MORPHOLOGY_FAILED_UNLOCK,
    USER_TOKEN_STATUS_MORPHOLOGY_TIMEOUT_UNLOCK,
    USER_TOKEN_STATUS_ALTERNATE_ERROR,
    USER_TOKEN_STATUS_ALTERNATE_ERROR_UNLOCK,
];
export const TOKEN_STATUS_AS_UNAVAILABLE = [USER_TOKEN_STATUS_ERROR, USER_TOKEN_STATUS_SERVICE_ERROR];
export const TOKEN_STATUS_AS_ACTIVE_LIST = [USER_TOKEN_STATUS_ACTIVE];
export const SELFIE_ERROR_CODE_S001 = "S001";
export const SELFIE_ERROR_CODE_S002 = "S002";
export const SELFIE_ERROR_CODE_S003 = "S003";
export const SELFIE_ERROR_CODE_UNDEFINED = "UNDEFINED";
export const TOKEN_STATUS_AS_LOCKED = [USER_TOKEN_STATUS_LOCKED_ENTRUST, USER_TOKEN_STATUS_LOCKED_LOCAL];

export const CONFIG_SHOW_TOKEN_AUTHENTICATE_WEB = "entrust.token.disclaimer.show.activate";
export const CONFIG_SHOW_TOKEN_AUTHENTICATE_MOBILE = "entrust.token.disclaimer.show.activate.mobile";

export const CONFIG_SHOW_TOKEN_INACTIVE_WEB = "entrust.token.disclaimer.show.activate";
export const CONFIG_SHOW_TOKEN_INACTIVE_MOBILE = "entrust.token.disclaimer.show.activate.mobile";

export const CONFIG_SHOW_TOKEN_MIGRATE_ENT_WEB = "entrust.token.disclaimer.show.migrate";
export const CONFIG_SHOW_TOKEN_MIGRATE_ENT_MOBILE = "entrust.token.disclaimer.show.migrate.mobile";

export const CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_WEB = "entrust.token.disclaimer.show.migrate";
export const CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_MOBILE = "entrust.token.disclaimer.show.migrate.mobile";

export const CONFIG_SHOW_TOKEN_MIGRATE_UUID_WEB = "entrust.token.disclaimer.show.migrate";
export const CONFIG_SHOW_TOKEN_MIGRATE_UUID_MOBILE = "entrust.token.disclaimer.show.migrate.mobile";

export const CONFIG_SHOW_TOKEN_DISCLAIMER_WEB_LIST = [
    CONFIG_SHOW_TOKEN_AUTHENTICATE_WEB,
    CONFIG_SHOW_TOKEN_INACTIVE_WEB,
    CONFIG_SHOW_TOKEN_MIGRATE_ENT_WEB,
    CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_WEB,
    CONFIG_SHOW_TOKEN_MIGRATE_UUID_WEB,
];

export const CONFIG_SHOW_TOKEN_DISCLAIMER_MOBILE_LIST = [
    CONFIG_SHOW_TOKEN_AUTHENTICATE_MOBILE,
    CONFIG_SHOW_TOKEN_INACTIVE_MOBILE,
    CONFIG_SHOW_TOKEN_MIGRATE_ENT_MOBILE,
    CONFIG_SHOW_TOKEN_MIGRATE_LOCAL_MOBILE,
    CONFIG_SHOW_TOKEN_MIGRATE_UUID_MOBILE,
];

export const TOKEN_STATUS_INVALID_WRAPPER = [
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_INACTIVE,
];

import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import {
    PDFAmountField,
    PDFAmountFieldError,
    PDFExpirationText,
    PDFTextField,
    PDFTextFieldWallyMessage,
    PDFaddresseeField,
} from "pages/forms/customForms/PDFTicketWally";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import * as schedulerUtils from "util/scheduler";
import { formatAccount, getDefaultValueDate, useMetaData } from "./hooks/WallyHooks";

const maxLengthReason = config.getInteger("core.password.maxLength");
const expirationDays = config.getInteger("core.requestMoney.expirationDays.wally");
const { decimalSeparator, thousandSeparator } = numberFormat();

const SendWallyTransaction = (props) => {
    const {
        mode,
        location,
        dispatch,
        preDataForm,
        wally,
        beneficiaryWally,
        accountWally,
        userWally,
        transaction,
        isFetchingPeople,
        isFetchingWally,
        fetching,
    } = props;

    const isRequestTransferForm = location.pathname === "/sendWallyRequestTransaction";
    const isSendTransferForm = location.pathname === "/sendWallyTransaction";
    const isSendPendingTransferForm = location.pathname === "/transactionPendingWally";
    const isRenderTicket = location.pathname === "/transactionWally" || location.pathname.includes("/transaction/");
    let ID_FORM = isRenderTicket ? transaction?.idActivity?.replace(".send", "") : "";
    ID_FORM = !isRenderTicket && isRequestTransferForm ? "requestTransfers.wally" : ID_FORM;
    ID_FORM = !isRenderTicket && isSendTransferForm ? "transfers.wally" : ID_FORM;
    ID_FORM = !isRenderTicket && isSendPendingTransferForm ? "transfers.approve.wally" : ID_FORM;

    const ID_ACTIVITY = `${ID_FORM}.send`;
    const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
    const TITLE_FORM = isRenderTicket ? "wally.movements.title" : `forms.${ID_FORM}.title`;
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [favorite, setFavorite] = useState(beneficiaryWally?.favorite);

    useEffect(() => {
        if (mode === "edit" && !isSendPendingTransferForm && ID_FORM) {
            const requestData = isRequestTransferForm
                ? null
                : {
                      beneficiaryAcctId: beneficiaryWally?.accountId,
                  };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
        }
    }, [mode]);

    useEffect(() => {
        if (mode === "edit" && isSendPendingTransferForm) {
            dispatch(formActions.confirmMovementPendingWally(ID_ACTIVITY));
        }
    }, []);

    useEffect(() => {
        setFavorite(beneficiaryWally?.favorite);
    }, [isFetchingPeople]);

    const validateForm = (values) => {
        const errors = {};
        const { amount } = values;
        if (!amount?.quantity) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }

        if (amount?.quantity > accountWally?.balance && !isRequestTransferForm) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.insufficient`);
        }

        if (values?.creditReference && values?.creditReference.length > (maxLengthReason || 40)) {
            errors.creditReference = `forms.${ID_FORM}.message.maxCharacters`;
        }

        return errors;
    };

    const handlerCancelAction = () => {
        dispatch(push("/desktop"));
    };

    const handlerLink = () => {
        dispatch(wallyActions.setPathRedirectToWally(location.pathname));
        dispatch(push(`/formCustom/transferInternal?creditAccount=${accountWally.idProduct}&&from=wally`));
    };
    const addDayExpirations = (date) => {
        const tmpDate = date.clone();
        tmpDate.add(expirationDays || 15, "days");
        return tmpDate;
    };

    const renderTicket = (values) => {
        const showReference =
            (transaction?.idTransactionStatus === "FINISHED" || transaction?.idTransactionStatus === "REJECTED") &&
            transaction.idActivity !== "requestTransfers.wally.send";
        const ticketFinished = transaction?.idTransactionStatus === "FINISHED";

        return (
            <>
                {ticketFinished ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.sending`)}
                    />
                ) : (
                    <PDFAmountFieldError idForm={ID_FORM} name="amount" values={values} />
                )}
                <PDFaddresseeField
                    idForm={ID_FORM}
                    value={values?.beneficiaryFullName}
                    label={
                        ID_ACTIVITY === "receivedTransfers.wally.send"
                            ? i18n.get("forms.receivedTransfers.wally.from")
                            : i18n.get("forms.transfers.wally.to")
                    }
                />
                {values?.creditReference ? (
                    <PDFTextFieldWallyMessage
                        idForm={ID_FORM}
                        value={values?.creditReference}
                        label={i18n.get("forms.transfers.wally.textarea.label")}
                    />
                ) : null}
                {showReference && values?.backendReference ? (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.transfers.wally.voucher`)}
                    />
                ) : null}
                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        values?.scheduler?.valueDate?.format("DD/MM/YYYY hh:mm A") ||
                        moment(values?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z").format("DD/MM/YYYY hh:mm A")
                    }
                    label={i18n.get("forms.transfers.internal.date.label_preview")}
                />

                {isRequestTransferForm && mode === "view" && (
                    <PDFExpirationText
                        idForm={ID_FORM}
                        value={`${i18n.get(`forms.${ID_FORM}.expirationDate`)} ${addDayExpirations(
                            values?.scheduler?.valueDate,
                        )?.format("DD/MM/YYYY hh:mm A")}`}
                    />
                )}
            </>
        );
    };

    const renderFields = (setFieldValue, values) => {
        const { currentLang, fromBackoffice } = props;
        const genericProps = {
            mode,
            lang: currentLang,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        const dataAmount = {
            decimalSeparator,
            options: [{ id: "USD", label: "USD" }],
            precision: 2,
            thousandSeparator,
        };

        const onChangeAmmount = () => {
            if (isRequestTransferForm) {
                setFieldValue("creditAcctId", accountWally?.idProduct);
                setFieldValue("debitAccountId", beneficiaryWally?.accountId);
            } else {
                setFieldValue("debitAccountId", accountWally?.idProduct);
                setFieldValue("creditAcctId", beneficiaryWally?.accountId);
            }
            setFieldValue("beneficiaryFullName", beneficiaryWally?.fullName);
            setFieldValue("beneficiaryPhoneNumber", beneficiaryWally?.phoneNumber);
            setFieldValue("beneficiaryPartyId", beneficiaryWally?.partyId);
            setFieldValue("wallyPhoneNumber", userWally?.phoneNumber);
            setFieldValue("isCommerce", beneficiaryWally?.type === "C");

            setFieldValue("scheduler", {
                selectedOption: schedulerUtils.TODAY,
                valueDate: getDefaultValueDate(metadata),
            });
        };

        const handleFavorite = () => {
            if (!isFetchingPeople) {
                dispatch(wallyActions.favoriteRequest(beneficiaryWally));
            }
        };

        return (
            <>
                {mode === "edit" && (
                    <>
                        {isRequestTransferForm ? (
                            <Box display="flex" column alignY="center" className="mt-4 mb-3" fullWidth>
                                <Box display="flex" alignX="center" alignY="center" className="mb-2" fullWidth>
                                    <Text color="heading" size="3" className="mr-2">
                                        {i18n.get(`forms.${ID_FORM}.titleForm`)}
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" column alignY="center" className="mt-4 mb-3" fullWidth>
                                <Box display="flex" alignX="center" alignY="center" className="mb-2" fullWidth>
                                    <Text color="heading" size="3" className="mr-2">
                                        {i18n.get("forms.transfers.wally.available")}
                                        {":"}
                                    </Text>
                                    <FormattedAmount
                                        color="heading"
                                        size="2"
                                        bold
                                        quantity={accountWally?.balance}
                                        currency={accountWally?.currency}
                                    />
                                </Box>
                                <Button
                                    label={
                                        beneficiaryWally?.type === "P"
                                            ? "forms.transfers.wally.link.people"
                                            : "forms.transfers.wally.link"
                                    }
                                    bsStyle="link-wally"
                                    small
                                    onClick={handlerLink}
                                />
                            </Box>
                        )}
                        <Box display="flex" column fullWidth>
                            <Box className="px-5 pt-5 pb-9 mb-3 mx-n-5" background="white" borderRadius="default">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={dataAmount}
                                    key="amount"
                                    name="amount"
                                    value={{ currency: "USD", quantity: values?.amount }}
                                    idField="amount"
                                    customPlaceholderCurrency=""
                                    customPlaceholderQuantity="0.00"
                                    quantityTextAlign="text-left"
                                    disabledCurrency
                                    boldAmount
                                    onChange={onChangeAmmount}
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignX="between"
                                alignY="center"
                                className="py-7 pl-7 pr-6 mb-3 mx-n-5"
                                background="white"
                                borderRadius="default">
                                <Text size="3" color="heading">
                                    {i18n.get("forms.transfers.wally.to")}:
                                </Text>
                                <Text fullWidth size="2" color="heading" bold className="px-3" align="center">
                                    {beneficiaryWally?.fullName}
                                </Text>
                                {beneficiaryWally?.type === "P" && (
                                    <Button
                                        loading={isFetchingPeople}
                                        bsStyle="only-icon"
                                        imageSm
                                        image={favorite ? "images/star.svg" : "images/star-off.svg"}
                                        onClick={() => handleFavorite()}
                                    />
                                )}
                            </Box>
                            <Box className="px-5 pb-8 mx-n-5" background="white" borderRadius="default">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Textarea}
                                    key="creditReference"
                                    name="creditReference"
                                    idField="creditReference"
                                    value={values?.creditReference}
                                    renderAs="creditReference"
                                    isRequired={false}
                                    optional={i18n.get("form.field.optional")}
                                    maxLength={maxLengthReason || 40}
                                />
                            </Box>
                        </Box>
                    </>
                )}

                {mode === "preview" || mode === "view" ? (
                    <Box display="flex" alignX="center" fullWidth className="amount-wrapper wally">
                        <Box display="flex" column>
                            {mode === "preview" ? (
                                <Box display="flex" alignX="center">
                                    <Text
                                        component="label"
                                        labelKey={`forms.${ID_ACTIVITY}`}
                                        className="mb-0"
                                        size="5"
                                        color="heading"
                                        regular
                                    />
                                </Box>
                            ) : (
                                <Box display="flex">
                                    <FieldLabel
                                        labelNoMarginTop
                                        labelText={i18n.get(`forms.${ID_FORM}.sending`)}
                                        mode="view"
                                    />
                                    <Image src="images/wally-head.svg" className="ml-2" />
                                </Box>
                            )}
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                value={values.amount}
                                idField="amount"
                                bigDataAmount
                                renderLabel={false}
                            />
                        </Box>
                    </Box>
                ) : null}

                {mode === "preview" || mode === "view" ? (
                    <Box className={classNames("wally", { "mt-6": mode === "view" })}>
                        <Box
                            display="flex"
                            alignX="center"
                            {...(mode === "preview" && { className: "mb-6 px-5" })}
                            fullWidth>
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.beneficiaryFullName}
                                label={
                                    ID_ACTIVITY === "receivedTransfers.wally.send"
                                        ? "forms.receivedTransfers.wally.from"
                                        : "forms.transfers.wally.to"
                                }
                                bigMessageStyle
                                wally
                            />
                        </Box>
                        {values?.creditReference && (
                            <Box
                                display="flex"
                                alignX="center"
                                {...(mode === "view" && { className: "mb-9" })}
                                {...(mode === "preview" && { className: "px-5" })}
                                fullWidth>
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    wally
                                    value={values?.creditReference}
                                    label="forms.transfers.wally.textarea.label"
                                    regularMessageStyle
                                    centerBlock
                                />
                            </Box>
                        )}

                        {mode === "view" && values?.backendReference && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.backendReference}
                                wally
                                label="forms.transfers.wally.voucher"
                                shouldRender={mode === "view"}
                            />
                        )}
                        {mode === "view" && (
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                wally
                                marginStyle
                                value={
                                    values?.scheduler?.valueDate?.format("DD/MM/YYYY hh:mm A") ||
                                    (transaction?.valueDateTime
                                        ? moment(transaction?.valueDateTime, "YYYY-MM-DDTHH:mm:ss.000Z").format(
                                              "DD/MM/YYYY hh:mm A",
                                          )
                                        : moment(values?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z").format(
                                              "DD/MM/YYYY hh:mm A",
                                          ))
                                }
                                label="forms.transfers.wally.date"
                            />
                        )}
                        {isRequestTransferForm && mode === "view" && (
                            <Box display="flex" alignX="center">
                                <Text size="6" color="border-base">
                                    {i18n.get(`forms.${ID_FORM}.expirationDate`)}{" "}
                                    {addDayExpirations(values?.scheduler?.valueDate)?.format("DD/MM/YYYY hh:mm A")}
                                </Text>
                            </Box>
                        )}
                        {isSendPendingTransferForm && mode === "preview" && (
                            <Box display="flex" alignX="center" className="mt-4">
                                <Text size="6" color="border-base">
                                    {i18n.get(`forms.${ID_FORM}.expirationDate`)}{" "}
                                    {addDayExpirations(
                                        moment(values?.transactionDate, "YYYY-MM-DDTHH:mm:ss.000Z"),
                                    )?.format("DD/MM/YYYY hh:mm A")}
                                </Text>
                            </Box>
                        )}
                    </Box>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        cancelAction: handlerCancelAction,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        showSchedulerMessage: false,
        wally,
        isJoint: false,
        subtitle: formatAccount(accountWally),
        subtitlePrefix: accountWally?.productAlias,
        validate: validateForm,
        fetching: fetching || isFetchingWally,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    beneficiaryWally: wallySelectors.getSelectedBeneficiary(state),
    accountWally: wallySelectors.getAccountWally(state),
    userWally: wallySelectors.getUserWally(state),
    isFetchingPeople: wallySelectors.isFetchingPeople(state),
    isFetchingWally: wallySelectors.isFetching(state),
});

SendWallyTransaction.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    currentLang: string,
    preDataForm: shape({}),
    wally: bool,
    fromBackoffice: bool,
    subtitle: string,
    subtitlePrefix: string,
    previewData: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    beneficiaryWally: shape({}),
    accountWally: shape({}),
    userWally: shape({}),
    isFetchingPeople: bool,
    isFetchingWally: bool,
    fetching: bool,
};

SendWallyTransaction.defaultProps = {
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: {},
    wally: true,
    subtitle: true,
    subtitlePrefix: true,
    fromTransaction: false,
    previewData: {},
    transaction: {},
    location: {},
    beneficiaryWally: {},
    accountWally: {},
    userWally: {},
    isFetchingPeople: false,
    isFetchingWally: false,
    fetching: false,
};
export default compose(connect(mapStateToProps), withRouter)(SendWallyTransaction);

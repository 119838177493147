/* eslint-disable */
import classNames from "classnames";
import React, { useEffect } from "react";
import { func, shape, string, bool, arrayOf } from "prop-types";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FormattedAmount from "pages/_components/FormattedAmount";

const SelectAccount = (props) => {
    const { field, form, options, initialChecked, kuaraSecondaryColor } = props;

    useEffect(() => {
        if (field && form) {
            if (initialChecked) {
                form.setFieldValue(field.name, initialChecked.idProduct);
            }
        }
    }, []);

    const onChange = (e) => {
        const { value } = e.target;
        if (field && form) {
            form.setFieldValue(field.name, value);
        }
    };

    return (
        <Box display="flex" column alignY="center" className="mb-8 select-account-wrapper" gap="3" fullWidth>
            {options.map((account) => (
                <Box
                    display="flex"
                    alignY="center"
                    background="white"
                    className="radio-btn-wrapper m-0 py-5 pr-3 pl-5"
                    borderRadius="default"
                    key={account.longLabel}
                    fullWidth>
                    <input
                        id={account.number}
                        type="radio"
                        name={field.name}
                        value={account?.idProduct}
                        label={account?.longLabel}
                        className={classNames("radio-btn", { "kuara-secondary-color": kuaraSecondaryColor })}
                        onClick={onChange}
                        onChange={onChange}
                        checked={account?.idProduct == field?.value}
                    />
                    <Box display="flex" column className="ml-3" alignX="between">
                        <Text htmlFor="accountItem" size="5" color="heading" bold>
                            {account?.productAlias}
                        </Text>
                        <Text htmlFor="accountItem" size="6">
                            {`${account.productAlias} ${account.number} -`}
                            <div className="display-contents">
                                <FormattedAmount
                                    className="display-contents"
                                    size="6"
                                    currency={account.currency}
                                    quantity={account.balance}
                                />
                            </div>
                        </Text>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

SelectAccount.propTypes = {
    idForm: string.isRequired,
    className: string,
    allOption: string,
    field: shape({}).isRequired,
    form: shape({}).isRequired,
    handleChange: func,
    hideLabel: bool,
    hidePlaceholder: bool,
    options: arrayOf(string).isRequired,
    initialOptions: arrayOf(string),
    searchable: bool,
    i18nOptionsPrefix: string,
    textOptionsMap: shape({}),
    kuaraSecondaryColor: bool,
};

SelectAccount.defaultProps = {
    className: null,
    allOption: null,
    handleChange: null,
    hideLabel: false,
    hidePlaceholder: false,
    i18nOptionsPrefix: "",
    searchable: false,
    initialOptions: null,
    textOptionsMap: null,
    kuaraSecondaryColor: false,
};

export default SelectAccount;

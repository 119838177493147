import flowRight from "lodash/flowRight";
import withFocus from "pages/_components/withFocus";
import formField from "pages/forms/_components/_fields/_commons/formField";
import ModalManualRole from "pages/forms/customForms/ModalManualRole";
import { func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
// import { routerActions } from "react-router-redux/actions";
import ShowInfoFile from "pages/forms/customForms/_customFields/ShowInfoFile";

// NO BORRAR (IMPLEMENTACION PRODUCTO)
// import Box from "pages/_components/Box";
// import Button from "pages/_components/Button";
// import GridTable from "pages/_components/GridTable/GridTable";
// import Text from "pages/_components/Text";
// import FormattedAmount from "pages/_components/FormattedAmount";

const ModalSelectCustom = (props) => {
    const {
        value,
        mode,
        setValue,
        // NO BORRAR (IMPLEMENTACION PRODUCTO)
        // onChangeManual = null,
        uploadType,
        currency,
        dispatch,
        idTransactionTicket,
        optionCurrencyList,
        optionBankList,
        genericProps,
        infoErrorFile,
        onChangeAmount,
        fileTotalAmount,
        addItemManual,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [currencyCustom, setCurrencyCustom] = useState(currency);
    const [amount, setAmount] = useState(0);

    const [page, setPage] = useState(0);
    const [itemPage, setItemPage] = useState([]);
    const NUMBER_PAGE = 10;

    const getDataPagination = () => {
        if (!value || value.length === 0) {
            setItemPage([]);
            return;
        }

        if (value.length <= NUMBER_PAGE) {
            const newValue = [...value];
            setItemPage(newValue);
            return;
        }

        const startIndex = page * NUMBER_PAGE;
        let endIndex = startIndex + NUMBER_PAGE;
        endIndex = endIndex > value.length ? value.length : endIndex;
        const items = value.slice(startIndex, endIndex);
        if (items) {
            const newValue = [...itemPage];
            newValue.push(...items);
            setItemPage(newValue);
        }

        const newPage = page + 1;
        setPage(newPage);
    };

    const calculateAmount = (items) => {
        let total = fileTotalAmount?.quantity;
        if (!total) {
            const itemArray = items || value;
            total = 0;
            if (itemArray) {
                const itemsDefault = itemArray || [];
                total = itemsDefault.reduce((acc, val) => acc + parseFloat(val.creditAmountQuantity), 0);
            }
        }
        setAmount(total);
    };

    const updateCurrencyItems = (currencyData) => {
        if (value) {
            const valueCurrency = value.map((item) => ({ ...item, creditAmountCurrency: currencyData }));
            setValue(valueCurrency);
        }
    };

    useEffect(() => {
        setItemPage([]);
        setPage(0);
        calculateAmount();
        getDataPagination();
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if ((onChangeAmount, amount, currencyCustom)) {
            onChangeAmount(amount, currencyCustom);
        }
        // eslint-disable-next-line
    }, [amount, currencyCustom]);

    const onClose = () => {
        setShowModal(false);
    };

    const getCurrencyItems = () => {
        let currencyResult = currencyCustom;
        if (value && value.length > 0) {
            currencyResult = value[0].creditAmountCurrency || currencyCustom;
        }

        return currencyResult;
    };

    const addItem = (item) => {
        if (item) {
            const newValue = [...value, { ...item, creditAmountCurrency: getCurrencyItems() }];
            setValue(newValue);
        }
        setShowModal(false);
    };

    const handleCurrency = (event) => {
        if (event && event.target.value) {
            const currencyData = event.target.value;
            updateCurrencyItems(currencyData);
            setCurrencyCustom(currencyData);
        }
    };

    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // const deleteItem = (itemRemove) => {
    //     if (value) {
    //         const itemsFilter = value.filter((item) => item.id !== itemRemove.id);
    //         setValue(itemsFilter);
    //         const itemsFilterMore = itemPage.filter((item) => item.id !== itemRemove.id);
    //         setItemPage(itemsFilterMore);
    //         if (onChangeManual) {
    //             onChangeManual();
    //         }
    //     }
    // };

    // const renderMore = () => {
    //     const numberPageInt = Math.floor(value.length / NUMBER_PAGE);
    //     let numberPageResidue = 0;
    //     if (value.length > NUMBER_PAGE) {
    //         numberPageResidue = value.length % NUMBER_PAGE;
    //     }
    //     const numberPages = numberPageInt + (numberPageResidue > 0 ? 1 : 0);
    //     const render = value && page + 1 <= numberPages;
    //     return render;
    // };

    // const getColumnsTemplate = () => "1fr 1fr 1fr 1fr 9rem";

    // const renderItemTable = () => {
    //     if (!itemPage) {
    //         return <div />;
    //     }

    // const listItems = itemPage.map((item, index) => (
    //     <>
    //         <GridTable.Container
    //             // eslint-disable-next-line react/no-array-index-key
    //             key={index}
    //             columnsTemplate={getColumnsTemplate()}
    //             className="gapX-9"
    //             padding="py-0 px-5">
    //             <GridTable.Data columnStart={1} alignX="flex-start">
    //                 <Box display="flex" column>
    //                     <Text size="6" color="primary" bold>
    //                         {item.beneficiaryName}
    //                     </Text>
    //                     <Text size="6">
    //                         {/* {item.bankIdentifier} */}
    //                         Banco Aliado
    //                     </Text>
    //                 </Box>
    //             </GridTable.Data>
    //             <GridTable.Data columnStart={2} alignX="center">
    //                 <Text size="6">{item.accountNumber}</Text>
    //             </GridTable.Data>
    //             <GridTable.Data columnStart={3} alignX="center">
    //                 <Text size="6">{i18n.get(`forms.transfers.local.productType.${item.productType}`)}</Text>
    //             </GridTable.Data>
    //             <GridTable.Data columnStart={4} alignX="flex-end">
    //                 <FormattedAmount currency={getCurrencyItems()} quantity={item.amount} />
    //             </GridTable.Data>

    //                 <GridTable.Data columnStart={5} alignX="flex-end">
    //                     <Box display="flex" alignX="flex-end" gap="3" fullWidth>
    //                         <Button
    //                             className="toolbar-btn view-close"
    //                             onClick={() => {
    //                                 deleteItem(item);
    //                             }}
    //                             image="images/icons/editPen16.svg"
    //                             bsStyle="link"
    //                         />
    //                         <Button
    //                             className="toolbar-btn view-close"
    //                             onClick={() => {
    //                                 deleteItem(item);
    //                             }}
    //                             image="images/icons/trashDelete.svg"
    //                             bsStyle="link"
    //                         />
    //                     </Box>
    //                 </GridTable.Data>
    //             </GridTable.Container>
    //         </>
    //     ));

    //     return listItems;
    // };

    // const addItemManual = () => {
    //     // NO BORRAR (IMPLEMENTACION PRODUCTO)
    //     // setShowModal(true);
    //     // setItemPage([]);
    //     // setPage(0);
    //     // if (onChangeManual && uploadType && uploadType === "file") {
    //     //     onChangeManual();
    //     // }

    //     dispatch(formActions.rolePaymentSetDetailData(values.inputManually, values.amount, false, true));
    //     dispatch(
    //         routerActions.push({
    //             pathname: "/salaryPayment/request/processDetail",
    //             state: { showModal: true },
    //         }),
    //     );
    // };
    const editList = () => {
        // const { values } = props;
        // dispatch(routerActions.push("/salaryPayment/request/processDetail"));
    };

    // NO BORRAR (IMPLEMEMNTACION PRODUCTO)
    // const renderTableItemDetails = () => (
    //     <>
    //         <GridTable>
    //             <GridTable.Header>
    //                 <GridTable.Container columnsTemplate={getColumnsTemplate()} className="gapX-9">
    //                     <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
    //                         <Text labelKey="tableHeader.account" />
    //                     </GridTable.Data>
    //                     <GridTable.Data columnStart={2} alignX="center" size="7" inHeader>
    //                         <Text labelKey="tableHeader.bank" />
    //                     </GridTable.Data>
    //                     <GridTable.Data columnStart={3} alignX="center" size="7" inHeader>
    //                         <Text labelKey="tableHeader.description" />
    //                     </GridTable.Data>
    //                     <GridTable.Data columnStart={4} alignX="flex-end" size="7" inHeader>
    //                         <Text labelKey="tableHeader.amount" />
    //                     </GridTable.Data>
    //                 </GridTable.Container>
    //             </GridTable.Header>
    //             <GridTable.Body>{renderItemTable()}</GridTable.Body>
    //             {renderMore() && (
    //                 <div className="text-center no-more-data flex">
    //                     <Button
    //                         bsStyle="link"
    //                         onClick={() => {
    //                             getDataPagination();
    //                         }}
    //                         image="images/show.svg"
    //                         label="massive.payments.line.more.results"
    //                         className="btn-small"
    //                     />
    //                 </div>
    //             )}
    //         </GridTable>
    //     </>
    // );

    return (
        <>
            <ModalManualRole
                onClose={onClose}
                addItem={addItem}
                isVisible={showModal}
                optionBankList={optionBankList}
                genericProps={genericProps}
                currency={currencyCustom}
                idActivity={props.idActivity}
            />
            <ShowInfoFile
                uploadType={uploadType}
                mode={mode}
                value={value}
                onClickNewItem={() => {
                    addItemManual();
                }}
                onClickEditItem={() => {
                    editList();
                }}
                dispatch={dispatch}
                idTransactionTicket={idTransactionTicket}
                optionCurrencyList={optionCurrencyList}
                currencyCustom={getCurrencyItems()}
                handleCurrency={(e) => {
                    handleCurrency(e);
                }}
                amount={fileTotalAmount.quantity}
                lines={value?.length || 0}
                infoErrorFile={infoErrorFile}
            />

            {/* NO BORRAR (IMPLEMENTACION PRODUCTO TABLA) */}
            {/* {mode !== "view" && value && value.length > 0 && (
                <Box
                    display="flex"
                    background="component-background"
                    className="mt-9 pt-9 pb-10"
                    borderRadius="default">
                    {renderTableItemDetails()}
                </Box>
            )} */}
        </>
    );
};

ModalSelectCustom.propTypes = {
    setValue: func,
    value: shape({}),
    mode: string,
    optionCurrencyList: shape([]),
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // onChangeManual: func,
    uploadType: string,
    currency: string,
    dispatch: func,
    idTransactionTicket: string,
    optionBankList: shape({}).isRequired,
    genericProps: shape({}),
    infoErrorFile: shape({}),
    onChangeAmount: func,
    fileTotalAmount: shape({}),
    addItemManual: func,
    idActivity: string,
};
ModalSelectCustom.defaultProps = {
    setValue: () => {},
    value: {},
    mode: "",
    optionCurrencyList: [],
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // onChangeManual: () => {},
    uploadType: "",
    currency: "",
    dispatch: () => {},
    idTransactionTicket: "",
    genericProps: {},
    infoErrorFile: {},
    onChangeAmount: () => {},
    fileTotalAmount: { currency: "USD", quantity: 0 },
    addItemManual: () => {},
    idActivity: "",
};
export default flowRight(withFocus, formField())(ModalSelectCustom);

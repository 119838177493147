export const NO_TRANSITION = "noTransition";

export const ACTIVITIES_WITHOUT_PREVIEW_STEP = [
    "administration.medium.modify.signature.send",
    "administration.restrictions.user.delete.send",
];

export const MAX_FAILED_TIMES = 2;

export const TIME_IN_MILLIS_TO_REFRESH = 15000;

export const TIME_CHANGE_OTP = 30;

export const MAX_LAST_LOGIN_TO_SHOW = 5;

export const CORPORATE_GROUP_ENVIRONMENT_TYPE = "corporateGroup";
export const RETAIL_ENVIRONMENT_TYPE = "retail";
export const CORPORATE_ENVIRONMENT_TYPE = "corporate";
export const CORPORATE_USER_ADMIN = "admin";
export const CORPORATE_USER_SECONDARY = "secondary";

export const SALARY_PAYMENT_ID_FORM = "salaryPayment";

export const REGION_USA = "US";
export const REGION_REST_OF_LATAM = "LATAM";
export const REGION_ARG = "AR";
export const REGION_CHI = "CL";
export const REGION_CAN = "CA";
export const REGION_UY = "UY";
export const REGION_COL = "CO";
export const REGION_MEX = "MX";
export const REGION_PER = "PE";
export const REGION_ECU = "EC";
export const REGION_PAR = "PY";
export const REGION_PAN = "PA";
export const REGION_CR = "CR";
export const REGION_VE = "VE";
export const AVAILABLE_REGIONS = [REGION_PAN];
export const ITBMS = "ITBMS";

export const COMPANY_NAME = "Banesco";
export const GENERAL_CONDITIONS_CATEGORY_DEFAULT = "termsAndConditions";
export const ID_ACTIVITY_DOWNLOAD_STATE_PRE = "product.state.download.pre";
export const ID_ACTIVITY_DOWNLOAD_STATE = "product.state.download.send";
export const ID_ACTIVITY_EXCHANGE_POINTS = "product.exchange.points.send";

export const USD_CURRENCY = "USD";

export const STATUS_CHECK = {
    AVAILABLE: "AVAILABLE",
    CLEARED: "CLEARED",
    ISSUED: "ISSUED",
    STOPPED: "STOPPED",
    RETURNED: "RETURNED",
};

export const PANAMA_COUNTRY_CODE = "507";
export const PANAMA_COUNTRY_NAME = "pa";

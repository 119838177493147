import classNames from "classnames";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { bool, func, string } from "prop-types";
import { Modal } from "react-bootstrap";
import useCustomLocationSetter from "hooks/useLocationSetter";
import React from "react";
import { selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import { connect } from "react-redux";
import * as i18nUtils from "util/i18n";

const DEFAULT_REVELOCK_ID = "/sideBarModal";
const SideBarModal = ({
    children,
    onClose,
    show,
    title,
    modalId,
    currentRevelockPosition,
    scrollable,
    modalContainerClassName,
    modalBodyClassName,
    modalHeaderClassName,
}) => {
    useCustomLocationSetter(show, currentRevelockPosition, modalId || DEFAULT_REVELOCK_ID);

    return (
        <Modal
            aria-labelledby={`modal-${modalId}-${i18nUtils.get(title)}`}
            aria-modal="true"
            onHide={() => {
                onClose();
            }}
            show={show}
            className={classNames("drawer")}>
            <div className={classNames("modal-container", modalContainerClassName)}>
                <Modal.Header closeButton className={modalHeaderClassName}>
                    <Text id="modalTitleID" component="h4" labelKey={title} className="modal-title" />
                </Modal.Header>
                <Modal.Body className={modalBodyClassName}>
                    <Box
                        display="flex"
                        fullWidth
                        className={classNames("px-0 px-md-9", { "modal-scrollable-content": scrollable })}
                        flex1>
                        {children}
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

SideBarModal.propTypes = {
    children: func,
    onClose: func,
    show: bool,
    title: string,
    modalId: string,
    currentRevelockPosition: string,
    scrollable: bool,
    modalContainerClassName: string,
    modalBodyClassName: string,
    modalHeaderClassName: string,
};

SideBarModal.defaultProps = {
    children: "",
    onClose: () => {},
    show: false,
    title: "",
    modalId: "",
    currentRevelockPosition: "",
    scrollable: false,
    modalContainerClassName: null,
    modalBodyClassName: null,
    modalHeaderClassName: null,
};

const mapStateToProps = (state) => ({
    currentRevelockPosition: revelockSelectors.getPosition(state),
});

export default connect(mapStateToProps)(SideBarModal);

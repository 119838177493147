import { useEffect } from "react";
import { useRevelockPositionProvider } from "providers/RevelockPositionProvider";

const useCustomLocationSetter = (show, currentRevelockPosition, id) => {
    const { setLocationCustomPath } = useRevelockPositionProvider();

    const setLocationRevelock = (basePath, prefij) => {
        if (!setLocationCustomPath || !show) {
            return;
        }

        setLocationCustomPath(basePath, prefij ? `/${prefij}` : prefij);
    };

    useEffect(() => {
        setLocationRevelock(currentRevelockPosition || window.location.pathname, id);

        return () => {
            setLocationRevelock(currentRevelockPosition || window.location.pathname);
        };
    }, [show]);
};

export default useCustomLocationSetter;

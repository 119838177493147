import { Field, Form, withFormik } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DateField from "pages/_components/fields/DateField";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, instanceOf, shape, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { actions as accountsActions } from "reducers/accounts";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "accounts.movements.filters.period";

class PeriodFilter extends Component {
    componentDidMount() {
        const { setFieldValue, advancedFilter } = this.props;
        if (setFieldValue && advancedFilter?.dateFrom && advancedFilter?.dateTo) {
            setFieldValue("dateFrom", advancedFilter.dateFrom);
            setFieldValue("dateTo", advancedFilter.dateTo);
        }
    }

    render() {
        const {
            isSubmitting,
            values: { dateFrom, dateTo },
            minDateFrom,
            minDateTo,
        } = this.props;

        return (
            <Form style={{ display: "contents" }}>
                <Col xs={6} md={3}>
                    <Field
                        component={DateField}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="dateFrom"
                        selectsStart
                        startDate={dateFrom}
                        handleChange={this.handleChangeDateFrom}
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"], // don't allow it to flip to be above
                            },
                        }}
                        {...(minDateFrom && { minDate: minDateFrom })}
                        {...(dateTo && { maxDate: dateTo })}
                    />
                </Col>
                <Col xs={6} md={3}>
                    <Field
                        component={DateField}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="dateTo"
                        selectsEnd
                        startDate={dateFrom}
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"], // don't allow it to flip to be above
                            },
                        }}
                        // eslint-disable-next-line no-nested-ternary
                        {...(dateFrom
                            ? { minDate: dateFrom }
                            : minDateTo
                            ? { minDate: minDateTo }
                            : moment().add(-6, "months"))}
                    />
                </Col>
                <Col xs={12} md={2} className="pr-md-7 pt-3 pt-md-0 full-height">
                    <Box display="flex" alignY="flex-end" fullHeight>
                        <Button
                            bsStyle="primary"
                            className="mt-auto"
                            label="product.filters.filter"
                            loading={isSubmitting}
                            type="submit"
                            block
                        />
                    </Box>
                </Col>
            </Form>
        );
    }
}

PeriodFilter.propTypes = {
    isSubmitting: bool,
    values: shape([]),
    minDateFrom: instanceOf(Date),
    minDateTo: instanceOf(Date),
    isMobile: bool.isRequired,
    setFieldValue: func.isRequired,
    advancedFilter: shape({}),
};

PeriodFilter.defaultProps = {
    isSubmitting: false,
    values: [],
    minDateFrom: null,
    minDateTo: null,
    advancedFilter: null,
};

export default compose(
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dateFrom: null,
            dateTo: null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                }),
            ),
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch, productId } = formikBag.props;
            dispatch(
                accountsActions.details(productId, { ...filters, advanced: true, filterType: "period" }, formikBag),
            );
        },
    }),
)(PeriodFilter);

import classNames from "classnames";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Dropdown from "pages/_components/Dropdown";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text/Text";
import { bool, func, shape, string, oneOfType, arrayOf } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { push } from "react-router-redux";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import * as i18n from "util/i18n";
import SideBarModal from "pages/_components/modal/SideBarModal";
import StopChecksEditForm from "pages/checks/StopChecksEditForm";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const Checkbooks = (props) => {
    const {
        dispatch,
        isDesktop,
        isFetching,
        checkbooks,
        accountNumber,
        isRequestAvailable,
        accounts,
        match,
        account,
        setLocationCustomPath,
        isActiveCorporate,
    } = props;
    const [checkbooksView, setCheckbooksView] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectCheckbook, setSelectCheckbook] = useState({});
    const [isStopCheckOpen, setIsStopCheckOpen] = useState(false);
    const [mergedAccount, setMergedAccount] = useState({});

    const productId = match.params.id;

    const desktopColumnsTemplate = "0.75fr 1fr 1fr 0.75fr 1fr 1fr 0.75fr 2rem";
    const mobileColumnsTemplate = "1.2fr 1.3fr 0.5fr 0.25fr";

    /**
     * Set position revelock
     */
    const getLocationBasePath = () => {
        const basePathSplit = match?.path?.split(":");
        return basePathSplit && basePathSplit.length > 0 ? basePathSplit[0] : "";
    };

    const setRevelockLocation = () => {
        if (setLocationCustomPath) {
            const pageId = "checkbooks.list";
            setLocationCustomPath(getLocationBasePath(), pageId);
        }
    };

    useEffect(() => {
        setRevelockLocation();
        dispatch(checksActions.listCheckbooksRequest(productId));
        dispatch(accountsActions.listAccounts());
    }, []);

    const getMergedAccount = () => {
        let accountFromList = null;

        if (account) {
            if (accounts && accounts.length > 0) {
                accountFromList = accounts?.find((a) => a.idProduct === account.idProduct);
            }
        }

        let returnAccount = null;
        if (accountFromList) {
            returnAccount = {
                ...account,
                status: accountFromList.status,
                isValidToReferenceLetter: accountFromList.isValidToReferenceLetter,
            };
        }

        return returnAccount;
    };

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            dispatch(accountsActions.readAccount(match.params.id));
        }

        if (account) {
            setMergedAccount(getMergedAccount);
        }
    }, [accounts, account]);

    useEffect(() => {
        setCheckbooksView(checkbooks);
    }, [checkbooks]);

    const handleBack = () => {
        dispatch(push(`/accounts/${productId}`));
    };

    const handleClickFilter = () => {
        if (inputValue) {
            const checkBooksFiltered = checkbooks.filter((checkbook) => checkbook.initialCheckNumber === inputValue);
            setCheckbooksView(checkBooksFiltered);
        } else {
            setCheckbooksView(checkbooks);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleClickFilter();
        }
    };

    function handleInputChange(event) {
        const { value } = event.target;
        setInputValue(value);
    }

    const redirectToCheckbooksRequest = () => {
        dispatch(push(`/formCustom/requestsCheckBooks?debitAccount=${productId}`));
    };

    const renderTable = () => (
        <GridTable>
            <GridTable.Header>
                <GridTable.Container
                    columnsTemplate={isDesktop ? desktopColumnsTemplate : mobileColumnsTemplate}
                    className={isDesktop ? "gapX-9" : "gapX-3"}
                    padding="py-0 px-5 pl-md-5 pr-md-2">
                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                        <Text labelKey="checkbooks.header.table.initialCheckNumber" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={2} alignX="center" inHeader>
                        <Text labelKey="checkbooks.header.table.activationDate" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={3} alignX="center" inHeader>
                        <Text labelKey="checkbooks.header.table.status" />
                    </GridTable.Data>

                    {isDesktop && (
                        <>
                            <GridTable.Data columnStart={4} alignX="center" inHeader>
                                <Text labelKey="checkbooks.header.table.numberOfChecks" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={5} alignX="center" inHeader>
                                <Text labelKey="checkbooks.header.table.available" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={6} alignX="center" inHeader>
                                <Text labelKey="checkbooks.header.table.notAvailable" />
                            </GridTable.Data>

                            <GridTable.Data columnStart={7} alignX="center" inHeader>
                                <Text labelKey="checkbooks.header.table.action" />
                            </GridTable.Data>
                        </>
                    )}
                </GridTable.Container>
            </GridTable.Header>

            <GridTable.Body>
                {checkbooksView.map((checkbookView) => (
                    <GridTable.Container
                        className="px-5"
                        padding={classNames("px-5 pl-md-5 pr-md-2", { "py-0": isDesktop, "py-5": !isDesktop })}
                        columnsTemplate={isDesktop ? desktopColumnsTemplate : mobileColumnsTemplate}
                        rows={1}
                        {...(!isDesktop && {
                            onClick: () => {
                                dispatch(routerActions.push(`/checks/${productId}/${checkbookView.id}`));
                            },
                        })}
                        key={checkbookView.id}>
                        <>
                            <GridTable.Data columnStart={1} alignX="flex-start">
                                <Text>{checkbookView.initialCheckNumber}</Text>
                            </GridTable.Data>

                            <GridTable.Data columnStart={2} alignX="center">
                                <FormattedDate date={checkbookView.activationDate} />
                            </GridTable.Data>
                            <GridTable.Data columnStart={3} alignX={isDesktop ? "center" : "flex-end"}>
                                <Text>
                                    {checkbookView.status === "AC"
                                        ? i18n.get(`checkbooks.status.${checkbookView.status}`)
                                        : checkbookView.status}
                                </Text>
                            </GridTable.Data>
                        </>

                        {isDesktop && (
                            <>
                                <GridTable.Data columnStart={4} alignX="center">
                                    <Text>{checkbookView.numberOfChecks}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={5} alignX="center">
                                    <Text>{checkbookView.available}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={6} alignX="center">
                                    <Text>{checkbookView.notAvailable}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={7} alignX="center" overflow="visible">
                                    <Button
                                        bsStyle="link"
                                        className="px-3"
                                        small
                                        label="checkbooks.button.action.detail"
                                        onClick={() => {
                                            dispatch(routerActions.push(`/checks/${productId}/${checkbookView.id}`));
                                        }}
                                    />
                                </GridTable.Data>
                                {checkbookView.notAvailable === "0" && (
                                    <GridTable.Data columnStart={8} alignX="center" overflow="visible">
                                        <Dropdown
                                            image="images/contextualMenu.svg"
                                            buttonClass="py-0 px-3 btn-only-icon icon-big"
                                            bsStyle="link"
                                            pullLeft
                                            pullCenter>
                                            <Button
                                                label="checkbooks.button.action.suspend"
                                                className="dropdown__item-btn"
                                                bsStyle="link"
                                                onClick={() => {
                                                    setIsStopCheckOpen(true);
                                                    setSelectCheckbook(checkbookView);
                                                }}
                                            />
                                        </Dropdown>
                                    </GridTable.Data>
                                )}
                            </>
                        )}
                        {!isDesktop && (
                            <GridTable.Data className="p-0 ml-5" columnStart={4} alignX="center">
                                <Image src="images/icons/chevronRight.svg" color="text-color" />
                            </GridTable.Data>
                        )}
                    </GridTable.Container>
                ))}
                {checkbooksView.length === 0 && (
                    <Box display="flex" alignX="center" className="p-10">
                        <Text align="center" labelKey="checkbooks.table.filter.empty" />
                    </Box>
                )}
            </GridTable.Body>
        </GridTable>
    );

    return (
        <>
            <Notification scopeToShow="checkBooks" />
            <Head
                onBack={handleBack}
                title="checkbooks.header.title"
                subtitlePrefix={i18n.get("accounts.productType.checking")}
                subtitle={accountNumber}
                {...(isRequestAvailable && isActiveCorporate &&
                    mergedAccount?.status === "ACTIVA" && {
                        actionButton: () => {
                            redirectToCheckbooksRequest();
                        },
                        actionButtonImage: "images/icons/circlePlus.svg",
                    })}
                {...(isRequestAvailable && isActiveCorporate &&
                    isDesktop &&
                    mergedAccount?.status === "ACTIVA" && {
                        actionButtonLabel: "checkbookRequest.new",
                        actionButtonBsStyle: "primary",
                    })}
            />

            <MainContainer showLoader={isFetching}>
                {checkbooks?.length > 0 ? (
                    <Box
                        component="article"
                        background="white"
                        borderRadius="default"
                        className="pt-5 mt-5 mb-5"
                        toBorderInMobile>
                        <Box
                            display="flex"
                            alignY="flex-end"
                            alignX="between"
                            gap="7"
                            {...(!isDesktop && { border: "bottom-background-border-disabled" })}
                            className="pb-8 px-5"
                            fullWidth>
                            <Row alignY="flex-end" gapX="4">
                                <Col xs={6} md={6} lg={3}>
                                    <Box className="form-group">
                                        <FieldLabel labelKey="checkbooks.filter.label" labelNoMarginTop />
                                        <Box className="input-group">
                                            <input
                                                className="form-control"
                                                type="number"
                                                max="9999999"
                                                min="0"
                                                value={inputValue}
                                                name="input"
                                                onChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                                placeholder=""
                                            />
                                        </Box>
                                    </Box>
                                </Col>
                                <Col xs={4} md={3}>
                                    <Button
                                        label="product.filters.filter"
                                        bsStyle="primary"
                                        className={classNames("mt-auto", { "px-9": isDesktop, "px-7": !isDesktop })}
                                        disabled={checkbooks?.length === 0}
                                        onClick={handleClickFilter}
                                    />
                                </Col>
                                {!isDesktop && (
                                    <Col xs={2} md={3}>
                                        <Button
                                            bsStyle="link"
                                            onClick={() => dispatch(routerActions.push(`/searchchecks/${productId}`))}
                                            image="images/search.svg"
                                        />
                                    </Col>
                                )}
                            </Row>
                            {isDesktop && (
                                <Box display="flex" alignX="flex-end">
                                    <Button
                                        bsStyle="link"
                                        label="checkbooks.list.search"
                                        image="images/search.svg"
                                        labelClassName="text-no-wrap"
                                        onClick={() => dispatch(routerActions.push(`/searchchecks/${productId}`))}
                                    />
                                </Box>
                            )}
                        </Box>
                        {renderTable()}
                    </Box>
                ) : (
                    <Row>
                        <Col xs={12} md={8} mdOffset={2}>
                            <Box
                                display="flex"
                                alignX="center"
                                background="component-background"
                                borderRadius="default"
                                border="dashed-image-border-base-color"
                                className="p-10 mt-5 mt-md-12">
                                <Text align="center" labelKey="checkbooks.table.empty" />
                            </Box>
                        </Col>
                    </Row>
                )}
            </MainContainer>
            <SideBarModal
                show={isStopCheckOpen}
                onClose={() => {
                    setIsStopCheckOpen(false);
                }}
                title="checkbooks.title.sidebar.suspend"
                scrollable>
                <StopChecksEditForm
                    onChangeStatus={() => setIsStopCheckOpen(false)}
                    checksToSuspend={[
                        selectCheckbook.initialCheckNumber,
                        (
                            parseInt(selectCheckbook?.initialCheckNumber || "0", 10) +
                            selectCheckbook.numberOfChecks -
                            1
                        ).toString(),
                    ]}
                    quantityChecks={selectCheckbook.numberOfChecks}
                    productId={productId}
                    type="checkbooks"
                    checkTypeValue={selectCheckbook.id}
                />
            </SideBarModal>
        </>
    );
};

Checkbooks.propTypes = {
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool.isRequired,
    checkbooks: shape({}).isRequired,
    accountNumber: string.isRequired,
    isRequestAvailable: oneOfType([string, bool]).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    account: shape({
        productType: string,
        idProduct: string,
        permissions: shape({}),
        currency: string,
    }).isRequired,
    setLocationCustomPath: shape({}).isRequired,
    isActiveCorporate: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: checksSelectors.getFetching(state),
    checkbooks: checksSelectors.checkbooks(state),
    accountNumber: checksSelectors.accountNumber(state),
    isRequestAvailable: sessionSelectors.hasPermissions(state, ["requestCheckbook"]),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    account: accountsSelectors.getSelectedAccount(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(resizableRoute(withRevelockPositionContext(Checkbooks)));

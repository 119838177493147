/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { selectors as productsSelectors } from "reducers/products";
import * as i18nUtils from "util/i18n";
import isLocalPhoneNumber from "util/mobileNumber";
import Sticker from "../Sticker";

const ListItemDefault = ({
    amount,
    amount2,
    amountLabel,
    categoryIdIcon,
    completeFavorites,
    contextOptions,
    currency,
    currency2,
    expiredText,
    favorite,
    fetching,
    // franchise,
    hideChevron,
    icon,
    idProduct,
    image,
    isDesktop,
    isExpired,
    name,
    onClick,
    overdraftEnabled,
    overdueStatusLoan,
    path,
    productType,
    // productTypeTitle,
    rate,
    reference,
    setFavoriteAccount,
    showProductIcon,
    showStar,
    showStatusIcon,
    status,
    title,
    kuaraProduct,
    wallyProduct,
}) => {
    const [changingStatusFavorite, setChangingStatusFavorite] = useState(false);

    useEffect(() => {
        if (!fetching) {
            setChangingStatusFavorite(false);
        }
    }, [fetching]);

    const onClickFavoriteAccount = (e) => {
        e.preventDefault();
        setChangingStatusFavorite(true);
        setFavoriteAccount(idProduct, productType, !favorite);
    };

    const urlImagen = categoryIdIcon
        ? `images/icons/productType${categoryIdIcon}.svg`
        : `images/icons/productType${productType}.svg`;

    const loanStatus = () => {
        const statusLoan = overdueStatusLoan?.toLowerCase();

        let statusBg = "";

        switch (statusLoan) {
            case "nab":
                statusBg = "error-color";
                break;
            case "del120":
            case "del90":
            case "del12":
                statusBg = "error-border-color";
                break;
            case "del":
                statusBg = "warning-border-color";
                break;
            default:
                return statusBg;
        }
        return statusBg;
    };

    return (
        <Box component="li" display="flex" alignX="between" alignY="center" className="prod-item" noList>
            <Link
                // TODO (a11y)
                // aria-label={`${productTypeTitle}: ${franchise !== "" ? `${franchise} ` : ""}${name}`}
                aria-describedby={idProduct}
                className={classNames(
                    "prod-item-link full-width",
                    { "is-expired": isExpired },
                    { "prod-item-link-no-context": contextOptions === null },
                )}
                to={path}
                onClick={() => onClick()}>
                <Box display="flex" alignY="center" fullWidth>
                    <Box
                        className={classNames("prod-item-wrapper pl-5 py-4 py-md-5", { "pr-5": !contextOptions })}
                        display="flex"
                        alignX="between"
                        alignY="center"
                        fullWidth
                        gap="4"
                        flex1>
                        {isDesktop && showProductIcon && (
                            <Image
                                className="prod-item-icon"
                                wrapperClassName="prod-item-icon-svg-wrapper"
                                src={image === null ? urlImagen : image}
                            />
                        )}

                        <Box display="flex" column alignY="flex-start" className="prod-item-info-wrapper mr-auto">
                            <Box display="flex" className="item-info-wrapper" alignY="center">
                                {icon && <Image src={`images/${icon}.svg`} wrapperClassName="item-info-icon" />}

                                <Text
                                    component="h3"
                                    className="item-info-name"
                                    ariaLabel={`${name} - ${title} - ${reference}`}
                                    ellipsis>
                                    {name}
                                </Text>
                                {showStar && (
                                    <Box display="flex" alignY="center" alignX="flex-start" className="star-wrapper">
                                        <Button
                                            ariaLabel={
                                                favorite ? "global.favoriteStar.a11y" : "global.noFavoriteStar.a11y"
                                            }
                                            image={`images/icons/favoriteStar${favorite ? "On" : "Off"}.svg`}
                                            className={`star-btn ${completeFavorites ? "disabled" : "off"}`}
                                            disabled={completeFavorites && !favorite}
                                            bsStyle="only-icon"
                                            loading={changingStatusFavorite}
                                            onClick={onClickFavoriteAccount}
                                        />
                                    </Box>
                                )}

                                {overdueStatusLoan && overdueStatusLoan !== "" && (
                                    <Sticker
                                        labelKey="loan.overdue.status.defaulter"
                                        uppercase={false}
                                        bold
                                        className={!showStar && "ml-4"}
                                        status={loanStatus()}
                                        {...((overdueStatusLoan.toLowerCase() === "nab" ||
                                            overdueStatusLoan.toLowerCase() === "del120") && { color: "inverse" })}
                                    />
                                )}

                                {kuaraProduct && isLocalPhoneNumber() && (
                                    <Image
                                        src="images/logos/kuaraHorizontalSmall.svg"
                                        wrapperClassName="item-info-icon ml-3"
                                    />
                                )}

                                {wallyProduct && !isLocalPhoneNumber() && (
                                    <Image src="images/wally.svg" wrapperClassName="item-info-icon ml-3" />
                                )}

                                {isExpired && !(overdueStatusLoan && overdueStatusLoan !== "") && (
                                    <Sticker
                                        uppercase={false}
                                        bold
                                        className={!showStar && "ml-4"}
                                        status="warning-border-color"
                                        color="text-boton">
                                        {expiredText}
                                    </Sticker>
                                )}
                                {status === "INACTIVA" && showStatusIcon && (
                                    <Box display="flex" alignY="center" alignX="flex-start">
                                        <Image src="images/icons/pauseSmall.svg" />
                                    </Box>
                                )}

                                {status === "NOACTIVA" && showStatusIcon && (
                                    <Box display="flex" alignY="center" alignX="flex-start">
                                        <Image src="images/icons/stopSmall.svg" />
                                    </Box>
                                )}
                            </Box>
                            <Box display="inline-flex" alignY="baseline" fullWidth>
                                {isDesktop && (reference || title) ? (
                                    <Text className="prod-item-info-title">
                                        {title} {reference}
                                    </Text>
                                ) : (
                                    <Box display="flex" column fullWidth>
                                        {title ? (
                                            <Text className="prod-item-info-title" ellipsis>
                                                {title}
                                            </Text>
                                        ) : null}
                                        {reference ? (
                                            <Text className="prod-item-info-desc" ellipsis>
                                                {reference}
                                            </Text>
                                        ) : null}
                                    </Box>
                                )}
                            </Box>
                            {(rate || rate === "0") && rate !== "" && (
                                <Box className="prod-item-info-desc">
                                    <Text>{i18nUtils.get("deposit.tax.list.label")}</Text>
                                    <Text> {rate}%</Text>
                                </Box>
                            )}
                        </Box>
                        <Box
                            id={idProduct}
                            display="flex"
                            column
                            alignY="flex-end"
                            className="prod-item-amount-wrapper ml-auto">
                            <span className="prod-item-amount-label">{amountLabel}</span>
                            <Box display="flex">
                                <FormattedAmount
                                    quantity={amount}
                                    currency={currency}
                                    className="prod-item-amount-value"
                                />
                                {currency2 != null && <span className="data-amount-currency ml-2 mr-2">|</span>}
                                {currency2 != null && (
                                    <FormattedAmount
                                        quantity={amount2}
                                        currency={currency2}
                                        className="prod-item-amount-value"
                                    />
                                )}
                            </Box>
                            {overdraftEnabled && (
                                <I18n
                                    componentProps={{ className: "prod-item-amount-overdraft" }}
                                    id="accounts.overdraft.enabled"
                                />
                            )}
                        </Box>
                        {contextOptions ? (
                            <Dropdown
                                image="images/contextualMenu.svg"
                                buttonClass="p-2 mx-2 icon-big"
                                bsStyle="only-icon"
                                ariaLabel="global.contextMenu.a11y"
                                pullRight>
                                {contextOptions.map((item) => (
                                    <Button
                                        key={item.label}
                                        label={item.label}
                                        ariaLabel={item.label}
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                        href={item.href}
                                        onClick={(e) => {
                                            if (item.onClick) {
                                                e.preventDefault();
                                                item.onClick();
                                            }
                                        }}
                                    />
                                ))}
                            </Dropdown>
                        ) : !isDesktop && !hideChevron ? (
                            <Image
                                src="images/icons/chevronRight.svg"
                                wrapperClassName="chevron-right-wrapper"
                                width="4"
                                height="4"
                                wrapperHeight="4"
                                wrapperWidth="4"
                                color="text-color"
                            />
                        ) : null}
                    </Box>
                </Box>
            </Link>

            {status && <span className="Product-label">{status.shortDescription}</span>}
        </Box>
    );
};

ListItemDefault.propTypes = {
    amount: number.isRequired,
    amount2: number.isRequired,
    amountLabel: string.isRequired,
    categoryIdIcon: string,
    completeFavorites: bool,
    contextOptions: arrayOf(shape({})),
    currency: string,
    currency2: string.isRequired,
    expiredText: string,
    favorite: bool,
    fetching: bool,
    // TODO (a11y)
    // franchise: string,
    hideChevron: bool,
    icon: string,
    idProduct: string.isRequired,
    image: string,
    isDesktop: bool.isRequired,
    isExpired: bool,
    name: string.isRequired,
    onClick: func,
    overdraftEnabled: bool,
    overdueStatusLoan: string,
    path: oneOfType([string, shape({ pathname: string })]).isRequired,
    productType: string,
    // TODO (a11y)
    // productTypeTitle: string,
    rate: string,
    reference: string.isRequired,
    setFavoriteAccount: func,
    showProductIcon: bool,
    showStar: bool,
    showStatusIcon: bool,
    status: string,
    title: string,
    kuaraProduct: bool,
    wallyProduct: bool,
};

ListItemDefault.defaultProps = {
    categoryIdIcon: "",
    completeFavorites: false,
    contextOptions: null,
    currency: null,
    expiredText: null,
    favorite: false,
    fetching: false,
    // TODO (a11y)
    // franchise: null,
    hideChevron: false,
    icon: null,
    image: null,
    isExpired: null,
    onClick: () => {},
    overdraftEnabled: null,
    overdueStatusLoan: "",
    productType: null,
    // TODO (a11y)
    // productTypeTitle: null,
    rate: "",
    setFavoriteAccount: () => {},
    showProductIcon: true,
    showStar: false,
    showStatusIcon: false,
    status: null,
    title: null,
    kuaraProduct: false,
    wallyProduct: false,
};

const mapStateToProps = (state) => ({
    fetching: productsSelectors.isFeatchingProduct(state),
});

export default connect(mapStateToProps)(ListItemDefault);

import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Captcha from "pages/_components/fields/credentials/Captcha";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelector } from "reducers/settings";

import { compose } from "redux";
import * as i18n from "util/i18n";
import { startSensorDataCapture } from "util/monitor/monitorDbf.utils";
import * as Yup from "yup";

const FORM_ID = "settings.changeEmail";
class ChangeEmailConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        showCaptcha: bool.isRequired,
    };

    componentDidMount() {
        startSensorDataCapture();
    }

    render() {
        const { isDesktop, isSubmitting, showCaptcha } = this.props;
        const conector = !i18n.getLang() === "es" ? "or" : "o";

        return (
            <>
                <Notification scopeToShow="/settings/changeEmail/confirmation" />
                <Head title="settings.changeEmail" backLinkTo="/settings/changeEmail" />
                <MainContainer>
                    <Notification scopeToShow="changeEmail" />
                    <Form className="full-height" noValidate="novalidate">
                        <Box display="flex" column fullWidth fullHeight>
                            <Box
                                background="white"
                                className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8", {
                                    "mx-n-5 full-height": !isDesktop,
                                })}
                                borderRadius="xl">
                                <Row fullHeight>
                                    <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                        <Box display="flex" column alignX="between" fullHeight>
                                            <Box display="flex" column fullWidth>
                                                <Text
                                                    size="5"
                                                    color="heading"
                                                    component="p"
                                                    className="m-0 mb-8 mb-md-10"
                                                    align="center"
                                                    labelKey="settings.changeEmail.code.hasBeenSent"
                                                    {...(!isDesktop && { align: "center" })}
                                                />
                                                <Box display="flex" alignX="center" className="m-0 mb-md-10">
                                                    <span className="color-heading size-5 align-center">
                                                        {i18n.get("settings.changeEmail.code.didNotReceive")}
                                                        <Button
                                                            href="/settings/changeEmail"
                                                            className="btn-no-min-height pl-2 py-0 pr-0"
                                                            bsStyle="link"
                                                            secondary
                                                            bold
                                                            paragraph
                                                            label="settings.changeEmail.tryAgain"
                                                        />
                                                    </span>
                                                </Box>
                                            </Box>

                                            <Field
                                                idForm={FORM_ID}
                                                autoComplete="off"
                                                component={TextField}
                                                hidePlaceholder
                                                maxLength={50}
                                                labelNoMarginTop
                                                name="mailCode"
                                                autoFocus={isDesktop}
                                                className="mb-md-11 remove-flex"
                                            />

                                            {showCaptcha && (
                                                <Box display="flex" alignX="center" fullWidth className="mb-8">
                                                    <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                                </Box>
                                            )}

                                            <Box display="flex" column alignX="flex-end">
                                                <Text
                                                    color="text"
                                                    size={isDesktop ? "7" : "6"}
                                                    align="center"
                                                    className="mb-3"
                                                    labelKey="settings.changeEmail.itWasNotReceived"
                                                />

                                                <Box
                                                    display="flex"
                                                    {...(!isDesktop && { column: true })}
                                                    alignX="center"
                                                    alignY="center"
                                                    wrap
                                                    fullWidth>
                                                    <span
                                                        className={classNames("color-text  align-center mx-2 mb-3", {
                                                            "size-7": isDesktop,
                                                            "size-6": !isDesktop,
                                                        })}>
                                                        <Text labelKey="settings.changeEmail.channels.whatsapp" />
                                                        <Button
                                                            href=""
                                                            className="btn-no-min-height p-2"
                                                            bsStyle="link"
                                                            primaryLink
                                                            xs
                                                            label="settings.changeEmail.channels.whatsapp.link"
                                                        />
                                                    </span>
                                                    {isDesktop && (
                                                        <span
                                                            className={classNames("color-text  align-center mb-3", {
                                                                "size-7": isDesktop,
                                                                "size-6": !isDesktop,
                                                            })}>
                                                            {conector}
                                                        </span>
                                                    )}
                                                    <span
                                                        className={classNames("color-text  align-center mx-2 mb-3", {
                                                            "size-7": isDesktop,
                                                            "size-6": !isDesktop,
                                                        })}>
                                                        <Button
                                                            href=""
                                                            className="btn-no-min-height p-2"
                                                            bsStyle="link"
                                                            primaryLink
                                                            xs
                                                            label="settings.changeEmail.channels.chat.link"
                                                        />
                                                    </span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                            <Box {...(!isDesktop && { className: "mt-auto" })}>
                                <Row className="justify-content-center">
                                    <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            block
                                        />
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    showCaptcha: settingsSelector.getShowCaptcha(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mailCode: "",
            captcha: "",
        }),
        validationSchema: ({ showCaptcha }) =>
            Yup.object().shape({
                mailCode: Yup.string().required(i18n.get(`${FORM_ID}.code.required`)),
                captcha: showCaptcha ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`)) : Yup.string(),
            }),
        handleSubmit: ({ mailCode, captcha }, formikBag) => {
            formikBag.props.dispatch(settingsActions.updateMail(mailCode, captcha, formikBag));
        },
    }),
)(ChangeEmailConfirmation);

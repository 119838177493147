//-----------------------------------------------------
// *** DESCOMENTAR TODO PARA VOLVER A VER EL WIDGET****
//-----------------------------------------------------

import Box from "pages/_components/Box";
import FastRechargeSlide from "pages/_components/FastRechargeCarousel/FastRechargeSlide";
import { func, shape } from "prop-types";
import React from "react";
import Slider from "react-slick";

const FastRechargeCarousel = ({ sliderList, onClick }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        arrows: false,
    };
    return (
        <Box className="fast-recharge-slide">
            <Slider {...settings}>
                {sliderList.map((service) => (
                    <FastRechargeSlide
                        key={service.id}
                        id={service.id}
                        title={service.nameBiller}
                        label={service.alias}
                        serialNumber={service.idBill}
                        quantity={service.amountQuantity}
                        currency={service.amountCurrency}
                        onClick={() => onClick(service)}
                    />
                ))}
            </Slider>
        </Box>
    );
};

FastRechargeCarousel.propTypes = {
    sliderList: shape({}).isRequired,
    onClick: func.isRequired,
};

export default FastRechargeCarousel;

//-----------------------------------------------------
// *** DESCOMENTAR TODO PARA VOLVER A VER EL WIDGET****
//-----------------------------------------------------

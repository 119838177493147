import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AmountFilter from "pages/accounts/_components/AmountFilter";
import ChannelFilter from "pages/accounts/_components/ChannelFilter";
import CheckFilter from "pages/accounts/_components/CheckFilter";
import PeriodFilter from "pages/accounts/_components/PeriodFilter";
import ReferenceFilter from "pages/accounts/_components/ReferenceFilter";
import YearAndMonthFilter from "pages/accounts/_components/YearAndMonthFilter";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, func, instanceOf, shape, string } from "prop-types";
import React, { Component, createElement } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import * as i18n from "util/i18n";
import { selectors as accountsSelectors } from "reducers/accounts";

const components = {
    period: PeriodFilter,
    yearAndMonth: YearAndMonthFilter,
    amount: AmountFilter,
    reference: ReferenceFilter,
    channel: ChannelFilter,
    check: CheckFilter,
};

class MoreFilters extends Component {
    state = {
        selectedFilter: "period",
    };

    allOptions = [
        {
            value: "period",
            label: i18n.get("accounts.movements.filters.searchBy.period"),
        },
        {
            value: "amount",
            label: i18n.get("accounts.movements.filters.searchBy.amount"),
        },
        {
            value: "reference",
            label: i18n.get("accounts.movements.filters.searchBy.reference"),
        },
        {
            value: "channel",
            label: i18n.get("accounts.movements.filters.searchBy.channels"),
        },
        {
            value: "check",
            label: i18n.get("accounts.movements.filters.searchBy.check"),
        },
    ];

    componentDidMount() {
        const { advancedFilter } = this.props;
        const { filterType } = advancedFilter;

        const { selectedFilter } = this.state;

        if (filterType && filterType !== selectedFilter) {
            this.setState({ selectedFilter: filterType });
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;
        const { channels, currency, dispatch, productId, minDate, advancedFilter } = this.props;

        let props = { dispatch, isDesktop: true, productId };
        if (selectedFilter === "period") {
            props = { ...props, minDateFrom: minDate, minDateTo: minDate, advancedFilter };
        }

        if (selectedFilter === "yearAndMonth") {
            props = { ...props, minDate, advancedFilter };
        }

        if (selectedFilter === "channel") {
            props = { ...props, channels };
        }

        if (selectedFilter === "amount") {
            props = { ...props, currency, advancedFilter };
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter } = this.state;
        const { filterOptions } = this.props;

        const options = filterOptions || this.allOptions;

        return (
            <>
                <Row gapY="3">
                    <Col xs={12} md={4} className="pl-md-5 pl-lg-7">
                        <Box className="form-group form-group--select">
                            <Box className="form-group-text">
                                <Text
                                    component="label"
                                    labelKey="accounts.movements.filters.searchBy"
                                    className="control-label"
                                    htmlFor="react-select-moreFilter-input"
                                />
                            </Box>
                            <Box className="input-group ">
                                <Box flex1>
                                    <Select
                                        id="moreFilter"
                                        clearable={false}
                                        searchable={false}
                                        label="accounts.movements.filters.searchBy"
                                        onChange={this.handleChange}
                                        options={options}
                                        value={selectedFilter}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Col>
                    {this.renderFilter()}
                </Row>
            </>
        );
    }
}

MoreFilters.propTypes = {
    dispatch: func.isRequired,
    channels: arrayOf(string).isRequired,
    currency: string.isRequired,
    productId: string.isRequired,
    filterOptions: arrayOf(shape({})),
    minDate: instanceOf(Date),
    advancedFilter: arrayOf(shape({})),
};

MoreFilters.defaultProps = {
    filterOptions: null,
    minDate: null,
    advancedFilter: null,
};

const mapStateToProps = (state) => ({
    advancedFilter: accountsSelectors.getFilters(state),
});

export default connect(mapStateToProps)(MoreFilters);

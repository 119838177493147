import { createReducer, makeActionCreator } from "util/redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

export const types = {
    SHOW_UPDATE_COMPANY_DATA_STEP_ZERO: "updateCompanyData/SHOW_UPDATE_COMPANY_DATA_STEP_ZERO",
    HIDE_UPDATE_COMPANY_DATA_STEP_ZERO: "updateCompanyData/HIDE_UPDATE_COMPANY_DATA_STEP_ZERO",

    UPDATE_COMPANY_DATA_SEND_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_SEND_REQUEST",
    UPDATE_COMPANY_DATA_SEND_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_SEND_SUCCESS",
    UPDATE_COMPANY_DATA_SEND_FAILURE: "updateCompanyData/UPDATE_COMPANY_DATA_SEND_FAILURE",

    GET_CREDENTIAL_GROUPS_REQUEST: "updateCompanyData/GET_CREDENTIAL_GROUPS_REQUEST",
    GET_CREDENTIAL_GROUPS_SUCCESS: "updateCompanyData/GET_CREDENTIAL_GROUPS_SUCCESS",
    GET_CREDENTIAL_GROUPS_FAILURE: "updateCompanyData/GET_CREDENTIAL_GROUPS_FAILURE",

    UPDATE_COMPANY_DATA_STEP1_PRE_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_STEP1_PRE_REQUEST",
    UPDATE_COMPANY_DATA_STEP1_PRE_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_STEP1_PRE_SUCCESS",

    UPDATE_COMPANY_DATA_STEP2_PRE_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_STEP2_PRE_REQUEST",
    UPDATE_COMPANY_DATA_STEP2_PRE_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_STEP2_PRE_SUCCESS",

    UPDATE_COMPANY_DATA_STEP3_PRE_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_STEP3_PRE_REQUEST",
    UPDATE_COMPANY_DATA_STEP3_PRE_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_STEP3_PRE_SUCCESS",

    UPDATE_COMPANY_DATA_STEP4_PRE_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_STEP4_PRE_REQUEST",
    UPDATE_COMPANY_DATA_STEP4_PRE_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_STEP4_PRE_SUCCESS",

    UPDATE_COMPANY_DATA_STEP5_PRE_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_STEP5_PRE_REQUEST",
    UPDATE_COMPANY_DATA_STEP5_PRE_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_STEP5_PRE_SUCCESS",

    UPDATE_COMPANY_DATA_STEP6_PRE_REQUEST: "updateCompanyData/UPDATE_COMPANY_DATA_STEP6_PRE_REQUEST",
    UPDATE_COMPANY_DATA_STEP6_PRE_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_STEP6_PRE_SUCCESS",

    UPDATE_COMPANY_DATA_FAILURE: "updateCompanyData/UPDATE_COMPANY_DATA_FAILURE",

    UPDATE_COMPANY_DATA_SAVE_FORM: "updateCompanyData/UPDATE_COMPANY_DATA_SAVE_FORM",
    UPDATE_COMPANY_DATA_SAVE_FORM_SUCCESS: "updateCompanyData/UPDATE_COMPANY_DATA_SAVE_FORM_SUCCESS",
    UPDATE_COMPANY_DATA_SAVE_FORM_FAILURE: "updateCompanyData/UPDATE_COMPANY_DATA_SAVE_FORM_FAILURE",

    UPDATE_COMPANY_FAILURE: "updateCompanyData/UPDATE_COMPANY_FAILURE",

    ENABLE_STEP_1: "updateCompanyData/ENABLE_STEP_1",
    ENABLE_STEP_2: "updateCompanyData/ENABLE_STEP_2",
    ENABLE_STEP_3: "updateCompanyData/ENABLE_STEP_3",
    ENABLE_STEP_4: "updateCompanyData/ENABLE_STEP_4",

    GET_PROVINCE_LIST_REQUEST: "updateCompanyData/GET_PROVINCE_LIST_REQUEST",
    GET_PROVINCE_LIST_SUCCESS: "updateCompanyData/GET_PROVINCE_LIST_SUCCESS",
    GET_PROVINCE_LIST_FAILURE: "updateCompanyData/GET_PROVINCE_LIST_FAILURE",

    DISABLE_DISTRICT: "updateCompanyData/DISABLE_DISTRICT",
    GET_DISTRICT_LIST_REQUEST: "updateCompanyData/GET_DISTRICT_LIST_REQUEST",
    GET_DISTRICT_LIST_SUCCESS: "updateCompanyData/GET_DISTRICT_LIST_SUCCESS",
    GET_DISTRICT_LIST_FAILURE: "updateCompanyData/GET_DISTRICT_LIST_FAILURE",

    DISABLE_JURISDICTION: "updateCompanyData/DISABLE_JURISDICTION",
    GET_JURISDICTION_LIST_REQUEST: "updateCompanyData/GET_JURISDICTION_LIST_REQUEST",
    GET_JURISDICTION_LIST_SUCCESS: "updateCompanyData/GET_JURISDICTION_LIST_SUCCESS",
    GET_JURISDICTION_LIST_FAILURE: "updateCompanyData/GET_JURISDICTION_LIST_FAILURE",

    GET_STATE_PROVINCE_LIST_REQUEST: "updateCompanyData/GET_STATE_PROVINCE_LIST_REQUEST",
    GET_STATE_PROVINCE_LIST_SUCCESS: "updateCompanyData/GET_STATE_PROVINCE_LIST_SUCCESS",
    GET_STATE_PROVINCE_LIST_FAILURE: "updateCompanyData/GET_STATE_PROVINCE_LIST_FAILURE",

    GET_CITY_LIST_REQUEST: "updateCompanyData/GET_CITY_LIST_REQUEST",
    GET_CITY_LIST_SUCCESS: "updateCompanyData/GET_CITY_LIST_SUCCESS",
    GET_CITY_LIST_FAILURE: "updateCompanyData/GET_CITY_LIST_FAILURE",

    SELECT_FILE: "updateCompanyData/SELECT_FILE",
    UPDATE_MANDATORY_ATTACHMENT: "updateCompanyData/UPDATE_MANDATORY_ATTACHMENT",
    UPDATE_ADDITIONAL_ATTACHMENT: "updateCompanyData/UPDATE_ADDITIONAL_ATTACHMENT",
    REMOVE_MANDATORY_ATTACHMENT: "updateCompanyData/REMOVE_MANDATORY_ATTACHMENT",

    RESET_LIST_LOCATION: "updateCompanyData/RESET_LIST_LOCATION",
    UPDATE_CORPORATE_INFO: "updateCompanyData/UPDATE_CORPORATE_INFO",
};

export const INITIAL_STATE = {
    exchangeToken: undefined,
    fetching: false,
    credentialGroups: undefined,
    showUpdateCompanyDataStepZero: false,
    preDataStep1: {},
    preDataStep2: {},
    preDataStep3: {},
    preDataStep4: {},
    preDataStep5: {},
    preDataStep6: {},
    isDisabledStep1: true,
    isDisabledStep2: true,
    isDisabledStep3: true,
    isDisabledStep4: true,
    selectedMandatoryAttachments: [],
    selectedAdditionalAttachments: [],
    mandatoryCount: 0,
    additionalCount: 0,
    cityList: null,
    districtList: null,
    provinceList: null,
    jurisdictionList: null,
    dateLastUpdateCorporate: "",
    stateUpdateCorporate: "",
};

export const actions = {
    getCredentialGroupsRequest: makeActionCreator(types.GET_CREDENTIAL_GROUPS_REQUEST, "credential"),
    updateCompanyDataRequest: makeActionCreator(types.UPDATE_COMPANY_DATA_SEND_REQUEST, "otp", "formikBag"),
    showUpdateCompanyDataStepZero: makeActionCreator(types.SHOW_UPDATE_COMPANY_DATA_STEP_ZERO),
    hideUpdateCompanyDataStepZero: makeActionCreator(types.HIDE_UPDATE_COMPANY_DATA_STEP_ZERO),
    preFormStep1: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP1_PRE_REQUEST, "exchangeToken"),
    preFormStep1Success: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP1_PRE_SUCCESS, "preDataStep1"),
    preFormStep2: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP2_PRE_REQUEST, "exchangeToken"),
    preFormStep2Success: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP2_PRE_SUCCESS, "preDataStep2"),
    preFormStep3: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP3_PRE_REQUEST, "exchangeToken"),
    preFormStep3Success: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP3_PRE_SUCCESS, "preDataStep3"),
    preFormStep4: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP4_PRE_REQUEST, "exchangeToken"),
    preFormStep4Success: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP4_PRE_SUCCESS, "preDataStep4"),
    preFormStep5: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP5_PRE_REQUEST, "exchangeToken"),
    preFormStep5Success: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP5_PRE_SUCCESS, "preDataStep5"),
    preFormStep6: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP6_PRE_REQUEST, "exchangeToken"),
    preFormStep6Success: makeActionCreator(types.UPDATE_COMPANY_DATA_STEP6_PRE_SUCCESS, "preDataStep6"),
    requestFailure: makeActionCreator(types.UPDATE_COMPANY_DATA_FAILURE),
    enableStep1: makeActionCreator(types.ENABLE_STEP_1, "isDisabledStep1"),
    enableStep2: makeActionCreator(types.ENABLE_STEP_2, "isDisabledStep2"),
    enableStep3: makeActionCreator(types.ENABLE_STEP_3, "isDisabledStep3"),
    enableStep4: makeActionCreator(types.ENABLE_STEP_4, "isDisabledStep4"),
    saveForm: makeActionCreator(types.UPDATE_COMPANY_DATA_SAVE_FORM, "values", "step", "formikBag"),
    saveFormSuccess: makeActionCreator(types.UPDATE_COMPANY_DATA_SAVE_FORM_SUCCESS),
    saveFormFailure: makeActionCreator(types.UPDATE_COMPANY_DATA_SAVE_FORM_FAILURE),
    updateCompanyFailure: makeActionCreator(types.UPDATE_COMPANY_FAILURE),

    getProvinceListRequest: makeActionCreator(types.GET_PROVINCE_LIST_REQUEST, "countryCode"),
    getProvinceListSuccess: makeActionCreator(types.GET_PROVINCE_LIST_SUCCESS, "provinceList"),
    getProvinceListFailure: makeActionCreator(types.GET_PROVINCE_LIST_FAILURE),

    disableDistrict: makeActionCreator(types.DISABLE_DISTRICT, "disableDistrict"),
    getDistrictListRequest: makeActionCreator(types.GET_DISTRICT_LIST_REQUEST, "provinceCode"),
    getDistrictListSuccess: makeActionCreator(types.GET_DISTRICT_LIST_SUCCESS, "districtByProvinceList"),
    getDistrictListFailure: makeActionCreator(types.GET_DISTRICT_LIST_FAILURE),

    disableJurisdiction: makeActionCreator(types.DISABLE_JURISDICTION, "disableJurisdiction"),
    getJurisdictionListRequest: makeActionCreator(types.GET_JURISDICTION_LIST_REQUEST, "districtCode"),
    getJurisdictionListSuccess: makeActionCreator(types.GET_JURISDICTION_LIST_SUCCESS, "jurisdictionList"),
    getJurisdictionListFailure: makeActionCreator(types.GET_JURISDICTION_LIST_FAILURE),

    getCityListRequest: makeActionCreator(types.GET_CITY_LIST_REQUEST, "stateProvinceCode"),
    getCityListSuccess: makeActionCreator(types.GET_CITY_LIST_SUCCESS, "cityByStateProvinceList"),
    getCityListFailure: makeActionCreator(types.GET_CITY_LIST_FAILURE),

    selectFile: makeActionCreator(types.SELECT_FILE, "file", "isMandatory", "internalIndex"),
    updateMandatoryAttachments: makeActionCreator(types.UPDATE_MANDATORY_ATTACHMENT, "files"),
    updateAdditionalAttachments: makeActionCreator(types.UPDATE_ADDITIONAL_ATTACHMENT, "files"),

    resetListLocation: makeActionCreator(types.RESET_LIST_LOCATION),
    updateCorporateInfo: makeActionCreator(
        types.UPDATE_CORPORATE_INFO,
        "dateLastUpdateCorporate",
        "stateUpdateCorporate",
    ),
};

export const selectors = {
    isFetching: ({ updateCompanyData }) => updateCompanyData?.fetching,
    getCredentialGroups: ({ updateCompanyData }) => updateCompanyData?.credentialGroups || [],
    isShowUpdateCompanyDataStepZero: ({ updateCompanyData }) => updateCompanyData?.showUpdateCompanyDataStepZero,
    getExchangeToken: ({ updateCompanyData }) => updateCompanyData?.exchangeToken,
    getPreDataStep1: ({ updateCompanyData }) => updateCompanyData?.preDataStep1,
    getPreDataStep2: ({ updateCompanyData }) => updateCompanyData?.preDataStep2,
    getPreDataStep3: ({ updateCompanyData }) => updateCompanyData?.preDataStep3,
    getPreDataStep4: ({ updateCompanyData }) => updateCompanyData?.preDataStep4,
    getPreDataStep5: ({ updateCompanyData }) => updateCompanyData?.preDataStep5,
    getPreDataStep6: ({ updateCompanyData }) => updateCompanyData?.preDataStep6,
    isDisabledStep1: ({ updateCompanyData }) => updateCompanyData?.isDisabledStep1,
    isDisabledStep2: ({ updateCompanyData }) => updateCompanyData?.isDisabledStep2,
    isDisabledStep3: ({ updateCompanyData }) => updateCompanyData?.isDisabledStep3,
    isDisabledStep4: ({ updateCompanyData }) => updateCompanyData?.isDisabledStep4,

    getDistrictList: ({ updateCompanyData }) => updateCompanyData?.districtList,
    getJurisdictionList: ({ updateCompanyData }) => updateCompanyData?.jurisdictionList,
    getProvinceList: ({ updateCompanyData }) => updateCompanyData?.provinceList,
    getCityList: ({ updateCompanyData }) => updateCompanyData?.cityList,

    getSelectedMandatoryAttachments: ({ updateCompanyData }) => updateCompanyData?.selectedMandatoryAttachments,
    getSelectedAdditionalAttachments: ({ updateCompanyData }) => updateCompanyData?.selectedAdditionalAttachments,

    getMandatoryCount: ({ updateCompanyData }) => updateCompanyData?.mandatoryCount,
    getAdditionalCount: ({ updateCompanyData }) => updateCompanyData?.additionalCount,
    getDateLastUpdateCorporate: ({ updateCompanyData }) => updateCompanyData.dateLastUpdateCorporate,
    getStateUpdateCorporate: ({ updateCompanyData }) => updateCompanyData.stateUpdateCorporate,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.SHOW_UPDATE_COMPANY_DATA_STEP_ZERO]: (state) => ({
        ...state,
        showUpdateCompanyDataStepZero: true,
    }),
    [types.HIDE_UPDATE_COMPANY_DATA_STEP_ZERO]: (state) => ({
        ...state,
        showUpdateCompanyDataStepZero: false,
    }),
    [types.UPDATE_COMPANY_DATA_SEND_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        isDisabledStep1: true,
        isDisabledStep2: true,
        isDisabledStep3: true,
        isDisabledStep4: true,
    }),
    [types.UPDATE_COMPANY_DATA_SEND_SUCCESS]: (state, action) => ({
        ...state,
        // Se deja el fetching en true por la carga de la página ya que sino hace un flash en la carga en web y mobile
        // (luego se hace otro llamado al step1 que setea en false)
        fetching: true,
        exchangeToken: action?.data?.exchangeToken,
        showUpdateCompanyDataStepZero: false,
    }),
    [types.UPDATE_COMPANY_DATA_SEND_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.UPDATE_COMPANY_FAILURE]: (state) => ({
        ...INITIAL_STATE,
        dateLastUpdateCorporate: state.dateLastUpdateCorporate,
        stateUpdateCorporate: state.stateUpdateCorporate,
    }),
    [types.GET_CREDENTIAL_GROUPS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_CREDENTIAL_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        credentialGroups: action?.credentialsGroups,
        stateUpdateCorporate: action?.stateUpdateCorporate ? action.stateUpdateCorporate : state.stateUpdateCorporate,
    }),
    [types.GET_CREDENTIAL_GROUPS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        credentialGroups: undefined,
    }),
    [types.UPDATE_COMPANY_DATA_STEP1_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_COMPANY_DATA_STEP1_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep1: action.preDataStep1,
    }),
    [types.UPDATE_COMPANY_DATA_STEP2_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_COMPANY_DATA_STEP2_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep2: action.preDataStep2,
    }),
    [types.UPDATE_COMPANY_DATA_STEP3_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_COMPANY_DATA_STEP3_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep3: action.preDataStep3,
    }),
    [types.UPDATE_COMPANY_DATA_STEP4_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_COMPANY_DATA_STEP4_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep4: action.preDataStep4,
    }),
    [types.UPDATE_COMPANY_DATA_STEP5_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_COMPANY_DATA_STEP5_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep5: action.preDataStep5,
    }),
    [types.UPDATE_COMPANY_DATA_STEP6_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.UPDATE_COMPANY_DATA_STEP6_PRE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        preDataStep6: action.preDataStep6,
    }),
    [types.UPDATE_COMPANY_DATA_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        isDisabledStep1: true,
        isDisabledStep2: true,
        isDisabledStep3: true,
        isDisabledStep4: true,
    }),
    [types.ENABLE_STEP_1]: (state, action) => ({
        ...state,
        isDisabledStep1: action.isDisabledStep1,
    }),
    [types.ENABLE_STEP_2]: (state, action) => ({
        ...state,
        isDisabledStep2: action.isDisabledStep2,
        isDisabledCity: action.isDisabledStep2,
    }),
    [types.ENABLE_STEP_3]: (state, action) => ({
        ...state,
        isDisabledStep3: action.isDisabledStep3,
        isDisabledCity: action.isDisabledStep3,
    }),
    [types.ENABLE_STEP_4]: (state, action) => ({
        ...state,
        isDisabledStep4: action.isDisabledStep4,
    }),
    [types.UPDATE_COMPANY_DATA_SAVE_FORM]: (state) => ({
        ...state,
    }),
    [types.UPDATE_COMPANY_DATA_SAVE_FORM_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.UPDATE_COMPANY_DATA_SAVE_FORM_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.GET_PROVINCE_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.GET_PROVINCE_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        provinceList: action.provinceList,
    }),
    [types.GET_PROVINCE_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.GET_DISTRICT_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.GET_DISTRICT_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        districtList: action.districtByProvinceList,
    }),
    [types.GET_DISTRICT_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.GET_JURISDICTION_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.GET_JURISDICTION_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        jurisdictionList: action.jurisdictionList,
    }),
    [types.GET_JURISDICTION_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.GET_CITY_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.GET_CITY_LIST_SUCCESS]: (state, action) => ({
        ...state,
        cityList: action.cityByStateProvinceList,
        isFetching: false,
    }),
    [types.GET_CITY_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),

    [types.SELECT_FILE]: (state) => ({
        ...state,
        isLoading: true,
    }),
    [types.UPDATE_MANDATORY_ATTACHMENT]: (state, action) => ({
        ...state,
        isLoading: false,
        selectedMandatoryAttachments: action.files,
        mandatoryCount: state.mandatoryCount + 1,
    }),
    [types.UPDATE_ADDITIONAL_ATTACHMENT]: (state, action) => ({
        ...state,
        isLoading: false,
        selectedAdditionalAttachments: action.files,
        additionalCount: state.additionalCount + 1,
    }),
    [types.RESET_LIST_LOCATION]: (state) => ({
        ...state,
        cityList: null,
        districtList: null,
        provinceList: null,
        jurisdictionList: null,
    }),
    [types.UPDATE_CORPORATE_INFO]: (state, action) => ({
        ...state,
        dateLastUpdateCorporate: action.dateLastUpdateCorporate,
        stateUpdateCorporate: action.stateUpdateCorporate,
    }),
});

export default persistReducer(
    {
        key: "updateCompanyData",
        storage: storageSession,
        blacklist: ["fetching", "exchangeToken", "showUpdateCompanyDataStepZero"],
    },
    reducer,
);

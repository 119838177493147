export const checkIfOnlyOneNumber = (documentCountry, documentType) =>
    (documentCountry === "VE" && documentType === "CED") || documentCountry === "PA";

export const chaekIfCipPa = (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP";
export const chaekIfCrpPa = (documentCountry, documentType) => documentCountry === "PA" && documentType === "CRP";

export const generateIdentificationToShow = (documentUser) => {
    if (documentUser) {
        const { documentCountry, documentType, idNumber1, idNumber2, documentNumber1, documentNumber2 } = documentUser;
        const chaekifCipPa = chaekIfCipPa(documentCountry, documentType);
        const chaekifCrpPa = chaekIfCrpPa(documentCountry, documentType);
        const checkifOnlyOneNumber = checkIfOnlyOneNumber(documentCountry, documentType);

        const documentNumberPart1 = documentCountry === "PA" ? `${documentNumber1.padStart(5, "0")}` : documentNumber1;
        const documentNumberPart2 =
            documentCountry === "PA" && documentType === "CIP"
                ? `${documentNumber2.padStart(6, "0")}`
                : documentNumber2;

        return `${idNumber1}${chaekifCrpPa ? "-" : ""}${chaekifCipPa ? " " : ""}${idNumber2}${
            checkifOnlyOneNumber ? "-" : ""
        }${documentNumberPart1}${chaekifCipPa ? "-" : ""}${documentNumberPart2}`;
    }
    return "";
};

export const generateIdentificationPanamena = (documentUser, shouldFillWithZeros) => {
    const { documentType, idNumber1, idNumber2, documentNumber1, documentNumber2 } = documentUser;
    let idNumberPart1 = "";
    let idNumberPart2 = "";
    let documentNumberPart1 = "";
    let documentNumberPart2 = "";
    let documentUserShouldFillWithZeros = { ...documentUser };
    // CIP cedula de identidad Panamena
    if (documentType === "CIP") {
        idNumberPart1 = idNumber2;
        idNumberPart2 = idNumber1 === "PAN" ? "" : idNumber1;
        documentNumberPart1 = documentNumber1.padStart(5, "0");
        documentNumberPart2 = documentNumber2.padStart(6, "0");
        if (shouldFillWithZeros) {
            documentUserShouldFillWithZeros = {
                ...documentUserShouldFillWithZeros,
                documentNumber1: documentNumberPart1,
                documentNumber2: documentNumberPart2,
            };
        }
    }

    // CRP Cédula Extranjera
    if (documentType === "CRP") {
        idNumberPart1 = "00";
        idNumberPart2 = idNumber1;
        documentNumberPart1 = idNumber2.padStart(5, "0");
        documentNumberPart2 = documentNumber1.padStart(6, "0");
        if (shouldFillWithZeros) {
            documentUserShouldFillWithZeros = {
                ...documentUserShouldFillWithZeros,
                idNumber2: documentNumberPart1,
                documentNumber1: documentNumberPart2,
            };
        }
    }

    return shouldFillWithZeros
        ? documentUserShouldFillWithZeros
        : `${idNumberPart1}-${idNumberPart2}-${documentNumberPart1}-${documentNumberPart2}`;
};

export const generateIdentificationVenezolana = (documentUser, shouldFillWithZeros) => {
    const { documentType, idNumber1, documentNumber1 } = documentUser;
    let idetificationNumber = "";
    let documentUserShouldFillWithZeros = { ...documentUser };
    if (documentType === "PAS") {
        idetificationNumber = documentNumber1;
    } else if (documentType === "CED") {
        let documentNumberPart1 = "";
        if (idNumber1 === "P") {
            documentNumberPart1 = documentNumber1.padStart(15, "0");
            idetificationNumber = `${idNumber1}${documentNumberPart1}`;
        } else {
            documentNumberPart1 = documentNumber1.padStart(9, "0");
            idetificationNumber = `${idNumber1}${documentNumberPart1}`;
        }
        if (shouldFillWithZeros) {
            documentUserShouldFillWithZeros = {
                ...documentUserShouldFillWithZeros,
                documentNumber1: documentNumberPart1,
            };
        }
    }
    return shouldFillWithZeros ? documentUserShouldFillWithZeros : idetificationNumber;
};

export const generateIdentificationToSend = (documentUser, shouldFillWithZeros) => {
    switch (documentUser?.documentCountry) {
        case "PA":
            return generateIdentificationPanamena(documentUser, shouldFillWithZeros);
        case "VE":
            return generateIdentificationVenezolana(documentUser, shouldFillWithZeros);
        default:
            return shouldFillWithZeros ? { ...documentUser } : documentUser?.documentNumber1 || "";
    }
};

export const sizeImputDocument1 = (documentCountry, documentType, idNumberStyle2) => {
    if ((documentCountry !== "PA" && documentCountry !== "VE") || documentType === "PAS") {
        return 12;
    }
    if (
        (documentCountry === "PA" && documentType !== "CIP" && idNumberStyle2) ||
        (documentCountry === "VE" && idNumberStyle2)
    ) {
        return 8;
    }
    if (
        documentType === "CRP" ||
        documentType === "CED" ||
        (documentCountry === "PA" && documentType !== "CIP" && !idNumberStyle2) ||
        (documentCountry === "VE" && !idNumberStyle2)
    ) {
        return 6;
    }
    if (idNumberStyle2) {
        return 4;
    }
    return 3;
};

export const placeholderInputDocument1 = (documentCountry, documentType, idNumber1) => {
    if (documentCountry === "PA" && documentType === "CIP") {
        return "00000";
    }
    if (documentCountry === "PA" && documentType === "CRP") {
        return "000000";
    }
    if (documentCountry === "VE" && documentType === "CED") {
        if (idNumber1 === "P") {
            return "000000000000000";
        }
        return "000000000";
    }
    return "";
};

export const maxlengthInputDocument1 = (documentCountry, documentType, idNumber1) => {
    if (documentCountry === "PA" && documentType === "CIP") {
        return 5;
    }
    if (documentCountry === "PA" && documentType === "CRP") {
        return 6;
    }
    if (documentCountry === "VE" && documentType === "CED" && idNumber1 !== "P") {
        return 9;
    }
    return 15;
};

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";

class LanguageSelectionLink extends Component {
    static propTypes = {
        activeLanguage: string,
        disabled: bool,
    };

    static defaultProps = {
        activeLanguage: "en",
        disabled: false,
    };

    handleClick = (event) => {
        const { disabled } = this.props;

        if (disabled) {
            event.preventDefault();
        }
    };

    render() {
        const { activeLanguage } = this.props;
        const a11yMessage1 = i18n.get("settings.changeLanguage.link.a11y");
        const a11yMessage2 = i18n.get(`settings.changeLanguage.label.${activeLanguage}.a11y`);
        if (activeLanguage) {
            return (
                <Button bsStyle="link" href="/languageSelection">
                    <Box display="flex" alignY="center" gap="2" className="px-3">
                        <Image wrapperClassName="mr-0" src={`images/${activeLanguage}-flag.svg`} />
                        <Text size="6" ariaHidden="true" regular>{`${activeLanguage.toUpperCase()}`}</Text>
                        <Text className="visually-hidden">{`${a11yMessage1}, ${a11yMessage2}`}</Text>
                    </Box>
                </Button>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(LanguageSelectionLink);

import classNames from "classnames";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import InternationalTransferTrackingModal from "pages/InternationalTransferTracking/InternationalTransferTrackingModal";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { SWIFT_RECEIVED_ACCOUNT_MOVEMENT_CODE } from "util/account";
import { getLocationBasePath } from "util/revelock";
import { insertLinebreaks } from "util/string";
import { TRANSFER_SUPPORT_REFUND_RECEIVED_TYPE } from "util/transferSupport.util";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const PAGE_IDENTIFIER = "accounts.movement";
class MovementDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        movement: shape({
            idAccount: string,
            idStatement: number,
            note: string,
        }).isRequired,
        account: shape({
            currency: string,
        }).isRequired,
        isDesktop: bool,
        setLocationCustomPath: func,
        match: shape().isRequired,
        showModalTracking: bool,
    };

    static defaultProps = {
        isDesktop: null,
        setLocationCustomPath: () => {},
        showModalTracking: false,
    };

    state = {
        note: {
            value: "",
            isEditing: true,
        },
        saving: false,
        isEditAvailable: false,
    };

    componentDidMount() {
        const { movement } = this.props;

        const hasNote = movement && movement.note;
        this.setState({ note: { value: hasNote || "", isEditing: !hasNote } });
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleChange = (event) => {
        const { value } = event.target;

        this.setState({ note: { value, isEditing: true } });
    };

    handleClick = () => {
        const { note } = this.state;
        let { value } = note;

        value = value.replace(/\n+/g, "\n");

        const { dispatch, movement } = this.props;
        const { idAccount, idStatement } = movement;

        this.setState({
            saving: true,
        });

        dispatch(accountsActions.editMovementNote(idAccount, idStatement, insertLinebreaks(value), this.onFinish));
    };

    onFinish = () => {
        const {
            note: { value },
        } = this.state;
        this.setState({ saving: false, note: { isEditing: false, value } });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.closeOptions());
        dispatch(goBack());
    };

    isSwiftReceivedTransfer = () => {
        const { movement } = this.props;
        return (
            movement?.transactionType &&
            movement.transactionType === SWIFT_RECEIVED_ACCOUNT_MOVEMENT_CODE &&
            movement?.reference
        );
    };

    callbackSuccessRequest = (procedureNUmber) => {
        const { dispatch } = this.props;
        this.setState({ showModalTracking: false });
        dispatch(
            notificationActions.showNotification(
                `${i18n.get("transfers.foreign.support.request.success.message")} ${procedureNUmber || ""}`,
                "success",
                ["movementDetail"],
                false,
            ),
        );
    };

    render() {
        const {
            note: { value },
            // eslint-disable-next-line no-unused-vars
            saving,
            isEditAvailable,
        } = this.state;

        const { account, movement, isDesktop } = this.props;
        const isCheck = movement && movement.check && movement.check !== 0;

        return (
            movement && (
                <>
                    <Head
                        onBack={this.handleBack}
                        title="accounts.movement.detail.title"
                        {...(this.isSwiftReceivedTransfer() && {
                            actionButton: () => {
                                this.setState({ showModalTracking: true });
                            },
                            actionButtonLabel: "Devolver fondos",
                            actionButtonBsStyle: "primary",
                        })}
                    />
                    <Notification scopeToShow="movementDetail" />

                    <MainContainer>
                        <Box display="flex" column gap="8">
                            <Box
                                background="white"
                                borderRadius="default"
                                className="p-5 mt-5 mt-md-0 pb-7 pb-md-0 mx-n-5">
                                <form className="above-the-fold pb-0 pb-md-8">
                                    <Row>
                                        <Col xs={12} md={10} mdOffset={1}>
                                            <Row alignY="center">
                                                <Col xs={12}>
                                                    <Box
                                                        border="bottom-background-divider-1"
                                                        className="pb-5 pt-md-5 pb-md-7">
                                                        <Text
                                                            bold
                                                            size={isDesktop ? "5" : "3"}
                                                            color="heading"
                                                            labelKey="creditCards.movement.detail.date"
                                                        />
                                                        <Text size={isDesktop ? "5" : "3"} color="heading">
                                                            {": "}
                                                            <FormattedDate date={movement?.date} />
                                                        </Text>
                                                    </Box>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Box component="ul" className="data-list pt-0 pt-md-7">
                                                        <Box component="li" className="mb-3">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="creditCards.movement.detail.dateprocess"
                                                            />
                                                            <Text size={isDesktop ? "5" : "3"} color="heading">
                                                                {": "}
                                                                <FormattedDate date={movement?.valueDate} />
                                                            </Text>
                                                        </Box>
                                                        <Box component="li" className="mb-3">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="creditCards.movement.detail.description"
                                                            />
                                                            <Text size={isDesktop ? "5" : "3"} color="heading">
                                                                {": "}
                                                                {movement?.concept}
                                                            </Text>
                                                        </Box>
                                                        {Boolean(isCheck) && (
                                                            <Box component="li" className="mb-3">
                                                                <Text
                                                                    bold
                                                                    size={isDesktop ? "5" : "3"}
                                                                    color="heading"
                                                                    labelKey="accounts.movement.detail.check"
                                                                />
                                                                <Text size={isDesktop ? "5" : "3"} color="heading">
                                                                    {": "}
                                                                    {movement?.check}
                                                                </Text>
                                                            </Box>
                                                        )}
                                                        <Box display="flex" component="li">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="creditCards.movement.detail.amount"
                                                            />
                                                            <FormattedAmount
                                                                className="fit-content"
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                currency={`: ${account?.currency}`}
                                                                quantity={movement?.credit || movement?.debit}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Col>

                                                <Col xs={12} md={6} className="full-height">
                                                    <Box display="flex" column fullHeight className="form-group">
                                                        <label htmlFor="movement.detail" className="control-label mb-0">
                                                            <Text
                                                                bold
                                                                size={isDesktop ? "5" : "3"}
                                                                color="heading"
                                                                labelKey="accounts.movement.detail.notes"
                                                                className="data-label-special-mb"
                                                            />
                                                        </label>
                                                        <Box fullHeight className="input-group">
                                                            <textarea
                                                                id="movement.detail"
                                                                {...(!isEditAvailable && {
                                                                    tabIndex: "-1",
                                                                    readOnly: true,
                                                                })}
                                                                className={classNames(
                                                                    "form-control full-height min-height-7rem",
                                                                    { "no-focusable": !isEditAvailable },
                                                                )}
                                                                value={insertLinebreaks(value)}
                                                                onChange={this.handleChange}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Col>
                                                <Box display="flex" alignX="flex-end">
                                                    {!isEditAvailable ? (
                                                        <Button
                                                            className="btn-link"
                                                            image="images/editPen.svg"
                                                            label="global.edit"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isEditAvailable: !isEditAvailable,
                                                                });
                                                            }}
                                                        />
                                                    ) : (
                                                        <Box display="flex" gap="5">
                                                            <Button
                                                                bsStyle="only-icon"
                                                                image="images/icons/circleCross.svg"
                                                                className="widget-edit-close"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isEditAvailable: false,
                                                                    });
                                                                    this.setState({
                                                                        note: {
                                                                            value: movement?.note || "",
                                                                            isEditing: !movement?.note,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                            <Button
                                                                bsStyle="primary"
                                                                image="images/check-circle.svg"
                                                                className="widget-edit-close"
                                                                onClick={() => {
                                                                    this.handleClick();
                                                                    this.setState({
                                                                        isEditAvailable: false,
                                                                    });
                                                                }}
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                            </Box>

                            <Row gapY="3">
                                <Col xs={12} md={4} mdOffset={4}>
                                    <Button bsStyle="outline" label="global.back" onClick={this.handleBack} block />
                                </Col>
                            </Row>
                        </Box>
                    </MainContainer>
                    {this.state.showModalTracking !== undefined &&
                        this.state.showModalTracking === true &&
                        movement &&
                        account && (
                            <InternationalTransferTrackingModal
                                showModal={this.state.showModalTracking}
                                handleCloseModal={() => this.setState({ showModalTracking: false })}
                                transferSupportType={TRANSFER_SUPPORT_REFUND_RECEIVED_TYPE}
                                transferSupportData={{
                                    transactionId: movement?.reference || movement?.idStatement || "-",
                                    amount: movement?.credit || movement?.debit,
                                    currency: account?.currency || "",
                                    date: movement?.date,
                                    tracking: movement?.reference || movement?.idStatement || "-",
                                    accountLabel: account?.label || account?.longLabel || "-",
                                    accountId: account?.idProduct || "",
                                }}
                                callbackSuccess={this.callbackSuccessRequest}
                                callbackError={() => this.setState({ showModalTracking: false })}
                                scopeError={["movementDetail"]}
                            />
                        )}
                </>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    movement: accountsSelectors.getSelectedMovement(state),
});

export default connect(mapStateToProps)(resizableRoute(withRevelockPositionContext(MovementDetail)));

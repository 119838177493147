import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";
import * as utilsI18n from "util/i18n";
import { getMobileOS, isMobileNativeFunc } from "util/device";
import { selectors as selectorsSoftToken } from "reducers/softToken";
import {
    DOCUMENT_TYPE_CED_TOKEN,
    DOCUMENT_TYPE_CIP_TOKEN,
    DOCUMENT_TYPE_IMAGE_BACK,
    DOCUMENT_TYPE_IMAGE_FRONT,
    DOCUMENT_TYPE_MULTIPLE_TOKEN,
    DOCUMENT_TYPE_PAS_TOKEN,
    DOCUMENT_TYPE_IMAGE_SELFIE,
} from "util/userToken.util";
import { routerActions } from "react-router-redux/actions";
import { selectors as sessionSelectors } from "reducers/session";
import { WidgetErrorType, WidgetFinishStatus, widgetLicenseAndroid, widgetLicenseIOS } from "./constants";

const TokenActivationStep2 = ({
    dispatch,
    fetching,
    fetchingSoftToken,
    documentType,
    callbackDataDocument,
    selfieNonTokenizedImage,
    isLoggedIn,
}) => {
    useEffect(
        () =>
            // TODO: Clear callback data biometric

            () => {
                dispatch(enrollmentActions.clearCallbackDataDocument());
            },
        [],
    );

    const [ocrData, setOcrData] = useState({});
    const [loaderSmallBoxed, setLoaderSmallBoxed] = useState(false);
    const [documentSelected, setDocumentSelected] = useState(undefined);
    const [documentScannedList, setDocumentScannedList] = useState([]);

    const DOCUMENT_TYPE_PLUGIN_MAP = new Map([
        [
            DOCUMENT_TYPE_CIP_TOKEN,
            {
                documentTypePlugin: window.facephi.selphid.doctype.WidgetSelphIDDocumentType.IDCard,
                labelButton: "token.activation.step2.button.ci",
                imageButton: "images/icons/token/documentToken.svg",
                scanMode: window.facephi.selphid.scanmode.WidgetSelphIDScanMode.SearchMode,
            },
        ],
        [
            DOCUMENT_TYPE_CED_TOKEN,
            {
                documentTypePlugin: window.facephi.selphid.doctype.WidgetSelphIDDocumentType.IDCard,
                labelButton: "token.activation.step2.button.ci",
                imageButton: "images/icons/token/documentToken.svg",
                scanMode: window.facephi.selphid.scanmode.WidgetSelphIDScanMode.SearchMode,
            },
        ],
        [
            DOCUMENT_TYPE_PAS_TOKEN,
            {
                documentTypePlugin: window.facephi.selphid.doctype.WidgetSelphIDDocumentType.Passport,
                labelButton: "token.activation.step2.button.passport",
                imageButton: "images/icons/token/passportToken.svg",
                scanMode: window.facephi.selphid.scanmode.WidgetSelphIDScanMode.GenericMode,
            },
        ],
    ]);

    const onErrorWizardCapture = () => {
        // TODO: Error capture camera
    };

    const getOcrDataDocument = (documentData) => {
        try {
            if (!documentData) {
                return undefined;
            }

            const dataResult = JSON.parse(documentData);
            if (!dataResult) {
                return undefined;
            }

            const backInformation = {
                issuer: dataResult["Back/INPUT/Issuer"] || "",
                dateExpire: dataResult["Back/ML/DateOfExpiry"] || "",
                dateIssue: dataResult["Back/ML/DateOfIssue"] || "",
                fingerPrintCode: dataResult["Back/ML/FingerprintCode"] || "",
                placeIssue: dataResult["Back/ML/PlaceOfIssue"] || "",
            };

            const frontInformation = {
                issuer: dataResult["Front/INPUT/Issuer"] || "",
                civilStatus: dataResult["Front/ML/CivilStatus"] || "",
                dateBirth: dataResult["Front/ML/DateOfBirth"] || "",
                documentNumber: dataResult["Front/ML/DocumentNumber"] || "",
                firstName: dataResult["Front/ML/FirstName"] || "",
                gender: dataResult["Front/ML/Gender"] || "",
                lastName: dataResult["Front/ML/LastName"] || "",
                nationality: dataResult["Front/ML/Nationality"] || "",
                placeOfBirth: dataResult["Front/ML/PlaceOfBirth"] || "",
            };

            const resultOcr = {
                backInformation,
                frontInformation,
                dateBirth: dataResult.DateOfBirth || "",
                dateExpiry: dataResult.DateOfExpiry || "",
                dateIssue: dataResult.DateOfIssue || "",
                documentCaptured: dataResult.DocumentCaptured || "",
                documentNumber: dataResult.DocumentNumber || "",
                firstName: dataResult.FirstName || "",

                gender: dataResult.Gender || "",
                issuer: dataResult.Issuer || "",
                lastName: dataResult.LastName || "",
                nationality: dataResult.Nationality || "",
                placeOfBirth: dataResult.PlaceOfBirth || "",
            };

            return resultOcr;
        } catch (e) {
            return undefined;
        }
    };

    const setSuccessfullScanned = (dataScanned) => {
        const docScannedList = [];
        if (dataScanned?.frontDocumentImage && dataScanned?.rawFrontDocument) {
            docScannedList.push({
                documentData: dataScanned.frontDocumentImage,
                enumDocumentType: DOCUMENT_TYPE_IMAGE_FRONT,
                documentDataRaw: dataScanned?.rawFrontDocument,
            });
        }
        if (dataScanned?.backDocumentImage && dataScanned?.rawBackDocument) {
            docScannedList.push({
                documentData: dataScanned.backDocumentImage,
                enumDocumentType: DOCUMENT_TYPE_IMAGE_BACK,
                documentDataRaw: dataScanned.rawBackDocument,
            });
        }

        if (selfieNonTokenizedImage) {
            docScannedList.push({
                documentData: selfieNonTokenizedImage,
                enumDocumentType: DOCUMENT_TYPE_IMAGE_SELFIE,
            });
        }
        setOcrData(getOcrDataDocument(dataScanned?.documentData));
        setDocumentScannedList(docScannedList);
    };

    const onSuccessWizardCapture = (result) => {
        // Here must return the value of processing Widget if is a success.
        if (result != null && result) {
            const data = result;
            const status = parseInt(data.finishStatus, 10);
            switch (status) {
                case WidgetFinishStatus.Ok:
                    setSuccessfullScanned(result);
                    break;

                case WidgetFinishStatus.Error:
                    if (data.errorType) {
                        dispatch(
                            notificationActions.showNotification(
                                utilsI18n.get(`token.activation.error.${status}`),
                                "error",
                                ["tokenActivation"],
                            ),
                        );

                        if (
                            status === WidgetFinishStatus.CameraPermissionDenied ||
                            status === WidgetFinishStatus.SettingsPermissionDenied
                        ) {
                            dispatch(
                                notificationActions.showNotification(
                                    utilsI18n.get(`token.activation.plugin.error${data.errorType}`),
                                    "error",
                                    ["tokenActivation"],
                                ),
                            );
                        } else {
                            // eslint-disable-next-line no-console
                            console.error(utilsI18n.get(`token.activation.plugin.error${data.errorType}`));
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(utilsI18n.get(`token.activation.plugin.error${data.errorType}`));
                        dispatch(push("/serverError"));
                    }
                    return;
                case WidgetFinishStatus.CancelByUser: // CancelByUser
                    dispatch(
                        notificationActions.showNotification(
                            utilsI18n.get(`token.activation.plugin.error.manuallyStopped`),
                            "error",
                            ["tokenActivation"],
                        ),
                    );
                    return;
                case WidgetFinishStatus.Timeout: // Timeout
                    dispatch(
                        notificationActions.showNotification(
                            utilsI18n.get(`token.activation.plugin.error.timeout`),
                            "error",
                            ["tokenActivation"],
                        ),
                    );
                    break;
                default:
                    break;
            }
        } else {
            // eslint-disable-next-line no-console
            console.error(utilsI18n.get(`token.activation.plugin.error${WidgetErrorType.UnknownError}`));
            dispatch(push("/serverError"));
        }
    };

    const startPluginDoc = (document) => {
        if (!document || !document.documentType || !document.documentCountry) {
            return;
        }

        const { documentType: docType, documentCountry } = document;
        const documentTypePlugin = DOCUMENT_TYPE_PLUGIN_MAP.get(docType);
        if (!documentTypePlugin?.documentTypePlugin) {
            return;
        }

        const resourcesBundlePathSelphID = "fphi-selphid-widget-resources-selphid-custom-1.0"; // SelphID bundle
        const config = new window.facephi.selphid.config.WidgetSelphIDConfig();
        config.scanMode =
            documentTypePlugin?.scanMode || window.facephi.selphid.scanmode.WidgetSelphIDScanMode.SearchMode;
        config.documentType = documentTypePlugin.documentTypePlugin;
        config.specificData = `${documentCountry}|<ALL>`;
        config.setLocale(utilsI18n.getLang());
        config.showResultAfterCapture = false;
        config.setShowResultAfterCapture(false);
        config.showTutorial = false;
        config.generateRawImages = true;
        const widgetLicense = getMobileOS() === "Android" ? widgetLicenseAndroid : widgetLicenseIOS;

        window.facephi.selphid.universal
            .StartCapture(
                window.facephi.selphid.operation.WidgetSelphIDOperation.CaptureWizard, // The capture operation
                resourcesBundlePathSelphID, // Name of the bundle with all of the widget resources
                widgetLicense, // Widget license
                // Data extracted from the previous capture process to merge with the next operation (May be null)
                ocrData,
                config,
            ) // Optional widget configuration option
            .then(
                (result) => onSuccessWizardCapture(result),
                (err) => onErrorWizardCapture(err),
            );

        // config.setShowResultAfterCapture(false);
    };

    const errorValidateDocumentType = () => {
        dispatch(
            notificationActions.showNotification(utilsI18n.get("token.activation.document.notfound.message"), "error", [
                "tokenActivation",
            ]),
        );
    };

    const validateDocumentType = () => {
        if (!documentType?.enumDocumentType || !documentType?.documentList) {
            return;
        }

        const { enumDocumentType, documentList } = documentType;

        const documentTypeList = [
            DOCUMENT_TYPE_CIP_TOKEN,
            DOCUMENT_TYPE_CED_TOKEN,
            DOCUMENT_TYPE_MULTIPLE_TOKEN,
            DOCUMENT_TYPE_PAS_TOKEN,
        ];
        if (!documentTypeList.includes(enumDocumentType)) {
            errorValidateDocumentType();
            return;
        }

        if (enumDocumentType !== DOCUMENT_TYPE_MULTIPLE_TOKEN) {
            startPluginDoc(documentList[0]);
            setDocumentSelected(documentList[0]);
        }
    };

    const initTokenValidate = () => {
        if (!isMobileNativeFunc()) {
            dispatch(push("/"));
            return;
        }

        validateDocumentType();
    };

    useEffect(() => {
        initTokenValidate();
    }, [documentType]);

    const next = () => {
        setLoaderSmallBoxed(true);
        dispatch(
            enrollmentActions.validateTokenActivationDocument(
                documentSelected,
                documentScannedList,
                ocrData,
                callbackDataDocument,
            ),
        );
    };

    const handleBack = () => {
        dispatch(routerActions.replace(isLoggedIn ? "/auth/tokenActivationStep1" : "/tokenActivationStep1"));
    };

    const clearState = () => {
        setDocumentScannedList([]);
    };

    const renderDocumentTypeOptions = () => {
        if (!documentType?.documentList) {
            return <div />;
        }

        const { documentList } = documentType;

        return (
            <Row gapY="5" className="height-auto">
                {documentList.map((item) => {
                    if (!item) {
                        return <div />;
                    }
                    const { documentType: docType } = item;
                    const documentButton = DOCUMENT_TYPE_PLUGIN_MAP.get(docType);

                    return (
                        <Col xs={12}>
                            <Button
                                bsStyle="big-link"
                                image={documentButton?.imageButton || ""}
                                onClick={() => {
                                    startPluginDoc(item);
                                    setDocumentSelected(item);
                                }}
                                block>
                                <Box display="flex" alignX="center" fullWidth column gap={1}>
                                    <Text labelKey={documentButton?.labelButton || ""} />
                                    <Text labelKey={`country.name.${item?.documentCountry || ""}`} />
                                </Box>
                            </Button>
                        </Col>
                    );
                })}
            </Row>
        );
    };

    const contentWithoutImages = () => (
        <>
            <Box display="flex" column alignY="center" alignX="between" className="mx-7">
                <Text
                    component="h2"
                    align="center"
                    size="2"
                    color="heading"
                    bold
                    className="mt-7 mb-5"
                    labelKey="token.activation.step2.subtitle"
                />
                <Text
                    component="p"
                    align="center"
                    size="5"
                    color="heading"
                    className="my-0"
                    labelKey="token.activation.step2.text1"
                />
                <Text
                    component="p"
                    align="center"
                    size="5"
                    color="heading"
                    className="mt-0 mb-12"
                    labelKey="token.activation.step2.text2"
                />
            </Box>

            {renderDocumentTypeOptions()}
        </>
    );

    const getImageDocumentByType = (type) => documentScannedList.find((doc) => doc && doc.enumDocumentType === type);

    const contentWithImages = () => {
        const frontImageDocument = getImageDocumentByType(DOCUMENT_TYPE_IMAGE_FRONT);
        const backImageDocument = getImageDocumentByType(DOCUMENT_TYPE_IMAGE_BACK);

        return (
            <>
                <Box display="flex" column alignY="center" alignX="between" className="mx-7">
                    {frontImageDocument?.documentData && (
                        <Box className="my-5" fullWidth>
                            <img
                                src={`data:image/jpeg;base64,${frontImageDocument.documentData}`}
                                alt=""
                                className="full-width border-radius-md"
                            />
                        </Box>
                    )}
                    {backImageDocument?.documentData && (
                        <Box className="mb-5" fullWidth>
                            <img
                                src={`data:image/jpeg;base64,${backImageDocument.documentData}`}
                                alt=""
                                className="full-width border-radius-md"
                            />
                        </Box>
                    )}
                </Box>
                <Box display="flex" column className="mb-10 mt-auto" fullWidth>
                    <Button label="token.activation.step2.button.next" bsStyle="primary" onClick={next} block />
                    <Button
                        label="token.activation.step2.button.takeAgain"
                        type="button"
                        className="btn-outline"
                        onClick={clearState}
                        block
                    />
                </Box>
            </>
        );
    };

    return (
        <>
            <Head onBack={handleBack} title="token.activation.title" additionalClassName="background-primary" />
            <Notification scopeToShow="tokenActivation" />
            <MainContainer
                classNameMainWrapper="background-menu-background"
                showLoader={fetching || fetchingSoftToken}
                showChildrenWithLoader
                loaderSmallBoxed={loaderSmallBoxed}>
                {documentScannedList && documentScannedList.length > 0 ? contentWithImages() : contentWithoutImages()}
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.isFetching(state),
    fetchingSoftToken: selectorsSoftToken.isFetching(state),
    documentType: enrollmentSelectors.getDocumentTypeToken(state),
    selfieNonTokenizedImage: enrollmentSelectors.getSelfieNonTokenized(state),
    callbackDataDocument: enrollmentSelectors.getCallbackDataDocument(state),
    isLoggedIn: sessionSelectors.isLoggedIn(state),
});

TokenActivationStep2.propTypes = {
    fetching: bool.isRequired,
    dispatch: func.isRequired,
    fetchingSoftToken: bool,
    documentType: shape,
    callbackDataDocument: shape,
    selfieNonTokenizedImage: string,
    isLoggedIn: bool,
};
TokenActivationStep2.defaultProps = {
    fetchingSoftToken: false,
    documentType: undefined,
    callbackDataDocument: undefined,
    selfieNonTokenizedImage: undefined,
    isLoggedIn: false,
};

export default connect(mapStateToProps)(TokenActivationStep2);

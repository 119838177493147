import { Field, Form, withFormik } from "formik";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as productsActions } from "reducers/products";
import { compose } from "redux";
import { getLocationBasePath } from "util/revelock";
import * as Yup from "yup";

const FORM_ID = "accounts.alias";
const PAGE_IDENTIFIER = "accounts.setAlias";
const MAX_LENGTH_ALIAS = 28;
class SetAlias extends Component {
    componentDidMount() {
        const { dispatch, ...rest } = this.props;
        dispatch(accountsActions.readAccount(rest.match.params.id));
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { fetching, isSubmitting } = this.props;

        return (
            <>
                <Notification scopeToShow="accounts/setAlias" />
                <Head onBack={this.handleBack} title="accounts.alias.setAlias.title" />
                <MainContainer showLoader={fetching}>
                    <Form className="above-the-fold full-height">
                        <Box fullHeight fullWidth display="flex" column>
                            <Box background="white" borderRadius="default" className="mt-5 p-5 pb-9 mx-n-5">
                                <Field
                                    autoFocus
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="setAlias"
                                    type="text"
                                    labelNoMarginTop
                                    maxLength={MAX_LENGTH_ALIAS}
                                />
                            </Box>

                            <Button
                                type="submit"
                                className="mt-auto mb-7"
                                bsStyle="primary"
                                label="accounts.alias.setAlias.save"
                                loading={isSubmitting}
                                block
                            />
                        </Box>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetchingDetail(state),
});

SetAlias.propTypes = {
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    isSubmitting: bool.isRequired,
    setLocationCustomPath: func,
    match: shape().isRequired,
};
SetAlias.defaultProps = {
    setLocationCustomPath: () => {},
};
export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ account, match }) => ({
            setAlias: account?.productAlias ? account.productAlias.substring(0, MAX_LENGTH_ALIAS) : "",
            productId: match.params.id,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string().nullable(),
            }),
        handleSubmit: ({ productId, setAlias }, formikBag) => {
            formikBag.props.dispatch(productsActions.changeProductAlias(setAlias, productId, true), formikBag);
        },
    }),
)(withRevelockPositionContext(SetAlias));

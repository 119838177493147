import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "administrationInvitations/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "administrationInvitations/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "administrationInvitations/LOAD_LIST_SUCCESS",
    LOAD_MORE_REQUEST: "administrationInvitations/LOAD_MORE_REQUEST",
    LOAD_MORE_FAILED: "administrationInvitations/LOAD_MORE_FAILED",
    LOAD_MORE_SUCCESS: "administrationInvitations/LOAD_MORE_SUCCESS",

    RESEND_REQUEST: "administrationInvitations/RESEND_REQUEST",
    RESEND_FAILURE: "administrationInvitations/RESEND_FAILURE",
    RESEND_SUCCESS: "administrationInvitations/RESEND_SUCCESS",

    DELETE_REQUEST: "administrationInvitations/DELETE_REQUEST",
    DELETE_FAILURE: "administrationInvitations/DELETE_FAILURE",
    DELETE_SUCCESS: "administrationInvitations/DELETE_SUCCESS",
};

export const INITIAL_STATE = {
    list: [],
    currentPage: 1,
    totalPages: 0,
    hasMoreData: false,
    fetching: false,
    fetchingMoreInvitations: false,
    idTransaction: null,
    idActivity: null,
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state, { pageNumber }) => ({
        ...state,
        fetching: true,
        currentPage: pageNumber,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, { data }) => ({
        list: data.invitations,
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        hasMoreData: data.currentPage < data.totalPages,
        fetching: false,
    }),

    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetchingMoreInvitations: true,
    }),
    [types.LOAD_MORE_FAILED]: (state) => ({
        ...state,
        fetchingMoreInvitations: false,
    }),
    [types.LOAD_MORE_SUCCESS]: (state, { data }) => ({
        ...state,
        fetchingMoreInvitations: false,
        list: state.list ? state.list.concat(data.invitations) : data.list,
        hasMoreData: data.currentPage < data.totalPages,
        currentPage: data.currentPage,
    }),

    [types.RESEND_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.RESEND_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.RESEND_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.DELETE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.DELETE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.DELETE_SUCCESS]: (state, { id }) => ({
        ...state,
        fetching: false,
        list: state.list.filter((element) => element.id !== id),
    }),
});

export const actions = {
    loadListRequest: (filters) => ({
        type: types.LOAD_LIST_REQUEST,
        filters,
    }),
    loadMoreRequest: (filters) => ({
        type: types.LOAD_MORE_REQUEST,
        filters,
    }),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadMoreFailure: makeActionCreator(types.LOAD_MORE_FAILED),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),

    resendRequest: (id) => ({
        type: types.RESEND_REQUEST,
        id,
    }),
    resendFailure: makeActionCreator(types.RESEND_FAILURE),
    resendSuccess: makeActionCreator(types.RESEND_SUCCESS),

    deleteRequest: (id) => ({
        type: types.DELETE_REQUEST,
        id,
    }),
    deleteFailure: makeActionCreator(types.DELETE_FAILURE),
    deleteSuccess: makeActionCreator(types.DELETE_SUCCESS, "id"),
};

export const selectors = {
    getInvitations: ({ administrationInvitations }) => administrationInvitations.list,
    isFetching: ({ administrationInvitations }) => administrationInvitations.fetching,
    isFetchingMoreInvitations: ({ administrationInvitations }) => administrationInvitations.fetchingMoreInvitations,
    getCurrentPage: ({ administrationInvitations }) => administrationInvitations.currentPage,
    getTotalPages: ({ administrationInvitations }) => administrationInvitations.totalPages,
    getHasMoreData: ({ administrationInvitations }) => administrationInvitations.hasMoreData,
};

import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import TextField from "pages/_components/fields/TextField";
import Row from "pages/_components/Row";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "enrollment.wizard.validateUser";
class ValidateUser extends Component {
    cancel = () => {
        const { dispatch } = this.props;
        dispatch(push("/enrollment"));
    };

    render() {
        const { isSubmitting, showCaptcha, isDesktop } = this.props;

        return (
            <>
                <HeaderEnrollment title="enrollment.validateUser.title" subtitle="enrollment.validateUser.subtitle" />
                <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                    <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto">
                        <Col xs={12} md={8} mdOffset={2}>
                            <Box
                                background="white"
                                borderRadius="xxl"
                                className="white-boxed pt-7 pt-md-12 pb-10 pb-md-12">
                                <Box fullWidth className="mb-7 mb-md-7">
                                    <Field
                                        idForm={FORM_ID}
                                        name="usernameToValidate"
                                        component={TextField}
                                        placeholder=""
                                        labelNoMarginTop
                                    />
                                </Box>
                                <Box fullWidth>
                                    <Field
                                        idForm={FORM_ID}
                                        copyEnabled={false}
                                        type="password"
                                        name="password"
                                        component={Credential}
                                        placeholder=""
                                        labelNoMarginTop
                                    />
                                </Box>

                                {showCaptcha && (
                                    <Box display="flex" alignX="center" fullWidth className="mt-7">
                                        <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                    </Box>
                                )}
                            </Box>
                        </Col>
                    </Row>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row gapY={isDesktop ? "8" : "3"}>
                            <Col xs={12} md={3} mdOffset={3}>
                                <Button
                                    bsStyle="outline"
                                    label="global.cancel"
                                    type="button"
                                    onClick={this.cancel}
                                    block
                                />
                            </Col>
                            <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Form>
            </>
        );
    }
}

ValidateUser.propTypes = {
    dispatch: func.isRequired,
    showCaptcha: bool.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    showCaptcha: enrollmentSelectors.getShowCaptchaValidateUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            usernameToValidate: "",
            password: "",
            captcha: "",
        }),
        validationSchema: ({ showCaptcha }) =>
            Yup.object().shape({
                usernameToValidate: Yup.string().required(i18n.get(`${FORM_ID}.usernameToValidate.empty`)),
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.empty`)),
                captcha: showCaptcha ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.empty`)) : Yup.string(),
            }),
        handleSubmit: ({ usernameToValidate, password, captcha }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.validateUser(usernameToValidate, password, captcha, formikBag));
        },
    }),
)(ValidateUser);

import * as form from "middleware/form";
import * as updateCompanyDataApi from "middleware/updateCompanyData/updateCompanyData.middleware";
import { replace } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import {
    actions as updateCompanyDataActions,
    selectors as updateCompanyDataSelectors,
    types as updateCompanyDataTypes,
} from "reducers/updateCompanyData/updateCompanyData.reducer";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { adjustIdFieldErrors } from "util/form.js";

function* getCredencialsGroups({ credential }) {
    const { activityId } = credential;

    const responsePreDataStep0 = yield call(updateCompanyDataApi.updateCompanyDataStep0PreData);
    const stateUpdateCorporate = responsePreDataStep0?.data?.data?.stateUpdateCorporate;

    if (stateUpdateCorporate !== "inReview") {
        const response = yield call(form.listCredentialsGroups, "", activityId);
        if (!response?.type || !response?.data?.data) {
            yield put(updateCompanyDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
            return;
        }

        if (response.type === "W") {
            yield put(updateCompanyDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
            return;
        }

        const { groups } = response?.data?.data;
        if (!groups) {
            yield put(updateCompanyDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
            return;
        }
        yield put({
            type: updateCompanyDataTypes.GET_CREDENTIAL_GROUPS_SUCCESS,
            credentialsGroups: groups,
        });
    } else {
        yield put({
            type: updateCompanyDataTypes.GET_CREDENTIAL_GROUPS_SUCCESS,
            stateUpdateCorporate,
        });
    }
}

function* updateCompanyDataSendRequest({ otp, formikBag }) {
    const response = yield call(updateCompanyDataApi.updateCompanyDataSendRequest, otp);
    if (!response?.type || !response?.data?.data) {
        yield put(updateCompanyDataTypes.UPDATE_COMPANY_FAILURE);
        yield;
    } else if (response.type === "W") {
        yield put({ type: updateCompanyDataTypes.UPDATE_COMPANY_DATA_SEND_FAILURE });
        if (response.data.data.otp !== "returnCode.COR091W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
    } else {
        const { _exchangeToken } = response?.data?.data;
        if (_exchangeToken) {
            yield put({
                type: updateCompanyDataTypes.UPDATE_COMPANY_DATA_SEND_SUCCESS,
                data: { exchangeToken: _exchangeToken },
            });
            yield put(replace("/companyDataUpdateStep1"));
        }
    }
    formikBag.setSubmitting(false);
}

function* preFormStep1({ exchangeToken }) {
    const response = yield call(updateCompanyDataApi.updateCompanyDataStep1PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        yield put(updateCompanyDataActions.preFormStep1Success(response.data?.data));
    } else if (response.data?.code === "COR020W") {
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/desktop"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.requestFailure());
    }
}

function* preFormStep2({ exchangeToken }) {
    const response = yield call(updateCompanyDataApi.updateCompanyDataStep2PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        yield put(updateCompanyDataActions.preFormStep2Success(response.data.data));
    } else if (response && response.data?.code === "COR020W") {
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

function* preFormStep3({ exchangeToken }) {
    const response = yield call(updateCompanyDataApi.updateCompanyDataStep3PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        yield put(updateCompanyDataActions.preFormStep3Success(response.data?.data));
    } else if (response.data?.code === "COR020W") {
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/desktop"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

function* preFormStep4({ exchangeToken }) {
    const response = yield call(updateCompanyDataApi.updateCompanyDataStep4PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        yield put(updateCompanyDataActions.preFormStep4Success(response.data?.data));
    } else if (response.data?.code === "COR020W") {
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/desktop"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.requestFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
    });
};

function* selectFile({ file, isMandatory, internalIndex }) {
    const result = yield readFileAsDataUrl(file);
    if (isMandatory) {
        const selectedMandatoryAtts = yield select(updateCompanyDataSelectors.getSelectedMandatoryAttachments);
        const index = selectedMandatoryAtts.map((e) => e.idx).indexOf(internalIndex);
        if (selectedMandatoryAtts.length === 0 || index < 0) {
            selectedMandatoryAtts.push({
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            });
        } else if (index >= 0) {
            selectedMandatoryAtts[index] = {
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            };
        }
        yield put(updateCompanyDataActions.updateMandatoryAttachments(selectedMandatoryAtts));
    } else {
        const selectedAdditionalAtts = yield select(updateCompanyDataSelectors.getSelectedAdditionalAttachments);
        const index = selectedAdditionalAtts.map((e) => e.idx).indexOf(internalIndex);
        if (selectedAdditionalAtts.length === 0 || index < 0) {
            selectedAdditionalAtts.push({
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            });
        } else if (index >= 0) {
            selectedAdditionalAtts[index] = {
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            };
        }
        yield put(updateCompanyDataActions.updateAdditionalAttachments(selectedAdditionalAtts));
    }
}

function* saveForm({ values, step, formikBag }) {
    const { exchangeToken } = formikBag?.props;
    const response = yield call(updateCompanyDataApi.saveCompanyDataStep, step, values, exchangeToken);
    if (response && response.status === 200) {
        yield put(updateCompanyDataActions.saveFormSuccess());
        yield put(replace(`/companyDataUpdateStep${step + 1}`));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.saveFormFailure());
    }
}

function* getDistrictList({ provinceCode }) {
    const response = yield call(updateCompanyDataApi.getCatalogListByParentCatalogName, "DISTRITO", provinceCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const districtByProvinceList = recordList || {};
        yield put(updateCompanyDataActions.getDistrictListSuccess(districtByProvinceList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.getDistrictListFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

function* getJurisdictionList({ districtCode }) {
    const response = yield call(updateCompanyDataApi.getCatalogListByParentCatalogName, "CORREGIMIENTO", districtCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const jurisdictionList = recordList || {};
        yield put(updateCompanyDataActions.getJurisdictionListSuccess(jurisdictionList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.getJurisdictionListFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

function* getProvinceList({ countryCode }) {
    const response = yield call(updateCompanyDataApi.getCatalogListByParentCatalogName, "PROVINCIA", countryCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const provinceList = recordList || {};
        yield put(updateCompanyDataActions.getProvinceListSuccess(provinceList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.getProvinceListFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

function* getCityList({ stateProvinceCode }) {
    const response = yield call(updateCompanyDataApi.getCatalogListByParentCatalogName, "CIUDAD", stateProvinceCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        yield put(updateCompanyDataActions.getCityListSuccess(recordList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["companyDataUpdate"]));
        yield put(updateCompanyDataActions.getCityListFailure());
        yield put(replace("/companyDataUpdateStep1"));
    }
}

const sagas = [
    takeLatest(updateCompanyDataTypes.UPDATE_COMPANY_DATA_SEND_REQUEST, updateCompanyDataSendRequest),
    takeLatest(updateCompanyDataTypes.GET_CREDENTIAL_GROUPS_REQUEST, getCredencialsGroups),
    takeLatest(updateCompanyDataTypes.UPDATE_COMPANY_DATA_STEP1_PRE_REQUEST, preFormStep1),
    takeLatest(updateCompanyDataTypes.UPDATE_COMPANY_DATA_STEP2_PRE_REQUEST, preFormStep2),
    takeLatest(updateCompanyDataTypes.UPDATE_COMPANY_DATA_STEP3_PRE_REQUEST, preFormStep3),
    takeLatest(updateCompanyDataTypes.UPDATE_COMPANY_DATA_STEP4_PRE_REQUEST, preFormStep4),
    takeLatest(updateCompanyDataTypes.UPDATE_COMPANY_DATA_SAVE_FORM, saveForm),
    takeLatest(updateCompanyDataTypes.GET_DISTRICT_LIST_REQUEST, getDistrictList),
    takeLatest(updateCompanyDataTypes.GET_JURISDICTION_LIST_REQUEST, getJurisdictionList),
    takeLatest(updateCompanyDataTypes.GET_PROVINCE_LIST_REQUEST, getProvinceList),
    takeLatest(updateCompanyDataTypes.GET_CITY_LIST_REQUEST, getCityList),
    takeLatest(updateCompanyDataTypes.SELECT_FILE, selectFile),
];

export default sagas;

// Tour.js
import { func, shape } from "prop-types";
import React from "react";
import JoyRide from "react-joyride";
import { connect } from "react-redux";
import { actions as tourActions } from "reducers/tour";
import * as i18n from "util/i18n";

const Tour = ({ steps, dispatch }) => {
    const handleJoyrideCallback = (data) => {
        const { action, status, lifecycle } = data;

        if (action === "skip" && lifecycle === "complete") {
            dispatch(tourActions.hide());
            dispatch(tourActions.addSkip());
        }
        if (status === "finished" && lifecycle === "complete") {
            dispatch(tourActions.hide());
            dispatch(tourActions.finish());
        }
    };
    const inLineStyles = {
        options: {
            overlayColor: "rgba(68, 74, 83, 0.7)",
            borderRadius: "1rem",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.95)",
            primaryColor: "var(--primary-color)",
            width: "26.5rem",
            beaconSize: 36,
            zIndex: 1500,
        },
        spotlight: {
            borderRadius: "0.9375rem",
            backgroundColor: "#888888",
        },
        tooltip: {
            borderRadius: "0.9375rem",
            margin: "-1px",
            padding: "1.5rem",
            minWidth: "26.5rem",
        },
        tooltipTitle: {
            color: "var(--heading-color)",
            fontSize: "1.25rem",
            fontWeight: "700",
        },
        tooltipContent: {
            color: "var(--text-color)",
            fontSize: "1rem",
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: "0.75rem",
        },
        buttonNext: {
            color: "var(--component-background)",
            borderRadius: "1.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            minHeight: "2.5rem",
            fontSize: "0.875rem",
            fontWeight: "700",
        },
        buttonBack: {
            color: "var(--primary-hover-color)",
            borderRadius: "1.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            fontSize: "0.875rem",
            fontWeight: "700",
            minHeight: "2.5rem",
            marginRight: "1rem",
        },
        buttonSkip: {
            color: "var(--primary-hover-color)",
            borderRadius: "1.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            fontSize: "0.875rem",
            minHeight: "2.5rem",
            fontWeight: "700",
        },
    };
    return (
        <JoyRide
            steps={steps}
            continuous
            showProgress
            showSkipButton
            hideCloseButton
            disableOverlayClose
            scrollOffset={80}
            callback={handleJoyrideCallback}
            styles={inLineStyles}
            floaterProps={{
                styles: {
                    arrow: {
                        length: 9,
                        spread: 17,
                        left: 50,
                    },
                },
            }}
            locale={{
                back: i18n.get("dashboardTour.back"),
                close: i18n.get("dashboardTour.close"),
                last: i18n.get("dashboardTour.last"),
                next: i18n.get("dashboardTour.next"),
                open: i18n.get("dashboardTour.open"),
                skip: i18n.get("dashboardTour.skip"),
            }}
        />
    );
};

Tour.propTypes = {
    steps: shape({}).isRequired,
    dispatch: func.isRequired,
};

export default connect()(Tour);

export default {
    morning: "images/morning.png",
    morningCorporateBG: "images/morningCorporateBG.png",
    morningMobile: "images/morning-mobile.png",
    morningMobileCorporate: "images/morning-mobile-corporate.png",
    morningMobileCorporateBG: "images/morningMobileCorporateBG.png",

    afternoon: "images/afternoon.png",
    afternoonCorporateBG: "images/afternoonCorporateBG.png",
    afternoonMobile: "images/afternoon-mobile.png",
    afternoonMobileCorporate: "images/afternoon-mobile-corporate.png",
    afternoonMobileCorporateBG: "images/afternoonMobileCorporateBG.png",

    night: "images/night.png",
    nightCorporateBG: "images/nightCorporateBG.png",
    nightMobile: "images/night-mobile.png",
    nightMobileCorporate: "images/night-mobile-corporate.png",
    nightMobileCorporateBG: "images/nightMobileCorporateBG.png",

    logo: "images/banesco.png",
};

import { store } from "store";

const isLocalPhoneNumber = () => {
    const { user } = store.getState().session;
    if (!user) {
        return false;
    }

    const { isLocalNumber } = user;
    return isLocalNumber !== undefined && isLocalNumber === true;
};

export default isLocalPhoneNumber;

import React, { Component } from "react";
import { Field, Form } from "formik";
import { arrayOf, func, shape, bool, string, oneOf } from "prop-types";
import { Route } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";

import * as i18nUtils from "util/i18n";
import { actions as navigationActions } from "reducers/administration/common/navigation";

import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import GroupFormWizardSteps from "pages/administration/_components/groupForm/WizardSteps";
import GroupFormWizardTabs from "pages/administration/_components/groupForm/WizardTabs";
import Button from "pages/_components/Button";
import Wizard from "pages/_components/Wizard";
import PermissionsPanel from "pages/administration/_components/advancedPermissionsForm/PermissionsPanel";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class GroupForm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })),
        handleSubmit: func.isRequired,
        isSubmitting: bool.isRequired,
        idForm: string.isRequired,
        mode: oneOf(["view", "edit"]),
        buttonText: string.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        actions: shape({
            updateHasClose: func,
            updateHasBack: func,
            reset: func,
        }).isRequired,
    };

    static defaultProps = {
        mode: "edit",
        credentialGroups: [],
    };

    componentDidMount() {
        this.updateCloseBackButtonsVisibility();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
        } = this.props;

        if (prevProps.location.pathname !== pathname) {
            this.updateCloseBackButtonsVisibility();
        }
    }

    updateCloseBackButtonsVisibility = () => {
        const {
            actions,
            location: { pathname },
        } = this.props;
        actions.reset();
        if (pathname.match(/create\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/create\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        }
    };

    validateName = (value) => {
        const { idForm } = this.props;

        if (!value) {
            return i18nUtils.get(`${idForm}.name.required`);
        }

        return undefined;
    };

    render() {
        const { credentialGroups, handleSubmit, isSubmitting, idForm, mode, buttonText, ...props } = this.props;

        return (
            <Form className="above-the-fold">
                <Wizard>
                    <Container className="container--layout flex-grow-1">
                        <Container.Column md={6} sm={12}>
                            <Container.ColumnBody>
                                <Field
                                    name="name"
                                    idForm={idForm}
                                    component={TextField}
                                    mode={mode}
                                    validate={this.validateName}
                                    hidePlaceholder
                                />
                                <GroupFormWizardTabs idForm={idForm} mode={mode} formProps={props} />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                    <Route
                        render={({ location }) => (
                            <PermissionsPanel
                                key={location.pathname}
                                mode={location.pathname.indexOf("confirm") === -1 ? "edit" : "view"}
                                render={(content) => (
                                    <Container className="container--layout flex-grow-1">
                                        <Container.Column md={6} sm={12}>
                                            <Container.ColumnBody>
                                                <GroupFormWizardSteps idForm={idForm} mode={mode} formProps={props} />
                                                {mode === "view" && credentialGroups && (
                                                    <AdministrationFormConfirmation
                                                        credentialGroups={credentialGroups}
                                                        onSubmit={handleSubmit}
                                                        renderOutOfFormik
                                                    />
                                                )}
                                            </Container.ColumnBody>
                                        </Container.Column>
                                        <Container.Column md={6} sm={12}>
                                            <Container.ColumnBody>{content}</Container.ColumnBody>
                                        </Container.Column>
                                    </Container>
                                )}
                            />
                        )}
                    />
                </Wizard>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Container.Column className="col-12 col-xl-6" lg={6} md={6} sm={12}>
                        <Container.ColumnBody>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="administration.advanced.group.create.button.confirm.text"
                                loading={isSubmitting}
                                block
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
            </Form>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(navigationActions, dispatch),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(GroupForm);

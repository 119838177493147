import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    GET_PREAPPROVED_PRODUCTS_LIST_REQUEST: "preApprovedProduct/GET_PREAPPROVED_PRODUCTS_LIST_REQUEST",
    GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_SUCCESS: "preApprovedProduct/GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_SUCCESS",
    GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_FAILED: "preApprovedProduct/GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_FAILED",
    PREAPPROVED_PRODUCT_CHANGE_STATUS: "preApprovedProduct/PREAPPROVED_PRODUCT_CHANGE_STATUS",
    PREAPPROVED_PRODUCT_PRE_FORM_REQUEST: "preApprovedProduct/PREAPPROVED_PRODUCT_PRE_FORM_REQUEST",
    PREAPPROVED_PRODUCT_PRE_FORM_SUCCESS: "preApprovedProduct/PREAPPROVED_PRODUCT_PRE_FORM_SUCCESS",
    PREAPPROVED_PRODUCT_PRE_FORM_FAILED: "preApprovedProduct/PREAPPROVED_PRODUCT_PRE_FORM_FAILED",
    PREAPPROVED_PRODUCT_SEND_REQUEST: "preApprovedProduct/PREAPPROVED_PRODUCT_SEND_REQUEST",
    PREAPPROVED_PRODUCT_SEND_REQUEST_SUCCESS: "preApprovedProduct/PREAPPROVED_PRODUCT_SEND_REQUEST_SUCCESS",
    PREAPPROVED_PRODUCT_SEND_REQUEST_FAILED: "preApprovedProduct/PREAPPROVED_PRODUCT_SEND_REQUEST_FAILED",
    PREAPPROVED_PRODUCT_DECLINED: "preApprovedProduct/PREAPPROVED_PRODUCT_DECLINED",
    PREAPPROVED_PRODUCT_DECLINED_SUCCESS: "preApprovedProduct/PREAPPROVED_PRODUCT_DECLINED_SUCCESS",
    PREAPPROVED_PRODUCT_DECLINED_FAILED: "preApprovedProduct/PREAPPROVED_PRODUCT_DECLINED_FAILED",
    MODAL_SHOW: "preApprovedProduct/MODAL_SHOW",
    MODAL_CLOSE: "preApprovedProduct/MODAL_CLOSE",
};

export const INITIAL_STATE = {
    preApprovedProductsList: null,
    salaryRangeList: null,
    creditCardIssuerList: null,
    isFetching: false,
    showModal: false,
};

export default createReducer(INITIAL_STATE, {
    [types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST]: (state) => ({
        ...state,
    }),
    [types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_SUCCESS]: (state, action) => ({
        ...state,
        preApprovedProductsList: action.preApprovedProductsList,
    }),
    [types.PREAPPROVED_PRODUCT_CHANGE_STATUS]: (state) => ({
        ...state,
    }),
    [types.PREAPPROVED_PRODUCT_PRE_FORM_REQUEST]: (state) => ({
        ...state,
    }),
    [types.PREAPPROVED_PRODUCT_PRE_FORM_SUCCESS]: (state, action) => ({
        ...state,
        salaryRangeList: action.salaryRangeList,
        creditCardIssuerList: action.creditCardIssuerList,
    }),
    [types.PREAPPROVED_PRODUCT_PRE_FORM_FAILED]: (state) => ({
        ...state,
    }),
    [types.PREAPPROVED_PRODUCT_SEND_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.PREAPPROVED_PRODUCT_SEND_REQUEST_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
        showModal: false,
    }),
    [types.PREAPPROVED_PRODUCT_SEND_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        showModal: false,
    }),
    [types.MODAL_SHOW]: (state) => ({
        ...state,
        showModal: true,
    }),
    [types.MODAL_CLOSE]: (state) => ({
        ...state,
        showModal: false,
        isFetching: false,
    }),
    [types.PREAPPROVED_PRODUCT_DECLINED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.PREAPPROVED_PRODUCT_DECLINED_SUCCESS]: (state) => ({
        ...state,
        showModal: false,
        isFetching: false,
    }),
    [types.PREAPPROVED_PRODUCT_DECLINED_FAILED]: (state) => ({
        ...state,
        showModal: false,
        isFetching: false,
    }),
});

export const actions = {
    getPreApprovedProductsList: makeActionCreator(types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST),
    getPreApprovedProductsListFailed: makeActionCreator(types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_FAILED),
    getPreApprovedProductsListSuccess: makeActionCreator(
        types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST_SUCCESS,
        "preApprovedProductsList",
    ),
    preApprovedProductChangeStatus: makeActionCreator(
        types.PREAPPROVED_PRODUCT_CHANGE_STATUS,
        "productType",
        "productStatusCode",
    ),
    preApprovedProductPreForm: makeActionCreator(types.PREAPPROVED_PRODUCT_PRE_FORM_REQUEST),
    preApprovedProductPreFormFailed: makeActionCreator(types.PREAPPROVED_PRODUCT_PRE_FORM_FAILED),
    preApprovedProductPreFormSuccess: makeActionCreator(
        types.PREAPPROVED_PRODUCT_PRE_FORM_SUCCESS,
        "salaryRangeList",
        "creditCardIssuerList",
    ),
    preApprovedProductSendRequest: makeActionCreator(
        types.PREAPPROVED_PRODUCT_SEND_REQUEST,
        "productType",
        "salary",
        "workPlace",
        "cardType",
        "productStatusCode",
    ),
    preApprovedProductDecline: makeActionCreator(
        types.PREAPPROVED_PRODUCT_DECLINED,
        "productType",
        "productStatusCode",
        "isDeclined",
    ),
    modalShow: makeActionCreator(types.MODAL_SHOW),
    modalHide: makeActionCreator(types.MODAL_CLOSE),
};

export const selectors = {
    getPreApprovedProductList: ({ preApprovedProduct }) => preApprovedProduct.preApprovedProductsList,
    getSalaryRangeList: ({ preApprovedProduct }) => preApprovedProduct.salaryRangeList,
    getCreditCardIssuerList: ({ preApprovedProduct }) => preApprovedProduct.creditCardIssuerList,
    isFetching: ({ preApprovedProduct }) => preApprovedProduct.isFetching,
    showModal: ({ preApprovedProduct }) => preApprovedProduct.showModal,
};

import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { bool, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class EquivalentTotalBalance extends Component {
    static propTypes = {
        equivalentTotalBalance: string,
        isMobile: bool,
    };

    static defaultProps = {
        equivalentTotalBalance: "",
        isMobile: false,
    };

    render() {
        const { equivalentTotalBalance, isMobile } = this.props;

        return (
            <Box
                component="article"
                display="flex"
                background="heading-color"
                borderRadius="default"
                column
                className="p-5 mb-5">
                <Box className="mb-3">
                    <Text
                        {...(!isMobile && { bold: true, size: "3" })}
                        {...(isMobile && { uppercase: true, size: "6" })}
                        color="inverse"
                        labelKey="accounts.equivalentTotalBalance"
                        suffix=" *"
                    />
                </Box>
                <Box className="mb-3">
                    <Text labelKey="accounts.balanceComposed.a11y" className="visually-hidden" />
                    <Text
                        className="data-amount content-data-strong"
                        {...(!isMobile && { size: "big" })}
                        {...(isMobile && { size: "2" })}
                        bold
                        color="inverse">
                        {equivalentTotalBalance}
                    </Text>
                </Box>
                <Box display="flex">
                    <Text {...(!isMobile && { size: "7" })} {...(isMobile && { size: "6" })} color="inverse">
                        {`(*) ${i18n.get("accounts.balanceComposed")}`}
                    </Text>
                </Box>
            </Box>
        );
    }
}

export default EquivalentTotalBalance;

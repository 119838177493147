import * as recovery from "middleware/recoveryUser";
import { replace } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { actions as recoveryUserActions, types } from "reducers/recoveryUser";
import { call, put, takeLatest } from "redux-saga/effects";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const RecoveryUserSagas = [
    takeLatest(types.RECOVERY_USER_STEP1_PRE_REQUEST, preForm),
    takeLatest(types.RECOVERY_USER_STEP2_SEND_REQUEST, sendForm),
];

export default RecoveryUserSagas;

function* preForm() {
    const response = yield call(recovery.recoveryUserStep1PreData);

    if (response && response.status === 200) {
        yield put(recoveryUserActions.preFormSuccess(response?.data?.data));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryUser"]));
        yield put(recoveryUserActions.requestFailure());
    }
}

function* sendForm({ documentCountry, documentType, documentNumber, isCorporate = false }) {
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "session.recoverUser.step1");
    const response = yield call(
        recovery.recoveryUserStep1SendData,
        documentCountry,
        documentType,
        documentNumber,
        isCorporate,
        fingerPrintToken,
    );
    if (response && response?.status === 200) {
        yield put(replace("/loginStep1"));
        yield put(notificationActions.showNotification(response?.data?.data?.message, "success", ["externalLayout"]));
        yield put(recoveryUserActions.sendFormSuccess());
    } else {
        yield put(replace("/loginStep1"));
        yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
        yield put(recoveryUserActions.requestFailure());
    }
}

import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";
import * as form from "middleware/form";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";

const loadGroupsOfUserRequest = (middleware, actions) =>
    function* loadGroupsOfUserRequestSaga({ id }) {
        const response = yield call(middleware.loadDetailsRequest, id);

        if (response.type === "W") {
            yield put(actions.loadGroupsFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            yield put(actions.loadGroupsSuccess(response.data.data));
        }
    };

const updateGroupsOfUserPreview = (middleware, actions) =>
    function* updateGroupsOfUserPreviewSaga({ data, formikBag }) {
        formikBag.setSubmitting(false);
        const response = yield call(middleware.updateGroupsOfUserPreview, data);

        if (response.type === "W") {
            formikBag.setErrors(response.data.data);
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationGroupsOfUser",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.user.detail.groups.modify.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.onSuccess(data.idUser));
            yield put(actions.updateGroupsOfUserPreviewSuccess(data.groups, credentialGroups));
        }
    };
// eslint-disable-next-line no-unused-vars
const updateGroupsOfUserConfirm = (middleware, actions) =>
    function* updateGroupsOfUserConfirmSaga({ data, formikBag }) {
        formikBag.setSubmitting(false);
        const groups = data.groupsToSave.map((group) => group.idGroup);
        const params = {
            idUser: data.idUser,
            groups,
        };

        const credentialsWithUnderscore = credentialsToUnderscoreFormat(data.credentials);
        const response = yield call(middleware.updateGroupsOfUser, { ...params, ...credentialsWithUnderscore });

        if (response.type === "W") {
            formikBag.setErrors(response.data.data);
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationGroupsOfUser",
                ]),
            );
        } else {
            yield put(notificationActions.showNotification(response.data.message, "success", ["administration"]));
            yield put(routerActions.go(-2));
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_GROUPS_REQUEST, loadGroupsOfUserRequest(middleware, actions)),
    takeLatest(types.UPDATE_GROUPS_OF_USER_PREVIEW, updateGroupsOfUserPreview(middleware, actions)),
    takeLatest(types.UPDATE_GROUPS_OF_USER_CONFIRM, updateGroupsOfUserConfirm(middleware, actions)),
];

export default sagasCreator;

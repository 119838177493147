import * as API from "middleware/api";
import { format } from "date-fns";
import { credentialsToUnderscoreFormat } from "util/form";
import moment from "moment";
import { dateToGMT0, plusDateMinutes, setTimeToDate } from "util/date";
import { cloneDeep } from "lodash";

export const loadListRequest = (filters, onlyPendings, onlyProcessing, pendingDispatch, gmt0 = true) => {
    let { dateFrom, dateTo } = cloneDeep(filters);
    const {
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        client,
        channelType,
        operationType,
        status,
        isAscending,
        isSorting,
        isCreatedByMe,
    } = filters;

    if (gmt0 !== undefined && gmt0 === true) {
        if (moment.isMoment(dateFrom)) {
            dateFrom = dateFrom.toDate();
        }

        if (moment.isMoment(dateTo)) {
            dateTo = dateTo.toDate();
        }

        dateFrom = setTimeToDate(dateFrom, 0, 0, 0);
        dateTo = setTimeToDate(dateTo, 23, 59, 0);
        dateTo = plusDateMinutes(dateTo, 1);

        dateFrom = dateToGMT0(dateFrom);
        dateTo = dateToGMT0(dateTo);
    }

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD HH:mm:ss") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD HH:mm:ss") : null;

    return API.executeWithAccessToken("/transactions.list", {
        dateFrom,
        dateTo,
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        channelType,
        operationType,
        onlyPendings,
        onlyProcessing,
        pendingDispatch,
        client,
        status,
        isAscending,
        isSorting,
        isCreatedByMe,
    });
};

export const loadListRequestHistoric = (filters, lastRegistryNumber, previousRegistryNumber) => {
    let { dateFrom, dateTo } = filters;
    const {
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        client,
        channelType,
        operationType,
        status,
        isAscending,
        isSorting,
    } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/transactions.list.historic", {
        dateFrom,
        dateTo,
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        channelType,
        operationType,
        client,
        status,
        isAscending,
        isSorting,
        lastRegistryNumber,
        previousRegistryNumber,
    });
};

export const deleteDraftRequest = (idTransaction) =>
    API.executeWithAccessToken("/core.deleteTransactionDraft", { idTransactionToDelete: idTransaction });

export const getPendingTransactionsQuantity = () => API.executeWithAccessToken("/transactions.get.pending.quantity");

export const downloadListTransaction = (
    { operationType, dateFrom, dateTo, channelType, status, contentType, isCreatedByMe },
    url,
    lastRegistryNumber,
) =>
    API.executeWithAccessToken(url, {
        operationType,
        dateFrom,
        dateTo,
        channelType,
        status,
        contentType,
        dateLabelDownload: moment().format("dddd D MMMM YYYY"),
        hourLabelDownload: moment().format("HH:mm A"),
        lastRegistryNumber,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isCreatedByMe,
    });

export const loadTransactionMigrationList = (idTransaction) =>
    API.executeWithAccessToken("/transactions.migration.list", { idTransaction });

export const deleteMigrationTransaction = (idTransaction, transactionType) => {
    const paymentId = idTransaction;
    const paymentType = transactionType;
    return API.executeWithAccessToken("/transactions.migration.delete", { paymentId, paymentType });
};

export const reScheduleMigrationTransaction = (
    transactionId,
    transactionType,
    scheduler,
    beneficiary,
    idDebitAccount,
    reason,
    amount,
    debitAccount,
    debitAccountData,
    creditAccount,
    creditAccountData,
    beneficiaryId,
    credentials,
    creationDateTime,
) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const params = {
        ...credentialsWithUnderscore,
    };
    const schedulerCustom = scheduler;
    const forex = false;
    const transactionAmount = amount;
    return API.executeWithAccessToken("/transactions.migration.reSchedule.send", {
        transactionId,
        transactionType,
        schedulerCustom,
        beneficiary,
        idDebitAccount,
        reason,
        transactionAmount,
        debitAccount,
        debitAccountData,
        creditAccountData,
        creditAccount,
        beneficiaryId,
        forex,
        creationDateTime,
        ...params,
    });
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from "classnames";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import MenuDropdownContext from "pages/_components/menu/MenuDropdownContext";
import { arrayOf, bool, element, node, number, shape, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as desktopSelectors } from "reducers/desktop";

const MenuDropdown = ({
    children,
    className,
    isSidebarCollapsed,
    openWrapperClassName,
    fetching,
    titleKey,
    image,
    bsStyle,
    disabled,
    notificationCount,
    notificationCountTextID,
    location,
    id,
    useChildren,
}) => {
    const firstChildRef = useRef(null);
    const lastChildRef = useRef(null);
    const buttonRef = useRef(null);
    const ulRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [hasActiveDropdown, setHasActiveDropdown] = useState(false);
    const pathCurrentSplit = `${location?.pathname}/`;

    const hasActive = children?.some((c) => pathCurrentSplit.includes(`${c?.props?.linkTo}/`));

    const trapFocus = (event) => {
        const getFocusableElements = (ref) =>
            ref?.current?.querySelectorAll('a, button, input, [tabindex]:not([tabindex="-1"])');

        const firstFocusableElements = getFocusableElements(firstChildRef);
        const lastFocusableElements = getFocusableElements(lastChildRef);

        const handleTabKey = () => {
            const firstElement = firstFocusableElements[0];
            const lastElement = lastFocusableElements[lastFocusableElements?.length - 1];

            if (!firstElement || !lastElement) {
                return;
            }

            if (event.shiftKey && document.activeElement === firstElement) {
                event.preventDefault();
                lastElement.focus();
            } else if (!event.shiftKey && document.activeElement === lastElement) {
                event.preventDefault();
                firstElement.focus();
            }
        };
        if (event.key === "Tab") {
            handleTabKey();
        }
    };

    const handleClick = () => {
        setIsOpen((open) => !open);
        if (!isOpen && buttonRef.current) {
            buttonRef.current.focus();
        }
    };

    const handleKeyDown = (event) => {
        event.stopPropagation();
        if (event.key === "Escape" && isOpen) {
            setIsOpen(false);
            if (buttonRef.current) {
                buttonRef.current.focus();
            }
        }
        if (event.key === " " || event.key === "Spacebar") {
            document.activeElement.click();
        }
    };

    useEffect(() => {
        setHasActiveDropdown(hasActive && !isOpen);
    }, [isOpen, hasActive]);

    useEffect(() => {
        if (isSidebarCollapsed) {
            setIsOpen(false);
        }
    }, [isSidebarCollapsed]);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener("keydown", trapFocus);
            window.addEventListener("keydown", handleKeyDown);
        } else {
            window.removeEventListener("keydown", trapFocus);
            window.removeEventListener("keydown", handleKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", trapFocus);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen]);

    return (
        <MenuDropdownContext.Provider value={{ isOpen, isInsideDropdown: true }}>
            <Box
                id={id}
                component="li"
                className={classNames("menu-dropdown", className, { "is-active-dropdown": hasActiveDropdown })}
                ref={node}>
                <Box display="flex" fullWidth alignX="between" alignY="center">
                    <Button
                        ref={buttonRef}
                        image={image}
                        loading={fetching}
                        onClick={handleClick}
                        className={classNames("menu-dropdown-btn", {
                            "is-opened": isOpen,
                            "single-environment": children.length === 0,
                        })}
                        labelClassName="menu-dropdown-btn-text"
                        {...(titleKey && { label: titleKey })}
                        bsStyle={bsStyle}
                        expandable
                        aria-haspopup
                        aria-expanded={isOpen}
                        disabled={disabled || children.length === 0}>
                        {useChildren}
                        {notificationCount > 0 && (
                            <>
                                <Badge count={notificationCount} />
                                <Text labelKey={notificationCountTextID} className="visually-hidden" />
                            </>
                        )}
                        {children.length > 0 && (
                            <Image
                                className={classNames("svg-image", { "rotate-arrow": isOpen })}
                                wrapperClassName={classNames("menu-dropdown-arrow")}
                                src="images/styledmenu-arrow-down-evenodd.svg"
                                width="3"
                            />
                        )}
                    </Button>
                </Box>
                {children.length > 0 && (
                    <Box
                        className={classNames("menu-dropdown-content", openWrapperClassName, {
                            "is-opened": isOpen,
                        })}>
                        <ul ref={ulRef} className="menu-dropdown-ul">
                            {children.map((child, ix) => (
                                <li
                                    // eslint-disable-next-line no-nested-ternary
                                    {...(ix === 0
                                        ? { ref: firstChildRef }
                                        : ix === children.length - 1
                                        ? { ref: lastChildRef }
                                        : {})}
                                    className="menu-dropdown-item">
                                    {React.cloneElement(child, { component: "div" })}
                                </li>
                            ))}
                        </ul>
                    </Box>
                )}
            </Box>
        </MenuDropdownContext.Provider>
    );
};

MenuDropdown.propTypes = {
    children: arrayOf(element).isRequired,
    isSidebarCollapsed: bool.isRequired,
    className: string,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    openWrapperClassName: string,
    fetching: bool,
    titleKey: string,
    image: string,
    bsStyle: string,
    disabled: bool,
    notificationCount: number,
    notificationCountTextID: string,
    id: string,
    titleKeyAsChildren: string,
    useChildren: node,
};

MenuDropdown.defaultProps = {
    className: null,
    bsStyle: null,
    fetching: false,
    openWrapperClassName: null,
    titleKey: null,
    image: null,
    disabled: false,
    notificationCount: 0,
    notificationCountTextID: "",
    id: null,
    titleKeyAsChildren: null,
    useChildren: null,
};

const mapStateToProps = (state) => ({
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
});

export default withRouter(connect(mapStateToProps)(MenuDropdown));

import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";

import { credentialsToUnderscoreFormat } from "util/form.js";
import * as utilDownload from "util/download";
import * as form from "middleware/form";
import * as i18n from "util/i18n";

const createSignaturesSchemePreRequest = (middleware, actions) =>
    function* createSignaturesSchemePreRequestSaga() {
        const { data, type } = yield call(middleware.createSignaturesSchemePre);

        if (type === "W") {
            yield put(actions.createSignaturesSchemePreFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationCreateSignatureScheme",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.create.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.createSignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const createSignaturesSchemeRequest = (middleware, actions) =>
    function* createSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { setSubmitting, setErrors } = formikBag;
        const { credentials, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const { data, type } = yield call(middleware.createSignaturesScheme, {
            ...restOfParams,
            ...credentialsWithUnderscore,
        });

        setSubmitting(false);

        if (type === "W") {
            let errorMessage = data.data.NO_FIELD || i18n.get("global.unexpectedError");
            setErrors(data.data);

            if (data.code === "COR054W") {
                errorMessage = data.message;
            }

            yield put(
                notificationActions.showNotification(errorMessage, "error", ["administrationCreateSignatureScheme"]),
            );
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("administration.signatures.create.confirm.success"),
                    "success",
                    ["administrationSignaturesSchemes"],
                ),
            );
            yield put(replace(`/administration/medium/signaturesSchemes`));
            yield put(actions.createSignaturesSchemeSuccess(data.data));
        }
    };

const deleteSignaturesSchemePreRequest = (middleware, actions) =>
    function* deleteSignaturesSchemePreRequestSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield call(middleware.deleteSignaturesSchemePre, idSignature);

        if (type === "W") {
            yield put(actions.deleteSignaturesSchemePreFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationSignaturesSchemes",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.delete.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.deleteSignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const deleteSignaturesSchemeRequest = (middleware, actions) =>
    function* deleteSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { credentials, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const { products = [], functionalGroups = [], groupsMap, idSignature, signatureType } = restOfParams;
        const { data, type } = yield call(middleware.deleteSignaturesScheme, {
            ...credentialsWithUnderscore,
            products,
            functionalGroups,
            signatureId: idSignature,
            signatureLevelsCounts: groupsMap,
            signatureType,
        });

        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (type === "W") {
            let errorMessage = data.data.NO_FIELD || i18n.get("global.unexpectedError");
            setErrors(data.data);

            if (data.code === "COR054W") {
                errorMessage = data.message;
            }

            yield put(
                notificationActions.showNotification(errorMessage, "error", ["administrationDeleteSignatureScheme"]),
            );
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("administration.signatures.delete.medium.confirm.success"),
                    "success",
                    ["administrationSignaturesSchemes"],
                ),
            );
            yield put(replace(`/administration/medium/signaturesSchemes`));
            yield put(actions.deleteSignaturesSchemeSuccess(data.data));
        }
    };

const listSignaturesSchemesRequest = (middleware, actions) =>
    function* listSignaturesSchemesRequestSaga({ params }) {
        const { data, type } = yield call(middleware.listSignaturesSchemes, params ? params.filters : params);

        if (type === "W") {
            yield put(actions.listSignaturesSchemesFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationSignaturesSchemes",
                ]),
            );
        } else {
            const signatureFeaturesList = [];
            if (data.data && data.data.signatures && Array.isArray(data.data.signatures)) {
                data.data.signatures.forEach((signature) => {
                    if (signature && signature.signatureFeatures && Array.isArray(signature.signatureFeatures)) {
                        signatureFeaturesList.push(...signature.signatureFeatures);
                    }
                });
            }

            const dataAdded = { ...data.data, signatureFeaturesList };

            yield put(actions.listSignaturesSchemesSuccess(dataAdded));
        }
    };

const loadMoreSignaturesRequest = (middleware, actions) =>
    function* loadMoreSignaturesRequestSaga({ pageNumber }) {
        const { data, type } = yield call(middleware.listSignaturesSchemes, pageNumber);

        if (type === "W") {
            yield put(actions.listSignaturesSchemesFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationSignaturesSchemes",
                ]),
            );
        } else {
            yield put(actions.listMoreSignaturesSchemesSuccess(data.data));
        }
    };

const modifySignaturesSchemePreRequest = (middleware, actions) =>
    function* modifySignaturesSchemePreRequesSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield call(middleware.modifySignaturesSchemePre, idSignature);

        if (type === "W") {
            yield put(actions.modifySignaturesSchemePreFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationModifySignatureScheme",
                ]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.modify.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.modifySignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const modifySignaturesSchemeRequest = (middleware, actions) =>
    function* modifySignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { credentials, signatureLevelsCounts, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const { data, type } = yield call(middleware.modifySignaturesScheme, {
            ...restOfParams,
            ...credentialsWithUnderscore,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (type === "W") {
            let errorMessage = data.data.NO_FIELD || i18n.get("global.unexpectedError");
            setErrors(data.data);

            if (data.code === "COR054W") {
                errorMessage = data.message;
            }

            yield put(
                notificationActions.showNotification(errorMessage, "error", ["administrationModifySignatureScheme"]),
            );
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("administration.signatures.modify.medium.confirm.success"),
                    "success",
                    ["administrationSignaturesSchemes"],
                ),
            );
            yield put(replace(`/administration/medium/signaturesSchemes`));
            yield put(actions.modifySignaturesSchemeSuccess(data.data));
        }
    };

const signatureSchemeExportRequest = (middleware, actions) =>
    function* signatureSchemeExportRequestSaga({ format }) {
        const { data, type } = yield call(middleware.exportSignatureSchemes, { format, orderBy: "id_signature ASC" });

        if (type === "W") {
            yield put(actions.exportListFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "administrationUsers",
                ]),
            );
        } else {
            const { content, fileName } = data.data;
            if (format === "pdf") {
                utilDownload.downloadPdf(fileName, content);
            } else {
                utilDownload.downloadXls(fileName, content);
            }
            yield put(actions.exportListSuccess());
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.CREATE_SIGNATURES_SCHEME_PRE_REQUEST, createSignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.CREATE_SIGNATURES_SCHEME_REQUEST, createSignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.DELETE_SIGNATURES_SCHEME_PRE_REQUEST, deleteSignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.DELETE_SIGNATURES_SCHEME_REQUEST, deleteSignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.LIST_SIGNATURES_SCHEMES_REQUEST, listSignaturesSchemesRequest(middleware, actions)),
    takeLatest(types.SIGNATURES_SCHEME_LOAD_MORE_REQUEST, loadMoreSignaturesRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST, modifySignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_REQUEST, modifySignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST, signatureSchemeExportRequest(middleware, actions)),
];

export default sagasCreator;

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { arrayOf, bool, element, func, oneOfType, string } from "prop-types";
import React, { Component, Fragment } from "react";

class EditableLabel extends Component {
    static propTypes = {
        hidePlaceholder: bool,
        placeholder: string,
        onSave: func.isRequired,
        value: string.isRequired,
        children: oneOfType([element, arrayOf(element)]).isRequired,
        hideEditButton: bool,
    };

    static defaultProps = {
        hidePlaceholder: true,
        placeholder: "",
        hideEditButton: false,
    };

    state = {
        isEditing: false,
        value: "",
    };

    handleEdit = () => {
        const { value } = this.props;

        this.setState({ isEditing: true, value });
    };

    handleKeyDown = (event) => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (event.key === "Enter" && onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        } else if (event.key === "Escape") {
            this.setState({ isEditing: false });
        }
    };

    handleOnChange = (event) => {
        this.setState({ value: event.target.value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        }
    };

    handleCancel = () => {
        const { value } = this.props;

        this.setState({
            isEditing: false,
            value,
        });
    };

    render() {
        const { isEditing, value } = this.state;
        const { children, hidePlaceholder, placeholder, hideEditButton } = this.props;

        return (
            (isEditing && (
                <Fragment>
                    <label htmlFor="modifyAlias" className="visually-hidden">
                        <I18n id="products.alias.editingAliasLabel.a11y" />
                    </label>
                    <input
                        id="modifyAlias"
                        className="form-control"
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={!hidePlaceholder ? placeholder : ""}
                        value={value}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={isEditing}
                        maxLength="28"
                    />
                    <Button
                        bsStyle="link"
                        image="images/check.svg"
                        label="global.modify"
                        labelClassName="visually-hidden"
                        onClick={this.handleSave}
                    />
                    <Button
                        bsStyle="link"
                        image="images/cross.svg"
                        label="global.cancel"
                        labelClassName="visually-hidden"
                        onClick={this.handleCancel}
                    />
                </Fragment>
            )) || (
                <>
                    <Box className="d-grid" fullWidth>
                        {children}
                    </Box>
                    {!hideEditButton && (
                        <Button
                            className="btn-edit"
                            image="images/editPen.svg"
                            label="global.edit"
                            onClick={this.handleEdit}
                        />
                    )}
                </>
            )
        );
    }
}

export default EditableLabel;

import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

const FieldError = (props) => {
    const {
        error,
        relativeStyleError,
        noEllipsis,
        absoluteStyleError,
        errorTooltipPosition,
        className,
        removeFullWidth,
    } = props;

    const [showEllipsis, setShowEllipsis] = useState(false);
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [tooltipWidth, setTooltipWidth] = useState(0);

    const wrapperRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const wrapper = wrapperRef.current;
            const tooltip = tooltipRef.current;

            if (wrapper && tooltip) {
                tooltip.classList.remove("full-width");

                setWrapperWidth(wrapper.offsetWidth);
                setTooltipWidth(tooltip.offsetWidth);

                const shouldShowEllipsis = wrapperWidth <= tooltipWidth;

                if (shouldShowEllipsis !== showEllipsis) {
                    setShowEllipsis(shouldShowEllipsis);
                }

                if (shouldShowEllipsis) {
                    tooltip.classList.add("full-width");
                }
            }
        };

        const observer = new ResizeObserver(handleResize);

        const wrapper = wrapperRef.current;
        const tooltip = tooltipRef.current;

        if (wrapper) {
            observer.observe(wrapper);
        }
        if (tooltip) {
            observer.observe(tooltip);
        }

        handleResize();

        return () => {
            if (wrapper) {
                observer.unobserve(wrapper);
            }
            if (tooltip) {
                observer.unobserve(tooltip);
            }
            observer.disconnect();
        };
    }, [wrapperWidth, tooltipWidth]);

    return (
        error && (
            <Box
                {...(!removeFullWidth && { fullWidth: true })}
                className={classNames("form-group-error-wrapper", className, {
                    "form-group-error-wrapper-relative": relativeStyleError,
                    "form-group-error-wrapper-absolute": absoluteStyleError,
                    "z-index-menu": !noEllipsis && showEllipsis,
                })}>
                <div ref={wrapperRef} className="form-group-error mt-1">
                    <div ref={tooltipRef} {...(showEllipsis && { className: "full-width" })}>
                        {!noEllipsis && showEllipsis ? (
                            <Tooltip
                                onHover
                                forEllipsis
                                inText={error}
                                position={errorTooltipPosition}
                                className="cursor-pointer full-width">
                                <Box display="flex" alignY="center" className="min-height-5">
                                    <Image
                                        src="images/icons/errorIcon.svg"
                                        className="svg-icon error-icon"
                                        wrapperWidth="6"
                                    />
                                    <Text className="mx-1" ellipsis>
                                        {error}
                                    </Text>
                                </Box>
                            </Tooltip>
                        ) : (
                            <Box display="flex" alignY="center" className="min-height-5">
                                <Image
                                    src="images/icons/errorIcon.svg"
                                    className="svg-icon error-icon"
                                    wrapperWidth="6"
                                />
                                <Text>{error}</Text>
                            </Box>
                        )}
                    </div>
                </div>
            </Box>
        )
    );
};

FieldError.propTypes = {
    error: string.isRequired,
    relativeStyleError: bool,
    noEllipsis: bool,
    absoluteStyleError: bool,
    errorTooltipPosition: string,
    className: string,
    removeFullWidth: bool,
};

FieldError.defaultProps = {
    relativeStyleError: false,
    noEllipsis: false,
    absoluteStyleError: false,
    errorTooltipPosition: "bottom-left",
    className: null,
    removeFullWidth: false,
};

export default FieldError;

import classNames from "classnames";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";

class SwitchField extends Component {
    static propTypes = {
        name: string,
        onChange: func,
        onBlur: func,
        error: string,
        label: string,
        hideLabel: bool,
        idForm: string,
        value: bool,
        formGroup: bool,
        biggerLabel: bool,
        block: bool,
        noMarginBottom: bool,
        color: string,
        size: string,
        bold: bool,
        lineHeight125: bool,
    };

    static defaultProps = {
        name: "",
        error: "",
        label: "",
        hideLabel: false,
        idForm: "",
        value: false,
        onChange: () => {},
        onBlur: null,
        formGroup: false,
        biggerLabel: false,
        block: true,
        noMarginBottom: false,
        color: null,
        size: null,
        bold: false,
        lineHeight125: false,
    };

    handleChange = ({ target, ...event }) => {
        const { onChange } = this.props;

        onChange({
            ...event,
            target: {
                ...target,
                value: target.value === "true",
            },
        });
    };

    render() {
        const {
            idForm,
            error,
            hideLabel,
            label,
            value,
            formGroup,
            biggerLabel,
            block,
            noMarginBottom,
            color,
            size,
            bold,
            lineHeight125,
            ...props
        } = this.props;

        return (
            <>
                <div
                    className={classNames(
                        "c-control c-control-block c-control--switch display-flex align-items-center position-relative mr-5",
                        {
                            "form-group": formGroup,
                            "has-error": error,
                        },
                    )}>
                    <Box display="flex" alignY="center">
                        <input
                            id={props.name}
                            className="c-control-input"
                            type="checkbox"
                            {...props}
                            onChange={this.handleChange}
                            value={!value}
                            checked={value}
                        />

                        <label
                            className={classNames("c-control-label", { disabled: props.disabled })}
                            htmlFor={props.name}>
                            {!hideLabel && label ? (
                                <Text
                                    size={size}
                                    color={color}
                                    bold={bold}
                                    lineHeight125={lineHeight125}
                                    className="ml-3">
                                    {label}
                                </Text>
                            ) : (
                                !hideLabel && (
                                    <Text
                                        labelKey={`${idForm}.${props.name}.label`}
                                        size={size}
                                        color={color}
                                        bold={bold}
                                        lineHeight125={lineHeight125}
                                        className="ml-3"
                                    />
                                )
                            )}
                        </label>
                    </Box>

                    {error && <FieldError error={error} />}
                </div>
            </>
        );
    }
}

export default SwitchField;

import { CORPORATE_GROUP_ENVIRONMENT_TYPE, CORPORATE_ENVIRONMENT_TYPE } from "constants.js";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";
import ListHistoric from "pages/transactions/_components/ListHistoric";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import ProductFilters from "pages/_components/ProductFilters";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as configUtils from "util/config";
import TransactionFilterProvider, {
    PDF_CONTENT_TYPE,
    URL_LIST_TRANSACTION_HISTORY,
    XLS_CONTENT_TYPE,
} from "providers/TransactionFilterProvider";
import * as utilsI18n from "util/i18n";

const LAST_TRANSACTIONS = "last";

class TransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        lastRegistryNumber: string,
    };

    static defaultProps = {
        lastRegistryNumber: "0",
    };

    state = {
        defaultFilters: null,
        ascending: false,
        filtersValue: null,
    };

    componentDidMount = () => {
        const { activeEnvironment, dispatch } = this.props;
        const isCorporate = activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE;

        let dateFrom = null;
        let dateTo = isCorporate
            ? configUtils.get("transaction.list.disclaimer.date.corporate")
            : configUtils.get("transaction.list.disclaimer.date");
        if (dateTo) {
            const sections = dateTo.split("/");
            dateTo = sections.length > 2 ? `${sections[1]}/${sections[0]}/${sections[2]}` : new Date();

            let month = sections[1];
            let year = sections[2];

            if (month === "01") {
                month = "12";
                year -= 1;
            } else if (month !== "11" && month !== "12") {
                month = `0${month - 1}`;
            } else {
                month -= 1;
            }
            dateFrom = sections.length > 2 ? `${month}/${sections[0]}/${year}` : new Date();
        }

        const defaultFilters = {
            dateFrom,
            dateTo,
            pageNumber: 1,
            filter: LAST_TRANSACTIONS,
        };

        this.setState({ defaultFilters });
        dispatch(transactionsActions.loadHistoricListRequest(defaultFilters, true, "0"));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(replace("/transactions/list"));
    };

    handleOrder = () => {
        const { ascending } = this.state;
        this.setState({ ascending: !ascending });
    };

    resetHandleOrder = () => {
        this.setState({ ascending: false });
    };

    render() {
        const { fetching, isDesktop, dispatch, isFirstFetching, activeEnvironment, lastRegistryNumber } = this.props;
        const { defaultFilters, ascending, filtersValue } = this.state;
        const isCorporateGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;
        const documents = [
            {
                label: "accounts.pdfFile",
                onClick: (callback) => {
                    callback(PDF_CONTENT_TYPE, filtersValue);
                },
            },
            {
                label: "accounts.xlsFile",
                onClick: (callback) => {
                    callback(XLS_CONTENT_TYPE, filtersValue);
                },
            },
        ];
        const isCorporate = activeEnvironment.type === CORPORATE_ENVIRONMENT_TYPE;
        const disclaimerDate = isCorporate
            ? configUtils.get("transaction.list.disclaimer.date.corporate")
            : configUtils.get("transaction.list.disclaimer.date");
        const titleText = utilsI18n.get(`transactions.list.historic.title`, null, {
            DATE: disclaimerDate,
        });

        const setFilterValues = (dateFrom, dateTo, channelType, status) => {
            let newFilters = {};
            if (status) {
                newFilters = {
                    status,
                    dateFrom: defaultFilters?.dateFrom,
                    dateTo: defaultFilters?.dateTo,
                    channelType: null,
                };
            } else if (channelType) {
                newFilters = {
                    dateFrom: defaultFilters?.dateFrom,
                    dateTo: defaultFilters?.dateTo,
                    channelType,
                };
            } else if (dateFrom || dateTo) {
                newFilters = {
                    ...filtersValue,
                    dateFrom: dateFrom || (filtersValue?.dateFrom ? filtersValue?.dateFrom : defaultFilters?.dateFrom),
                    dateTo: dateTo || (filtersValue?.dateTo ? filtersValue?.dateTo : defaultFilters?.dateTo),
                    channelType: null,
                };
            }
            this.setState({ filtersValue: newFilters });
        };

        return (
            <TransactionFilterProvider
                url={URL_LIST_TRANSACTION_HISTORY}
                defaultFilters={defaultFilters}
                lastRegistryNumber={lastRegistryNumber}>
                <Notification scopeToShow="historicTransactions" />
                <Head
                    titleText={titleText}
                    onBack={this.handleBack}
                    exportList={documents}
                    exportListBsStyle="only-icon"
                />
                <MainContainer showLoader={isFirstFetching} showChildrenWithLoader className="px-0">
                    <div className="above-the-fold">
                        <ProductFilters
                            alwaysShowChips
                            closeMoreFiltersWhenSiblingClick
                            borderRadiusTop
                            notCollapsedFilters
                            documents={documents}
                            disclaimerDate={disclaimerDate}
                            notBorderTop
                            historic
                            moreFilters={
                                <HiddenFilters
                                    dispatch={dispatch}
                                    isDesktop={isDesktop}
                                    fetching={fetching}
                                    isTransactionList
                                    dateFromIsRequired
                                    historic
                                    resetHandleOrder={this.resetHandleOrder}
                                    setFilterValues={setFilterValues}
                                />
                            }
                            moreFiltersClosedKeyLabel="global.productFilters.btnFilter.closed.label"
                            moreFiltersOpenedKeyLabel="global.productFilters.btnFilter.opened.label"
                            filterDownloadBtn
                            isDesktop={isDesktop}
                        />
                        <ListHistoric
                            isDesktop={isDesktop}
                            showSearchButton
                            defaultFilters={defaultFilters}
                            showEnvironment={isCorporateGroup}
                            itemsAreClickeable={!isCorporateGroup}
                            handleOrder={this.handleOrder}
                            ascending={ascending}
                        />
                    </div>
                </MainContainer>
            </TransactionFilterProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    isFirstFetching: transactionsSelectors.getFirstFetch(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lastRegistryNumber: transactionsSelectors.getLastRegistryNumber(state),
    previousRegistryNumber: transactionsSelectors.getPreviousRegistryNumber(state),
});

export default connect(mapStateToProps)(TransactionsList);

import React, { Component } from "react";
import Row from "pages/_components/Row";
import Box from "pages/_components/Box";
import Col from "react-bootstrap/lib/Col";
import InfoCardBig from "pages/_components/InfoCardBig";
import { bool, shape } from "prop-types";
import { format } from "date-fns";

class Conditions extends Component {
    render() {
        const { creditCard, isDesktop } = this.props;
        const lastPaymentDateStr = creditCard.lastPaymentDate ? format(creditCard.lastPaymentDate, "DD/MM/YYYY") : null;

        const infoCardBigArray = [
            {
                title: `creditCard.detail.condition.credit.limit`,
                quantity: creditCard.creditLimit,
                currency: creditCard.creditLimitCurrency,
            },
            {
                title: `creditCard.detail.condition.lastPayment.date`,
                quantity: lastPaymentDateStr,
            },
            {
                title: `creditCard.detail.condition.interest.rate`,
                quantity: `${creditCard.interestRate}%`,
            },
        ];
        if (!creditCard?.isCorporative) {
            infoCardBigArray.push({
                title: `creditCard.detail.condition.purchases.installments`,
                tooltipText: `creditCard.detail.condition.purchases.installments.tooltip`,
                ...(isDesktop ? { tooltipPosition: "left-centered" } : { tooltipPosition: "bottom-centered" }),
                currency: creditCard.purchasesInstallmentsCurrency,
                quantity: creditCard.purchasesInstallments,
            });
            infoCardBigArray.push({
                title: `creditCard.detail.condition.bonus.balance`,
                tooltipText: `creditCard.detail.condition.bonus.balance.tooltip`,
                ...(isDesktop ? { tooltipPosition: "left-centered" } : { tooltipPosition: "bottom-centered" }),
                quantity: creditCard.bonusBalance,
                currency: creditCard.bonusBalanceCurrency,
            });
        }

        return (
            <Box
                display="flex"
                background={isDesktop ? "white" : "transparent"}
                borderRadius="default"
                className="px-5 pt-3 pb-8 px-md-12 pt-md-9 pb-md-12">
                <Row gapY="3" className="px-0">
                    {infoCardBigArray.map((el) => {
                        let elementRender = null;
                        if (el.title) {
                            elementRender = (
                                <Col xs={12} md={6} className="height-auto">
                                    <InfoCardBig
                                        title={el.title}
                                        tooltipText={el.tooltipText}
                                        tooltipPosition={el.tooltipPosition}
                                        currency={el.currency}
                                        quantity={el.quantity}
                                        highlighted={el.highlighted}
                                        withBorder
                                        className="py-5 py-md-7 px-5 mb-0"
                                    />
                                </Col>
                            );
                        }
                        return elementRender;
                    })}
                </Row>
            </Box>
        );
    }
}

Conditions.propTypes = {
    creditCard: shape({}),
    isDesktop: bool.isRequired,
};

Conditions.defaultProps = {
    creditCard: null,
};

export default Conditions;

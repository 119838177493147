import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import Select from "pages/forms/_components/_fields/Select";
import Box from "pages/_components/Box";
import DatePicker from "pages/_components/fields/datepicker";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, bool, instanceOf, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";

class LapseSubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool).isRequired,
        errors: objectOf(string),
        firstWorkingDate: string.isRequired,
        nonWorkingDays: arrayOf(string).isRequired,
        value: instanceOf(moment).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        disabled: bool,
    };

    static defaultProps = {
        errors: null,
        disabled: false,
    };

    excludeDates = () => {
        const { nonWorkingDays } = this.props;

        const excludeDates = [];
        nonWorkingDays.forEach((entry) => {
            excludeDates.push(moment(entry));
        });

        return excludeDates;
    };

    filterDate = (date) => {
        const { enabledWeekDays } = this.props;

        return enabledWeekDays[(date.isoWeekday() % 7) + 1];
    };

    getNeverOption = () => (
        <li className="c-control c-control--radio c-control--has-icon display-flex gap-3 align-items-center position-relative">
            {this.getOption(schedulerUtils.NEVER)}
        </li>
    );

    getUntilOption = () => {
        const {
            errors,
            firstWorkingDate,
            value: { date, lapse },
            disabled,
        } = this.props;

        return (
            <li
                className={classNames(
                    "c-control c-control--radio c-control-block c-control--has-icon list-style-none",
                    {
                        "has-error": !isEmpty(errors) && lapse === schedulerUtils.UNTIL,
                    },
                )}>
                {/* {this.getOption(schedulerUtils.UNTIL)} */}
                <div className="form-group form-group--datepicker form-group-row">
                    <label htmlFor="untilValue.date" className="visually-hidden" aria-hidden>
                        {i18nUtils.get("scheduler.until.a11y")}
                    </label>

                    <Box display="flex" alignY="center" className="data-label-special-mb">
                        <Text
                            htmlFor="untilValue.date"
                            component="legend"
                            labelKey="scheduler.ends"
                            className="data-label"
                        />
                    </Box>
                    <Box className="input-group">
                        <DatePicker
                            tabIndex={lapse === schedulerUtils.UNTIL ? "0" : "-1"}
                            className="form-control"
                            dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                            minDate={firstWorkingDate}
                            name="untilValue"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(newDate) => this.handleChange(schedulerUtils.UNTIL, newDate)}
                            selected={moment(date || moment().format("YYYY-MM-DD"))}
                            autoComplete="off"
                            id="untilValue.date"
                            showYearDropdown
                            showMonthDropdown
                            disabled={disabled}
                        />
                    </Box>
                </div>
            </li>
        );
    };

    getTimesOption = () => {
        const {
            errors,
            value: { lapse, number },
        } = this.props;
        const options = Array.from({ length: 42 }, (_, index) => ({ id: index + 1, label: `${index + 1}` }));

        return (
            <li
                className={classNames(
                    "c-control c-control--radio c-control-block c-control--has-icon display-flex gap-3 align-items-center position-relative",
                    {
                        "has-error": !isEmpty(errors) && lapse === schedulerUtils.UP_TO,
                    },
                )}>
                {this.getOption(schedulerUtils.UP_TO)}
                <div className="form-group form-group--select form-group-row">
                    <div className="form-text-group">
                        <label id="label.occurrencies" className="control-label" aria-hidden>
                            <I18n id="scheduler.after" />
                        </label>
                    </div>
                    <div className="input-group">
                        <Select
                            tabIndex={lapse === schedulerUtils.UP_TO ? "0" : "-1"}
                            className="slideFromBottom"
                            clearable={false}
                            labelKey="label"
                            onChange={(option) => this.handleChange(schedulerUtils.UP_TO, option)}
                            options={options}
                            searchable={false}
                            value={number || 1}
                            valueKey="id"
                            id="occurrencies"
                        />
                    </div>
                    <div className="form-text-group">
                        <label id="label.lapseTimes" className="control-label" aria-hidden>
                            <I18n id="scheduler.lapseTimes" />
                        </label>
                    </div>
                </div>
            </li>
        );
    };

    getOption = (id) => {
        const { field } = this.props;

        return (
            <>
                <input
                    tabIndex={field.value.lapse === id ? "0" : "-1"}
                    type="radio"
                    id={id.toLowerCase()}
                    name="lapseOptions"
                    value={id}
                    className="c-control-input"
                    checked={field.value.lapse === id}
                    onChange={() => this.handleChange(id)}
                />
                <label className="c-control-label text-uppercase" htmlFor={id.toLowerCase()}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {id === schedulerUtils.NEVER && <I18n id="scheduler.never" />}
                    {id === schedulerUtils.UNTIL && (
                        <>
                            <I18n id="scheduler.on" />
                            <label className="visually-hidden" htmlFor="scheduler.on">
                                {i18nUtils.get("scheduler.until.a11y")}
                            </label>
                        </>
                    )}
                    {id === schedulerUtils.UP_TO && (
                        <label className="visually-hidden">{i18nUtils.get("scheduler.up_to.a11y")}</label>
                    )}
                </label>
            </>
        );
    };

    handleChange = (id, data) => {
        const { value, form, field, firstWorkingDate } = this.props;
        let newValue = null;
        if (id === schedulerUtils.NEVER) {
            newValue = { ...value, lapse: id };
        } else if (id === schedulerUtils.UNTIL) {
            if (firstWorkingDate && typeof data !== "undefined" && data.diff(moment(firstWorkingDate)) < 0) {
                return;
            }
            newValue = { ...value, date: data || value.date, lapse: id };
        } else if (id === schedulerUtils.UP_TO) {
            newValue = { ...value, lapse: id, number: data ? data.id : 1 };
        }
        form.setFieldValue(field.name, newValue);
        form.setTouched({ ...form.touched, [field.name]: true });
    };

    render() {
        return (
            <fieldset id="endRulesFieldsetID">
                <div className="form-group">
                    <ul className="form-check-group">{this.getUntilOption()}</ul>
                </div>
            </fieldset>
        );
    }
}

export default LapseSubOption;

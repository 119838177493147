import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { func, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";
import * as dateUtils from "util/date";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "administration.restrictions.ticket";

class RestrictionsManageTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    componentDidMount() {
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    renderDays = (bitWiseNumber) => (
        <Text>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </Text>
    );

    renderCalendarAndIPSection(onlyEnvironment, dataToSave) {
        return (
            <>
                {!dataToSave.calendarToSave && onlyEnvironment && (
                    <Box display="block" className="mb-4">
                        <Text
                            component="label"
                            labelKey="administration.restrictions.restrictions.byCalendar"
                            addColon
                            className="m-0"
                        />
                        <Text
                            labelKey={
                                dataToSave.disableCalendar
                                    ? "administration.restrictions.disableCalendar.label"
                                    : "administration.restrictions.enableCalendar.label"
                            }
                        />
                    </Box>
                )}
                {!dataToSave.perpetual &&
                    dataToSave.calendarToSave &&
                    !dataToSave.disableCalendar &&
                    (dataToSave.calendarEnabled || !!dataToSave.idUser) && (
                        <>
                            <Box display="block" className="mb-4">
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.timeZone.label"
                                    addColon
                                    className="m-0"
                                />
                                <Text>{dataToSave.timeZone}</Text>
                            </Box>

                            <Box display="block" className="mb-4">
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.restrictions.days"
                                    addColon
                                    className="m-0"
                                />
                                {dataToSave.days !== 127 && this.renderDays(dataToSave.days)}
                                {dataToSave.days === 127 && (
                                    <Text labelKey="administration.restrictions.allDays.label" />
                                )}
                            </Box>

                            <Box display="block" className="mb-4">
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.restrictions.time"
                                    addColon
                                    className="m-0"
                                />
                                <Text>
                                    <Text labelKey="administration.restrictions.from" />
                                    {` ${dateUtils.timeinMinToString(dataToSave.startTime)} `}
                                    <Text labelKey="administration.restrictions.to" />
                                    {` ${dateUtils.timeinMinToString(dataToSave.endTime)} `}
                                    <Text labelKey="administration.restrictions.hrs" />
                                </Text>
                            </Box>
                        </>
                    )}
                {!dataToSave.idUser && dataToSave.calendarToSave && !dataToSave.calendarEnabled && (
                    <Box display="block" className="mb-4">
                        <Text
                            component="label"
                            labelKey="administration.restrictions.disableCalendar.label"
                            addColon
                            className="m-0"
                        />
                        <Text labelKey="administration.restrictions.disableCalendar.text" />
                    </Box>
                )}
                {/* NO BORRAR (IMPLEMENTACION PRODUCTO) */}
                {/* ip Detail */}
                {/* {(dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP ? (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id="administration.restrictions.IP" />
                        </div>
                        <div className="data-desc">
                            {dataToSave.anyIP && <I18n id="administration.restrictions.anyIP.label" />}
                            <span>{!dataToSave.anyIP && dataToSave.ipsList.join(", ")}</span>
                        </div>
                        <Box display="block" className="mb-4">
                            <Text
                                component="label"
                                labelKey="administration.restrictions.IP"
                                addColon
                                className="m-0"
                            />
                            <Text>
                                {dataToSave.anyIP && <Text labelKey="administration.restrictions.anyIP.label" />}
                                <Text>{!dataToSave.anyIP && dataToSave.ipsList.join(", ")}</Text>
                            </Text>
                        </Box>
                    </div>
                ) : (
                    <>
                        {onlyEnvironment && (
                            <Box display="block" className="mb-4">
                                <Text
                                    component="label"
                                    labelKey="administration.restrictions.restrictions.byIP"
                                    addColon
                                    className="m-0"
                                />
                                <Text
                                    labelKey={
                                        dataToSave.disableIP
                                            ? "administration.restrictions.disableIP.label"
                                            : "administration.restrictions.enableIP.label"
                                    }
                                />
                            </Box>
                        )}
                    </>
                )} */}
            </>
        );
    }

    renderDetail(data) {
        const onlyEnvironment = typeof data.idUser === "undefined";
        return (
            <>
                <Box display="block" className="mb-4">
                    <Text
                        component="label"
                        labelKey="administration.restrictions.restrictions.type"
                        addColon
                        className="m-0"
                    />
                    <Text
                        labelKey={
                            onlyEnvironment
                                ? "administration.restrictions.restrictions.environment"
                                : "administration.restrictions.restrictions.user"
                        }
                    />
                </Box>
                {this.renderCalendarAndIPSection(onlyEnvironment, data)}
            </>
        );
    }

    render() {
        const { transactionData } = this.props;
        if (transactionData.data) {
            return (
                <Box display="flex" column>
                    {this.renderDetail(transactionData.data)}
                </Box>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(RestrictionsManageTicketData));

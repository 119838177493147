import Image from "pages/_components/Image";
import { bool, string } from "prop-types";
import React, { Component } from "react";
import { config, Spring } from "react-spring";
import classNames from "classnames";

class DropdownArrow extends Component {
    static propTypes = {
        isActive: bool.isRequired,
        arrowColor: string,
        linkStyle: bool,
    };

    static defaultProps = {
        arrowColor: "primary-color",
        linkStyle: false,
    };

    render() {
        const { isActive, arrowColor, linkStyle } = this.props;

        return (
            <Spring
                config={config.stiff}
                from={{ transform: "rotate(90deg)" }}
                to={{
                    transform: isActive ? "rotate(180deg)" : "rotate(0)",
                }}>
                {(styles) => (
                    <Image
                        src="images/icons/accordionArrow.svg"
                        styles={styles}
                        color={arrowColor}
                        className={classNames("svg-image", {
                            "rotate-180": isActive && !linkStyle,
                            "rotate-90": linkStyle,
                        })}
                    />
                )}
            </Spring>
        );
    }
}

export default DropdownArrow;

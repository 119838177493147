import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors, permissionsSelectors } from "reducers/administration";
import { detailsActions, permissionsActions } from "reducers/administration/medium";
import { actions, selectors as administrationUsersSelector } from "reducers/administration/users";
import { actions as restrictionActions } from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";

import Details from "pages/administration/_components/Details";

const mapStateToProps = (state) => {
    const user = detailsSelectors.getUser(state);
    const userInfo = administrationUsersSelector.getUserInfo(state, user);
    const fetchingUser = detailsSelectors.isFetching(state);
    const fetchingUserInfo = administrationUsersSelector.isFetching(state);
    const loggedUser = sessionSelectors.getUser(state);
    const permissions = permissionsSelectors.getPermissions(state);
    const groups = permissionsSelectors.getGroups(state);

    let hasActiveSignature = false;
    if (permissions && groups) {
        let list = [];
        groups.forEach((el) => {
            if (el.idItem === "transfers" || el.idItem === "payments") {
                list = [...list, ...el.childrenList];
            }
        });

        let contador = 0;
        Object.keys(permissions).forEach((el) => {
            contador += list.some((elem) => elem.idItem === el);
        });
        hasActiveSignature = contador > 0;
    }

    return {
        user,
        userInfo,
        fetching: fetchingUser || fetchingUserInfo,
        hasSignatureEnabled: detailsSelectors.hasSignatureEnabled(state),
        hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
        userEnvStatus: detailsSelectors.getUserEnvStatus(state),
        userTokenStatus: detailsSelectors.getUserTokenStatus(state),
        schemeName: "medium",
        loggedUser,
        hasActiveSignature,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        { ...actions, ...detailsActions, ...restrictionActions, ...permissionsActions },
        dispatch,
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);

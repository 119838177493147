import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { func, bool, shape } from "prop-types";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { push, routerActions } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import { Modal } from "react-bootstrap";
import * as config from "util/config";
import TokenActivationHelp from "./TokenActivationHelp";

const DEFAULT_MAX_ATTEMPS = "3";
const TokenActivationAttemps = ({ dispatch, isLoggedIn, history }) => {
    const [showTutorial, setShowTutorial] = useState(false);

    const continueValidation = () => {
        dispatch(push(isLoggedIn ? "/auth/tokenActivationStep2" : "/tokenActivationStep2"));
    };

    const isLastAttempt = useMemo(() => {
        const maxAttemps = config.get("token.activation.attemps.max") || DEFAULT_MAX_ATTEMPS;
        return (
            (history?.location?.state?.currentAttempt &&
                history.location.state.currentAttempt >= parseInt(maxAttemps, 10)) ||
            false
        );
    }, [history, history?.location, history?.location?.state, history?.location?.state?.currentAttempt]);

    return (
        <>
            <Modal aria-labelledby="modalTitleID" aria-modal="true" show={showTutorial} className="modal-splash">
                <div className="modal-container">
                    <Box fullHeight fullWidth>
                        <TokenActivationHelp
                            onCloseCustom={() => {
                                setShowTutorial(false);
                            }}
                        />
                    </Box>
                </div>
            </Modal>
            <Head title="token.activation.title" hideNavbarInMobile additionalClassName="background-primary" />
            <MainContainer classNameMainWrapper="background-menu-background" showLoader={false}>
                <Box
                    display="flex"
                    column
                    alignY="center"
                    background="white"
                    borderRadius="md"
                    className="mt-8"
                    fullWidth>
                    <Text
                        component="p"
                        size="1"
                        bold
                        align="center"
                        color="heading"
                        className="mx-5 mt-8 mb-0 mb-8"
                        labelKey="token.activation.attemps.title"
                    />

                    <Image className="mb-9" src="images/icons/userTokenWarning.svg" />
                    <Text component="p" align="center" size="4" color="heading" className="mx-3 mb-12">
                        {(!isLastAttempt || isLastAttempt === false) && (
                            <div>
                                {i18n.get("token.activation.attemps.description.firstPart")}{" "}
                                <Button
                                    label="token.activation.attemps.description.help.link"
                                    bsStyle="link"
                                    className="p-0 btn-link"
                                    onClick={() => {
                                        setShowTutorial(true);
                                    }}
                                />{" "}
                                {i18n.get("token.activation.attemps.description.lastPart")}
                            </div>
                        )}
                        {isLastAttempt && isLastAttempt === true && i18n.get("token.activation.attemps.empty.message")}
                    </Text>
                </Box>
                <Box display="flex" column className="mt-auto" fullWidth>
                    <Button
                        label="token.activation.failed.button.continue"
                        bsStyle="primary"
                        onClick={continueValidation}
                        block
                    />

                    <Button
                        label="token.activation.failed.button.tryAgain"
                        type="button"
                        className="btn-outline"
                        onClick={() => {
                            dispatch(
                                routerActions.replace({
                                    pathname: isLoggedIn ? "/auth/tokenActivationStep1" : "/tokenActivationStep1",
                                    state: {
                                        currentAttempt:
                                            (history?.location?.state?.currentAttempt &&
                                                history?.location?.state?.currentAttempt) ||
                                            0,
                                    },
                                }),
                            );
                        }}
                        block
                        disabled={isLastAttempt}
                    />
                </Box>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    isLoggedIn: sessionSelectors.isLoggedIn(state),
});

TokenActivationAttemps.propTypes = {
    isLoggedIn: bool,
    dispatch: func.isRequired,
    history: shape({}),
};

TokenActivationAttemps.defaultProps = {
    isLoggedIn: false,
    history: undefined,
};

export default connect(mapStateToProps)(TokenActivationAttemps);

import { createReducer, makeActionCreator } from "util/redux";
import { TRANSFER_SUPPORT_AMENDMENT_TYPE, TRANSFER_SUPPORT_CONFIRM_TYPE } from "util/transferSupport.util";

export const types = {
    GET_TRANSFER_LIST_REQUEST: "GET_TRANSFER_LIST_REQUEST",
    GET_TRANSFER_LIST_SUCCESS: "GET_TRANSFER_LIST_SUCCESS",
    GET_TRANSFER_LIST_FAILURE: "GET_TRANSFER_LIST_FAILURE",

    SEND_SUPPORT_TRANSFER_REQUEST: "SEND_SUPPORT_TRANSFER_REQUEST",
    SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS: "SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS",
    SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS_UPDATE: "SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS_UPDATE",
    SEND_SUPPORT_TRANSFER_REQUEST_FAILURE: "SEND_SUPPORT_TRANSFER_REQUEST_FAILURE",

    SET_SUPPORT_TRANSFER_SHOW_WARNING_MODAL: "SET_SUPPORT_TRANSFER_SHOW_WARNING_MODAL",
};

export const INITIAL_STATE = {
    transferList: [],
    fetching: false,
    fetchingSend: false,
    showWarningModal: true,
};

export const actions = {
    getTransferListRequest: makeActionCreator(types.GET_TRANSFER_LIST_REQUEST),
    sendTransferSupport: makeActionCreator(
        types.SEND_SUPPORT_TRANSFER_REQUEST,
        "supportRequestType",
        "transactionIdentifier",
        "beneficiaryData",
        "accountId",
        "callback",
        "callbackError",
        "scopeError",
    ),
    sendTransferSupportSuccess: makeActionCreator(
        types.SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS_UPDATE,
        "transactionIdentifier",
        "supportRequestType",
    ),
    setShowSupportTransferWarningModal: makeActionCreator(
        types.SET_SUPPORT_TRANSFER_SHOW_WARNING_MODAL,
        "showWarningModal",
    ),
};

export const selectors = {
    getTransferList: ({ transferSupport }) => transferSupport?.transferList || [],
    isFetching: ({ transferSupport }) => transferSupport?.fetching || false,
    isFetchingSend: ({ transferSupport }) => transferSupport?.fetchingSend || false,
    isShowingWarningModal: ({ transferSupport }) => transferSupport?.showWarningModal,
};

export default createReducer(INITIAL_STATE, {
    [types.SET_SUPPORT_TRANSFER_SHOW_WARNING_MODAL]: (state, action) => ({
        ...state,
        showWarningModal: action?.showWarningModal,
    }),
    [types.GET_TRANSFER_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        transferList: [],
        fetchingSend: false,
    }),
    [types.GET_TRANSFER_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: true,
        transferList: [],
    }),
    [types.GET_TRANSFER_LIST_SUCCESS]: (state, action) => ({
        ...state,
        transferList: action?.transferList || [],
        fetching: false,
    }),
    [types.SEND_SUPPORT_TRANSFER_REQUEST]: (state) => ({
        ...state,
        fetchingSend: true,
    }),
    [types.SEND_SUPPORT_TRANSFER_REQUEST_FAILURE]: (state) => ({
        ...state,
        fetchingSend: false,
        showWarningModal: true,
    }),
    [types.SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS_UPDATE]: (state, { transactionIdentifier, supportRequestType }) => {
        const updatedTransferList = state.transferList.map((transfer) => {
            if (transfer.transactionId === transactionIdentifier) {
                if (supportRequestType === TRANSFER_SUPPORT_CONFIRM_TYPE) {
                    return { ...transfer, wasConfirmed: true };
                }
                if (supportRequestType === TRANSFER_SUPPORT_AMENDMENT_TYPE) {
                    return { ...transfer, wasAmmended: true };
                }
            }
            return transfer;
        });

        return {
            ...state,
            showWarningModal: true,
            fetchingSend: false,
            transferList: updatedTransferList,
        };
    },
    [types.SEND_SUPPORT_TRANSFER_REQUEST_SUCCESS]: (state) => ({
        ...state,
        fetchingSend: false,
        showWarningModal: true,
    }),
});

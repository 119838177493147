import React, { Component, Fragment } from "react";
import Measure from "react-measure";
import { func, arrayOf, shape, bool, string, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Table from "rc-table";
import { routerActions } from "react-router-redux/actions";
import Link from "react-router-dom/Link";

import { actions, selectors } from "reducers/administration/groups";
import * as i18n from "util/i18n";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/fields/Checkbox";
import Header from "pages/_components/header/Header";
import Dropdown from "pages/_components/Dropdown";
import Image from "pages/_components/Image";

class AdministrationGroups extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        groups: arrayOf(shape({ idGroup: number, name: string, status: string, usersInGroup: number, blocked: bool }))
            .isRequired,
        groupsExtendedInfo: shape({}),
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreGroups: bool,
        currentPage: number,
        totalPages: number,
        hasMoreData: bool,
    };

    static defaultProps = {
        fetching: false,
        fetchingExport: false,
        fetchingMoreGroups: false,
        currentPage: 0,
        totalPages: 0,
        hasMoreData: false,
        groupsExtendedInfo: null,
    };

    state = {
        tableHeight: 0,
        selectedGroups: new Map(),
        selectAllChecked: false,
        blockedQuantity: 0,
        unblockedQuantity: 0,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.loadListRequest());
    }

    onRowClick = ({ idGroup }) => {
        const { dispatch } = this.props;

        dispatch(routerActions.push(`/administration/advanced/group/${idGroup}/details`));
    };

    generateTableColumns = (massiveDisabledQuantity) => {
        const { selectAllChecked, selectedGroups } = this.state;
        const { groups } = this.props;

        const columnArray = [
            {
                key: "check",
                dataIndex: "check",
                title: (
                    <Checkbox
                        hideLabel
                        onChange={this.handleSelectAll}
                        name="selectAllGroups"
                        checked={selectAllChecked || groups.length === selectedGroups.length + massiveDisabledQuantity}
                        disabled={groups.length === massiveDisabledQuantity}
                    />
                ),
                width: 20,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
            {
                key: "groupName",
                dataIndex: "groupName",
                title: i18n.get("administration.groups.list.name"),
                width: 300,
            },
            {
                key: "usersInGrouop",
                dataIndex: "usersInGrouop",
                title: i18n.get("administration.groups.list.usersInGroup"),
                width: 100,
            },
            {
                className: "text-center",
                key: "groupStatus",
                dataIndex: "groupStatus",
                title: i18n.get("administration.groups.list.status"),
                width: 200,
            },
        ];
        return columnArray;
    };

    populateGroupsData = (massiveDisabledQuantity) => {
        const { groups, groupsExtendedInfo } = this.props;
        const { selectedGroups, selectAllChecked } = this.state;

        return groups.map(({ idGroup, name, usersInGroup, blocked, status }) => ({
            key: idGroup.toString(),
            check: (
                <Checkbox
                    onChange={() => this.handleCheckClick(idGroup, name, massiveDisabledQuantity)}
                    hideLabel
                    id={idGroup}
                    name={idGroup.toString()}
                    checked={
                        groupsExtendedInfo[idGroup].massiveEnabled && (selectAllChecked || selectedGroups.has(idGroup))
                    }
                    disabled={!groupsExtendedInfo[idGroup].massiveEnabled}
                />
            ),
            idGroup,
            groupName: groupsExtendedInfo[idGroup].massiveEnabled ? (
                name
            ) : (
                <div className="data-wrapper data-wrapper-flex">
                    <span className="data-desc">{name}</span>
                    <Image src="images/administrator.svg" className="svg-icon svg-caret" />
                </div>
            ),
            usersInGrouop: usersInGroup,
            groupStatus: (
                <div className="data-wrapper data-wrapper-flex">
                    <div className={`data-tag ${blocked ? "data-tag-blocked" : ""}`}>
                        <span> {status} </span>
                    </div>
                </div>
            ),
        }));
    };

    renderPageHeader = () => {
        const { fetchingExport } = this.props;

        return (
            <Header>
                <div className="toolbar-item view-title">
                    <h2>
                        <I18n id="administration.groups.list.title" />
                    </h2>
                </div>
                <div className="toolbar-item toolbar-item--fixed">
                    <Link to="/administration/advanced/group/create/step1" className="btn btn-outline">
                        <Image src="images/plus.svg" />
                        <I18n id="administration.advanced.group.create.link.text" />
                    </Link>
                </div>

                <div className="toolbar-item toolbar-item--fixed">
                    <Dropdown
                        image="images/download.svg"
                        label="global.download"
                        fetching={fetchingExport}
                        pullRight
                        buttonClass="btn btn-outline">
                        <Button
                            onClick={() => this.handleClickDownload("pdf")}
                            label="global.download.pdf"
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                        <Button
                            onClick={() => this.handleClickDownload("xls")}
                            label="global.download.xls"
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    </Dropdown>
                </div>
            </Header>
        );
    };

    handleCheckClick = (idGroupChecked, nameGroupChecked, massiveDisabledQuantity) => {
        const { groups } = this.props;
        let { blockedQuantity, unblockedQuantity } = this.state;
        const { selectedGroups, selectAllChecked } = this.state;
        const groupChecked = groups.filter((group) => group.idGroup === idGroupChecked);

        if (!selectedGroups.has(idGroupChecked)) {
            selectedGroups.set(idGroupChecked, nameGroupChecked);
            if (groupChecked[0].blocked) {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }
            if (selectedGroups.size === groups.length - massiveDisabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedGroups.delete(idGroupChecked);
            if (groupChecked[0].blocked) {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedGroups,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { groups, groupsExtendedInfo } = this.props;
        const newSelectedGroups = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            groups.forEach((group) => {
                if (groupsExtendedInfo[group.idGroup].massiveEnabled) {
                    newSelectedGroups.set(group.idGroup, group.name);
                    if (group.blocked) {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }
        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedGroups: newSelectedGroups,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage } = this.props;

        this.setState({ selectAllChecked: false });
        dispatch(actions.loadMoreRequest({ pageNumber: currentPage + 1 }));
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;

        dispatch(actions.exportListRequest(format));
    };

    handleActionClick = (action) => {
        const { dispatch } = this.props;
        const { selectedGroups } = this.state;

        dispatch(actions.changeGroupStatusPreview([...selectedGroups.keys()], [...selectedGroups.values()], action));
    };

    renderActionButtons = () => {
        const { selectedGroups, blockedQuantity, unblockedQuantity } = this.state;

        if (selectedGroups.size > 0) {
            return (
                <Fragment>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <div
                            style={{
                                display: "inline-block",
                                marginRight: ".5rem",
                            }}>
                            {selectedGroups.size} {i18n.get("administration.groups.list.selected.quantity")}
                        </div>
                        {unblockedQuantity > 0 && (
                            <Button
                                className="btn btn-quiet btn-small"
                                key="block"
                                label="administration.block"
                                onClick={() => this.handleActionClick("block")}
                            />
                        )}
                        {blockedQuantity > 0 && (
                            <Button
                                className="btn btn-quiet btn-small"
                                key="unblock"
                                label="administration.unblock"
                                onClick={() => this.handleActionClick("unblock")}
                            />
                        )}
                        <Button
                            className="btn btn-quiet btn-small"
                            key="delete"
                            label="administration.delete"
                            onClick={() => this.handleActionClick("delete")}
                        />
                    </div>
                </Fragment>
            );
        }
        return null;
    };

    renderTableFooter = () => {
        const { totalPages, hasMoreData, fetchingMoreGroups } = this.props;

        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreData ? (
                        <div className="text-center no-more-data" key="noMoreUsers">
                            <Button
                                className="btn btn-link"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreGroups}
                                image="images/show.svg"
                                label="administration.groups.list.more"
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreUsers">
                            <p className="text-lead">
                                <I18n id="administration.groups.list.more.noMoreData" />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    render() {
        const { groupsExtendedInfo, totalPages, fetching } = this.props;
        const { selectedGroups, tableHeight } = this.state;
        const massiveDisabledQuantity = Object.values(groupsExtendedInfo).filter((group) => !group.massiveEnabled)
            .length;
        const columns = this.generateTableColumns(massiveDisabledQuantity);

        return (
            <Fragment>
                {this.renderPageHeader()}
                <MainContainer className="main-container" showLoader={fetching}>
                    <div className="above-the-fold">
                        <Container
                            className="container--layout container--scrollable-table flex-grow-1 items-center"
                            gridClassName="form-content">
                            <Col className="col-12">
                                <div className="flex-container">
                                    {selectedGroups.size === 0 ? (
                                        <Fragment>
                                            <h4>{i18n.get("administration.groups.index.title")}</h4>
                                        </Fragment>
                                    ) : (
                                        <Fragment>{this.renderActionButtons()}</Fragment>
                                    )}
                                </div>
                                {tableHeight ? (
                                    <Table
                                        columns={columns}
                                        data={this.populateGroupsData(massiveDisabledQuantity)}
                                        scroll={{ y: tableHeight }}
                                        rowKey={(record) => record.key}
                                        emptyText={i18n.get("administration.groups.list.noRecords")}
                                        onRow={(record) => ({
                                            onClick: () => this.onRowClick(record),
                                        })}
                                        footer={totalPages > 1 ? this.renderTableFooter : null}
                                    />
                                ) : (
                                    <Measure
                                        bounds
                                        onResize={({ bounds }) => this.setState({ tableHeight: bounds.height })}>
                                        {({ measureRef }) => (
                                            <div ref={measureRef} style={{ height: "100%", width: "100%" }} />
                                        )}
                                    </Measure>
                                )}
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    groups: selectors.getGroups(state),
    groupsExtendedInfo: selectors.getGroupsExtendedInfo(state),
    currentPage: selectors.getCurrentPage(state),
    totalPages: selectors.getTotalPages(state),
    hasMoreData: selectors.getHasMoreData(state),
    fetching: selectors.isFetching(state),
    fetchingMoreGroups: selectors.isFetchingMoreGroups(state),
    fetchingExport: selectors.isFetchingExport(state),
});

export default connect(mapStateToProps)(AdministrationGroups);

import Box from "pages/_components/Box/Box";
import MainContainer from "pages/_components/MainContainer";
import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { arrayOf, bool, func, string } from "prop-types";
import EnabledWalletCard from "./EnabledWalletCard";

const EnabledWalletCardModal = ({
    show,
    cardId,
    cardType,
    callbackError,
    scopeError,
    activityId,
    callbackProvision,
}) => {
    const modalRef = useRef(null);

    return (
        <Modal ref={modalRef} show={show}>
            <Box display="flex" column alignY="center">
                <Modal.Body>
                    <MainContainer classNameMainWrapper="background-menu-background" showLoader={false}>
                        <EnabledWalletCard
                            cardId={cardId}
                            cardType={cardType}
                            callbackError={callbackError}
                            scopeError={scopeError}
                            activityId={activityId}
                            callbackProvision={callbackProvision}
                        />
                    </MainContainer>
                </Modal.Body>
            </Box>
        </Modal>
    );
};

EnabledWalletCardModal.propTypes = {
    show: bool,
    cardId: string.isRequired,
    cardType: string.isRequired,
    callbackError: func,
    scopeError: arrayOf(string),
    activityId: string,
    callbackProvision: func,
};

EnabledWalletCardModal.defaultProps = {
    show: false,
    callbackError: () => {},
    scopeError: [],
    activityId: undefined,
    callbackProvision: () => {},
};
export default EnabledWalletCardModal;

import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import AvailableAmountSection from "pages/creditLines/_components/AvailableAmountSection";
import CreditLinesList from "pages/creditLines/_components/CreditLinesList";
import TotalAmountSection from "pages/creditLines/_components/TotalAmountSection";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { routerActions } from "react-router-redux/actions";
import { actions as creditLinesActions, selectors as creditLinesSelectors } from "reducers/creditLines";
import { compose } from "redux";
import { getAvailableAmountList, getSumTotalAmountList } from "./hooks/creditLinesHooks";

const CreditLines = (props) => {
    const { dispatch, isDesktop, creditLinesList, fetching, total } = props;
    const handleBack = () => {
        dispatch(routerActions.goBack());
    };

    useEffect(() => {
        dispatch(creditLinesActions.settingProductListFetching());
        if (!creditLinesList || creditLinesList.length === 0) {
            dispatch(creditLinesActions.listRequest());
        }
    }, []);

    const sumTotalAmountList = getSumTotalAmountList(total);
    const availableAmountList = getAvailableAmountList(total);

    return (
        <>
            <Notification scopeToShow="creditLines" />
            <Head onBack={handleBack} title="menu.creditLines" />
            <MainContainer showLoader={fetching}>
                {creditLinesList.length > 0 ? (
                    <Row className="mt-5 mt-md-0">
                        <Col xs={12} md={10} mdOffset={1}>
                            <Row {...(!isDesktop ? { gapY: "3" } : { className: "mb-7" })}>
                                <Col xs={12} md={6}>
                                    <TotalAmountSection list={sumTotalAmountList} />
                                </Col>

                                {isDesktop ? (
                                    <Col md={6}>
                                        <AvailableAmountSection list={availableAmountList} />
                                    </Col>
                                ) : (
                                    <AvailableAmountSection list={availableAmountList} />
                                )}
                            </Row>
                            <CreditLinesList list={creditLinesList} />
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-5 mt-md-0">
                        <Col xs={12} md={8} mdOffset={2}>
                            <EmptyTextBox text="product.empty.text.credit.lines" footerLink />
                        </Col>
                    </Row>
                )}
            </MainContainer>
        </>
    );
};

CreditLines.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    creditLinesList: arrayOf(shape({})),
    total: shape({}),
    fetching: bool,
};
CreditLines.defaultProps = {
    fetching: true,
    creditLinesList: [],
    total: {},
};

const mapStateToProps = (state) => ({
    creditLinesList: creditLinesSelectors.getList(state),
    total: creditLinesSelectors.getTotal(state),
    fetching: creditLinesSelectors.isFetching(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(CreditLines));

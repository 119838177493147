import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FeatureFlag from "pages/_components/FeatureFlag";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import ColumnHeader from "pages/_components/container/ColumnHeader";
import CapsList from "pages/administration/_components/CapsList";
import PermissionsList from "pages/administration/_components/PermissionsList";
import UserDetailsGroupList from "pages/administration/_components/UserDetailsGroupList";
import RestrictionsUserProfile from "pages/administration/restrictions/RestrictionsUserProfile";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18nUtils from "util/i18n";
import { maskEmail, maskPhoneNumber } from "util/format";
import { push } from "react-router-redux/actions";
import { connect } from "react-redux";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import {
    USER_TOKEN_STATUS_ACTIVE,
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_STARTED,
    USER_TOKEN_STATUS_LOCKED_ENTRUST,
    USER_TOKEN_STATUS_PENDING_APROVE,
    USER_TOKEN_STATUS_PENDING_UNLOCK,
    USER_TOKEN_STATUS_LOCKED_LOCAL,
} from "util/userToken.util";
/* eslint-disable react/no-multi-comp */

const PAGE_IDENTIFIER = "administratiuseron.medium.user.read";

class Details extends Component {
    static propTypes = {
        actions: shape({
            loadDetailsRequest: func.isRequired,
            changeUserStatusPreview: func.isRequired,
            updateDispatcherRequest: func.isRequired,
            getRestrictions: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({ id: string }),
        }).isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        userEnvStatus: string,
        userTokenStatus: string,
        user: shape({
            idUser: string,
            fullName: string,
        }),
        hasMassiveEnabled: bool,
        schemeName: string,
        fetching: bool.isRequired,
        signatureLevel: string.isRequired,
        userInfo: shape({
            dispatcher: bool,
        }),
        loggedUser: shape({}).isRequired,
        dispatch: func.isRequired,
        hasActiveSignature: bool.isRequired,
        setLocationCustomPath: func.isRequired,
    };

    static defaultProps = {
        userEnvStatus: "",
        userTokenStatus: "",
        user: {},
        hasMassiveEnabled: false,
        schemeName: "",
        userInfo: {},
    };

    state = {
        fetchingAction: false,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        const idUser = match.params.id;
        actions.loadDetailsRequest(idUser);
        actions.getRestrictions(idUser);
        this.setRevelockLocation();
        actions.resetLocalPermissionEdition();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/administration/users"));
    };

    handleSelect = (key, fullName) => {
        const { actions, match, userEnvStatus } = this.props;
        let actionOnTheUser = "delete";
        if (key === 0) {
            actionOnTheUser = userEnvStatus === "active" ? "block" : "unblock";
        }
        this.setState({ fetchingAction: true });
        actions.changeUserStatusPreview([match.params.id], [fullName], actionOnTheUser);
    };

    handleToggleDispatcher = (dispatcher) => {
        const { user, actions } = this.props;
        actions.updateDispatcherRequest(user.idUser, dispatcher);
    };

    renderHeader = () => {
        const { user, userEnvStatus, userInfo } = this.props;
        const actionKey = userEnvStatus === "active" ? "administration.block" : "administration.unblock";
        let userEnvironmentStatus = userEnvStatus;
        if (user.idUserStatus === "blocked") {
            userEnvironmentStatus = "blocked_bo";
        }
        const isAdministrator = userInfo.isAdmin;

        return (
            <>
                <Head
                    onBack={this.handleBack}
                    hasCenterContent
                    {...(!isAdministrator && {
                        rightChildren: (
                            <Box display="flex" gap="7">
                                {userEnvironmentStatus !== "blocked_bo" && (
                                    <Button
                                        bsStyle="outline"
                                        className="btn-width-lg"
                                        label={actionKey}
                                        onClick={() => {
                                            this.handleSelect(0, user.fullName);
                                        }}
                                    />
                                )}
                                <Button
                                    bsStyle="outline"
                                    className="btn-width-lg"
                                    label="administration.delete"
                                    onClick={() => {
                                        this.handleSelect(1, user.fullName);
                                    }}
                                />
                            </Box>
                        ),
                    })}>
                    <Box display="flex" alignY="center" gap="3">
                        <Text component="h2" className="m-0" color="heading-color">
                            {user.fullName}
                        </Text>
                        {isAdministrator && (
                            <Box
                                display="flex"
                                alignX="center"
                                alignY="center"
                                background="background-divider"
                                className="px-5 py-1"
                                borderRadius="sm">
                                <Text labelKey="administration.administrator" size="7" color="text-boton" bold />
                            </Box>
                        )}
                        <Box
                            display="flex"
                            alignX="center"
                            alignY="center"
                            background={userEnvironmentStatus === "active" ? "success" : "error-background-color"}
                            className="px-5 py-1"
                            borderRadius="sm">
                            <Text labelKey={`user.status.${userEnvironmentStatus}`} size="7" color="text-boton" bold />
                        </Box>
                    </Box>
                </Head>
                {/* NO BORRAR (IMPLEMENTACION DE PRODUCTO) */}
                {/* <AdministrationHeading>
                    {({ email, fullName }) => (
                        <div>
                            <Grid>
                                <Row className="content-data">
                                    <Heading.Data>
                                        <Heading.DataGroup label="administration.permissions.email" data={email} />
                                    </Heading.Data>
                                    <Heading.Data>
                                        <Heading.DataGroup
                                            label="administration.permissions.status"
                                            data={
                                                <span>
                                                    <I18n id={`user.status.${userEnvStatus}`} />
                                                </span>
                                            }
                                            dataClassName={classNames("data-tag", {
                                                "data-tag-blocked": userEnvStatus === "blocked",
                                            })}
                                        />
                                    </Heading.Data>
                                </Row>
                            </Grid>
                            {hasMassiveEnabled && (
                                <Grid fluid>
                                    <Nav
                                        className="product-detail-commands"
                                        variant="pills"
                                        onSelect={(key) => this.handleSelect(key, fullName)}>
                                        <Nav.Item className="btn btn-outline" href="#">
                                            <Image src="images/lock--checked.svg" className="svg-icon svg-caret" />

                                            <I18n id={actionKey} />
                                        </Nav.Item>
                                        <Nav.Item className="btn btn-outline" href="#">
                                            <Image src="images/delete-message.svg" className="svg-icon svg-caret" />
                                            <I18n id="administration.delete" />
                                        </Nav.Item>
                                    </Nav>
                                </Grid>
                            )}
                        </div>
                    )}
                </AdministrationHeading> */}
            </>
        );
    };

    renderAdvancedContent = () => {
        const { user, signatureLevel, schemeName, userInfo } = this.props;

        return (
            <>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout flex-grow-1">
                            <Container.Column md={4} sm={12}>
                                <ColumnHeader
                                    title="administration.channels.title"
                                    {...(!userInfo.isAdmin && {
                                        linkText: "administration.configure",
                                        url: `/administration/${schemeName}/channels/${user.idUser}`,
                                    })}
                                />
                                <Container.ColumnBody>
                                    <CapsList hasDefaultLabel />
                                </Container.ColumnBody>
                                <Container.ColumnHeader
                                    title="administration.permissions.signature"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/signature/${user.idUser}/confirm`}
                                />
                                <Container.ColumnBody>
                                    <p>{signatureLevel || i18nUtils.get("global.no")}</p>
                                </Container.ColumnBody>
                            </Container.Column>
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader
                                    title="administration.groups.index.title"
                                    linkText="administration.configure"
                                    url={`/administration/${schemeName}/groupsOfUser/${user.idUser}`}
                                />
                                <Container.ColumnBody>
                                    <UserDetailsGroupList />
                                </Container.ColumnBody>
                                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                                    <Container.ColumnHeader
                                        title="administration.dispatcher.title"
                                        linkText={`administration.users.dispatcher.${userInfo.dispatcher}`}
                                        url={`/administration/users/dispatcher/${user.idUser}/confirm`}
                                    />
                                    <Container.ColumnBody>
                                        <p>
                                            {userInfo.dispatcher
                                                ? i18nUtils.get("global.yes")
                                                : i18nUtils.get("global.no")}
                                        </p>
                                    </Container.ColumnBody>
                                </FeatureFlag>
                            </Container.Column>
                            <Container.Column md={4} sm={12}>
                                <Container.ColumnHeader title="administration.restrictions.title" />
                                <Container.ColumnBody>
                                    <RestrictionsUserProfile idUser={user.idUser} />
                                </Container.ColumnBody>
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>{" "}
            </>
        );
    };

    renderUserResume = () => {
        const { user, fetching, userInfo, loggedUser, userTokenStatus } = this.props;
        const isLoggedUser = loggedUser?.userId === user?.idUser;
        const inactiveStatusList = [
            USER_TOKEN_STATUS_INACTIVE,
            USER_TOKEN_STATUS_MIGRATE_ENTRUST,
            USER_TOKEN_STATUS_AUTHENTICATE,
            USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
            USER_TOKEN_STATUS_STARTED,
        ];

        const userTokenColor = () => {
            let color = "warning-color";
            if (userTokenStatus === USER_TOKEN_STATUS_ACTIVE) {
                color = "success-color";
            }
            if (inactiveStatusList.includes(userTokenStatus)) {
                color = "disabled-color";
            }
            if (
                userTokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST ||
                userTokenStatus === USER_TOKEN_STATUS_LOCKED_LOCAL
            ) {
                color = "error";
            }
            return color;
        };

        const userTokenLavel = () => {
            if (inactiveStatusList.includes(userTokenStatus)) {
                return USER_TOKEN_STATUS_INACTIVE?.toLowerCase();
            }
            if (userTokenStatus === USER_TOKEN_STATUS_LOCKED_ENTRUST) {
                return "locked";
            }
            return userTokenStatus?.toLowerCase();
        };

        return (
            <>
                {!fetching && (
                    <>
                        <Col xs="8">
                            <Box
                                display="flex"
                                alignY="center"
                                background="component-background"
                                borderRadius="default"
                                className="px-8 py-7"
                                fullHeight>
                                <Row className="content-data">
                                    <Col xs={4}>
                                        <Box display="flex" column>
                                            <Text
                                                component="label"
                                                className="m-0"
                                                labelKey="administration.user.details.email"
                                                addColon
                                                color="heading-color"
                                                bold
                                            />
                                            <Text color="heading-color" ellipsis>
                                                {maskEmail(user?.email)?.toLowerCase()}
                                            </Text>
                                        </Box>
                                    </Col>
                                    <Col xs={4}>
                                        <Box display="flex" column>
                                            <Text
                                                component="label"
                                                className="m-0"
                                                labelKey="administration.permissions.phone"
                                                addColon
                                                color="heading-color"
                                                bold
                                            />
                                            <Text color="heading-color" ellipsis>
                                                {maskPhoneNumber(user.mobileNumber)}
                                            </Text>
                                        </Box>
                                    </Col>
                                    <Col xs={4}>
                                        <Box display="flex" column>
                                            <Text
                                                component="label"
                                                className="m-0"
                                                labelKey="administration.permissions.lastConnection"
                                                addColon
                                                color="heading-color"
                                                bold
                                            />
                                            {user.lastLogin ? (
                                                <FormattedDate
                                                    color="heading-color"
                                                    date={user.lastLogin}
                                                    anotherFormat="DD/MM/YY hh:mm A"
                                                />
                                            ) : (
                                                <Text color="heading-color">-</Text>
                                            )}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>
                        <Col xs="4">
                            <Box
                                display="flex"
                                wrap
                                alignX="between"
                                alignY="center"
                                gap="3"
                                background="component-background"
                                borderRadius="default"
                                className="px-8 py-7">
                                <Box display="flex" column>
                                    <Text
                                        component="label"
                                        className="m-0"
                                        labelKey="administration.user.details.token"
                                    />
                                    <Box display="flex" gap="3" className="ml-n-5">
                                        {!isLoggedUser && userInfo.isAdmin ? (
                                            <Text
                                                color="text-disabled-color"
                                                labelKey="administration.users.list.hiddenToken"
                                            />
                                        ) : (
                                            <>
                                                <Box display="flex">
                                                    <Image src="images/icons/bullet.svg" color={userTokenColor()} />
                                                </Box>
                                                <Text
                                                    color={userTokenColor()}
                                                    labelKey={`softToken.status.${userTokenLavel()}`}
                                                    uppercase
                                                />
                                            </>
                                        )}
                                    </Box>
                                </Box>
                                <Button
                                    bsStyle="link"
                                    {...(!userInfo.isAdmin &&
                                        userTokenStatus === USER_TOKEN_STATUS_PENDING_APROVE && {
                                            label: "global.activate",
                                            href: `/administration/token/${user.idUser}/confirm`,
                                        })}
                                />
                                <Button
                                    bsStyle="link"
                                    {...(!userInfo.isAdmin &&
                                        userTokenStatus === USER_TOKEN_STATUS_PENDING_UNLOCK && {
                                            label: "administration.unblock",
                                            href: `/administration/token/${user.idUser}/confirm`,
                                        })}
                                />
                            </Box>
                        </Col>
                    </>
                )}
            </>
        );
    };

    renderMediumContent = () => {
        const { user, schemeName, userInfo } = this.props;
        const { isEnabledToCreate } = userInfo;

        return (
            <>
                <Row>
                    {this.renderUserResume()}
                    <Col xs={4}>
                        <Box display="flex" column gap="7" fullHeight>
                            <Box display="flex" column background="component-background" borderRadius="default">
                                <ColumnHeader
                                    title="administration.channels.title"
                                    {...(!userInfo.isAdmin && {
                                        url: `/administration/${schemeName}/channels/${user.idUser}`,
                                        linkText: "administration.configure",
                                    })}
                                />
                                <Box display="flex" column className="px-8 pt-5 pb-8">
                                    <CapsList hasDefaultLabel />
                                </Box>
                            </Box>
                            <Box display="flex" column background="component-background" borderRadius="default" flex1>
                                <ColumnHeader
                                    title="global.create"
                                    {...(!userInfo.isAdmin &&
                                        userInfo.hasEnableApproveCreate &&
                                        isEnabledToCreate && {
                                            linkText: `${
                                                userInfo.hasPermissionCreate ? "global.deactivate" : "global.activate"
                                            }`,
                                            url: `/administration/${schemeName}/create/${user.idUser}/confirm`,
                                        })}
                                    {...(!userInfo.isAdmin &&
                                        userInfo.hasEnableApproveCreate &&
                                        !isEnabledToCreate &&
                                        userInfo.hasPermissionCreate && {
                                            linkText: `${"global.deactivate"}`,
                                            url: `/administration/${schemeName}/create/${user.idUser}/confirm`,
                                        })}
                                    tooltipText={`administration.permission.create.${
                                        userInfo.hasPermissionCreate ? "deactivate" : "activate"
                                    }.tooltip`}
                                />
                                <Box display="flex" column className="px-8 pt-5 pb-5">
                                    <Box
                                        display="flex"
                                        alignX="center"
                                        background={
                                            userInfo.hasPermissionCreate ? "success-color" : "error-border-color"
                                        }
                                        className="p-3 min-width-2-5rem"
                                        borderRadius="xl"
                                        fitWidth>
                                        <Text
                                            color="text-inverse-color"
                                            size="6"
                                            bold
                                            labelKey={userInfo.hasPermissionCreate ? "global.yes" : "global.no"}
                                            uppercase
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" column background="component-background" borderRadius="default" flex1>
                                <ColumnHeader
                                    title="global.approve"
                                    {...(!userInfo.isAdmin &&
                                        userInfo.hasEnableApproveCreate && {
                                            linkText: `${
                                                userInfo.hasPermissionApprove ? "global.deactivate" : "global.activate"
                                            }`,
                                            url: `/administration/${schemeName}/approve/${user.idUser}/confirm`,
                                        })}
                                    tooltipText={`administration.permission.approve.${
                                        userInfo.hasPermissionApprove ? "deactivate" : "activate"
                                    }.tooltip`}
                                />
                                <Box display="flex" column className="px-8 pt-5 pb-5">
                                    <Box
                                        display="flex"
                                        alignX="center"
                                        background={
                                            userInfo.hasPermissionApprove ? "success-color" : "error-border-color"
                                        }
                                        className="p-3 min-width-2-5rem"
                                        borderRadius="xl"
                                        fitWidth>
                                        <Text
                                            color="text-inverse-color"
                                            size="6"
                                            bold
                                            labelKey={userInfo.hasPermissionApprove ? "global.yes" : "global.no"}
                                            uppercase
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={4}>
                        <Box display="flex" column background="component-background" borderRadius="default" fullHeight>
                            <ColumnHeader
                                className="px-5 py-3"
                                title="administration.permissions"
                                {...(!userInfo.isAdmin && {
                                    linkText: "administration.configure",
                                    url: `/administration/${schemeName}/permissions/${user.idUser}`,
                                })}
                            />
                            <Box display="flex" column className="pb-9">
                                <PermissionsList
                                    idUser={user.idUser}
                                    onlyRead
                                    classNameContainer={null}
                                    borderContainer={null}
                                    accordionBorderRadius={null}
                                    childrenListULClassName="m-0 full-width"
                                    childrenListLIClassName="px-0"
                                    childrenListLIInnerClassName="pl-5 pr-7 min-height-9"
                                    contentClassName="my-0 px-0">
                                    {(list) =>
                                        list || (
                                            <Text
                                                labelKey="administration.permissions.empty"
                                                size="6"
                                                align="center"
                                                className="px-5 py-3 mt-3"
                                            />
                                        )
                                    }
                                </PermissionsList>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={4}>
                        <Box display="flex" column background="component-background" borderRadius="default" fullHeight>
                            <ColumnHeader title="administration.restrictions.title" />
                            <Box display="flex" column className="pt-5 pb-9" position="relative" fullHeight>
                                <RestrictionsUserProfile idUser={user.idUser} isAdminUser={userInfo.isAdmin} />
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </>
        );
    };

    renderContent = () => {
        const { schemeName } = this.props;

        if (schemeName === "medium") {
            return this.renderMediumContent();
        }

        return this.renderAdvancedContent();
    };

    render() {
        const { fetching } = this.props;
        const { fetchingAction } = this.state;
        const isLoading = fetching; // && !Object.keys(user).length;

        return (
            <>
                <Notification scopeToShow="administration" />
                {this.renderHeader()}
                <MainContainer showLoader={isLoading || fetchingAction}>{this.renderContent()}</MainContainer>
            </>
        );
    }
}

export default connect()(withRevelockPositionContext(Details));

import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Selector from "pages/_components/fields/formik/Selector";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Trigger from "pages/_components/Trigger";
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { numberFormat } from "util/number";
import * as Yup from "yup";
import { permissionsSelectors, channelsSelectors } from "reducers/administration";
import { permissionsActions } from "reducers/administration/medium";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import * as i18nUtils from "util/i18n";
import { routerActions } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "administration.permissions.productType";

const AdministrationPermissionsConfigDetails = ({
    fetching,
    values,
    history,
    permissionConfigurationDetails,
    dispatch,
    match,
    setValues,
    transfersPermissions,
    paymentsPermissions,
    setFieldValue,
    errors,
    setErrors,
    caps,
    topAmount,
    user,
    activeEnvironment: { administrationScheme },
}) => {
    const [listData, setListData] = useState([]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(() =>
        permissionConfigurationDetails.reduce((acc, permission) => ({ ...acc, [permission.idPermission]: false }), {}),
    );
    const [anyEditing, setAnyEditing] = useState(false);
    const [isEditMode, setIsEditMode] = useState(
        permissionConfigurationDetails.reduce(
            (acc, permission) => ({ ...acc, [permission.idPermission.replaceAll(".", "_")]: false }),
            {},
        ),
    );

    const [emptyValue, setEmptyValue] = useState({});
    const [oldEditingValue, setOldEditingValue] = useState({});

    const setInitialValues = () => {
        const initialValues = {};
        const {
            params: { sectionId },
        } = match;
        let initialList = permissionConfigurationDetails || [];
        if (sectionId === "transfers" && transfersPermissions.length) {
            initialList = transfersPermissions;
        }
        if (sectionId === "payments" && paymentsPermissions.length) {
            initialList = paymentsPermissions;
        }

        initialList.forEach((permission) => {
            const idPermission = permission.idPermission.replaceAll(".", "_");
            initialValues[`${idPermission}`] = {
                idPermission: permission.idPermission,
                selected: permission.selected,
                maxAutonomyAmount: permission.maxAutonomyAmount,
                maxSignatureAmount: permission.maxSignatureAmount,
                amountOfSignatures: permission.amountOfSignatures,
                accounts: permission.accounts.map((account) => ({
                    selected: account.selected,
                    idProduct: account.idProduct,
                    label: account.label,
                    productType: account.productType,
                })),
            };
        });

        if (initialList.length) {
            const emptyAccounts = [];
            if (initialList[0].accounts.length) {
                for (let i = 0; i < initialList[0].accounts.length; i++) {
                    emptyAccounts.push({
                        ...initialList[0].accounts[i],
                        selected: false,
                    });
                }
            }

            setEmptyValue({
                ...initialList[0],
                selected: false,
                maxAutonomyAmount: {
                    currency: "USD",
                    quantity: "0.00",
                },
                maxSignatureAmount: {
                    currency: "USD",
                    quantity: "0.00",
                },
                amountOfSignatures: 0,
                accounts: emptyAccounts,
            });
        }

        setListData(initialList);
        setValues(initialValues);
    };

    useEffect(() => {
        if (!caps && !topAmount && user) {
            const path = {
                simple: "simple/permissions",
                medium: "medium/details",
                advanced: "advanced/details",
            };
            dispatch(
                routerActions.push({
                    pathname: `/administration/${path[administrationScheme]}/${user.idUser}`,
                }),
            );
        }
        setListData(permissionConfigurationDetails);
        const {
            params: { id, sectionId },
        } = match;
        if (!permissionConfigurationDetails.length) {
            dispatch(permissionsActions.loadPermissionsDetailsRequest(id, sectionId));
        } else {
            setInitialValues();
        }
    }, []);

    useEffect(() => {
        setInitialValues();
    }, [transfersPermissions, paymentsPermissions]);

    const { decimalSeparator, thousandSeparator } = numberFormat();

    const url = window.location.href;
    const isOnlyRead = url.endsWith("onlyRead");
    const isFinancialOperations = url.includes("transfers");
    const isPaymentsAndReloads = url.includes("payments");

    const togglePermission = (idPermission) => {
        setIsAccordionOpen((prevStates) => ({
            ...prevStates,
            [idPermission]: !prevStates[idPermission],
        }));
    };

    const rowHasErrors = (data) => {
        const newErrors = {};

        let userMaximum = caps?.all?.amount || 0.0;
        if (userMaximum === -1 && topAmount?.maximum) {
            userMaximum = topAmount.maximum;
        }

        const idPermission = data.idPermission.replaceAll(".", "_");
        // alguna cuenta seleccionada
        let accountSelected = false;
        for (let i = 0; i < data.accounts.length; i++) {
            if (data.accounts[i].selected) {
                accountSelected = true;
                break;
            }
        }

        if (!accountSelected) {
            newErrors[`${idPermission}.trigger`] = i18nUtils.get("global.validation.error.required");
        }

        // validar cantidad de firmas.
        if (!data.amountOfSignatures) {
            newErrors[`${idPermission}.amountOfSignatures`] = i18nUtils.get("global.validation.error.required");
        }
        if (!+data.maxSignatureAmount.quantity) {
            newErrors[`${idPermission}.maxSignatureAmount`] = i18nUtils.get("global.validation.error.required");
        }

        if (
            +data.maxSignatureAmount.quantity &&
            +data.maxAutonomyAmount.quantity >= +data.maxSignatureAmount.quantity
        ) {
            newErrors[`${idPermission}.maxAutonomyAmount`] = i18nUtils.get(
                "permissions.validation.maxAutonomyAmount.error.max.exceeded",
            );
        }

        if (+data.maxSignatureAmount.quantity && +data.maxSignatureAmount.quantity > userMaximum) {
            newErrors[`${idPermission}.maxSignatureAmount`] = i18nUtils.get(
                "permissions.validation.maxSignatureAmount.error.max.exceeded",
            );
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length > 0;
    };

    const saveData = (idPermission) => {
        const updatedData = values[idPermission];

        if (rowHasErrors(updatedData)) {
            return;
        }

        if (!updatedData.maxAutonomyAmount?.quantity) {
            setFieldValue(`${idPermission}.maxAutonomyAmount.quantity`, "0.00");
        }
        if (!updatedData.maxSignatureAmount?.quantity) {
            setFieldValue(`${idPermission}.maxSignatureAmount.quantity`, "0.00");
        }
        setListData((currentListData) =>
            currentListData.map((item) => {
                if (item.idPermission.replaceAll(".", "_") === idPermission) {
                    return {
                        ...item,
                        selected: updatedData.selected,
                        maxAutonomyAmount: {
                            currency: updatedData.maxAutonomyAmount.currency,
                            quantity: updatedData.maxAutonomyAmount?.quantity || "0.00",
                        },
                        maxSignatureAmount: {
                            currency: updatedData.maxSignatureAmount.currency,
                            quantity: updatedData.maxSignatureAmount?.quantity || "0.00",
                        },
                        amountOfSignatures: updatedData.amountOfSignatures,
                        accounts: updatedData.accounts.map((account) => ({
                            ...account,
                            selected: account.selected,
                        })),
                    };
                }
                return item;
            }),
        );
        setIsAccordionOpen(() => ({
            [idPermission]: false,
        }));
        setIsEditMode((prevModes) => ({
            ...prevModes,
            [idPermission]: false,
        }));
        setAnyEditing(false);
    };

    const cancelData = (idPermission) => {
        setIsAccordionOpen(() => ({
            [idPermission]: false,
        }));
        setIsEditMode((prevModes) => ({
            ...prevModes,
            [idPermission]: false,
        }));
        setAnyEditing(false);
        setFieldValue(idPermission, oldEditingValue);
        setListData((currentListData) =>
            currentListData.map((item) => {
                if (item.idPermission.replaceAll(".", "_") === idPermission) {
                    return {
                        ...item,
                        selected: oldEditingValue.selected,
                        maxAutonomyAmount: oldEditingValue.maxAutonomyAmount,
                        maxSignatureAmount: oldEditingValue.maxSignatureAmount,
                        amountOfSignatures: oldEditingValue.amountOfSignatures,
                        accounts: oldEditingValue.accounts.map((account) => ({
                            ...account,
                            selected: account.selected,
                        })),
                    };
                }
                return item;
            }),
        );
    };

    const editData = (idPermission) => {
        setOldEditingValue({
            ...values[idPermission],
            maxAutonomyAmount: {
                currency: values[idPermission]?.maxAutonomyAmount?.currency,
                quantity: values[idPermission]?.maxAutonomyAmount?.quantity || "0.00",
            },
        });

        if (values[idPermission]?.maxAutonomyAmount?.quantity === "0.00") {
            setFieldValue(`${idPermission}.maxAutonomyAmount.quantity`, null);
        }
        setIsEditMode((prevModes) => ({
            ...prevModes,
            [idPermission]: true,
        }));
        setAnyEditing(true);
    };

    const getDataAmount = () => ({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
    });

    const handleChangePermissionSelect = (val, idPermission, data, index) => {
        if (!isAccordionOpen[data.idPermission]) {
            togglePermission(data.idPermission);
        }

        setOldEditingValue({
            idPermission: values[idPermission].idPermission,
            selected: values[idPermission].selected,
            accounts: values[idPermission].accounts,
            amountOfSignatures: values[idPermission].amountOfSignatures,
            maxSignatureAmount: values[idPermission].maxSignatureAmount,
            maxAutonomyAmount: values[idPermission].maxAutonomyAmount,
        });

        const newValue = {
            idPermission: listData[index].idPermission,
            selected: val,
            accounts: listData[index].accounts,
            amountOfSignatures: listData[index].amountOfSignatures,
            maxSignatureAmount: listData[index].maxSignatureAmount,
            maxAutonomyAmount: listData[index].maxAutonomyAmount,
        };
        if (listData[index]?.maxSignatureAmount?.quantity && listData[index]?.maxSignatureAmount?.quantity === "0.00") {
            newValue.maxSignatureAmount = {
                currency: "USD",
                quantity: null,
            };
        }
        if (listData[index]?.maxAutonomyAmount?.quantity && listData[index]?.maxAutonomyAmount?.quantity === "0.00") {
            newValue.maxAutonomyAmount = {
                currency: "USD",
                quantity: null,
            };
        }

        const newListData = listData.slice(0, index).concat([newValue], listData.slice(index + 1));
        setListData(newListData);
        setFieldValue(idPermission, newValue);

        if (!val) {
            const permissionEmptyValue = { ...emptyValue, idPermission: data.idPermission };
            setIsAccordionOpen((prevStates) => ({
                ...prevStates,
                [data.idPermission]: false,
            }));
            setFieldValue(idPermission, permissionEmptyValue);
            setListData([...listData.slice(0, index), { ...permissionEmptyValue }, ...listData.slice(index + 1)]);
        }

        setAnyEditing(val);
        setIsEditMode((prevModes) => ({
            ...prevModes,
            [idPermission]: val,
        }));
    };

    const getColumnsTemplate = () => (isOnlyRead ? "1.5fr 6rem 1fr 1fr 1fr" : "3rem 1.5fr 6rem 1fr 1fr 1fr 6.5rem");

    const handleBack = () => {
        history.goBack();
    };

    const filteredListData = listData.filter((item) => item.selected === true);

    return (
        <>
            <Head
                {...(isFinancialOperations && {
                    title: `${FORM_ID}.financialOperations`,
                })}
                {...(isPaymentsAndReloads && {
                    title: `${FORM_ID}.paymentsAndReloads`,
                })}
                {...(!isFinancialOperations &&
                    !isPaymentsAndReloads && {
                        title: "administration.permissions.configurePermissions",
                    })}
                onBack={handleBack}
            />
            <MainContainer showLoader={fetching}>
                <Form className="d-flex flex-column gap-8 full-height">
                    <GridTable>
                        <GridTable.Header
                            tableHeaderBackground="component-background"
                            className="border-radius-top-default">
                            <GridTable.Container
                                columnsTemplate={getColumnsTemplate()}
                                {...(isOnlyRead && { padding: "py-2 px-8" })}>
                                <GridTable.Data
                                    columnStart={isOnlyRead ? 1 : 2}
                                    alignX="flex-start"
                                    alignY="center"
                                    inHeader>
                                    <Text
                                        labelKey={`${FORM_ID}.operationType`}
                                        align="left"
                                        color="primary-color"
                                        bold
                                    />
                                </GridTable.Data>
                                <GridTable.Data
                                    columnStart={isOnlyRead ? 2 : 3}
                                    alignX="center"
                                    alignY="center"
                                    inHeader>
                                    <Text labelKey={`${FORM_ID}.accounts`} color="primary-color" bold />
                                </GridTable.Data>
                                <GridTable.Data
                                    columnStart={isOnlyRead ? 3 : 4}
                                    alignX="flex-end"
                                    alignY="center"
                                    inHeader>
                                    <Text labelKey={`${FORM_ID}.autonomy`} color="primary-color" bold />
                                </GridTable.Data>
                                <GridTable.Data
                                    columnStart={isOnlyRead ? 4 : 5}
                                    alignX="flex-end"
                                    alignY="center"
                                    inHeader>
                                    <Text
                                        labelKey={`${FORM_ID}.maxSignatureAmount`}
                                        align="right"
                                        color="primary-color"
                                        bold
                                    />
                                </GridTable.Data>
                                <GridTable.Data
                                    columnStart={isOnlyRead ? 5 : 6}
                                    alignX="flex-end"
                                    alignY="center"
                                    inHeader>
                                    {/* TODO (label) */}
                                    <Text labelKey={`${FORM_ID}.approvals`} color="primary-color" bold />
                                </GridTable.Data>
                            </GridTable.Container>
                        </GridTable.Header>
                        <GridTable.Body>
                            {(isOnlyRead ? filteredListData : listData).map((data, index) => {
                                const idPermission = data.idPermission.replaceAll(".", "_");
                                const checked = values[`${idPermission}`].selected;
                                const editable = isEditMode[`${idPermission}`];
                                const countAccounts = data.accounts.length;
                                const countSelectedAccounts = values[idPermission].accounts.filter(
                                    (account) => account.selected === true,
                                ).length;
                                const accountsToShow = isOnlyRead
                                    ? data.accounts.filter((item) => item.selected)
                                    : data.accounts;

                                const hasRowErrors = Object.keys(errors).some(
                                    (errorKey) => errorKey.startsWith(`${idPermission}`) || countSelectedAccounts > 0,
                                );

                                return (
                                    <GridTable.Container
                                        key={idPermission}
                                        {...(hasRowErrors && editable && !isOnlyRead
                                            ? { padding: "pt-3 pb-6 px-5" }
                                            : { padding: "py-3 px-5" })}
                                        {...(isOnlyRead && { padding: "py-2 px-8" })}
                                        columnsTemplate={getColumnsTemplate()}
                                        isAccordionOpen={isAccordionOpen[data.idPermission]}
                                        accordionChildren={accountsToShow.map((account, indexAcc) => (
                                            <Box
                                                component="li"
                                                key={account.idProduct}
                                                display="flex"
                                                alignY="center"
                                                fullWidth
                                                className="px-10 py-4"
                                                border="bottom-background-border-disabled"
                                                gap="8">
                                                {!isOnlyRead && (
                                                    <Field
                                                        idForm={FORM_ID}
                                                        name={`${idPermission}.accounts[${indexAcc}].selected`}
                                                        component={Checkbox}
                                                        noLabel
                                                        disabled={!editable}
                                                    />
                                                )}

                                                <Text
                                                    color="heading-color"
                                                    size="6"
                                                    {...(isOnlyRead && { className: "pl-3" })}>
                                                    {account.label}
                                                </Text>
                                            </Box>
                                        ))}>
                                        {!isOnlyRead && (
                                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                                <Field
                                                    idForm={FORM_ID}
                                                    name={`${idPermission}.selected`}
                                                    component={SwitchField}
                                                    noLabel
                                                    onChangeValue={(val) =>
                                                        handleChangePermissionSelect(val, idPermission, data, index)
                                                    }
                                                    disabled={anyEditing && !isEditMode[`${idPermission}`]}
                                                    hideLabel
                                                />
                                            </GridTable.Data>
                                        )}
                                        <GridTable.Data
                                            columnStart={isOnlyRead ? 1 : 2}
                                            alignX="flex-start"
                                            alignY="center">
                                            <Text
                                                color="primary-color"
                                                size="7"
                                                labelKey={`administration.permissions.${data.idPermission}`}
                                            />
                                        </GridTable.Data>
                                        <GridTable.Data
                                            columnStart={isOnlyRead ? 2 : 3}
                                            alignX="center"
                                            alignY="center"
                                            overflow="visible">
                                            <Field
                                                idForm={FORM_ID}
                                                name={`${idPermission}.trigger`}
                                                idField={`${idPermission}.trigger`}
                                                key={`${idPermission}.trigger`}
                                                component={Trigger}
                                                onClick={() => togglePermission(data.idPermission)}
                                                isAccordionOpen={isAccordionOpen[data.idPermission]}
                                                enableQantity={countSelectedAccounts}
                                                totalQuantity={countAccounts}
                                                values={countSelectedAccounts}
                                                editableMode={editable}
                                            />
                                        </GridTable.Data>
                                        <GridTable.Data
                                            columnStart={isOnlyRead ? 3 : 4}
                                            alignX="flex-end"
                                            alignY="center"
                                            overflow="visible">
                                            {checked && editable && !isOnlyRead ? (
                                                <Field
                                                    idForm={FORM_ID}
                                                    mode="edit"
                                                    component={FormFieldsComponents.Amount}
                                                    acceptZero
                                                    data={getDataAmount()}
                                                    name={`${idPermission}.maxAutonomyAmount`}
                                                    idField={`${idPermission}.maxAutonomyAmount`}
                                                    key={`${idPermission}.maxAutonomyAmount`}
                                                    customPlaceholderQuantity="0.00"
                                                    quantityTextAlign="text-right"
                                                    currencyBox={false}
                                                    value={{
                                                        currency: "USD",
                                                        quantity: values[`${idPermission}`].maxAutonomyAmount,
                                                    }}
                                                    noLabel
                                                />
                                            ) : (
                                                <FormattedAmount
                                                    quantity={data.maxAutonomyAmount.quantity}
                                                    noCurrency
                                                    align="right"
                                                />
                                            )}
                                        </GridTable.Data>
                                        <GridTable.Data
                                            columnStart={isOnlyRead ? 4 : 5}
                                            alignX="flex-end"
                                            alignY="center"
                                            overflow="visible">
                                            {checked && editable && !isOnlyRead ? (
                                                <Field
                                                    idForm={FORM_ID}
                                                    mode="edit"
                                                    component={FormFieldsComponents.Amount}
                                                    data={getDataAmount()}
                                                    name={`${idPermission}.maxSignatureAmount`}
                                                    idField={`${idPermission}.maxSignatureAmount`}
                                                    key={`${idPermission}.maxSignatureAmount`}
                                                    customPlaceholderQuantity="0.00"
                                                    quantityTextAlign="text-right"
                                                    currencyBox={false}
                                                    value={{
                                                        currency: "USD",
                                                        quantity: values[`${idPermission}`].maxSignatureAmount.quantity,
                                                    }}
                                                    noLabel
                                                />
                                            ) : (
                                                <FormattedAmount
                                                    quantity={data.maxSignatureAmount.quantity}
                                                    noCurrency
                                                    align="right"
                                                />
                                            )}
                                        </GridTable.Data>
                                        <GridTable.Data
                                            columnStart={isOnlyRead ? 5 : 6}
                                            alignX="flex-end"
                                            alignY="center"
                                            overflow="visible">
                                            {checked && editable && !isOnlyRead ? (
                                                <Field
                                                    idForm={FORM_ID}
                                                    component={Selector}
                                                    formGroupClassName="max-width-5rem"
                                                    name={`${idPermission}.amountOfSignatures`}
                                                    idField={`${idPermission}.amountOfSignatures`}
                                                    key={`${idPermission}.amountOfSignatures`}
                                                    options={[
                                                        { value: 1, label: "1" },
                                                        { value: 2, label: "2" },
                                                    ]}
                                                    clearable={false}
                                                    searchable={false}
                                                    value={{
                                                        value: values[`${idPermission}`].amountOfSignatures,
                                                        label: `${values[`${idPermission}`].amountOfSignatures}`,
                                                    }}
                                                    forceValue
                                                    noLabel
                                                    placeholder="0"
                                                    errorWithOutTouched
                                                />
                                            ) : (
                                                <Text color="heading-color">{data.amountOfSignatures}</Text>
                                            )}
                                        </GridTable.Data>
                                        {checked && !isOnlyRead && (
                                            <GridTable.Data columnStart={7} alignX="center" alignY="center">
                                                {editable ? (
                                                    // (originalValue[Permission].selected && !isEditMode[permission])
                                                    // || values[permission].selected
                                                    <Box display="flex" gap="2">
                                                        <Button
                                                            image="images/icons/circleCheck.svg"
                                                            onClick={() => saveData(idPermission)}
                                                            className="px-2"
                                                            imageHoverColor="primary-hover-color"
                                                            removeMinHeight
                                                            imageMd
                                                        />
                                                        <Button
                                                            image="images/icons/circleCross2.svg"
                                                            onClick={() => cancelData(idPermission)}
                                                            className="px-2"
                                                            imageHoverColor="primary-hover-color"
                                                            removeMinHeight
                                                            imageMd
                                                        />
                                                    </Box>
                                                ) : (
                                                    // checked && !isEditMode[permission]
                                                    <Box display="flex" gap="2">
                                                        <Button
                                                            image="images/icons/editPen.svg"
                                                            onClick={() => {
                                                                editData(idPermission);
                                                                if (!isAccordionOpen[data.idPermission]) {
                                                                    togglePermission(data.idPermission);
                                                                }
                                                            }}
                                                            className="px-2"
                                                            imageMd
                                                            imageHoverColor="primary-hover-color"
                                                            removeMinHeight
                                                            disabled={anyEditing}
                                                        />
                                                    </Box>
                                                )}
                                            </GridTable.Data>
                                        )}
                                    </GridTable.Container>
                                );
                            })}
                        </GridTable.Body>
                    </GridTable>
                    <Row>
                        {!isOnlyRead ? (
                            <>
                                <Col xs={3} xsOffset={3}>
                                    <Button bsStyle="outline" label="global.cancel" onClick={handleBack} block />
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.save"
                                        // TODO (isSubmitting )
                                        // loading={isSubmitting}
                                        disabled={anyEditing}
                                        block
                                    />
                                </Col>
                            </>
                        ) : (
                            <Col xs={4} xsOffset={4}>
                                <Button bsStyle="outline" label="global.back" onClick={handleBack} block />
                            </Col>
                        )}
                    </Row>
                </Form>
            </MainContainer>
        </>
    );
};
AdministrationPermissionsConfigDetails.propTypes = {
    editMode: bool,
    fetching: bool,
    history: shape({}).isRequired,
    values: shape({}).isRequired,
    permissionConfigurationDetails: shape([]).isRequired,
    errors: shape({}).isRequired,
    dispatch: func.isRequired,
    match: shape({}).isRequired,
    setValues: func.isRequired,
    setErrors: func.isRequired,
    transfersPermissions: shape([]).isRequired,
    paymentsPermissions: shape([]).isRequired,
    setFieldValue: func.isRequired,
    caps: shape({}).isRequired,
    topAmount: shape({}),
    user: shape({}).isRequired,
    activeEnvironment: shape({}).isRequired,
};

AdministrationPermissionsConfigDetails.defaultProps = {
    editMode: false,
    fetching: false,
    topAmount: null,
};

const mapStateToProps = (state) => ({
    permissionConfigurationDetails: permissionsSelectors.getPermissionConfigurationDetails(state),
    transfersPermissions: permissionsSelectors.getTrasnfersEditionResult(state),
    paymentsPermissions: permissionsSelectors.getPaymentsEditionResult(state),
    fetching: permissionsSelectors.isFetching(state),
    caps: channelsSelectors.getCaps(state),
    topAmount: channelsSelectors.getTopAmount(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(
    withFormik({
        mapPropsToValues: (props) => {
            const initialValues = {};
            props.permissionConfigurationDetails.forEach((permission) => {
                const idPermission = permission.idPermission.replaceAll(".", "_");
                initialValues[`${idPermission}`] = {
                    idPermission: permission.idPermission,
                    selected: permission.selected,
                    maxAutonomyAmount: permission.maxAutonomyAmount,
                    maxSignatureAmount: permission.maxSignatureAmount,
                    amountOfSignatures: permission.amountOfSignatures,
                    accounts: permission.accounts.map((account) => ({
                        selected: account.selected,
                        idProduct: account.idProduct,
                        label: account.label,
                        productType: account.productType,
                    })),
                };
            });
            return initialValues;
        },

        validationSchema: Yup.object().shape({
            // selected: Yup.boolean().required("Required"),
        }),

        handleSubmit: (values, formikBag) => {
            formikBag.setSubmitting(false);
            const {
                props: {
                    match: {
                        params: { sectionId },
                    },
                    dispatch,
                    history,
                },
            } = formikBag;
            dispatch(permissionsActions.saveLocalPermissionEdition(Object.values(values), sectionId));
            setTimeout(() => history.goBack(), 250);
        },
    })(AdministrationPermissionsConfigDetails),
);

import { useEffect } from "react";
import { startSensorDataCapture } from "util/monitor/monitorDbf.utils";

export const useStartSensorDataCapture = (mode) => {
    useEffect(() => {
        if (mode === "preview") {
            startSensorDataCapture();
        }
    }, []);
};

export default useStartSensorDataCapture;

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { func, number, shape, string } from "prop-types";
import React from "react";
import { routerActions } from "react-router-redux/actions";
import { actions as fileActions } from "reducers/files";
// import * as i18n from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";

const ShowInfoFile = ({
    uploadType,
    mode,
    onClickNewItem,
    // onClickEditItem,
    dispatch,
    idTransactionTicket,
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // optionCurrencyList,
    currencyCustom,
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // handleCurrency,
    amount,
    lines,
    infoErrorFile,
}) => {
    const handleDownloadErrorsClick = () => {
        const { idRelatedFile, nameRelatedFile } = infoErrorFile;
        if (idRelatedFile && nameRelatedFile) {
            dispatch(fileActions.downloadFileRequest(idRelatedFile, nameRelatedFile));
        }
    };

    const handleClickDetail = () => {
        dispatch(routerActions.push(`/transaction/${idTransactionTicket}/processDetail`));
    };
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // const renderCurrencyOptionsEdit = () => {
    //     if (!optionCurrencyList) {
    //         return <div />;
    //     }

    //     const handleCurrencyCustom = (event) => {
    //         if (handleCurrency) {
    //             handleCurrency(event);
    //         }
    //     };

    //     return (
    //         <>
    //             <Row className="form-content justify-content-center">
    //                 <Col sm={12 - optionCurrencyList.length} />
    //                 {optionCurrencyList.map((option) => (
    //                     <Col sm={1}>
    //                         <RadioButton
    //                             id={option.currency}
    //                             checked={currencyCustom ? option.currency === currencyCustom : false}
    //                             inLineControl={false}
    //                             key={option.currency}
    //                             label={option.label}
    //                             name={option.currency}
    //                             onChange={handleCurrencyCustom}
    //                             value={option.currency}
    //                         />
    //                     </Col>
    //                 ))}
    //             </Row>
    //         </>
    //     );
    // };
    return (
        <>
            {/* NO BORRAR (IMPLEMENTACION PRODUCTO) */}
            {/* <DetailBox>
                {mode === "edit" && uploadType !== "file" && (
                    <div className="detailBox-row">
                        <div className="detailBox-data" />
                        <div className="text-right">{renderCurrencyOptionsEdit()}</div>
                    </div>
                )}
            </DetailBox> */}
            <Box display="flex" column gap="3" fullWidth>
                {mode === "edit" && onClickNewItem && uploadType && uploadType !== "file" && (
                    <Box display="flex" fitWidth>
                        <Button
                            onClick={() => {
                                onClickNewItem();
                            }}
                            label={`forms.inputFile.massivePayments.${lines > 0 ? "editPayments" : "addPayments"}`}
                            bsStyle="link"
                            block
                            image={lines > 0 ? "images/icons/editPen16.svg" : "images/icons/circlePlusEvenOdd.svg"}
                        />
                    </Box>
                )}
                <Box
                    display="flex"
                    alignY="center"
                    alignX="between"
                    background="menu-background"
                    borderRadius="lg"
                    className="p-5">
                    {/* TODO (LABEL) */}
                    <Text labelKey="forms.inputFile.massivePayments.lines" color="heading-color" bold />
                    <Box display="flex" alignY="flex-end" column>
                        <Box display="inline-flex" gap="2">
                            <Text color="heading-color" bold>
                                {lines}
                            </Text>
                            {infoErrorFile && infoErrorFile.invalidLines > 0 && (
                                <Text
                                    labelKey="forms.inputFile.massivePayments.total.lines"
                                    color="heading-color"
                                    bold
                                />
                            )}
                        </Box>
                        {infoErrorFile && infoErrorFile.invalidLines > 0 && (
                            <>
                                <div>
                                    {/* <I18n id="forms.inputFile.massivePayments.invalid.lines" /> */}
                                    <Text
                                        labelKey="forms.inputFile.massivePayments.invalid.lines"
                                        size="6"
                                        color="heading-color"
                                    />
                                    <Text size="6" color="heading-color">
                                        {` ${infoErrorFile.invalidLines} | `}
                                    </Text>
                                    {/* <I18n id="forms.inputFile.massivePayments.valid.lines" /> */}
                                    <Text
                                        labelKey="forms.inputFile.massivePayments.valid.lines"
                                        size="6"
                                        color="heading-color"
                                    />
                                    <Text size="6" color="heading-color">
                                        {` ${infoErrorFile.validLines}`}
                                    </Text>
                                </div>
                                <Button
                                    onClick={() => {
                                        handleDownloadErrorsClick();
                                    }}
                                    // label="forms.inputFile.massivePayments.download.errors"
                                    bsStyle="link"
                                    className="p-0 remove-min-height">
                                    <Text
                                        labelKey="forms.inputFile.massivePayments.download.errors"
                                        color="primary"
                                        regular
                                        size="7"
                                        underlined
                                    />
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    alignX="between"
                    alignY="center"
                    background="menu-background"
                    borderRadius="lg"
                    className="p-5">
                    {/* TODO (LABEL) */}
                    <Text labelKey="forms.inputFile.massivePayments.totalAmount" color="heading-color" bold uppercase />
                    <Box display="flex" column alignY="flex-end" gap="2">
                        <FormattedAmount currency={currencyCustom} quantity={amount || 0} color="heading-color" bold />
                        {infoErrorFile && infoErrorFile.invalidLines > 0 && (
                            <Box display="flex" alignX="flex-end" className="max-width-18rem">
                                <Text
                                    labelKey="forms.inputFile.massivePayments.total.amount.disclaimer"
                                    color="heading-color"
                                    size="7"
                                    align="right"
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                {mode === "view" && (
                    <Box display="flex" fitWidth>
                        <Button
                            onClick={() => {
                                handleClickDetail();
                            }}
                            label="forms.inputFile.massivePayments.transaction.detail"
                            bsStyle="link"
                            block
                        />
                    </Box>
                )}
            </Box>
        </>
    );
};

ShowInfoFile.propTypes = {
    onClickNewItem: func,
    // onClickEditItem: func,
    amount: number,
    mode: string,
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // optionCurrencyList: shape([]),
    // handleCurrency: func,
    uploadType: string,
    currencyCustom: string,
    dispatch: func,
    idTransactionTicket: string,
    lines: number,
    infoErrorFile: shape({}),
};
ShowInfoFile.defaultProps = {
    onClickNewItem: () => {},
    // onClickEditItem: () => {},
    amount: 0,
    mode: "",
    // NO BORRAR (IMPLEMENTACION PRODUCTO)
    // optionCurrencyList: [],
    // handleCurrency: () => {},
    uploadType: "",
    currencyCustom: "",
    dispatch: () => {},
    idTransactionTicket: "",
    lines: 0,
    infoErrorFile: {},
};
export default ShowInfoFile;

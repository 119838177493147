import I18n from "pages/_components/I18n";
import Text from "pages/_components/Text";
import { arrayOf, shape, string, func } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/administration/common/administrationTicket";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "administration.users.invite.ticket";

class UserInviteTicket extends Component {
    static propTypes = {
        transactionData: shape({
            data: shape({
                signatureLevel: string,
                role: string,
                groupNames: arrayOf(string),
                firstName: string,
                lastName: string,
                email: string,
                mobilePhone: string,
            }),
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    componentDidMount() {
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    renderMediumSchemeConfiguration = () => {
        const { transactionData } = this.props;
        return (
            <>
                <span display="flex" className="mb-4">
                    <Text component="label" className="m-0" labelKey="administration.users.invite.signature" addColon />
                    <Text labelKey={`administration.users.invite.${transactionData.data.role}.signature`} />
                </span>
                <span display="flex" className="mb-4">
                    <Text
                        component="label"
                        className="m-0"
                        labelKey="administration.users.invite.roles.label"
                        addColon
                    />
                    <Text labelKey={`invitation.permissions.role.${transactionData.data.role}`} />
                </span>
            </>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const {
            transactionData: { data },
        } = this.props;
        const signatureLabel =
            data.signatureLevel === "N"
                ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                : data.signatureLevel;
        const groupText = data.groupNames.map((groupName, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
                <span> {groupName} </span> <br />
            </Fragment>
        ));
        return (
            <>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.edit.signatureLevel" />
                    </div>
                    <div className="data-desc">{signatureLabel}</div>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="administration.users.edit.groups" />
                    </div>
                    <div className="data-desc">{groupText}</div>
                </div>
            </>
        );
    };

    render() {
        const {
            transactionData,
            activeEnvironment: { administrationScheme },
        } = this.props;

        if (transactionData.data) {
            return (
                <>
                    <span display="flex" className="mb-4">
                        <Text
                            component="label"
                            className="m-0"
                            labelKey="administration.users.invite.firstName.label"
                            addColon
                        />
                        <Text>{transactionData.data.firstName}</Text>
                    </span>
                    <span display="flex" className="mb-4">
                        <Text
                            component="label"
                            className="m-0"
                            labelKey="administration.users.invite.lastName.label"
                            addColon
                        />
                        <Text>{transactionData.data.lastName}</Text>
                    </span>
                    <span display="flex" className="mb-4">
                        <Text
                            component="label"
                            className="m-0"
                            labelKey="administration.users.invite.email.label"
                            addColon
                        />
                        <Text>{transactionData.data.email}</Text>
                    </span>
                    <span display="flex" className="mb-4">
                        <Text
                            component="label"
                            className="m-0"
                            labelKey="administration.users.invite.mobilePhone.label"
                            addColon
                        />
                        <Text>
                            {transactionData.data?.prefix ? `+${transactionData.data?.prefix}` : ""}
                            {transactionData.data?.mobilePhone}
                        </Text>
                    </span>

                    {administrationScheme === "medium"
                        ? this.renderMediumSchemeConfiguration()
                        : this.renderAdvancedSchemeConfiguration()}
                </>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(UserInviteTicket));

import * as API from "middleware/api";

export const listCheckbooks = (idAccount) => API.executeWithAccessToken("/checkbooks.list", { idAccount });

export const listChecks = (idAccount, idCheckbook) =>
    API.executeWithAccessToken("/checks.list", { idAccount, idCheckbook });

export const listAllChecks = (idAccount) => API.executeWithAccessToken("/checks.listAll", { idAccount });

export const getCheckUrl = (idAccount, checkNumber) =>
    API.executeWithAccessToken("/checks.image", {
        idAccount,
        checkNumber,
    });

/* ID FORMS */
const ID_FORM_TRANSFER_LOCAL = "transfers.local";
const ID_FORM_CREDIT_CARD = "creditCard.payment.creditCardThird";
const ID_FORM_CREDIT_CARD_RECHARGE = "creditCard.recharge.creditCardThird";

export const TRANSFER_LOCAL_FORM_LIST = [ID_FORM_TRANSFER_LOCAL, ID_FORM_CREDIT_CARD, ID_FORM_CREDIT_CARD_RECHARGE];

/* TITLE FORMS */
const TITLE_TRANSFER_FORM = "forms.transfers.title";
const LOCAL_TRANSFER_FORM = "forms.transferLocal.formName";
const TITLE_CREDIT_CARD_FORM = "forms.payCreditCard.formName";
const TITLE_CREDIT_CARD_RECHARGE_FORM = "forms.recharge.title";

const THIRD_TRANSACTIONS_TITLE = {
    "/formCustom/transferLocal": LOCAL_TRANSFER_FORM,
    "transfers.local.send": TITLE_TRANSFER_FORM,
    "/formCustom/payCreditCardThird": TITLE_CREDIT_CARD_FORM,
    "creditCard.payment.creditCardThird.send": TITLE_CREDIT_CARD_FORM,
    "/formCustom/rechargeCreditCardThird": TITLE_CREDIT_CARD_RECHARGE_FORM,
    "creditCard.recharge.creditCardThird.send": TITLE_CREDIT_CARD_RECHARGE_FORM,
};

const THIRD_TRANSACTIONS_PREVIEW_VIEW_TITLE = {
    "/formCustom/transferLocal": LOCAL_TRANSFER_FORM,
    "/formCustom/payCreditCardThird": TITLE_CREDIT_CARD_FORM,
    "/formCustom/rechargeCreditCardThird": TITLE_CREDIT_CARD_RECHARGE_FORM,
};

const THIRD_TRANSACTIONS_ID = {
    "/formCustom/transferLocal": ID_FORM_TRANSFER_LOCAL,
    "/formCustom/payCreditCardThird": ID_FORM_CREDIT_CARD,
    "creditCard.payment.creditCardThird.send": ID_FORM_CREDIT_CARD,
    "/formCustom/rechargeCreditCardThird": ID_FORM_CREDIT_CARD_RECHARGE,
    "creditCard.recharge.creditCardThird.send": ID_FORM_CREDIT_CARD_RECHARGE,
};

const TRANSACTIONS_KIND = {
    "transfers.local": "transfers",
    "creditCard.payment.creditCardThird": "cardPayment",
    "creditCard.recharge.creditCardThird": "recharge",
};

const TRANSACTIONS_PAY_LABEL = {
    "/formCustom/transferLocal": "forms.transfers.local.maketransfer.label_preview",
    "/formCustom/payCreditCardThird": "forms.creditCard.payment.makepay.label_preview",
    "/formCustom/rechargeCreditCardThird": "forms.creditCard.recharge.label_preview",
};

const TRANSACTIONS_SCHEDULER_LABEL = {
    "/formCustom/transferLocal": "forms.transfers.local.dateScheduler.label_preview",
    "/formCustom/payCreditCardThird": "forms.creditCard.payment.dateScheduler.label_preview",
    "/formCustom/rechargeCreditCardThird": "forms.creditCard.recharge.dateScheduler.label_preview",
};

const TRANSACTIONS_OPERATION_LABEL = {
    "/formCustom/transferLocal": "forms.transfers.local",
    "/formCustom/payCreditCardThird": "forms.payCreditCard.operationType",
    "creditCard.payment.creditCardThird.send": "forms.payCreditCard.operationType",
    "/formCustom/rechargeCreditCardThird": "forms.creditCard.recharge.typeOperation",
    "creditCard.recharge.creditCardThird.send": "forms.creditCard.recharge.typeOperation",
};

export const getThirdTransactionTitle = (string) => THIRD_TRANSACTIONS_TITLE[string] || TITLE_TRANSFER_FORM;

export const getThirdTransactionId = (pathName) => THIRD_TRANSACTIONS_ID[pathName] || "transfers.local";

export const getThirdTransactionPreviewViewTitle = (string) =>
    THIRD_TRANSACTIONS_PREVIEW_VIEW_TITLE[string] || TITLE_TRANSFER_FORM;

export const getTransactionKindByIdForm = (idForm) => TRANSACTIONS_KIND[idForm] || "transfers.local";

export const getTransactionPayLabel = (pathName) =>
    TRANSACTIONS_PAY_LABEL[pathName] || "forms.transfers.local.maketransfer.label_preview";
export const getTransactionSchedulerLabel = (pathName) =>
    TRANSACTIONS_SCHEDULER_LABEL[pathName] || "forms.transfers.local.dateScheduler.label_preview";

export const getTransactionOperationlabel = (string) => TRANSACTIONS_OPERATION_LABEL[string] || "forms.transfers.local";

import Box from "pages/_components/Box";
import classNames from "classnames";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { node, oneOfType, string, bool } from "prop-types";
import React, { Component } from "react";

class GeneralMsg extends Component {
    static propTypes = {
        title: oneOfType([string, node]),
        description: string,
        imagePath: string,
        pageTitle: node,
        callToAction: node,
        showWhiteBox: bool,
        minHeightFull: bool,
        descriptionNode: node,
    };

    static defaultProps = {
        title: null,
        description: null,
        imagePath: null,
        pageTitle: null,
        callToAction: null,
        showWhiteBox: false,
        minHeightFull: false,
        descriptionNode: null,
    };

    render() {
        const {
            title,
            description,
            imagePath,
            callToAction,
            pageTitle,
            showWhiteBox,
            minHeightFull,
            descriptionNode,
        } = this.props;

        return (
            <Box
                {...(showWhiteBox && {
                    background: "white",
                    borderRadius: "default",
                    border: "dashed-background-divider",
                })}
                display="flex"
                alignY="center"
                className={classNames("general-msg-wrapper", {
                    "min-height-full": minHeightFull,
                })}
                column>
                {pageTitle}
                {imagePath && <Image src={imagePath} className="mb-0 mb-md-6" />}
                {title && (
                    <Text component="p" align="center">
                        {title}
                    </Text>
                )}
                {description && <Text align="center" labelKey={description} />}
                {descriptionNode}
                {callToAction}
            </Box>
        );
    }
}

export default GeneralMsg;

import React, { useRef } from "react";
import { MainContainerContext } from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import CustomDatePicker from "pages/_components/fields/datepicker/CustomDatePicker";
import { isIOSPlatform, isMobileNativeFunc } from "util/device";
import { bool } from "prop-types";

const DatePickerWithCustomInput = (props) => {
    const startRef = useRef(null);

    const datePickerRef = useRef(null);

    const handleFocus = (e) => {
        e.preventDefault();
        datePickerRef.current.setOpen(true);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };

    const { isMobile } = props;

    const renderMobile = () => (
        <MainContainerContext.Consumer>
            {(ref) => (
                <CustomDatePicker
                    {...props}
                    onKeyDown={(e) => e.preventDefault()}
                    isMobile={isMobile}
                    ref={datePickerRef}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                    peekNextMonth
                    {...(isMobileNativeFunc() &&
                        isIOSPlatform() && { customInput: <CustomInput onFocus={handleFocus} /> })}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    popperPlacement="bottom-start"
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom-start"], // don't allow it to flip to be above
                        },
                    }}
                    viewContentRef={ref}
                />
            )}
        </MainContainerContext.Consumer>
    );

    const renderDesktop = () => (
        <CustomDatePicker
            {...props}
            isMobile={isMobile}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            autoComplete="off"
            ref={startRef}
            onKeyDown={onKeyDown}
            popperPlacement="bottom-start"
            popperModifiers={{
                flip: {
                    behavior: ["bottom-start"], // don't allow it to flip to be above
                },
            }}
        />
    );

    return <>{isMobile ? renderMobile() : renderDesktop()}</>;
};

// eslint-disable-next-line react/prop-types
const CustomInput = React.forwardRef(({ value, onClick, onFocus }, ref) => (
    <input
        ref={ref}
        value={value}
        onClick={onClick}
        onFocus={onFocus}
        readOnly
        style={{ cursor: "pointer", border: "none", marginLeft: "25px" }}
    />
));

DatePickerWithCustomInput.propTypes = {
    isMobile: bool.isRequired,
};

export default resizableRoute(DatePickerWithCustomInput);

import Box from "pages/_components/Box";
import PinInput from "pages/_components/fields/PinInput";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TokenButton from "pages/_components/TokenButton";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { replace } from "react-router-redux";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
// import * as config from "util/config";
// import * as i18n from "util/i18n";
import { Field } from "formik";
import { actions as authenticateHandlerActions } from "reducers/authenticateHandler";
import { selectors as sessionSelectors } from "reducers/session";
import { validationLength } from "util/validationSchemaUtil";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import { buildEntrustKey, buildEnvironmentKey } from "util/softToken.util";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "token.pin";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM = "forms.create.token.pin";

const CreateTokenPinForm = (props) => {
    const { dispatch } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [true, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
    } = {};

    const preFormReady = () => {
        dispatch(authenticateHandlerActions.preFormReady());
    };

    useEffect(() => {
        preFormReady();
    }, []);

    const validationSchema = () =>
        Yup.object().shape({
            pinCode: validationLength("", "token.pin.validation.pin", 4, 4, true, undefined),
        });

    const cancelAction = () => {
        dispatch(
            replace({
                pathname: "/settings/authenticatorHandler",
                state: { transition: "transition-flow-close" },
            }),
        );
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors, setErrors) => {
        const handlePinCodeChange = () => {
            setErrors({});
        };
        return (
            <Box
                display="flex"
                column
                alignY="center"
                borderRadius="default"
                background="component-background"
                className="pt-5 pb-7 px-5 mt-8 mb-3"
                flex1>
                <Text component="h2" className="mt-0 mb-4" size="3" bold color="heading" align="center">
                    {i18n.get(`token.pin.create.tittle`)}
                </Text>
                <Text component="p" className="mt-0 mb-9 px-8" size="5" color="heading" align="center">
                    {i18n.get(`token.pin.create.text`)}
                </Text>
                <Field
                    name="pinCode"
                    component={PinInput}
                    placeholder="*"
                    maxLength={1}
                    labelNoMarginTop
                    showLabel={false}
                    noMarginBottom
                    className="mb-9"
                    handleChangeProp={() => handlePinCodeChange(errors, setErrors)}
                    type="text"
                />
                <TokenButton isAlwaysUnlock entrustKey={buildEntrustKey()} environmentType={buildEnvironmentKey()} />
            </Box>
        );
    };

    const formProps = {
        title: TITLE_FORM,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderTicket: null,
        useDefaultSubmit: true,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        validationSchema,
        showSchedulerMessage: false,
        topDisclaimer: "",
        showFilterChips: false,
        cancelAction,
        formFlex: true,
        submitButtonLabel: "global.confirm",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    loggedUser: sessionSelectors.getUser(state),
});

CreateTokenPinForm.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    loggedUser: shape({
        bankType: string,
    }),
};
CreateTokenPinForm.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    location: {},
    fromTransaction: false,
    loggedUser: null,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(CreateTokenPinForm));

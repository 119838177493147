import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { channelsSelectors } from "reducers/administration";
import { selectors as sessionSelectors } from "reducers/session";

class CapsList extends Component {
    static propTypes = {
        activeEnvironment: shape({ type: string }).isRequired,
        caps: shape({
            amount: number,
            frequency: string,
            currency: string,
        }).isRequired,
        currency: shape({
            id: string,
        }).isRequired,
        topAmount: shape({
            amount: number,
            frequency: string,
            maximum: number,
        }),
        hasDefaultLabel: bool,
        isConfirmationStep: bool,
        isInTicket: bool,
    };

    static defaultProps = {
        hasDefaultLabel: false,
        topAmount: null,
        isConfirmationStep: false,
        isInTicket: false,
    };

    render() {
        const { currency = {}, isConfirmationStep, caps, isInTicket, topAmount } = this.props;

        const maxAmount = topAmount?.maximum || topAmount?.amount;
        const allAmount = caps?.all?.amount === undefined || caps?.all?.amount < 0 ? maxAmount : caps?.all?.amount;

        const frequency = caps?.all?.frequency ? caps?.all?.frequency : topAmount?.frequency;

        return (
            <>
                <Box
                    display="flex"
                    gap="2"
                    {...(isConfirmationStep && !isInTicket && { alignX: "between" })}
                    {...(!isConfirmationStep && !isInTicket && { column: true })}
                    {...(isInTicket && { alignX: "flex-start" })}>
                    <Text
                        component="label"
                        className="m-0"
                        labelKey="administration.channels.all"
                        size="5"
                        color={isInTicket ? "text-color" : "heading-color"}
                        {...((isInTicket || (isConfirmationStep && !isInTicket)) && { bold: true, addColon: true })}
                        {...(!isConfirmationStep && !isInTicket && { regular: true })}
                    />

                    <Box display="flex" gap="3">
                        <FormattedAmount
                            quantity={allAmount}
                            currency={currency.id}
                            size="5"
                            color={isInTicket ? "text-color" : "heading-color"}
                            {...(!isConfirmationStep && !isInTicket && { bold: true })}
                        />

                        {isConfirmationStep && (
                            <Text
                                labelKey={`administration.channels.${frequency}`}
                                size="5"
                                color={isInTicket ? "text-color" : "heading-color"}
                                regular
                            />
                        )}
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    caps: channelsSelectors.getCaps(state),
    currency: channelsSelectors.getCurrencies(state)[0],
    topAmount: channelsSelectors.getTopAmount(state),
});

export default connect(mapStateToProps)(CapsList);

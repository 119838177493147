import * as enrollment from "middleware/enrollment";
import { push, replace } from "react-router-redux";
import { selectors as enrollmentSelectors, types as enrollmentTypes } from "reducers/enrollment";
import { actions as notificationActions } from "reducers/notification";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { adjustIdFieldErrors } from "util/form";
import * as utilIdentification from "util/recoveryUser";
import { selectors as sessionSelectors } from "reducers/session";
import { softTokenTypes } from "reducers/softToken";
import {
    AUTHENTICATOR_TOKEN,
    BIOMETRIC_CALLBACK_SAVE_TOKEN,
    BIOMETRIC_CALLBACK_UNLOCK_MORPHOLOGY_TOKEN,
    BIOMETRIC_CALLBACK_UNLOCK_TOKEN,
} from "util/biometric.util";
import * as i18n from "util/i18n";
import {
    SELFIE_ERROR_CODE_S001,
    SELFIE_ERROR_CODE_S002,
    SELFIE_ERROR_CODE_S003,
    SELFIE_ERROR_CODE_UNDEFINED,
} from "util/userToken.util";
import { crashLogData } from "util/crashReport/crashReport.util";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";

const sagas = [
    takeLatest(enrollmentTypes.PRE_REQUEST, preData),
    takeLatest(enrollmentTypes.VALIDATE_DOCUMENT_REQUEST, validateDocument),
    takeLatest(enrollmentTypes.VALIDATE_INVITATION_CODE_REQUEST, validateInvitationCode),
    takeLatest(enrollmentTypes.FINISH_CREATE_REQUEST, finishCreate),
    takeLatest(enrollmentTypes.VALIDATE_USER_REQUEST, validateUser),
    takeLatest(enrollmentTypes.FINISH_ASSOCIATE_REQUEST, finishAssociate),
    takeLatest(enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_REQUEST, validateTokenActivationSelfie),
    takeLatest(enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_REQUEST, validateTokenActivationDocument),
];

export default sagas;

function* preData({ callback }) {
    const response = yield call(enrollment.preData);

    if (response.type === "W") {
        yield put({ type: enrollmentTypes.PRE_ERROR });

        let notificationScope = "enrollment";
        if (response.data.code === "API535W") {
            notificationScope = "externalLayout";
            yield put(push("/loginStep1"));
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        yield put({
            type: enrollmentTypes.PRE_SUCCESS,
            documentData: response.data.data,
        });
    }
    if (callback) {
        callback();
    }
}

function* validateDocument({ document, formikBag }) {
    const { documentCountry, documentType } = document;
    const documentNumber = utilIdentification.generateIdentificationToSend(document);

    const response = yield call(enrollment.validateDocument, documentCountry, documentType, documentNumber);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put({ type: enrollmentTypes.VALIDATE_DOCUMENT_ERROR });

        let notificationScope = "enrollment";
        switch (response.data.code) {
            case "COR020W":
                break;
            case "API535W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        const documentNumberToShow = utilIdentification.generateIdentificationToShow(document);

        yield put({
            type: enrollmentTypes.VALIDATE_DOCUMENT_SUCCESS,
            documentNumber: documentNumberToShow,
            exchangeToken: response.data.data._exchangeToken,
        });

        yield put(push("/enrollment/documentConfirmation"));
    }

    formikBag.setSubmitting(false);
}

function* validateInvitationCode({ invitationCode, captcha, isSecondary, formikBag }) {
    const exchangeToken = yield select(enrollmentSelectors.getExchangeToken);
    const response = yield call(enrollment.validateInvitationCode, invitationCode, captcha, isSecondary, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put({ type: enrollmentTypes.VALIDATE_INVITATION_CODE_ERROR });

        let notificationScope = "enrollment";
        switch (response.data.code) {
            case "COR020W":
                if (response.data.data.captcha) {
                    yield put({ type: enrollmentTypes.VALIDATE_INVITATION_CODE_ERROR_REQUIRE_CAPTCHA });
                }
                break;
            case "API533W":
            case "API536W":
                yield put({ type: enrollmentTypes.VALIDATE_INVITATION_CODE_ERROR_REQUIRE_CAPTCHA });
                break;
            case "API534W":
            case "API535W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        const { _exchangeToken, userFlow, invitationKind, environments } = response.data.data;

        yield put({
            type: enrollmentTypes.VALIDATE_INVITATION_CODE_SUCCESS,
            exchangeToken: _exchangeToken,
            userExists: userFlow,
            invitationKind,
            environments,
        });

        if (userFlow) {
            yield put(push("/enrollment/validateUser"));
        } else {
            yield put(push("/enrollment/createUser"));
        }
    }

    formikBag.setSubmitting(false);
}

function* finishCreate({ newUsername, newPassword, newPasswordRepeat, formikBag }) {
    const exchangeToken = yield select(enrollmentSelectors.getExchangeToken);
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "enrollment.wizard.finishCreate");

    const response = yield call(
        enrollment.finishCreate,
        newUsername,
        newPassword,
        newPasswordRepeat,
        exchangeToken,
        fingerPrintToken,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put({ type: enrollmentTypes.FINISH_CREATE_ERROR });

        let notificationScope = "enrollment";
        switch (response.data.code) {
            case "COR020W":
                break;
            case "API535W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
        }

        const errorValues = Object.values(response.data.data);

        if (errorValues?.length === 1) {
            yield put(notificationActions.showNotification(errorValues[0], "error", [notificationScope]));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
        }
    } else {
        const { name } = response.data.data;
        yield put({
            type: enrollmentTypes.FINISH_CREATE_SUCCESS,
            name,
        });

        yield put(push("/enrollment/finish"));
    }

    formikBag.setSubmitting(false);
}

function* validateUser({ usernameToValidate, password, captcha, formikBag }) {
    const exchangeToken = yield select(enrollmentSelectors.getExchangeToken);

    const response = yield call(enrollment.validateUser, usernameToValidate, password, captcha, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        yield put({ type: enrollmentTypes.VALIDATE_USER_ERROR });

        let notificationScope = "enrollment";
        switch (response.data.code) {
            case "COR020W":
                if (response.data.data.captcha) {
                    yield put({ type: enrollmentTypes.VALIDATE_USER_ERROR_REQUIRE_CAPTCHA });
                }
                break;
            case "API021W":
            case "COR050W":
            case "API516W":
            case "API519W":
                yield put({ type: enrollmentTypes.VALIDATE_USER_ERROR_REQUIRE_CAPTCHA });
                break;
            case "API517W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        const {
            _exchangeToken,
            userFullName,
            userDocumentCountry,
            userDocumentType,
            userDocumentNumber,
        } = response.data.data;

        yield put({
            type: enrollmentTypes.VALIDATE_USER_SUCCESS,
            exchangeToken: _exchangeToken,
            userFullName,
            userDocumentCountry,
            userDocumentType,
            userDocumentNumber,
        });

        yield put(push("/enrollment/summary"));
    }

    formikBag.setSubmitting(false);
}

function* finishAssociate({ callback }) {
    let notificationScope = "enrollment";
    const exchangeToken = yield select(enrollmentSelectors.getExchangeToken);
    if (!exchangeToken) {
        yield put({ type: enrollmentTypes.FINISH_ASSOCIATE_ERROR });
        yield put(notificationActions.showNotification(i18n.get("returnCode.API006E"), "error", [notificationScope]));
        callback(false);
    }
    const response = yield call(enrollment.finishAssociate, exchangeToken);

    if (response.type === "W") {
        yield put({ type: enrollmentTypes.FINISH_ASSOCIATE_ERROR });
        switch (response.data.code) {
            case "COR020W":
                break;
            case "API535W":
                yield put(push("/loginStep1"));
                notificationScope = "externalLayout";
                break;
            default:
        }

        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));

        callback(false);
    } else {
        const { name } = response.data.data;
        yield put({
            type: enrollmentTypes.FINISH_ASSOCIATE_SUCCESS,
            name,
        });

        callback(true);
    }
}

function* saveSoftToken(
    serialNumber,
    activateCode,
    deviceUuid,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
    userToken,
) {
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);
    let exchangeToken;
    if (!isLoggedIn) {
        exchangeToken = yield select(enrollmentSelectors.getExchangeToken);
    }

    const tokenRequest = {
        redirectSuccess,
        redirectError,
        enableActivate: true,
        exchangeToken,
        serialNumber,
        activateCode,
        isLoggedIn,
        deviceUuid,
        scopeSuccess,
        scopeError,
        userToken,
    };

    yield put({
        type: softTokenTypes.ACTIVATE_TOKEN_REQUEST,
        activateRequest: tokenRequest,
    });
}

function* showGenericErrorValidateToken(message) {
    yield put(
        notificationActions.showNotification(
            i18n.get(message || "validate.token.activation.error.label"),
            "error",
            ["tokenActivation"],
            false,
        ),
    );
}

function* showGenericErrorValidateTokenDocument(message) {
    yield put(
        notificationActions.showNotification(
            i18n.get(message || "validate.token.activation.document.error.label"),
            "error",
            ["tokenActivation"],
            false,
        ),
    );
}

function* selfieActivateToken(
    dataResponse,
    isLoggedIn,
    deviceUUID,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
) {
    const { activateCode, serialNumber, userToken } = dataResponse;
    if (!activateCode || !serialNumber) {
        yield put(push(isLoggedIn ? "/auth/tokenEntrustActivationFailed" : "/tokenEntrustActivationFailed"));
        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
        yield;
        return;
    }

    yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_SUCCESS });
    yield call(
        saveSoftToken,
        serialNumber,
        activateCode,
        deviceUUID,
        redirectSuccess,
        redirectError,
        scopeSuccess,
        scopeError,
        userToken,
    );
}

function* validateUnlockToken(
    dataResponse,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
    statusUnlockToken,
) {
    const { isTokenUnlocked } = dataResponse;
    const unlockMessage = `token.entrust.unlock.token.${isTokenUnlocked ? statusUnlockToken : "error"}.label`;
    const scope = (isTokenUnlocked ? scopeSuccess : scopeError) || [];
    const severity = isTokenUnlocked ? "success" : "error";

    yield put(notificationActions.showNotification(i18n.get(unlockMessage), severity, scope, false));

    const redirect = (isTokenUnlocked ? redirectSuccess : redirectError) || "/desktop";
    yield put(push(redirect));
}

function* redirectValidationDocument({
    isLoggedIn,
    documentType,
    callbackType,
    callbackData,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
    redirectResponse,
    redirectData,
}) {
    let callbackDataDocument = {
        type: callbackType,
        data: callbackData,
        redirectSuccess,
        redirectError,
        scopeSuccess,
        scopeError,
    };

    if (callbackType === BIOMETRIC_CALLBACK_UNLOCK_TOKEN) {
        callbackDataDocument = {
            ...callbackDataDocument,
            type: BIOMETRIC_CALLBACK_UNLOCK_MORPHOLOGY_TOKEN,
            authenticatorType: AUTHENTICATOR_TOKEN,
            deviceUuid: window?.app?.getDeviceUUID() || "",
        };
    }

    yield put(
        push({
            pathname: redirectResponse || (isLoggedIn ? "/auth/tokenActivationFailed" : "/tokenActivationFailed"),
            state: { ...(redirectData || {}) },
        }),
    );
    yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
    yield put({ type: enrollmentTypes.SET_DOCUMENT_TYPE_TOKEN, documentType });
    yield put({ type: enrollmentTypes.SET_CALLBACK_DATA_DOCUMENT, callbackDataDocument });
}

function* redirectSelfieError(selfieErrorCode, callbackRedirectDocument, isLoggedIn, currentAttempt = 0) {
    yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
    if (!selfieErrorCode || selfieErrorCode === SELFIE_ERROR_CODE_UNDEFINED) {
        yield call(showGenericErrorValidateToken);
        yield;
        return;
    }

    if (selfieErrorCode === SELFIE_ERROR_CODE_S001) {
        yield call(redirectValidationDocument, {
            ...callbackRedirectDocument,
            redirectResponse: isLoggedIn ? "/auth/tokenActivationAttemps" : "/tokenActivationAttemps",
            redirectData: { currentAttempt },
        });
        yield;
        return;
    }

    if (selfieErrorCode === SELFIE_ERROR_CODE_S002 || selfieErrorCode === SELFIE_ERROR_CODE_S003) {
        yield call(redirectValidationDocument, {
            ...callbackRedirectDocument,
            redirectResponse: isLoggedIn ? "/auth/tokenActivationStep2" : "/tokenActivationStep2",
        });
        yield;
        return;
    }

    yield call(showGenericErrorValidateToken);
}

function* validateTokenActivationSelfie({
    selfie,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
    callbackType,
    callbackData,
    redirectDocument,
    selfieNonTokenized,
    currentAttempt,
}) {
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);
    let exchangeToken;
    if (!isLoggedIn) {
        exchangeToken = yield select(enrollmentSelectors.getExchangeToken);
    }

    const response = yield call(
        isLoggedIn ? enrollment.validateTokenActivationSelfieAuth : enrollment.validateTokenActivationSelfie,
        selfie,
        callbackType,
        callbackData,
        selfieNonTokenized,
        exchangeToken,
    );

    let loggedUser = yield select((state) => sessionSelectors.getUser(state));
    loggedUser = loggedUser || { user: "Undefined" };

    crashLogData({
        title: "respoSelf",
        user: loggedUser?.idUser || "",
        deviceUuid: callbackData?.deviceUuid || "",
        responseSelfie: response,
    });

    if (!response?.type || !response?.data?.data) {
        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
        yield call(showGenericErrorValidateToken);
        yield;
        return;
    }

    if (response.type === "W") {
        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
        yield call(showGenericErrorValidateToken);
        yield;
        return;
    }

    const { validationSelfiePassed, documentType, selfieErrorCode } = response.data.data;

    if (!validationSelfiePassed || validationSelfiePassed !== true) {
        if (!documentType) {
            yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
            yield call(showGenericErrorValidateToken);
            yield;
            return;
        }

        yield call(
            redirectSelfieError,
            selfieErrorCode,
            {
                isLoggedIn,
                documentType,
                callbackType,
                callbackData,
                redirectSuccess:
                    redirectDocument ||
                    redirectSuccess ||
                    (isLoggedIn ? "/auth/tokenActivationPending" : "/tokenActivationPending"),
                redirectError,
                scopeSuccess,
                scopeError,
            },
            isLoggedIn,
            currentAttempt,
        );

        yield;
        return;
    }

    yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_SUCCESS });
    if (!callbackType) {
        yield;
        return;
    }

    if (callbackType === BIOMETRIC_CALLBACK_SAVE_TOKEN) {
        yield call(
            selfieActivateToken,
            response.data.data,
            isLoggedIn,
            callbackData?.deviceUuid,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
        );
        return;
    }
    if (callbackType === BIOMETRIC_CALLBACK_UNLOCK_TOKEN) {
        yield call(
            validateUnlockToken,
            response.data.data,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
            "success",
        );
    }
}

function* scanDocumentActivateToken(
    dataResponse,
    isLoggedIn,
    deviceUuid,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
) {
    const { serialNumber, activateCode, userToken } = dataResponse;

    if (!activateCode || !serialNumber) {
        yield put(push(isLoggedIn ? "/auth/tokenEntrustActivationFailed" : "/tokenEntrustActivationFailed"));
        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR });
        yield;
        return;
    }

    yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_SUCCESS });
    yield call(
        saveSoftToken,
        serialNumber,
        activateCode,
        deviceUuid,
        redirectSuccess,
        redirectError,
        scopeSuccess,
        scopeError,
        userToken,
    );
}

function* validateTokenActivationDocument({
    documentSelected,
    documentScannedList,
    ocrData,
    callbackType,
    callbackData,
    redirectSuccess,
    redirectError,
    scopeSuccess,
    scopeError,
}) {
    const isLoggedIn = yield select(sessionSelectors.isLoggedIn);
    let exchangeToken;
    if (!isLoggedIn) {
        exchangeToken = yield select(enrollmentSelectors.getExchangeToken);
    }

    const response = yield call(
        isLoggedIn ? enrollment.validateTokenActivationDocumentAuth : enrollment.validateTokenActivationDocument,
        documentSelected,
        documentScannedList,
        ocrData,
        callbackType,
        callbackData,
        exchangeToken,
    );

    let loggedUser = yield select((state) => sessionSelectors.getUser(state));
    crashLogData({
        title: "responseDoc",
        user: loggedUser?.idUser || "",
        deviceUuid: callbackData?.deviceUuid || "",
        responseDoc: response,
    });

    if (!response?.type || !response?.data?.data) {
        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_ERROR });
        yield call(showGenericErrorValidateTokenDocument, response?.data?.message);
        yield;
        return;
    }

    const { type } = response;

    if (type === "W") {
        if (response?.data?.code && response.data.code === "API559W") {
            yield put(replace(isLoggedIn ? "/auth/tokenActivationFailedDocument" : "/tokenActivationFailedDocument"));
            yield;
            return;
        }

        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_ERROR });
        yield call(showGenericErrorValidateTokenDocument, response?.data?.message);
        yield;
        return;
    }

    if (!loggedUser) {
        loggedUser = { user: "Undefined", isSecondary: false };
        const { userToken } = response.data?.data;
        if (userToken) {
            loggedUser = { user: userToken?.idUser, isSecondary: userToken?.environmentType === "CORPORATE_SECONDARY" };
        }
    }
    // Usuario secundario
    if (loggedUser?.isSecondary === true && callbackType === BIOMETRIC_CALLBACK_SAVE_TOKEN) {
        yield call(
            scanDocumentActivateToken,
            response?.data?.data,
            isLoggedIn,
            callbackData?.deviceUuid,
            isLoggedIn ? "/auth/tokenActivationPendingSecondary" : "/tokenActivationPendingSecondary",
            redirectError,
            scopeSuccess,
            scopeError,
        );
        yield;
        return;
    }

    // usuario secundario desbloqueo
    if (
        loggedUser?.isSecondary === true &&
        (callbackType === BIOMETRIC_CALLBACK_UNLOCK_TOKEN ||
            callbackType === BIOMETRIC_CALLBACK_UNLOCK_MORPHOLOGY_TOKEN)
    ) {
        yield call(
            validateUnlockToken,
            response.data.data,
            isLoggedIn ? "/auth/tokenActivationPendingSecondary" : "/tokenActivationPendingSecondary",
            redirectError,
            scopeSuccess,
            scopeError,
            "pending",
        );
        yield;
        return;
    }

    const { scanDocumentReference } = response.data.data;
    if (!scanDocumentReference || scanDocumentReference === "") {
        yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_ERROR });
        yield call(showGenericErrorValidateTokenDocument, response?.data?.message);
        yield;
        return;
    }

    yield put({ type: enrollmentTypes.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_SUCCESS });

    if (!callbackType) {
        yield;
        return;
    }

    if (callbackType === BIOMETRIC_CALLBACK_SAVE_TOKEN) {
        yield call(
            scanDocumentActivateToken,
            response?.data?.data,
            isLoggedIn,
            callbackData?.deviceUuid,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
        );
        yield;
        return;
    }

    if (
        callbackType === BIOMETRIC_CALLBACK_UNLOCK_TOKEN ||
        callbackType === BIOMETRIC_CALLBACK_UNLOCK_MORPHOLOGY_TOKEN
    ) {
        yield call(
            validateUnlockToken,
            response.data.data,
            redirectSuccess,
            redirectError,
            scopeSuccess,
            scopeError,
            "pending",
        );
    }
}

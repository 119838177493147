import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    TRANSACTIONAL_COUNTRIES_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_REQUEST",
    TRANSACTIONAL_COUNTRIES_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SUCCESS",
    TRANSACTIONAL_COUNTRIES_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_FAILED",
    TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST",
    TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS",
    TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED",
    TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST",
    TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS",
    TRANSACTIONAL_COUNTRIES_UPDATE_FAILED: "transactionalProfile/TRANSACTIONAL_COUNTRIES_UPDATE_FAILED",
    TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST: "transactionalProfile/TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST",
    TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS: "transactionalProfile/TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS",
    MODAL_SHOW: "transactionalProfile/MODAL_SHOW",
    MODAL_CLOSE: "transactionalProfile/MODAL_CLOSE",
    MODAL_SHOW_MESSAGE: "transactionalProfile/MODAL_SHOW_MESSAGE",
    MODAL_CLOSE_MESSAGE: "transactionalProfile/MODAL_CLOSE_MESSAGE",
    SET_MODIFIED_COUNTRY: "transactionalProfile/SET_MODIFIED_COUNTRY",
    SET_MODIFIED_COUNTRY_LIST: "transactionalProfile/SET_MODIFIED_COUNTRY_LIST",
    SET_IS_ALL_IN_CHECKED: "transactionalProfile/SET_IS_ALL_IN_CHECKED",
    SET_IS_ALL_OUT_CHECKED: "transactionalProfile/SET_IS_ALL_OUT_CHECKED",
    RESET_COUNTRY_SELECTED: "transactionalProfile/RESET_COUNTRY_SELECTED",
};

export const INITIAL_STATE = {
    isFetching: false,
    countriesList: [],
    modifiedCountries: [],
    accountList: [],
    credentialsGroups: [],
    hasCompleteCredentialGroups: false,
    displayModal: false,
    displayModalMessage: false,
    isAllInChecked: false,
    isAllOutChecked: false,
    modalProps: {},
    isOpenFromMenu: false,
};

export default createReducer(INITIAL_STATE, {
    [types.TRANSACTIONAL_COUNTRIES_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        countriesList: [],
        hasProfile: null,
        accountSelected: action.account,
    }),
    [types.MODAL_SHOW]: (state, action) => ({
        ...state,
        displayModal: true,
        modalProps: action.modalProps,
        isOpenFromMenu: action.isOpenFromMenu,
    }),
    [types.MODAL_CLOSE]: (state) => ({ ...state, displayModal: false, isOpenFromMenu: false }),

    [types.MODAL_SHOW_MESSAGE]: (state) => ({ ...state, displayModalMessage: true, isOpenFromMenu: false }),
    [types.MODAL_CLOSE_MESSAGE]: (state) => ({ ...state, displayModalMessage: false, isOpenFromMenu: false }),

    [types.TRANSACTIONAL_COUNTRIES_SUCCESS]: (state, action) => {
        const countriesMap = {};
        action.countries.forEach((item) => {
            countriesMap[item.id] = item;
        });

        return {
            ...state,
            isFetching: false,
            countriesList: action.countries,
            modifiedCountries: countriesMap,
            hasProfile: action.hasProfile,
        };
    },

    [types.RESET_COUNTRY_SELECTED]: (state) => {
        const countriesMap = {};
        state.countriesList.forEach((item) => {
            countriesMap[item.id] = item;
        });
        return {
            ...state,
            modifiedCountries: countriesMap,
        };
    },

    [types.TRANSACTIONAL_COUNTRIES_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        countriesList: [],
        hasProfile: null,
        accountSelected: null,
    }),
    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        countriesList: [],
        hasProfile: false,
        accountSelected: null,
        accountList: [],
    }),

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS]: (state, action) => {
        const countriesMap = {};
        action.countries.forEach((item) => {
            countriesMap[item.id] = item;
        });

        return {
            ...state,
            isFetching: false,
            countriesList: action.countries,
            modifiedCountries: countriesMap,
            hasProfile: action.hasProfile,
            accountList: action.accounts,
            accountSelected: action.account,
        };
    },

    [types.TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        countriesList: [],
        hasProfile: false,
        accountSelected: null,
        accountList: [],
        displayModal: false,
    }),
    [types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),

    [types.TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countriesList: action.countries,
        hasProfile: action.hasProfile,
    }),

    [types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        hasCompleteCredentialGroups: true,
    }),
    [types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST]: (state) => ({
        ...state,
        hasCompleteCredentialGroups: false,
    }),
    [types.SET_MODIFIED_COUNTRY]: (state, action) => {
        const modifiedCountriesMap = {
            ...state.modifiedCountries,
            [action.countryId]: { ...state.modifiedCountries[action.countryId], [action._type]: action.value },
        };
        return {
            ...state,
            modifiedCountries: modifiedCountriesMap,
        };
    },
    [types.SET_MODIFIED_COUNTRY_LIST]: (state, action) => {
        const modifiedCountriesList = Object.values(state.modifiedCountries).map((item) => ({
            ...item,
            [action._type]: action.value,
        }));

        const modifiedCountriesMap = {};
        modifiedCountriesList.forEach((item) => {
            modifiedCountriesMap[item.id] = item;
        });

        return {
            ...state,
            modifiedCountries: modifiedCountriesMap,
        };
    },
    [types.SET_IS_ALL_IN_CHECKED]: (state, action) => ({
        ...state,
        isAllInChecked: action.isAllInChecked,
    }),
    [types.SET_IS_ALL_OUT_CHECKED]: (state, action) => ({
        ...state,
        isAllOutChecked: action.isAllOutChecked,
    }),
});

export const actions = {
    transactionalCountriesRequest: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_REQUEST, "accountId", "account"),

    transactionalCountriesRequestSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SUCCESS,
        "countries",
        "hasProfile",
    ),

    transactionalCountriesRequestFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_FAILED),

    transactionalCountriesSettingRequest: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SETTINGS_REQUEST,
        "accountId",
        "onClose",
    ),
    transactionalCountriesSettingSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_SETTINGS_SUCCESS,
        "countries",
        "hasProfile",
        "accounts",
        "account",
    ),
    transactionalCountriesSettingFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_SETTINGS_FAILED),

    transactionalCountriesUpdateRequest: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_UPDATE_REQUEST,
        "accountId",
        "countriesList",
        "onFinish",
        "otp",
        "formikBag",
        "isCreate",
        "hasTransactionalProfile",
    ),
    transactionalCountriesUpdateSuccess: makeActionCreator(
        types.TRANSACTIONAL_COUNTRIES_UPDATE_SUCCESS,
        "countries",
        "hasProfile",
    ),
    transactionalCountriesUpdateFailed: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED),
    transactionalProfileActions: makeActionCreator(types.TRANSACTIONAL_COUNTRIES_UPDATE_FAILED),
    credentialsGroupsRequest: makeActionCreator(types.TRANSACTIONAL_CREDENTIALS_GROUPS_REQUEST, "data"),
    credentialsGroupsSuccess: makeActionCreator(types.TRANSACTIONAL_CREDENTIALS_GROUPS_SUCCESS, "credentialsGroups"),
    modalShow: makeActionCreator(types.MODAL_SHOW, "modalProps", "isOpenFromMenu"),
    modalHide: makeActionCreator(types.MODAL_CLOSE),
    modalMessageShow: makeActionCreator(types.MODAL_SHOW_MESSAGE),
    modalMessageHide: makeActionCreator(types.MODAL_CLOSE_MESSAGE),
    setModifiedCountry: makeActionCreator(types.SET_MODIFIED_COUNTRY, "value", "countryId", "_type"),
    setModifiedCountryList: makeActionCreator(types.SET_MODIFIED_COUNTRY_LIST, "_type", "value"),
    setIsAllInChecked: makeActionCreator(types.SET_IS_ALL_IN_CHECKED, "isAllInChecked"),
    setIsAllOutChecked: makeActionCreator(types.SET_IS_ALL_OUT_CHECKED, "isAllOutChecked"),
    resetCountrySelected: makeActionCreator(types.RESET_COUNTRY_SELECTED),
};

export const selectors = {
    getFetching: ({ transactionalProfile }) => transactionalProfile.isFetching,
    getCountriesList: ({ transactionalProfile }) => transactionalProfile.countriesList,
    getModifiedCountries: ({ transactionalProfile }) => transactionalProfile.modifiedCountries,
    getSelectedCountryId: ({ transactionalProfile }) => transactionalProfile.modalProps?.countryId,
    getHasProfile: ({ transactionalProfile }) => transactionalProfile.hasProfile,
    getAccount: ({ transactionalProfile }) => transactionalProfile.accountSelected,
    getAccounts: ({ transactionalProfile }) => transactionalProfile.accountList,
    getCredentialsGroups: ({ transactionalProfile }) => transactionalProfile.credentialsGroups,
    hasCompleteCredentialGroups: ({ transactionalProfile }) => transactionalProfile.hasCompleteCredentialGroups,
    getDisplayModal: ({ transactionalProfile }) => transactionalProfile?.displayModal,
    getModalProps: ({ transactionalProfile }) => transactionalProfile?.modalProps,
    getIsOpenFromMenu: ({ transactionalProfile }) => transactionalProfile?.isOpenFromMenu,
    getDisplayModalMessage: ({ transactionalProfile }) => transactionalProfile?.displayModalMessage,
    getIsAllInChecked: ({ transactionalProfile }) => transactionalProfile.isAllInChecked,
    getIsAllOutChecked: ({ transactionalProfile }) => transactionalProfile.isAllOutChecked,
};

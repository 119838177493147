import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { string } from "yup";
import FieldError from "../FieldError";

const PinInput = ({
    inputLenght,
    form,
    field,
    className,
    noMarginBottom,
    isMasked,
    inputMode,
    handleChangeProp,
    type,
}) => {
    const [pin, setPin] = useState(new Array(inputLenght).fill(""));

    const [isVisible, setVisible] = useState(false);

    const handleChange = (element, index) => {
        const valueInput = element?.value && element.value !== "" ? [...element.value][0] : "";

        // Only numbers validation
        if (!Number.isNaN(Number(element.value))) {
            const resultPin = [...pin].map((item, idx) => (idx === index ? valueInput : item));
            setPin(resultPin);
            form.setFieldValue(
                field.name,
                resultPin.reduce((acc, item) => acc + item, ""),
            );
            if (handleChangeProp) {
                handleChangeProp();
            }
        }
    };

    const fieldHasError = () => {
        if (!form || !form.errors || !field || !field.name) {
            return false;
        }
        const { errors } = form;
        const { name } = field;
        return Object.keys(errors).includes(name);
    };

    const renderError = () => {
        if (!fieldHasError()) {
            return <div />;
        }
        const { errors } = form;
        const { name } = field;
        return <FieldError error={errors[name]} />;
    };

    const preventSpace = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    const handleKeyUp = (e) => {
        if ((e.key >= "0" && e.key <= "9") || e.key === "Backspace") {
            if (e.target.nextSibling && e.target.value !== "" && e.key !== "Tab" && e.Key !== "Shift") {
                e.target.nextSibling.focus();
            }

            if (e.target.previousSibling && e.key === "Backspace" && e.key !== "Tab" && e.Key !== "Shift") {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleBlur = () => {
        setVisible(false);
    };

    const toggleIsVisible = () => {
        setVisible((prevState) => !prevState);
    };

    useEffect(() => {
        pin.forEach((item, index) => {
            document.getElementById(`pin_${index}`).addEventListener("paste", (e) => {
                e.preventDefault();
            });
        });
    }, []);

    return (
        <Box
            className={classNames("form-group", className, {
                "has-error": fieldHasError(),
            })}>
            <Box
                display="flex"
                alignX="center"
                className={classNames("height-auto", { "mb-0": noMarginBottom, "mb-10 mb-md-8": !noMarginBottom })}>
                {pin.map((data, index) => (
                    <input
                        id={`pin_${index}`}
                        className={classNames("form-control input", { "mask-input": !isVisible && type !== "text" })}
                        type={type}
                        name="pin"
                        placeholder="*"
                        maxLength="1"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyPress={preventSpace}
                        onFocus={handleFocus}
                        onKeyUp={handleKeyUp}
                        onBlur={handleBlur}
                        inputMode={inputMode}
                        pattern="^[0-9]*$"
                        autoComplete="off"
                    />
                ))}
                {isMasked && (
                    <Button
                        className="btn-only-icon"
                        bsStyle="link"
                        block={false}
                        onClick={toggleIsVisible}
                        ariaLabel={
                            isVisible
                                ? "login.step1.secondFactor.maskPassword.a11y"
                                : "login.step1.secondFactor.unmaskPassword.a11y"
                        }>
                        <Image
                            src={isVisible ? "images/icons/showPass.svg" : "images/icons/hidePass.svg"}
                            className="svg-icon"
                            color="primary-color-i"
                        />
                    </Button>
                )}
            </Box>
            {isMasked && (
                <Text
                    component="p"
                    size="7"
                    align="center"
                    labelKey="activities.creditCard.assignPin.inputLabel"
                    className="mr-10 mb-0 mt-5"
                />
            )}
            {renderError()}
        </Box>
    );
};

PinInput.propTypes = {
    className: string,
    field: shape({}).isRequired,
    form: shape({}).isRequired,
    inputLenght: number,
    isMasked: bool,
    noMarginBottom: bool,
    inputMode: string,
    handleChangeProp: func.isRequired,
    type: string,
};

PinInput.defaultProps = {
    className: "mb-10 mb-md-8",
    inputLenght: 4,
    isMasked: false,
    noMarginBottom: false,
    inputMode: "numeric",
    type: "password",
};

export default PinInput;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelectors,
} from "reducers/authenticateHandler";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { push } from "react-router-redux";
import { actions as statusActions } from "reducers/status";
import {
    TOKEN_STATUS_AS_ACTIVE_LIST,
    TOKEN_STATUS_AS_PENDING_LIST,
    USER_TOKEN_STATUS_UNDEFINED,
} from "util/userToken.util";
import { ENT000, hasRegisteredIdentity } from "util/softToken.util";
import { bool, func, shape, string } from "prop-types";
import TokenButton from "./TokenButton";

const TokenButtonWrapper = ({
    dispatch,
    isFetching,
    isStep0,
    tokenValidateStatus,
    entrustKey,
    isShowDetails,
    changeLoader,
    isDetails,
    userNameToken,
}) => {
    const [hasSofttokenRegister, setHasSofttokenRegister] = useState(false);
    const [hasSofttokenRegisterLoading, setHasSofttokenRegisterLoading] = useState(true);
    const [showToken, setShowToken] = useState(true);

    const validateRegisteredIdentity = async () => {
        try {
            setHasSofttokenRegisterLoading(true);

            const hasRegisteredIdentityResponse = await hasRegisteredIdentity(
                entrustKey?.key || "",
                entrustKey?.environmentType || "",
            );

            if (!hasRegisteredIdentityResponse) {
                setHasSofttokenRegister(false);
                setHasSofttokenRegisterLoading(false);
                return;
            }

            const { code, data } = JSON.parse(hasRegisteredIdentityResponse);
            if (!code || code !== ENT000 || !data || data !== "true") {
                setHasSofttokenRegister(false);
                setHasSofttokenRegisterLoading(false);
                return;
            }
            setHasSofttokenRegister(true);
        } catch (error) {
            setHasSofttokenRegisterLoading(false);
        }
    };

    const getTokenInfo = () => {
        dispatch(
            authenticateHandlerActions.getTokenInfoStatusRequest(
                () => {
                    setHasSofttokenRegisterLoading(false);
                },
                entrustKey?.environmentType || "",
                entrustKey?.key || "",
            ),
        );
    };

    useEffect(() => {
        if (entrustKey) {
            validateRegisteredIdentity();
        }
    }, [entrustKey]);

    useEffect(() => {
        if (hasSofttokenRegister) {
            getTokenInfo();
        }
    }, [hasSofttokenRegister]);

    const handleClickActiveToken = () => {
        dispatch(push("/loginStep1"));
        dispatch(statusActions.saveLastHref("/tokenActivationStep1"));
    };

    const renderLoading = () => <Button className="btn-login-action btn-login-action-dashed" block loading />;

    const showTokenHandler = async () => {
        if (isShowDetails) {
            dispatch(push("/tokenViewDetails"));
        } else {
            setShowToken(false);
        }
    };

    const renderActiveTokenButton = () => (
        <Button
            className={`btn-login-action btn-login-action-dashed 
            ${isDetails && "py-4 background-white border-background-divider border-radius-extreme"}`}
            onClick={handleClickActiveToken}
            block>
            <Box>
                <Box>
                    <Text labelKey="login.step0.active.token" />
                </Box>
                {isDetails && (
                    <Box>
                        <Text>{userNameToken}</Text>
                    </Box>
                )}
            </Box>
        </Button>
    );

    const renderButtonShowToken = () => (
        <Button
            className="btn-login-action"
            bsStyle="outline"
            onClick={() => showTokenHandler()}
            block
            label="token.show.button"
        />
    );

    const renderTokenButton = () => {
        if (isStep0 && showToken && hasSofttokenRegister) {
            return renderButtonShowToken();
        }
        if (hasSofttokenRegisterLoading || isFetching) {
            return renderLoading();
        }
        if (changeLoader) {
            changeLoader();
        }
        if (!tokenValidateStatus || tokenValidateStatus === USER_TOKEN_STATUS_UNDEFINED) {
            return renderActiveTokenButton();
        }

        if (TOKEN_STATUS_AS_PENDING_LIST.some((item) => item === tokenValidateStatus)) {
            return (
                <Button
                    className={`btn-login-action btn-login-action-dashed 
                     ${isDetails && "py-4 background-white border-background-divider border-radius-extreme"}`}
                    disabled
                    block>
                    <Box>
                        <Box>
                            <Text labelKey="login.step0.pending.token" />
                        </Box>
                        {isDetails && (
                            <Box>
                                <Text>{userNameToken}</Text>
                            </Box>
                        )}
                    </Box>
                </Button>
            );
        }

        if (TOKEN_STATUS_AS_ACTIVE_LIST.some((item) => item === tokenValidateStatus)) {
            return (
                <TokenButton
                    isStep0={isStep0}
                    entrustKey={entrustKey?.key || ""}
                    environmentType={entrustKey?.environmentType || ""}
                    loadingPrimaryBackground
                    isDetails={isDetails}
                />
            );
        }

        return renderActiveTokenButton();
    };

    const renderContent = () => (hasSofttokenRegister ? renderTokenButton() : renderActiveTokenButton());

    if (isStep0) {
        return renderContent();
    }

    return hasSofttokenRegisterLoading || isFetching ? renderLoading() : renderContent();
};

const mapStateToProps = (state, ownProps) => ({
    isFetching: authenticateHandlerSelectors.isFetchingTokenValidateStatusByList(state, ownProps.entrustKey?.key),
    tokenValidateStatus: authenticateHandlerSelectors.getTokenValidateStatusByList(state, ownProps.entrustKey?.key),
    userNameToken: authenticateHandlerSelectors.getUserNameByList(state, ownProps.entrustKey?.key),
});

TokenButtonWrapper.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isStep0: bool,
    tokenValidateStatus: string,
    entrustKey: shape({}).isRequired,
    changeLoader: func,
    isDetails: bool,
    userNameToken: string,
};
TokenButtonWrapper.defaultProps = {
    isFetching: false,
    isStep0: false,
    tokenValidateStatus: undefined,
    entrustKey: null,
    changeLoader: null,
    isDetails: false,
    userNameToken: "",
};

export default connect(mapStateToProps)(TokenButtonWrapper);

import classNames from "classnames";
import Avatar from "pages/_components/Avatar";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import I18n from "pages/_components/I18n";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import Dropdown from "../Dropdown";
import Popup from "../Popup";
import UserLink from "../UserLink";

const NavigationBarOptions = ({
    activeEnvironment,
    className,
    environments,
    goToEnvironment,
    loggedUser,
    logOut,
    maskAmount,
    showMask,
    updateMaskAmount,
    unreadCommunications,
}) => (
    <Box
        id="navigation-bar-header"
        component="nav"
        role="navigation"
        // TODO (LABEL a11y)
        ariaLabel="Notificaciones y Configuración"
        className={classNames("navigation-bar-options", className)}>
        {showMask && (
            <Button
                id="eyeButton"
                onClick={() => {
                    updateMaskAmount();
                }}
                bsStyle="link"
                image={maskAmount ? "images/icons/showOff.svg" : "images/icons/show.svg"}
                label={maskAmount ? "navigation.bar.desktop.show" : "navigation.bar.desktop.show.off"}
                labelClassName="label-class-name"
                imageMd
            />
        )}
        <Box display="flex" position="relative">
            <Button
                id="notificationButton"
                bsStyle="only-icon"
                image="images/icons/bell.svg"
                href="/communications"
                imageMd>
                <I18n component="p" id="menu.chat.badge" componentProps={{ className: "visually-hidden" }} />
            </Button>
            <Badge count={unreadCommunications} />
        </Box>

        {environments && Object.keys(environments).length > 1 && (
            <Dropdown
                buttonClass="header-dropdown-button"
                dropdownButtonContent={<EnvironmentTag type={activeEnvironment.type} name={activeEnvironment.name} />}
                pullRight>
                {Object.values(environments)
                    .filter((environment) => parseInt(environment.idEnvironment, 10) !== activeEnvironment.id)
                    .map((environment) => (
                        <Button
                            key={environment.idEnvironment}
                            role="option"
                            className="dropdown__item-btn header-dropdown-item"
                            bsStyle="link"
                            imageMd
                            onClick={() => goToEnvironment(environment.idEnvironment)}>
                            <EnvironmentTag type={environment.environmentType} name={environment.name} />
                        </Button>
                    ))}
            </Dropdown>
        )}
        {environments &&
            Object.keys(environments).length === 1 &&
            Object.values(environments).map((environment) => (
                <Box key={environment.name} display="flex" className="px-4 min-height-9">
                    <EnvironmentTag type={environment.environmentType} name={environment.name} />
                </Box>
            ))}

        <Popup
            id="userPopup"
            popupTitleID="menu.settings.panelTitle"
            popupButtonContent={<Avatar noImage user={loggedUser} />}>
            <UserLink loggedUser={loggedUser} />
            <Row className="px-0">
                <Col sm={12} className="col-12">
                    <Button bsStyle="link" onClick={() => logOut()} block imageMd>
                        <Text className="popup-btn-text" labelKey="global.logout" />
                    </Button>
                </Col>
            </Row>
        </Popup>
    </Box>
);

NavigationBarOptions.propTypes = {
    activeEnvironment: shape({
        administrationScheme: string,
        forms: shape({}),
        id: number,
        name: string,
        permissions: shape({
            accounts: bool,
            creditcards: bool,
            loans: bool,
            payCreditCard: bool,
            payCreditCardThird: bool,
            payCreditCardOther: bool,
            payLoan: bool,
            payLoanOther: bool,
            payLoanThird: bool,
            payThirdPartiesCreditCard: bool,
            payThirdPartiesLoan: bool,
            requestTransactionCancellation: bool,
            transferForeign: bool,
            transferInternal: bool,
            transferLocal: bool,
            transferThirdParties: bool,
        }),
        type: string,
    }),
    className: string,
    environments: objectOf(
        shape({
            environmentType: string,
            idEnvironment: string,
            name: string,
        }),
    ),
    goToEnvironment: func,
    maskAmount: bool,
    loggedUser: shape({
        accessToken: string,
        defaultAvatarId: string,
        email: string,
        previousLoginInfo: arrayOf(
            shape({
                city: string,
                country: string,
                date: string,
                idUser: string,
                ip: string,
                latitude: number,
                longitude: number,
            }),
        ),
        securitySeal: oneOfType([number, string]),
        userFullName: string,
    }),
    logOut: func,
    showMask: bool,
    unreadCommunications: number,
    updateMaskAmount: func,
};

NavigationBarOptions.defaultProps = {
    activeEnvironment: null,
    className: null,
    environments: null,
    goToEnvironment: null,
    loggedUser: null,
    logOut: null,
    maskAmount: false,
    showMask: false,
    unreadCommunications: 0,
    updateMaskAmount: null,
};

export default NavigationBarOptions;

import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PinInput from "pages/_components/fields/PinInput";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { parse } from "query-string";
import Disclaimer from "pages/_components/Disclaimer";
import { startSensorDataCapture } from "util/monitor/monitorDbf.utils";

const FORM_ID = "settings.changePhone";
const INPUT_LENGTH_CONFIRMATION = 4;
class ChangePhoneConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        phoneCanBeUsedInWally: bool,
        userFullName: string.isRequired,
        location: shape({}),
    };

    static defaultProps = {
        location: { search: {} },
        phoneCanBeUsedInWally: false,
    };

    componentDidMount() {
        startSensorDataCapture();
    }

    confirmCodeForm = () => {
        const { isDesktop, isSubmitting, location, phoneCanBeUsedInWally } = this.props;
        const mobilePhone = parse(location.search)?.mobilePhone || "";

        return (
            <Form className="full-height" noValidate="novalidate">
                {!phoneCanBeUsedInWally && (
                    <Disclaimer
                        styled="warning"
                        labelKey="settings.changePhone.notAllowed.disclaimer.wally"
                        borderRadius="top-default"
                    />
                )}
                <Box display="flex" column fullWidth fullHeight>
                    <Box
                        background="white"
                        className={classNames("mt-5 mt-md-0 pt-5 pt-md-8 px-5 pb-8 pb-md-12 mb-8", {
                            "mx-n-5 full-height": !isDesktop,
                        })}
                        borderRadius="xl">
                        <Row fullHeight>
                            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                                <Box display="flex" column alignX={isDesktop ? "between" : "around"} fullHeight>
                                    <Box display="flex" column alignY="center">
                                        <span className="m-0 mb-8 mb-md-11 text-center">
                                            <Text
                                                size="5"
                                                color="heading"
                                                labelKey="settings.changePhone.code.hasBeenSent"
                                            />
                                            <Text size="5" color="heading" labelKey="" bold>
                                                {mobilePhone ? ` +${mobilePhone}` : ""}
                                            </Text>
                                        </span>

                                        <Field
                                            idForm={FORM_ID}
                                            autoComplete="off"
                                            name="mobilePhoneCode"
                                            component={PinInput}
                                            hidePlaceholder
                                            maxLength={1}
                                            labelNoMarginTop
                                            showLabel={false}
                                            type="number"
                                            autoFocus={isDesktop}
                                            className="mb-10"
                                            inputLenght={INPUT_LENGTH_CONFIRMATION}
                                        />
                                        <Text
                                            color="heading"
                                            size="5"
                                            align="center"
                                            labelKey="settings.changeEmail.code.didNotReceive"
                                            className="mt-md-"
                                        />
                                        <Button
                                            href="/settings/changePhone"
                                            className="mt-3 mb-md-11"
                                            bsStyle="link"
                                            secondary
                                            bold
                                            paragraph
                                            label="settings.changeEmail.tryAgain"
                                        />
                                    </Box>

                                    <Box display="flex" column alignX="flex-end" alignY="center" className="mt-6">
                                        <Text
                                            color="text"
                                            size={isDesktop ? "7" : "6"}
                                            align="center"
                                            className="mb-3"
                                            labelKey="settings.changeEmail.itWasNotReceived"
                                        />

                                        <Box
                                            display="flex"
                                            {...(!isDesktop && { column: true })}
                                            alignX="center"
                                            alignY="center"
                                            wrap
                                            fullWidth>
                                            <span
                                                className={classNames("color-text  align-center mx-2 mb-3", {
                                                    "size-7": isDesktop,
                                                    "size-6": !isDesktop,
                                                })}>
                                                <Text labelKey="settings.changeEmail.channels.whatsapp" />
                                                <Button
                                                    href=""
                                                    className="btn-no-min-height p-2"
                                                    bsStyle="link"
                                                    primaryLink
                                                    xs
                                                    label="settings.changeEmail.channels.whatsapp.link"
                                                />
                                            </span>

                                            <span
                                                className={classNames("color-text  align-center mx-2 mb-3", {
                                                    "size-7": isDesktop,
                                                    "size-6": !isDesktop,
                                                })}>
                                                {i18n.get("settings.changeEmail.channels.chat")}
                                                <Button
                                                    href=""
                                                    className="btn-no-min-height p-2"
                                                    bsStyle="link"
                                                    primaryLink
                                                    xs
                                                    label="settings.changeEmail.channels.chat.link"
                                                />
                                            </span>
                                        </Box>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                    <Box {...(!isDesktop && { className: "mt-auto" })}>
                        <Row className="justify-content-center">
                            <Col xs={12} md={8} mdOffset={2} lg={4} lgOffset={4}>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    block
                                />
                            </Col>
                        </Row>
                    </Box>
                </Box>
            </Form>
        );
    };

    render() {
        return (
            <>
                <Head title="settings.changePhoneConfirmation" backLinkTo="/settings/changePhone" />
                <MainContainer>
                    <Notification scopeToShow="changePhone" />
                    {this.confirmCodeForm()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    phoneCanBeUsedInWally: settingsSelectors.phoneCanBeUsedInWally(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mobilePhoneCode: "",
        }),
        validationSchema: () => {
            const requiredMessage = i18n.get(`${FORM_ID}.mobilePhoneCode.required`);
            return Yup.object().shape({
                mobilePhoneCode: Yup.string()
                    .required(requiredMessage)
                    .min(INPUT_LENGTH_CONFIRMATION, requiredMessage),
            });
        },
        handleSubmit: ({ mobilePhoneCode }, formikBag) => {
            formikBag.props.dispatch(settingsActions.updateMobilePhone(mobilePhoneCode, formikBag));
        },
    }),
)(ChangePhoneConfirmation);

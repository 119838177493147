/* eslint-disable */
import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, shape, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class MultiSelectField extends Component {
    mulstiSelectRef = null;

    static propTypes = {
        className: string,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        hideLabel: bool,
        hidePlaceholder: bool,
        idForm: string.isRequired,
        initialOptions: arrayOf(string),
        labelNoMarginTop: bool,
        options: arrayOf(string).isRequired,
        searchable: bool,
        withSelectedLabel: string,
        withSelectedLabelNoMoreOptions: string,
        disabled: bool.isRequired,
    };

    static defaultProps = {
        className: null,
        hideLabel: false,
        hidePlaceholder: false,
        initialOptions: null,
        labelNoMarginTop: false,
        searchable: false,
        withSelectedLabel: null,
        withSelectedLabelNoMoreOptions: null,
        disabled: false,
    };

    state = {
        isFocused: false,
        selectedOptions: [],
        valueChanged: false,
    };

    componentDidMount() {
        const { form, field, initialOptions } = this.props;

        if (initialOptions) {
            this.setState({
                selectedOptions: initialOptions,
            });
            form.setFieldValue(field.name, initialOptions);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedOptions !== this.state.selectedOptions) {
            this.mulstiSelectRef.current.setValue(this.state.selectedOptions, "select-option");
        }
    }

    setMultiselectRef = (ref) => {
        this.mulstiSelectRef = ref;
    };

    handleRemoveOption = (valueToRemove) => {
        const newSelectedOptions = this.state.selectedOptions.filter((option) => option.value !== valueToRemove);
        this.mulstiSelectRef.current.setValue(newSelectedOptions, "select-option");
    };

    handleSelectChange = (valueOptions) => {
        const { form, field } = this.props;
        let values = null;
        if (valueOptions && valueOptions.length) {
            values = valueOptions.map((option) => option.value);
        }
        this.setState({ selectedOptions: valueOptions });
        form.setFieldValue(field.name, valueOptions);
    };

    render() {
        const {
            className,
            field,
            form: { errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            labelNoMarginTop,
            options,
            searchable,
            withSelectedLabel,
            withSelectedLabelNoMoreOptions,
            disabled,
        } = this.props;
        const { isFocused, selectedOptions } = this.state;
        const hasError = errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--select", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hideLabel && (
                    <FieldLabel
                        idField={`react-select-${idForm}.${field.name}-input`}
                        labelKey={`${idForm}.${field.name}.label`}
                        labelNoMarginTop={labelNoMarginTop}
                    />
                )}

                <div className="input-group">
                    <Select
                        id={`${idForm}.${field.name}`}
                        multi
                        className={className}
                        onChange={(valueOptions) => this.handleSelectChange(valueOptions)}
                        options={options.filter((o) => !selectedOptions.map((v) => v.value).includes(o.value))}
                        placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                        searchable={searchable}
                        value={selectedOptions}
                        clearable={false}
                        setMultiSelectRefrence={this.setMultiselectRef}
                        withSelectedLabel={withSelectedLabel}
                        withSelectedLabelNoMoreOptions={withSelectedLabelNoMoreOptions}
                        disabled={disabled}
                    />
                </div>
                {selectedOptions.length > 0 && (
                    <Box display="flex" alignX="center" alignY="center" className="mt-5 px-5" gap="3" wrap fullWidth>
                        {selectedOptions.map((selectedOption) => (
                            <Box
                                display="flex"
                                alignY="center"
                                background="menu-background"
                                className="px-5"
                                borderRadius="xxl"
                                gap="3">
                                <Text color="heading-color" className="line-height-125">
                                    {selectedOption.label}
                                </Text>
                                <Box display="flex" onClick={() => this.handleRemoveOption(selectedOption.value)}>
                                    <Button
                                        image="images/cross.svg"
                                        bsStyle="only-icon"
                                        onClick={() => this.handleRemoveOption(selectedOption.value)}
                                        removeMinHeight
                                        className="heading-color"
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default MultiSelectField;

import { REGION_PAN, REGION_VE } from "constants.js";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import SelectSimpleField from "pages/forms/customForms/_customFields/SelectSimpleField";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { arrayOf, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";

const CardDeliveryAddress = (props) => {
    const { countryList, provinceList, districtList, jurisdictionList, field, form, mode } = props;
    const [provinceListFiltered, setProvinceListFiltered] = useState([]);
    const [districtListFiltered, setDistrictListFiltered] = useState([]);
    const [jurisdictionListFiltered, setJurisdictionListFiltered] = useState([]);

    useEffect(() => {
        if (!field?.value && mode === "edit") {
            form.setFieldValue(field.name, {});
        }
    }, [field?.value, mode]);

    useEffect(() => {
        const newCountry = field?.value?.country;
        if (newCountry?.id === REGION_PAN) {
            setProvinceListFiltered(provinceList);
        } else {
            setProvinceListFiltered([]);
        }
        setDistrictListFiltered([]);
        setJurisdictionListFiltered([]);
    }, [field?.value?.country]);

    useEffect(() => {
        const newProvince = field?.value?.province;
        if (newProvince) {
            const districtFiltered = districtList.filter((dist) => dist?.id?.substring(1, 4) === newProvince?.id);
            setDistrictListFiltered(districtFiltered);
            setJurisdictionListFiltered([]);
        }
    }, [field?.value?.province]);
    useEffect(() => {
        const newDistrict = field?.value?.district;
        const jurisdictionFiltered = jurisdictionList.filter((jurisdiction) =>
            newDistrict?.id.endsWith(jurisdiction?.idDistrict),
        );
        setJurisdictionListFiltered(jurisdictionFiltered);
    }, [field?.value?.district]);

    const handleChangeCountry = (event) => {
        const val = field?.value || {};

        let newValue;
        if (event.id === REGION_PAN) {
            newValue = { country: { id: event.id, label: event.label, alpha3: event.alpha3 } };
        } else {
            const province = val?.province?.id ? "" : val?.province;
            const postalCode = event.id === REGION_VE ? "" : val?.postalCode;
            const referencePoint = event.id !== REGION_VE ? "" : val?.referencePoint;
            newValue = {
                ...val,
                province,
                postalCode,
                referencePoint,
                country: { id: event.id, label: event.label, alpha3: event.alpha3 },
            };
        }

        form.setFieldValue(field.name, newValue);
        form.setErrors({ ...form?.errors, address: { ...form?.errors?.address, country: null } });
    };

    const handleChangeProvince = (event) => {
        const val = field?.value || {};
        let newValue;
        if (event.id) {
            newValue = { country: val?.country, province: { id: event.id, label: event.label } };
        } else {
            newValue = { ...val, province: event.target.value };
        }

        form.setFieldValue(field.name, newValue);
        form.setErrors({ ...form?.errors, address: { ...form?.errors?.address, province: null } });
    };

    const handleChangeDistrict = (event) => {
        const val = field?.value || {};
        const newValue = {
            country: val?.country,
            province: val?.province,
            district: { id: event.id, label: event.label },
        };
        form.setFieldValue(field.name, newValue);
        form.setErrors({ ...form?.errors, address: { ...form?.errors?.address, district: null } });
    };

    const handleChangeJurisdiction = (event) => {
        const val = field?.value || {};
        const newValue = {
            country: val?.country,
            province: val?.province,
            district: val?.district,
            jurisdiction: { id: event.id, label: event.label },
        };
        form.setFieldValue(field.name, newValue);
        form.setErrors({ ...form?.errors, address: { ...form?.errors?.address, jurisdiction: null } });
    };

    const patternValid = (pattern, val) => {
        if (pattern) {
            try {
                const regex = new RegExp(pattern);
                return regex.test(val);
            } catch (e) {
                return true;
            }
        }
        return true;
    };

    const handleChangeImput = (event) => {
        if (patternValid(event.target.pattern, event.target.value)) {
            const val = field?.value || {};
            const newValue = { ...val, [event.target.id]: event.target.value };
            form.setFieldValue(field.name, newValue);
            form.setErrors({ ...form?.errors, address: { ...form?.errors?.address, [event.target.id]: null } });
        }
    };

    const content = () => (
        <Row>
            <Col xs={6} md={6}>
                <SelectSimpleField
                    form={form}
                    field={field}
                    labelKey="forms.report.replace.card.country.label"
                    name="country"
                    placeholder={i18n.get("forms.placeholder.select")}
                    valueKey="id"
                    value={field?.value?.country?.id || ""}
                    onChange={handleChangeCountry}
                    options={countryList}
                    searchable
                />
                {form?.errors?.address?.country && form?.touched?.address && (
                    <FieldError error={form?.errors?.address?.country} />
                )}
            </Col>

            {field?.value?.country?.id === REGION_PAN ? (
                <>
                    <Col xs={6} md={6}>
                        <SelectSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.report.replace.card.province.label"
                            name="province"
                            placeholder={i18n.get("forms.placeholder.select")}
                            valueKey="id"
                            value={field?.value?.province?.id || ""}
                            onChange={handleChangeProvince}
                            options={provinceListFiltered}
                        />
                        {form?.errors?.address?.province && form?.touched?.address && (
                            <FieldError error={form?.errors?.address?.province} />
                        )}
                    </Col>
                    <Col xs={6} md={6}>
                        <SelectSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.report.replace.card.district.label"
                            name="district"
                            placeholder={i18n.get("forms.placeholder.select")}
                            valueKey="id"
                            value={field?.value?.district?.id || ""}
                            onChange={handleChangeDistrict}
                            options={districtListFiltered}
                        />
                        {form?.errors?.address?.district && form?.touched?.address && (
                            <FieldError error={form?.errors?.address?.district} />
                        )}
                    </Col>
                    <Col xs={6} md={6}>
                        <SelectSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.report.replace.card.correction.label"
                            name="jurisdiction"
                            placeholder={i18n.get("forms.placeholder.select")}
                            valueKey="id"
                            value={field?.value?.jurisdiction?.id || ""}
                            onChange={handleChangeJurisdiction}
                            options={jurisdictionListFiltered}
                        />
                        {form?.errors?.address?.jurisdiction && form?.touched?.address && (
                            <FieldError error={form?.errors?.address?.jurisdiction} />
                        )}
                    </Col>
                </>
            ) : (
                <>
                    <Col xs={6} md={6}>
                        <TextSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.report.replace.card.otherProvince.label"
                            name="province"
                            placeholder=""
                            valueKey="id"
                            value={field?.value?.province || ""}
                            onChange={handleChangeProvince}
                            maxLength="35"
                            pattern="^[a-zA-Z0-9ñ@+,._ -]*$"
                        />
                        {form?.errors?.address?.province && form?.touched?.address && (
                            <FieldError error={form?.errors?.address?.province} />
                        )}
                    </Col>
                    <Col xs={12} md={12}>
                        <TextSimpleField
                            form={form}
                            field={field}
                            labelKey="forms.report.replace.card.city.label"
                            name="city"
                            placeholder=""
                            value={field?.value?.city || ""}
                            onChange={handleChangeImput}
                            maxLength="35"
                            pattern="^[a-zA-Z0-9ñ@+,._ -]*$"
                        />
                        {form?.errors?.address?.city && form?.touched?.address && (
                            <FieldError error={form?.errors?.address?.city} />
                        )}
                    </Col>
                </>
            )}
            <Col xs={6} md={6}>
                <TextSimpleField
                    form={form}
                    field={field}
                    labelKey={
                        field?.value?.country?.id === REGION_PAN
                            ? "forms.report.replace.card.street.label"
                            : "forms.report.replace.card.otherStreet.label"
                    }
                    name="street"
                    placeholder=""
                    value={field?.value?.street || ""}
                    onChange={handleChangeImput}
                    maxLength={field?.value?.country?.id === REGION_PAN ? "45" : "35"}
                    pattern="^[a-zA-Z0-9ñ@+,._ -]*$"
                />
                {form?.errors?.address?.street && form?.touched?.address && (
                    <FieldError error={form?.errors?.address?.street} />
                )}
            </Col>
            <Col xs={6} md={6}>
                <TextSimpleField
                    form={form}
                    field={field}
                    labelKey="forms.report.replace.card.residential.label"
                    name="residential"
                    placeholder=""
                    value={field?.value?.residential || ""}
                    onChange={handleChangeImput}
                    maxLength="45"
                    pattern="^[a-zA-Z0-9ñ@+,._ -]*$"
                />
                {form?.errors?.address?.residential && form?.touched?.address && (
                    <FieldError error={form?.errors?.address?.residential} />
                )}
            </Col>
            <Col xs={6} md={6}>
                <TextSimpleField
                    form={form}
                    field={field}
                    labelKey="forms.report.replace.card.apartment.label"
                    name="apartment"
                    placeholder=""
                    value={field?.value?.apartment || ""}
                    onChange={handleChangeImput}
                    maxLength="45"
                    pattern="^[a-zA-Z0-9ñ@+,._ -]*$"
                />
                {form?.errors?.address?.apartment && form?.touched?.address && (
                    <FieldError error={form?.errors?.address?.apartment} />
                )}
            </Col>
            {field?.value?.country?.id === REGION_PAN || field?.value?.country?.id === REGION_VE ? (
                <Col xs={6} md={6}>
                    <TextSimpleField
                        form={form}
                        field={field}
                        labelKey="forms.report.replace.card.referencePoint.label"
                        name="referencePoint"
                        placeholder=""
                        value={field?.value?.referencePoint || ""}
                        onChange={handleChangeImput}
                        maxLength="50"
                        pattern="^[a-zA-Z0-9ñ@+,._ -]*$"
                    />
                    {form?.errors?.address?.referencePoint && form?.touched?.address && (
                        <FieldError error={form?.errors?.address?.referencePoint} />
                    )}
                </Col>
            ) : (
                <Col xs={6} md={6}>
                    <TextSimpleField
                        form={form}
                        field={field}
                        labelKey="forms.report.replace.card.postalCode.label"
                        name="postalCode"
                        placeholder=""
                        value={field?.value?.postalCode || ""}
                        onChange={handleChangeImput}
                        maxLength="15"
                        pattern="^[a-zA-Z0-9]*$"
                    />
                    {form?.errors?.address?.postalCode && form?.touched?.address && (
                        <FieldError error={form?.errors?.address?.postalCode} />
                    )}
                </Col>
            )}
        </Row>
    );
    return (
        <Box
            display="flex"
            column
            background="white"
            className="pb-9 pt-8 pl-5 pl-lg-10 pr-5 pr-lg-10 mx-n-5"
            borderRadius="default"
            fullHeight>
            <Text color="primary" size="4" bold className="mb-9" labelKey="forms.report.replace.card.address.title" />
            {content()}
        </Box>
    );
};
CardDeliveryAddress.propTypes = {
    countryList: arrayOf(shape({})),
    provinceList: arrayOf(shape({})),
    districtList: arrayOf(shape({})),
    jurisdictionList: arrayOf(shape({})),
    field: shape({}).isRequired,
    form: shape({}).isRequired,
    mode: string,
    value: shape({}),
};
CardDeliveryAddress.defaultProps = {
    countryList: [],
    provinceList: [],
    districtList: [],
    jurisdictionList: [],
    mode: "",
    value: {},
};
export default resizableRoute(CardDeliveryAddress);

import * as i18nUtils from "util/i18n";
import moment from "moment";
import * as dateUtils from "util/date";

export const TODAY = "TODAY";
export const ONCE = "FUTURE";
export const DAY = "DAY";
export const WEEK = "WEEK";
export const MONTH = "MONTH";
export const YEAR = "YEAR";
export const CUSTOM = "CUSTOM";
export const NEVER = "UNLIMITED";
export const UP_TO = "UP_TO";
export const UNTIL = "UNTIL";
export const LAST_DAY_OF_THE_MONTH = "LAST_DAY_OF_THE_MONTH";
export const EVERY_TWO_MONTHS = "EVERY_TWO_MONTHS";
export const QUARTERLY = "QUARTERLY";
export const BIANNUAL = "BI_ANNUAL";
export const BIWEEKLY = "BI_WEEKLY";

const FIRST = "first";
const SECOND = "second";
const THRID = "third";
const FOURTH = "fourth";
const LAST = "last";

export const listRecurrencies = (allowRecurrent) => {
    let result = [
        {
            value: ONCE,
            label: i18nUtils.get(`scheduler.${ONCE.toLowerCase()}`),
        },
    ];
    if (allowRecurrent) {
        result = result.concat([
            {
                value: DAY,
                label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}`),
            },
            {
                value: WEEK,
                label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}`),
            },
            {
                value: MONTH,
                label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}`),
            },
            {
                value: YEAR,
                label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}`),
            },
            {
                value: LAST_DAY_OF_THE_MONTH,
                label: i18nUtils.get(`scheduler.${LAST_DAY_OF_THE_MONTH.toLowerCase()}`),
            },
            {
                value: EVERY_TWO_MONTHS,
                label: i18nUtils.get(`scheduler.${EVERY_TWO_MONTHS.toLowerCase()}`),
            },
            {
                value: QUARTERLY,
                label: i18nUtils.get(`scheduler.${QUARTERLY.toLowerCase()}`),
            },
            {
                value: BIANNUAL,
                label: i18nUtils.get(`scheduler.${BIANNUAL.toLowerCase()}`),
            },
            {
                value: BIWEEKLY,
                label: i18nUtils.get(`scheduler.${BIWEEKLY.toLowerCase()}`),
            },
            // {
            //     value: CUSTOM,
            //     label: i18nUtils.get(`scheduler.${CUSTOM.toLowerCase()}`),
            // },
        ]);
    }
    return result;
};

export const listEvery = () => [
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}s`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}s`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}s`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}s`),
    },
];

export const getOccurrenceWithWeekOfMonth = (weekOfMonth) => {
    switch (weekOfMonth) {
        case 1:
            return FIRST;
        case 2:
            return SECOND;
        case 3:
            return THRID;
        case 4:
            return FOURTH;
        default:
            return LAST;
    }
};

export const getMessagePay = (value) => {
    if (value) {
        if (value.selectedOption !== null && value.selectedOption !== undefined) {
            const formatDate = {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            };

            let endDate = moment(new Date()).add(1, "days");

            if (value.selectedOption === ONCE) {
                endDate = value.valueDate._d;
            } else if (value.selectedOption !== TODAY && value.selectedOption !== ONCE) {
                endDate = value.program.date._d === undefined ? value.program.date : value.program.date._d;

                if (typeof value.program.date === "string") {
                    if (value.program.date.split(".")[0] !== null && value.program.date.split(".")[0] !== undefined) {
                        endDate = new Date(value.program.date.split(".")[0]);
                    }
                }
            }

            if (value.selectedOption !== TODAY) {
                endDate = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), endDate, formatDate);
                const lapse = value.program?.lapse || "";

                let untilPlaceholder = "";
                let message = "";
                if (lapse === "UNLIMITED") {
                    const endDatePlaceholder = i18nUtils.get(`scheduler.custom.messagePay.endDate.placeholder`);
                    untilPlaceholder = i18nUtils.get(`scheduler.untilFurtherNotice.label`).toLowerCase();
                    message = i18nUtils.get(`scheduler.messagePay.${value?.selectedOption.toLowerCase()}`, null, {
                        UNTIL: untilPlaceholder,
                    });
                    message = message.replace(endDatePlaceholder, "");
                } else {
                    untilPlaceholder = i18nUtils.get(`scheduler.messagePay.until`).toLowerCase();
                    message = i18nUtils.get(`scheduler.messagePay.${value?.selectedOption.toLowerCase()}`, null, {
                        UNTIL: untilPlaceholder,
                        END_DATE: endDate,
                    });
                }

                return message;
            }
        }
    }

    return null;
};

export const getScheduleMessageStartDate = (value, titleKind) => {
    if (value) {
        const formatDate = {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        };

        let startDate = moment(new Date()).add(1, "days");

        if (value.selectedOption === ONCE) {
            startDate = value.valueDate._d;
        } else if (value.selectedOption !== TODAY && value.selectedOption !== ONCE) {
            startDate = value.program.startsOn;
            if (typeof value.program.startsOn === "string") {
                if (
                    value.program.startsOn.split(".")[0] !== null &&
                    value.program.startsOn.split(".")[0] !== undefined
                ) {
                    startDate = new Date(value.program.startsOn.split(".")[0]);
                }
            }
        }

        if (value.selectedOption !== TODAY) {
            startDate = dateUtils.DAY_MONTH_FORMAT(i18nUtils.getLang(), startDate, formatDate);
            const message = i18nUtils.get(`scheduler.messageDate.${titleKind}`, null, {
                START_DATE: startDate,
            });
            return message;
        }
    }

    return null;
};

const customFrecuencyMap = new Map([
    // Last day of the month
    // TODO: I.J.
    [
        LAST_DAY_OF_THE_MONTH,
        {
            // frecuency: DAY,
            frecuency: LAST_DAY_OF_THE_MONTH,
            frecuencyValue: "1",
        },
    ],
    // Every 2 months
    [
        EVERY_TWO_MONTHS,
        {
            // frecuency: WEEK,
            frecuency: EVERY_TWO_MONTHS,
            frecuencyValue: "2",
        },
    ],
    // Every 3 months
    [
        QUARTERLY,
        {
            // frecuency: MONTH,
            frecuency: QUARTERLY,
            frecuencyValue: "3",
        },
    ],
    // Every 6 months
    [
        BIANNUAL,
        {
            // frecuency: MONTH,
            frecuency: BIANNUAL,
            frecuencyValue: "6",
        },
    ],
    // Every 15 days
    [
        BIWEEKLY,
        {
            // frecuency: DAY,
            frecuency: BIWEEKLY,
            frecuencyValue: "15",
        },
    ],
]);

// refactored to avoid consistent-return esLint error
export const isCustom = (inputFrecuency) => (inputFrecuency ? customFrecuencyMap.has(inputFrecuency) : false);

// refactored to avoid consistent-return esLint error
export const getCustomFrecuency = (input, label) => {
    const entry = customFrecuencyMap.get(input);
    if (entry) {
        return label === "frecuency" ? entry.frecuency : entry.frecuencyValue;
    }
    return "";
};

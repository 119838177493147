import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, shape, integer, string } from "prop-types";

import { groupsSelectors, detailsSelectors } from "reducers/administration";

import Image from "pages/_components/Image";

class UserDetailsGroupList extends Component {
    static propTypes = {
        selectedGroups: arrayOf(shape({ idGroups: integer, idGroupAsString: string, name: string })).isRequired,
        adminGroupsIds: arrayOf(string).isRequired,
    };

    render() {
        const { selectedGroups, adminGroupsIds } = this.props;

        return (
            <ul className="list">
                {selectedGroups.map(({ idGroup, idGroupAsString, name }) => (
                    <li key={idGroup} className="list-item">
                        <div className="list-item-inner">
                            <div className="data-wrapper data-wrapper-flex">
                                <span className="data-desc">{name}</span>
                                {adminGroupsIds.includes(idGroupAsString) && (
                                    <Image src="images/administrator.svg" className="svg-icon svg-caret" />
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedGroups: groupsSelectors.getSelectedGroups(state),
    adminGroupsIds: detailsSelectors.getAdminGroupsIds(state),
});

export default connect(mapStateToProps)(UserDetailsGroupList);

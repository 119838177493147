import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";
import * as i18n from "util/i18n";
import * as dateUtils from "util/date";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "administration.restrictions.delete";

class RestrictionsDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
        idUser: string.isRequired,
        startTime: string.isRequired,
        endTime: string.isRequired,
        timeZone: string.isRequired,
        days: number.isRequired,
        userName: string.isRequired,
        fetching: bool.isRequired,
        setLocationCustomPath: func.isRequired,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    componentDidMount() {
        const { dispatch, idTransaction, idActivity } = this.props;
        dispatch(actions.restrictionDeleteRequest(idTransaction, idActivity));
        this.preLoadInfo();
        this.setRevelockLocation();
    }

    componentDidUpdate(prevProps) {
        const { idUser } = this.props;
        if (prevProps.idUser !== idUser) {
            this.preLoadInfo();
        }
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, match, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            dispatch(actions.deleteUserAccessRestrictions(match.params.userId, credentials, formikBag));
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    preLoadInfo() {
        const { dispatch, idUser } = this.props;

        dispatch(actions.getUserRestrictionsData(idUser));
    }

    renderDays = (bitWiseNumber) => (
        <div>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </div>
    );

    renderDetail() {
        const { userName, days, startTime, endTime, timeZone } = this.props;
        const dataToSave = {
            days,
            startTime,
            endTime,
            timeZone,
        };

        return (
            <Box component="section">
                <Box display="flex" column gap="3" className="mb-11" fullWidth>
                    {userName && (
                        <Box display="flex" alignX="between" gap="10" fullWidth>
                            <Text
                                component="label"
                                labelKey="administration.restrictions.user"
                                className="m-0"
                                color="heading-color"
                                addColon
                            />
                            <Text className="m-0" color="heading-color" align="left">
                                {userName}
                            </Text>
                        </Box>
                    )}
                    <Box display="flex" alignX="between" gap="10" fullWidth>
                        <Text
                            component="label"
                            labelKey="administration.restrictions.timeZone.label"
                            className="m-0"
                            color="heading-color"
                            addColon
                        />
                        <Text className="m-0" color="heading-color" align="left">
                            {dataToSave.timeZone}
                        </Text>
                    </Box>
                    <Box display="flex" alignX="between" gap="10" fullWidth>
                        <Text
                            component="label"
                            labelKey="administration.restrictions.restrictions.days"
                            className="m-0"
                            color="heading-color"
                            addColon
                        />
                        <Text className="m-0" color="heading-color" align="left">
                            {dataToSave.days === 127
                                ? i18n.get("administration.restrictions.allDays.label")
                                : this.renderDays(dataToSave.days)}
                        </Text>
                    </Box>
                    <Box display="flex" alignX="between" gap="10" fullWidth>
                        <Text
                            component="label"
                            labelKey="global.hour"
                            className="m-0"
                            color="heading-color"
                            addColon
                            capitalize
                        />
                        <Text align="left">
                            <Text color="heading-color" labelKey="administration.restrictions.from" />
                            <Text color="heading-color">{` ${dataToSave.startTime} `}</Text>
                            <Text color="heading-color" labelKey="administration.restrictions.to" />

                            <Text color="heading-color">{` ${dataToSave.endTime} `}</Text>
                            <Text color="heading-color" labelKey="administration.restrictions.hrs" />
                        </Text>
                    </Box>
                </Box>
            </Box>
        );
    }

    render() {
        const { credentialGroups, fetching } = this.props;
        if (!credentialGroups) {
            return null;
        }

        return (
            <>
                <Head title="administration.restrictions.delete" onBack={this.handleBack} />
                <MainContainer>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="xxl"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-8" border="bottom-border-base-color-1">
                                    <Text
                                        component="h2"
                                        labelKey="administration.restrictions.restrictions.beforeDelete.user"
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>
                                {this.renderDetail()}
                                <AdministrationFormConfirmation
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    fetching={fetching}
                                    onClickCancel={this.handleBack}
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
    userName: selectors.getUserName(state),
    fetching: selectors.getFetching(state),
    timeZones: selectors.getTimezones(state),
    idUser: match.params.userId,
    days: selectors.getDays(state),
    startTime: selectors.getStartTime(state),
    endTime: selectors.getEndTime(state),
    timeZone: selectors.getTimeZone(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(RestrictionsDeleteConfirmation));

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";
import { bool, func, node, number, shape, string } from "prop-types";
import React from "react";
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import { ReactComponent as Hide } from "styles/images/hide.svg";
import { ReactComponent as Show } from "styles/images/show.svg";
import { calculateScoreStrengthPassword } from "util/settings";

class Password extends React.Component {
    static propTypes = {
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        autoComplete: string,
        maxLength: number,
        autoFocus: bool,
        showstrength: bool,
        copyEnabled: bool,
        inputRef: shape(),
        tooltip: node,
        setFieldValue: func,
        relativeStyleError: bool,
        warningIcon: string,
    };

    static defaultProps = {
        autoComplete: "on",
        maxLength: 20,
        autoFocus: false,
        showstrength: false,
        copyEnabled: "true",
        inputRef: React.createRef(),
        tooltip: null,
        setFieldValue: () => {},
        relativeStyleError: false,
        warningIcon: "tooltip.svg",
    };

    state = {
        isVisible: false,
    };

    componentDidUpdate(prevProps, prevState) {
        const { isVisible } = this.state;
        const { inputRef } = this.props;
        if (isVisible !== prevState.isVisible && inputRef) {
            inputRef.current.focus();
        }
    }

    handleOnChange = (event) => {
        const { setFieldValue } = this.props;

        if (setFieldValue) {
            setFieldValue(`${event.target.name}`, event.target.value);
            setFieldValue(`credentials.${event.target.name}`, event.target.value);
        }

        this.setState({
            scoreStrengthPass: calculateScoreStrengthPassword(event.target.value),
        });
    };

    toggleIsVisible = () => {
        this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
    };

    revealFunction = () => {
        const { isVisible } = this.state;

        return (
            <Box display="flex" position="relative" className="px-7">
                <Button className="btn-only-icon" bsStyle="link" block={false} onClick={this.toggleIsVisible}>
                    <I18n
                        id={
                            isVisible
                                ? "login.step1.secondFactor.maskPassword.a11y"
                                : "login.step1.secondFactor.unmaskPassword.a11y"
                        }
                        componentProps={{ className: "visually-hidden" }}
                    />
                    {isVisible ? <Show className="svg-image" /> : <Hide className="svg-image" />}
                </Button>
            </Box>
        );
    };

    render() {
        const { isVisible, scoreStrengthPass } = this.state;
        const {
            autoComplete,
            showstrength,
            copyEnabled,
            tooltip,
            inputRef,
            relativeStyleError,
            warningIcon,
        } = this.props;
        return (
            <div className="form-group password-input">
                <TextField
                    {...this.props}
                    showstrength={showstrength.toString()}
                    inputRef={inputRef}
                    type={isVisible ? "text" : "password"}
                    inputFunctions={this.revealFunction()}
                    handleOnChange={this.handleOnChange}
                    autoCapitalize="none"
                    copyEnabled={copyEnabled}
                    tooltip={tooltip}
                    relativeStyleError={relativeStyleError}
                    warningIcon={warningIcon}
                    autoComplete={autoComplete}
                />
                {showstrength && <ProgressBar now={scoreStrengthPass} />}
            </div>
        );
    }
}

export default Password;

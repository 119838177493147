import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";

import { bool, func } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import Button from "pages/_components/Button";
import { routerActions } from "react-router-redux/actions";

const WallyModal = ({ onClose, show, dispatch }) => {
    const redirectToWally = () => {
        onClose();
        dispatch(routerActions.push("/sendWally"));
    };

    return (
        <div className="modal-container">
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={() => {
                    onClose();
                }}
                show={show}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Box display="flex" column fullWidth>
                        <Box display="flex" alignX="center" alignY="center" gap="3" className="pt-8" fullWidth>
                            <Box display="flex">
                                <Image
                                    src="images/wally.svg"
                                    fullWidth={false}
                                    isMobileNative
                                    height="5"
                                    wrapperWidth="8"
                                    wrapperHeight="4"
                                    className="ml-min"
                                />
                            </Box>
                            <Text
                                id="modalTitleID"
                                component="h4"
                                labelKey="wally.home.title"
                                className="m-0"
                                size="1"
                                bold
                                color="modal-background"
                            />
                        </Box>
                        <Box display="flex" fullWidth flex1>
                            <Box display="flex" column gap="7" className="pb-8 px-7" fullWidth>
                                <Text component="p" size="6" align="center" labelKey="wally.modal.description.1" />
                                <Image src="images/wallyDescription.svg" />

                                <Text component="p" size="6" align="center" labelKey="wally.modal.description.2" />

                                <Box display="flex" scrollable>
                                    <Button
                                        label="global.continue"
                                        bsStyle="primary"
                                        className="wally-color mt-4"
                                        block
                                        onClick={redirectToWally}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal.Body>
            </Modal>
        </div>
    );
};

WallyModal.propTypes = {
    onClose: func,
    show: bool,
    dispatch: func,
};

WallyModal.defaultProps = {
    onClose: () => {},
    show: false,
    dispatch: () => {},
};

export default resizableRoute(WallyModal);

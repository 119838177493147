import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ACTIVATE_REQUEST: "kuara/ACTIVATE_REQUEST",
    ACTIVATE_SUCCESS: "kuara/ACTIVATE_SUCCESS",
    ACTIVATE_FAILURE: "kuara/ACTIVATE_FAILURE",
    UPDATE_USERKUARA_REQUEST: "kuara/UPDATE_USERKUARA_REQUEST",
    DESACTIVATE_USERKUARA_REQUEST: "kuara/DESACTIVATE_USERKUARA_REQUEST",
    FAVORITE_REQUEST: "kuara/FAVORITE_REQUEST",
    FAVORITE_SUCCESS: "kuara/FAVORITE_SUCCESS",
    FAVORITE_FAILURE: "kuara/FAVORITE_FAILURE",
    SET_SELECTED_ACCOUNT: "kuara/SET_SELECTED_ACCOUNT",
    SET_SELECTED_BENEFICIARY: "kuara/SET_SELECTED_BENEFICIARY",
    READ_KUARA_USER_REQUEST: "kuara/READ_KUARA_USER_REQUEST",
    READ_KUARA_USER_SUCCESS: "kuara/READ_KUARA_USER_SUCCESS",
    READ_KUARA_USER_FAILURE: "kuara/READ_KUARA_USER_FAILURE",
    LIST_BENEFICIARIES_COMMERCE_REQUEST: "kuara/LIST_BENEFICIARIES_COMMERCE_REQUEST",
    LIST_BENEFICIARIES_COMMERCE_SUCCESS: "kuara/LIST_BENEFICIARIES_COMMERCE_SUCCESS",
    LIST_BENEFICIARIES_COMMERCE_FAILURE: "kuara/LIST_BENEFICIARIES_COMMERCE_FAILURE",
    LIST_BENEFICIARIES_PEOPLE_REQUEST: "kuara/LIST_BENEFICIARIES_PEOPLE_REQUEST",
    LIST_BENEFICIARIES_PEOPLE_SUCCESS: "kuara/LIST_BENEFICIARIES_PEOPLE_SUCCESS",
    LIST_BENEFICIARIES_PEOPLE_FAILURE: "kuara/LIST_BENEFICIARIES_PEOPLE_FAILURE",
    LIST_MOVEMENTS_REQUEST: "kuara/LIST_MOVEMENTS_REQUEST",
    LIST_MOVEMENTS_SUCCESS: "kuara/LIST_MOVEMENTS_SUCCESS",
    LIST_MOVEMENTS_FAILURE: "kuara/LIST_MOVEMENTS_FAILURE",
    LIST_MOVEMENTS_PENDING_REQUEST: "kuara/LIST_MOVEMENTS_PENDING_REQUEST",
    LIST_MOVEMENTS_PENDING_SUCCESS: "kuara/LIST_MOVEMENTS_PENDING_SUCCESS",
    LIST_MOVEMENTS_PENDING_FAILURE: "kuara/LIST_MOVEMENTS_PENDING_FAILURE",
    DELETE_MOVEMENT_PENDING_REQUEST: "kuara/DELETE_MOVEMENT_PENDING_REQUEST",
    REFRESH_PENDING_TRANSACTIONS_KUARA_QUANTITY_SUCCESS: "kuara/REFRESH_PENDING_TRANSACTIONS_KUARA_QUANTITY_SUCCESS",
    PATH_REDIRECT_TO_KUARA: "kuara/PATH_REDIRECT_TO_KUARA",
    LIST_ACCOUNTS_REQUEST: "kuara/LIST_ACCOUNTS_REQUEST",
    LIST_ACCOUNTS_SUCCESS: "kuara/LIST_ACCOUNTS_SUCCESS",
    LIST_ACCOUNTS_FAILURE: "kuara/LIST_ACCOUNTS_FAILURE",
    VALIDATE_KUARA_USER_REQUEST: "kuara/VALIDATE_KUARA_USER_REQUEST",
    VALIDATE_KUARA_USER_SUCCESS: "kuara/VALIDATE_KUARA_USER_SUCCESS",
    VALIDATE_KUARA_USER_FAILURE: "kuara/VALIDATE_KUARA_USER_FAILURE",
    SET_KUARA_FETCHING: "kuara/SET_KUARA_FETCHING",
    VALIDATE_KUARA_NUMBERS_REQUEST: "kuara/VALIDATE_KUARA_NUMBERS_REQUEST",
    VALIDATE_KUARA_NUMBERS_SUCCESS: "kuara/VALIDATE_KUARA_NUMBERS_SUCCESS",
    VALIDATE_KUARA_NUMBERS_FAILURE: "kuara/VALIDATE_KUARA_NUMBERS_FAILURE",
    SET_LIST_BENEFICIARIES_PEOPLE: "kuara/SET_LIST_BENEFICIARIES_PEOPLE",
};

export const INITIAL_STATE = {
    selectedAccount: null,
    selectedBeneficiary: null,
    activateKuara: null,
    favoriteKuara: false,
    userKuara: null,
    accountKuara: null,
    isActiveKuara: null,
    isPhoneValid: null,
    isFetching: true,
    isFetchingPeople: false,
    beneficiariesCommercePA: [],
    beneficiariesCommerceOT: [],
    beneficiariesPeople: [],
    movementsKuara: [],
    movementsPendingKuara: [],
    isFetchingMovemets: false,
    pendingTransactionsKuaraQuantity: 0,
    pathRedirectToKuara: "",
    accountsListKuara: [],
    mobilePhoneMask: null,
    countryNumber: null,
    statusAccount: null,
    numbersValid: null,
    activeOtherBank: null,
};

export default createReducer(INITIAL_STATE, {
    [types.SET_SELECTED_ACCOUNT]: (state, action) => ({
        ...state,
        selectedAccount: action.account,
        accountKuara: action.account,
    }),
    [types.SET_SELECTED_BENEFICIARY]: (state, action) => ({
        ...state,
        selectedBeneficiary: action.beneficiary,
    }),
    [types.FAVORITE_REQUEST]: (state) => ({
        ...state,
        isFetchingPeople: true,
    }),
    [types.FAVORITE_SUCCESS]: (state, action) => ({
        ...state,
        beneficiariesPeople: action.beneficiaries,
        selectedBeneficiary: action.beneficiarySelected,
        isFetchingPeople: false,
    }),
    [types.FAVORITE_FAILURE]: (state) => ({
        ...state,
        isFetchingPeople: false,
    }),
    [types.READ_KUARA_USER_REQUEST]: (state) => ({
        ...state,
        isActiveKuara: null,
        countryNumber: null,
        mobilePhoneMask: null,
        accountKuara: null,
        isFetching: true,
    }),
    [types.READ_KUARA_USER_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.READ_KUARA_USER_SUCCESS]: (state, action) => ({
        ...state,
        isActiveKuara: action.active,
        countryNumber: action.countryNumber,
        mobilePhoneMask: action.mobilePhoneMask,
        accountKuara: action.account,
        isFetching: false,
    }),

    [types.LIST_BENEFICIARIES_COMMERCE_REQUEST]: (state) => ({
        ...state,
        beneficiariesCommercePA: [],
        beneficiariesCommerceOT: [],
        isFetching: true,
    }),
    [types.LIST_BENEFICIARIES_COMMERCE_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_BENEFICIARIES_COMMERCE_SUCCESS]: (state, action) => ({
        ...state,
        beneficiariesCommercePA: action.countryCode === "PA" ? action.beneficiaries : state.beneficiariesCommercePA,
        beneficiariesCommerceOT: action.countryCode !== "PA" ? action.beneficiaries : state.beneficiariesCommerceOT,
        isFetching: false,
    }),

    [types.LIST_BENEFICIARIES_PEOPLE_REQUEST]: (state) => ({
        ...state,
        beneficiariesPeople: [],
        isFetchingPeople: true,
    }),
    [types.LIST_BENEFICIARIES_PEOPLE_FAILURE]: (state) => ({
        ...state,
        isFetchingPeople: false,
    }),
    [types.LIST_BENEFICIARIES_PEOPLE_SUCCESS]: (state, action) => ({
        ...state,
        beneficiariesPeople: action.beneficiaries,
        isFetchingPeople: false,
    }),
    [types.LIST_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        movementsKuara: [],
        isFetchingMovemets: true,
    }),
    [types.LIST_MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        movementsKuara: action.movementsKuara,
        isFetchingMovemets: false,
    }),
    [types.LIST_MOVEMENTS_FAILURE]: (state) => ({
        ...state,
        movementsKuara: [],
        isFetchingMovemets: false,
    }),
    [types.LIST_MOVEMENTS_PENDING_REQUEST]: (state) => ({
        ...state,
        movementsPendingKuara: [],
        isFetchingMovemets: true,
    }),
    [types.LIST_MOVEMENTS_PENDING_SUCCESS]: (state, action) => ({
        ...state,
        movementsPendingKuara: action.movementsPendingKuara,
        isFetchingMovemets: false,
    }),
    [types.LIST_MOVEMENTS_PENDING_FAILURE]: (state) => ({
        ...state,
        movementsPendingKuara: [],
        isFetchingMovemets: false,
    }),
    [types.REFRESH_PENDING_TRANSACTIONS_KUARA_QUANTITY_SUCCESS]: (state, action) => ({
        ...state,
        pendingTransactionsKuaraQuantity: action.pendingTransactionsKuaraQuantity,
    }),
    [types.PATH_REDIRECT_TO_KUARA]: (state, action) => ({
        ...state,
        pathRedirectToKuara: action.pathRedirectToKuara,
    }),
    [types.LIST_ACCOUNTS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LIST_ACCOUNTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        accountsListKuara: action.accounts,
    }),
    [types.LIST_ACCOUNTS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.VALIDATE_KUARA_USER_REQUEST]: (state) => ({
        ...state,
        isActiveKuara: null,
        isFetching: true,
    }),
    [types.VALIDATE_KUARA_USER_SUCCESS]: (state, action) => ({
        ...state,
        isActiveKuara: action.active,
        activeOtherBank: action.activeOtherBank,
    }),
    [types.VALIDATE_KUARA_USER_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isActiveKuara: false,
    }),
    [types.ACTIVATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.ACTIVATE_SUCCESS]: (state, action) => ({
        ...state,
        countryNumber: action.countryNumber,
        mobilePhoneMask: action.mobilePhoneMask,
        isFetching: false,
    }),
    [types.ACTIVATE_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SET_KUARA_FETCHING]: (state, action) => ({
        ...state,
        isFetching: action.fetching,
    }),
    [types.VALIDATE_KUARA_NUMBERS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        numbersValid: null,
    }),
    [types.VALIDATE_KUARA_NUMBERS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        numbersValid: action.numbersValid,
    }),
    [types.VALIDATE_KUARA_NUMBERS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SET_LIST_BENEFICIARIES_PEOPLE]: (state, action) => ({
        ...state,
        beneficiariesPeople: action.contacts,
    }),
});

export const actions = {
    activateRequest: makeActionCreator(types.ACTIVATE_REQUEST, "account", "onFinish"),
    updateRequest: makeActionCreator(types.UPDATE_USERKUARA_REQUEST, "account", "onFinish"),
    desactivateRequest: makeActionCreator(types.DESACTIVATE_USERKUARA_REQUEST, "onFinish"),
    favoriteRequest: makeActionCreator(types.FAVORITE_REQUEST, "beneficiary"),
    favoriteSuccess: makeActionCreator(types.FAVORITE_SUCCESS, "beneficiaries", "beneficiarySelected"),
    setSelectedAccount: makeActionCreator(types.SET_SELECTED_ACCOUNT, "account"),
    setSelectedBeneficiary: makeActionCreator(types.SET_SELECTED_BENEFICIARY, "beneficiary"),
    userKuaraRequest: makeActionCreator(types.READ_KUARA_USER_REQUEST),
    userKuaraFailure: makeActionCreator(types.READ_KUARA_USER_FAILURE),
    userKuaraSuccess: makeActionCreator(
        types.READ_KUARA_USER_SUCCESS,
        "active",
        "countryNumber",
        "mobilePhoneMask",
        "account",
    ),
    listBeneficiariesCommerceRequest: makeActionCreator(
        types.LIST_BENEFICIARIES_COMMERCE_REQUEST,
        "countryCode",
        "start",
        "end",
    ),
    listBeneficiariesCommerceFailure: makeActionCreator(types.LIST_BENEFICIARIES_COMMERCE_FAILURE),
    listBeneficiariesCommerceSuccess: makeActionCreator(
        types.LIST_BENEFICIARIES_COMMERCE_SUCCESS,
        "beneficiaries",
        "countryCode",
    ),
    validateNumbersRequest: makeActionCreator(types.VALIDATE_KUARA_NUMBERS_REQUEST, "phoneNumbers", "onFinish"),
    validateNumbersSuccess: makeActionCreator(types.VALIDATE_KUARA_NUMBERS_SUCCESS, "numbersValid"),
    validateNumbersFailed: makeActionCreator(types.VALIDATE_KUARA_NUMBERS_FAILURE),
    listBeneficiariesPeopleFailure: makeActionCreator(types.LIST_BENEFICIARIES_COMMERCE_FAILURE),
    listBeneficiariesPeolpeSuccess: makeActionCreator(types.LIST_BENEFICIARIES_PEOPLE_SUCCESS, "beneficiaries"),
    listMovementsRequest: makeActionCreator(types.LIST_MOVEMENTS_REQUEST, "onFinish"),
    listMovementsSuccess: makeActionCreator(types.LIST_MOVEMENTS_SUCCESS, "movementsKuara"),
    listMovementsFailure: makeActionCreator(types.LIST_MOVEMENTS_FAILURE),
    listMovementsPendingRequest: makeActionCreator(types.LIST_MOVEMENTS_PENDING_REQUEST, "onFinish"),
    listMovementsPendingSuccess: makeActionCreator(types.LIST_MOVEMENTS_PENDING_SUCCESS, "movementsPendingKuara"),
    listMovementsPendingFailure: makeActionCreator(types.LIST_MOVEMENTS_PENDING_FAILURE),
    deleteMovementPendingRequest: makeActionCreator(types.DELETE_MOVEMENT_PENDING_REQUEST, "movement", "onFinish"),
    setPathRedirectToKuara: makeActionCreator(types.PATH_REDIRECT_TO_KUARA, "pathRedirectToKuara"),
    listAccountRequest: makeActionCreator(types.LIST_ACCOUNTS_REQUEST),
    listAccountSuccess: makeActionCreator(types.LIST_ACCOUNTS_SUCCESS, "accounts"),
    listAccountFailure: makeActionCreator(types.LIST_ACCOUNTS_FAILURE),
    validateUserKuaraRequest: makeActionCreator(types.VALIDATE_KUARA_USER_REQUEST, "navigate"),
    validateUserKuaraFailure: makeActionCreator(types.VALIDATE_KUARA_USER_FAILURE),
    validateUserKuaraSuccess: makeActionCreator(types.VALIDATE_KUARA_USER_SUCCESS, "active", "activeOtherBank"),
    activateSuccess: makeActionCreator(types.ACTIVATE_SUCCESS, "mobilePhoneMask", "countryNumber", "accountNumber"),
    activateFailure: makeActionCreator(types.ACTIVATE_FAILURE),
    setFetching: makeActionCreator(types.SET_KUARA_FETCHING, "fetching"),
    setBeneficiaryPeople: makeActionCreator(types.SET_LIST_BENEFICIARIES_PEOPLE, "contacts"),
};

export const selectors = {
    getSelectedAccount: ({ kuara }) => kuara.selectedAccount,
    getSelectedBeneficiary: ({ kuara }) => kuara.selectedBeneficiary,
    getUserKuara: ({ kuara }) => kuara.userKuara,
    getAccountKuara: ({ kuara }) => kuara.accountKuara,
    getMovementsKuara: ({ kuara }) => kuara.movementsKuara,
    getMovementsPendingKuara: ({ kuara }) => kuara.movementsPendingKuara,
    getFetchingMovementsKuara: ({ kuara }) => kuara.isFetchingMovemets,
    getIsActiveKuara: ({ kuara }) => kuara.isActiveKuara,
    getIsPhoneValid: ({ kuara }) => kuara.isPhoneValid,
    getBeneficiariesCommercePA: ({ kuara }) => kuara.beneficiariesCommercePA,
    getBeneficiariesCommerceOT: ({ kuara }) => kuara.beneficiariesCommerceOT,
    getBeneficiariesPeople: ({ kuara }) => kuara.beneficiariesPeople,
    getPendingTransactionsKuaraQuantity: ({ kuara }) => kuara.pendingTransactionsKuaraQuantity,
    isFetching: ({ kuara }) => kuara.isFetching,
    isFetchingPeople: ({ kuara }) => kuara.isFetchingPeople,
    getPathRedirectToKuara: ({ kuara }) => kuara.pathRedirectToKuara,
    getAccountListKuara: ({ kuara }) => kuara.accountsListKuara,
    getCountryNumberKuara: ({ kuara }) => kuara.countryNumber,
    getMobilePhoneMaskKuara: ({ kuara }) => kuara.mobilePhoneMask,
    getNumbersValid: ({ kuara }) => kuara.numbersValid,
    getActiveOtherBank: ({ kuara }) => kuara.activeOtherBank,
};

import BoxFieldError from "pages/_components/BoxFieldError/BoxFieldError";
import React from "react";
import { selectors as notificationSelectors } from "reducers/notification";

import { connect } from "react-redux";
import { string, arrayOf, number } from "prop-types";

class BoxErrorNotification extends React.Component {
    state = {
        closed: false,
    };

    static propTypes = {
        scopes: arrayOf(string).isRequired,
        scopeToShow: string.isRequired,
        message: string,
        level: string,
        cont: number.isRequired,
    };

    static defaultProps = {
        message: null,
        level: null,
    };

    componentDidUpdate(prevProps) {
        const { cont } = this.props;
        if (cont !== prevProps.cont) {
            this.setClosed(false);
        }
    }

    setClosed = (value = true) => {
        this.setState(() => ({ closed: value }));
    };

    render() {
        const { closed } = this.state;
        const { message, level, scopes, scopeToShow } = this.props;
        if (message && scopes && scopes.indexOf(scopeToShow) !== -1 && !closed) {
            return <BoxFieldError message={message} notificationType={level} handleClickClose={this.setClosed} />;
        }
        return <></>;
    }
}

const mapStateToProps = (state) => ({
    message: notificationSelectors.getMessage(state),
    scopes: notificationSelectors.getScopes(state),
    level: notificationSelectors.getLevel(state),
    cont: notificationSelectors.getCont(state),
});

export default connect(mapStateToProps)(BoxErrorNotification);

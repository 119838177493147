import * as configUtils from "util/config";

export const invitationCodeMask = () => {
    const invitationCodeUnmaskedLength = configUtils.getInteger("backoffice.invitationCodes.unmaskedLength", 4);

    let mask = new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/);
    for (let i = 0; i < 2; i++) {
        mask = [...mask, "-", ...new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/)];
    }

    return mask;
};

export const timeCodeMask = () => [/\d/, /\d/, ":", /\d/, /\d/];

export const timeRegex = () => "^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$";

export default {};

export const creditCardFormat = (fieldValue) => {
    if (fieldValue.charAt(0) === "4" || fieldValue.charAt(0) === "5" || fieldValue.charAt(0) === "") {
        return [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
    }
    if (fieldValue.charAt(0) === "3") {
        return [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/];
    }

    // Necessary to accept the input after the first value

    return [/ /];
};

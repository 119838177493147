import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { bool, string } from "prop-types";
import React, { Component } from "react";

class Logo extends Component {
    static propTypes = {
        className: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const { className, isDesktop } = this.props;
        return (
            <>
                {isDesktop ? (
                    <Image src="images/logoCompany.svg" className={className} />
                ) : (
                    <Image src="images/icons/MbBanescoContigoLogo.svg" className={className} />
                )}

                <I18n id="global.companyName" componentProps={{ className: "visually-hidden" }} />
            </>
        );
    }
}
export default Logo;

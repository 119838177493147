import * as API from "middleware/api";

export const updateCompanyDataSendRequest = (otp) =>
    API.executeWithAccessToken("/administration.corporateData.update.step0.send", { _otp: otp });

export const updateCompanyDataStep0PreData = () =>
    API.executeWithAccessToken("/administration.corporateData.update.step0.pre");

export const updateCompanyDataStep1PreData = (exchangeToken) =>
    API.executeWithAccessToken("/administration.corporateData.update.step1.pre", { exchangeToken });

export const updateCompanyDataStep2PreData = (exchangeToken) =>
    API.executeWithAccessToken("/administration.corporateData.update.step2.pre", { exchangeToken });

export const updateCompanyDataStep3PreData = (exchangeToken) =>
    API.executeWithAccessToken("/administration.corporateData.update.step3.pre", { exchangeToken });

export const updateCompanyDataStep4PreData = (exchangeToken) =>
    API.executeWithAccessToken("/administration.corporateData.update.step4.pre", { exchangeToken });

export const saveCompanyDataStep = (step, updateCorporateData, exchangeToken) =>
    API.executeWithAccessToken(`/administration.corporateData.update.step${step}.send`, {
        updateCorporateData,
        exchangeToken,
    });

export const getCatalogListByParentCatalogName = (catalogName, parentCatalogName) =>
    API.executeWithAccessToken("/settings.catalog.list.pre", { catalogName, parentCatalogName });

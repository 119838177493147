/* eslint-disable no-nested-ternary */
import { resizableRoute } from "pages/_components/Resizable";
import classNames from "classnames";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, node, string } from "prop-types";
import React, { useState, useRef, useEffect } from "react";

const Tooltip = ({
    ariaLabel,
    children,
    className,
    classNameTooltip,
    forEllipsis,
    image,
    inText,
    inverseIconColor,
    isDesktop,
    onHover,
    position,
    text,
    title,
    tooltipInfo,
    tooltipLightSyled,
}) => {
    const [show, setShow] = useState(false);
    const [optimalPosition, setOptimalPosition] = useState(position);
    const buttonRef = useRef(null);
    const tooltipRef = useRef(null);

    const handleClickOutside = (event) => {
        if (buttonRef?.current && !buttonRef.current?.contains(event.target)) {
            setShow(false);
        }
    };

    const calculatePosition = () => {
        const buttonRect = buttonRef.current?.getBoundingClientRect();
        const tooltipRect = tooltipRef.current?.getBoundingClientRect();
        const buffer = 10;
        const space = {
            "top-left":
                buttonRect.top -
                (tooltipRect?.height + buffer) +
                (window.innerWidth - buttonRect.left - (tooltipRect?.width + buffer)),
            "top-centered": buttonRect.top - (tooltipRect?.height + buffer),
            "top-right":
                buttonRect.top - (tooltipRect?.height + buffer) + (buttonRect.right - tooltipRect?.width - buffer),
            "bottom-left":
                window.innerHeight -
                buttonRect.bottom -
                (tooltipRect?.height + buffer) +
                (window.innerWidth - buttonRect.left - (tooltipRect?.width + buffer)),
            "bottom-centered": window.innerHeight - buttonRect.bottom - (tooltipRect?.height + buffer),
            "bottom-right":
                window.innerHeight -
                buttonRect.bottom -
                (tooltipRect?.height + buffer) +
                (buttonRect.right - tooltipRect?.width - buffer),
            "left-top":
                buttonRect.left -
                (tooltipRect?.width + buffer) +
                (window.innerHeight - buttonRect.top - (tooltipRect?.height + buffer)),
            "left-centered": buttonRect.left - (tooltipRect?.width + buffer),
            "left-bottom":
                buttonRect.left - (tooltipRect?.width + buffer) + (buttonRect.bottom - tooltipRect?.height - buffer),
            "right-top":
                window.innerWidth -
                buttonRect.right -
                (tooltipRect?.width + buffer) +
                (window.innerHeight - buttonRect.top - (tooltipRect?.height + buffer)),
            "right-centered": window.innerWidth - buttonRect.right - (tooltipRect?.width + buffer),
            "right-bottom":
                window.innerWidth -
                buttonRect.right -
                (tooltipRect?.width + buffer) +
                (buttonRect.bottom - tooltipRect?.height - buffer),
        };
        const bestPosition = Object.entries(space).reduce((best, current) => (current[1] > best[1] ? current : best), [
            "bottom-centered",
            space["bottom-centered"],
        ])[0];

        setOptimalPosition(bestPosition);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        if (show && !position) {
            calculatePosition();
        }

        if (show && position) {
            setOptimalPosition(position);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [show]);

    return (
        <>
            {children !== null ? (
                forEllipsis ? (
                    <>
                        <Button
                            {...(onHover && isDesktop
                                ? {
                                      onMouseEnter: (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShow(!show);
                                      },
                                      onMouseLeave: (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShow(!show);
                                      },
                                  }
                                : {
                                      onClick: (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setShow(!show);
                                      },
                                  })}
                            onKeyDown={(e) => {
                                if (e.key === "Escape") {
                                    setShow(false);
                                }
                            }}
                            onBlur={() => setShow(false)}
                            ref={buttonRef}
                            className={classNames(
                                "tooltip-trigger-container tooltip-trigger-container-with-children ml-0",
                                className,
                                {
                                    "z-index-win": show,
                                },
                            )}>
                            {children}
                        </Button>
                        {show && (
                            <div
                                ref={tooltipRef}
                                role="tooltip"
                                className={classNames(
                                    "tooltip-text-container",
                                    classNameTooltip,
                                    `tooltip-${optimalPosition}`,
                                )}>
                                {inText ? (
                                    <Text className="tooltip-text">{inText}</Text>
                                ) : (
                                    <Text labelKey={text} className="tooltip-text" />
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        ref={tooltipRef}
                        role="tooltip"
                        className={classNames(
                            "tooltip-text-container tooltip-in-input",
                            classNameTooltip,
                            `tooltip-${optimalPosition}`,
                            { "tooltip-light-styled": tooltipLightSyled },
                        )}>
                        {children}
                    </div>
                )
            ) : (
                <>
                    <Button
                        ref={buttonRef}
                        ariaLabel={ariaLabel}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShow(!show);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Escape") {
                                setShow(false);
                            }
                        }}
                        onBlur={() => setShow(false)}
                        className={classNames("tooltip-trigger-container", className, {
                            "z-index-win": show,
                        })}>
                        {image && (
                            <Image
                                className={classNames("tooltip-img", { "tooltip-img-inverse": inverseIconColor })}
                                src={image}
                            />
                        )}
                        {title && <span className="tooltip-title">{title}</span>}
                        {show && (
                            <div
                                ref={tooltipRef}
                                role="tooltip"
                                className={classNames(
                                    "tooltip-text-container",
                                    classNameTooltip,
                                    `tooltip-${optimalPosition}`,
                                    { "tooltip-light-styled": tooltipLightSyled },
                                )}>
                                {inText ? (
                                    <Text className="tooltip-text">{inText}</Text>
                                ) : tooltipInfo ? (
                                    <>{tooltipInfo}</>
                                ) : (
                                    <Text labelKey={text} className="tooltip-text" />
                                )}
                            </div>
                        )}
                    </Button>
                </>
            )}
        </>
    );
};

Tooltip.propTypes = {
    ariaLabel: string,
    children: node,
    className: string,
    classNameTooltip: string,
    forEllipsis: bool,
    image: string,
    inText: string,
    inverseIconColor: bool,
    isDesktop: bool.isRequired,
    onHover: bool,
    position: string,
    text: string,
    title: string,
    tooltipInfo: node,
    tooltipLightSyled: bool,
};

Tooltip.defaultProps = {
    ariaLabel: "Tooltip",
    children: null,
    className: null,
    classNameTooltip: null,
    forEllipsis: false,
    image: "images/tooltip-icon.svg",
    inText: null,
    inverseIconColor: false,
    onHover: false,
    position: null,
    text: null,
    title: null,
    tooltipInfo: null,
    tooltipLightSyled: false,
};

export default resizableRoute(Tooltip);

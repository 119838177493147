import * as API from "middleware/api";

export const listLoans = () => API.executeWithAccessToken("/loans.list");

export const listStatements = (idLoan) => API.executeWithAccessToken("/loans.listStatements", { idLoan });

export const readLoan = (idLoan) => API.executeWithAccessToken("/loans.read", { idLoan });

export const downloadPayment = (idLoan, { ...filters }, fileFormat) =>
    API.executeWithAccessToken("/loans.downloadPayment", {
        idLoan,
        format: fileFormat,
        ...filters,
    });

import classNames from "classnames";
import Badge from "pages/_components/Badge";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, number, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors as wallySelectors } from "reducers/wally";

const ShortCutBtn = ({
    id,
    image,
    label,
    href,
    className,
    onClick,
    isDesktop,
    isStepZero,
    isMobile,
    inverse,
    showNotification,
    notificationCount,
    textColor,
}) => (
    <Box component="li" className="short-cut-btn-wrapper" display="inline-flex" column alignY="center">
        <Box>
            <Button
                aria-labelledby={`id-${label}`}
                id={id}
                href={href}
                image={image}
                onClick={onClick}
                className={classNames("short-cut-btn btn-only-icon", className, { inverse })}>
                {showNotification && notificationCount > 0 && <Badge count={notificationCount} circular />}
            </Button>
        </Box>

        <Box display="flex" alignY="center" alignX="center" fullWidth fullHeight>
            <Text
                id={`id-${label}`}
                className={classNames("short-cut-btn-label", { inverse })}
                labelKey={label}
                size={!isDesktop ? "6" : "7"}
                align="center"
                // eslint-disable-next-line no-nested-ternary
                color={textColor || (!isStepZero && !isDesktop ? "inverse" : isDesktop ? "heading" : "text")}
                bold={isMobile}
            />
        </Box>
    </Box>
);

ShortCutBtn.propTypes = {
    id: string,
    image: string,
    label: string,
    href: string,
    className: string,
    onClick: func,
    isDesktop: bool,
    isStepZero: bool,
    isMobile: bool.isRequired,
    inverse: bool,
    showNotification: bool,
    notificationCount: number,
    textColor: string,
};

ShortCutBtn.defaultProps = {
    id: null,
    image: null,
    label: null,
    href: null,
    onClick: null,
    className: null,
    isDesktop: false,
    isStepZero: false,
    inverse: false,
    showNotification: false,
    notificationCount: 0,
    textColor: null,
};

const mapStateToProps = (state) => ({
    notificationCount: wallySelectors.getPendingTransactionsWallyQuantity(state),
});

export default connect(mapStateToProps)(resizableRoute(ShortCutBtn));

import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import React from "react";
import { Col } from "react-bootstrap";
import { resizableRoute } from "pages/_components/Resizable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { shape } from "prop-types";
import * as i18nUtils from "util/i18n";

// TO DO
const CreditLinesDetailsTable = ({ creditLine }) => {
    let productType = creditLine?.secondaryTypeEnum ?? creditLine?.typeEnum;
    productType = productType.toLowerCase();

    const getAvailableAmountText = (currency) =>
        i18nUtils.get("creditLine.detail.available.balance", "", {
            CURRENCY: currency,
        });

    return (
        <>
            <Col xs={12} md={8} className="px-5 px-md-0 mb-5 mb-md-0 mt-5 mt-mb-0">
                <Row className="justify-content-center" gapX="3" gapY="3">
                    <Col xs={6}>
                        <Box
                            display="flex"
                            column
                            borderRadius="default"
                            className="px-5 py-4"
                            background="component-background"
                            gap="2"
                            fullHeight>
                            <Text
                                component="label"
                                className="my-0"
                                color="heading-color"
                                size="6"
                                regular
                                labelKey="creditLine.detail.productType.header.label"
                            />

                            <Text
                                color="heading-color"
                                bold
                                labelKey={`creditLine.detail.productType.label.${productType}`}
                            />
                        </Box>
                    </Col>
                    <Col xs={6}>
                        <Box
                            display="flex"
                            column
                            borderRadius="default"
                            className="px-5 py-4"
                            background="component-background"
                            gap="2"
                            fullHeight>
                            <Text component="label" className="my-0" color="heading-color" size="6" regular>
                                {getAvailableAmountText(creditLine.availableAmount?.currency)}
                            </Text>
                            <FormattedAmount
                                currency={creditLine.availableAmount?.currency}
                                quantity={creditLine.availableAmount?.quantity}
                                color="heading-color"
                                bold
                            />
                        </Box>
                    </Col>
                    <Col xs={6}>
                        <Box
                            display="flex"
                            column
                            borderRadius="default"
                            className="px-5 py-4"
                            background="component-background"
                            gap="2"
                            fullHeight>
                            <Text
                                component="label"
                                className="my-0"
                                color="heading-color"
                                size="6"
                                regular
                                labelKey="creditLine.detail.pendingAmount.balance"
                            />
                            <FormattedAmount
                                currency={creditLine.pendingAmount?.currency}
                                quantity={creditLine.pendingAmount?.quantity}
                                color="heading-color"
                                bold
                            />
                        </Box>
                    </Col>
                    <Col xs={6}>
                        <Box
                            display="flex"
                            column
                            borderRadius="default"
                            className="px-5 py-4"
                            background="component-background"
                            gap="2"
                            fullHeight>
                            <Text
                                component="label"
                                className="my-0"
                                color="heading-color"
                                size="6"
                                regular
                                labelKey="creditLine.detail.expirationDate"
                            />
                            <FormattedDate
                                date={creditLine.expirationDate}
                                anotherFormat="DD/MM/YYYY"
                                color="heading-color"
                                bold
                            />
                        </Box>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

CreditLinesDetailsTable.propTypes = {
    creditLine: shape({}).isRequired,
};

export default connect()(resizableRoute(withRouter(CreditLinesDetailsTable)));

import { call, put, select, takeLatest } from "redux-saga/effects";
import {
    calculateRate,
    getDepositTypeMetadata,
    getBeneficiaryByDocumentType,
} from "middleware/fixedTermDeposit/fixedTermDeposit.middleware";
import {
    fixedTermDepositTypes,
    selectors as fixedTermDepositSelectors,
} from "reducers/fixedTermDeposit/fixedTermDeposit.reducer";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";
import * as utilIdentification from "util/recoveryUser";

function* calculateRateData({ dataCalculateRate }) {
    const { amount, productName, term, setFieldValue, keyRateForm } = dataCalculateRate;
    const response = yield call(calculateRate, amount, productName, term);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: fixedTermDepositTypes.CALCULATE_RATE_FAILURE,
        });
        yield;
        return;
    }

    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: fixedTermDepositTypes.CALCULATE_RATE_FAILURE,
        });
        yield;
        return;
    }

    const { rate } = data?.data;
    if (!rate) {
        yield put({
            type: fixedTermDepositTypes.CALCULATE_RATE_FAILURE,
        });
        yield;
        return;
    }

    if (setFieldValue && keyRateForm) {
        setFieldValue(keyRateForm, rate.toFixed(2));
    }

    yield put({
        type: fixedTermDepositTypes.CALCULATE_RATE_SUCCESS,
        rate,
    });
}

function* setDepositTypeResult(metadata, setDepositTypeMetadata, currency, productName) {
    if (setDepositTypeMetadata) {
        setDepositTypeMetadata(metadata);
    }

    yield put({
        type: fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_SUCCESS,
        depositTypeMetadata: metadata,
        currency,
        productName,
    });
}

function* getBeneficiaryByDocumentTypeSaga({ dataGetBeneficiaryByDocumentType }) {
    const { documentCountry, documentType } = dataGetBeneficiaryByDocumentType;

    const documentNumber = utilIdentification.generateIdentificationToSend(dataGetBeneficiaryByDocumentType);

    const params = {
        documentCountry,
        documentType,
        documentNumber,
    };

    try {
        const response = yield call(getBeneficiaryByDocumentType, params);

        if (response?.data) {
            if (!response.data?.data?.isValidate) {
                yield put(
                    notificationActions.showNotification(i18nUtils.get("finances.error.message"), "error", ["form"]),
                );
            }
            yield put({
                type: fixedTermDepositTypes.GET_BENEFICIARY_BY_DOCUMENT_TYPE_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: fixedTermDepositTypes.GET_BENEFICIARY_BY_DOCUMENT_TYPE_FAILURE,
            });
        }
    } catch (error) {
        yield put({
            type: fixedTermDepositTypes.GET_BENEFICIARY_BY_DOCUMENT_TYPE_FAILURE,
        });
    }
}

function* getDepositTypeMetadataRequest({ dataDepositTypeMetadata }) {
    const { currency, productName, setDepositTypeMetadata } = dataDepositTypeMetadata;

    const depositTypeMetadataMap = yield select(fixedTermDepositSelectors.getDepositTypeMetadataMap);
    const depositTypeMetadataResult = depositTypeMetadataMap?.get(
        `${currency?.toLowerCase() || ""}-${productName?.toLowerCase() || ""}`,
    );

    if (depositTypeMetadataResult) {
        yield call(setDepositTypeResult, depositTypeMetadataResult, setDepositTypeMetadata);

        yield;
        return;
    }

    const response = yield call(getDepositTypeMetadata, currency, productName);

    if (!response?.type || !response?.data?.data) {
        yield put({
            type: fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_FAILURE,
        });
        yield;
        return;
    }

    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_FAILURE,
        });
        yield;
        return;
    }

    const { depositTypeMetadata } = data.data;
    if (!depositTypeMetadata) {
        yield put({
            type: fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_FAILURE,
        });
        yield;
        return;
    }

    yield call(setDepositTypeResult, depositTypeMetadata, setDepositTypeMetadata, currency, productName);
}

const sagas = [
    takeLatest(fixedTermDepositTypes.CALCULATE_RATE_REQUEST, calculateRateData),
    takeLatest(fixedTermDepositTypes.DEPOSITE_TYPE_METADATA_REQUEST, getDepositTypeMetadataRequest),
    takeLatest(fixedTermDepositTypes.GET_BENEFICIARY_BY_DOCUMENT_TYPE_REQUEST, getBeneficiaryByDocumentTypeSaga),
];

export default sagas;

import { PDFTextField, PDFAmountField } from "pages/forms/customForms/PDFTicket";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import TicketRenderCustom from "../TicketRenderCustom";

const ID_FORM = "product.exchange.points.record";

const ExchangePointsTicketForm = (props) => {
    const { transaction } = props;
    const { activityName: title, idActivity } = transaction;
    const cardNumber = transaction?.data?.creditCardNumber;
    const points = transaction?.data?.points;
    const moneyValue =
        typeof transaction?.data?.moneyValue === "string"
            ? Number(transaction?.data?.moneyValue)
            : transaction?.data?.moneyValue;

    const reference = transaction?.data?.reference;

    const renderFields = () => (
        <>
            <Box display="flex" column alignY="center" fullWidth className="amount-wrapper pb-5">
                <Text color="inverse" size="5" defaultValue={i18n.get("forms.product.exchange.points.record.amount")} />
                <FormattedAmount quantity={moneyValue} size="big" currency="USD" color="inverse" bold />
            </Box>
            <Box className="mt-9 mx-7">
                <FormFieldsComponents.ReadTextCustom
                    value={reference}
                    label="forms.product.exchange.points.record.reference"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={transaction?.valueDateAsString}
                    label="forms.product.exchange.points.record.date"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={i18n.get("forms.product.exchange.points.record.operationType.value")}
                    label="forms.product.exchange.points.record.operationType"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={cardNumber}
                    label="forms.product.exchange.points.record.card"
                />
                <FormFieldsComponents.ReadTextCustom
                    value={points}
                    label="forms.product.exchange.points.record.exchangedPoints"
                />
            </Box>
        </>
    );
    const renderTicket = () => (
        <>
            {/* TODO (LABEL "Canje realizado con éxito") */}
            <PDFAmountField idForm={ID_FORM} value={`USD ${moneyValue}`} label={i18n.get(`forms.${ID_FORM}.amount`)} />
            <PDFTextField idForm={ID_FORM} value={reference} label={i18n.get(`forms.${ID_FORM}.reference`)} />
            <PDFTextField
                idForm={ID_FORM}
                value={transaction?.valueDateAsString}
                label={i18n.get(`forms.${ID_FORM}.date`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={i18n.get(`forms.${ID_FORM}.operationType.value`)}
                label={i18n.get(`forms.${ID_FORM}.operationType`)}
            />
            <PDFTextField idForm={ID_FORM} value={cardNumber} label={i18n.get(`forms.${ID_FORM}.card`)} />
            <PDFTextField idForm={ID_FORM} value={points} label={i18n.get(`forms.${ID_FORM}.exchangedPoints`)} />
        </>
    );

    const formProps = {
        title,
        renderFields,
        renderTicket,
        idActivity,
    };
    return <TicketRenderCustom {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
});

ExchangePointsTicketForm.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
};
ExchangePointsTicketForm.defaultProps = {
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(ExchangePointsTicketForm));

/* eslint-disable react/no-danger */
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { replace } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as wallySelectors } from "reducers/wally";
import { actions as notificationActions } from "reducers/notification";
import { compose } from "redux";
import * as config from "util/config";
import { get as getMessage } from "util/i18n";

const EnrollmentWally = (props) => {
    const { dispatch, fetching, accounts, isPhoneValid } = props;
    const accountStatus = {
        Actived: "ACTIVA",
        Inactived: "INACTIVA",
        NoActived: "NOACTIVA",
        NoAccount: "NOACCOUNT",
        IsMancomunate: "MANCOMUNADA",
        IsPhoneValid: "PHONE",
    };

    useEffect(() => {
        dispatch(accountsActions.listAccountsForWally());
    }, []);

    const isValidAccounts = () => {
        let validateAccount = null;

        validateAccount = accounts.length === 0 ? accountStatus.NoAccount : validateAccount;

        validateAccount = accounts.some((account) => account.status === accountStatus.Inactived)
            ? accountStatus.Inactived
            : validateAccount;
        validateAccount = accounts.some((account) => account.status === accountStatus.NoActived)
            ? accountStatus.NoActived
            : validateAccount;

        validateAccount = accounts.some((account) => account.status === accountStatus.Actived)
            ? accountStatus.Actived
            : validateAccount;

        validateAccount =
            accounts.length === 1 && accounts[0].IsMancomunate ? accountStatus.IsMancomunate : validateAccount;

        validateAccount = !isPhoneValid ? accountStatus.IsPhoneValid : validateAccount;

        switch (validateAccount) {
            case accountStatus.NoAccount:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {getMessage("wally.validate.NoAccount")}
                            <Button
                                label="wally.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                            .
                        </div>,
                        "error",
                        ["wally"],
                        false,
                    ),
                );
                break;
            case accountStatus.Actived:
                dispatch(push("/enrollmentWallySelectAccount"));
                break;
            case accountStatus.Inactived:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: getMessage("wally.validate.Inactived") }} />
                            <Button
                                label="wally.validate.Inactived.link"
                                externalHref={config.get("accessBar.chat.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["wally"],
                        false,
                    ),
                );
                break;
            case accountStatus.NoActived:
                dispatch(
                    notificationActions.showNotification(
                        getMessage("wally.validate.NoActived"),
                        "error",
                        ["wally"],
                        false,
                    ),
                );
                break;
            case accountStatus.IsMancomunate:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {getMessage("wally.validate.IsMancomunate")}
                            <Button
                                label="wally.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["wally"],
                        false,
                    ),
                );
                break;

            case accountStatus.IsPhoneValid:
                dispatch(
                    notificationActions.showNotification(
                        getMessage("wally.validate.IsPhoneValid"),
                        "error",
                        ["wally"],
                        false,
                    ),
                );
                break;

            default:
                break;
        }
    };

    const handleBack = () => {
        dispatch(replace("/desktop"));
    };

    return (
        <PageLoading loading={fetching}>
            <Head wally onBack={handleBack} title="wally.header.activation" titleImage="wally-head.svg" />
            <Notification scopeToShow="wally" />
            <MainContainer className="pb-0">
                <Box display="flex" column alignX="between" fullWidth fullHeight>
                    <Box display="flex" column alignX="center" alignY="center" fullHeight className="py-10 px-10">
                        <Image src="images/wally.svg" className="image-size pt-2 mb-7" />
                        <Text labelKey="wally.welcome.title" bold size="1" align="center" color="wally" />
                    </Box>
                    <Box
                        display="flex"
                        column
                        alignX="center"
                        alignY="center"
                        background="white"
                        className="px-5 pt-7 pb-9 mx-n-5"
                        borderRadius="extreme-top">
                        <Text
                            labelKey="wally.welcome.subtitle"
                            align="center"
                            size="2"
                            color="heading"
                            bold
                            className="mb-7"
                        />
                        <Box component="ul" fullWidth>
                            <Box component="li" className="mb-6 ml-5 marker-outside">
                                <Text color="heading" size="3" labelKey="wally.welcome.content1" />
                            </Box>
                            <Box component="li" className="mb-6 ml-5 marker-outside">
                                <Text color="heading" size="3" labelKey="wally.welcome.content2" />
                            </Box>
                            <Box component="li" className="mb-10 ml-5 marker-outside">
                                <Text color="heading" size="3" labelKey="wally.welcome.content3" />
                            </Box>
                        </Box>
                        <Button
                            label="wally.welcome.button"
                            bsStyle="primary"
                            onClick={() => isValidAccounts()}
                            block
                            className="mb-10"
                        />
                    </Box>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccountsForWally(state),
    fetching: accountsSelectors.getFetchingAccountsWally(state),
    isPhoneValid: wallySelectors.getIsPhoneValid(state),
});

EnrollmentWally.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    accounts: arrayOf(shape({ length: number })),
    isPhoneValid: bool,
};

EnrollmentWally.defaultProps = { fetching: false, accounts: [], isPhoneValid: false };

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(EnrollmentWally));

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, oneOfType, shape, string, number as typeNumber } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux/actions";
// eslint-disable-next-line no-unused-vars
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as notificationActions } from "reducers/notification";
import { generatePinAssigmentData } from "util/creditCards";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import { CreditCardFiantialReliefStatus } from "../CreditCard/CreditCard";

export const CreditCardStatus = {
    Actived: "00",
    Blocked: "106",
    AdminBlocked: "112",
    Inactived: "32",
};

export const ProductionStatus = {
    Locked: "Locked",
    Active: "Active",
};

export const actionChangeStatusProduct = {
    Block: {
        actionName: "block",
        actionImage: "images/BlockProduct.svg",
        statusExpected: CreditCardStatus.Blocked,
    },
    Unlock: {
        actionName: "unlock",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: CreditCardStatus.Actived,
    },
    Active: {
        actionName: "active",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: CreditCardStatus.Actived,
    },
};

const CreditCardWidget = ({
    cardStyle,
    className,
    icon,
    id,
    isDesktop,
    path,
    isPrepaid,
    onClick,
    dispatch,
    shortLabel,
    idProduct,
    client,
    description,
    creditCardDetail,
    isFetchingList,
    activeEnvironment,
}) => {
    const [viewAvailable, setViewAvailable] = useState(false);
    const Component = path ? Link : Box;

    const handleClickAvailable = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setViewAvailable(true);
    };

    const financialReliefFlag =
        !creditCardDetail?.financialRelief || creditCardDetail?.financialRelief === CreditCardFiantialReliefStatus.N;

    return (
        <Component
            {...(path && { to: path })}
            {...(!path &&
                onClick && {
                    onClick: () => {
                        onClick();
                    },
                })}>
            <Box id={id} className={classNames("credit-card credit-card-widget mx-auto", cardStyle, className)}>
                <Box display="flex" alignY="flex-start" fullWidth flex1>
                    <Box display="flex" alignY="center" fullWidth>
                        <Box display="flex" alignY="center">
                            <Image src={`images/${icon}-creditCard.svg`} className="credit-card-logo" />
                        </Box>
                        <Box className="pr-3 d-grid max-width-105rem">
                            <Text size="small" color="inverse-color" uppercase ellipsis>
                                {description}
                            </Text>
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" alignY="flex-start" column>
                    <Text size={isDesktop ? "7" : "6"} color="inverse-color" uppercase className="mb-1">
                        {client.name}
                    </Text>
                    <Box>
                        <Text size={isDesktop ? "4" : "2"} color="inverse" bold>
                            {shortLabel}
                        </Text>
                    </Box>
                </Box>

                <Box display="flex" alignY="flex-end" alignX="between" fullWidth flex1>
                    <Box display="flex" alignX="between" alignY="flex-end" fullWidth className="min-height-10">
                        {viewAvailable ? (
                            <>
                                {!isFetchingList ? (
                                    <Box display="flex" column alignY="flex-start">
                                        <Text
                                            component="label"
                                            labelKey="creditCard.details.info.availableBalance"
                                            className="mt-0 mb-1"
                                            size="small"
                                            color="inverse-color"
                                            regular
                                        />

                                        <FormattedAmount
                                            quantity={creditCardDetail?.availableBalance}
                                            currency={creditCardDetail?.currency}
                                            className="credit-card-value text-left"
                                            size={isDesktop ? "6" : "5"}
                                            avoidMasking
                                        />
                                        {activeEnvironment.type !== "corporate" && creditCardDetail?.expirationDate && (
                                            <Box display="flex">
                                                <Text
                                                    size="small"
                                                    color="inverse-color"
                                                    addColon
                                                    labelKey="global.vto"
                                                />

                                                <FormattedDate
                                                    size="small"
                                                    color="inverse-color"
                                                    date={creditCardDetail?.expirationDate}
                                                    anotherFormat="MM/YY"
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <Button
                                        label="widgets.creditCards.available.button"
                                        bsStyle="outline"
                                        className="in-widget"
                                        loading={isFetchingList}
                                        inverse
                                    />
                                )}
                            </>
                        ) : (
                            <Button
                                label="widgets.creditCards.available.button"
                                bsStyle="outline"
                                className="in-widget"
                                onClick={handleClickAvailable}
                                inverse
                            />
                        )}
                        <Box display="flex" alignX="center" alignY="flex-end">
                            {creditCardDetail?.isCloseToExpiration && activeEnvironment.type !== "corporate" && (
                                <Box
                                    display="flex"
                                    className="px-2 py-2"
                                    background="warning-border-color"
                                    borderRadius="sm">
                                    <Text size="small" color="text-boton" bold labelKey="creditCard.close.to.expired" />
                                </Box>
                            )}
                            {creditCardDetail?.hasExpirated && (
                                <Box display="flex" className="px-2 py-2" background="error-color" borderRadius="sm">
                                    <Text
                                        size="small"
                                        color="text-inverse-color"
                                        uppercase
                                        bold
                                        labelKey="creditCard.expired"
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" alignY="flex-end">
                            <Dropdown
                                ariaLabel="global.contextMenu.a11y"
                                image="images/contextualMenu.svg"
                                buttonClass="py-0 px-3 icon-big"
                                bsStyle="only-icon"
                                pullRight
                                pullUp>
                                <Button
                                    label={
                                        isPrepaid
                                            ? "contextMenu.creditCards.widgets.options.reload.button"
                                            : "contextMenu.creditCards.widgets.options.pay.button"
                                    }
                                    className="dropdown__item-btn"
                                    bsStyle="link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (isPrepaid) {
                                            dispatch(push(`/formCustom/rechargeCreditCardLocal?creditCard=${id}`));
                                        } else {
                                            dispatch(push(`/formCustom/payCreditCardLocal?creditCard=${id}`));
                                        }
                                    }}
                                />
                                {activeEnvironment.type !== "corporate" && financialReliefFlag && (
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            const values = generatePinAssigmentData({
                                                idProduct,
                                                numberMask: creditCardDetail?.numberMask,
                                                shortLabel: creditCardDetail?.shortLabel,
                                                franchise: creditCardDetail?.franchise,
                                                expirationDate: creditCardDetail?.expirationDateMask,
                                                submitAction: creditCardActions.assignPinRequest,
                                            });

                                            if (creditCardDetail.statusCode === CreditCardStatus.Actived) {
                                                dispatch(changeStatusProductActions.modalShow(values));
                                            } else {
                                                dispatch(
                                                    notificationActions.showNotification(
                                                        i18nUtils.get("creditCards.snackbar.assignPin.disabled"),
                                                        "error",
                                                        ["desktop"],
                                                    ),
                                                );
                                            }
                                        }}
                                        label="contextMenu.creditCards.widgets.options.pin"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                        loading={!creditCardDetail?.expirationDateMask && creditCardDetail === null}
                                    />
                                )}

                                {activeEnvironment.type !== "corporate" &&
                                    creditCardDetail?.showReplaceOption &&
                                    creditCardDetail.productionStatus !== "Inactive" && (
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatch(push(`/formCustom/reportReplaceCard/${id}`));
                                            }}
                                            label="creditCard.buttons.report.replace.card"
                                            className="dropdown__item-btn"
                                            bsStyle="link"
                                        />
                                    )}

                                {activeEnvironment.type !== "corporate" && creditCardDetail?.showRenewOption && (
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            dispatch(push(`/formCustom/renewCard/${id}`));
                                        }}
                                        label="creditCard.buttons.report.renew.card"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                )}
                                {activeEnvironment.type !== "corporate" && (
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            dispatch(push(`/creditCards/financingplans/${id}`));
                                        }}
                                        label="creditCard.buttons.financing.plans"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                )}
                            </Dropdown>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Component>
    );
};

CreditCardWidget.propTypes = {
    blocked: bool,
    cardStyle: string,
    className: string,
    client: shape({}).isRequired,
    creditCardDetail: shape({}),
    description: string,
    dispatch: func.isRequired,
    expirationDateMask: string,
    franchise: string,
    icon: string,
    id: string,
    idProduct: string,
    isDesktop: bool.isRequired,
    isPrepaid: bool.isRequired,
    isSwitchVisible: bool.isRequired,
    minimumPayment: typeNumber.isRequired,
    minimumPaymentCurrency: string.isRequired,
    number: string,
    onClick: func,
    path: oneOfType([string, shape({ pathname: string })]),
    shortLabel: string,
    isFetchingList: bool,
    activeEnvironment: shape({}),
};

CreditCardWidget.defaultProps = {
    blocked: false,
    cardStyle: null,
    className: "",
    creditCardDetail: {},
    description: "",
    expirationDateMask: "",
    franchise: "",
    icon: null,
    id: null,
    idProduct: "",
    number: "",
    onClick: () => {},
    path: null,
    shortLabel: "",
    isFetchingList: false,
    activeEnvironment: { permissions: [] },
};

const mapStateToProps = (state, ownProps) => ({
    creditCardDetail: creditCardSelectors.getDetailInList(state, ownProps.idProduct),
    isFetchingList: creditCardSelectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(resizableRoute(CreditCardWidget));

import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { makeActionCreator } from "util/redux";

export const types = {
    LOAD_THEME: "theme/LOAD_THEME",
};

export const INITIAL_STATE = {
    themeOption: "",
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOAD_THEME:
            document.documentElement.setAttribute("data-theme", `default ${action.theme}`);
            return {
                ...state,
                themeOption: action.theme,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "theme",
        storage: storageSession,
        blacklist: ["showCaptcha"],
    },
    reducer,
);

export const actions = {
    changeLoadTheme: makeActionCreator(types.LOAD_THEME, "theme"),
};

export const selectors = {
    getThemeOption: (state) => state.theme.themeOption,
};

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";
import { actions, selectors as administrationUsersSelector } from "reducers/administration/users";

import TokenConfirm from "pages/administration/_components/confirmations/TokenConfirm";

const mapStateToProps = (state) => {
    const user = detailsSelectors.getUser(state);
    const userInfo = administrationUsersSelector.getUserInfo(state, user);

    return {
        user,
        userInfo,
        hasSignatureEnabled: detailsSelectors.hasSignatureEnabled(state),
        credentialGroups: detailsSelectors.getCredentialGroups(state),
        idTransaction: detailsSelectors.getIdTransaction(state),
        idActivity: detailsSelectors.getIdActivity(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    schemeName: "medium",
});

export default connect(mapStateToProps, mapDispatchToProps)(TokenConfirm);

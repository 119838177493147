import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import { string } from "prop-types";
import React from "react";
import FormattedAmount from "../../_components/FormattedAmount";

const InfoCardSimplified = ({ titleRequired, titleDate, currency, quantityRequired, quantityDate }) => (
    <Box display="flex" alignX="between" column alignY="flex-start" className="info-card simplified">
        <Box display="flex" alignX="between" alignY="center" fullWidth flex1 wrap className="mb-2 mb-md-0">
            <Text size="small" color="heading" labelKey={titleRequired} ellipsis />
            <FormattedAmount bold color="heading" currency={currency} quantity={quantityRequired} />
        </Box>
        <Box display="flex" alignX="between" alignY="center" fullWidth flex1 wrap>
            <Text size="small" color="heading" labelKey={titleDate} ellipsis />
            <FormattedAmount bold color="heading" currency={currency} quantity={quantityDate} />
        </Box>
    </Box>
);

InfoCardSimplified.propTypes = {
    titleRequired: string,
    titleDate: string,
    currency: string,
    quantityRequired: string,
    quantityDate: string,
};

InfoCardSimplified.defaultProps = {
    titleRequired: null,
    titleDate: null,
    currency: null,
    quantityRequired: null,
    quantityDate: null,
};

export default InfoCardSimplified;

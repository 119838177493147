import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import SwitchToggle from "pages/_components/SwitchToggle/SwitchToggle";
import { bool, func, oneOfType, shape, string, number as typeNumber } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import { actions as creditCardActions } from "reducers/creditCard";
import { generatePinAssigmentData } from "util/creditCards";

export const CreditCardFiantialReliefStatus = {
    Y: "Y",
    YA: "YA",
    N: "N",
};

export const CreditCardStatus = {
    Actived: "00",
    Blocked: "106",
    AdminBlocked: "112",
    Inactived: "32",
};

export const ProductionStatus = {
    Locked: "Locked",
    Active: "Active",
};

export const actionChangeStatusProduct = {
    Block: {
        actionName: "block",
        actionImage: "images/BlockProduct.svg",
        statusExpected: CreditCardStatus.Blocked,
    },
    Unlock: {
        actionName: "unlock",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: CreditCardStatus.Actived,
    },
    Active: {
        actionName: "active",
        actionImage: "images/UnlockProduct.svg",
        statusExpected: CreditCardStatus.Actived,
    },
};

const CreditCard = ({
    minimumPayment,
    minimumPaymentCurrency,
    availableBalance,
    cardStyle,
    className,
    currency,
    expiredText,
    icon,
    id,
    isExpired,
    path,
    reference,
    payButton,
    isSwitchVisible,
    isPrepaid,
    onClick,
    isFetchingPoints,
    totalPoints,
    cashBack,
    onClickLoadCashBackPoint,
    hasErrorLoadingPoints,
    dispatch,
    number,
    statusCode,
    shortLabel,
    franchise,
    productionStatus,
    idProduct,
    expirationDateMask,
    isIssuerAvaibleForPoints,
    isIssuerAvaibleForCashback,
    isCorporative,
}) => {
    const Component = path ? Link : Box;

    const handleToggle = () => {
        let action;
        action = statusCode === CreditCardStatus.Actived ? actionChangeStatusProduct.Block : action;
        action =
            statusCode === (CreditCardStatus.Blocked || CreditCardStatus.AdminBlocked)
                ? actionChangeStatusProduct.Unlock
                : action;
        action =
            statusCode === CreditCardStatus.Inactived ||
            (statusCode === CreditCardStatus.Actived && productionStatus === ProductionStatus.Locked)
                ? actionChangeStatusProduct.Active
                : action;

        const productionStatusExpected =
            action.actionName === actionChangeStatusProduct.Active.actionName
                ? ProductionStatus.Active
                : productionStatus;

        const cardBrand = `${franchise.charAt(0).toUpperCase()}${franchise.slice(1).toLowerCase()}`;

        const values = {
            productType: "creditCards",
            productData: {
                idProduct: id,
                numberMask: reference,
                statusCode,
                shortLabel,
                parametersSubmit: { idCreditCard: idProduct, cardBrand, type: franchise },
            },
            title: `creditCards.drawer.${action.actionName}.title`,
            actionName: action.actionName,
            statusExpected: action.statusExpected,
            productionStatusExpected,
            actionImage: action.actionImage,
            paramsNotification: {
                success: {
                    CARD_NUMBER: shortLabel,
                    CARD_BRAND: cardBrand,
                },
                error: { CARD_NUMBER: shortLabel },
            },
        };
        dispatch(changeStatusProductActions.modalShow(values));
    };

    return (
        <Component
            {...(path && { to: path })}
            {...(!path &&
                onClick && {
                    onClick: () => {
                        onClick();
                    },
                })}>
            <Box
                id={id}
                className={classNames("credit-card mx-auto", cardStyle, className, { "is-expired": isExpired })}>
                <Box display="flex" alignY="flex-start" fullWidth flex1>
                    <Box display="flex" alignX="between" alignY="center" fullWidth className="min-height-9">
                        <Box display="flex" fullWidth flex1 alignY="center">
                            <Image src={`images/${icon}-creditCard.svg`} className="credit-card-logo" />
                            <span className="credit-card-label">{reference}</span>
                            {isExpired && <span className="credit-card-label card-expired">{expiredText}</span>}
                        </Box>
                        {isSwitchVisible && !isCorporative && (
                            <Box display="flex" fullWidth flex1 alignX="flex-end">
                                <SwitchToggle
                                    switchIsOn={
                                        statusCode === CreditCardStatus.Actived &&
                                        productionStatus !== ProductionStatus.Locked
                                    }
                                    handleToggle={handleToggle}
                                    labelOnKey="creditCard.unblocked"
                                    labelOffKey={
                                        statusCode === CreditCardStatus.Blocked ||
                                        statusCode === CreditCardStatus.AdminBlocked
                                            ? "creditCard.blocked"
                                            : "creditCard.inactive"
                                    }
                                    textClassName="credit-card-label ml-3"
                                    id={id}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex" alignY="center" fullWidth flex1 className="min-height-9">
                    <Box display="flex" fullWidth flex1 column alignY="flex-start">
                        <I18n componentProps={{ className: "credit-card-label" }} id="creditCard.availableBalance" />
                        <FormattedAmount
                            quantity={availableBalance}
                            currency={currency}
                            className="credit-card-value"
                        />
                    </Box>
                    {!isPrepaid && isFetchingPoints && (
                        <Box display="flex" alignX="flex-end">
                            <div className="cashback-points-preloader">
                                <div>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </div>
                        </Box>
                    )}
                    {!isPrepaid && isIssuerAvaibleForPoints && !isFetchingPoints && !hasErrorLoadingPoints && (
                        <Box display="flex" fullWidth flex1 column alignY="flex-end">
                            <I18n componentProps={{ className: "credit-card-label" }} id="list.addWidget.points" />
                            <FormattedAmount
                                quantity={totalPoints}
                                noCurrency
                                className="credit-card-label"
                                noDecimalsWhenRound
                            />
                        </Box>
                    )}
                    {!isPrepaid && isIssuerAvaibleForCashback && !isFetchingPoints && !hasErrorLoadingPoints && (
                        <Box display="flex" fullWidth flex1 column alignY="flex-end">
                            <I18n componentProps={{ className: "credit-card-label" }} id="list.addWidget.cashback" />
                            <span className="credit-card-label">{cashBack}</span>
                        </Box>
                    )}
                    {!isPrepaid && !isFetchingPoints && hasErrorLoadingPoints && (
                        <Box display="flex" fullWidth flex1 column alignY="flex-end">
                            <Button
                                className="only-icon "
                                image="images/icons/retry.svg"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickLoadCashBackPoint();
                                }}
                            />
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignY="flex-end" fullWidth flex1>
                    <Box display="flex" alignX="between" alignY="center" fullWidth>
                        {!isPrepaid && (
                            <Box display="flex" fullWidth flex1 column alignY="flex-start">
                                <I18n
                                    componentProps={{ className: "credit-card-label" }}
                                    id="creditCard.minimumPayment"
                                />
                                <FormattedAmount
                                    quantity={Math.abs(minimumPayment)}
                                    currency={minimumPaymentCurrency}
                                    className="credit-card-value"
                                />
                            </Box>
                        )}
                        <Box display="flex" alignY="flex-end" className="ml-auto">
                            {payButton ? (
                                <Button
                                    label={
                                        isPrepaid
                                            ? "widgets.creditCards.prepaid.button"
                                            : "widgets.creditCards.pay.button"
                                    }
                                    bsStyle="outline"
                                    inverse
                                    small
                                    block
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (isPrepaid) {
                                            dispatch(push(`/formCustom/rechargeCreditCardLocal?creditCard=${id}`));
                                        } else {
                                            dispatch(push(`/formCustom/payCreditCardLocal?creditCard=${id}`));
                                        }
                                    }}
                                />
                            ) : (
                                <Dropdown
                                    ariaLabel="global.contextMenu.a11y"
                                    image="images/contextualMenu.svg"
                                    buttonClass="py-0 px-3 icon-big"
                                    bsStyle="only-icon"
                                    pullRight
                                    pullUp>
                                    <Button
                                        label={
                                            isPrepaid
                                                ? "contextMenu.creditCards.widgets.options.reload.button"
                                                : "contextMenu.creditCards.widgets.options.pay.button"
                                        }
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (isPrepaid) {
                                                dispatch(push(`/formCustom/rechargeCreditCardLocal?creditCard=${id}`));
                                            } else {
                                                dispatch(push(`/formCustom/payCreditCardLocal?creditCard=${id}`));
                                            }
                                        }}
                                    />
                                    {/* <Button
                                    href="/websiteunderconstruction"
                                    label="contextMenu.creditCards.widgets.options.lostCard"
                                    className="dropdown__item-btn"
                                    bsStyle="link"
                                /> */}
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            const values = generatePinAssigmentData({
                                                idProduct,
                                                numberMask: number,
                                                shortLabel,
                                                franchise,
                                                expirationDate: expirationDateMask,
                                                submitAction: creditCardActions.assignPinRequest,
                                            });

                                            dispatch(changeStatusProductActions.modalShow(values));
                                        }}
                                        label="contextMenu.creditCards.widgets.options.pin"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            dispatch(push(`/creditCards/financingplans/${id}`));
                                        }}
                                        label="creditCard.buttons.financing.plans"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                </Dropdown>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Component>
    );
};

CreditCard.propTypes = {
    dispatch: func.isRequired,
    minimumPayment: typeNumber.isRequired,
    minimumPaymentCurrency: string.isRequired,
    availableBalance: typeNumber.isRequired,
    cardStyle: string,
    className: string,
    currency: string.isRequired,
    expiredText: string,
    icon: string,
    id: string,
    blocked: bool,
    isExpired: bool,
    path: oneOfType([string, shape({ pathname: string })]),
    reference: string.isRequired,
    payButton: bool,
    isSwitchVisible: bool.isRequired,
    isPrepaid: bool.isRequired,
    onClick: func,
    totalPoints: typeNumber,
    isFetchingPoints: bool,
    cashBack: string,
    onClickLoadCashBackPoint: func,
    hasErrorLoadingPoints: bool,
    number: string,
    statusCode: string,
    shortLabel: string,
    franchise: string,
    productionStatus: string,
    idProduct: string,
    expirationDateMask: string,
    isIssuerAvaibleForPoints: bool.isRequired,
    isIssuerAvaibleForCashback: bool.isRequired,
    isCorporative: bool,
};

CreditCard.defaultProps = {
    className: "",
    number: "",
    statusCode: "",
    shortLabel: "",
    cardStyle: null,
    expiredText: null,
    icon: null,
    id: null,
    isExpired: null,
    blocked: false,
    path: null,
    payButton: false,
    onClick: () => {},
    totalPoints: null,
    isFetchingPoints: true,
    cashBack: "",
    onClickLoadCashBackPoint: () => {},
    hasErrorLoadingPoints: false,
    franchise: "",
    productionStatus: "",
    idProduct: "",
    expirationDateMask: "",
    isCorporative: false,
};

export default connect()(CreditCard);

import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push, routerActions } from "react-router-redux";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { compose } from "redux";
import { findContact, getContactDisplayName, requestPermission } from "util/contact.util";
import * as i18n from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import ListContacts from "./WallyContactComponents/ListContacts";
import { formatAccount, formatPhone, useFilterBeneficiaries } from "./hooks/WallyHooks";

const RequestWally = (props) => {
    const { dispatch, isActiveWaly, beneficiariesPeople, accountWally, history } = props;
    const [hasPermission, setHasPermission] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contactList, setContactList] = useState([]);
    const [listContactsFilter, filterContacts] = useFilterBeneficiaries(beneficiariesPeople);

    const requestPhoneNumbers = async () => {
        const responseRequest = await requestPermission();

        if (!responseRequest?.read || (responseRequest.read !== true && responseRequest.read !== "true")) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("wally.forms.transfers.contact.permission.denied"),
                    "error",
                    ["desktop"],
                    true,
                    null,
                    () => (
                        <Box alignX="center" display="flex">
                            <Button
                                label="wally.forms.transfers.contact.permission.open"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!window?.cordova?.plugins?.settings) {
                                        return;
                                    }
                                    window.cordova.plugins.settings.open(
                                        "application_details",
                                        () => {},
                                        () => {},
                                    );
                                }}
                                bsStyle="link"
                            />
                        </Box>
                    ),
                ),
            );

            dispatch(routerActions.replace("/desktop"));
            return;
        }

        const responseContactList = await findContact({
            fields: {
                phoneNumbers: true,
            },
        });
        setHasPermission(responseRequest);
        setContactList(responseContactList);
        if (!responseRequest) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isActiveWaly) {
            requestPhoneNumbers();
        } else {
            dispatch(push("/wally"));
        }
    }, []);

    useEffect(() => {
        if (contactList.length > 0 && hasPermission) {
            const arrPhone = [];
            const arrContacts = [];
            contactList.forEach((element) => {
                if (element?.phoneNumbers.length > 0) {
                    const number = formatPhone(element.phoneNumbers[0].value);
                    if (number) {
                        arrPhone.push(number);
                        arrContacts.push({ number, displayName: getContactDisplayName(element) });
                    }
                }
            });
            setLoading(true);
            dispatch(wallyActions.listBeneficiariesPeopleRequest(arrPhone, arrContacts, () => setLoading(false)));
        }
    }, [contactList]);
    const handleFavorite = (beneficiary) => {
        dispatch(wallyActions.favoriteRequest(beneficiary));
    };
    const onSelectBeneficiary = (beneficiary) => {
        dispatch(wallyActions.setSelectedBeneficiary(beneficiary));
        dispatch(push("/sendWallyRequestTransaction"));
    };

    const handleBack = () => {
        history.goBack();
    };
    return (
        <>
            <Head
                titleImage="wally-head.svg"
                subtitlePrefix={accountWally?.productAlias}
                subtitle={formatAccount(accountWally)}
                onBack={handleBack}
                title="wally.forms.requestTransfers.title"
            />
            <MainContainer showLoader={loading}>
                <Box display="flex" column fullWidth className="pt-7">
                    <Box display="flex" column alignX="center" alignY="center" className="pb-6 ">
                        <Text
                            bold
                            size="2"
                            align="center"
                            color="heading"
                            labelKey="wally.forms.requestTransfers.help"
                        />
                    </Box>
                    <Formik>
                        <Form>
                            <Box
                                display="flex"
                                background="white"
                                borderRadius="default"
                                alignY="center"
                                className="py-9 pl-5 pr-7 mb-3 mx-n-5">
                                <Field
                                    component={TextField}
                                    name="filter"
                                    type="text"
                                    placeholder={i18n.get("global.search")}
                                    showLabel={false}
                                    noLabel
                                    serarchStyle
                                    labelNoMarginTop
                                    handleOnChange={(event) =>
                                        filterContacts(event.target.value, beneficiariesPeople, [
                                            "phoneNumber",
                                            "displayName",
                                        ])
                                    }
                                />
                                <Button
                                    className="ml-3"
                                    imageMd
                                    image="images/add-contact.svg"
                                    href="/sendWallyRequestAddContact"
                                    imageColor="wally"
                                />
                            </Box>
                        </Form>
                    </Formik>

                    <Box background="white" borderRadius="default" className="mx-n-5">
                        <ListContacts
                            contacts={listContactsFilter}
                            onSelectBeneficiary={onSelectBeneficiary}
                            updateFavorite={handleFavorite}
                            noMargin={false}
                        />
                    </Box>
                </Box>
            </MainContainer>
        </>
    );
};

RequestWally.propTypes = {
    history: shape({}).isRequired,
    dispatch: func.isRequired,
    beneficiariesPeople: arrayOf(shape({})),
    accountWally: shape({}),
    isActiveWaly: bool.isRequired,
};

RequestWally.defaultProps = { beneficiariesPeople: [], accountWally: {} };
const mapStateToProps = (state) => ({
    isActiveWaly: wallySelectors.getIsActiveWally(state),
    beneficiariesPeople: wallySelectors.getBeneficiariesPeople(state),
    accountWally: wallySelectors.getAccountWally(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(RequestWally));

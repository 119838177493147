import classNames from "classnames";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import GridTable from "pages/_components/GridTable/GridTable";
import Text from "pages/_components/Text";
import TransactionItem from "pages/transactions/_components/TransactionItem";
import { arrayOf, bool, func, instanceOf, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as notificationActions } from "reducers/notification";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as i18nUtils from "util/i18n";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            filter: string,
        }),
        dispatch: func.isRequired,
        pageNumber: number,
        onlyPendings: bool,
        onlyProcessing: bool,
        pendingDispatch: bool,
        filters: shape({
            idFilter: string,
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        transactions: arrayOf(shape({})),
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetching: bool.isRequired,
        showEnvironment: bool,
        itemsAreClickeable: bool,
        ascending: bool.isRequired,
        handleOrder: func.isRequired,
        history: shape({
            push: func.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        transactions: null,
        defaultFilters: null,
        pageNumber: 1,
        onlyPendings: false,
        onlyProcessing: false,
        pendingDispatch: false,
        filters: null,
        showEnvironment: false,
        itemsAreClickeable: true,
    };

    fetchMoreTransactions = () => {
        const {
            defaultFilters,
            dispatch,
            pageNumber,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            ascending,
        } = this.props;
        let { filters } = this.props;

        filters = filters
            ? { ...filters, pageNumber, isAscending: ascending, isSorting: false }
            : { ...defaultFilters, pageNumber };

        dispatch(
            transactionsActions.loadMoreTransactionsRequest(filters, onlyPendings, onlyProcessing, pendingDispatch),
        );
    };

    onMobileItemCLick = (event, transaction) => {
        const { dispatch, isDesktop, history } = this.props;
        history.push(`/transaction/${transaction.transaction.idTransaction}`);

        if (
            transaction.transaction.idForm === SALARY_PAYMENT_ID_FORM &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactionList",
                ]),
            );
        }
    };

    handleOrderList = () => {
        const {
            defaultFilters,
            dispatch,
            pageNumber,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            ascending,
            handleOrder,
        } = this.props;
        let { filters } = this.props;

        filters = filters
            ? { ...filters, pageNumber, isAscending: !ascending, isSorting: true }
            : { ...defaultFilters, pageNumber };

        dispatch(transactionsActions.loadListRequest(filters, onlyPendings, onlyProcessing, pendingDispatch));

        handleOrder();
    };

    render() {
        const {
            transactions,
            hasMoreData,
            isDesktop,
            isTablet,
            fetching,
            showEnvironment,
            itemsAreClickeable,
            ascending,
            onlyPendings,
            onlyProcessing,
        } = this.props;

        const columnsTemplate = isDesktop
            ? `5rem ${isTablet ? "repeat(5, 1fr) 4rem" : "1fr 1fr 1fr 1fr 10.5rem minmax(1.5rem, 2rem)"}`
            : "1fr auto 1.5rem";

        const rowsTemplate = isDesktop ? "1fr" : "auto 1fr auto";
        if (!transactions) {
            return null;
        }

        const onClickHandler = (transaction) => {
            if (isDesktop) {
                return () => this.props.history.push(`/transaction/${transaction.transaction.idTransaction}`);
            }
            return (e) => this.onMobileItemCLick(e, transaction);
        };

        const list = transactions.map((transaction) => (
            <GridTable.Container
                key={transaction.transaction.idTransaction}
                className="product-data"
                padding="py-2 pl-5 pl-md-3 pr-5"
                columnsTemplate={columnsTemplate}
                rowsTemplate={rowsTemplate}
                {...(itemsAreClickeable && { onClick: onClickHandler(transaction) })}>
                <TransactionItem
                    key={`transaction-${transaction.transaction.idTransaction}`}
                    transaction={transaction}
                    isDesktop={isDesktop}
                    showEnvironment={showEnvironment}
                />
            </GridTable.Container>
        ));

        return (
            <Container className="container--layout flex-grow-1 no-scrollable">
                <Col className="col-12 ">
                    <div
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {transactions.length ? (
                            <GridTable>
                                <GridTable.Header>
                                    <GridTable.Container
                                        columnsTemplate={columnsTemplate}
                                        padding="py-2 pl-5 pl-md-3 pr-5">
                                        {isDesktop && (
                                            <GridTable.Data
                                                columnStart={1}
                                                columnWidth={1}
                                                alignX="center"
                                                alignY="center"
                                                inHeader>
                                                <Text labelKey="transactions.list.header.channel" />
                                            </GridTable.Data>
                                        )}
                                        <GridTable.Data
                                            columnStart={isDesktop ? 2 : 1}
                                            columnWidth={1}
                                            alignX="flex-start"
                                            alignY="center"
                                            inHeader>
                                            <Text labelKey="transactions.list.header.operationType" />
                                        </GridTable.Data>
                                        {showEnvironment && (
                                            <GridTable.Data columnStart={3} columnWidth={1} alignX="flex-end" inHeader>
                                                <Text labelKey="transactions.list.header.client" />
                                            </GridTable.Data>
                                        )}
                                        {isDesktop && (
                                            <>
                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 4 : 3}
                                                    columnWidth={1}
                                                    alignX="center"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.creationDate" />
                                                    <Button
                                                        imageXs
                                                        black
                                                        className={classNames("px-3", {
                                                            "rotate-full": ascending,
                                                        })}
                                                        onClick={this.handleOrderList}
                                                        image="images/arrow-down.svg"
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 5 : 4}
                                                    columnWidth={1}
                                                    alignX="center"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.receiver" />
                                                </GridTable.Data>

                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 6 : 5}
                                                    columnWidth={1}
                                                    alignX="flex-end"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.amount" />
                                                </GridTable.Data>
                                                <GridTable.Data
                                                    columnStart={showEnvironment ? 7 : 6}
                                                    columnWidth={1}
                                                    alignX="center"
                                                    inHeader>
                                                    <Text labelKey="transactions.list.header.status" />
                                                </GridTable.Data>
                                            </>
                                        )}
                                        {!isDesktop && (
                                            <GridTable.Data columnStart={2} columnWidth={1} alignX="center" inHeader>
                                                <Text labelKey="transactions.list.header.amountStatus" />
                                            </GridTable.Data>
                                        )}
                                    </GridTable.Container>
                                </GridTable.Header>

                                <GridTable.Body>
                                    {list}
                                    {hasMoreData && (
                                        <Box display="flex" fullWidth alignX="center" className="no-more-data">
                                            <Button
                                                bsStyle="link"
                                                onClick={this.fetchMoreTransactions}
                                                image="images/arrow-down.svg"
                                                label="transactions.list.filters.list.moreData"
                                                imageRight
                                                loading={fetching}
                                                className="btn-small"
                                            />
                                        </Box>
                                    )}
                                </GridTable.Body>
                            </GridTable>
                        ) : (
                            <Box display="flex" fullWidth alignX="center" className="no-more-data min-height-8rem">
                                <Text
                                    labelKey={
                                        onlyPendings || onlyProcessing
                                            ? "pending.transactions.list.none"
                                            : "transactions.list.none"
                                    }
                                    component="p"
                                    align="center"
                                    bold
                                    size="6"
                                    color="text"
                                />
                            </Box>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
});

export default withRouter(connect(mapStateToProps)(List));

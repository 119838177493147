import classNames from "classnames";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import * as utilIdentification from "util/recoveryUser";

const Document = ({
    searchable,
    form,
    field,
    value,
    reducedColumnGap,
    labelNoMarginTop,
    countries,
    documentCountryMap,
    idNumberMap,
    idNumber2Map,
    spacingInputs,
    idNumberStyle2,
    disabled,
    disabledAll,
    setDocumentUserValue,
}) => {
    const documentCountryInitial = field.value?.documentCountry || value?.documentCountry;
    const documentTypeInitial = field.value?.documentType || value?.documentType;

    const [documentTypes, setDocumentTypes] = useState([]);
    const [idNumbers, setIdNumbers] = useState([]);
    const [idNumbers2, setIdNumbers2] = useState([]);

    const getDocumentTypesForCountry = (country, countryMap) =>
        countryMap
            .filter((doc) => doc.id_country_code === country)
            .map((doc) => ({
                value: doc.id_document_type,
                label: i18n.get(`documentType.label.${doc.id_document_type}`),
            }));

    const getIdNumbers = (country, documentType, idNumberMapAux) =>
        idNumberMapAux.filter(
            (idNumber) => idNumber.id_country_code === country && idNumber.id_document_type === documentType,
        );

    const getIdNumbers2 = (country, idNumber2MapAux) => idNumber2MapAux[country]?.map((elem) => ({ value: elem }));

    useEffect(() => {
        if (documentCountryInitial) {
            setDocumentTypes(getDocumentTypesForCountry(documentCountryInitial, documentCountryMap));
        }

        if (documentCountryInitial && documentTypeInitial) {
            setIdNumbers(getIdNumbers(documentCountryInitial, documentTypeInitial, idNumberMap));
            setIdNumbers2(getIdNumbers2(documentCountryInitial, idNumber2Map));
        }
    }, [documentCountryInitial, documentTypeInitial, idNumberMap, idNumber2Map, documentCountryMap]);

    const handleCountryChange = (selectedOption) => {
        const documentTypesAux = getDocumentTypesForCountry(selectedOption?.id, documentCountryMap);

        setDocumentTypes(documentTypesAux);

        const existsType = (country, type) => {
            if (!country) {
                return false;
            }
            return documentTypesAux.some((doc) => doc.id_document_type === type);
        };

        const existsDocumentType = existsType(selectedOption?.id, field.value?.documentType || value?.documentType);

        if (selectedOption?.id) {
            form.setErrors({});
        }
        form.setFieldValue(field.name, {
            ...field.value,
            documentCountry: selectedOption?.id || "",
            documentType: existsDocumentType ? field.value?.documentType || value?.documentType : "",
            idNumber1: "",
            idNumber2: "",
            documentNumber1: "",
            documentNumber2: "",
        });

        if (setDocumentUserValue) {
            setDocumentUserValue({
                documentCountry: selectedOption?.id || "",
                documentType: existsDocumentType ? field.value?.documentType || value?.documentType : "",
                idNumber1: "",
                idNumber2: "",
                documentNumber1: "",
                documentNumber2: "",
            });
        }
    };

    const handleDocumentTypeChange = (selectedOption) => {
        const documentCountry = field.value?.documentCountry || value?.documentCountry;

        const idNumbersAux = getIdNumbers(documentCountry, selectedOption?.value, idNumberMap);
        const idNumbers2Aux = getIdNumbers2(documentCountry, idNumber2Map);

        setIdNumbers(idNumbersAux);
        setIdNumbers2(idNumbers2Aux);

        if (selectedOption?.value) {
            form.setErrors({});
        }

        form.setFieldValue(field.name, {
            ...field.value,
            documentType: selectedOption?.value || "",
            idNumber1: "",
            idNumber2: "",
            documentNumber1: "",
            documentNumber2: "",
        });

        if (setDocumentUserValue) {
            setDocumentUserValue({
                ...field.value,
                documentType: selectedOption?.value || "",
                idNumber1: "",
                idNumber2: "",
                documentNumber1: "",
                documentNumber2: "",
            });
        }
    };

    const handleIdNumber1Change = (selectedOption) => {
        if (selectedOption?.idNumber) {
            form.setErrors({});
        }
        form.setFieldValue(field.name, {
            ...field.value,
            idNumber1: selectedOption?.idNumber || "",
            documentNumber1: "",
            documentNumber2: "",
        });
    };

    const handleIdNumber2Change = (selectedOption) => {
        if (selectedOption?.value) {
            form.setErrors({});
        }
        form.setFieldValue(field.name, {
            ...field.value,
            idNumber2: selectedOption?.value || "",
        });
    };

    const handleDocumentNumber1Change = (e) => {
        if (e.target.validity.valid) {
            if (e.target.value?.length <= e.target.maxLength) {
                form.setFieldValue(field.name, {
                    ...field.value,
                    documentNumber1: e?.target?.value?.toUpperCase() || "",
                });
            }
        }
    };

    const handleDocumentNumber2Change = (e) => {
        if (e.target.validity.valid) {
            if (e.target.value?.length <= e.target.maxLength) {
                form.setFieldValue(field.name, {
                    ...field.value,
                    documentNumber2: e.target.value,
                });
            }
        }
    };

    const fieldHasError = (subfield) => {
        const { touched, errors } = form;
        return touched[field.name] && errors[field.name]?.[subfield];
    };

    const renderError = (subfield) => {
        const { errors } = form;
        if (fieldHasError(subfield)) {
            return (
                <Box className="pb-5" fullWidth>
                    <FieldError error={errors[field.name]?.[subfield]} />
                </Box>
            );
        }
        return null;
    };

    const documentCountry = field.value?.documentCountry || value?.documentCountry;
    const documentType = field.value?.documentType || value?.documentType;
    const idNumber1 = field.value?.idNumber1 || value?.idNumber1;
    const idNumber2 = field.value?.idNumber2 || value?.idNumber2;
    const documentNumber1 =
        field.value?.documentNumber1 !== undefined ? field.value?.documentNumber1 : value?.documentNumber1;

    const documentNumber2 =
        field.value?.documentNumber2 !== undefined ? field.value?.documentNumber2 : value?.documentNumber2;

    return (
        <Box className="form-group">
            <div className="document-form">
                <Row className="document-form-row p-0">
                    <Col xs={12} className={spacingInputs}>
                        <FieldLabel
                            idField={`react-select-${field.name}_documentCountry-input`}
                            labelKey="document.group.select.country.label"
                            labelNoMarginTop={labelNoMarginTop}
                        />
                        <div className="input-group">
                            <Select
                                id={`${field.name}_documentCountry`}
                                className="slideFromBottom flex-container"
                                searchable={searchable}
                                onChange={handleCountryChange}
                                clearable={false}
                                alwaysDown
                                name={`${field.name}_documentCountry`}
                                value={documentCountry}
                                options={countries}
                                labelKey="name"
                                valueKey="id"
                                placeholder={i18n.get("document.group.select.country.placeholder")}
                                disabled={disabled || disabledAll}
                            />
                        </div>
                        {renderError("documentCountry")}
                    </Col>
                    <Col xs={12} className={spacingInputs}>
                        <FieldLabel
                            idField={`react-select-${field.name}_documentType-input`}
                            labelKey={
                                documentCountry === "PA"
                                    ? "document.group.id.type.label"
                                    : "document.group.id.type.document.label"
                            }
                            labelNoMarginTop={labelNoMarginTop}
                        />
                        <div className="input-group">
                            <Select
                                id={`${field.name}_documentType`}
                                disabled={!documentCountry || disabledAll}
                                className="slideFromBottom flex-container"
                                searchable={searchable}
                                onChange={handleDocumentTypeChange}
                                clearable={false}
                                alwaysDown
                                name={`${field.name}_documentType`}
                                value={documentType}
                                options={documentTypes}
                                placeholder={i18n.get("document.group.id.type.placeholder")}
                            />
                        </div>
                        {renderError("documentType")}
                    </Col>
                </Row>
            </div>
            <div className="document-form">
                <Row
                    className={classNames("document-form-row p-0", {
                        "reduced-column-gap": reducedColumnGap,
                    })}>
                    <FieldLabel
                        idField={`${field.name}_document1`}
                        labelKey="document.group.id.number.label"
                        labelNoMarginTop={labelNoMarginTop}
                    />
                    {documentType !== "PAS" && (documentCountry === "PA" || documentCountry === "VE") && (
                        <Col xs={6} md={idNumberStyle2 ? 4 : 6}>
                            <Box className="multiple-selects">
                                <Box className="select-item select-item--first-child">
                                    <Select
                                        disabled={disabled || !documentType || disabledAll}
                                        className="slideFromBottom flex-container"
                                        searchable={searchable}
                                        onChange={handleIdNumber1Change}
                                        clearable={false}
                                        alwaysDown
                                        name={`${field.name}_idNumber1`}
                                        value={idNumber1}
                                        options={idNumbers}
                                        valueKey="idNumber"
                                        labelKey="idNumber"
                                        placeholder=""
                                    />
                                </Box>

                                {documentCountry === "PA" && (
                                    <Box className="select-item select-item--second-child">
                                        <Select
                                            disabled={disabled || !documentType || disabledAll}
                                            className="slideFromBottom flex-container"
                                            searchable={searchable}
                                            onChange={handleIdNumber2Change}
                                            clearable={false}
                                            alwaysDown
                                            name={`${field.name}_idNumber2`}
                                            value={idNumber2}
                                            options={idNumbers2}
                                            valueKey="value"
                                            labelKey="value"
                                            placeholder=""
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Col>
                    )}
                    <Col
                        className="document-form-separator input-group"
                        xs={utilIdentification.sizeImputDocument1(documentCountry, documentType, !idNumberStyle2)}
                        md={utilIdentification.sizeImputDocument1(documentCountry, documentType, idNumberStyle2)}>
                        <input
                            disabled={!documentType || disabledAll}
                            id={`${field.name}_document1`}
                            className="form-control ellipsis-input"
                            name={`${field.name}_documentNumber1`}
                            type={documentType === "PAS" ? "text" : "tel"}
                            value={documentNumber1 === undefined ? "" : documentNumber1}
                            pattern={documentType === "PAS" ? "^[0-9a-zA-Z]*$" : "^[0-9]*$"}
                            onChange={handleDocumentNumber1Change}
                            placeholder={utilIdentification.placeholderInputDocument1(
                                documentCountry,
                                documentType,
                                idNumber1,
                            )}
                            maxLength={utilIdentification.maxlengthInputDocument1(
                                documentCountry,
                                documentType,
                                idNumber1,
                            )}
                        />
                    </Col>

                    {documentCountry === "PA" && documentType === "CIP" && (
                        <Col className="document-form-separator input-group" xs={3} md={idNumberStyle2 ? 4 : 3}>
                            <input
                                disabled={!documentType || disabledAll}
                                id={`${field.name}_document1`}
                                className="form-control ellipsis-input"
                                name={`${field.name}_documentNumber2`}
                                value={documentNumber2 === undefined ? "" : documentNumber2}
                                onChange={handleDocumentNumber2Change}
                                type="tel"
                                placeholder="000000"
                                maxLength={6}
                                pattern="^[0-9]*$"
                            />
                        </Col>
                    )}
                </Row>
            </div>

            {renderError("documentNumber1") ||
                renderError("documentNumber2") ||
                renderError("idNumber1") ||
                renderError("idNumber2")}

            {documentCountry === "PA" && documentType === "CIP" && (
                <Box className="mt-3" display="flex" column>
                    <Text size="7" labelKey="document.group.example1.label" />
                    <Text size="7" labelKey="document.group.example2.label" />
                </Box>
            )}
            {documentCountry === "PA" && documentType === "CRP" && (
                <Box className="mt-3" display="flex" column>
                    <Text size="7" labelKey="document.group.example1.label" />
                </Box>
            )}
        </Box>
    );
};

Document.propTypes = {
    searchable: bool,
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    reducedColumnGap: bool,
    labelNoMarginTop: bool,
    countries: arrayOf(shape({})),
    documentCountryMap: arrayOf(shape({})),
    idNumberMap: arrayOf(shape({})),
    idNumber2Map: shape({}),
    spacingInputs: string,
    idNumberStyle2: bool,
    value: shape({}),
    disabled: bool,
    disabledAll: bool,
    setDocumentUserValue: func,
};

Document.defaultProps = {
    searchable: true,
    reducedColumnGap: false,
    labelNoMarginTop: false,
    countries: [],
    documentCountryMap: [],
    idNumberMap: [],
    idNumber2Map: {},
    spacingInputs: "mb-5",
    idNumberStyle2: false,
    value: {},
    disabled: false,
    disabledAll: false,
    setDocumentUserValue: null,
};

export default withFocus(Document);

import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import DocumentField from "pages/_components/fields/Document";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/usersInvite";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "administration.users.invite";

const UserInvite = (props) => {
    const { dispatch, fetching, countries, documentTypes, history, idNumberMap1, idNumberMap2 } = props;

    useEffect(() => {
        dispatch(actions.userInvitePre());
    }, []);

    const handleCancel = () => {
        dispatch(push("/administration/users"));
    };
    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <Notification scopeToShow="administrationUserInvite" />
            <Head title="administration.users.invite.title" onBack={handleBack} />

            <MainContainer className="main-container" showLoader={fetching}>
                <Form>
                    <Box
                        display="flex"
                        column
                        background="component-background"
                        borderRadius="default"
                        className="pt-6 pb-7 mb-8">
                        <Text
                            component="h2"
                            className="m-t mb-8"
                            labelKey="administration.users.document.subtitle"
                            size="5"
                            color="heading-color"
                            align="center"
                            bold
                        />
                        <Row>
                            <Col xs="6" xsOffset="3">
                                <Box className="mb-7">
                                    <Field
                                        countries={countries}
                                        documentCountryMap={documentTypes}
                                        idNumberMap={idNumberMap1}
                                        idNumber2Map={idNumberMap2}
                                        component={DocumentField}
                                        name="document"
                                        reducedColumnGap
                                        labelNoMarginTop
                                        spacingInputs="mb-5 mb-md-7"
                                        idNumberStyle2
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </Box>

                    <Row>
                        <Col xs={3} xsOffset={3}>
                            <Button
                                bsStyle="outline"
                                label="global.cancel"
                                loading={fetching}
                                onClick={handleCancel}
                                block
                            />
                        </Col>
                        <Col xs={3}>
                            <Button bsStyle="primary" label="global.continue" loading={fetching} type="submit" block />
                        </Col>
                    </Row>
                </Form>
            </MainContainer>
        </>
    );
};

UserInvite.propTypes = {
    dispatch: func.isRequired,
    countries: arrayOf(shape({ id: string, name: string })).isRequired,
    documentTypes: arrayOf(shape({ id_country_code: string, id_document_type: string })).isRequired,
    idNumberMap1: arrayOf(shape({})),
    idNumberMap2: shape({}),
    fetching: bool.isRequired,
    history: shape({}).isRequired,
};

UserInvite.defaultProps = {
    idNumberMap1: [],
    idNumberMap2: null,
};
const mapStateToProps = (state) => ({
    countries: selectors.getCountryList(state),
    documentTypes: selectors.getDocumentTypeList(state),
    defaultCountry: selectors.getDefaultCountry(state),
    idNumberMap1: selectors.getIdNumberMap1(state),
    idNumberMap2: selectors.getIdNumberMap2(state),
    fetching: selectors.fetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ defaultCountry }) => ({
            document: {
                documentCountry: defaultCountry,
                documentType: "",
                documentNumber1: "",
                documentNumber2: "",
                idNumber1: "",
                idNumber2: "",
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                document: Yup.object().shape({
                    documentCountry: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentCountry.empty`)),
                    documentType: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentType.empty`)),
                    documentNumber1: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentNumber.empty`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentNumber.empty`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentNumber.empty`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentNumber.empty`)),
                    }),
                }),
            }),
        handleSubmit: ({ document }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(actions.userInviteVerify(document, formikBag));
        },
    }),
)(UserInvite);

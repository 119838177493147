import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";

const DefaultModal = (props) => {
    const { isDesktop, acceptFunction, cancelFunction, text, headingText, modalShow } = props;
    return (
        <div className="modal-container">
            <Modal show={modalShow}>
                <Box className="p-7">
                    <Box display="flex" alignX="center" fullWidth>
                        <Text size="3" color="heading" align="center">
                            {headingText}
                        </Text>
                    </Box>
                    <Box display="flex" alignX="center" className="my-9">
                        <Text align="center" size="5" color="text">
                            {text}
                        </Text>
                    </Box>

                    <Box display="flex" column fullWidth>
                        {isDesktop ? (
                            <Row className="px-0">
                                <Col xs={6}>
                                    <Button label="global.cancel" bsStyle="outline" onClick={cancelFunction} block />
                                </Col>
                                <Col xs={6}>
                                    <Button label="global.accept" bsStyle="primary" onClick={acceptFunction} block />
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <Button label="global.accept" bsStyle="primary" onClick={acceptFunction} block />
                                <Button label="global.cancel" bsStyle="outline" onClick={cancelFunction} block />
                            </>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

DefaultModal.propTypes = {
    isDesktop: bool.isRequired,
    acceptFunction: func.isRequired,
    cancelFunction: func.isRequired,
    text: string.isRequired,
    modalShow: bool.isRequired,
    headingText: string.isRequired,
};

export default connect()(resizableRoute(DefaultModal));

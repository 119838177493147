import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import DefaultModal from "pages/_components/modal/DefaultModal";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { array, bool, func, oneOf, oneOfType } from "prop-types";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";
import * as i18n from "util/i18n";

import * as pushNotificationsUtils from "util/pushNotifications";

class PushNotifications extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        pushNotificationsConfiguredUserDevices: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        isMobileNative: bool.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        pushNotificationsConfiguredUserDevices: null,
        fetching: false,
    };

    state = {
        showDefaultModal: false,
        itemToDelete: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.pushNotificationsListUserDevices());
    }

    hideDefaultModal = () => {
        this.setState({ showDefaultModal: false, itemToDelete: null });
    };

    showDefaultModal = (item) => {
        this.setState({ showDefaultModal: true, itemToDelete: item });
    };

    deleteDevice = () => {
        const { itemToDelete } = this.state;
        const { dispatch } = this.props;

        dispatch(pushNotificationsActions.pushNotificationsDeleteUserDevice(itemToDelete));
        this.hideDefaultModal();
    };

    configure = (e) => {
        e.preventDefault();
        pushNotificationsUtils.showOSSettings();
    };

    registerDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.pushNotificationsRegisterUserDevice());
    };

    renderDevices = () => {
        const { pushNotificationsConfiguredUserDevices, isDesktop } = this.props;
        if (pushNotificationsConfiguredUserDevices && pushNotificationsConfiguredUserDevices.length) {
            return pushNotificationsConfiguredUserDevices.map((item, index) => (
                <Box
                    component="li"
                    display="flex"
                    alignX="between"
                    alignY="center"
                    background="white"
                    fullWidth
                    borderRadius="md"
                    className="px-5 py-2 mb-3"
                    // eslint-disable-next-line react/no-array-index-key
                    key="index">
                    <Box display="flex" flex1>
                        <Text
                            size={isDesktop ? "6" : "5"}
                            color="heading"
                            labelKey={`devices.apple.identifier.${item.model}`}
                            defaultValue={`${item.model}`}
                            ellipsis
                        />
                    </Box>
                    <Box display="flex" flex1>
                        <Text size={isDesktop ? "6" : "5"} ellipsis>
                            {`....${item.uuid.substring(item.uuid.length - 4, item.uuid.length)}`}
                        </Text>
                    </Box>
                    <Button
                        image="images/icons/trash.svg"
                        label="global.delete"
                        className="btn-only-icon"
                        id={index}
                        onClick={() => this.showDefaultModal(item.uuid)}
                        bsStyle="circle"
                    />
                </Box>
            ));
        }
        return (
            <Box
                display="flex"
                alignX="center"
                alignY="center"
                background="white"
                fullWidth
                borderRadius="md"
                className="px-5 py-5 mb-3">
                <Text
                    labelKey="settings.pushNotifications.configuredDevices.none"
                    align="center"
                    size={isDesktop ? "6" : "5"}
                    color="heading-color"
                />
            </Box>
        );
    };

    renderConfigureButton = () => {
        const { isMobileNative, pushNotificationsConfiguredUserDevices } = this.props;
        if (isMobileNative) {
            if (pushNotificationsUtils.isPushEnabled()) {
                if (pushNotificationsConfiguredUserDevices) {
                    const currentDeviceRegistered = pushNotificationsUtils.isCurrentDeviceRegistered(
                        pushNotificationsConfiguredUserDevices,
                    );
                    if (currentDeviceRegistered) {
                        const deviceUUID = window.app.getDeviceUUID();
                        return (
                            <Row>
                                <Col sm={12} className="col-12">
                                    <Button
                                        className="mb-9"
                                        label="settings.pushNotifications.device.remove"
                                        bsStyle="primary"
                                        onClick={() => this.showDefaultModal(deviceUUID)}
                                        block
                                    />
                                </Col>
                            </Row>
                        );
                    }
                    return (
                        <Row>
                            <Col sm={12}>
                                <Button
                                    className="mb-9"
                                    label="settings.pushNotifications.device.register"
                                    bsStyle="primary"
                                    onClick={this.registerDevice}
                                    block
                                />
                            </Col>
                        </Row>
                    );
                }
            } else {
                return (
                    <Row>
                        <Col sm={12}>
                            <Box display="flex" alignX="center" className="mt-12 mb-8">
                                <Text size="6" align="center" labelKey="settings.pushNotifications.warning" />
                            </Box>
                            <Button
                                className="mb-9"
                                label="settings.pushNotifications.device.configure"
                                bsStyle="primary"
                                onClick={this.configure}
                                block
                            />
                        </Col>
                    </Row>
                );
            }
        }
        return null;
    };

    getContent = () => {
        const { isDesktop } = this.props;
        return (
            <form {...(!isDesktop && { className: "full-height" })}>
                <Box display="flex" column alignX="between" fullHeight>
                    <Row className="height-auto">
                        <Col xs={12} md={10} mdOffset={1}>
                            <Disclaimer
                                className="p-3 px-5 p-md-5 mt-5 mt-md-0 mb-8 mb-md-9"
                                styled="info"
                                labelKey="settings.pushNotifications.title"
                            />
                            <Box display="flex" className="mb-5">
                                <Text
                                    size="4"
                                    bold
                                    color="heading"
                                    labelKey="settings.pushNotifications.configuredDevices"
                                />
                            </Box>
                            {this.renderDevices()}
                        </Col>
                    </Row>

                    <Row className="height-auto">
                        <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                            {this.renderConfigureButton()}
                        </Col>
                    </Row>
                </Box>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;
        const { showDefaultModal } = this.state;

        return (
            <>
                <Head title="settings.pushNotifications" backLinkTo="/settings" />
                <MainContainer showLoader={fetching}>
                    <DefaultModal
                        modalShow={showDefaultModal}
                        acceptFunction={this.deleteDevice}
                        cancelFunction={this.hideDefaultModal}
                        headingText={i18n.get("settings.pushNotifications.modal.title")}
                        text={i18n.get("settings.pushNotifications.modal.text")}
                    />
                    <Notification scopeToShow="pushNotifications" />
                    {this.getContent()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: pushNotificationsSelectors.getFetching(state),
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(resizableRoute(PushNotifications));

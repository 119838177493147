import DatePicker from "react-datepicker";
import { bool, shape } from "prop-types";

class CustomDatePicker extends DatePicker {
    static propTypes = {
        isMobile: bool.isRequired,
        viewContentRef: shape({}),
        disable: bool,
    };

    componentDidUpdate() {
        const { isMobile, viewContentRef } = this.props;
        const { open } = this.state;

        if (isMobile && viewContentRef) {
            if (open) {
                viewContentRef.style.overflowY = "hidden";
            } else {
                viewContentRef.style.overflowY = "auto";
            }
        }
    }

    deferFocusInput = () => {
        this.cancelFocusInput();
    };
}

export default CustomDatePicker;

import { createReducer, makeActionCreator } from "util/redux";

export const softTokenTypes = {
    VALIDATE_TOKEN_STATUS_REQUEST: "VALIDATE_TOKEN_STATUS_REQUEST",
    SAVE_TOKEN_REQUEST: "SAVE_TOKEN_REQUEST",
    SAVE_TOKEN_FAILURE: "SAVE_TOKEN_FAILURE",
    SAVE_TOKEN_SUCCESS: "SAVE_TOKEN_SUCCESS",
    ACTIVATE_TOKEN_REQUEST: "ACTIVATE_TOKEN_REQUEST",
    ACTIVATE_TOKEN_FAILURE: "ACTIVATE_TOKEN_FAILURE",
    ACTIVATE_TOKEN_SUCCESS: "ACTIVATE_TOKEN_SUCCESS",
    GET_STATUS_TOKEN_REQUEST: "softToken/GET_STATUS_TOKEN_REQUEST",
    GET_STATUS_TOKEN_SUCCESS: "softToken/GET_STATUS_TOKEN_SUCCESS",
    GET_STATUS_TOKEN_FAILURE: "softToken/GET_STATUS_TOKEN_FAILURE",
    MIGRATE_ENTRUST_TOKEN_PRE_REQUEST: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_REQUEST",
    MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS",
    MIGRATE_ENTRUST_TOKEN_PRE_FAILURE: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_FAILURE",
    MIGRATE_ENTRUST_TOKEN_PRE_CLEAR: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_CLEAR",
    RESEND_OTP_AUTHENTICATE_SUCCESS: "softToken/RESEND_OTP_AUTHENTICATE_SUCCESS",
    RESEND_OTP_AUTHENTICATE_REQUEST: "softToken/RESEND_OTP_AUTHENTICATE_REQUEST",
    RESEND_OTP_AUTHENTICATE_FAILURE: "softToken/RESEND_OTP_AUTHENTICATE_FAILURE",
    CLEAR_FETCHING: "softToken/CLEAR_FETCHING",
};

export const INITIAL_STATE = {
    fetching: false,
    tokenStatus: undefined,
    fetchingTokenDeviceValidation: false,
};

export const selectors = {
    isFetching: ({ softToken }) => softToken.fetching || false,
    getStatusToken: ({ softToken }) => softToken.tokenStatus,
    isFetchingTokenDeviceValidation: ({ softToken }) => softToken?.fetchingTokenDeviceValidation || false,
};

export const actions = {
    validateTokenStatusRequest: makeActionCreator(softTokenTypes.VALIDATE_TOKEN_STATUS_REQUEST),
    saveTokenRequest: makeActionCreator(softTokenTypes.SAVE_TOKEN_REQUEST, "saveRequest"),
    activateTokenRequest: makeActionCreator(softTokenTypes.ACTIVATE_TOKEN_REQUEST, "activateRequest"),
    getStatusTokenRequest: makeActionCreator(softTokenTypes.GET_STATUS_TOKEN_REQUEST),
    getStatusTokenSuccess: makeActionCreator(softTokenTypes.GET_STATUS_TOKEN_SUCCESS, "tokenStatus"),
    migrateEntrustTokenPreRequest: makeActionCreator(softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_REQUEST, "validateData"),
    migrateEntrustTokenPreClear: makeActionCreator(softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_CLEAR),
    resendOtpAuthenticateRequest: makeActionCreator(softTokenTypes.RESEND_OTP_AUTHENTICATE_REQUEST),
    clearFetching: makeActionCreator(softTokenTypes.CLEAR_FETCHING),
};

export default createReducer(INITIAL_STATE, {
    [softTokenTypes.VALIDATE_TOKEN_STATUS_REQUEST]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.SAVE_TOKEN_REQUEST]: (state) => ({ ...state, fetching: true }),
    [softTokenTypes.SAVE_TOKEN_FAILURE]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.SAVE_TOKEN_SUCCESS]: (state) => ({ ...state, fetching: false }),

    [softTokenTypes.ACTIVATE_TOKEN_REQUEST]: (state) => ({ ...state, fetching: true }),
    [softTokenTypes.ACTIVATE_TOKEN_FAILURE]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.ACTIVATE_TOKEN_SUCCESS]: (state) => ({ ...state, fetching: false }),

    [softTokenTypes.GET_STATUS_TOKEN_REQUEST]: (state) => ({ ...state, fetching: true }),
    [softTokenTypes.GET_STATUS_TOKEN_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        tokenStatus: action?.tokenStatus,
    }),
    [softTokenTypes.GET_STATUS_TOKEN_FAILURE]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_REQUEST]: (state) => ({
        ...state,
        migrateEntrustTokenPreData: undefined,
        fetchingTokenDeviceValidation: true,
    }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_FAILURE]: (state) => ({
        ...state,
        fetchingTokenDeviceValidation: false,
    }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS]: (state) => ({
        ...state,
        fetchingTokenDeviceValidation: false,
    }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_CLEAR]: (state) => ({
        ...state,
        migrateEntrustTokenPreData: undefined,
        fetchingTokenDeviceValidation: false,
    }),
    [softTokenTypes.RESEND_OTP_AUTHENTICATE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [softTokenTypes.RESEND_OTP_AUTHENTICATE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [softTokenTypes.RESEND_OTP_AUTHENTICATE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [softTokenTypes.CLEAR_FETCHING]: (state) => ({
        ...state,
        fetching: false,
    }),
});

import {
    CORPORATE_ENVIRONMENT_TYPE,
    CORPORATE_USER_ADMIN,
    CORPORATE_USER_SECONDARY,
    RETAIL_ENVIRONMENT_TYPE,
} from "constants.js";
import { store } from "../store";
import { crashLogData } from "./crashReport/crashReport.util";

export const ENT000 = "ENT000";

export const validateSerialNumber = (serialNumber) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.validateSerialNumber(
            serialNumber,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const validateActivateCode = (activateCode) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.validateActivateCode(
            activateCode,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const hasRegisteredIdentity = (key, enviromentType) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.hasRegisteredIdentity(
            key,
            enviromentType,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const registerIdentity = (serialNumber, activateCode, key, extraData = "") =>
    new Promise((resolve) => {
        window.cordova.plugins.CordovaEntrustPlugin.registerIdentity(
            serialNumber,
            activateCode,
            key,
            JSON.stringify(extraData),
            (response) => {
                resolve(response);
            },
            (error) => {
                crashLogData({ sdkError: error });
                resolve(error);
            },
        );
    });

export const generateOTP = (key, enviromentType) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.generateOTP(
            key,
            enviromentType,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const deleteIdentity = (key, enviromentType) =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.deleteIdentity(
            key,
            enviromentType,
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const getEntrustKeyList = () =>
    new Promise((resolve, reject) => {
        window.cordova.plugins.CordovaEntrustPlugin.getEntrustKeyList(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });

export const buildEntrustKey = (userToken) => {
    let userId = "";
    let enviromentType = "";
    let isAdmin = false;
    if (!store.getState().session?.user) {
        userId = userToken?.userId;
        isAdmin = userToken?.environmentType === "CORPORATE_ADMIN";
        switch (userToken?.environmentType) {
            case "CORPORATE_SECONDARY":
                enviromentType = CORPORATE_ENVIRONMENT_TYPE;
                break;
            case "CORPORATE_ADMIN":
                enviromentType = CORPORATE_ENVIRONMENT_TYPE;
                break;
            case "RETAIL":
                enviromentType = RETAIL_ENVIRONMENT_TYPE;
                break;
            default:
                break;
        }
    } else {
        userId = store.getState().session.user?.userId;
        enviromentType = store.getState().session.activeEnvironment?.type;
        isAdmin = store.getState().session?.isAdministrator;
    }

    let key = `${userId}_enviroment:${enviromentType}`;

    if (enviromentType === CORPORATE_ENVIRONMENT_TYPE) {
        const userType = isAdmin ? CORPORATE_USER_ADMIN : CORPORATE_USER_SECONDARY;
        key = `${key}_${userType}`;
    }

    return key;
};

const generateEnvType = (item) => {
    const environmentType = JSON.parse(item?.extraData || "{}")?.environmentType || "";
    return { ...item, environmentType };
};

const filterByEnvType = (entrustKeys, enviromentType) =>
    entrustKeys.filter((item) => {
        const environmentTypeRef = JSON.parse(item?.extraData || "{}")?.environmentType || "";
        return environmentTypeRef && environmentTypeRef.toUpperCase().includes(enviromentType.toUpperCase());
    });
export const getEntrustKeys = async () => {
    try {
        const entrustKeysResponse = await getEntrustKeyList();
        const { data } = JSON.parse(entrustKeysResponse);
        const entrustKeys = JSON.parse(data);
        const corporateKeys = filterByEnvType(entrustKeys, CORPORATE_ENVIRONMENT_TYPE);
        const retailKeys = filterByEnvType(entrustKeys, RETAIL_ENVIRONMENT_TYPE);

        return {
            corporateKeys: corporateKeys.map(generateEnvType),
            retailKeys: retailKeys.map(generateEnvType),
        };
    } catch (error) {
        throw new Error("failed to get normalized keys");
    }
};

export const buildEnvironmentKey = (userToken) => {
    let enviromentType = "";
    let isAdmin = false;
    let userType = "";
    if (!store.getState().session?.user) {
        isAdmin = userToken?.environmentType === "CORPORATE_ADMIN";
        switch (userToken?.environmentType) {
            case "CORPORATE_SECONDARY":
                enviromentType = CORPORATE_ENVIRONMENT_TYPE.toUpperCase();
                break;
            case "CORPORATE_ADMIN":
                enviromentType = CORPORATE_ENVIRONMENT_TYPE.toUpperCase();
                break;
            case "RETAIL":
                enviromentType = RETAIL_ENVIRONMENT_TYPE.toUpperCase();
                break;
            default:
                break;
        }

        if (enviromentType === CORPORATE_ENVIRONMENT_TYPE.toUpperCase()) {
            isAdmin = userToken?.environmentType === "CORPORATE_ADMIN";
            userType = isAdmin ? CORPORATE_USER_ADMIN : CORPORATE_USER_SECONDARY;
            enviromentType = `${enviromentType}_${userType.toUpperCase()}`;
        }
    } else {
        enviromentType = (store?.getState()?.session?.activeEnvironment?.type || "").toUpperCase();
        if (enviromentType === CORPORATE_ENVIRONMENT_TYPE.toUpperCase()) {
            isAdmin = store?.getState()?.session?.isAdministrator || false;
            userType = isAdmin ? CORPORATE_USER_ADMIN : CORPORATE_USER_SECONDARY;
            enviromentType = `${enviromentType}_${userType.toUpperCase()}`;
        }
    }
    return enviromentType;
};

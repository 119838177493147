import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";

const DetailFinancingPlans = ({ financingPlans, dispatch, isDesktop, match, creditCard }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const productId = match.params.id;
        dispatch(creditCardActions.financingPlansRequest(productId, () => setLoading(false)));
        if (!creditCard) {
            dispatch(creditCardActions.detailRequest(productId));
        }
    }, [creditCard]);

    const handleBack = () => {
        dispatch(routerActions.goBack());
    };

    const handleClickPayment = () => {
        const productId = match.params.id;
        dispatch(push(`/formCustom/financingPlan?creditCard=${productId}`));
    };

    const getColumnsTemplate = () => "1.25fr 1fr 1fr 1fr 1fr 1.25fr 0.5fr";

    const renderTableMobile = () => (
        <>
            {financingPlans.length > 0 ? (
                financingPlans.map((financingPlan, idx) => (
                    <Box
                        className="px-5 py-3"
                        {...(idx + 1 !== financingPlans.length && {
                            border: "bottom-background-border-disabled",
                        })}>
                        <Box display="flex" alignX="between" className="mb-2">
                            <Text color="secondary" bold ellipsis className="mr-5">
                                {financingPlan.description}
                            </Text>
                            <Text bold>{financingPlan.referenceNumber}</Text>
                        </Box>
                        <Box display="flex" alignX="between" className="mb-2">
                            <Text
                                size="6"
                                labelKey="creditCard.financing.plans.header.table.openingBalance"
                                uppercase
                                addColon
                            />
                            <FormattedAmount
                                size={6}
                                color="heading"
                                quantity={financingPlan.initialBalance}
                                noCurrency
                            />
                        </Box>

                        <Box display="flex" alignX="between" className="mb-2">
                            <Text
                                size="6"
                                labelKey="creditCard.financing.plans.header.table.amountInstallment"
                                uppercase
                                addColon
                            />
                            <FormattedAmount
                                size={6}
                                color="heading"
                                quantity={financingPlan.openInstallmentBalance}
                                noCurrency
                                addColon
                            />
                        </Box>
                        <Box display="flex" alignX="between" className="mb-2">
                            <Text
                                size="6"
                                labelKey="creditCard.financing.plans.header.table.currentDebt"
                                uppercase
                                addColon
                            />
                            <FormattedAmount size={6} color="heading" quantity={financingPlan.balanceDue} noCurrency />
                        </Box>
                        <Box display="flex" alignX="between" className="mb-2">
                            <Text
                                size="6"
                                labelKey="creditCard.financing.plans.header.table.paidInstallments"
                                uppercase
                                addColon
                            />
                            <Text size={6} color="heading" noCurrency>
                                {financingPlan.paidFees}
                            </Text>
                        </Box>
                        <Box display="flex" alignX="between">
                            <Text
                                size="6"
                                labelKey="creditCard.financing.plans.header.table.numberInstallments"
                                uppercase
                                addColon
                            />
                            <Text size={6} color="heading" noCurrency>
                                {financingPlan.term}
                            </Text>
                        </Box>
                    </Box>
                ))
            ) : (
                <Box display="flex" alignX="center" className="my-9">
                    <Text align="center" labelKey="creditCard.financing.plans.table.empty" />
                </Box>
            )}
        </>
    );

    const renderTableDesktop = () => (
        <GridTable>
            <GridTable.Header>
                <GridTable.Container
                    columnsTemplate={getColumnsTemplate()}
                    className={isDesktop ? "gapX-9" : "gapX-3"}
                    padding="py-2 px-5 px-md-5">
                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.description" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={2} alignX="center" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.reference" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={3} alignX="flex-end" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.openingBalance" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={4} alignX="flex-end" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.amountInstallment" />
                    </GridTable.Data>
                    <GridTable.Data columnStart={5} alignX="flex-end" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.currentDebt" />
                    </GridTable.Data>
                    <GridTable.Data columnStart={6} alignX="center" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.paidInstallments" />
                    </GridTable.Data>

                    <GridTable.Data columnStart={7} alignX="flex-end" inHeader>
                        <Text labelKey="creditCard.financing.plans.header.table.numberInstallments" />
                    </GridTable.Data>
                </GridTable.Container>
            </GridTable.Header>
            {financingPlans?.length > 0 ? (
                <GridTable.Body>
                    {financingPlans.map((financingPlan) => (
                        <GridTable.Container
                            className="product-data px-5"
                            columnsTemplate={getColumnsTemplate()}
                            rows={1}
                            key={financingPlan.referenceNumber}>
                            <GridTable.Data columnStart={1} alignX="flex-start">
                                <Text
                                    uppercase
                                    {...(!isDesktop && {
                                        color: "secondary",
                                        bold: true,
                                    })}
                                    ellipsis>
                                    {financingPlan.description}
                                </Text>
                            </GridTable.Data>

                            <GridTable.Data columnStart={2} alignX="center">
                                <Text>{financingPlan.referenceNumber}</Text>
                            </GridTable.Data>
                            <GridTable.Data columnStart={3} alignX="flex-end">
                                <FormattedAmount
                                    size={6}
                                    color="text"
                                    quantity={financingPlan.initialBalance}
                                    noCurrency
                                />
                            </GridTable.Data>
                            <GridTable.Data columnStart={4} alignX="flex-end">
                                <FormattedAmount
                                    size={6}
                                    color="text"
                                    quantity={financingPlan.openInstallmentBalance}
                                    noCurrency
                                />
                            </GridTable.Data>
                            <GridTable.Data columnStart={5} alignX="flex-end">
                                <FormattedAmount size={6} color="text" quantity={financingPlan.balanceDue} noCurrency />
                            </GridTable.Data>
                            <GridTable.Data columnStart={6} alignX="center">
                                <Text>{financingPlan.paidFees}</Text>
                            </GridTable.Data>
                            <GridTable.Data columnStart={7} alignX="flex-end">
                                <Text>{financingPlan.term}</Text>
                            </GridTable.Data>
                        </GridTable.Container>
                    ))}
                </GridTable.Body>
            ) : (
                <GridTable.Body>
                    <GridTable.Container
                        className="product-data px-5"
                        columnsTemplate={getColumnsTemplate()}
                        border=""
                        rows={1}>
                        <GridTable.Data columnWidth={7} alignX="center">
                            <Text
                                align="center"
                                className="mt-6 mt-md-9"
                                labelKey="creditCard.financing.plans.table.empty"
                            />
                        </GridTable.Data>
                    </GridTable.Container>
                </GridTable.Body>
            )}
        </GridTable>
    );

    return (
        <>
            <Notification scopeToShow="creditCardDetailFinancingPlan" />
            <Head
                onBack={handleBack}
                title="creditCard.financing.plans.header.title"
                subtitlePrefix={creditCard?.franchise}
                subtitle={` **** ${creditCard?.number.slice(-4)}`}
                rightChildren={
                    isDesktop ? (
                        <Box display="flex" alignY="center" alignX="flex-end" gap="7">
                            <Button
                                label="creditCard.buttons.pay.financing.plans"
                                bsStyle="primary"
                                className="px-12"
                                disabled={financingPlans?.length === 0}
                                onClick={handleClickPayment}
                            />
                        </Box>
                    ) : null
                }
            />

            <MainContainer showLoader={loading}>
                <Box component="article" background="white" borderRadius="default" className="pt-5 mt-5 mb-5">
                    <Box
                        {...(!isDesktop && { border: "bottom-background-border-disabled" })}
                        className="pb-5"
                        fullWidth>
                        <Text
                            component="h2"
                            size="4"
                            bold
                            className="ml-5 my-0"
                            labelKey="creditCard.financing.plans.table.title"
                        />
                    </Box>
                    {isDesktop ? renderTableDesktop() : renderTableMobile()}
                </Box>
                <>
                    <Box
                        display="flex"
                        alignX="between"
                        alignY="center"
                        gap="7"
                        background="heading-color"
                        borderRadius="default"
                        className="px-5 px-md-8 py-7 mb-5">
                        <Text
                            size={isDesktop ? "big" : "2"}
                            color="inverse"
                            bold
                            labelKey="creditCard.financing.plans.footer.total"
                        />
                        <Box
                            display="flex"
                            {...(isDesktop
                                ? { alignY: "center", gap: "7" }
                                : { column: true, columnReverse: true, alignY: "flex-end" })}>
                            <Text
                                size={isDesktop ? "5" : "7"}
                                color="inverse"
                                align="right"
                                labelKey="creditCard.financing.plans.footer.description"
                            />
                            <FormattedAmount
                                size={isDesktop ? "big" : "2"}
                                color="inverse"
                                bold
                                quantity={
                                    financingPlans
                                        ?.map((financing) => financing.balanceDue)
                                        .reduce((a, b) => a + b, 0) || 0
                                }
                                currency="USD"
                            />
                        </Box>
                    </Box>
                    {!isDesktop && (
                        <Button
                            bsStyle="primary"
                            block
                            className="mt-auto"
                            label="creditCard.buttons.pay.financing.plans"
                            disabled={financingPlans?.length === 0}
                            onClick={handleClickPayment}
                        />
                    )}
                </>
            </MainContainer>
        </>
    );
};

DetailFinancingPlans.propTypes = {
    dispatch: func.isRequired,
    financingPlans: arrayOf(shape({})).isRequired,
    isDesktop: bool.isRequired,
    match: shape({}).isRequired,
    creditCard: shape({}).isRequired,
};
DetailFinancingPlans.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;
    return {
        creditCard: creditCardSelectors.getDetail(state, id),
        financingPlans: creditCardSelectors.getFinancingPlans(state),
    };
};

export default connect(mapStateToProps)(resizableRoute(DetailFinancingPlans));

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import { func, string } from "prop-types";
import * as i18n from "util/i18n";
import React from "react";

const PreApprovedScrollBar = ({ id, actionHandler, closeHandler, secondaryActionHandler }) => (
    <Box id={id} className="pre-approverd-bottom-buttom">
        <Box display="flex" alignX="center" alignY="center" background="wally" className="pl-5 pr-3 pt-3 pb-8" gap="5">
            <Box display="flex" column>
                <Text size="5" color="text-inverse-color" bold>
                    {i18n.get("preApprovedProduct.scrollbar.title")}
                </Text>
                <Button bsStyle="link" className="px-0 btn-remove-min-height" onClick={secondaryActionHandler}>
                    <Text size="7" color="text-inverse-color" underlined regular>
                        {i18n.get("preApprovedProduct.scrollbar.doNotShowAgain")}
                    </Text>
                </Button>
            </Box>
            <Box className="min-width-9rem">
                <Button bsStyle="primary" className="alternative-color" block onClick={actionHandler}>
                    <Text size="3">{i18n.get("preApprovedProduct.scrollbar.button")}</Text>
                </Button>
            </Box>
            <Button image="images/icons/cross16.svg" imageColor="text-inverse-color" onClick={closeHandler} />
        </Box>
    </Box>
);

PreApprovedScrollBar.propTypes = {
    actionHandler: func,
    closeHandler: func,
    id: string,
    secondaryActionHandler: func,
};

PreApprovedScrollBar.defaultProps = {
    actionHandler: null,
    closeHandler: null,
    id: null,
    secondaryActionHandler: null,
};

export default PreApprovedScrollBar;

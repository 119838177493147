import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { selectors as sessionSelectors } from "reducers/session";
import Notification from "pages/_components/Notification";
import { bool } from "prop-types";
import TokenButtonWrapper from "pages/_components/TokenButton/TokenButtonWrapper";
import { getEntrustKeys } from "util/softToken.util";
import Box from "../Box";

const TokenButtonDetails = ({ isActiveCorporate }) => {
    const [entrustKeyList, setEntrustKeyList] = useState([]);
    const [loader, setLoader] = useState(true);

    const getKeys = async () => {
        try {
            const { corporateKeys } = await getEntrustKeys();
            if (isActiveCorporate && corporateKeys.length > 1) {
                setEntrustKeyList(corporateKeys);
            }
        } catch (error) {
            // console.log("error", error);
        }
    };

    useEffect(() => {
        getKeys();
    }, []);

    return (
        <>
            <Head title="token.details.title" backLinkTo="/" additionalClassName="background-primary" />
            <Notification scopeToShow="tokenDetails" />
            <MainContainer classNameMainWrapper="background-menu-background" showLoader={loader} loadChildrenAtOnce>
                <Box className="pt-5">
                    {entrustKeyList.map((el) => (
                        <Box className="py-2 token-details" alignY="center">
                            <TokenButtonWrapper
                                key={el?.entrustKey}
                                entrustKey={el}
                                isShowDetails
                                changeLoader={() => setLoader(false)}
                                isDetails
                            />
                        </Box>
                    ))}
                </Box>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

TokenButtonDetails.propTypes = {
    isActiveCorporate: bool.isRequired,
};
TokenButtonDetails.defaultProps = {};

export default connect(mapStateToProps)(TokenButtonDetails);

import classNames from "classnames";
import { array, bool, func, node, oneOfType, shape, string } from "prop-types";
import React from "react";

import isEmpty from "lodash/isEmpty";
import FieldError from "pages/_components/fields/FieldError";
import FieldHelp from "pages/_components/fields/FieldHelp";
import FieldHint from "pages/_components/fields/FieldHint";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as i18nUtils from "util/i18n";

const formField = (
    options = {
        /* render component without label/error wrapper */
        pureRender: false,
        /* string | (props) => string */
        formClass: "",
        /* (value, props) => Boolean */
        isValidValue: null,
        isEmptyValue: null,
        renderLegend: null,
        /* (props) => <Element /> */
        customLabel: () => null,
    },
) => (FormFieldComponent) =>
    class extends React.Component {
        static getDerivedStateFromProps(nextProps) {
            const { form, fieldList, dependsOnProduct } = nextProps;

            if (!dependsOnProduct) {
                return {};
            }

            const dependencyValue = form.values[dependsOnProduct];
            const dependencyField = fieldList.find(({ idField }) => idField === dependsOnProduct) || {};

            return {
                dependencyValue,
                dependencyField,
            };
        }

        static displayName = `field(${FormFieldComponent.name})`;

        static propTypes = {
            dateFormat: string,
            defaultValue: oneOfType([string, shape({}), array]),
            dependsOnProduct: string,
            field: shape({}),
            fieldList: shape({ find: func }),
            form: shape({}),
            helpMap: shape({}),
            hintMap: shape({}),
            idActivity: string.isRequired,
            idField: string,
            idForm: string,
            idValidation: string,
            isFocused: bool,
            isRequired: bool,
            labelClassName: string,
            labelNoMarginTop: bool,
            lang: string.isRequired,
            mode: string.isRequired,
            noLabel: bool,
            noLabelEditMode: bool,
            noMarginBottom: bool,
            optionalMessageMap: shape({}),
            placeholderMap: shape({}),
            readOnly: bool,
            renderLabel: bool,
            subType: string,
            ticketOnly: bool,
            tooltipInfo: node,
            tooltipLightSyled: bool,
            tooltipPosition: string,
            tooltipText: string,
            type: string,
            value: oneOfType([string, shape({}), array]),
            relativeStyleError: bool,
            absoluteStyleError: bool,
            labelIdField: string,
        };

        static defaultProps = {
            dateFormat: "",
            defaultValue: "",
            dependsOnProduct: undefined,
            field: null,
            fieldList: undefined,
            form: null,
            helpMap: null,
            hintMap: null,
            idField: "",
            idForm: undefined,
            idValidation: "",
            isFocused: false,
            isRequired: false,
            labelClassName: null,
            labelNoMarginTop: false,
            noLabel: false,
            noLabelEditMode: false,
            noMarginBottom: false,
            optionalMessageMap: null,
            placeholderMap: null,
            readOnly: undefined,
            renderLabel: true,
            subType: "",
            ticketOnly: false,
            tooltipInfo: null,
            tooltipLightSyled: false,
            tooltipPosition: null,
            tooltipText: null,
            type: "",
            value: undefined,
            relativeStyleError: false,
            absoluteStyleError: false,
            labelIdField: null,
        };

        constructor(props) {
            super(props);
            this.state = {
                tags: [],
            };
        }

        componentDidMount() {
            const { defaultValue, field, value } = this.props;
            if (!this.isEmptyValue(defaultValue) && this.isEmptyValue(field.value)) {
                this.setValue(defaultValue);
            } else if (this.isEmptyValue(field.value) && !this.isEmptyValue(value)) {
                this.setValue(value);
            }
        }

        customLabel = (props) => {
            if (typeof options.customLabel === "function") {
                return options.customLabel(props);
            }
            return null;
        };

        setTouched = () => {
            const {
                form: { setFieldTouched },
                field: { name },
            } = this.props;
            setFieldTouched(name);
        };

        isTouched = () => {
            const {
                form: { touched },
                field: { name },
            } = this.props;
            return !!touched[name];
        };

        setError = (msg) => {
            const {
                form: { errors, setErrors },
                field: { name },
            } = this.props;

            if (msg) {
                setErrors({ ...errors, [name]: msg });
            }
            if (name in errors) {
                const { [name]: error, ...rest } = errors;
                setErrors(rest);
            }
        };

        hasError = () => !!this.errorText();

        errorText = () => {
            const {
                form: { errors },
                field: { name },
            } = this.props;

            return errors[name];
        };

        setAllTagValue = (value) => {
            const {
                form: { setFieldValue },
                field: { name },
            } = this.props;

            const newAddedItems = value.map((item) => item.id);
            this.setState({ tags: newAddedItems }, () => {
                // eslint-disable-next-line react/destructuring-assignment
                setFieldValue(name, this.state.tags); // This has to be this way as it has to reflect the updated value
            });
        };

        setTagValue = (value) => {
            const {
                form: { setFieldValue },
                field: { name },
            } = this.props;

            const { tags } = this.state;

            const newTags = [...tags, value.id];
            this.setState({ tags: newTags }, () => {
                // eslint-disable-next-line react/destructuring-assignment
                setFieldValue(name, this.state.tags); // This has to be this way as it has to reflect the updated value
                this.validate(value);
            });
        };

        removeTagValue = (i) => {
            const {
                form: { setFieldValue },
                field: { name },
            } = this.props;

            const { tags } = this.state;
            const newTags = tags.filter((tag, index) => index !== i);
            setFieldValue(name, newTags);
            this.setState({ tags: newTags }, () => {});
        };

        setValue = (value) => {
            const {
                form: { setFieldValue },
                field: { name },
                idValidation,
            } = this.props;

            if (idValidation === "email" || this.isValidValue(value)) {
                setFieldValue(name, value);
                this.validate(value);
            }
        };

        validate = (value) => {
            const { isRequired } = this.props;

            if (isRequired && this.isEmptyValue(value)) {
                this.setError(this.i18n("requiredError"));
                return false;
            }

            this.setError(null);

            return true;
        };

        onBlur = () => {
            this.setTouched();
        };

        i18n = (type) => {
            const { lang, idActivity, idField } = this.props;

            const map = this.props[`${type}Map`] || {};

            if (isEmpty(map)) {
                return i18nUtils.getI18Component(type, lang, idActivity, idField);
            }
            return map[lang];
        };

        isValidValue = (value) => {
            if (value == null) {
                return false;
            }
            if (typeof options.isValidValue === "function") {
                const props = this.componentProps();
                return options.isValidValue(value, props);
            }
            return true;
        };

        isEmptyValue = (value) => {
            if (value == null) {
                return true;
            }
            if (Array.isArray(value)) {
                let retorno = true;
                value.forEach((val) => {
                    if (val !== "") {
                        retorno = false;
                    }
                });
                return retorno;
            }
            if (typeof options.isEmptyValue === "function") {
                const props = this.componentProps();
                return options.isEmptyValue(value, props);
            }
            return value == null || value === "";
        };

        formClass = (props) => {
            if (typeof options.formClass === "function") {
                return options.formClass(props);
            }
            if (typeof options.formClass === "string") {
                return options.formClass;
            }
            return "";
        };

        componentProps = () => {
            const { mode, readOnly, field, value, idForm } = this.props;
            let { idField } = this.props;
            const { dependencyField, dependencyValue } = this.state;
            if (!idField) {
                idField = `${idForm}.${idField}`;
            }

            return {
                ...this.props,
                idField,
                name: field.name,
                value: field.value || value,
                label: this.i18n("label"),
                placeholder: this.i18n("placeholder"),
                optionalMessage: this.i18n("optionalMessage"),
                editing: mode === "edit" && !readOnly,
                i18n: this.i18n,
                setTagValue: this.setTagValue,
                setAllTagValue: this.setAllTagValue,
                removeTagValue: this.removeTagValue,
                setValue: this.setValue,
                setError: this.setError,
                onBlur: this.onBlur,
                setTouched: this.setTouched,
                dependencyField,
                dependencyValue,
            };
        };

        render() {
            const Component = options.renderLegend ? "fieldset" : "div";
            const props = this.componentProps();
            const { idField } = props;
            if (options.pureRender) {
                return <FormFieldComponent {...props} />;
            }

            const {
                field: { value },
                idActivity,
                isFocused,
                isRequired,
                labelClassName,
                labelNoMarginTop,
                mode,
                noLabel,
                noLabelEditMode,
                noMarginBottom,
                readOnly,
                renderLabel,
                subType,
                ticketOnly,
                tooltipInfo,
                tooltipLightSyled,
                tooltipPosition,
                tooltipText,
                type,
                relativeStyleError,
                absoluteStyleError,
                labelIdField,
            } = this.props;
            const formClass = this.formClass(props);
            /**
             * Get label by mode, only if the form is static
             */
            const labelText = mode !== "edit" && idActivity ? `label_${mode}` : "label";

            if ((mode === "view" || mode === "preview" || readOnly) && !this.isEmptyValue(value)) {
                return (
                    <div className={classNames(subType !== "vertical-display" ? "data-wrapper" : "", formClass)}>
                        {type !== "coordinates" &&
                            !noLabel &&
                            (this.customLabel(props) ||
                                (this.i18n("label") && (
                                    <FieldLabel
                                        labelNoMarginTop={labelNoMarginTop}
                                        labelClassName={labelClassName}
                                        labelText={this.i18n(labelText)}
                                        mode="view"
                                        idField={idField}
                                        renderLabel={renderLabel}
                                    />
                                )))}
                        <FormFieldComponent idField={idField} {...props} />
                    </div>
                );
            }
            if (mode === "edit" && !ticketOnly) {
                return (
                    <Component
                        className={classNames("form-group", formClass, {
                            "has-error": this.hasError(),
                            "has-focus": isFocused,
                            "form-group-margin-bottom": !noMarginBottom,
                        })}>
                        {type !== "coordinates" &&
                            !noLabel &&
                            !noLabelEditMode &&
                            (this.customLabel(props) || (
                                <FieldLabel
                                    labelText={this.i18n(labelText)}
                                    optional={isRequired ? "" : i18nUtils.get("forms.optional")}
                                    idField={labelIdField || idField}
                                    isLegend={options.renderLegend}
                                    labelNoMarginTop={labelNoMarginTop}
                                    labelClassName={labelClassName}
                                    renderLabel={renderLabel}
                                    tooltipInfo={tooltipInfo}
                                    tooltipLightSyled={tooltipLightSyled}
                                    tooltipPosition={tooltipPosition}
                                    tooltipText={tooltipText}
                                />
                            ))}

                        <FormFieldComponent idField={idField} {...props} />
                        {this.hasError() && (
                            <FieldError
                                error={this.errorText()}
                                relativeStyleError={relativeStyleError}
                                absoluteStyleError={absoluteStyleError}
                            />
                        )}

                        {/* {this.hasError() && this.isTouched() && <FieldError error={this.errorText()} />} */}
                        {this.i18n("help") && <FieldHelp text={this.i18n("help")} />}
                        {this.i18n("hint") && <FieldHint text={this.i18n("hint")} />}
                    </Component>
                );
            }
            return null;
        }
    };

export default formField;

import { createReducer, makeActionCreator } from "util/redux";
import { types as creditLinesTypes } from "reducers/creditLines";

export const types = {
    LIST_REQUEST: "widgets/LIST_REQUEST",
    LIST_FAILURE: "widgets/LIST_FAILURE",
    LIST_SUCCESS: "widgets/LIST_SUCCESS",
    LIST_NEXT_PAYMENT: "widgets/LIST_NEXT_PAYMENT",
    DELETE_WIDGET: "widgets/DELETE_WIDGET",
    ADD_WIDGET_REQUESTED: "widgets/ADD_WIDGET_REQUESTED",
    LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_REQUEST: "widgets/LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_REQUEST",
    LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_FAILURE: "widgets/LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_FAILURE",
    LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_SUCCESS: "widgets/LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_SUCCESS",
    SET_LIST_ACCOUNT_WIDGET: "widgets/SET_LIST_ACCOUNT_WIDGET",
};

export const INITIAL_STATE = {
    widgetNamesRequested: [],
    listNextPayment: [],
    accounts: {
        isFetching: false,
        data: { accounts: [] },
    },
    creditCards: {
        isFetching: false,
        data: { creditCards: [] },
    },
    exchangeRates: {
        isFetching: false,
        data: { rates: [] },
    },
    portfolio: {
        isFetching: false,
        data: undefined,
    },
    loans: {
        isFetching: false,
        data: { loans: [] },
    },
    notifications: {
        isFetching: false,
        data: undefined,
    },
    pendingTransactions: {
        isFetching: false,
        data: { pendingTransactions: [] },
    },
    scheduledTransactions: {
        isFetching: false,
        data: { scheduledTransactions: [] },
    },
    deposits: {
        isFetching: false,
        data: { deposits: [] },
    },
    points: {
        isFetching: false,
        data: undefined,
    },
    creditLines: {
        isFetching: false,
        data: {
            creditLines: [],
            total: undefined,
        },
    },
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: true,
        },
    }),
    [creditLinesTypes.LIST_REQUEST]: (state) => ({
        ...state,
        creditLines: {
            ...state.creditLines,
            isFetching: true,
        },
    }),
    [creditLinesTypes.FETCH]: (state) => ({
        ...state,
        creditLines: {
            ...state.creditLines,
            isFetching: true,
        },
    }),
    [types.LIST_FAILURE]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: false,
            hadError: true,
        },
    }),
    [creditLinesTypes.LIST_FAILURE]: (state) => ({
        ...state,
        creditLines: {
            isFetching: false,
        },
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        [action.widget]: {
            data: action.data,
            isFetching: false,
        },
    }),
    [creditLinesTypes.LIST_SUCCESS]: (state, action) => ({
        ...state,
        creditLines: {
            data: {
                list: action.list,
                total: action.total,
            },
            isFetching: false,
        },
    }),
    [types.LIST_NEXT_PAYMENT]: (state, action) => {
        const newList = state.listNextPayment.slice();

        const keyToFind = [...action.listNextPayment.entries()][0][0] ?? null;

        if (!keyToFind) {
            return state;
        }

        let existingIndex = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of newList) {
            if (item.has(keyToFind)) {
                existingIndex = true;
            }
        }
        if (existingIndex) {
            newList[keyToFind] = action.listNextPayment;
        } else {
            newList.push(action.listNextPayment);
        }

        return {
            ...state,
            listNextPayment: newList,
            [keyToFind]: {
                ...state[keyToFind],
                isFetching: false,
            },
        };
    },
    [types.DELETE_WIDGET]: (state, action) => ({
        ...state,
        [action.widget.id]: {
            ...INITIAL_STATE[action.widget.id],
        },
    }),
    [types.ADD_WIDGET_REQUESTED]: (state, action) => ({
        ...state,
        widgetNamesRequested: [...state.widgetNamesRequested, action.widgetNamesRequested],
    }),
    [types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_REQUEST]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: true,
        },
    }),
    [types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_FAILURE]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: false,
        },
    }),
    [types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_SUCCESS]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: false,
        },
    }),
    [types.SET_LIST_ACCOUNT_WIDGET]: (state) => ({
        ...state,
        accounts: {
            ...state.accounts,
            isFetching: true,
        },
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST, "widget", "takeDataCache"),
    listFailure: makeActionCreator(types.LIST_FAILURE, "widget"),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "widget", "data"),
    deleteWidget: makeActionCreator(types.DELETE_WIDGET, "widget"),
    addWidgetRequested: makeActionCreator(types.ADD_WIDGET_REQUESTED, "widgetNamesRequested"),
    setListPayment: makeActionCreator(types.LIST_NEXT_PAYMENT, "listNextPayment"),
    listWidgetAvailableNextPaymentRequest: makeActionCreator(
        types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_REQUEST,
        "widget",
    ),
    listWidgetAvailableNextPaymentFailure: makeActionCreator(
        types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_FAILURE,
        "widget",
    ),
    listWidgetAvailableNextPaymentSuccess: makeActionCreator(
        types.LIST_WIDGET_AVAILABLE_NEXT_PAYMENT_SUCCESS,
        "widget",
    ),
    setListAccountWidget: makeActionCreator(types.SET_LIST_ACCOUNT_WIDGET),
};

export const selectors = {
    getWidget: ({ widgets }, name) => widgets[name],
    getWidgetList: ({ widgets }, name) => widgets[name].data[name],
    getWidgetNamesRequested: ({ widgets }) => widgets.widgetNamesRequested,
    getListNextPayment: ({ widgets }) => widgets.listNextPayment,
};

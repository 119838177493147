import { types, actions } from "reducers/checks";
import { call, put, takeLatest } from "redux-saga/effects";
import * as checksMiddleware from "middleware/checks";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { isMobileNativeFunc } from "util/device";
import { openLink } from "util/browser";

const sagas = [
    takeLatest(types.CHECKBOOKS_LIST_REQUEST, checkbooksListRequest),
    takeLatest(types.CHECKS_LIST_REQUEST, checksListRequest),
    takeLatest(types.ALL_CHECKS_LIST_REQUEST, allChecksListRequest),
    takeLatest(types.URL_CHECK_REQUEST, getUrlCheckRequest),
];

export default sagas;

function* checkbooksListRequest({ idAccount }) {
    const response = yield call(checksMiddleware.listCheckbooks, idAccount);
    if (response && response.status === 200) {
        const { checkbooks, accountNumber } = response.data.data;

        yield put(actions.listCheckbooksSuccess(checkbooks, accountNumber));
    } else {
        yield put(actions.listCheckbooksFailure());
    }
}

function* checksListRequest({ idAccount, idCheckbook }) {
    const response = yield call(checksMiddleware.listChecks, idAccount, idCheckbook);
    if (response && response.status === 200) {
        const { checks, accountNumber, initialCheckNumber, finalCheckNumber, currentCheckbook } = response.data.data;

        yield put(
            actions.listChecksSuccess(checks, accountNumber, initialCheckNumber, finalCheckNumber, currentCheckbook),
        );
    } else {
        yield put(actions.listChecksFailure());
    }
}

function* allChecksListRequest({ idAccount }) {
    const response = yield call(checksMiddleware.listAllChecks, idAccount);
    if (response && response.status === 200) {
        const { checks, accountNumber } = response.data.data;

        yield put(actions.listAllChecksSuccess(checks, accountNumber));
    } else {
        yield put(actions.listAllChecksFailure());
    }
}

function* showGenericErrorTrackingUrl() {
    yield put(
        notificationActions.showNotification(
            i18n.get("checks.getUrl.error.message"),
            "error",
            ["checks", "account/details"],
            false,
        ),
    );
    yield put({
        type: types.URL_CHECK_CLEAR,
    });
}

function* getUrlCheckRequest({ idAccount, checkNumber }) {
    const response = yield call(checksMiddleware.getCheckUrl, idAccount, checkNumber);
    if (!response?.type || !response?.data?.data) {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }
    const { type, data } = response.data;
    if (type === "W") {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }
    const { fileUrl } = data;
    if (!fileUrl || fileUrl === "") {
        yield call(showGenericErrorTrackingUrl);
        yield;
        return;
    }
    yield put({
        type: types.URL_CHECK_CLEAR,
    });
    if (isMobileNativeFunc()) {
        yield call(openLink, fileUrl);
        yield;
        return;
    }
    yield call(window.open, fileUrl, "_blank", "noreferrer");
}

import Box from "pages/_components/Box/Box";
import MainContainer from "pages/_components/MainContainer";
import React from "react";
import { Modal } from "react-bootstrap";
import { bool, func, string } from "prop-types";
import BannerAppleWallet from "./BannerAppleWalletComponent";

const BannerAppleWalletModal = ({ show, redirectCallback, redirect, setShow }) => (
    <Modal show={show !== undefined && show === true}>
        <Box display="flex" column alignY="center">
            <Modal.Body>
                <MainContainer classNameMainWrapper="background-menu-background" showLoader={false}>
                    <BannerAppleWallet setShow={setShow} redirectCallback={redirectCallback} redirect={redirect} />
                </MainContainer>
            </Modal.Body>
        </Box>
    </Modal>
);

BannerAppleWalletModal.propTypes = {
    show: bool,
    redirectCallback: func,
    redirect: string,
    setShow: func,
};

BannerAppleWalletModal.defaultProps = {
    show: false,
    redirectCallback: () => {},
    redirect: "",
    setShow: () => {},
};

export default BannerAppleWalletModal;

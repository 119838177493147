import * as API from "middleware/api";

export const loadDetailsRequest = (id) =>
    API.executeWithAccessToken("/administration.medium.user.details.read", { id });

export const loadPermissionsRequest = (id) =>
    API.executeWithAccessToken("/administration.medium.read.financialPermissionsWithSignatures", {
        id,
    });

export const loadPermissionsDetailsRequest = (id, sectionId) =>
    API.executeWithAccessToken("/administration.medium.read.financialPermissionsWithSignatures", {
        id,
        sectionId,
    });

export const updatePermissionsPreview = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.permissions.preview", data);

export const updatePermissionsRequest = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.permissions.send", data);

export const loadChannelsRequest = (id) => API.executeWithAccessToken("/administration.medium.read.channels", { id });

export const updateChannelsPreview = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.channels.preview", data);

export const updateChannelsRequest = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.channels.send", data);

export const createSignaturesScheme = (data) =>
    API.executeWithAccessToken("/administration.signatures.create.send", data);

export const createSignaturesSchemePre = () => API.executeWithAccessToken("/administration.signatures.create.pre");

export const listSignaturesSchemes = ({ pageNumber = 1, orderBy = "id_signature ASC" } = {}) =>
    API.executeWithAccessToken("/administration.signatures.list", {
        pageNumber,
        orderBy,
    });

export const deleteSignaturesScheme = (data) =>
    API.executeWithAccessToken("/administration.signatures.delete.send", data);

export const deleteSignaturesSchemePre = (id) =>
    API.executeWithAccessToken("/administration.signatures.delete.pre", { id });

export const modifySignaturesScheme = (data) =>
    API.executeWithAccessToken("/administration.signatures.modify.send", data);

export const modifySignaturesSchemePre = (id) =>
    API.executeWithAccessToken("/administration.signatures.modify.pre", { id });

export const updateSignatureRequest = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.signature.send", data);

export const exportSignatureSchemes = (data) => API.executeWithAccessToken("/administration.signatures.export", data);

import { bool, func, number as typeNumber, shape, string } from "prop-types";
import React, { Component } from "react";
import CreditCard from "pages/_components/CreditCard";
import CreditCardWidget from "pages/_components/CreditCardWidget";
import { withRouter } from "react-router-dom";
import { actions as productActions } from "reducers/products";
import { actions as creditCardActions } from "reducers/creditCard";

class CreditCardsListItem extends Component {
    static propTypes = {
        availableBalance: typeNumber,
        availableBalanceCurrency: string,
        blocked: bool,
        cashBackAmount: typeNumber,
        cashBackAmountCurrency: typeNumber,
        creditCardData: shape({}),
        description: string,
        dispatch: func,
        expirationDateMask: string,
        franchise: string,
        hasErrorLoadingPoints: bool,
        hideAmountLabel: bool,
        history: shape({}),
        idProduct: string,
        isFetchingPoints: bool,
        label: string,
        minimumPayment: typeNumber,
        minimumPaymentCurrency: string,
        numberMask: string,
        path: string,
        productAlias: string,
        shortLabel: string,
        showLink: bool,
        statusCode: string,
        totalPoints: typeNumber,
        isList: bool,
        isIssuerAvaibleForPoints: bool,
        isIssuerAvaibleForCashback: bool,
    };

    static defaultProps = {
        availableBalance: null,
        availableBalanceCurrency: null,
        blocked: false,
        cashBackAmount: null,
        cashBackAmountCurrency: null,
        creditCardData: {},
        description: "",
        dispatch: () => {},
        expirationDateMask: null,
        franchise: null,
        hasErrorLoadingPoints: false,
        hideAmountLabel: false,
        history: {},
        idProduct: null,
        isFetchingPoints: true,
        label: null,
        minimumPayment: null,
        minimumPaymentCurrency: null,
        numberMask: "",
        path: null,
        productAlias: null,
        shortLabel: "",
        showLink: true,
        statusCode: "",
        totalPoints: null,
        isList: false,
        isIssuerAvaibleForPoints: false,
        isIssuerAvaibleForCashback: false,
    };

    onClickLoadCashBackPoint = (idProduct) => {
        const { dispatch } = this.props;
        dispatch(creditCardActions.detailEveryRequest(idProduct));
    };

    onClickItem = (idProduct, description, statusCode, creditcard) => {
        const { path, history, dispatch } = this.props;
        dispatch(creditCardActions.setExtraDataDetail(creditcard));
        dispatch(productActions.setSelectProduct({ idProduct, description, statusCode }));
        const finalPath = path || `/creditCards/${idProduct}`;
        history.push(finalPath);
    };

    render() {
        const {
            minimumPayment,
            minimumPaymentCurrency,
            idProduct,
            blocked,
            numberMask,
            franchise,
            availableBalanceCurrency,
            description,
            statusCode,
            shortLabel,
            creditCardData,
            expirationDateMask,
            isFetchingPoints,
            totalPoints,
            cashBackAmount,
            cashBackAmountCurrency,
            hasErrorLoadingPoints,
            isList,
            isIssuerAvaibleForPoints,
            isIssuerAvaibleForCashback,
        } = this.props;
        return (
            <>
                {isList ? (
                    <CreditCard
                        {...this.props}
                        minimumPayment={minimumPayment}
                        minimumPaymentCurrency={minimumPaymentCurrency}
                        currency={availableBalanceCurrency}
                        icon={franchise.toLowerCase()}
                        id={idProduct}
                        blocked={blocked}
                        onClick={() => {
                            this.onClickItem(idProduct, description, statusCode, creditCardData);
                        }}
                        reference={numberMask}
                        totalPoints={totalPoints}
                        cashBack={`${cashBackAmountCurrency} ${cashBackAmount}`}
                        hasPointsService={Boolean(totalPoints)}
                        hasCashBackService={Boolean(cashBackAmount)}
                        onClickLoadCashBackPoint={() => {
                            this.onClickLoadCashBackPoint(idProduct);
                        }}
                        hasErrorLoadingPoints={hasErrorLoadingPoints}
                        isFetchingPoints={isFetchingPoints}
                        statusCode={statusCode}
                        shortLabel={shortLabel}
                        idProduct={idProduct}
                        expirationDate={expirationDateMask}
                        isIssuerAvaibleForPoints={isIssuerAvaibleForPoints}
                        isIssuerAvaibleForCashback={isIssuerAvaibleForCashback}
                    />
                ) : (
                    <CreditCardWidget
                        {...this.props}
                        minimumPayment={minimumPayment}
                        minimumPaymentCurrency={minimumPaymentCurrency}
                        currency={availableBalanceCurrency}
                        icon={franchise.toLowerCase()}
                        id={idProduct}
                        blocked={blocked}
                        onClick={() => {
                            this.onClickItem(idProduct, description, statusCode, creditCardData);
                        }}
                        reference={numberMask}
                        hasErrorLoadingPoints={hasErrorLoadingPoints}
                        isFetchingPoints={isFetchingPoints}
                        statusCode={statusCode}
                        shortLabel={shortLabel}
                        idProduct={idProduct}
                        expirationDate={expirationDateMask}
                    />
                )}
            </>
        );
    }
}

export default withRouter(CreditCardsListItem);

import classNames from "classnames";
import EnrollmentStepper from "pages/enrollment/_components/EnrollmentStepper";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, node, number, oneOfType, shape, string } from "prop-types";
import RevelockPositionProvider from "providers/RevelockPositionProvider";
import React, { Component } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { connect } from "react-redux";
import { Link, Route, withRouter } from "react-router-dom";
import PageTransition from "react-router-page-transition";
import { goBack } from "react-router-redux";
import { actions as loginActions } from "reducers/login";

class EnrollmentLayout extends Component {
    state = { transition: "" };

    static propTypes = {
        location: shape({
            pathname: string,
        }).isRequired,
        dispatch: func.isRequired,
        component: oneOfType([node, func]).isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        isDesktop: bool.isRequired,
        showBack: bool,
        mustGobackWithClose: bool,
        camera: string,
        transition: string,
        step: number,
        completed: number,
    };

    static defaultProps = {
        showBack: false,
        mustGobackWithClose: false,
        camera: null,
        transition: "transition-drill-in",
        step: null,
        completed: null,
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { step } = this.props;
        const { step: nextStep } = nextProps;

        let transition = "transition-change-feature";

        if (
            typeof step !== "undefined" &&
            typeof nextStep !== "undefined" &&
            parseInt(step, 10) <= parseInt(nextStep, 10)
        ) {
            transition = "transition-drill-in";
        }

        if (
            typeof step !== "undefined" &&
            typeof nextStep !== "undefined" &&
            parseInt(step, 10) > parseInt(nextStep, 10)
        ) {
            transition = "transition-drill-out";
        }

        this.setState({
            transition,
        });
    }

    handleClick = () => {
        const { dispatch, mustGobackWithClose } = this.props;
        if (mustGobackWithClose) {
            dispatch(goBack());
        } else {
            dispatch(loginActions.reset());
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    getMobileLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            camera,
            showBack,
            step,
            completed,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, camera };
        const { transition } = this.state;

        return (
            <div className={`enrollment ${transition}`}>
                <PageTransition timeout={600}>
                    <div
                        className={classNames("transition-item enrollment-layout full-height", {
                            "camera-layout": camera,
                            "camera-layout--landscape": camera === "landscape",
                        })}>
                        <ErrorBoundary>
                            <Box component="header" background="white" className="app-header">
                                <Navbar collapseOnSelect fluid>
                                    <Navbar.Header>
                                        <Box className="navbar-header-wrapper">
                                            <Box display="flex" alignY="center">
                                                {showBack && (
                                                    <Box className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                                        <Button
                                                            block={false}
                                                            bsStyle="only-icon"
                                                            onClick={this.onHeaderBack}
                                                            image="images/arrowLeft.svg"
                                                            label="global.back"
                                                        />
                                                    </Box>
                                                )}
                                                <Navbar.Brand>
                                                    <Link className="navbar-brand" to="/">
                                                        <Image src="images/logo-banesco.svg" />
                                                        <Text
                                                            className="visually-hidden"
                                                            labelKey="global.companyName"
                                                        />
                                                    </Link>
                                                </Navbar.Brand>
                                            </Box>
                                        </Box>
                                    </Navbar.Header>
                                </Navbar>
                            </Box>

                            <MainContainer background="menu-background" className="full-height">
                                <Notification scopeToShow="enrollment" />
                                {step && (
                                    <Box component="section" className="enrollment-stepper">
                                        <EnrollmentStepper currentStep={step} completed={completed} />
                                    </Box>
                                )}
                                <Box component="section" display="flex" column flex1>
                                    <ReceivedComponent {...matchProps} {...extras} />
                                </Box>
                            </MainContainer>
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            showBack,
            step,
            completed,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };

        return (
            <ErrorBoundary>
                <Box className="app theme-auth enrollment-layout">
                    <Box component="header" background="white" className="app-header">
                        <Navbar collapseOnSelect fluid>
                            <Navbar.Header>
                                <Box className="navbar-header-wrapper">
                                    <Box display="flex" alignY="center">
                                        {showBack && (
                                            <Box className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                                <Button
                                                    block={false}
                                                    bsStyle="only-icon"
                                                    onClick={this.onHeaderBack}
                                                    image="images/arrowLeft.svg"
                                                    label="global.back"
                                                />
                                            </Box>
                                        )}
                                        <Navbar.Brand>
                                            <Link className="navbar-brand" to="/">
                                                <Box
                                                    {...(!showBack && isDesktop && { className: "pl-12 ml-2" })}
                                                    display="flex"
                                                    alignY="center">
                                                    <Image src="images/logo-banesco.svg" />
                                                </Box>
                                                <Text className="visually-hidden" labelKey="global.companyName" />
                                            </Link>
                                        </Navbar.Brand>
                                    </Box>
                                </Box>
                            </Navbar.Header>
                        </Navbar>
                    </Box>
                    {step && (
                        <Box component="aside" className="enrollment-stepper">
                            <EnrollmentStepper currentStep={step} completed={completed} />
                        </Box>
                    )}

                    <Box component="main" className="enrollment-principal main-wrapper" role="main">
                        <Box component="section" className="main-container" position="relative">
                            <Notification scopeToShow="enrollment" />
                            <ReceivedComponent {...matchProps} {...extras} />
                        </Box>
                    </Box>
                </Box>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <RevelockPositionProvider {...this.props}>
                <Route
                    {...rest}
                    render={(matchProps) => {
                        const { isMobile } = this.props;

                        if (isMobile) {
                            return <>{this.getMobileLayout(matchProps)}</>;
                        }

                        return <>{this.getDesktopLayout(matchProps)}</>;
                    }}
                />
            </RevelockPositionProvider>
        );
    }
}

export default withRouter(connect()(resizableRoute(EnrollmentLayout)));

import classNames from "classnames";
import { bool, func, node, number, string } from "prop-types";
import React, { Component } from "react";

import Image from "pages/_components/Image";

class IndeterminateCheckbox extends Component {
    static propTypes = {
        className: string,
        id: string.isRequired,
        label: node,
        onCheckClick: func.isRequired,
        optionsAmount: number.isRequired,
        selectedOptionsAmount: number.isRequired,
        disabled: bool,
    };

    static defaultProps = {
        className: "c-control c-control--has-icon display-flex gap-3 align-items-center position-relative",
        label: null,
        disabled: false,
    };

    render() {
        const { id, onCheckClick, selectedOptionsAmount, optionsAmount, label, className, disabled } = this.props;
        const isIndeterminate = selectedOptionsAmount > 0 && selectedOptionsAmount < optionsAmount;

        return (
            <div
                className={classNames(className, { "pointer-events-none": disabled })}
                onClick={(event) => event.stopPropagation()}>
                <input
                    id={id}
                    tabIndex="-1"
                    type="checkbox"
                    className="c-control-input"
                    onChange={onCheckClick}
                    checked={selectedOptionsAmount > 0}
                    indeterminate={`${isIndeterminate}`}
                    readOnly
                    disabled={disabled}
                />
                <label className="c-control-label" htmlFor={id}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src={isIndeterminate ? "images/indeterminate.svg" : "images/icons/checkBox.svg"} />
                        </div>
                    </div>
                    {label && <div className="form-group-text">{label}</div>}
                </label>
            </div>
        );
    }
}

export default IndeterminateCheckbox;

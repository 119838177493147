import Container from "pages/_components/Container";
import GridTable from "pages/_components/GridTable/GridTable";
import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import CreditCardMovement from "pages/creditCards/_components/Movement";
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as creditCardsActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as creditCardMovementsActions } from "reducers/creditCardMovementDetails";
import * as configUtil from "util/config";

class CreditCardMovements extends Component {
    fetchMoreMovements = () => {
        const { idProduct, pageNumber, dispatch, offset, totalCountOnline, filters } = this.props;

        if (this.fetchNeeded()) {
            const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
            const movementsPerPage = configUtil.getInteger("movementsPerPage");

            const missing =
                offset !== 0
                    ? movementsPerPage
                    : movementsPerPage - (totalCountOnline - (movementsFirstPage % movementsPerPage));

            const newFilters = { ...filters, pageNumber: pageNumber + 1, offset, quantity: missing };

            dispatch(creditCardsActions.fetchMoreMovementsRequest(idProduct, newFilters));
        } else {
            dispatch(creditCardsActions.noFetchMovements(idProduct, pageNumber + 1, true));
        }
    };

    columnsTypes = () => {
        const { isDesktop } = this.props;
        const operation = isDesktop ? "1fr 2fr 1fr 1fr" : "1fr minmax(4rem, auto) 1.75rem";
        return operation;
    };

    handleMovementClick = (movement) => {
        const { dispatch, history, creditCardParams } = this.props;
        history.push(`/creditCards/${movement.idProduct}/${movement.idStatement}`);
        const movementWithCTParams = {
            ...movement,
            ...creditCardParams,
        };
        dispatch(creditCardMovementsActions.setSelectedMovement(movementWithCTParams));
    };

    renderItem = (movement) => {
        const { isDesktop, isTablet } = this.props;
        return (
            <GridTable.Container
                key={movement.idStatement}
                className="product-data"
                columnsTemplate={this.columnsTypes("creditCard", isDesktop, isTablet)}
                onClick={() => this.handleMovementClick(movement)}>
                <CreditCardMovement movement={movement} isDesktop={isDesktop} />
            </GridTable.Container>
        );
    };

    fetchNeeded = () => {
        const { movements, pageNumber } = this.props;
        const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
        const movementsPerPage = configUtil.getInteger("movementsPerPage");

        const max = movementsFirstPage + pageNumber * movementsPerPage;
        return movements.length < max;
    };

    hasMoreMovements = () => {
        const { hasMoreMovements } = this.props;
        const { pageNumber, totalCountOnline } = this.props;
        const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
        const movementsPerPage = configUtil.getInteger("movementsPerPage");
        const max = movementsFirstPage + (pageNumber - 1) * movementsPerPage;
        return totalCountOnline > max || hasMoreMovements;
    };

    getMovements = () => {
        const { movements, pageNumber, totalCountOnline } = this.props;

        const movementsFirstPage = configUtil.getInteger("movementsFirstPage");
        const movementsPerPage = configUtil.getInteger("movementsPerPage");

        const max = movementsFirstPage + (pageNumber - 1) * movementsPerPage;

        if (totalCountOnline < max) {
            return movements;
        }

        return movements.slice(0, max);
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop } = this.props;
        const isEmpty = list.length === 0;
        return (
            <Container className="container--layout scrollable movements-container">
                <GridTable>
                    <GridTable.Header>
                        <GridTable.Container columnsTemplate={this.columnsTypes()}>
                            {isDesktop ? (
                                <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                    <I18n id="creditCards.movement.detail.date" />
                                </GridTable.Data>
                            ) : null}
                            <GridTable.Data columnStart={isDesktop ? 2 : 1} alignX="flex-start" inHeader>
                                <I18n id="creditCards.movement.detail.description" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={isDesktop ? 3 : 2} alignX="flex-end" inHeader>
                                <I18n id="creditCards.movement.detail.amount" />
                            </GridTable.Data>
                        </GridTable.Container>
                    </GridTable.Header>

                    <GridTable.Body>
                        {list}
                        {!isEmpty && renderLoadMore()}
                    </GridTable.Body>
                </GridTable>
            </Container>
        );
    };

    render() {
        const { isFetchingMovements, filters } = this.props;

        return (
            <ProductList
                fetching={isFetchingMovements}
                items={this.getMovements()}
                renderItem={this.renderItem}
                lastPage={!this.hasMoreMovements()}
                onLoadMoreClick={this.fetchMoreMovements}
                filters={filters}
                noMoreDataText="creditCards.movements.noMoreMovements"
                loadMoreText="creditCard.searchMovements"
                noDataText="creditCards.movements.none"
                noFiltersDataText="creditCards.movements.none.filter">
                {this.renderList}
            </ProductList>
        );
    }
}

CreditCardMovements.propTypes = {
    dispatch: func.isRequired,
    idProduct: string.isRequired,
    movements: shape({}).isRequired,
    hasMoreMovements: bool.isRequired,
    pageNumber: number.isRequired,
    isFetchingMovements: bool.isRequired,
    filters: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    totalCountOnline: number,
    offset: number,
    history: shape({
        push: func.isRequired,
    }).isRequired,
    creditCardParams: shape({}).isRequired,
};

CreditCardMovements.defaultProps = {
    totalCountOnline: 0,
    offset: 0,
};

const mapStateToProps = (state) => ({
    movements: creditCardSelectors.getMovements(state),
    hasMoreMovements: creditCardSelectors.isHasMoreMovements(state),
    pageNumber: creditCardSelectors.getPageNumber(state),
    isFetchingMovements: creditCardSelectors.isFetchingMovements(state),
    totalCountOnline: creditCardSelectors.getTotalCount(state),
    filters: creditCardSelectors.getFilters(state),
    offset: creditCardSelectors.getOffset(state),
});

export default withRouter(connect(mapStateToProps)(CreditCardMovements));

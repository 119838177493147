// Dashboard Tour steps Mobile
// eslint-disable-next-line import/prefer-default-export
export const dashboardTourStepsMobile = [
    {
        image: "images/mobileDashboardTour/step1.svg",
        title: "dashboardTour.mobile.title.step1",
        content: "dashboardTour.mobile.text.step1",
    },
    {
        image: "images/mobileDashboardTour/step2.svg",
        title: "dashboardTour.mobile.title.step2",
        content: "dashboardTour.mobile.text.step2",
    },
    {
        image: "images/mobileDashboardTour/step3.svg",
        title: "dashboardTour.mobile.title.step3",
        content: "dashboardTour.mobile.text.step3",
    },
    {
        image: "images/mobileDashboardTour/step4.svg",
        title: "dashboardTour.mobile.title.step4",
        content: "dashboardTour.mobile.text.step4",
    },
    {
        image: "images/mobileDashboardTour/step5.svg",
        content: "dashboardTour.mobile.text.step5",
    },
];
// eslint-disable-next-line import/prefer-default-export
export const corporateDashboardTourStepsMobile = [
    {
        image: "images/mobileDashboardTour/corporateStep1.svg",
        title: "dashboardTour.mobile.title.corporateStep1",
        content: "dashboardTour.mobile.text.corporateStep1",
    },
    {
        image: "images/mobileDashboardTour/corporateStep2.svg",
        title: "dashboardTour.mobile.title.corporateStep2",
        content: "dashboardTour.mobile.text.corporateStep2",
    },
    {
        image: "images/mobileDashboardTour/corporateStep3.svg",
        title: "dashboardTour.mobile.title.corporateStep3",
        content: "dashboardTour.mobile.text.corporateStep3",
    },
    {
        image: "images/mobileDashboardTour/corporateStep4.svg",
        title: "dashboardTour.mobile.title.corporateStep4",
        content: "dashboardTour.mobile.text.corporateStep4",
    },
    {
        image: "images/mobileDashboardTour/corporateStep5.svg",
        title: "dashboardTour.mobile.title.corporateStep5",
        content: "dashboardTour.mobile.text.corporateStep5",
    },
    {
        image: "images/mobileDashboardTour/corporateStep6.svg",
        title: "dashboardTour.mobile.title.corporateStep6",
        content: "dashboardTour.mobile.text.corporateStep6",
    },
    {
        image: "images/mobileDashboardTour/corporateStep7.svg",
        content: "dashboardTour.mobile.text.corporateStep7",
    },
];

/* eslint-disable react/no-danger */
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { replace } from "react-router-redux";
import { push } from "react-router-redux/actions";
import { actions as notificationActions } from "reducers/notification";
import { selectors as kuaraSelectors, actions as kuaraActions } from "reducers/kuara";
import { compose } from "redux";
import * as config from "util/config";
import { get as getMessage } from "util/i18n";

const EnrollmentKuara = (props) => {
    const { dispatch, fetching, accounts, activeOtherBank } = props;

    const accountStatus = {
        Actived: "ACTIVA",
        Inactived: "INACTIVA",
        NoActived: "NOACTIVA",
        NoAccount: "NOACCOUNT",
        IsMancomunate: "MANCOMUNADA",
        IsPhoneValid: "PHONE",
    };

    useEffect(() => {
        dispatch(kuaraActions.listAccountRequest());
    }, []);

    const isValidAccounts = () => {
        let validateAccount = null;

        validateAccount = accounts.length === 0 ? accountStatus.NoAccount : validateAccount;

        validateAccount = accounts.some((account) => account.status === accountStatus.Inactived)
            ? accountStatus.Inactived
            : validateAccount;

        validateAccount = accounts.some((account) => account.status === accountStatus.NoActived)
            ? accountStatus.NoActived
            : validateAccount;

        validateAccount = accounts.some((account) => account.status === accountStatus.Actived)
            ? accountStatus.Actived
            : validateAccount;

        validateAccount =
            accounts.length === 1 && accounts[0].IsMancomunate ? accountStatus.IsMancomunate : validateAccount;

        switch (validateAccount) {
            case accountStatus.NoAccount:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {getMessage("kuara.validate.NoAccount")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                            .
                        </div>,
                        "error",
                        ["kuara"],
                        true,
                    ),
                );
                break;
            case accountStatus.Actived:
                dispatch(push("/EnrollmentKuaraSelectAccount"));
                break;
            case accountStatus.Inactived:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: getMessage("kuara.validate.Inactived") }} />
                            <Button
                                label="kuara.validate.Inactived.link"
                                externalHref={config.get("accessBar.chat.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["kuara"],
                        true,
                    ),
                );
                break;
            case accountStatus.NoActived:
                dispatch(
                    notificationActions.showNotification(
                        getMessage("kuara.validate.NoActived"),
                        "error",
                        ["kuara"],
                        true,
                    ),
                );
                break;
            case accountStatus.IsMancomunate:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {getMessage("kuara.validate.IsMancomunate")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["kuara"],
                        true,
                    ),
                );
                break;

            case accountStatus.IsPhoneValid:
                dispatch(
                    notificationActions.showNotification(
                        getMessage("kuara.validate.IsPhoneValid"),
                        "error",
                        ["kuara"],
                        true,
                    ),
                );
                break;

            default:
                break;
        }
    };

    const handleBack = () => {
        dispatch(replace("/desktop"));
    };

    return (
        <PageLoading loading={fetching} classicStyle={false}>
            <Head wally onBack={handleBack} title="kuara.header.activation" additionalClassName="kuara-header" />
            <Notification scopeToShow="kuara" />
            <MainContainer className="pb-0">
                <Box display="flex" column alignX="between" fullWidth fullHeight>
                    <Box
                        display="flex"
                        background="kuara-secondary"
                        alignX="center"
                        alignY="flex-start"
                        className="py-10 px-10 mx-n-5"
                        fullHeight>
                        <Image src="images/logos/kuaraHorizontal.svg" height="9" />
                    </Box>
                    <Box
                        display="flex"
                        column
                        alignX="center"
                        alignY="center"
                        position="relative"
                        background="component-background"
                        className="px-5 pb-9 mx-n-5"
                        fullHeight>
                        <Box position="absolute" bottom="100" fullWidth>
                            <Box display="flex" alignX="center" top="50" position="absolute" fullWidth>
                                {/* TODO (LABEL KUARA) */}
                                <Text
                                    labelKey={
                                        activeOtherBank ? "kuara.welcome.subtitle.otherBank" : "kuara.welcome.subtitle"
                                    }
                                    bold
                                    size="1"
                                    align="center"
                                    color={activeOtherBank ? "heading-color" : "kuara-primary"}
                                />
                            </Box>
                            <Box display="flex" fullWidth>
                                <Image src="images/logos/kuaraLogoBGUp.svg" color="text-inverse-color" width="100" />
                            </Box>
                        </Box>

                        <Box display="flex" column alignX="center" alignY="center" gap="7" className="pb-10" fullHeight>
                            {/* TODO (LABEL KUARA) */}
                            <Text
                                labelKey={
                                    activeOtherBank ? "kuara.welcome.content1.otherBank" : "kuara.welcome.content1"
                                }
                                color={activeOtherBank ? "heading-color" : "kuara-primary"}
                                size="3"
                                align="center"
                                {...(!activeOtherBank && { bold: true })}
                            />
                            {/* TODO (LABEL KUARA) */}
                            <Text
                                labelKey={
                                    activeOtherBank ? "kuara.welcome.content2.otherBank" : "kuara.welcome.content2"
                                }
                                color={activeOtherBank ? "heading-color" : "kuara-primary"}
                                size="3"
                                align="center"
                                {...(!activeOtherBank && { bold: true })}
                            />
                        </Box>

                        {(activeOtherBank === undefined ||
                            activeOtherBank === null ||
                            activeOtherBank === false ||
                            activeOtherBank === "false") && (
                            <Button
                                label="kuara.welcome.button"
                                bsStyle="primary"
                                onClick={() => isValidAccounts()}
                                block
                                className="mb-10"
                            />
                        )}
                    </Box>
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    accounts: kuaraSelectors.getAccountListKuara(state),
    fetching: kuaraSelectors.isFetching(state),
    isPhoneValid: kuaraSelectors.getIsPhoneValid(state),
    activeOtherBank: kuaraSelectors.getActiveOtherBank(state),
});

EnrollmentKuara.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    accounts: arrayOf(shape({ length: number })),
    isPhoneValid: bool,
    activeOtherBank: bool,
};

EnrollmentKuara.defaultProps = { fetching: true, accounts: [], isPhoneValid: false, activeOtherBank: null };

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(EnrollmentKuara));

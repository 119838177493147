/* eslint-disable*/
import * as tour from "middleware/tour";
import { types, actions } from "reducers/tour";
import { call, put, takeLatest } from "redux-saga/effects";
import { types as desktopTypes } from "reducers/desktop";

const sagas = [takeLatest(types.ADD_SKIP_REQUEST, addSkip)];

export default sagas;

function* addSkip({ number, onFinish }) {
    const response = yield call(tour.addSkip, number);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put({ type: types.ADD_SKIP_SUCCESS, skipsCounter: response.data.data.skipsCounter });
        yield put({ type: desktopTypes.LOAD_LAYOUT_REQUEST });
    } else {
        yield put({ type: types.ADD_SKIP_FAILURE });
    }
    typeof onFinish === "function" && onFinish();
}

import React, { Component } from "react";
import { string, func } from "prop-types";

import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";

class SettingsOption extends Component {
    static propTypes = {
        linkTo: string,
        messageKey: string.isRequired,
        onClick: func,
    };

    static defaultProps = {
        linkTo: null,
        onClick: null,
    };

    render() {
        const { linkTo, messageKey, onClick } = this.props;

        return (
            <Box component="li" noList fullWidth>
                <Button
                    bsStyle="item"
                    {...(linkTo && { href: linkTo })}
                    {...(onClick && { onClick })}
                    label={messageKey}
                    className="full-width mb-3">
                    <Image src="images/chevromRight.svg" className="svg-icon svg-caret" />
                </Button>
            </Box>
        );
    }
}

export default SettingsOption;

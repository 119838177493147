import Box from "pages/_components/Box";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Disclaimer from "pages/_components/Disclaimer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { actions as userActions, selectors as userSelectors } from "reducers/administration/users";

import { detailsSelectors } from "reducers/administration";
import { connect } from "react-redux";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { goBack, push } from "react-router-redux";
import { USER_TOKEN_STATUS_PENDING_APROVE, USER_TOKEN_STATUS_PENDING_UNLOCK } from "util/userToken.util";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const FORM_ID = "administration.userToken";

const TokenConfirm = (props) => {
    const { dispatch, fetching, credentialGroups, user, userToken, setLocationCustomPath } = props;

    const setRevelockLocation = () => {
        if (setLocationCustomPath) {
            setLocationCustomPath("/", FORM_ID);
        }
    };

    useEffect(() => {
        setRevelockLocation();

        if (!Object.keys(user).length) {
            dispatch(goBack());
        } else {
            dispatch(userActions.getUserTokenRequest(user.idUser));
        }
    }, []);

    const renderFormImg = () => (
        <Container className="container--layout items-center" gridClassName="form-content container--flex-middle">
            <Row>
                <Col xs={12} lg={6}>
                    {userToken?.selfieImg && (
                        <Box className="my-5 selfie-image-wraper">
                            <img src={`data:image/jpeg;base64,${userToken.selfieImg}`} alt="" />
                        </Box>
                    )}
                </Col>
                <Col xs={12} lg={6}>
                    {userToken?.documentImg && (
                        <Box className="my-5" fullWidth>
                            <img
                                src={`data:image/jpeg;base64,${userToken.documentImg}`}
                                alt=""
                                className="full-width border-radius-md"
                            />
                        </Box>
                    )}
                </Col>
            </Row>
        </Container>
    );

    const handleSubmit = (data, formikBag) => {
        const { actions, formActions, idTransaction, idActivity } = props;
        const { signatureLevel, ...credentials } = data;
        if (!idTransaction) {
            actions.activateUserTokenRequest(
                {
                    credentials,
                    idUser: user.idUser,
                    fullName: user.fullName,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    const handleBack = () => {
        dispatch(goBack());
    };

    const handleCancel = () => {
        if (
            userToken?.status === USER_TOKEN_STATUS_PENDING_APROVE ||
            userToken?.status === USER_TOKEN_STATUS_PENDING_UNLOCK
        ) {
            const { actions } = props;
            actions.rejectUserTokenRequest({
                idUser: user.idUser,
                fullName: user.fullName,
            });
        } else {
            dispatch(push(`/administration/medium/details/${user.idUser}`));
        }
    };

    const getLabelCancel = () => {
        let label = "global.cancel";
        if (
            userToken?.status === USER_TOKEN_STATUS_PENDING_APROVE ||
            userToken?.status === USER_TOKEN_STATUS_PENDING_UNLOCK
        ) {
            label = "global.reject";
        }

        return label;
    };

    return (
        <>
            <Head title={`${FORM_ID}.title`} onBack={handleBack} />

            <MainContainer showLoader={fetching && !Object.keys(user).length}>
                <Row>
                    <Col xs={8} xsOffset={2} className="pb-80">
                        <Disclaimer
                            styled="warning"
                            labelKey={`${FORM_ID}.warning`}
                            image="images/icons/warning.svg"
                            borderRadius="top-default"
                        />
                        <Box
                            display="flex"
                            column
                            background="component-background"
                            borderRadius="bottom-default"
                            className="px-80 pt-8 pb-9">
                            <Box display="flex" className="pb-5 mb-12" border="bottom-border-base-color-1">
                                <Text
                                    component="h2"
                                    labelKey={`${FORM_ID}.subtitle`}
                                    className="m-0"
                                    size="5"
                                    color="heading-color"
                                    bold
                                />
                            </Box>

                            <Box display="flex" alignX="center" className="mb-2 py-5" fullWidth>
                                <Text labelKey="administration.channels.user" color="primary" bold addColon />
                                <Text color="primary">{user.fullName}</Text>
                            </Box>
                            {renderFormImg()}
                            <AdministrationFormConfirmation
                                otherInitialValues={{}}
                                credentialGroups={credentialGroups}
                                onSubmit={handleSubmit}
                                onClickCancel={handleCancel}
                                labelCancel={getLabelCancel()}
                            />
                        </Box>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};
TokenConfirm.propTypes = {
    user: shape({ firstName: string, lastName: string }).isRequired,
    fetching: bool,
    credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
    userToken: shape({}).isRequired,
    dispatch: func.isRequired,
    idActivity: string,
    idTransaction: string,
    actions: func.isRequired,
    formActions: func.isRequired,
    setLocationCustomPath: func.isRequired,
};

TokenConfirm.defaultProps = {
    fetching: false,
    idActivity: null,
    idTransaction: null,
};

const mapStateToProps = (state) => ({
    userToken: userSelectors.getUserToken(state),
    user: detailsSelectors.getUser(state),
    credentialGroups: detailsSelectors.getCredentialGroups(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(TokenConfirm));

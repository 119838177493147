import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import PhoneInput from "pages/_components/fields/PhoneInput";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TokenButton from "pages/_components/TokenButton";
import { bool, func, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { buildEntrustKey, buildEnvironmentKey } from "util/softToken.util";

const changePhoneNumberStep1 = ({ dispatch, history, fetching }) => {
    const handleBack = () => {
        history.goBack();
    };
    return (
        <PageLoading loading={fetching}>
            <Head titleImage="wally-head.svg" onBack={handleBack} title="ajustes wally" />
            <MainContainer>
                <Box display="flex" alignX="center" fullWidth className="pt-8 mb-7">
                    <Image src="images/smartphone-wally.svg" isMobileNative />
                </Box>
                <Text
                    /* TODO : label */
                    labelKey="Cambiar número de celular"
                    bold
                    size="1"
                    align="center"
                    color="heading"
                    className="mb-3"
                />
                <Text
                    /* TODO : label */
                    labelKey="Este es el nuevo número de celular que estará asociado a tu cuenta."
                    align="center"
                    size="4"
                    className="px-3 mb-5"
                />
                <Formik
                    onSubmit={() => {
                        dispatch(push("/changePhoneNumberStep2"));
                    }}>
                    <Form className="full-height">
                        <Box display="flex" column fullHeight>
                            <Box background="white" className="pt-1 pb-9 px-5 mb-5" borderRadius="default">
                                {/* TODO : label */}
                                <Field name="account" component={PhoneInput} country="pa" labelText="Telefono movil" />
                            </Box>
                            <Box display="flex" alignX="center" className="mb-7">
                                <TokenButton
                                    entrustKey={buildEntrustKey() || ""}
                                    environmentType={buildEnvironmentKey() || ""}
                                />
                            </Box>
                            <Box display="flex" column className="mt-auto">
                                <Button bsStyle="primary" block type="submit" href="/changePhoneNumberStep2">
                                    {/* TODO : label */}
                                    asociar nuevo numero
                                </Button>
                                <Button label="wally.select.button.cancel" bsStyle="outline" block />
                            </Box>
                        </Box>
                    </Form>
                </Formik>
            </MainContainer>
        </PageLoading>
    );
};

changePhoneNumberStep1.propTypes = { dispatch: func.isRequired, history: shape({}).isRequired, fetching: bool };

changePhoneNumberStep1.defaultProps = { fetching: false };

export default withRouter(connect()(resizableRoute(changePhoneNumberStep1)));

import classNames from "classnames";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, node, oneOfType, shape, string } from "prop-types";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as momentDayActions, selectors as momentDaySelectors } from "reducers/momentDay";
import imageMomentDay from "util/momentday/resources";

const MomentDay = (props) => {
    const {
        dispatch,
        isMobile,
        momentDay,
        className,
        children,
        pageStep0,
        style,
        withoutBackgroundMobile: propWithoutBackgroundMobile,
        isActiveCorporate,
        noImageBG,
    } = props;

    useEffect(() => {
        const hour = new Date().getHours();
        const defineMomentDay =
            (hour >= 6 && hour < 12 && "morning") || (hour >= 12 && hour < 18 && "afternoon") || "night";
        dispatch(momentDayActions.setMomentDay(defineMomentDay));
    }, [dispatch]);
    const imageSrc = isMobile ? imageMomentDay[`${momentDay}Mobile`] : `/${imageMomentDay[momentDay]}`;
    const imageBG = isMobile
        ? imageMomentDay[`${momentDay}MobileCorporateBG`]
        : `/${imageMomentDay[`${momentDay}CorporateBG`]}`;
    const logoSrc = imageMomentDay.logo;

    const withoutBackgroundMobile = propWithoutBackgroundMobile && isMobile;
    return (
        <Fragment>
            <PageLoading loading={!momentDay} classicStyle={false}>
                {momentDay && (
                    <div className={className} style={style}>
                        <div
                            className={classNames("login-background-wrapper", {
                                "background-and-logo":
                                    !pageStep0 && isMobile && !withoutBackgroundMobile && !isActiveCorporate,
                            })}>
                            {children}
                            {!isMobile && !isActiveCorporate ? (
                                <img className="position-absolute z-index-1" src={imageSrc} alt={`${momentDay}`} />
                            ) : null}

                            {(!isMobile || isActiveCorporate) && !noImageBG && !withoutBackgroundMobile ? (
                                <img
                                    className={classNames({
                                        "mix-blend-mode-screen": !isActiveCorporate,
                                        "img-is-corporate": isActiveCorporate,
                                    })}
                                    src={isActiveCorporate ? imageBG : logoSrc}
                                    alt={isActiveCorporate ? `${momentDay}` : "banesco logo"}
                                />
                            ) : null}
                        </div>
                    </div>
                )}
            </PageLoading>
        </Fragment>
    );
};

MomentDay.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    className: string,
    children: oneOfType([arrayOf(node), node]),
    style: shape({}),
    pageStep0: bool,
    momentDay: string,
    withoutBackgroundMobile: bool,
    isActiveCorporate: bool.isRequired,
    noImageBG: bool,
};
MomentDay.defaultProps = {
    className: "",
    children: {},
    style: {},
    pageStep0: false,
    momentDay: "",
    withoutBackgroundMobile: false,
    noImageBG: false,
};
const mapStateToProps = (state) => ({
    momentDay: momentDaySelectors.getMomentDay(state) || "",
});

export default withRouter(connect(mapStateToProps)(resizableRoute(MomentDay)));

import classNames from "classnames";
import Box from "pages/_components/Box";
import I18n from "pages/_components/I18n";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { bool, string, node } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class FieldLabel extends Component {
    static propTypes = {
        hideLabel: bool,
        idField: string,
        isLegend: bool,
        labelClassName: string,
        labelKey: string,
        labelNoMarginTop: bool,
        labelText: string,
        mode: string,
        optional: string,
        renderLabel: bool,
        tooltipInfo: node,
        tooltipLightSyled: bool,
        tooltipPosition: string,
        tooltipText: string,
        htmlFor: string,
        uppercase: bool,
        dataLabelSpecialMb: bool,
    };

    static defaultProps = {
        hideLabel: false,
        idField: null,
        isLegend: false,
        labelClassName: null,
        labelKey: null,
        labelNoMarginTop: false,
        labelText: null,
        mode: null,
        optional: null,
        renderLabel: true,
        tooltipInfo: null,
        tooltipLightSyled: false,
        tooltipPosition: null,
        tooltipText: null,
        htmlFor: null,
        uppercase: false,
        dataLabelSpecialMb: true,
    };

    getFocus = (id) => {
        const element = document.getElementById(id) || document.getElementById(`react-select-${id}--value`);
        if (
            element &&
            element.nodeName !== "INPUT" &&
            element.nodeName !== "TEXTAREA" &&
            element.nodeName !== "SELECT"
        ) {
            element.querySelector(".Select-input").focus();
        }
    };

    render() {
        const {
            idField,
            labelKey,
            labelText,
            mode,
            optional,
            hideLabel,
            isLegend,
            labelClassName,
            labelNoMarginTop,
            renderLabel,
            tooltipText,
            tooltipInfo,
            tooltipLightSyled,
            tooltipPosition,
            htmlFor,
            uppercase,
            dataLabelSpecialMb,
        } = this.props;
        const LabelTag = isLegend ? "legend" : "label";

        if (mode === "edit") {
            return (
                <div
                    className={classNames("form-group-text", { "visually-hidden": hideLabel })}
                    role="presentation"
                    onClick={() => this.getFocus(idField)}>
                    <LabelTag
                        id={`${LabelTag}.${idField}`}
                        className={classNames("control-label", {
                            "visually-hidden": isLegend,
                            "data-label-no-margin-top": labelNoMarginTop,
                        })}
                        htmlFor={htmlFor || idField}>
                        {labelKey ? <I18n id={labelKey} /> : labelText}
                        {optional && <small className="text-optional">{optional}</small>}
                    </LabelTag>
                    {isLegend && (
                        <label
                            id={`labelLegend.${idField}`}
                            className={classNames("control-label", {
                                "data-label-no-margin-top": labelNoMarginTop,
                            })}
                            htmlFor={htmlFor || idField}>
                            {labelKey ? <I18n id={labelKey} /> : labelText}
                            {optional && <small className="text-optional">{optional}</small>}
                        </label>
                    )}
                </div>
            );
        }

        return renderLabel ? (
            <Box display="flex" alignY="flex-end" {...(dataLabelSpecialMb && { className: "data-label-special-mb" })}>
                {labelKey ? (
                    <Text
                        component="label"
                        className={classNames("data-label", labelClassName, {
                            "data-label-no-margin-top": labelNoMarginTop,
                        })}
                        htmlFor={htmlFor || idField}
                        uppercase={uppercase}>
                        {i18n.get(labelKey)}
                        {optional && (
                            <Text size="5" color="text" light>
                                {" "}
                                ({optional})
                            </Text>
                        )}
                    </Text>
                ) : (
                    <Text
                        component="label"
                        className={classNames("data-label", labelClassName, {
                            "data-label-no-margin-top": labelNoMarginTop,
                        })}
                        htmlFor={htmlFor || idField}
                        uppercase={uppercase}>
                        {labelText}
                        {optional && (
                            <Text size="5" color="text" light>
                                {" "}
                                ({optional})
                            </Text>
                        )}
                    </Text>
                )}
                {(tooltipText || tooltipInfo) && (
                    <Tooltip
                        tooltipLightSyled={tooltipLightSyled}
                        text={tooltipText}
                        tooltipInfo={tooltipInfo}
                        position={tooltipPosition}
                    />
                )}
            </Box>
        ) : null;
    }
}

export default FieldLabel;

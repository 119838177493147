import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { string, shape, bool } from "prop-types";
import React from "react";

const Disclaimer = ({
    alignY,
    borderRadius,
    className,
    iconClassname,
    image,
    labelKey,
    notBorder,
    padding,
    styled,
    textParams,
    textSize,
    textColor,
    background,
    fullWidth,
}) => (
    <Box
        display="flex"
        alignY={alignY}
        borderRadius={borderRadius}
        {...(styled && notBorder && { background: styled })}
        {...(fullWidth && { fullWidth: true })}
        {...(styled && !notBorder && { border: `${styled}-1`, background: styled })}
        {...(background && { background })}
        className={classNames(padding, className)}>
        <Box display="flex" className="pt-1">
            <Image
                // eslint-disable-next-line no-nested-ternary
                src={image || (styled ? `images/icons/${styled}.svg` : `images/icons/tooltip.svg`)}
                className={classNames("mr-3", iconClassname)}
            />
        </Box>
        <Text textParams={textParams} size={textSize} color={textColor} labelKey={labelKey} />
    </Box>
);

Disclaimer.propTypes = {
    alignY: string,
    borderRadius: string,
    className: string,
    iconClassname: string,
    image: string,
    labelKey: string,
    notBorder: bool,
    padding: string,
    rest: shape(),
    styled: string,
    textParams: string,
    textSize: string,
    textColor: string,
    background: string,
    fullWidth: bool,
};

Disclaimer.defaultProps = {
    alignY: "center",
    borderRadius: "md",
    className: null,
    iconClassname: undefined,
    image: null,
    labelKey: null,
    notBorder: false,
    padding: "p-3 px-5 p-md-5",
    rest: null,
    styled: null,
    textParams: null,
    textSize: "6",
    textColor: "heading",
    background: null,
    fullWidth: false,
};

export default Disclaimer;

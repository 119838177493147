import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux/actions";
import { actions as desktopActions } from "reducers/desktop";
import { actions } from "reducers/widgets";

const DeactivationKuara = ({ dispatch, fetching }) => {
    const handleBack = () => {
        dispatch(push("/kuara"));
    };

    const redirectDesktop = () => {
        dispatch(desktopActions.setLayout(null));
        dispatch(actions.setListAccountWidget());
        dispatch(replace("/desktop"));
    };

    return (
        <PageLoading loading={fetching}>
            <Head onBack={handleBack} title="kuara.settings.title" additionalClassName="kuara-header" />
            <MainContainer>
                <Box display="flex" column fullHeight>
                    <Box display="flex" column alignX="between" alignY="center" className="pt-8 mb-9">
                        <Image
                            src="images/smartphone-cancel-wally.svg"
                            color="kuara-secondary"
                            isMobileNative
                            height="12"
                            className="mb-9"
                        />

                        <Box
                            display="flex"
                            column
                            alignY="center"
                            alignX="center"
                            background="white"
                            fullWidth
                            borderRadius="xxl"
                            className="pt-11 pb-10 mb-9">
                            <Text
                                labelKey="kuara.settings.deactivate.confirmation.text1"
                                size={4}
                                align="center"
                                className="px-5 mb-3"
                            />
                            <Box display="flex">
                                <Image src="images/logos/kuaraHorizontalSmall.svg" height="6" />
                            </Box>
                        </Box>
                        <Text
                            labelKey="kuara.settings.deactivate.confirmation.text2"
                            align="center"
                            size={3}
                            color="heading"
                        />
                    </Box>

                    <Button
                        label="global.goToDesktop"
                        bsStyle="outline"
                        block
                        className="mb-6 mt-auto"
                        onClick={redirectDesktop}
                    />
                </Box>
            </MainContainer>
        </PageLoading>
    );
};

DeactivationKuara.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
};

DeactivationKuara.defaultProps = { fetching: false };

export default connect()(resizableRoute(DeactivationKuara));

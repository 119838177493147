import React, { Component } from "react";
import { bool, shape, string, func } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import moment from "moment";

import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import * as Yup from "yup";
import { resizableRoute } from "pages/_components/Resizable";

import { actions as transactionsActions } from "reducers/transactions";
import { CORPORATE_ENVIRONMENT_TYPE } from "constants.js";

import * as configUtil from "util/config";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import DateField from "pages/_components/fields/DateField";

import * as i18n from "util/i18n";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";

const FORM_ID = "transactions.list.filters.period";

const getDefaultDate = (historic, type, activeEnvironment) => {
    const isCorporate = activeEnvironment?.type === CORPORATE_ENVIRONMENT_TYPE;

    const date = isCorporate
        ? configUtil.get("transaction.list.disclaimer.date.corporate")
        : configUtil.get("transaction.list.disclaimer.date");
    if (!date) {
        return null;
    }

    const sections = date.split("/");
    if (sections.length <= 2) {
        return null;
    }

    let resultDate = moment(`${sections[1]}/${sections[0]}/${sections[2]}`);

    if (historic && type === "min") {
        resultDate = resultDate.add(-12, "months");
    }

    if (type === "max" && !historic) {
        resultDate = moment();
    }

    return resultDate;
};

const validateFilterPeriod = (filters, formikBag) => {
    const { dateFromIsRequired } = formikBag.props;
    const { dateFrom, dateTo } = filters;
    let filtersAux = filters;
    if (dateFromIsRequired && dateFrom === null && dateTo !== null) {
        filtersAux = {
            ...filtersAux,
            dateFrom: dateTo,
        };
    }
    return filtersAux;
};

class PeriodFilter extends Component {
    state = {
        selectedDateFrom: null,
    };

    static propTypes = {
        fetching: bool,
        values: shape({}),
        selectedOperationType: string.isRequired,
        setFieldValue: func.isRequired,
        isDesktop: bool.isRequired,
        setDateFrom: func,
        setDateTo: func,
        historic: bool,
        dateFromIsRequired: bool,
        setFilterValues: func.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
    };

    static defaultProps = {
        fetching: false,
        values: shape({
            dateFrom: null,
            dateTo: null,
        }),
        setDateFrom: () => {},
        setDateTo: () => {},
        historic: false,
        dateFromIsRequired: false,
    };

    componentDidUpdate(prevProps, prevState) {
        const { selectedOperationType, setFieldValue, dateFromIsRequired } = this.props;
        const { selectedDateFrom } = this.state;

        if (selectedOperationType !== prevProps.selectedOperationType) {
            setFieldValue("operationType", selectedOperationType);
        }

        if (dateFromIsRequired && selectedDateFrom !== prevState.selectedDateFrom) {
            const sections = selectedDateFrom.split("/");
            const dateFromAux = moment(`${sections[0]}/${sections[1]}/${sections[2]}`);
            setFieldValue("dateFrom", dateFromAux);
        }
    }

    handleChangeDateFrom = (selectedDate) => {
        const { setDateFrom, dateFromIsRequired, setFilterValues } = this.props;
        if (dateFromIsRequired && setDateFrom && selectedDate) {
            const selectedDateFromAux = selectedDate.format("YYYY/MM/DD");
            setDateFrom(selectedDateFromAux);
            setFilterValues(selectedDate.toDate(), undefined);
            this.setState({ selectedDateFrom: selectedDateFromAux });
        } else if (setDateFrom && selectedDate) {
            setDateFrom(selectedDate);
            setFilterValues(selectedDate.toDate(), undefined);
            this.setState({ selectedDateFrom: selectedDate });
        }
    };

    handleChangeDateTo = (selectedDate) => {
        const { setDateTo, dateFromIsRequired, setFilterValues } = this.props;
        if (setDateTo && selectedDate) {
            setDateTo(selectedDate.format("YYYY-MM-DD"));
            setFilterValues(undefined, selectedDate.toDate());
            if (dateFromIsRequired) {
                this.setState({ selectedDateFrom: selectedDate.format("YYYY/MM/DD") });
            }
        }
    };

    render() {
        const {
            fetching,
            isDesktop,
            values: { dateFrom, dateTo },
            historic,
            activeEnvironment,
        } = this.props;

        const { selectedDateFrom } = this.state;
        const minDate = getDefaultDate(historic, "min", activeEnvironment);
        const maxDate = getDefaultDate(historic, "max", activeEnvironment);

        return (
            <Form>
                <Container className="container--layout items-center" gridClassName="form-content">
                    <Col xs={6} md={4} className="col-12 col-no-pad-mobile">
                        <Field
                            idField="dateFromPeriodFilter"
                            component={DateField}
                            endDate={dateTo}
                            maxDate={dateTo !== null && dateTo < maxDate ? dateTo : maxDate}
                            minDate={minDate}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="dateFrom"
                            selectsStart
                            startDate={dateFrom}
                            {...(!isDesktop && {
                                popperPlacement: "bottom-left",
                                popperModifiers: {
                                    flip: {
                                        behavior: ["bottom-left"], // don't allow it to flip to be above
                                    },
                                },
                            })}
                            handleChange={this.handleChangeDateFrom}
                            historic
                        />
                    </Col>

                    <Col xs={6} md={4} className="col-12 col-no-pad-mobile">
                        <Field
                            idField="dateToPeriodFilter"
                            component={DateField}
                            endDate={dateTo}
                            maxDate={maxDate}
                            minDate={selectedDateFrom || minDate}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="dateTo"
                            selectsEnd
                            startDate={dateFrom}
                            {...(!isDesktop && {
                                popperPlacement: "bottom-right",
                                popperModifiers: {
                                    flip: {
                                        behavior: ["bottom-right"], // don't allow it to flip to be above
                                    },
                                },
                            })}
                            calendarStartDay={3}
                            handleChange={this.handleChangeDateTo}
                            historic
                        />
                    </Col>

                    <Col xs={12} md={4} className="col-12 col-no-pad-mobile" style={{ alignSelf: "flex-end" }}>
                        <Button
                            bsStyle="primary"
                            label="product.filters.filter"
                            loading={fetching}
                            type="submit"
                            block
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        dateFrom: null,
        dateTo: getDefaultDate(props.historic, "max", props.activeEnvironment),
        operationType: props.selectedOperationType,
        isCreatedByMe: props.isCreatedByMe,
    }),
    validationSchema: (props) =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: Yup.date().when("dateTo", (dateTo, schema) => {
                    const dateFromSchema = dateTo
                        ? schema.nullable().max(dateTo, i18n.get("accounts.movements.filters.period.dateFrom.error"))
                        : schema.nullable();

                    return props.dateFromIsRequired && values.dateTo === null && values.dateFrom === null
                        ? dateFromSchema.required(i18n.get("transactions.list.filters.period.dateFrom.required.error"))
                        : dateFromSchema;
                }),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get("accounts.movements.filters.period.dateTo.error"))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            onlyProcessing,
            pendingDispatch,
            resetHandleOrder,
            historic,
            isCreatedByMe,
        } = formikBag.props;
        if (isDesktop) {
            if (historic) {
                const filtersAux = validateFilterPeriod({ ...filters, isCreatedByMe }, formikBag);
                dispatch(transactionsActions.loadHistoricListRequest(filtersAux, false, "0", formikBag));
            } else {
                dispatch(
                    transactionsActions.loadListRequest(
                        { ...filters, isCreatedByMe },
                        onlyPendings,
                        onlyProcessing,
                        pendingDispatch,
                        false,
                        formikBag,
                    ),
                );
            }
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "period",
                    ...filters,
                    isCreatedByMe,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
        resetHandleOrder();
    },
})(resizableRoute(withTransactionFilterContext(PeriodFilter)));

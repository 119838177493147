import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, number } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

const ProgramedTransactionsMigrationDisclaimer = ({
    dispatch,
    isDesktop,
    transactions,
    loadMigrationExecutionCount,
}) => {
    const actionButtonStatusLink = (urlRedirect) => {
        dispatch(
            routerActions.push({
                pathname: urlRedirect,
            }),
        );
    };

    const showButtonActionStatus = (labelAction, urlRedirect) => (
        <Button
            label={labelAction}
            type="button"
            bsStyle={isDesktop ? "outline" : "link"}
            {...(!isDesktop ? { small: true, className: "px-0" } : { className: "px-7" })}
            onClick={() => {
                actionButtonStatusLink(urlRedirect);
            }}
        />
    );

    useEffect(() => {
        if (loadMigrationExecutionCount < 3) {
            dispatch(transactionsActions.loadMigrationTransactionListRequest());
        }
    }, []);

    if (!transactions || transactions.length === 0) {
        return null;
    }

    const buttonLabel = isDesktop
        ? "scheduled.transactions.migration.disclaimer.button.label"
        : "scheduled.transactions.migration.disclaimer.button.label.mobile";

    return (
        <Box
            display="flex"
            alignY="center"
            borderRadius="default"
            {...{ border: "warning-1" }}
            {...{ background: "warning" }}
            gapX={5}
            className="pl-4 pr-6 px-md-7 py-4 mb-5 entrust-disclaimer">
            <Box display="flex" className="pt-1">
                <Image
                    src="images/icons/calendarTimeDisclaimer.svg"
                    color="warning"
                    {...(!isDesktop && { width: "6" })}
                />
            </Box>
            <Box display="flex" flex1={1}>
                <Text
                    size="6"
                    color="text-boton"
                    className="entrust-disclaimer-text mr-md-7"
                    labelKey="scheduled.transactions.migration.disclaimer.content"
                />
            </Box>
            <Box display="flex">{showButtonActionStatus(buttonLabel, "/transactions/scheduled/migrate")}</Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getMigrationTransactions(state),
    loadMigrationExecutionCount: transactionsSelectors.getLoadMigrationExecutionCount(state),
});
ProgramedTransactionsMigrationDisclaimer.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    transactions: arrayOf(shape({})).isRequired,
    loadMigrationExecutionCount: number.isRequired,
};
ProgramedTransactionsMigrationDisclaimer.defaultProps = {};

export default connect(mapStateToProps)(resizableRoute(withRouter(ProgramedTransactionsMigrationDisclaimer)));

import classNames from "classnames";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class ActiveTokenModal extends Component {
    static propTypes = {
        handleCloseModal: func,
        isDisplayed: bool,
        drawer: bool,
    };

    static defaultProps = {
        handleCloseModal: () => {},
        isDisplayed: false,
        drawer: false,
    };

    render() {
        const { isDisplayed, handleCloseModal, drawer } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                onHide={handleCloseModal}
                show={isDisplayed}
                className={classNames({ drawer })}>
                <div className="modal-container">
                    <Modal.Header closeButton>
                        <Modal.Title id="modalTitleID">
                            <Text labelKey="enrollment.finish.activateToken" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Box
                            display="flex"
                            column
                            alignY="center"
                            fullWidth
                            fullHeight
                            className="px-9"
                            overflow="auto">
                            <Box display="flex" column alignY="center" alignX="center" fullWidth className="mb-8">
                                <Text align="center" className="mb-8" labelKey="enrollment.activateToken.text1" />
                                <Text align="center" labelKey="enrollment.activateToken.text2" />
                            </Box>
                            <Box display="flex" alignX="center" alignY="center" fullWidth>
                                <Image src="images/qrCodes/qrEnollmentCode.svg" />
                            </Box>
                        </Box>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

export default ActiveTokenModal;

import { call, put, takeLatest } from "redux-saga/effects";
import { types, actions } from "reducers/administration/invitations";
import { actions as notificationActions } from "reducers/notification";
import * as administrationInvitations from "middleware/administration/invitations";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreRequest),
    takeLatest(types.RESEND_REQUEST, resendRequest),
    takeLatest(types.DELETE_REQUEST, deleteRequest),
];

export default sagas;

function* loadListRequest(params) {
    const { filters } = params;
    const response = yield call(administrationInvitations.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(actions.loadListSuccess(response.data.data));
    }
}

function* loadMoreRequest(params) {
    const { filters } = params;
    const response = yield call(administrationInvitations.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadMoreFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put({
            type: types.LOAD_MORE_SUCCESS,
            data: response.data.data,
        });
    }
}

function* resendRequest(params) {
    const { id } = params;
    const response = yield call(administrationInvitations.resendRequest, id);

    if (response.type === "W") {
        yield put(actions.resendFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("administration.users.invite.snackbar.message.resend"),
                "success",
                ["administrationUsers"],
            ),
        );
        yield put(actions.resendSuccess());
    }
}

function* deleteRequest(params) {
    const { id } = params;
    const response = yield call(administrationInvitations.deleteRequest, id);

    if (response.type === "W") {
        yield put(actions.deleteFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("administration.users.invite.snackbar.message.delete"),
                "success",
                ["administrationUsers"],
            ),
        );
        yield put(actions.deleteSuccess(response.data.data.id));
    }
}

import * as API from "middleware/api";

export const getTransactionalProfileCountries = (accountId) =>
    API.executeWithAccessToken("/transactional.profile.account.pre", {
        accountId,
    });
export const getTransactionalProfileCountriesSetting = () =>
    API.executeWithAccessToken("/transactional.profile.accounts.pre");

export const getTransactionalProfileCountriesUpdate = (accountId, countries, otp, fingerPrintToken) =>
    API.executeWithAccessToken("/transactional.profile.send", { accountId, countries, _otp: otp, fingerPrintToken });

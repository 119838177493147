import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { func, string } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";

const NextPayments = ({ dueDate, image, onClick, prodName }) => (
    <Box
        display="flex"
        alignX="between"
        alignY="between"
        background="component-background"
        border="background-divider-1"
        borderRadius="default"
        className="py-5 px-5"
        gap="3">
        <Box display="flex" gap="3">
            <Box display="flex">
                <Image
                    src={`images/icons/productType${image}.svg`}
                    className="svg-image"
                    color="primary-color"
                    wrapperWidth="8"
                    wrapperHeight="8"
                    width="8"
                    height="8"
                />
            </Box>
            <Box display="flex" column alignY="flex-start" alignX="center">
                <span className="size-7 color-heading-color align-left">
                    {i18n.get("nextPayments.desktop.initialText")}
                    <Text color="heading-color" size="7" bold align="left" className="pl-2">
                        {prodName}
                    </Text>
                    <Text color="primary" size="7" bold className="pl-2">
                        {dueDate}
                    </Text>
                </span>
            </Box>
        </Box>
        <Box display="flex" alignX="center">
            <Button bsStyle="link" label="cardBase.pay" onClick={onClick} />
        </Box>
    </Box>
);

NextPayments.propTypes = {
    dueDate: string,
    image: string,
    onClick: func,
    prodName: string,
};

NextPayments.defaultProps = {
    dueDate: null,
    image: "productTypeHouse",
    onClick: null,
    prodName: null,
};

export default NextPayments;

import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string, arrayOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getMessageRequired, validationAccount, validationAmountCreditCard } from "util/validationSchemaUtil";
import * as Yup from "yup";
import * as creditCardsUtil from "util/creditCards";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import FormTransition from "../_components/FormTransition";
import { SCHEDULER_DATE } from "../_components/_fields/ReadText";
import {
    useAmountPaymentOptions,
    useCreditCardPayment,
    useCreditCardPaySelectorOptions,
    useSelectorOptions,
    OTHER_AMOUNT,
} from "./hooks/PayCreditCardHooks";
import { useDataAmount, useGenericProps, useMetaData } from "./hooks/TransferInternalHooks";
import { PDFAmountField, PDFAmountFieldError, PDFProductSelectorField, PDFTextField } from "./PDFTicket";
import AmountPaymentTypeField, { amountDefault } from "./_customFields/AmountPaymentTypeField/AmountPaymentTypeField";

const ID_FORM = "creditCard.payment.creditCardLocal";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "forms.creditCard.payment.formName";

const PayCreditCardLocal = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        transaction,
        currentLang,
        creditCardDetail,
        fetchingDetail,
        isDesktop,
        creditCardList,
        fetchingList,
        usesJointAccount,
        data,
    } = props;
    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [dataAmount] = useDataAmount(preDataForm);
    let accountOptions = useSelectorOptions(preDataForm);
    let creditCardOptions = useCreditCardPaySelectorOptions(creditCardList);
    if (usesJointAccount) {
        const ccData = data.creditCardData;
        if (ccData) {
            creditCardOptions = { options: [{ ...ccData, id: ccData.idProduct, label: ccData.otherLabel }] };
        }
        const daData = data.debitAccountData;
        if (daData) {
            accountOptions = { options: [{ ...daData, id: daData.idProduct, label: daData.otherLabel }] };
        }
    }
    const [amountPaymentOptions, setAmountPaymentOptions] = useAmountPaymentOptions(creditCardDetail);
    const [creditCardPayment] = useCreditCardPayment(location);
    const [idCreditCardSelected, setIdCreditCardSelected] = useState("");
    const [amountDebitAccount, setAmountDebitAccount] = useState({ currency: "", quantity: 0 });
    const titleForm = TITLE_FORM;
    const [warningPaymentType, setWarningPaymentType] = useState();

    useEffect(() => {
        dispatch(creditCardActions.detailStopFetching());
    }, []);
    useEffect(() => {
        if (mode === "edit") {
            dispatch(creditCardActions.listRequest());

            const formData = { debitAccount: null };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }

        if (mode === "view") {
            setAmountPaymentOptions(() => ({
                options: [],
            }));
            setIdCreditCardSelected("");
        }
    }, [dispatch, mode, location]);

    const validationSchema = () =>
        Yup.object().shape({
            amount: validationAmountCreditCard(`forms.${ID_FORM}.amount.required`),
            debitAccount: validationAccount(`forms.${ID_FORM}.debitAccount.invalid`, [
                ...(preDataForm?.debitAccountList || []),
            ]),
            creditCard: validationAccount(
                `forms.${ID_FORM}.creditCard.invalid`,
                creditCardsUtil.getNonPrepaidCreditCardsWithAdditionals(creditCardList),
            ),
        });

    const validateCustom = (values) => {
        if (values.paymentType !== OTHER_AMOUNT) {
            if (
                amountDebitAccount?.currency === values.amount?.currency &&
                amountDebitAccount?.quantity < values.amount?.quantity
            ) {
                return { NO_FIELD: getMessageRequired(`forms.${ID_FORM}.amount.insufficient`) };
            }
            if (!values.amount?.quantity || values.amount?.quantity <= 0 || !values.amount?.currency) {
                return { NO_FIELD: getMessageRequired(`forms.${ID_FORM}.amount.required`) };
            }
        }

        return {};
    };

    const getDetailCreditCard = (idCreditCard) => {
        if (
            idCreditCard &&
            !fetchingDetail &&
            idCreditCard !== idCreditCardSelected &&
            !amountPaymentOptions?.options?.some((option) => option.idProduct === idCreditCard)
        ) {
            const creditCardRef = creditCardOptions?.options?.find(
                (item) => item?.idProduct && item.idProduct === idCreditCard,
            );

            dispatch(
                creditCardActions.detailNoMovementsRequest(
                    idCreditCard,
                    creditCardRef?.relationType,
                    creditCardRef?.hasOwnLimit,
                    ID_FORM,
                ),
            );
        }
    };

    useEffect(() => {
        if (creditCardOptions?.options && creditCardOptions.options.length > 0 && creditCardPayment) {
            getDetailCreditCard(creditCardPayment);
            setIdCreditCardSelected(creditCardPayment);
        }

        return () => dispatch(creditCardActions.detailStopFetching());
    }, [creditCardOptions]);

    const renderTicket = (values) => {
        const vaucherLabel = transaction?.idTransactionStatus === "REJECTED" ? "Reference" : "";
        const showReference =
            transaction?.idTransactionStatus === "FINISHED" || transaction?.idTransactionStatus === "REJECTED";

        return (
            <>
                {transaction?.idTransactionStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                )}

                {showReference && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.creditCard.payment.voucher${vaucherLabel}.label`)}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        transaction?.idParentTransaction
                            ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                            : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                    }
                    label={i18n.get("forms.creditCard.payment.date.label_preview")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={`${
                        transaction?.programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""
                    }${i18n.get("forms.creditCard.payment.typeOperation")}`}
                    label={i18n.get("forms.creditCard.payment.typeOperation.label")}
                />

                <PDFProductSelectorField idForm={ID_FORM} name="debitAccount" values={values} />
                <PDFProductSelectorField idForm={ID_FORM} name="creditCard" values={values} />

                <PDFTextField
                    idForm={ID_FORM}
                    name="paymentType"
                    value={i18n.get(`forms.creditCard.payment.${values?.paymentType}.label`)}
                    label={i18n.get("forms.creditCard.payment.typePayment.label")}
                />
            </>
        );
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler) => {
        if (mode !== "view" && (!preDataForm || fetchingDetail || fetchingList || creditCardOptions.length === 0)) {
            return <PageLoading loading classicStyle={false} />;
        }

        if (values?.debitAccount && mode === "edit") {
            const debitAccount = preDataForm?.debitAccountList?.find((acc) => acc.idProduct === values?.debitAccount);
            const currency = debitAccount?.currency;
            const balance = debitAccount?.balance;

            if (amountDebitAccount?.currency !== currency || amountDebitAccount?.quantity !== balance) {
                setAmountDebitAccount({ currency: debitAccount?.currency, quantity: debitAccount?.balance });
            }
        }
        return (
            <>
                {mode === "edit" && (
                    <>
                        <Box display="none">
                            <Field
                                {...genericProps}
                                isFocused={false}
                                mode={mode}
                                component={FormFieldsComponents.Coordinates}
                                key="localization"
                                isRequired
                                name="localization"
                                idField="localization"
                            />
                        </Box>

                        <Box className="form-creditCardPay">
                            <Box
                                background="white"
                                borderRadius="default"
                                className="form-creditCardPay__creditCard pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-10 mx-n-5">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={creditCardOptions}
                                    key="creditCard"
                                    name="creditCard"
                                    renderAs="combo"
                                    value={creditCardPayment || values?.creditCardData}
                                    idField="creditCard"
                                    onChangeDataCustom={(creditCard) => {
                                        setIdCreditCardSelected(creditCard);
                                        getDetailCreditCard(creditCard);
                                        setWarningPaymentType(undefined);
                                        setFieldValue("amount", amountDefault);
                                    }}
                                />
                            </Box>
                            <Box
                                background="white"
                                borderRadius="default"
                                className="form-creditCardPay__debitAccount pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-10 mx-n-5">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={accountOptions}
                                    key="debitAccount"
                                    name="debitAccount"
                                    renderAs="combo"
                                    value={values?.debitAccount}
                                    loadBalance
                                    idField="debitAccount"
                                />
                            </Box>
                            {renderScheduler()}

                            {values?.scheduler?.selectedOption !== schedulerUtils.TODAY && !isDesktop ? (
                                <Box display="flex" alignX="flex-start" alignY="center">
                                    <Image
                                        wrapperClassName="form-confirmation-info-icon mr-3"
                                        src="images/tooltip-icon.svg"
                                    />
                                    <Text
                                        labelKey="forms.creditCard.payment.creditCardLocal.disclaimer"
                                        size="7"
                                        color="text-boton"
                                    />
                                </Box>
                            ) : null}

                            <Box className="form-creditCardPay__amount">
                                <Field
                                    genericProps={genericProps}
                                    optionsPayment={amountPaymentOptions}
                                    values={values}
                                    dataAmount={dataAmount}
                                    component={AmountPaymentTypeField}
                                    key="amount"
                                    name="amount"
                                    warningPaymentType={warningPaymentType}
                                    warningOtherAmountLabel={`forms.${ID_FORM}.amount.exceeded.warning`}
                                    idField="amount"
                                />
                            </Box>
                        </Box>
                    </>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                value={values.amount}
                                idField="amount"
                                bigDataAmount
                                label_viewMap={
                                    transaction?.idTransactionStatus === "FINISHED"
                                        ? { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_view`) }
                                        : { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label`) }
                                }
                            />
                        </Box>

                        {TooltipProcessingComponent}

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.backendReference || transaction?.data?.backendReference || ""}
                                label="forms.creditCard.payment.voucher.label"
                                shouldRender={
                                    mode === "view" &&
                                    !!(values?.backendReference || transaction?.data?.backendReference)
                                }
                            />
                            {values && values.backendReference && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={values?.backendReference}
                                    label="forms.creditCard.payment.voucherReference.label"
                                    shouldRender={
                                        mode === "view" &&
                                        transaction &&
                                        (transaction?.idTransactionStatus === "PENDING" ||
                                            transaction?.idTransactionStatus === "PROCESSING" ||
                                            transaction?.idTransactionStatus === "REJECTED")
                                    }
                                />
                            )}
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    // eslint-disable-next-line no-nested-ternary
                                    mode === "preview"
                                        ? moment().format("DD/MM/YYYY")
                                        : transaction?.idParentTransaction
                                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                                }
                                label="forms.creditCard.payment.date.label_preview"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={`${
                                    transaction?.programed
                                        ? i18n.get("forms.transaction.ticket.typeOperation.program")
                                        : ""
                                }${i18n.get("forms.creditCard.payment.typeOperation")}`}
                                label="forms.creditCard.payment.typeOperation.label"
                                shouldRender={mode === "view"}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={accountOptions}
                                key="debitAccount"
                                name="debitAccount"
                                renderAs="combo"
                                value={values?.debitAccountData?.otherLabel}
                                idField="debitAccount"
                            />
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={creditCardOptions}
                                key="creditCard"
                                name="creditCard"
                                renderAs="combo"
                                value={creditCardPayment || values?.creditCard}
                                idField="creditCard"
                                onChange={(creditCard) => getDetailCreditCard(creditCard)}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get(`forms.creditCard.payment.${values?.paymentType}.label`)}
                                label="forms.creditCard.payment.typePayment.label"
                            />
                            {values?.scheduler?.selectedOption &&
                            values?.scheduler?.selectedOption === schedulerUtils.TODAY &&
                            mode === "preview" ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={i18n.get("scheduler.immediate.radiobutton.label.cardPayment")}
                                    label="forms.creditCard.payment.makepay.label_preview"
                                />
                            ) : null}

                            {values?.scheduler?.selectedOption &&
                            values?.scheduler?.selectedOption !== schedulerUtils.TODAY ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    type={SCHEDULER_DATE}
                                    value={values?.scheduler}
                                    label="forms.creditCard.payment.dateScheduler.label_preview"
                                />
                            ) : null}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: titleForm,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        validate: validateCustom,
        showSchedulerMessage: false,
        invalidateCache: true,
        usesJointAccount,
    };
    return <FormTransition {...props} {...formProps} />;
};

PayCreditCardLocal.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    payCreditCardPermission: bool.isRequired,
    creditCardDetail: shape({}),
    fetchingDetail: bool,
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    usesJointAccount: bool,
    data: arrayOf(shape({})),
};
PayCreditCardLocal.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: shape({}),
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    creditCardDetail: {},
    fetchingDetail: false,
    fetchingList: false,
    creditCardList: [],
    usesJointAccount: false,
    data: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    payCreditCardPermission: sessionSelectors.hasPermissions(state, ["payCreditCard"]),
    creditCardDetail: creditCardSelectors.getDetailByForm(state, ID_FORM),
    fetchingDetail: creditCardSelectors.getFetching(state),
    fetchingList: creditCardSelectors.isFetching(state),
    creditCardList: creditCardSelectors.getList(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(PayCreditCardLocal));

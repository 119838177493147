import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import ShortCuts from "pages/_components/ShortCuts";
import Text from "pages/_components/Text";
import TokenButtonWrapperContainer from "pages/_components/TokenButton/TokenButtonWrapperContainer";
import Welcome from "pages/_components/Welcome";
import Campaigns from "pages/campaigns/Campaigns";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { selectors as assistantSelectors } from "reducers/assistant";
import { selectors as loginSelectors } from "reducers/login";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import { actions as themeActions, selectors as themeSelectors } from "reducers/theme";
import { actions as fingerprintActions } from "reducers/fingerprint";
import * as config from "util/config";

class Step0Content extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        displayCampaigns: bool,
        location: shape({}),
        theme: string.isRequired,
        isActiveCorporate: bool.isRequired,
    };

    static defaultProps = {
        displayCampaigns: null,
        location: {},
    };

    send = (loginType) => {
        const { isMobile, dispatch } = this.props;

        switch (loginType) {
            case "email": {
                if (isMobile) {
                    dispatch(push("/loginStep1"));
                }
                break;
            }
            default: {
                // TODO Undefined
                break;
            }
        }
    };

    handleChangeEnviroment = (themeOption) => {
        const { dispatch } = this.props;
        const containsCorporate = themeOption.includes("corporate");
        dispatch(sessionActions.isActiveCorporate(containsCorporate));
        dispatch(themeActions.changeLoadTheme(themeOption));
        dispatch(fingerprintActions.configurationDeviceHasFingerPrinterSuccess());
    };

    render() {
        const { displayCampaigns, location, dispatch, isActiveCorporate } = this.props;
        const isStepZero = location.pathname === "/";
        const shortCutBtns = [
            {
                image: { desktop: "images/shortCutBtn/SCBproducts.svg" },
                label: "login.step0.short.cut.balances",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/desktop"));
                    this.send("email", event);
                },
            },
            {
                image: { desktop: "images/shortCutBtn/SCBthirdParties.svg" },
                label: "login.step0.short.cut.transfer",
                click: () => dispatch(push("/transfersAccsess")),
            },
            {
                image: { desktop: "images/shortCutBtn/SCBpayments.svg" },
                label: "login.step0.short.cut.payments",
                click: () => dispatch(push("/paymentsAccess")),
            },
            {
                image: { desktop: "images/shortCutBtn/SCBconfig.svg" },
                label: "login.step0.short.cut.configuration",
                click: (event) => {
                    dispatch(statusActions.saveLastHref("/settings"));
                    this.send("email", event);
                },
            },
        ];

        const handleClickNotification = (event) => {
            this.send("email", event);
            dispatch(statusActions.saveLastHref("/communications"));
        };

        return (
            <>
                <Box component="header" display="flex" alignX="between" className="splash-header">
                    <Box display="flex" alignX="flex-start" alignY="center" fullWidth flex1 gap="4">
                        <Image wrapperClassName="login-image" src="images/banesco-mobile-logo-color.svg" />
                        <Welcome className="welcome-header" />
                    </Box>

                    <Box display="flex" alignX="flex-end" alignY="center">
                        <Button
                            image="images/icons/bell.svg"
                            imageMd
                            className="btn-login"
                            onClick={handleClickNotification}
                            bsStyle="link"
                        />
                    </Box>
                </Box>
                <MainContainer>
                    <Row className="background-component-background" gapX="0">
                        <Col xs={6}>
                            <Button
                                bsStyle="tab-style"
                                label="wally.forms.transfers.people"
                                imageMd
                                image="images/icons/enviroment-retail.svg"
                                block
                                className="is-active"
                                {...(!isActiveCorporate ? { className: "is-active" } : { className: "" })}
                                onClick={() => this.handleChangeEnviroment("retail")}
                            />
                        </Col>
                        <Col xs={6}>
                            <Button
                                bsStyle="tab-style"
                                label="global.corporate"
                                {...(isActiveCorporate ? { className: "is-active" } : { className: "" })}
                                imageMd
                                image="images/icons/enviroment-corporate.svg"
                                block
                                onClick={() => this.handleChangeEnviroment("corporate")}
                            />
                        </Col>
                    </Row>
                    <Row className="top-wrapper">
                        <Col xs={6}>
                            <Button
                                image="images/icons/step0login.svg"
                                className="btn-login-action"
                                href="/loginStep1"
                                label="login.access.bar.customer.id.access"
                                imageMd
                                block
                            />
                        </Col>
                        <Col xs={6}>
                            <TokenButtonWrapperContainer isStep0 />
                        </Col>
                    </Row>
                    <Box display="flex" column className="bottom-wrapper">
                        <Box className="mb-7">
                            <ShortCuts
                                options={shortCutBtns}
                                isStepZero={isStepZero}
                                className="bottom-wrapper-short-cuts"
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignX="center"
                            alignY="center"
                            className="campaign-container"
                            position="relative"
                            fullWidth
                            fullHeight>
                            {displayCampaigns ? (
                                <Campaigns
                                    isStepZero={isStepZero}
                                    section="landing"
                                    isActiveCorporate={isActiveCorporate}
                                />
                            ) : null}
                        </Box>

                        <Box display="flex" alignX="center" fullWidth className="mt-auto">
                            <Button
                                externalHref={config.get("accessBar.privacyProtection.url", "/")}
                                key="privacy"
                                bsStyle="link"
                                small>
                                <Text labelKey="footer.privacyProtection.url" regular underlined />
                            </Button>
                        </Box>
                    </Box>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: loginSelectors.getFetching(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    displayCampaigns: loginSelectors.getDisplayCampaigns(state),
    theme: themeSelectors.getThemeOption(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Step0Content)));

import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { arrayOf, bool, element, number, oneOfType, string } from "prop-types";
import React, { Children } from "react";

const Stepper = ({ children, className }) => (
    <Box component="ol" className={classNames("wrapper", className)}>
        {Children.map(children, (child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box component="li" className="step" key={index}>
                {child}
            </Box>
        ))}
    </Box>
);

Stepper.propTypes = {
    children: oneOfType([element, arrayOf(element)]).isRequired,
    className: string,
};

Stepper.defaultProps = {
    className: undefined,
};

const Step = ({ currentStep, children, showAsPill, stepNumber, completed }) => {
    const displayNumber = stepNumber;
    const active = currentStep === displayNumber;
    const done = currentStep > displayNumber;

    return (
        <Box
            className={classNames("step-wrapper", {
                pill: showAsPill,
                active,
                done,
            })}>
            {completed >= displayNumber ? (
                <Box className="stepper-check">
                    <Image src="images/icons/stepperCheck.svg" />
                </Box>
            ) : (
                <Text
                    className={classNames("step number", {
                        hidden: showAsPill && !active,
                    })}>
                    {displayNumber < 10 ? `${displayNumber}` : displayNumber}
                </Text>
            )}

            <Text
                className={classNames("step-text", {
                    "visually-hidden": showAsPill && active,
                    hidden: showAsPill && !active,
                })}>
                {children}
            </Text>
        </Box>
    );
};

Step.propTypes = {
    currentStep: number.isRequired,
    completed: number,
    showAsPill: bool,
    children: element.isRequired,
    stepNumber: number.isRequired,
};

Step.defaultProps = {
    showAsPill: false,
    completed: null,
};

export default Stepper;
export { Step };

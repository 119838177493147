import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { func, shape } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as wallyActions, selectors as wallySelectors } from "reducers/wally";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { formatAccount } from "./hooks/WallyHooks";

const SettingsWally = ({ history, dispatch, accountWally, userWally }) => {
    const [showModal, setshowModal] = useState(false);
    const [fetchingSubmit, setFetchingSubmit] = useState(false);

    const handleOpenModal = () => {
        setshowModal(true);
    };

    const handleCloseModal = () => {
        setshowModal(false);
    };

    const handleBack = () => {
        history.goBack();
    };
    const onFinish = () => {
        setFetchingSubmit(false);
    };

    const desactivateWally = () => {
        setshowModal(false);
        setFetchingSubmit(true);
        dispatch(wallyActions.desactivateRequest(accountWally, onFinish));
    };

    return (
        <PageLoading loading={fetchingSubmit}>
            {showModal ? (
                <TextAndButtonsModal
                    modalShow
                    acceptFunction={desactivateWally}
                    cancelFunction={handleCloseModal}
                    headingText={i18n.get("wally.settings.deactivate.modal.title")}
                    text={i18n.get("wally.settings.deactivate.modal.text")}
                />
            ) : null}

            <Head titleImage="wally-head.svg" onBack={handleBack} title="wally.settings.title" />

            <MainContainer>
                <Notification scopeToShow="settingswally" />
                <Box display="flex" column alignX="between" alignY="center" className="pt-8">
                    <Image src="images/settings-wally.svg" color="wally" isMobileNative height="12" className="mb-7" />

                    <Box display="flex" column alignY="center" alignX="center" fullWidth>
                        <Text labelKey="wally.settings.label1" size={4} align="center" className="px-5 mb-1" />
                        <Box display="flex">
                            <span className="size-4 align-center">
                                {i18n.get("wally.settings.label2")}{" "}
                                <Text labelKey="wally.finish.subtitle.span" color="wally" bold size="4" />
                            </span>
                            <Box display="inline-flex" className="ml-1">
                                <Image src="images/wally.svg" fullWidth={false} isMobileNative height="5" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" column alignX="center" alignY="center" fullWidth className="mt-8 mb-9">
                    <Box
                        background="white"
                        display="flex"
                        alignX="between"
                        alignY="center"
                        fullWidth
                        className=" mt-3 py-7 pl-6 pr-1"
                        borderRadius="xl">
                        <Box display="flex" alignX="flex-start" alignY="center">
                            <Box display="flex" className="mr-3">
                                <Image
                                    src="images/check-circle.svg"
                                    isMobileNative
                                    height="5"
                                    wrapperWidth="5"
                                    wrapperHeight="5"
                                />
                            </Box>
                            <Text labelKey="wally.settings.field.phone" size="3" bold color="heading" />:
                            <Text className="ml-2" size="3" ellipsis>
                                {userWally?.phoneNumber &&
                                    `+${userWally?.phoneNumber?.substr(0, 3)}****${userWally?.phoneNumber?.substr(
                                        userWally?.phoneNumber?.length - 4,
                                        4,
                                    )}`}
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        background="white"
                        display="flex"
                        alignX="between"
                        alignY="center"
                        fullWidth
                        className=" mt-3 py-4 pl-6 pr-1"
                        borderRadius="xl">
                        <Box display="flex" alignX="flex-start" alignY="center">
                            <Box display="flex" className="mr-3">
                                <Image
                                    src="images/check-circle.svg"
                                    isMobileNative
                                    height="5"
                                    wrapperWidth="5"
                                    wrapperHeight="5"
                                />
                            </Box>
                            <Text size="3" bold color="heading" ellipsis>
                                {accountWally?.productAlias}
                            </Text>
                            <Text className="ml-2" size="3">
                                {formatAccount(accountWally)}
                            </Text>
                        </Box>
                        <Button image="images/editPen.svg" small href="/changeAccountStep1" />
                    </Box>
                </Box>

                <Button
                    label="wally.settings.deactivate"
                    bsStyle="outline"
                    block
                    className="mt-auto"
                    onClick={handleOpenModal}
                />
            </MainContainer>
        </PageLoading>
    );
};

SettingsWally.propTypes = {
    history: shape({}).isRequired,
    dispatch: func.isRequired,
    accountWally: shape({}),
    userWally: shape({}),
};

SettingsWally.defaultProps = { accountWally: {}, userWally: {} };
const mapStateToProps = (state) => ({
    accountWally: wallySelectors.getAccountWally(state),
    userWally: wallySelectors.getUserWally(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(SettingsWally));

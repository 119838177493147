import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import * as Yup from "yup";

import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Disclaimer from "pages/_components/Disclaimer";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";

const ID_ACTIVITY = "administration.medium.read.channels";

class Channels extends Component {
    allAmountRef = null;

    static propTypes = {
        actions: shape({ loadChannelsRequest: func.isRequired }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        enabledChannelsFrequencies: arrayOf(
            shape({
                value: string.isRequired,
                label: string.isRequired,
            }),
        ),
        enabledChannels: arrayOf(string).isRequired,
        currencies: arrayOf(
            shape({
                id: string.isRequired,
                value: string.isRequired,
                label: string.isRequired,
            }),
        ).isRequired,
        nonRemovableChannels: arrayOf(string).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({
            maximum: number,
            amount: number,
            frequency: string,
        }),
        fetching: bool.isRequired,
        user: shape({}).isRequired,
        lang: string.isRequired,
        setLocationCustomPath: func.isRequired,
        userEnvStatus: string.isRequired,
    };

    static defaultProps = {
        enabledChannelsFrequencies: null,
        topAmount: null,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadChannelsRequest(match.params.id);
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", ID_ACTIVITY);
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    valueVerification = (values) => {
        const object = { ...values.channels };

        const keys = Object.keys(object);
        const modifiedObject = keys.reduce((acc, key) => {
            if (acc.channels[key].amount === "NaN" || acc.channels[key].amount === "") {
                acc.channels[key].amount = "0.00";
            }
            return acc;
        }, values);

        return modifiedObject;
    };

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions } = this.props;
        const verifiedValues = this.valueVerification(values);
        const enabledChannels = [...verifiedValues.enabledChannels];

        actions.updateChannelsPreview(
            {
                caps: Object.entries(verifiedValues.channels).reduce((enabled, [channel, data]) => {
                    if (!enabledChannels.includes(channel)) {
                        return enabled;
                    }

                    return { ...enabled, [channel]: data };
                }, {}),
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    validationSchema = () => {
        const { topAmount } = this.props;

        return Yup.lazy((values) =>
            Yup.object().shape({
                channels: Yup.object().shape({
                    all: Yup.object().shape({
                        amount: Number.isNaN(parseFloat(values?.channels?.all?.amount))
                            ? Yup.string().notRequired()
                            : Yup.number()
                                  .transform((cv, ov) => (ov === "" ? undefined : cv))
                                  .nullable()
                                  .max(
                                      topAmount.maximum,
                                      i18n.get("administration.limits.channels.topAmount.maxAmount.error"),
                                  )
                                  .min(0, i18n.get("administration.limits.channels.topAmount.required")),
                    }),
                }),
            }),
        );
    };

    fieldHasErrorAndToched = (fieldName, form) => {
        const { touched, errors } = form;
        if (
            errors &&
            touched &&
            Object.keys(errors).length !== 0 &&
            Object.keys(touched).length !== 0 &&
            errors.channels &&
            touched.channels
        ) {
            return errors.channels[fieldName] && touched.channels[fieldName];
        }

        return false;
    };

    getFieldErrorMessage = (fieldName, form) => {
        const { errors } = form;
        return errors.channels[fieldName].amount;
    };

    renderForm = (form) => {
        const { currencies, user, topAmount, caps, lang, userEnvStatus } = this.props;
        const { isSubmitting } = form;
        const allAmount = caps?.all?.amount;
        const status = () => {
            if (userEnvStatus === "active") {
                return "success";
            }
            if (userEnvStatus === "blocked") {
                return "error";
            }
            if (userEnvStatus === "pending") {
                return "warning";
            }
            return null;
        };

        const genericProps = {
            mode: "edit",
            lang,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        const { decimalSeparator, thousandSeparator } = numberFormat();

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: currencies,
        };

        return (
            <Form>
                <Box
                    display="flex"
                    column
                    background="component-background"
                    borderRadius="default"
                    className="px-8 py-7 mb-3"
                    fullHeight>
                    <Box display="flex">
                        <Text
                            component="label"
                            labelKey="administration.user.label"
                            className="m-0"
                            color="heading-color"
                            bold
                            addColon
                        />

                        <Text component="label" className="my-0 mr-5" color="heading-color" regular>
                            {user?.fullName}
                        </Text>

                        <Sticker
                            labelKey={`user.status.${userEnvStatus}`}
                            uppercase={false}
                            bold
                            status={status()}
                            textSize="7"
                            addPaddingY
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    column
                    background="component-background"
                    borderRadius="default"
                    className="pt-9 pb-8 mb-4"
                    fullHeight>
                    <Row>
                        <Col xs="4">
                            <Box display="flex" column className="pl-8 position-relative">
                                <Box className="form-group position-relative" alignX="left">
                                    <FieldLabel
                                        labelKey="administration.channels.all"
                                        labelNoMarginTop
                                        dataLabelSpecialMb={false}
                                    />
                                </Box>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    labelKey="Monto Tope General"
                                    data={dataAmount}
                                    key="channels.all"
                                    name="channels.all"
                                    value={{ currency: "USD", quantity: allAmount }}
                                    idField="channels.all"
                                    customPlaceholderCurrency=""
                                    customPlaceholderQuantity="0.00"
                                    quantityTextAlign="text-left"
                                    disabledCurrency
                                    isFromLimites
                                    acceptZero
                                />

                                <Box alignY="center">
                                    {this.fieldHasErrorAndToched("all", form) && (
                                        <FieldError error={this.getFieldErrorMessage("all", form)} />
                                    )}
                                </Box>

                                <Box display="flex" alignY="center" className="mt-5" alignX="center">
                                    <span className="color-primary-hover-color size-7 display-flex">
                                        {i18n.get("administration.limits.maximum.text")}
                                        <FormattedAmount
                                            quantity={topAmount?.maximum}
                                            currency="USD"
                                            size="7"
                                            color="primary-hover-color"
                                            bold
                                            className="mx-2"
                                        />
                                        {i18n.get("administration.limits.daily.text")}
                                    </span>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>

                <Disclaimer
                    iconClassname="tooltip-img"
                    labelKey="administration.channels.disclaimer"
                    textColor="heading-color"
                    styled="info"
                    borderRadius="default"
                    className="mb-8"
                    notBorder
                />

                <Row>
                    <Col xs={3} xsOffset={3}>
                        <Button bsStyle="outline" label="global.cancel" onClick={this.handleBack} block />
                    </Col>
                    <Col xs={3}>
                        <Button type="submit" bsStyle="primary" label="global.modify" loading={isSubmitting} block />
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        const { fetching, enabledChannels, caps, topAmount } = this.props;
        const { maximum, amount, frequency } = { ...topAmount };
        const isLoading = fetching && !Object.keys(caps).length;
        return (
            <>
                <Head title="administration.limits.title" onBack={this.handleBack} />

                <MainContainer showLoader={isLoading || !enabledChannels.length}>
                    <Formik
                        onSubmit={this.handleSubmit}
                        initialValues={{
                            channels: enabledChannels.reduce(
                                (values, channel) => ({
                                    ...values,
                                    [channel]: caps[channel] || { frequency: "daily" },
                                }),
                                {
                                    topAmount: {
                                        amount: maximum || amount,
                                        frequency,
                                    },
                                },
                            ),
                            enabledChannels: Object.keys(caps),
                        }}
                        validationSchema={this.validationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(Channels));

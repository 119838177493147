import classNames from "classnames";
import { format } from "date-fns";
import Statements from "pages/loans/Statements";
import Action from "pages/loans/_components/Action";
import DetailHeadInfo from "pages/loans/_components/DetailHeadInfo";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import { bool, func, number, oneOfType, shape, string, instanceOf } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";
import { selectors as i18nSelectors } from "reducers/i18n";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { getLocationBasePath } from "util/revelock";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "loans.read";
class Details extends Component {
    state = {
        showSideBarDownloadDetail: false,
    };

    componentDidMount() {
        const { loan, dispatch, ...rest } = this.props;
        const initialFilter = "pendingFees";

        dispatch(loansActions.readLoan(rest.match.params.id));
        dispatch(loansActions.details(rest.match.params.id));
        dispatch(loansActions.setSelectedFilter(initialFilter));
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(loansActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { dispatch, ...rest } = this.props;
        dispatch(loansActions.setSelectedFilter(id));
        dispatch(loansActions.details(rest.match.params.id));
    };

    toolbarChildren = () => {
        const { isDesktop } = this.props;
        return (
            <>
                <Action isDesktop={isDesktop} labelKey="loans.request" to="/loansPayment/request/" />
            </>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(loansActions.closeOptions());
        dispatch(routerActions.goBack());
    };

    handleClickDownload = (formats) => () => {
        const { dispatch, match } = this.props;
        dispatch(loansActions.downloadPayment(match.params.id, formats));
    };

    getSecondaryBtn = () => {
        const { loan, dispatch } = this.props;

        const secondaryButtons = [
            {
                bsStyle: "outline",
                label: `forms.product.state.download.title`,
                onClick: () => {
                    // dispatch(push("/websiteunderconstruction"));
                    this.setState({ showSideBarDownloadDetail: true });
                },
            },
        ];

        secondaryButtons.push({
            bsStyle: "primary",
            label: `loan.detail.pay.loan.button`,
            onClick: () => {
                dispatch(push(`/formCustom/payLoan?loan=${loan.idProduct}`));
            },
            disabled: loan?.overdueStatus === "NAB",
        });

        return secondaryButtons;
    };

    render() {
        const { loan, isDesktop, dispatch, selectedFilter, fetching, currentLang, lastPaymentDay } = this.props;

        const secondaryBtn = this.getSecondaryBtn();

        const contextOptions = [
            {
                label: "deposit.detail.button.edit.alias",
                onClick: () => {
                    dispatch(push(`/loans/${loan.idProduct}/setAlias`));
                },
            },
            {
                label: "forms.product.state.download.title",
                onClick: () => {
                    this.setState({ showSideBarDownloadDetail: true });
                    // dispatch(push("/websiteunderconstruction"));
                },
            },
        ];

        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "pendingFees",
                })}
                key="pendingFees"
                label="loans.statements.filters.pendingFees"
                onClick={() => this.handleFilterButtonsClick("pendingFees")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "paidFees",
                })}
                key="paidFees"
                label="loans.statements.filters.paidFees"
                onClick={() => this.handleFilterButtonsClick("paidFees")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "allFees",
                })}
                key="all"
                label="loans.statements.filters.all"
                onClick={() => this.handleFilterButtonsClick("allFees")}
            />,
        ];

        const productDetail = {
            ...loan,
            lastPaymentDay,
        };

        return (
            <>
                <PageLoading loading={fetching} classicStyle={false}>
                    <Notification scopeToShow="loans/details" />
                    {loan && (
                        <ProductDetail>
                            <ProductDetail.Header
                                contextOptions={isDesktop ? null : contextOptions}
                                dispatch={dispatch}
                                handleOptionsClick={this.handleClick}
                                isDesktop={isDesktop}
                                onBack={this.handleBack}
                                productId={loan.productId}
                                secondaryBtn={secondaryBtn}
                                product={loan}
                                productKind="loan">
                                {isDesktop ? (
                                    <DetailHeadInfo {...this.props} product={productDetail} />
                                ) : (
                                    <RemarkableProductInfo
                                        currency={loan.currency}
                                        quantity={loan.paidAmount}
                                        status={loan.status}
                                        startDate={format(loan.constitutedDate, "DD/MM/YYYY")}
                                        isDesktop={isDesktop}
                                        productType={loan.productType}
                                        completed={loan.paidPercentage}
                                        showSwitch={false}
                                        tooltipText="deposit.detail.fields.open.amount.label"
                                        icon={loan.categoryIdIcon}
                                    />
                                )}
                            </ProductDetail.Header>

                            <ProductDetail.Body
                                isDesktop={isDesktop}
                                filters={filters}
                                filtersKeyLabel="loans.details.paymentPlan"
                                product={loan}
                                productKind="loan"
                                moreFilters={false}
                                {...(!isDesktop && {
                                    detailHeadInfo: <DetailHeadInfo {...this.props} product={productDetail} />,
                                })}>
                                <Statements
                                    keyLabel="loan.detail.statement.header.feesToPay"
                                    iconLabel="images/icons/chartCircle.svg"
                                    isDesktop={isDesktop}
                                    operationCurrency={loan.currency}
                                    operationId={loan.idProduct}
                                    paymentPlan
                                    isFeePaid={false}
                                    noFiltersDataText="loan.detail.statement.noFees"
                                />
                                <Statements
                                    keyLabel="loan.detail.statement.header.paidFees"
                                    iconLabel="images/icons/chartCirclePayed.svg"
                                    isDesktop={isDesktop}
                                    loanId={loan.idProduct}
                                    loanCurrency={loan.currency}
                                    selectedFilter={selectedFilter}
                                    operationId={loan.idProduct}
                                    noFiltersDataText="loan.detail.statement.noPaidFees"
                                    isFeePaid
                                />
                            </ProductDetail.Body>
                        </ProductDetail>
                    )}
                </PageLoading>
                <SideBarModal
                    show={this.state.showSideBarDownloadDetail}
                    onClose={() => {
                        this.setState({ showSideBarDownloadDetail: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm
                        isDesktop={isDesktop}
                        account={loan}
                        currentLang={currentLang}
                        dispatch={dispatch}
                    />
                </SideBarModal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
    fetching: loansSelectors.getFetching(state),
    fetchingDownload: loansSelectors.getFetchingDownload(state),
    totalCount: loansSelectors.getTotalCount(state),
    totalFavorites: loansSelectors.getTotalFavorites(state),
    selectedFilter: loansSelectors.getSelectedFilter(state),
    lastPaymentDay: loansSelectors.getLastPaymentDay(state),
    currentLang: i18nSelectors.getLang(state),
});

Details.propTypes = {
    dispatch: func.isRequired,
    loan: shape({
        idProduct: string,
        currency: string,
    }),
    rest: shape(),
    match: shape().isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    fetchingDownload: bool.isRequired,
    totalCount: oneOfType([number, bool]).isRequired,
    totalFavorites: number.isRequired,
    selectedFilter: string.isRequired,
    lastPaymentDay: oneOfType([string, instanceOf(Date)]).isRequired,
    currentLang: string,
    setLocationCustomPath: func,
};

Details.defaultProps = {
    loan: {},
    rest: {},
    currentLang: "",
    setLocationCustomPath: () => {},
};

export default connect(mapStateToProps)(withRevelockPositionContext(Details));

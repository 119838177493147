import { useEffect, useState } from "react";

export const useEnvironmentList = (environmentList) => {
    const [environmentListOptions, setEnvironmentListOptions] = useState([]);

    const environmentListTmp = (environment) => ({
        ...environment,
        value: environment.idEnvironment,
        label: environment.name,
    });

    useEffect(() => {
        setEnvironmentListOptions(environmentList?.map(environmentListTmp));
    }, [environmentList]);
    return environmentListOptions;
};

export default useEnvironmentList;

import * as API from "middleware/api";

export const loadListRequest = ({ pageNumber = 1, orderBy = "last_login DESC" } = {}) =>
    API.executeWithAccessToken("/administration.invitations.list", {
        pageNumber,
        orderBy,
    });

export const resendRequest = (id) =>
    API.executeWithAccessToken("/administration.invitations.resend", {
        id,
    });

export const deleteRequest = (id) =>
    API.executeWithAccessToken("/administration.invitations.delete", {
        id,
    });

/* eslint-disable */
import classNames from "classnames";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ReactSelect, { components } from "react-select";
import * as i18n from "util/i18n";

const OptionTooltip = ({ position, labelKey, tooltipPosition }) => {
    const tooltipRef = useRef();
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const tooltipWidth = tooltipRect.width / 2;
            const newStyle = {
                position: "fixed",
                left: `${tooltipPosition.x - tooltipWidth}px`,
                top: `${tooltipPosition.y}px`,
            };

            setStyle(newStyle);
        }
    }, [tooltipPosition]);

    return (
        <div style={style} className="z-index-loader pointer-events-none">
            <div
                ref={tooltipRef}
                role="tooltip"
                className={classNames("tooltip-text-container pointer-events-none", `tooltip-${position}`)}>
                <Text className="tooltip-text" labelKey={labelKey} />
            </div>
        </div>
    );
};
OptionTooltip.propTypes = {
    position: string,
    labelKey: string.isRequired,
    tooltipPosition: shape({}).isRequired,
};

OptionTooltip.defaultProps = {
    position: "bottom-centered",
};

const CustomOption = (props) => {
    const {
        data: { optionTooltip },
    } = props;
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const showTooltip = (event) => {
        const optionElement = event.currentTarget;
        const rect = optionElement.getBoundingClientRect();
        const positionY = (rect.top + rect.bottom) / 2;
        setTooltipPosition({ x: event.clientX, y: positionY });
        setTooltipVisible(true);
    };

    const hideTooltip = () => {
        setTooltipVisible(false);
    };

    return (
        <>
            {optionTooltip ? (
                <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                    <components.Option {...props}>{props.children}</components.Option>
                </div>
            ) : (
                <components.Option {...props}>{props.children}</components.Option>
            )}
            {tooltipVisible &&
                createPortal(
                    <OptionTooltip
                        position="bottom-centered"
                        tooltipPosition={tooltipPosition}
                        labelKey={optionTooltip}
                    />,
                    document.getElementById("root"),
                )}
        </>
    );
};

const CustomDropdownIndicator = () => (
    <Box display="flex" alignY="center" className="Select-arrow-zone">
        <Box className="Select-arrow" />
    </Box>
);

const CustomNoOptionsMessage = () => (
    <Box display="flex" alignX="center" alignY="center" className="select__menu-notice--no-options">
        <Text labelKey="global.no.options" />
    </Box>
);

const MultiValueContainer = () => null;

const CustomValueContainer = ({
    children,
    options,
    value,
    withSelectedLabel,
    withSelectedLabelNoMoreOptions,
    ...props
}) => {
    const newChildren = React.Children.toArray(children).filter((child) => child.type !== components.MultiValue);

    const selectedLabel = withSelectedLabel && value?.length > 0 && options?.length > 0 ? withSelectedLabel : undefined;
    const noMoreOptionsLabel =
        withSelectedLabelNoMoreOptions && value?.length > 0 && options?.length === 0
            ? withSelectedLabelNoMoreOptions
            : undefined;

    return (
        <components.ValueContainer {...props}>
            {newChildren}

            {(withSelectedLabelNoMoreOptions || withSelectedLabel) && newChildren.length === 1 && (
                <Text className="Select-placeholder select__placeholder">{selectedLabel ?? noMoreOptionsLabel}</Text>
            )}
        </components.ValueContainer>
    );
};

CustomValueContainer.propTypes = {
    children: shape({}),
    options: shape({}).isRequired,
    value: shape({}).isRequired,
    withSelectedLabel: string,
    withSelectedLabelNoMoreOptions: string,
};

CustomValueContainer.defaultProps = {
    children: null,
    withSelectedLabel: null,
    withSelectedLabelNoMoreOptions: null,
};

const Select = ({
    className,
    clearable,
    containerClassName,
    disabled,
    field,
    form,
    id,
    isDesktop,
    labelKey,
    menuFitContent,
    multi,
    onChange,
    options,
    searchable,
    selectClassName,
    setMultiSelectRefrence,
    value,
    valueKey,
    withSelectedLabel,
    withSelectedLabelNoMoreOptions,
    selectRef,
    withSingleOption,
    ...props
}) => {
    const ref = useRef();

    let multiSelectRefrenceSetted = false;
    useEffect(() => {
        if (ref && setMultiSelectRefrence && !multiSelectRefrenceSetted) {
            setMultiSelectRefrence(ref);
            multiSelectRefrenceSetted = true;
        }
        if (options && options.length === 1 && !value && onChange && withSingleOption) {
            const singleOption = options[0];
            onChange(singleOption);
        }
    }, [options?.length, value]);

    return (
        <>
            <ReactSelect
                ref={selectRef ? selectRef : ref}
                id={id}
                unstyled
                components={{
                    Option: (props) => <CustomOption {...props} />,
                    DropdownIndicator: CustomDropdownIndicator,
                    NoOptionsMessage: CustomNoOptionsMessage,
                    ...(multi
                        ? {
                              MultiValueContainer,
                              ValueContainer: (props) => (
                                  <CustomValueContainer
                                      {...props}
                                      value={value}
                                      withSelectedLabel={withSelectedLabel}
                                      withSelectedLabelNoMoreOptions={withSelectedLabelNoMoreOptions}
                                      options={options}
                                  />
                              ),
                          }
                        : {}),
                }}
                classNames={{
                    container: () => classNames("Select full-width", containerClassName),
                    control: () => "Select-control",
                    dropdownIndicator: () => "Select-arrow-zone",
                    input: () => "Select-input",
                    menu: () =>
                        menuFitContent ? "Select-menu-outer Select-menu-outer--fit-content" : "Select-menu-outer",
                    menuList: () => "Select-menu",
                    option: () => "Select-option",
                    placeholder: () => "Select-placeholder",
                    valueContainer: () => "Select-multi-value-wrapper",
                }}
                menuPlacement="bottom"
                {...(!isDesktop && { menuShouldBlockScroll: true })}
                classNamePrefix="select"
                instanceId={id}
                isDisabled={disabled}
                isSearchable={searchable}
                isClearable={clearable}
                isMulti={multi}
                options={options}
                // Hacer fix para que se cierre el listado al cliquear afuera
                // closeMenuOnSelect={!multi}
                // eslint-disable-next-line no-nested-ternary
                {...(!multi
                    ? typeof value === "string" || value === null
                        ? {
                              value: options?.find((option) => option[valueKey] === value),
                          }
                        : {
                              value: value && options?.find((option) => option[valueKey] === value[valueKey]),
                          }
                    : { value: field.value })}
                {...(!(!onChange && form && form.setFieldValue) && { controlShouldRenderValue: !!value })}
                getOptionLabel={(option) => option[labelKey]}
                getOptionValue={(option) => option[valueKey]}
                onChange={(option) => {
                    if (onChange) {
                        onChange(option);
                    } else if (form && form.setFieldValue) {
                        form.setFieldValue(field.name, !option ? "" : option.value);
                    }
                }}
                {...{ placeholder: i18n.get("forms.placeholder.select"), ...props }}
            />
        </>
    );
};

Select.propTypes = {
    className: string,
    clearable: bool,
    containerClassName: string,
    disabled: bool,
    field: shape({}),
    form: shape({}),
    id: string.isRequired,
    isDesktop: bool.isRequired,
    labelKey: string,
    menuFitContent: bool,
    multi: bool,
    onChange: func,
    options: shape({}),
    searchable: bool,
    selectClassName: string,
    setMultiSelectRefrence: shape({}),
    value: shape({}),
    valueKey: string,
    withSelectedLabel: string,
    withSelectedLabelNoMoreOptions: string,
    selectRef: shape({}),
    withSingleOption: bool,
};

Select.defaultProps = {
    className: null,
    clearable: false,
    containerClassName: null,
    disabled: false,
    field: {},
    form: {},
    labelKey: "label",
    menuFitContent: false,
    multi: false,
    onChange: null,
    options: null,
    searchable: false,
    selectClassName: null,
    setMultiSelectRefrence: null,
    value: null,
    valueKey: "value",
    withSelectedLabel: null,
    withSelectedLabelNoMoreOptions: null,
    selectRef: null,
    withSingleOption: true,
};

export default resizableRoute(Select);

import React, { Component } from "react";
import { number, string, arrayOf, shape, func, bool } from "prop-types";

import FileUploader from "pages/_components/FileUploader";
import FileActions from "pages/forms/_components/_fields/_commons/FileActions";
import formField from "pages/forms/_components/_fields/_commons/formField";

class Inputfile extends Component {
    static propTypes = {
        value: arrayOf(
            shape({
                fileId: number.isRequired,
                fileSize: number.isRequired,
                fileName: string.isRequired,
                fileType: string.isRequired,
            }),
        ),
        setValue: func.isRequired,
        fromBackoffice: bool.isRequired,
        editing: bool.isRequired,
        idForm: string.isRequired,
        idField: string.isRequired,
        formTitle: string.isRequired,
        label: string.isRequired,
        allowMultiple: bool.isRequired,
        maxFileSizeMB: number.isRequired,
        maxFiles: number.isRequired,
        maxTotalFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf(string).isRequired,
        idActivity: string,
        handleFileProcess: func,
        handleInvalidLines: func,
        showNotification: func,
    };

    static defaultProps = {
        value: null,
        idActivity: null,
        handleFileProcess: () => {},
        handleInvalidLines: null,
        showNotification: null,
    };

    render() {
        const {
            idForm,
            idField,
            value,
            formTitle,
            label,
            allowMultiple,
            maxFileSizeMB,
            maxTotalFileSizeMB,
            maxFiles,
            acceptedFileTypes,
            idActivity,
            handleFileProcess,
            handleInvalidLines,
            showNotification,
        } = this.props;

        const customIdActivity = idActivity || "core.forms.send";
        return (
            <FileActions
                {...this.props}
                render={({ onRemoveFile, onAddFile }) => {
                    const onAddFileAndFinish = (file) => {
                        onAddFile(file);
                        showNotification();
                    };
                    return (
                        <FileUploader
                            name={idField}
                            idActivity={customIdActivity}
                            idForm={idForm}
                            idFormField={idField}
                            onRemoveFile={onRemoveFile}
                            onAddFile={onAddFileAndFinish}
                            description={`${formTitle} - ${label}`}
                            files={value}
                            allowMultiple={allowMultiple}
                            maxFileSize={`${maxFileSizeMB}mb`}
                            minFileSize="1B"
                            maxTotalFileSize={`${maxTotalFileSizeMB}mb`}
                            maxFiles={maxFiles}
                            acceptedFileTypes={acceptedFileTypes}
                            allowImagePreview
                            onFileProcess={handleFileProcess}
                            handleInvalidLines={handleInvalidLines}
                        />
                    );
                }}
            />
        );
    }
}

export default formField()(Inputfile);

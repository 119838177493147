import DepositAction from "pages/deposits/_components/DepositAction";
import DetailHeadInfo from "pages/deposits/_components/DetailHeadInfo";
import Box from "pages/_components/Box";
import InfoCardBig from "pages/_components/InfoCardBig";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import Row from "pages/_components/Row";
import WithPermissions from "pages/_components/WithPermissions";
import { arrayOf, func, number, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import {
    actions as depositsActions,
    selectors as depositsSelectors,
    selectors as depositSelectors,
} from "reducers/deposits";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as productSelectors } from "reducers/products";
import * as configUtil from "util/config";
import { bool, string } from "yup";
import { getLocationBasePath } from "util/revelock";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "deposits.read";
class DepositDetails extends Component {
    componentDidMount() {
        const { deposit, dispatch, fullList, ...rest } = this.props;
        dispatch(depositsActions.readDeposit(rest.match.params.id));
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(depositsActions.toggleOptions());
    };

    toolbarChildren = () => {
        const { isDesktop } = this.props;

        return [
            <WithPermissions permissions={["depositCreate"]}>
                <DepositAction isDesktop={isDesktop} key="new" labelKey="deposits.new" to="/formCustom/createDeposit" />
            </WithPermissions>,
        ];
    };

    getSecondaryBtn = () => {
        const { dispatch, deposit, fetchingDownloadCertified } = this.props;
        return [
            {
                loading: fetchingDownloadCertified,
                bsStyle: "outline",
                label: `deposit.detail.button.reprint`,
                onClick: () => {
                    dispatch(depositsActions.downloadCertified(deposit));
                },
            },
        ];
    };

    handleClickDownload = (format) => {
        const { dispatch, match } = this.props;
        dispatch(depositsActions.downloadMovements(match.params.id, format));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    render() {
        const {
            totalCount,
            deposit,
            channels,
            fetching,
            fetchingDownload,
            fetchingMovements,
            dispatch,
            isDesktop,
            activeRegion,
            totalFavorites,
            getSelectProduct,
            ...rest
        } = this.props;

        const { dueDateStr } = getSelectProduct || {};

        const fixedDeposit = deposit || {};

        // const mobileOptions = this.getMobileOptions();
        const secondaryBtn = this.getSecondaryBtn();
        const contextOptions = [
            {
                label: "deposit.detail.button.edit.alias",
                onClick: () => {
                    dispatch(push(`/deposits/${deposit.idProduct}/setAlias`));
                },
            },
            {
                label: "deposit.detail.button.reprint",
                onClick: () => {
                    dispatch(depositsActions.downloadCertified(deposit));
                },
            },
        ];

        const elementoPignoredAmount = fixedDeposit.amountPleged
            ? {
                  title: `deposit.detail.fields.ignored.amount.label`,
                  tooltipText: `deposit.detail.fields.ignored.amount.tooltip`,
                  ...(isDesktop ? { tooltipPosition: "left-centered" } : { tooltipPosition: "bottom-centered" }),
                  currency: fixedDeposit.currency,
                  quantity: fixedDeposit.amountPleged,
              }
            : {};

        const infoCardBigArray = [
            {
                title: `deposit.detail.fields.principal.balance.label`,
                tooltipText: `deposit.detail.fields.principal.balance.tooltip`,
                ...(isDesktop ? { tooltipPosition: "left-centered" } : { tooltipPosition: "bottom-centered" }),
                currency: fixedDeposit.currency,
                quantity: fixedDeposit.totalBalance,
            },
            {
                title: `deposit.detail.fields.interest.expiration.label`,
                tooltipText: `deposit.detail.fields.interest.expiration.tooltip`,
                ...(isDesktop ? { tooltipPosition: "left-centered" } : { tooltipPosition: "bottom-centered" }),
                currency: fixedDeposit.currency,
                quantity: fixedDeposit.rateAmount,
            },
            elementoPignoredAmount,
            {
                title: "deposit.detail.fields.movement.action.label",
                tooltipText: "deposit.detail.fields.movement.action.tooltip",
                quantity: fixedDeposit.renewalOption,
            },
        ];

        return (
            <PageLoading loading={fetching} classicStyle={false}>
                {!fetching && deposit && (
                    <>
                        <Notification scopeToShow="deposit/details" />
                        <ProductDetail>
                            <ProductDetail.Header
                                contextOptions={isDesktop ? null : contextOptions}
                                dispatch={dispatch}
                                handleOptionsClick={this.handleClick}
                                isDesktop={isDesktop}
                                onBack={this.handleBack}
                                productId={rest.match.params.id}
                                product={fixedDeposit}
                                secondaryBtn={secondaryBtn}
                                totalFavorites={0}>
                                {isDesktop ? (
                                    <DetailHeadInfo
                                        {...this.props}
                                        isDesktop={isDesktop}
                                        product={fixedDeposit}
                                        dueDateStr={dueDateStr}
                                        twoColumns
                                    />
                                ) : (
                                    <RemarkableProductInfo
                                        currency={fixedDeposit.currency}
                                        quantity={fixedDeposit.balance}
                                        status={fixedDeposit.status}
                                        isDesktop={isDesktop}
                                        productType={fixedDeposit.productType}
                                        showSwitch={false}
                                        tooltipText="deposit.detail.fields.open.amount.label"
                                    />
                                )}
                            </ProductDetail.Header>

                            <ProductDetail.Body
                                withTabs={false}
                                isDesktop={isDesktop}
                                {...(!isDesktop && {
                                    detailHeadInfo: (
                                        <DetailHeadInfo
                                            {...this.props}
                                            isDesktop={isDesktop}
                                            product={fixedDeposit}
                                            dueDateStr={dueDateStr}
                                        />
                                    ),
                                })}>
                                <Box
                                    display="flex"
                                    background={isDesktop ? "white" : "transparent"}
                                    borderRadius="default"
                                    className="px-5 pt-3 pb-8 px-md-11 py-md-12">
                                    <Row gapY="3" className="px-0">
                                        {infoCardBigArray.map((el) => {
                                            let elementRender = null;
                                            if (el.title) {
                                                elementRender = (
                                                    <Col xs={12} md={6} className="height-auto">
                                                        <InfoCardBig
                                                            title={el.title}
                                                            tooltipText={el.tooltipText}
                                                            tooltipPosition={el.tooltipPosition}
                                                            currency={el.currency}
                                                            quantity={el.quantity}
                                                            highlighted={el.highlighted}
                                                            noCurrency={el.noCurrency}
                                                        />
                                                    </Col>
                                                );
                                            }
                                            return elementRender;
                                        })}
                                    </Row>
                                </Box>
                            </ProductDetail.Body>
                        </ProductDetail>
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    deposit: depositsSelectors.getSelectedDeposit(state),
    totalDeposits: depositsSelectors.getTotalDeposits(state),
    channels: configUtil.getArray("core.enabledChannels"),
    fetching: depositsSelectors.getFetching(state),
    fetchingDownload: depositsSelectors.getFetchingDownload(state),
    fetchingMovements: depositsSelectors.getFetchingMovements(state),
    totalCount: depositsSelectors.getTotalCount(state),
    activeRegion: loginSelectors.getRegion(state),
    fullList: depositsSelectors.getFullList(state), // TODO ¿Hay que sacar el valor de algun lado? ejemplo configuration
    totalFavorites: depositsSelectors.getTotalFavorites(state),
    getSelectProduct: productSelectors.getSelectProduct(state),
    fetchingDownloadCertified: depositSelectors.getFetchingDownloadCertified(state),
});

DepositDetails.propTypes = {
    deposit: shape({
        productType: string,
        idProduct: string,
        permissions: shape(),
        currency: string,
    }).isRequired,
    channels: arrayOf().isRequired, // mapStateToProps
    fullList: bool.isRequired, // mapStateToProps
    fetching: bool.isRequired, // mapStateToProps
    fetchingDownload: func.isRequired, // mapStateToProps
    fetchingMovements: func.isRequired, // mapStateToProps
    totalDeposits: number.isRequired, // mapStateToProps
    match: shape().isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    totalCount: number.isRequired, // mapStateToProps
    activeRegion: string.isRequired, // mapStateToProps
    totalFavorites: number.isRequired,
    getSelectProduct: shape().isRequired,
    setLocationCustomPath: func,
    fetchingDownloadCertified: bool.isRequired,
};
DepositDetails.defaultProps = {
    setLocationCustomPath: () => {},
};

export default connect(mapStateToProps)(withRevelockPositionContext(DepositDetails));

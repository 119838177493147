export const ACCOUNT_ID_SESSION = "digitalSessionId";
// eslint-disable-next-line import/prefer-default-export
export const isFirstTimeOpen = () =>
    new Promise((resolve) => {
        if (!window?.cordova?.plugins?.InitializationDevicePlugin?.isFirstTimeOpenCommand) {
            resolve(false);
            return;
        }

        window.cordova.plugins.InitializationDevicePlugin.isFirstTimeOpenCommand(
            (isFirstTimeOpenResponse) => {
                const isFirstTimeValue = isFirstTimeOpenResponse === true || isFirstTimeOpenResponse === "true";
                resolve(isFirstTimeValue);
            },
            () => {
                resolve(false);
            },
        );
    });

export const setItemKeychainShared = (accountId, value) =>
    new Promise((resolve) => {
        if (!window?.cordova?.plugins?.InitializationDevicePlugin?.setItemKeychainShared) {
            resolve(false);
            return;
        }

        window.cordova.plugins.InitializationDevicePlugin.setItemKeychainShared(
            accountId,
            value,
            (response) => {
                resolve(response);
            },
            () => {
                resolve("0");
            },
        );
    });

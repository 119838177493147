import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Box from "pages/_components/Box";
import { bool, number as int, shape, string } from "prop-types";
import React, { Component } from "react";

class AdditionalCardItem extends Component {
    render() {
        const { isDesktop, additional, activeEnvironment } = this.props;
        const { iconType, type, brand, franchise, numberMask, avaible, client } = additional;
        const corpEnvironment = activeEnvironment?.type === "corporate";
        const cardLogo = brand === "visa" ? `${brand}-blue` : brand;

        const textColor = "heading";
        return (
            <>
                <GridTable.Data columnStart={1} rowWidth={isDesktop ? 1 : 2} alignX="center">
                    <Box display="flex">
                        <Image src={`images/icons/creditCard-${iconType}.svg`} className="svg-icon" />
                    </Box>
                </GridTable.Data>

                {corpEnvironment ? (
                    <GridTable.Data columnStart={2} alignX="flex-start" alignY={isDesktop ? "center" : "flex-end"}>
                        <Text color={textColor} ellipsis>
                            {client.name}
                        </Text>
                    </GridTable.Data>
                ) : (
                    <GridTable.Data columnStart={2} alignX="flex-start" alignY={isDesktop ? "center" : "flex-end"}>
                        <Text color={textColor} ellipsis labelKey={type} />
                    </GridTable.Data>
                )}
                <GridTable.Data
                    columnStart={isDesktop ? 3 : 2}
                    rowStart={isDesktop ? 1 : 2}
                    alignX={isDesktop ? "center" : "flex-start"}>
                    <Box display="flex">
                        <Image
                            wrapperWidth="auto"
                            wrapperHeight="auto"
                            {...(brand === "visa" && { height: "8" })}
                            src={`images/${cardLogo}-creditCard.svg`}
                            className="svg-icon mr-2"
                        />
                    </Box>
                    <Text color={textColor} ellipsis>
                        {`${franchise.charAt(0).toUpperCase()}${franchise
                            .slice(1)
                            .toLowerCase()} **** ${numberMask.slice(-4)}`}
                    </Text>
                </GridTable.Data>
                {isDesktop ? (
                    <GridTable.Data columnStart={4} alignX="flex-end">
                        <Text
                            ellipsis
                            size="6"
                            align="right"
                            color={textColor}
                            className="mr-md-4"
                            labelKey="creditCard.details.info.availableBalance.prePaid"
                        />

                        <FormattedAmount
                            color={textColor}
                            size="5"
                            bold
                            quantity={avaible}
                            className="data-amount justify-content-end"
                        />
                        <Image src="images/chevromRight.svg" color="text-color" className="svg-caret ml-4" />
                    </GridTable.Data>
                ) : (
                    <>
                        <GridTable.Data columnStart={3} alignX="flex-end" alignY="flex-end">
                            <Text
                                size="6"
                                color={textColor}
                                className="mr-md-4"
                                align="right"
                                labelKey="creditCard.details.info.availableBalance.prePaid"
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} rowStart={2}>
                            <FormattedAmount
                                color={textColor}
                                size="5"
                                bold
                                quantity={avaible}
                                className="data-amount justify-content-end"
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={4} rowWidth={2}>
                            <Image src="images/chevromRight.svg" color="text-color" className="svg-caret ml-2" />
                        </GridTable.Data>
                    </>
                )}
            </>
        );
    }
}

AdditionalCardItem.propTypes = {
    isDesktop: bool,
    additional: shape({
        iconType: string,
        type: string,
        brand: string,
        franchise: string,
        numberMask: string,
        avaible: int,
    }).isRequired,
    activeEnvironment: shape({
        type: string.isRequired,
    }),
};

AdditionalCardItem.defaultProps = {
    isDesktop: true,
    activeEnvironment: {},
};

export default resizableRoute(AdditionalCardItem);

/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import { arrayOf, bool, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import { resizableRoute } from "pages/_components/Resizable";

class Enviroments extends Component {
    static propTypes = {
        environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        activeEnvironment: shape({}),
        fromSetDefaultEnvironmentPage: bool,
        userData: shape({}),
        legendTextID: string,
        noLegendTextId: bool,
        whiteBoxed: bool,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        activeEnvironment: {},
        fromSetDefaultEnvironmentPage: false,
        userData: {},
        legendTextID: "settings.changeEnvironment",
        noLegendTextId: false,
        whiteBoxed: false,
    };

    handleClick = (value) => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
    };

    renderEnviromentName = ([id, environment]) => {
        const { activeEnvironment, fromSetDefaultEnvironmentPage, userData, whiteBoxed } = this.props;
        const environmentToCheck = fromSetDefaultEnvironmentPage ? userData.idDefaultEnvironment : activeEnvironment.id;
        const allowedToAccess = environment.allowedToAccess.toString() === "true";

        return (
            <>
                <Box
                    display="inline-flex"
                    alignX="flex-start"
                    alignY="center"
                    onClick={() => this.handleClick(id)}
                    fullWidth
                    {...(whiteBoxed && { background: "white", borderRadius: "md" })}
                    flex1
                    className={classNames("position-relative gap-3 min-height-7", {
                        "env-disabled": !allowedToAccess && !fromSetDefaultEnvironmentPage,
                        "pl-7 mb-5": !fromSetDefaultEnvironmentPage,
                        "mb-4": fromSetDefaultEnvironmentPage,
                        "mx-0 mb-3 py-4 px-5": whiteBoxed,
                    })}>
                    <Box
                        className="c-control c-control--has-icon c-control--checkbox display-flex gap-3 align-items-center"
                        position="relative">
                        <input
                            disabled={!allowedToAccess && !fromSetDefaultEnvironmentPage}
                            defaultChecked={Number(id) === environmentToCheck}
                            id={`environment-${id}`}
                            type="radio"
                            value={environment.name}
                            name="environment"
                            label={environment.name}
                            className="radio-btn"
                        />
                        <label htmlFor={`environment-${id}`} className={classNames("c-control-label")}>
                            <div className="c-control-icons">
                                <div className="c-control-mark">
                                    <Image src="images/radioButtonMark.svg" className="svg-icon svg-caret" />
                                </div>
                            </div>
                            {environment.name}
                        </label>
                    </Box>
                </Box>
            </>
        );
    };

    render() {
        const {
            environments,
            legendTextID,
            noLegendTextId,
            form: { errors },
            field,
            fromSetDefaultEnvironmentPage,
            isDesktop,
        } = this.props;

        const enviromentsCorporate = environments
            .filter((enviroment) => enviroment[1]?.environmentType === "corporate")
            .sort((a, b) => b[1]?.allowedToAccess.localeCompare(a[1]?.allowedToAccess));
        const enviromentsRetail = environments.filter((enviroment) => enviroment[1]?.environmentType === "retail");

        return (
            <>
                {legendTextID && !noLegendTextId && (
                    <Box display="flex" alignX="center">
                        <Text
                            className="mb-9 mt-0"
                            labelKey={legendTextID}
                            component="h3"
                            align="center"
                            size="3"
                            color="heading"
                            bold
                        />
                    </Box>
                )}
                <Box display="flex" column className="selection-list" position="relative">
                    <Box
                        className={classNames("form-group selection-list", {
                            "has-error": errors[field.name],
                            "mb-8 mb-md-9": !fromSetDefaultEnvironmentPage,
                            "mb-8": fromSetDefaultEnvironmentPage,
                        })}
                        opacityGradientScroll>
                        {enviromentsRetail && enviromentsRetail.length > 0 && (
                            <Box className="mb-6">
                                <Box className="enviroment-label-wrapper" display="flex">
                                    <Box display="flex">
                                        <Image src="images/icons/menu-enviroment-retail.svg" />
                                    </Box>
                                    <Text
                                        component="h4"
                                        className="enviroment-label-title"
                                        labelKey="enviroment.retail"
                                    />
                                </Box>
                                {enviromentsRetail.map(this.renderEnviromentName)}
                            </Box>
                        )}
                        <Box>
                            <Box className="enviroment-label-wrapper" display="flex">
                                <Box display="flex">
                                    <Image src="images/icons/menu-enviroment-corporate.svg" />
                                </Box>
                                <Text
                                    component="h4"
                                    className="enviroment-label-title"
                                    labelKey="enviroment.corporate"
                                />
                            </Box>
                            {enviromentsCorporate.map(this.renderEnviromentName)}
                        </Box>
                    </Box>
                    <Box position="absolute" bottom={isDesktop ? "5" : "3"} fullWidth>
                        {errors[field.name] && (
                            <div className="text-center">
                                <FieldError error={errors[field.name]} errorTooltipPosition="top-centered" />
                            </div>
                        )}
                    </Box>
                </Box>
            </>
        );
    }
}

export default resizableRoute(Enviroments);

import classNames from "classnames";
import { Field, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import TextField from "pages/_components/fields/TextField";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import {
    actions as beneficiaryActions,
    selectors as beneficiarySelectors,
} from "reducers/frequentDestination/frequentDestination";
import { compose } from "redux";
import { formatCreditCardNumber } from "util/creditCards";
import * as i18n from "util/i18n";

const ID_ACTIVITY = "beneficiary.selector";
const BeneficiarySelector = ({
    dispatch,
    onSelect,
    isDesktop,
    isFetching,
    beneficiaryType,
    beneficiaryList,
    productType,
    location,
    hideNewBeneficiaryButton,
}) => {
    const [beneficiaryListFilter, setBeneficiaryListFilter] = useState([]);
    const [searchText, setSearchText] = useState("");
    const { pathname } = location;
    useEffect(() => {
        dispatch(beneficiaryActions.getBeneficiaryList({ beneficiaryType, getExtraData: true, productType }));
        return () => {
            dispatch(beneficiaryActions.clearPreDataForm());
        };
    }, []);

    useEffect(() => {
        setBeneficiaryListFilter(beneficiaryList || []);
    }, [beneficiaryList]);

    const getColumnsTemplate = () => "1fr minmax(1rem, 1fr) 1.5rem";

    const renderNumber = (ptype, productNumber) => {
        if (ptype === "TC" && productNumber) {
            return formatCreditCardNumber(productNumber);
        }
        return productNumber;
    };

    const renderBeneficiaryItem = (beneficiary) => {
        if (!beneficiary) {
            return <div />;
        }

        const { name, alias, type, productType: ptype, productNumber, bankName, favorite } = beneficiary;

        return (
            <>
                <GridTable.Container
                    {...(isDesktop && { className: "px-9" })}
                    columnsTemplate={getColumnsTemplate()}
                    key="beneficiary.list.container.selector"
                    padding="py-3"
                    {...(onSelect && {
                        onClick: (e) => {
                            e.preventDefault();
                            onSelect(beneficiary);
                        },
                    })}>
                    <GridTable.Data className="p-0 mr-5" columnStart={1} alignX="flex-start">
                        <Box display="flex" column fullWidth>
                            <Box display="flex">
                                <Box display="flex" gapX="3" className="auto-full-width">
                                    <Text size={isDesktop ? "6" : "5"} color="secondary" bold ellipsis>
                                        {name || ""}
                                    </Text>
                                    {favorite && (
                                        <Box display="flex" alignY="center" alignX="flex-start">
                                            <Image src="images/icons/favoriteStarOn.svg" />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {alias && (
                                <Text size={isDesktop ? "6" : "5"} color="text" ellipsis>
                                    {alias}
                                </Text>
                            )}
                            <Text
                                color="text"
                                size={isDesktop ? "6" : "5"}
                                labelKey={`beneficiary.type.${(type || "").toLowerCase()}.label`}
                                ellipsis
                            />
                        </Box>
                    </GridTable.Data>
                    <GridTable.Data className="ml-5 p-0 min-height-3" columnStart={2} alignX="flex-end">
                        <Box display="flex" column fullWidth>
                            <Text
                                color="text"
                                size={isDesktop ? "6" : "5"}
                                align="right"
                                labelKey={`beneficiary.productType.${(type || "").toLowerCase()}.${(
                                    ptype || ""
                                ).toLowerCase()}.label`}
                                ellipsis
                                className="mb-1"
                            />
                            <Text color="text" size={isDesktop ? "6" : "5"} ellipsis align="right" className="mb-1">
                                {renderNumber(ptype, productNumber) || ""}
                            </Text>
                            <Text
                                color="text"
                                size={isDesktop ? "6" : "5"}
                                capitalize
                                ellipsis
                                align="right"
                                className="mb-1">
                                {bankName.toLowerCase() || ""}
                            </Text>
                        </Box>
                    </GridTable.Data>

                    <GridTable.Data className="p-0 ml-5" columnStart={3} alignX="flex-end">
                        <Image src="images/icons/chevronRight.svg" color="text-color" />
                    </GridTable.Data>
                </GridTable.Container>
            </>
        );
    };

    const renderList = () => (
        <>
            <Box {...(isDesktop && { className: "mx-n-9" })} fullHeight>
                <GridTable>
                    <GridTable.Body>
                        {beneficiaryListFilter && beneficiaryListFilter.map((item) => renderBeneficiaryItem(item))}
                    </GridTable.Body>
                </GridTable>
            </Box>
        </>
    );

    const filterDataBeneficiary = () => {
        if (!beneficiaryList) {
            setBeneficiaryListFilter([]);
            return;
        }

        if (!searchText || searchText === "") {
            setBeneficiaryListFilter([...beneficiaryList]);
            return;
        }

        const searchLower = searchText.toLowerCase();
        const filterBeneficiary = beneficiaryList.filter((beneficiary) => {
            const matchName = beneficiary.name && beneficiary.name.toLowerCase().includes(searchLower);
            const matchbankName = beneficiary.bankName && beneficiary.bankName.toLowerCase().includes(searchLower);
            let matchPRoductNumber =
                beneficiary.productNumber && beneficiary.productNumber.toLowerCase().includes(searchLower);
            matchPRoductNumber =
                matchPRoductNumber || (beneficiary.number && beneficiary.number.toLowerCase().includes(searchLower));
            const matchAlias = beneficiary.alias && beneficiary.alias.toLowerCase().includes(searchLower);
            return matchName || matchbankName || matchPRoductNumber || matchAlias;
        });

        setBeneficiaryListFilter(filterBeneficiary || []);
    };

    useEffect(() => {
        filterDataBeneficiary();
    }, [searchText]);

    const renderNewBeneficiary = () => (
        <Box display="flex" alignX="flex-end" className="pt-5 pb-5" border="bottom-background-divider-1">
            <Button
                label="beneficiary.list.create.title"
                onClick={() => {
                    dispatch(
                        routerActions.replace(
                            `/createFrequentDestination?type=${beneficiaryType[0]}&referencePage=${pathname}`,
                        ),
                    );
                }}
                image="images/plusCircle.svg"
                bsStyle="link"
            />
        </Box>
    );

    const renderHeaderSection = () => (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
            initialValues={{
                searchBeneficiaryTextValue: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const { searchBeneficiaryTextValue } = values;
                filterDataBeneficiary(searchBeneficiaryTextValue);
            }}>
            {() => (
                <Box
                    background="white"
                    className={classNames("pb-0 pb-md-12", { " mx-n-9 px-9": isDesktop })}
                    {...(isDesktop && { border: "bottom-background-divider-1" })}
                    fullwidth>
                    <Field
                        component={TextField}
                        hidePlaceholder
                        serarchStyle
                        idForm={ID_ACTIVITY}
                        name="searchBeneficiaryTextValue"
                        type="text"
                        showLabel={false}
                        placeholder={i18n.get("global.search")}
                        handleOnChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                    />
                </Box>
            )}
        </Formik>
    );

    return (
        <PageLoading loading={isFetching} classicStyle>
            <Box component="section" display="flex" column fullHeight fullWidth>
                {renderHeaderSection()}
                {!isDesktop && !hideNewBeneficiaryButton && renderNewBeneficiary()}
                {renderList()}
            </Box>
        </PageLoading>
    );
};

BeneficiarySelector.propTypes = {
    onSelect: func.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool,
    beneficiaryType: arrayOf(string),
    beneficiaryList: arrayOf(shape({})),
    productType: string,
    dispatch: func,
    location: shape({}),
    hideNewBeneficiaryButton: bool,
};

BeneficiarySelector.defaultProps = {
    dispatch: () => {},
    isFetching: false,
    beneficiaryType: [],
    beneficiaryList: [],
    productType: undefined,
    location: {},
    hideNewBeneficiaryButton: false,
};

const mapStateToProps = (state) => ({
    beneficiaryList: beneficiarySelectors.getBeneficiaryList(state),
    isFetching: beneficiarySelectors.isFetching(state),
});
export default compose(connect(mapStateToProps), withRouter)(BeneficiarySelector);

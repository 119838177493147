import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace, push, routerActions } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses.js";
import * as form from "middleware/form";
import { types, actions, selectors } from "reducers/administration/users";
import { actions as notificationActions } from "reducers/notification";
import * as administrationUsers from "middleware/administration/users";
import * as i18n from "util/i18n";
import * as utilDownload from "util/download";
import { credentialsToUnderscoreFormat } from "util/form.js";
import * as softtokenApi from "middleware/softToken/softToken";
import { USER_TOKEN_STATUS_ACTIVE, USER_TOKEN_STATUS_PENDING_APROVE } from "util/userToken.util";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreRequest),
    takeLatest(types.EXPORT_LIST_REQUEST, exportListRequest),
    takeLatest(types.CHANGE_USER_STATUS_PREVIEW, changeUserStatusPreview),
    takeLatest(types.CHANGE_USER_STATUS_CONFIRMATION, changeUserStatusConfirmation),
    takeLatest(types.USER_PERMISSION_ACTIVATOR_CONFIRMATION, userPermissionActivatorConfirmation),
    takeLatest(types.USER_PERMISSION_DEACTIVATOR_CONFIRMATION, userPermissionDeactivatorConfirmation),
    takeLatest(types.UPDATE_DISPATCHER_REQUEST, updateDispatcher),
    takeLatest(types.GET_USER_TOKEN_REQUEST, getUserTokenRequest),
    takeLatest(types.ACTIVATE_USER_TOKEN_REQUEST, activateUserTokenRequest),
    takeLatest(types.REJECT_USER_TOKEN_REQUEST, rejectUserTokenRequest),
];

export default sagas;

const actionToStatus = { block: "blocked", unblock: "active" };

function* loadListRequest(params) {
    const { filters } = params;
    const response = yield call(administrationUsers.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(actions.loadListSuccess(response.data.data));

        const { usersExtendedInfo } = response.data.data;
        yield* Object.keys(usersExtendedInfo).map(function*(userId) {
            const extendedInfo = usersExtendedInfo[userId];
            if (
                extendedInfo?.tokenStatus === USER_TOKEN_STATUS_ACTIVE ||
                extendedInfo?.tokenStatus === USER_TOKEN_STATUS_PENDING_APROVE
            ) {
                yield getUserStatus(userId);
            }
        });
    }
}

function* loadMoreRequest(params) {
    const { filters } = params;
    const response = yield call(administrationUsers.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put({
            type: types.LOAD_MORE_SUCCESS,
            data: response.data.data,
        });

        const { usersExtendedInfo } = response.data.data;
        yield* Object.keys(usersExtendedInfo).map(function*(userId) {
            const extendedInfo = usersExtendedInfo[userId];
            if (
                extendedInfo?.tokenStatus === USER_TOKEN_STATUS_ACTIVE ||
                extendedInfo?.tokenStatus === USER_TOKEN_STATUS_PENDING_APROVE
            ) {
                yield getUserStatus(userId);
            }
        });
    }
}

function* getUserStatus(idUser) {
    const response = yield call(softtokenApi.validateStatusToken, "", idUser);
    const tokenStatus = response.data.data?.tokenStatus ? response.data.data?.tokenStatus : "ERROR";

    const actualExtendedInfo = yield select(selectors.getUsersInfo);
    const tmpExtendedInfo = { ...actualExtendedInfo };
    tmpExtendedInfo[idUser].tokenStatus = tokenStatus;
    tmpExtendedInfo[idUser].loading = true;
    yield put({
        type: types.SET_USER_TOKEN_STATUS_SUCCESS,
        data: tmpExtendedInfo,
    });
}

function* exportListRequest(params) {
    const { type, data } = yield call(administrationUsers.exportListRequest, params);
    const { format } = params;

    if (type === "W") {
        yield put({ type: types.EXPORT_LIST_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            utilDownload.downloadPdf(fileName, content);
        } else {
            utilDownload.downloadXls(fileName, content);
        }
        yield put({ type: types.EXPORT_LIST_SUCCESS });
    }
}

function* changeUserStatusPreview({ userList, userNameList, userAction }) {
    const params = {
        userIdList: userList,
        userNameList,
        newStatus: actionToStatus[userAction],
    };
    let response = null;
    if (userAction === "block" || userAction === "unblock") {
        response = yield call(administrationUsers.changeUsersStatusPreview, params);
    } else if (userAction === "delete") {
        response = yield call(administrationUsers.deleteUsersPreview, params);
    }
    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.users.blockunblock.send",
        );
        const credentialGroups = responseCredentials.data.data.groups;
        yield put({
            type: types.CHANGE_USER_STATUS_PREVIEW_SUCCESS,
            userList,
            userNameList,
            userAction,
            credentialGroups,
        });
        yield put(push(`/administration/confirmUserAction`));
    }
}

function* changeUserStatusConfirmation({ usersToApplyAction, userNameList, userAction, credentials, formikBag }) {
    const params = {
        userIdList: usersToApplyAction,
        userNameList,
    };
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    let response = null;
    let successMessageSubKey = userAction;
    if (userAction === "block" || userAction === "unblock") {
        response = yield call(
            administrationUsers.changeUsersStatusConfirmation,
            { ...params, newStatus: actionToStatus[userAction] },
            credentialsWithUnderscore,
        );
        successMessageSubKey = actionToStatus[userAction];
    } else if (userAction === "delete") {
        response = yield call(administrationUsers.deleteUsersConfirmation, params, credentialsWithUnderscore);
    }

    formikBag.setSubmitting(false);
    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put(replace("/administration/users"));
            yield put(notificationActions.showNotification(response.data.data.NO_FIELD, "error", ["administration"]));
        } else {
            formikBag.setErrors(response.data.data);
            yield put(replace("/administration/confirmUserAction"));
        }
    } else {
        if (response.data.code && response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            yield put(notificationActions.showNotification(response.data.message, "success", ["administrationUsers"]));
        } else {
            let successMessageKey = `administration.users.${successMessageSubKey}.success`;
            if (usersToApplyAction.length > 1) {
                successMessageKey = `${successMessageKey}.plural`;
            }
            yield put(
                notificationActions.showNotification(i18n.get(successMessageKey), "success", ["administrationUsers"]),
            );
        }
        yield put(replace("/administration/users"));
    }
}

function* userPermissionActivatorConfirmation({ idUser, userName, permission, credentials, formikBag }) {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(
        administrationUsers.userPermissionActivatorConfirmation,
        { idUser, userName, permission },
        credentialsWithUnderscore,
    );
    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);

    if (response.type === "W") {
        if (response.data && response.data.code === "COR020W") {
            const errorMessage = i18n.get(`administration.medium.activate.${permission}.send.error`);
            setErrors(response.data.data);
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        }
    } else {
        yield put(routerActions.goBack());
        yield put(
            notificationActions.showNotification(
                i18n.get(`administration.permission.${permission}.activate.snackbar`),
                "success",
                ["administration"],
            ),
        );
    }
}

function* userPermissionDeactivatorConfirmation({ idUser, userName, permission, credentials, formikBag }) {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(
        administrationUsers.userPermissionDeactivatorConfirmation,
        { idUser, userName, permission },
        credentialsWithUnderscore,
    );
    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);

    if (response.type === "W") {
        if (response.data && response.data.code === "COR020W") {
            const errorMessage = i18n.get(`administration.medium.deactivate.${permission}.send.error`);
            setErrors(response.data.data);
            yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        }
    } else {
        yield put(routerActions.goBack());
        yield put(
            notificationActions.showNotification(
                i18n.get(`administration.permission.${permission}.deactivate.snackbar`),
                "success",
                ["administration"],
            ),
        );
    }
}

function* updateDispatcher({ data, formikBag }) {
    const { dispatcher, userId, credentials, ...rest } = data;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(administrationUsers.updateDispacther, {
        ...rest,
        ...credentialsWithUnderscore,
        userId,
        dispatcher,
    });
    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);
    if (response.type === "W") {
        setErrors(response.data.data);
        const errorMessage = response.data.message || i18n.get("global.unexpectedError");
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        yield put(actions.updateDispatcherFailure());
    } else {
        yield put(notificationActions.showNotification(response.data.message, "success", ["administration"]));
        yield put(routerActions.goBack());
        yield put(actions.updateDispatcherSuccess(response.data.data.userExtendedInfo));
    }
}

function* getUserTokenRequest(params) {
    const { idUser } = params;

    const response = yield call(administrationUsers.getUserToken, idUser);

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administrationUsers"]),
        );
    } else {
        yield put(actions.getUserTokenSuccess(response.data.data?.userTokenInfo));
    }
}

function* activateUserTokenRequest({ data, formikBag }) {
    const { idUser, fullName, credentials } = data;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    const response = yield call(administrationUsers.activateUserToken, idUser, fullName, credentialsWithUnderscore);
    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);
    if (response.type === "W") {
        const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");
        setErrors(response.data.data);
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("administration.userToken.activate.success"), "success", [
                "administration",
            ]),
        );
        yield put(routerActions.goBack());
        yield put(actions.activateUserTokenSuccess());
    }
}

function* rejectUserTokenRequest({ data }) {
    const { idUser, fullName } = data;

    const response = yield call(administrationUsers.rejectUserToken, idUser, fullName);

    if (response.type === "W") {
        const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
        yield put(actions.rejectUserTokenFailure());
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("administration.userToken.reject.success"), "success", [
                "administration",
            ]),
        );
        yield put(routerActions.goBack());
        yield put(actions.rejectUserTokenSuccess());
    }
}

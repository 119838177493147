import React, { Component } from "react";
import { string } from "prop-types";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";

class TokeStatusQR extends Component {
    static propTypes = {
        tokenStatus: string.isRequired,
    };

    render() {
        const { tokenStatus } = this.props;
        return (
            <>
                <Box display="flex" column alignX="between" className="scrollable-content px-0" fullWidth fullHeight>
                    <Box display="flex" column alignY="center" className="mb-auto">
                        <Text
                            key="text"
                            component="p"
                            className="m-0"
                            color="heading"
                            align="center"
                            labelKey={`token.drawer.${tokenStatus}.text`}
                        />

                        <Box className="my-9">
                            <Image key="src" src="images/qrCodes/qrEnollmentCode.svg" />
                        </Box>
                        <Text
                            key="disclaimer1"
                            component="p"
                            className="m-0"
                            color="heading"
                            align="center"
                            labelKey={
                                tokenStatus === "migrate" || tokenStatus === "info"
                                    ? "token.drawer.disclaimer2"
                                    : "token.drawer.disclaimer"
                            }
                        />
                    </Box>
                </Box>
            </>
        );
    }
}

export default TokeStatusQR;

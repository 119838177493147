import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { func, shape, string } from "prop-types";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const Info1 = ({ image, text, href, onClick, externalHref, actionLabel, textParams }) => {
    const Component = useMemo(() => {
        if (href) {
            return Link;
        }

        return externalHref ? "a" : "button";
    }, []);

    return (
        <Component onClick={onClick} type="button" className="pre-approved-button">
            <Box display="flex" background="menu-background" className="min-height-12">
                <Box display="flex" alignY="center" background="wally" className="pl-5 pr-1 py-3" gap="4" fullWidth>
                    <Box>
                        <Image src={image} className="pre-approved-image" />
                    </Box>
                    <Text
                        size="6"
                        color="text-inverse-color"
                        className="mx-auto pr-3"
                        labelKey={text}
                        textParams={textParams}
                        align="left"
                    />
                </Box>
                <Box
                    display="flex"
                    alignY="center"
                    background="pre-approved-background"
                    className="px-3 min-width-6rem">
                    <Text color="wally" size="5" bold>
                        {actionLabel}
                    </Text>
                </Box>
            </Box>
        </Component>
    );
};

Info1.propTypes = {
    image: string,
    text: string,
    externalHref: string,
    actionLabel: string,
    href: string,
    onClick: func,
    textParams: shape({}),
};

Info1.defaultProps = {
    image: null,
    text: null,
    externalHref: null,
    actionLabel: null,
    href: null,
    onClick: null,
    textParams: {},
};

export default Info1;

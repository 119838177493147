import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import Captcha from "pages/_components/fields/credentials/Captcha";
import HeaderEnrollment from "pages/enrollment/_components/HeaderEnrollment";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import * as maskUtils from "util/mask";
import * as Yup from "yup";

const FORM_ID = "enrollment.wizard.validateInvitationCode";

const InvitationCode = (props) => {
    const { isSubmitting, showCaptcha, isDesktop, location } = props;
    const isSecundary = location?.pathname.includes("/enrollment/invitationCodeSecundary");
    const cancel = () => {
        const { dispatch } = props;
        dispatch(push("/enrollment"));
    };

    const goRequestCode = () => {
        const { dispatch } = props;
        dispatch(enrollmentActions.cleanDocument());
        dispatch(push("/enrollment/document"));
    };
    return (
        <>
            <HeaderEnrollment title="enrollment.invitationCode.title" subtitle="enrollment.invitationCode.subtitle" />

            <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                <Row gapY={isDesktop ? "8" : "3"} className="mb-5 mb-md-8 height-auto">
                    <Col xs={12} md={8} mdOffset={2}>
                        <Box background="white" borderRadius="xxl" className="white-boxed pt-7 pt-md-11 pb-7 pb-md-8">
                            <Box className="mb-5" fullWidth>
                                <Field
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    mask={maskUtils.invitationCodeMask()}
                                    maxLength={50}
                                    name="code"
                                    labelNoMarginTop
                                />
                            </Box>
                            <Box
                                display="flex"
                                column
                                alignX="center"
                                alignY="center"
                                fullWidth
                                className="mb-3 mb-md-10">
                                {isSecundary ? (
                                    <Text
                                        labelKey="enrollment.invitationCode.secundary.help"
                                        align="center"
                                        className="mb-1 mb-md-3"
                                    />
                                ) : (
                                    <>
                                        <Text
                                            labelKey="enrollment.invitationCode.invitationCode.help"
                                            align="center"
                                            className="mb-1 mb-md-3"
                                        />

                                        <Button
                                            bsStyle="link"
                                            onClick={goRequestCode}
                                            label="enrollment.invitationCode.resendCode"
                                            secondary
                                            small
                                        />
                                    </>
                                )}
                            </Box>

                            {showCaptcha && (
                                <Box display="flex" alignX="center" fullWidth className="mb-5">
                                    <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                                </Box>
                            )}

                            {!isSecundary && (
                                <>
                                    <Box display="flex" alignX="center" fullWidth className="px-0 px-md-12 pb-3">
                                        <Text
                                            labelKey="enrollment.invitationCode.contactUs"
                                            align="center"
                                            size="7"
                                            className="mx-0 mx-md-8 "
                                        />
                                    </Box>

                                    <Box
                                        display="flex"
                                        alignX="center"
                                        {...(!isDesktop && { alignY: "center", column: true })}
                                        wrap>
                                        <Box>
                                            <Text labelKey="enrollment.invitationCode.contactUsLinks.dani" size="7" />
                                            <Button
                                                bsStyle="link"
                                                className="link-styled"
                                                secondary
                                                small
                                                externalHref={config.get("accessBar.chat.url")}
                                                label="contactUs.whatsapp.text"
                                            />
                                        </Box>
                                        <Box>
                                            <Button
                                                bsStyle="link"
                                                className="link-styled"
                                                secondary
                                                small
                                                externalHref={config.get("contactUs.chatEnLinea")}
                                                label="contactUs.chatEnLinea.text"
                                            />
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Col>
                </Row>
                <Box {...(!isDesktop && { className: "mt-auto" })}>
                    <Row gapY={isDesktop ? "8" : "3"}>
                        <Col xs={12} md={3} mdOffset={3}>
                            <Button bsStyle="outline" label="global.cancel" type="button" onClick={cancel} block />
                        </Col>
                        <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                type="submit"
                                block
                            />
                        </Col>
                    </Row>
                </Box>
            </Form>
        </>
    );
};

InvitationCode.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    showCaptcha: bool.isRequired,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    isSecundary: bool.isRequired,
};

const mapStateToProps = (state) => ({
    showCaptcha: enrollmentSelectors.getShowCaptchaInvitationCode(state),
    isSecundary: state.isSecundary,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            code: props.match.params.invitationCode || "",
            captcha: "",
            isSecundary: props.location?.pathname.includes("/enrollment/invitationCodeSecundary") || false,
        }),
        validationSchema: ({ showCaptcha }) =>
            Yup.object().shape({
                code: Yup.string().required(i18n.get(`${FORM_ID}.code.empty`)),
                captcha: showCaptcha ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.empty`)) : Yup.string(),
            }),
        handleSubmit: ({ code, captcha, isSecundary }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.validateInvitationCode(code, captcha, isSecundary, formikBag));
        },
    }),
)(InvitationCode);

import { Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import PermissionsForm from "pages/administration/_components/PermissionsForm";
import { arrayOf, bool, element, func, number as num, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { selectors as sessionSelectors } from "reducers/session";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import { permissionsSelectors } from "reducers/administration";
import { permissionsActions } from "reducers/administration/medium";

// import AdministrationHeading from "pages/administration/_components/Heading";

const groupShape = {
    idItem: string,
    label: string,
    ordinal: num,
};

groupShape.childrenList = arrayOf(shape(groupShape));

const PAGE_IDENTIFIER = "administration.permissions";

class Permissions extends Component {
    static propTypes = {
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        actions: shape({
            loadPermissionsRequest: func.isRequired,
            updatePermissionsPreview: func.isRequired,
        }).isRequired,
        products: arrayOf(shape(groupShape)).isRequired,
        groups: arrayOf(
            shape({
                idItem: string.isRequired,
                label: string.isRequired,
                ordinal: num.isRequired,
            }),
        ).isRequired,
        permissions: shape({}),
        fetching: bool,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        confirmRoute: element.isRequired,
        user: shape({}).isRequired,
        dispatch: func.isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        setLocationCustomPath: func.isRequired,
        transfersPermissions: shape([]).isRequired,
        paymentsPermissions: shape([]).isRequired,
        transfersInitialPermissions: shape([]).isRequired,
        paymentsInitialPermissions: shape([]).isRequired,
    };

    static defaultProps = {
        permissions: null,
        fetching: false,
    };

    state = {
        initialPermissions: null,
    };

    componentDidMount() {
        const { match, actions, transfersPermissions, paymentsPermissions } = this.props;

        if (!transfersPermissions.length || !paymentsPermissions.length) {
            actions.loadPermissionsRequest(match.params.id);
        }
        this.setRevelockLocation();

        const initialPermissions = this.getPermissions();
        this.setState({
            initialPermissions,
        });
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    permissionValue = (permissionList) => {
        if (permissionList && permissionList[0].productTypes) {
            return permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`);
        }

        return ["NONE"];
    };

    getPermissions = () => {
        const { groups, permissions } = this.props;
        const permissionsListToModify = { ...permissions };

        groups
            .filter((g) => g.childrenList.length > 0)
            .forEach((groupFiltered) => {
                const permissionListFiltered = groupFiltered?.childrenList.filter((gf) => gf.permissionList.length > 1);
                if (permissionListFiltered?.length && permissionListFiltered?.length > 0) {
                    permissionListFiltered.forEach((permissionFiltered) => {
                        const { idItem } = permissionFiltered;

                        const idPermissions = permissionFiltered?.permissionList.map(
                            ({ idPermission }) => idPermission,
                        );

                        if (idPermissions.length > 1) {
                            let isGroupPermissionActive = false;
                            idPermissions.forEach((permission) => {
                                isGroupPermissionActive =
                                    permissionsListToModify[permission] !== null &&
                                    permissionsListToModify[permission] !== undefined &&
                                    permissionsListToModify[permission].length !== 0;
                            });

                            if (isGroupPermissionActive) {
                                permissionsListToModify[idItem] = this.permissionValue(idItem);
                            }
                        }
                    });
                }
            });
        return permissionsListToModify;
    };

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions, transfersPermissions, paymentsPermissions } = this.props;
        actions.saveLocalPermissionEdition(values, "other");
        actions.updatePermissionsPreview(
            {
                permissions: values,
                idUser: match.params.id,
                permissionsUi: {
                    transfers: transfersPermissions,
                    payments: paymentsPermissions,
                },
            },
            setSubmitting,
        );
    };

    handleCancel = () => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
            user: { idUser },
        } = this.props;
        const path = {
            simple: "simple/permissions",
            medium: "medium/details",
            advanced: "advanced/details",
        };

        dispatch(push(`/administration/${path[administrationScheme]}/${idUser}`));
    };

    amountPermissionArEqual = (permissionList1, permissionList2) => {
        if (permissionList1.length !== permissionList2.length) {
            return false;
        }
        for (let i = 0; i < permissionList1.length; i++) {
            const p1 = permissionList1[i];
            const p2 = permissionList2[i];
            if (
                p1.selected !== p2.selected ||
                p1.amountOfSignatures !== p2.amountOfSignatures ||
                p1.maxSignatureAmount.quantity !== p2.maxSignatureAmount.quantity ||
                p1.maxAutonomyAmount.quantity !== p2.maxAutonomyAmount.quantity
            ) {
                return false;
            }
            for (let j = 0; j < p1.accounts.length; j++) {
                const accountP1 = p1.accounts[j];
                const accountP2 = p2.accounts.find((p) => p.idProduct === accountP1.idProduct);
                if (!accountP2 || accountP1.selected !== accountP2.selected) {
                    return false;
                }
            }
        }
        return true;
    };

    valuesUnchanged = (values) => {
        const {
            transfersInitialPermissions,
            transfersPermissions,
            paymentsInitialPermissions,
            paymentsPermissions,
        } = this.props;
        const { initialPermissions } = this.state;

        if (values && initialPermissions) {
            const keys = Object.keys(values);
            for (let i = 0; i < keys.length; i++) {
                const permissionId = keys[i];
                let value = values[permissionId];
                let initialValue = initialPermissions[permissionId];
                if (permissionId === "product.read") {
                    value = value.filter((f) => !f.startsWith("ALL_"));
                    initialValue = initialValue.filter((f) => !f.startsWith("ALL_"));
                }

                if (value && initialValue && value.length === initialValue.length) {
                    for (let j = 0; j < value.length; j++) {
                        if (!initialValue.includes(value[j])) {
                            return false;
                        }
                    }
                } else if ((value && value.length) || initialValue) {
                    return false;
                }
            }
        }

        return (
            this.amountPermissionArEqual(transfersInitialPermissions, transfersPermissions) &&
            this.amountPermissionArEqual(paymentsInitialPermissions, paymentsPermissions)
        );
    };

    renderContent = ({ isSubmitting, ...props }) => {
        const { fetching, groups, user } = this.props;
        const isLoading = fetching && !groups.length;
        const userStatus = user?.status?.idUserStatus;
        const status = () => {
            if (userStatus === "active") {
                return "success";
            }
            if (userStatus === "blocked") {
                return "error";
            }
            if (userStatus === "pending") {
                return "warning";
            }
            return null;
        };

        return (
            <>
                {/* <AdministrationHeading /> */}
                <MainContainer showLoader={isLoading || fetching}>
                    <Form>
                        <Box
                            display="flex"
                            column
                            background="component-background"
                            borderRadius="default"
                            className="px-8 py-7 mb-7"
                            fullHeight>
                            <Box display="flex">
                                <Text
                                    component="label"
                                    labelKey="administration.user.label"
                                    className="m-0"
                                    color="heading-color"
                                    bold
                                    addColon
                                />

                                <Text component="label" className="my-0 mr-5" color="heading-color" regular>
                                    {user?.fullName}
                                </Text>

                                <Sticker
                                    labelKey={`user.status.${userStatus}`}
                                    uppercase={false}
                                    bold
                                    status={status()}
                                    textSize="7"
                                    addPaddingY
                                />
                            </Box>
                        </Box>

                        <Text
                            component="h2"
                            labelKey="administration.permissions.functionalities"
                            className="mt-0 mb-5"
                            size="3"
                            color="heading-color"
                            bold
                        />
                        <Box className="mb-9">
                            <PermissionsForm {...props} user={user} />
                        </Box>

                        <Row>
                            <Col xs={3} xsOffset={3}>
                                <Button bsStyle="outline" label="global.cancel" onClick={this.handleCancel} block />
                            </Col>
                            <Col xs={3}>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="administration.permissions.modify"
                                    loading={isSubmitting}
                                    disabled={this.valuesUnchanged(props.values)}
                                    block
                                />
                            </Col>
                        </Row>
                    </Form>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching, groups, routerActions, match, confirmRoute, dispatch } = this.props;
        const isLoading = fetching && !groups.length;
        const statePermissions = this.getPermissions();
        return (
            <Formik initialValues={statePermissions} onSubmit={this.handleSubmit} enableReinitialize={isLoading}>
                {(props) => (
                    <Switch>
                        <Route path={match.url} exact>
                            <>
                                <Head
                                    title="administration.permissions.configurePermissions"
                                    onBack={() => {
                                        dispatch(permissionsActions.resetLocalPermissionEdition());
                                        routerActions.goBack();
                                    }}
                                />

                                {this.renderContent(props)}
                            </>
                        </Route>
                        <Route>{confirmRoute}</Route>
                    </Switch>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transfersPermissions: permissionsSelectors.getTrasnfersEditionResult(state),
    paymentsPermissions: permissionsSelectors.getPaymentsEditionResult(state),
    transfersInitialPermissions: permissionsSelectors.getTransfersInitialPermissions(state),
    paymentsInitialPermissions: permissionsSelectors.getPaymentsInitialPermissions(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(Permissions));

import { isAndroidPlatform, isIOSPlatform } from "util/device";
import TagManager from "react-gtm-module";

export const getMobileTagManagetId = (configRef) => {
    if (!configRef) {
        return undefined;
    }

    if (isIOSPlatform()) {
        return configRef.get("google.tag.manager.id.ios", "");
    }

    if (isAndroidPlatform()) {
        return configRef.get("google.tag.manager.id.android", "");
    }

    return undefined;
};

export const setDataLayer = async (props) => {
    try {
        const tagManagerArgs = {
            dataLayer: {
                ...(props || {}),
            },
        };

        TagManager.dataLayer(tagManagerArgs);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

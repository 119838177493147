import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import CalendarRestriction from "pages/administration/restrictions/CalendarRestriction";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push, routerActions } from "react-router-redux/actions";
import {
    INITIAL_STATE,
    actions as restrictionActions,
    selectors as restrictionSelectors,
} from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";
import * as Yup from "yup";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const PAGE_IDENTIFIER = "administration.restrictions";

// NO BORRAR (IMPLEMENTACION PRODUCTO)
// import IPRestriction from "pages/administration/restrictions/IPRestriction";

const isCalendarToSave = (values) =>
    INITIAL_STATE.days !== values.days ||
    INITIAL_STATE.startTime !== values.startTime ||
    INITIAL_STATE.endTime !== values.endTime ||
    INITIAL_STATE.perpetual !== values.perpetual ||
    INITIAL_STATE.timeZone !== values.timeZone;

class Restrictions extends Component {
    static propTypes = {
        days: number.isRequired,
        dispatch: func.isRequired,
        activeEnvironmentId: number.isRequired,
        perpetual: bool.isRequired,
        idUser: string,
        values: shape({}),
        history: shape({}).isRequired,
        timeZones: arrayOf(string),
        fetching: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        userName: string,
        restrictions: shape({}).isRequired,
        dataToSave: shape({}),
        calendarEnabled: bool.isRequired,
        startTime: string.isRequired,
        endTime: string.isRequired,
        timeZone: string.isRequired,
        mode: string.isRequired,
        match: shape({}).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    static defaultProps = {
        idUser: undefined,
        values: {},
        timeZones: [],
        userName: "",
        dataToSave: {},
    };

    componentDidMount() {
        const { dispatch, mode, dataToSave, idUser } = this.props;
        this.preLoadInfo();
        if (mode === "editing" && idUser === dataToSave.idUser) {
            dispatch(restrictionActions.manageRestrictionsDataToSave(dataToSave, "pre"));
        } else {
            dispatch(restrictionActions.manageRestrictionsDataToSave(null, "pre"));
        }
        this.setRevelockLocation();
    }

    componentDidUpdate(prevProps) {
        const { idUser } = this.props;
        if (prevProps.idUser !== idUser) {
            this.preLoadInfo();
        }
    }

    componentWillUnmount() {
        const { dispatch, mode } = this.props;
        if (mode !== "editing") {
            dispatch(restrictionActions.manageRestrictionsDataToSave(null, "pre"));
        }
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", PAGE_IDENTIFIER);
        }
    };

    shouldActivateSaveButton = () => {
        const { values } = this.props;
        return !isCalendarToSave(values) && values.idUser && values.ipsList.length === 0 && values.anyIP === false;
    };

    onCancelClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    preLoadInfo() {
        const { dispatch, timeZones, idUser, mode } = this.props;
        if (mode !== "editing") {
            dispatch(restrictionActions.setInitialState());
        }

        if (timeZones.length === 0) {
            dispatch(restrictionActions.manageRestrictionsPre());
        }
        dispatch(restrictionActions.getRestrictionsAvailability());
        if (typeof idUser === "undefined") {
            dispatch(restrictionActions.getEnvironmentRestrictionsData());
        } else {
            dispatch(restrictionActions.getUserRestrictionsData(idUser));
        }
    }

    render() {
        const {
            idUser,
            fetching,
            activeEnvironment,
            userName,
            isFirstFetching,
            values,
            days,
            startTime,
            endTime,
            timeZone,
            calendarEnabled,
        } = this.props;

        const isFormPreValid = () => {
            let isValid = true;
            if (idUser) {
                if (days > 0) {
                    isValid =
                        days === values?.days &&
                        startTime === values?.startTime &&
                        endTime === values?.endTime &&
                        timeZone === values?.timeZone;
                } else {
                    isValid = values?.startTime === values?.endTime && !values?.timeZone && !values?.days;
                }
            } else if (!values.calendarEnabled) {
                isValid = !calendarEnabled;
            } else if (calendarEnabled) {
                isValid =
                    days === values?.days &&
                    startTime === values?.startTime &&
                    endTime === values?.endTime &&
                    timeZone === values?.timeZone;
            } else {
                isValid = values?.startTime === values?.endTime && !values?.timeZone && !values?.days;
            }

            return isValid;
        };

        const isEnvironmentRestrictions = typeof idUser === "undefined";

        return (
            <Fragment>
                <Notification scopeToShow="restrictions" />

                <Head
                    title={
                        isEnvironmentRestrictions
                            ? "administration.restrictions.environmentRestrictions.title"
                            : "administration.restrictions.userRestrictions.title"
                    }
                    onBack={this.handleBack}
                />
                <MainContainer showLoader={fetching && isFirstFetching}>
                    <Form>
                        {isEnvironmentRestrictions && (
                            <Box
                                display="flex"
                                background="component-background"
                                borderRadius="default"
                                className="pl-8 pr-5 py-7 mb-5">
                                <EnvironmentTag name={activeEnvironment.name} type={activeEnvironment.type} />
                            </Box>
                        )}
                        {!isEnvironmentRestrictions && (
                            <Box
                                display="flex"
                                alignY="center"
                                background="component-background"
                                borderRadius="default"
                                className="pl-8 pr-5 py-7 mb-5"
                                gap="2">
                                <Box display="flex">
                                    <Image src="images/icons/enviroment-retail.svg" />
                                </Box>
                                <Text>{userName}</Text>
                            </Box>
                        )}

                        <Box
                            display="flex"
                            alignY="center"
                            background="component-background"
                            borderRadius="default"
                            className="mb-9"
                            gap="2">
                            <CalendarRestriction {...this.props} idUser={idUser} />
                        </Box>
                        {/* NO BORRAR (IMPLEMENTACION PRODUCTO) */}
                        {/* <IPRestriction {...this.props} idUser={idUser} /> */}

                        <Row>
                            <Col xs={3} xsOffset={3}>
                                <Button
                                    bsStyle="outline"
                                    className="btn btn-outline "
                                    label="global.cancel"
                                    onClick={this.onCancelClick}
                                    block
                                />
                            </Col>
                            <Col xs={3}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    type="submit"
                                    block
                                    loading={fetching}
                                    disabled={isFormPreValid()}
                                />
                            </Col>
                        </Row>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    days: restrictionSelectors.getDays(state),
    startTime: restrictionSelectors.getStartTime(state),
    endTime: restrictionSelectors.getEndTime(state),
    timeZone: restrictionSelectors.getTimeZone(state),
    perpetual: restrictionSelectors.getPerpetual(state),
    ipsList: restrictionSelectors.getIpsList(state),
    restrictions: restrictionSelectors.getRestrictions(state),
    timeZones: restrictionSelectors.getTimezones(state),
    fetching: restrictionSelectors.getFetching(state),
    isFirstFetching: restrictionSelectors.getFirstFetch(state),
    anyIP: restrictionSelectors.getAnyIP(state),
    calendarRestrictionId: restrictionSelectors.getCalendarRestrictionId(state),
    activeEnvironmentId: sessionSelectors.getActiveEnvironment(state).id,
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idUser: match.params.userId,
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
    userName: restrictionSelectors.getUserName(state),
    mode: restrictionSelectors.getMode(state),
    dataToSave: restrictionSelectors.getDataToSave(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const { userId } = props.match.params;
            const { dataToSave } = props;
            const valueDays = dataToSave?.days || props.days;
            const allDays = valueDays === 127;

            if (userId || !!userId || dataToSave?.calendarEnabled || props.calendarEnabled) {
                return {
                    days: dataToSave?.days || props.days,
                    startTime: dataToSave?.startTime || props.startTime,
                    endTime: dataToSave?.endTime || props.endTime,
                    timeZone: dataToSave?.timeZone || props.timeZone,
                    perpetual: dataToSave?.perpetual || props.perpetual,
                    ipsList: dataToSave?.ipsList || props.ipsList,
                    anyIP: dataToSave?.anyIP || props.anyIP,
                    calendarEnabled: !!userId || dataToSave?.calendarEnabled || props.calendarEnabled,
                    iPEnabled: dataToSave?.iPEnabled || props.iPEnabled,
                    idUser: userId,
                    allDays,
                };
            }
            return {
                days: 0,
                startTime: "00:00",
                endTime: "00:00",
                timeZone: null,
                perpetual: false,
                ipsList: [],
                anyIP: false,
                calendarEnabled: !!userId || dataToSave?.calendarEnabled || props.calendarEnabled,
                iPEnabled: false,
                allDays: false,
            };
        },
        validationSchema: () => {
            const EMPTY_FIELD_ERROR = "administration.restrictions.field.error.empty";
            return Yup.lazy((values) => {
                const shouldValidateCalendarFields = values.calendarEnabled;

                return Yup.object().shape({
                    days: shouldValidateCalendarFields
                        ? Yup.number()
                              .moreThan(0, i18nUtils.get(EMPTY_FIELD_ERROR))
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                        : Yup.number(),
                    timeZone: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .nullable()
                        : Yup.string().nullable(),
                    startTime: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .matches(
                                  new RegExp(maskUtils.timeRegex(), "g"),
                                  i18nUtils.get("administration.restrictions.error.startTime.invalid"),
                              )
                        : Yup.string().notRequired(),
                    endTime: shouldValidateCalendarFields
                        ? Yup.string()
                              .required(i18nUtils.get(EMPTY_FIELD_ERROR))
                              .matches(
                                  new RegExp(maskUtils.timeRegex(), "g"),
                                  i18nUtils.get("administration.restrictions.error.endTime.invalid"),
                              )
                              .test(
                                  "endTime",
                                  i18nUtils.get("administration.restrictions.error.startTimeGreaterThanEnd"),
                                  () => {
                                      const { startTime, endTime } = values;
                                      return (
                                          startTime !== undefined &&
                                          endTime !== undefined &&
                                          endTime.localeCompare(startTime) > 0
                                      );
                                  },
                              )
                        : Yup.string().notRequired(),
                });
            });
        },
        handleSubmit: (fields, formikBag) => {
            const { dispatch, calendarRestrictionId, activeEnvironmentId, calendarEnabled } = formikBag.props;
            const calendarToSave = isCalendarToSave(fields);
            let actionRestrinction =
                (!!fields.idUser && !calendarRestrictionId) || (!fields.idUser && !calendarEnabled) ? "Save" : "Modify";
            actionRestrinction = !fields.calendarEnabled ? "Delete" : actionRestrinction;
            const dataToSave = {
                ...fields,
                calendarRestrictionId,
                calendarToSave,
                environmentId: activeEnvironmentId,
                days: fields.allDays ? 127 : fields.days,
                actionRestrinction,
            };
            if (dataToSave.idUser) {
                delete dataToSave.calendarEnabled;
                delete dataToSave.iPEnabled;
                dataToSave.isEnvironmentRestrictions = false;
            } else {
                dataToSave.perpetual = false;
                dataToSave.isEnvironmentRestrictions = true;
            }
            dispatch(restrictionActions.manageRestrictionsConfirmPre(dataToSave, formikBag));
        },
    }),
)(withRevelockPositionContext(Restrictions));

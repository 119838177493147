import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";
import { actions as userActions } from "reducers/administration/users";
import * as form from "middleware/form";
import * as i18n from "util/i18n";
import { credentialsToUnderscoreFormat } from "util/form.js";

const loadDetailsRequest = (middleware, actions) =>
    function* loadRequest({ id }) {
        const response = yield call(middleware.loadDetailsRequest, id);

        if (response.type === "W") {
            yield put(actions.loadDetailsFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.medium.modify.signature.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;

            const permissions = response?.data?.data?.permissions;
            const userGroups = response?.data?.data?.groups;

            const groupsIdItemToFind = ["nonFinancialOperations", "transfers", "payments"];

            const hasEnableApproveCreate = userGroups.some((group) => {
                const propContainsIdItem = groupsIdItemToFind.includes(group.idItem);
                const hasNonEmptyArray = Array.isArray(group.childrenList) && group.childrenList.length > 0;
                return propContainsIdItem && hasNonEmptyArray;
            });

            const userExtendedInfo = {
                idUser: response.data.data.user.idUser,
                status: response.data.data.userEnvStatus,
                signatureLevel: response.data.data.signatureLevel,
                massiveEnabled: response.data.data.hasMassiveEnabled,
                dispatcher: response.data.data.dispatcher,
                isAdmin: response.data.data.isAdmin,
                hasSignature: response.data.data.hasSignature,
                hasEnableApproveCreate,
                hasPermissionCreate: permissions && "user.role.create" in permissions,
                hasPermissionApprove: permissions && "user.role.approve" in permissions,
                isEnabledToCreate: response.data.data.isEnabledToCreate,
            };
            yield put(userActions.loadSingleSuccess(userExtendedInfo));

            yield put(actions.loadChannelsSuccess({ ...response.data.data }));
            yield put(actions.loadDetailsSuccess({ ...response.data.data, credentialGroups }));
            if (actions.loadPermissionsSuccess !== undefined) {
                yield put(actions.loadPermissionsSuccess({ ...response.data.data }));
            }
            if (actions.loadGroupsSuccess !== undefined) {
                yield put(actions.loadGroupsSuccess(response.data.data));
            }
        }
    };

const updateSignatureRequest = (middleware, actions) =>
    function* updateRequest({ data, formikBag }) {
        const { signatureLevel, credentials, ...rest } = data;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const response = yield call(middleware.updateSignatureRequest, {
            ...rest,
            ...credentialsWithUnderscore,
            signatureLevel,
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);
        if (response.type === "W") {
            const errorMessage = response.data.data.NO_FIELD || i18n.get("global.unexpectedError");

            setErrors(response.data.data);
            if (response.data.data.otp === null) {
                yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
            }
        } else {
            if (signatureLevel === "A") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("administration.user.signature.enabled.confirmation"),
                        "success",
                        ["administration"],
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("administration.user.signature.disabled.confirmation"),
                        "success",
                        ["administration"],
                    ),
                );
            }
            yield put(routerActions.goBack());
            yield put(actions.updateChannelsRequestSuccess());
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_DETAILS_REQUEST, loadDetailsRequest(middleware, actions)),
    takeLatest(types.UPDATE_SIGNATURE_REQUEST, updateSignatureRequest(middleware, actions)),
];

export default sagasCreator;

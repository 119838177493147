import * as API from "middleware/api";

export const fetchCampaign = (section) =>
    API.executeWithAccessToken("/campaigns.readRandomCampaignBySection", { section });

export const fetchCampaignAnonymous = (section) =>
    API.executeAnonymous("/campaigns.readRandomCampaignBySectionAnonymous", { section });

export const fetchCampaignImage = (params) => API.executeAnonymous("/campaigns.readCampaignImage", params);

export const dismissPermanentCampaign = (idCampaign) =>
    API.executeWithAccessToken("/campaigns.userDismissCampaign", idCampaign);

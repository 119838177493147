import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import RemarkableProductInfo from "pages/_components/RemarkableProductInfo";
import Row from "pages/_components/Row";
import CreditLinesDetailsHeader from "pages/creditLines/CreditLinesDetailsHeader";
import CreditLinesDetailsTable from "pages/creditLines/CreditLinesDetailsTable";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { getLocationBasePath } from "util/revelock";
import { actions as creditLinesActions, selectors as creditLinesSelectors } from "reducers/creditLines";

const individualLinesTypes = [
    "LOANS_LIMITS_REVOLVING",
    "LOANS_LIMITS_NON_REVOLVING",
    "CONTRACTED_OVERTURNING",
    "CREDIT_LETTER",
    "BANK_GUARANTEE",
];

const controlLinesTypes = ["CONTROL_LINE_GLOBAL", "CONTROL_LINE_MASTER"];

const PAGE_IDENTIFIER = "list.creditLines.details";
const CreditLinesDetails = (props) => {
    const {
        creditLinesList,
        fetchingProductList,
        productCreditLinesList,
        dispatch,
        fetching,
        isDesktop,
        ...rest
    } = props;
    const [creditLine, setCreditLine] = useState(null);

    /**
     * Set position revelock
     */

    const setRevelockLocation = () => {
        const { setLocationCustomPath, match } = props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    const getCreditLineFromList = () => {
        let creditLineFromList = null;
        if (creditLinesList) {
            if (creditLinesList && creditLinesList.length > 0) {
                creditLineFromList = creditLinesList?.find((a) => a.id === rest.match.params.id);
                if (rest.match.params?.idChild !== null && rest.match.params?.idChild !== undefined) {
                    creditLineFromList = creditLineFromList?.childs?.find((a) => a.id === rest.match.params.idChild);
                }
            }
        }

        let finalCreditLine = null;
        if (creditLineFromList) {
            finalCreditLine = {
                ...creditLineFromList,
                productAlias: "CL",
                productType: "CL",
                productDetailTitle: creditLineFromList?.secondaryTypeEnum ?? creditLineFromList?.typeEnum,
                expirationDate: creditLineFromList?.expirationDate,
            };
        }
        return finalCreditLine;
    };

    const handleBack = () => {
        dispatch(routerActions.goBack());
    };

    const isIndividualCreditLine = () => {
        if (rest.match.params.id && !rest.match.params.idChild) {
            const individual = creditLinesList.find((line) => line.id === rest.match.params.id);

            if (individualLinesTypes.includes(individual?.secondaryTypeEnum)) {
                return true;
            }
        } else if (rest.match.params.idChild) {
            const parent = creditLinesList.find((line) => line.id === rest.match.params.id);
            const indivualChild = parent?.childs?.find((childLine) => childLine.id === rest.match.params.idChild);

            if (individualLinesTypes.includes(indivualChild?.secondaryTypeEnum)) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        // si no hay lineas de creditos cargadas redirijo al desktop
        if (!creditLinesList || creditLinesList.length === 0) {
            dispatch(replace("/desktop"));
        }
        setRevelockLocation();
    }, []);

    useEffect(() => {
        if (props.match.params && (props.match.params.id || props.match.params.idChild)) {
            const creditLineTmp = getCreditLineFromList();
            setCreditLine(creditLineTmp);
            if (creditLineTmp && isIndividualCreditLine()) {
                dispatch(creditLinesActions.productListRequest(creditLineTmp.id));
            }
        }
    }, [props.match.params]);

    return (
        <>
            <PageLoading loading={false} classicStyle={false}>
                {creditLine && (
                    <>
                        <Notification scopeToShow="creditLinesDetails" />

                        <ProductDetail>
                            <ProductDetail.Header
                                dispatch={dispatch}
                                isDesktop={isDesktop}
                                onBack={handleBack}
                                productId={rest.match.params.id}
                                product={creditLine}
                                hideEditButton>
                                <Row>
                                    <Col xs={12} md={4} className="height-auto">
                                        <RemarkableProductInfo
                                            currency={creditLine.onlineAmount?.currency}
                                            quantity={creditLine.onlineAmount?.quantity}
                                            isDesktop={isDesktop}
                                            productType="CL"
                                            idProduct={creditLine.id}
                                        />
                                    </Col>
                                    {isDesktop && <CreditLinesDetailsHeader creditLine={creditLine} />}
                                </Row>
                            </ProductDetail.Header>

                            <ProductDetail.Body
                                withTabs={!isDesktop}
                                id="productDetail.body"
                                closeMoreFiltersWhenSiblingClick
                                isDesktop={isDesktop}
                                product={creditLine}
                                productKind="creditLines"
                                {...(!isDesktop && {
                                    detailHeadInfo: <CreditLinesDetailsHeader creditLine={creditLine} />,
                                })}>
                                <CreditLinesDetailsTable
                                    tableType={creditLine?.secondaryTypeEnum ?? creditLine?.typeEnum}
                                    movementList={
                                        !controlLinesTypes.includes(creditLine?.typeEnum)
                                            ? productCreditLinesList
                                            : creditLine?.childs
                                    }
                                    isDesktop={isDesktop}
                                    dispatch={dispatch}
                                    parentId={rest.match.params.id}
                                    fetching={fetchingProductList || fetching}
                                />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </>
                )}
            </PageLoading>
        </>
    );
};

CreditLinesDetails.propTypes = {
    dispatch: func.isRequired,
    fetching: bool.isRequired,
    isDesktop: bool.isRequired,
    match: shape({}).isRequired,
    setLocationCustomPath: func.isRequired,
    creditLinesList: arrayOf(shape({})),
    productCreditLinesList: arrayOf(shape({})),
    creditLine: shape({}),
    fetchingProductList: bool,
};

CreditLinesDetails.defaultProps = {
    creditLinesList: null,
    fetchingProductList: false,
    creditLine: null,
    productCreditLinesList: null,
};
const mapStateToProps = (state) => ({
    fetching: creditLinesSelectors.isFetching(state),
    creditLinesList: creditLinesSelectors.getList(state),
    productCreditLinesList: creditLinesSelectors.getProductList(state),
    fetchingProductList: creditLinesSelectors.isFetchingProductList(state),
});

export default connect(mapStateToProps)(withRevelockPositionContext(CreditLinesDetails));

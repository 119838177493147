export const types = {
    SHOW_NOTIFICATION: "notification/SHOW_NOTIFICATION",
    REMOVE_NOTIFICATION: "notification/REMOVE_NOTIFICATION",
};

export const INITIAL_STATE = {
    message: null,
    level: null,
    scopes: [],
    cont: 0,
    clearNotifications: true,
    timeOut: null,
    rightComponent: undefined,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                message: action.message,
                level: action.level,
                scopes: action.scopes,
                cont: state.cont + 1,
                clearNotifications: action?.clearNotifications !== undefined ? action.clearNotifications : true,
                timeOut: action.timeOut,
                rightComponent: action?.rightComponent,
            };
        case types.REMOVE_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Action creators
export const actions = {
    showNotification: (message, level, scopes, clearNotifications = true, timeOut = null, rightComponent) => ({
        type: types.SHOW_NOTIFICATION,
        message,
        level,
        scopes,
        clearNotifications,
        timeOut,
        rightComponent,
    }),
    removeNotification: () => ({
        type: types.REMOVE_NOTIFICATION,
    }),
};

// Selectors
export const selectors = {
    getMessage: (state) => state.notification.message,
    getLevel: (state) => state.notification.level,
    getScopes: (state) => state.notification.scopes,
    getCont: (state) => state.notification.cont,
    getClearNotifications: (state) =>
        (state?.notification?.clearNotifications !== undefined ? state?.notification?.clearNotifications : true),
    getTimeOut: (state) => state.notification.timeOut,
    getRightComponent: (state) => state.notification.rightComponent,
};

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import HeadSimple from "pages/_components/HeadSimple";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";

const screenItems = [
    {
        icon: "images/icons/sunEclipse.svg",
        message: "token.activation.help.item1",
    },
    {
        icon: "images/icons/thunder.svg",
        message: "token.activation.help.item2",
    },
    {
        icon: "images/icons/usersSmallBig.svg",
        message: "token.activation.help.item3",
    },
    {
        icon: "images/icons/tv.svg",
        message: "token.activation.help.item4",
    },
    {
        icon: "images/icons/glasses.svg",
        message: "token.activation.help.item5",
    },
];

class TokenActivationHelp extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        onCloseCustom: func,
    };

    static defaultProps = {
        onCloseCustom: undefined,
    };

    onHeaderClose = () => {
        const { dispatch, onCloseCustom } = this.props;
        if (onCloseCustom) {
            onCloseCustom();
            return;
        }
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
        dispatch(goBack());
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Box display="flex" column fullHeight className="px-md-7 pt-3 pt-md-3 pb-7 px-5">
                <HeadSimple isMobile={isMobile} onRightClick={this.onHeaderClose} className="mb-8 pr-2" />

                <Box display="flex" column fullHeight fullWidth className="px-md-5 safe-margin-bottom">
                    <Box display="flex" column alignY="center" className="pd-12">
                        <Image className="mb-4" src="images/icons/userTokenDashedLine.svg" />
                        <Text
                            size="2"
                            component="h2"
                            color="text"
                            className="mb-12 mt-0"
                            labelKey="token.activation.help.subtitle"
                        />
                    </Box>
                    <Box display="flex" column>
                        {screenItems.map((item) => (
                            <Box display="flex" alignY="flex-start" className="mb-4 px-5">
                                <Box display="flex">
                                    <Image src={item.icon} className="mr-3" color="heading-color" />
                                </Box>
                                <Text size="6" color="heading" labelKey={item.message} />
                            </Box>
                        ))}
                    </Box>
                    <Disclaimer
                        className="pb-3 px-5 p-md-5 pt-0"
                        styled=""
                        labelKey="token.activation.help.disclaimer"
                    />
                </Box>
                <Box display="flex" column className="mb-6" fullWidth>
                    <Button
                        label="token.activation.help.button.understood"
                        bsStyle="primary"
                        onClick={this.onHeaderClose}
                        block
                    />
                </Box>
            </Box>
        );
    }
}

export default connect()(TokenActivationHelp);

import * as API from "middleware/api";

// eslint-disable-next-line import/prefer-default-export
export const calculateRate = (amount, productName, term) =>
    API.executeWithAccessToken("/fixed.term.deposit.getRate", { amount, productName, term });

export const getDepositTypeMetadata = (currency, productName) =>
    API.executeWithAccessToken("/fixed.term.deposit.getDetailType", { currency, productName });

export const getBeneficiaryByDocumentType = (params) =>
    API.executeWithAccessToken("/fixed.term.get.beneficiary.name", {
        ...params,
    });

import classNames from "classnames";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { actions as accountActions } from "reducers/accounts";
import { actions as checksActions } from "reducers/checks";

// These values are reflected in the names of the images which are used to represent the channels
const channels = {
    frontend: "web",
    phonegap: "mobile",
    assistant: "assistant",
    web: "web",
    web_mobile: "web",
    wally_mobile: "wally",
    pos: "pos",
    mobile: "mobile",
    caja: "bank",
    generico: "generic",
    kuara_mobile: "kuara",
};
const TRANSACTION_TYPE_FOREIGN_TRANSFER = "210";

class MovementItem extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        operationCurrency: string.isRequired,
        operationType: string.isRequired,
        movement: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    getComponentTransferForeign = (movement) => (
        <Box
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!movement?.reference || !movement?.idAccount) {
                    return;
                }

                const { dispatch } = this.props;
                dispatch(accountActions.getTrackingTransferUrlRequest(movement.idAccount, movement.reference));
            }}
            fullWidth>
            <Text color="primary" underlined ellipsis bold>
                {movement?.concept || ""}
            </Text>
        </Box>
    );

    getComponentUrlCheck = (movement) => {
        const { isDesktop } = this.props;

        return (
            <Box
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!movement?.check || !movement?.idAccount) {
                        return;
                    }

                    const { dispatch } = this.props;
                    dispatch(checksActions.getUrlCheckRequest(movement?.idAccount, movement?.check));
                }}
                fullWidth>
                <Text color="primary" underlined ellipsis {...(!isDesktop && { bold: true })}>
                    {movement?.concept || ""}
                </Text>
            </Box>
        );
    };

    getDefaultAccountConcept = (movement) => <Text ellipsis>{movement?.concept || ""}</Text>;

    getRenderContentAccount = (movement) => {
        if (!!movement.check && movement.transactionType !== TRANSACTION_TYPE_FOREIGN_TRANSFER) {
            return this.getComponentUrlCheck(movement);
        }
        if (!movement?.transactionType || movement.transactionType === "") {
            return this.getDefaultAccountConcept(movement);
        }

        if (movement.transactionType === TRANSACTION_TYPE_FOREIGN_TRANSFER) {
            return this.getComponentTransferForeign(movement);
        }

        return this.getDefaultAccountConcept(movement);
    };

    render() {
        const { operationCurrency, operationType, isDesktop, isTablet, movement } = this.props;
        const { balance, channel, concept, credit, date, debit } = movement;

        return isDesktop ? (
            <>
                {operationType === "accounts" && (
                    <>
                        <GridTable.Data className="p-0" columnStart={1} alignX="flex-start">
                            <Image src={`images/channel-${channels[channel]}.svg`} className="svg-icon text-center" />
                            <I18n id={`channels.${channel}`} componentProps={{ className: "visually-hidden" }} />
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                            <FormattedDate date={date} />
                        </GridTable.Data>
                        <GridTable.Data
                            className="p-0"
                            columnStart={3}
                            columnWidth={isTablet ? 2 : 3}
                            alignX="flex-start">
                            {this.getRenderContentAccount(movement)}
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={isTablet ? 5 : 6} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className={classNames("data-amount justify-content-end", {
                                    "is-negative": credit < 0 || debit < 0,
                                })}
                                quantity={credit || debit}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={isTablet ? 6 : 7} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className={classNames("justify-content-end", {
                                    "is-negative": credit < 0 || debit < 0,
                                })}
                                quantity={balance}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={isTablet ? 7 : 8} alignX="flex-end" tabIndex="-1">
                            {movement.note && (
                                <Image src="images/note.svg" className={`svg-icon ${!isTablet ? "mr-5" : "mr-2"}`} />
                            )}
                            <ChevromRight />
                        </GridTable.Data>
                    </>
                )}
                {operationType === "deposits" && (
                    <Fragment>
                        <GridTable.Data className="p-0" columnStart={1} alignX="flex-start">
                            <FormattedDate date={date} />
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={2} columnWidth={2} alignX="flex-start">
                            <Text ellipsis>{concept}</Text>
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={4} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className={classNames("justify-content-end", {
                                    "is-negative": credit < 0 || debit < 0,
                                })}
                                currency={operationCurrency}
                                quantity={credit || debit}
                            />
                        </GridTable.Data>
                        <GridTable.Data className="p-0" columnStart={5} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className={classNames("justify-content-end", {
                                    "is-negative": credit < 0 || debit < 0,
                                })}
                                currency={operationCurrency}
                                quantity={balance}
                            />
                        </GridTable.Data>
                    </Fragment>
                )}
            </>
        ) : (
            <>
                {operationType === "accounts" && (
                    <Fragment>
                        {concept && (
                            <GridTable.Data
                                className="text-bold mr-4"
                                columnStart={1}
                                columnWidth={1}
                                rowStart={1}
                                alignX="flex-start">
                                {this.getRenderContentAccount(movement)}
                            </GridTable.Data>
                        )}
                        <GridTable.Data
                            className="mr-4"
                            columnStart={1}
                            columnWidth={1}
                            rowStart={concept ? 2 : 1}
                            rowWidth={concept ? 1 : 2}
                            alignX="flex-start">
                            <FormattedDate date={date} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} rowStart={1} rowWidth={2} alignY="center" alignX="flex-end">
                            <FormattedAmount
                                size="4"
                                className={classNames("justify-content-end", {
                                    "is-negative": credit < 0 || debit < 0,
                                })}
                                noCurrency
                                quantity={credit || debit}
                            />
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} rowStart={1} rowWidth={2} alignY="center" alignX="flex-end">
                            <ChevromRight />
                        </GridTable.Data>
                    </Fragment>
                )}
                {operationType === "deposits" && (
                    <Fragment>
                        {concept && (
                            <GridTable.Data
                                className="text-bold mr-4"
                                columnStart={1}
                                columnWidth={2}
                                rowStart={1}
                                alignX="flex-start">
                                <Text ellipsis>{concept} </Text>
                            </GridTable.Data>
                        )}
                        <GridTable.Data
                            className="mr-4"
                            columnStart={1}
                            columnWidth={2}
                            rowStart={concept ? 2 : 1}
                            rowWidth={concept ? 1 : 2}
                            alignX="flex-start">
                            <FormattedDate date={date} />
                        </GridTable.Data>
                        <GridTable.Data columnStart={3} rowStart={1} rowWidth={2} alignY="center" alignX="flex-end">
                            <FormattedAmount
                                size="4"
                                className={classNames("justify-content-end", {
                                    "is-negative": credit < 0 || debit < 0,
                                })}
                                currency={operationCurrency}
                                quantity={credit || debit}
                            />
                        </GridTable.Data>
                    </Fragment>
                )}
            </>
        );
    }
}

export default connect()(MovementItem);

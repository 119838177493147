import classNames from "classnames";
import { arrayOf, bool, func, node, string, shape } from "prop-types";
import React, { Children, Component } from "react";
import { Col, Collapse } from "react-bootstrap";
import { resizableRoute } from "pages/_components/Resizable";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Row from "pages/_components/Row";
import Tooltip from "pages/_components/Tooltip";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import * as i18nUtils from "util/i18n";
import withTransactionFilterContext from "hoc/withTransactionFilterContext";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as accountsSelectors } from "reducers/accounts";
import Checkbox from "pages/_components/Checkbox";
import { CORPORATE_ENVIRONMENT_TYPE } from "constants.js";

class ProductFilters extends Component {
    static propTypes = {
        notCollapsedFilters: bool,
        documents: arrayOf(shape({})),
        closeMoreFiltersWhenSiblingClick: bool,
        filtersKeyLabel: string,
        filterDownloadBtn: bool,
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        borderRadiusTop: bool,
        moreFilters: node,
        children: node.isRequired,
        handleResetDateFilters: func,
        alwaysShowChips: bool,
        showDisclaimer: bool,
        selected: bool,
        downloading: bool,
        moreFiltersClosedKeyLabel: string,
        moreFiltersOpenedKeyLabel: string,
        dispatch: func.isRequired,
        disclaimerDate: string.isRequired,
        notBorderTop: bool,
        downloadFilterTransaction: func,
        isFetchingDownload: bool,
        historic: bool,
        handleFilterButtonsClick: func,
        visibleFilterBtnLabelOnOpen: bool,
        isAdvanced: bool,
        activeEnvironment: shape({}),
        isPendingTransactionList: bool,
        setIsCreatedByMe: func,
        isCreatedByMe: bool,
    };

    static defaultProps = {
        notCollapsedFilters: false,
        closeMoreFiltersWhenSiblingClick: false,
        documents: null,
        filtersKeyLabel: "",
        filterDownloadBtn: false,
        handleResetDateFilters: null,
        borderRadiusTop: false,
        moreFilters: null,
        alwaysShowChips: false,
        showDisclaimer: false,
        selected: false,
        downloading: false,
        moreFiltersClosedKeyLabel: "",
        moreFiltersOpenedKeyLabel: "",
        notBorderTop: false,
        downloadFilterTransaction: () => {},
        isFetchingDownload: false,
        historic: false,
        handleFilterButtonsClick: null,
        visibleFilterBtnLabelOnOpen: false,
        isAdvanced: false,
        activeEnvironment: null,
        isPendingTransactionList: false,
        setIsCreatedByMe: () => {},
        isCreatedByMe: false,
    };

    state = {
        open: false,
    };

    componentDidMount() {
        const { isAdvanced } = this.props;
        this.setState({ open: isAdvanced });
    }

    handleMoreFiltersClick = () => {
        const { handleResetDateFilters, isDesktop, handleFilterButtonsClick } = this.props;
        const { open } = this.state;
        this.setState({ open: !open });
        if (handleResetDateFilters) {
            handleResetDateFilters(false);
        }
        if (!isDesktop && open && handleFilterButtonsClick) {
            handleFilterButtonsClick("all");
        }
    };

    handleSiblingClick = () => {
        const { closeMoreFiltersWhenSiblingClick } = this.props;

        if (closeMoreFiltersWhenSiblingClick) {
            this.setState({ open: false });
        }
    };

    handleClickDisclaimerHistory = () => {
        const { dispatch } = this.props;
        dispatch(push("/transactions/list/historic"));
    };

    mapChipButtons = (children, filtersKeyLabel, alwaysShowChips) => (
        <>
            <Col
                xs={12}
                md={8}
                mdOffset={2}
                lg={6}
                lgOffset={3}
                className={`d-flex justify-content-center pt-4 ${alwaysShowChips ? "pb-4" : "pb-sm-1 pb-md-4"}`}>
                <Box display="flex" alignX="space-between" wrap alignY="center">
                    {filtersKeyLabel && <Text component="h4" className="table-legend" labelKey={filtersKeyLabel} />}
                    {children && (
                        <Box component="ul" display="flex" className="nav p-2">
                            {Children.map(children, (child, index) => (
                                // eslint-disable-next-line
                                <li key={index} onClick={this.handleSiblingClick}>
                                    {child}
                                </li>
                            ))}
                        </Box>
                    )}
                </Box>
            </Col>
        </>
    );

    renderDownloadDocs = (downloading, documents) => {
        const { isFetchingDownload } = this.props;
        if (!downloading) {
            return (
                <Dropdown
                    fetching={isFetchingDownload !== undefined ? isFetchingDownload : false}
                    image="images/download.svg"
                    label="global.download"
                    labelClassName="visually-hidden"
                    bsStyle="only-icon"
                    pullRight>
                    {documents.map((item) => (
                        <Button
                            key={item.label}
                            label={item.label}
                            onClick={() => {
                                const { downloadFilterTransaction } = this.props;
                                item.onClick(downloadFilterTransaction);
                            }}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    ))}
                </Dropdown>
            );
        }
        return <Button label="downloading" bsStyle="only-icon" loading />;
    };

    renderMoreFilters = (
        activeEnvironment,
        moreFiltersOpenedKeyLabel,
        moreFiltersClosedKeyLabel,
        moreFilters,
        notCollapsedFilters,
        selected,
        isDesktop,
        isTablet,
        filterDownloadBtn,
        downloading,
        documents,
        open,
        notBorderTop,
        tooltip = false,
        visibleFilterBtnLabelOnOpen = false,
        isPendingTransactionList,
        isCreatedByMe,
        setIsCreatedByMe,
    ) => (
        <>
            <Col
                xs={tooltip ? 10 : 12}
                md={moreFilters ? 2 : 12}
                lg={moreFilters ? 3 : 12}
                className={classNames({ "py-md-5": !filterDownloadBtn })}>
                <Box display="flex" fullWidth alignX="flex-end" className={classNames({ "pr-5": !filterDownloadBtn })}>
                    {moreFilters &&
                        (notCollapsedFilters ? (
                            <>
                                {!isDesktop && activeEnvironment?.type === CORPORATE_ENVIRONMENT_TYPE && (
                                    <Box display="flex" bottom="4" left="5" className="mr-auto ml-5">
                                        <Checkbox
                                            checked={isCreatedByMe}
                                            name="isCreatedByMe"
                                            labelText="productFilters.createdByMe"
                                            onChange={() => setIsCreatedByMe(!isCreatedByMe)}
                                        />
                                    </Box>
                                )}
                                {!isDesktop && (
                                    <Button
                                        className={classNames("my-3 mr-7", {
                                            "is-active": selected,
                                        })}
                                        label={this.state.open ? moreFiltersOpenedKeyLabel : moreFiltersClosedKeyLabel}
                                        bsStyle="link"
                                        onClick={this.handleMoreFiltersClick}
                                        small
                                    />
                                )}
                            </>
                        ) : (
                            <Button
                                className={classNames({ "is-active": selected })}
                                bsStyle="link"
                                {...(visibleFilterBtnLabelOnOpen &&
                                    !this.state.open && {
                                        labelClassName: classNames({ "visually-hidden": !isDesktop || isTablet }),
                                    })}
                                label={
                                    this.state.open && visibleFilterBtnLabelOnOpen && !isDesktop
                                        ? "accounts.movements.closeFilter"
                                        : "accounts.movements.moreMovements"
                                }
                                onClick={this.handleMoreFiltersClick}
                                image="images/search.svg"
                            />
                        ))}

                    {documents &&
                        !filterDownloadBtn &&
                        // eslint-disable-next-line no-nested-ternary
                        (notCollapsedFilters
                            ? isDesktop && this.renderDownloadDocs(downloading, documents)
                            : this.renderDownloadDocs(downloading, documents))}
                </Box>
            </Col>
            {moreFilters && (
                <Collapse in={notCollapsedFilters && !isDesktop ? !open : open}>
                    <Box>
                        <Row className={classNames("filters", { "without-border": notBorderTop })} gapY="3">
                            <Col xs={12} md={filterDownloadBtn ? 11 : 12}>
                                <Row>{moreFilters}</Row>
                            </Col>
                            {filterDownloadBtn && isDesktop && (
                                <Col xs={1} className="height-auto">
                                    <Box
                                        className="pt-7"
                                        display="flex"
                                        fullHeight
                                        fullWidth
                                        alignY="center"
                                        alignX="center">
                                        {this.renderDownloadDocs(downloading, documents)}
                                    </Box>
                                </Col>
                            )}
                        </Row>
                        {isDesktop && activeEnvironment?.type === CORPORATE_ENVIRONMENT_TYPE && (
                            <Box display="flex" position="absolute" bottom="4" left="5" zIndex="9">
                                <Checkbox
                                    checked={isCreatedByMe}
                                    name="isCreatedByMe"
                                    labelText="productFilters.createdByMe"
                                    onChange={() => setIsCreatedByMe(!isCreatedByMe)}
                                />
                            </Box>
                        )}
                    </Box>
                </Collapse>
            )}
        </>
    );

    getDisclaimerMessage = (disclaimerDate) => {
        const message = i18nUtils.get(`transactions.list.disclaimer.text`, null, {
            DATE: disclaimerDate,
        });
        return message;
    };

    renderDisclaimerHistory = (isDesktop, disclaimerDate) => (
        <>
            <Box
                background="info"
                display="flex"
                alignY="center"
                alignX="flex-start"
                className="px-5 py-6 py-md-5"
                borderRadius={isDesktop ? "top-xl" : "bottom-xl"}>
                <Box display="flex" alignY="center">
                    <Image wrapperClassName="fit-content" className="mr-3 mt-2" src="images/icons/tooltip.svg" />
                    <Text size={isDesktop ? "5" : "6"} color="text-boton" className="mr-3 mr-md-4">
                        {this.getDisclaimerMessage(disclaimerDate)}
                    </Text>
                </Box>

                <Button
                    bsStyle={isDesktop ? "outline" : "link"}
                    {...(!isDesktop && { small: true })}
                    label="transactions.list.disclaimer.button"
                    onClick={this.handleClickDisclaimerHistory}
                    className="min-width-6rem"
                />
            </Box>
        </>
    );

    render() {
        const { open } = this.state;
        const {
            notCollapsedFilters,
            children,
            downloading,
            documents,
            filtersKeyLabel,
            filterDownloadBtn,
            isTablet,
            moreFiltersOpenedKeyLabel,
            moreFiltersClosedKeyLabel,
            moreFilters,
            selected,
            isDesktop,
            borderRadiusTop,
            alwaysShowChips,
            showDisclaimer,
            disclaimerDate,
            notBorderTop,
            visibleFilterBtnLabelOnOpen,
            historic,
            activeEnvironment,
            isPendingTransactionList,
            isCreatedByMe,
            setIsCreatedByMe,
        } = this.props;

        return (
            <>
                {alwaysShowChips &&
                    (isDesktop ? (
                        <Box
                            component="section"
                            background="white"
                            {...(borderRadiusTop && { borderRadius: "top-xl" })}>
                            <Row gapY="0" gapX="3" className="flex-grow-1 product-filters position-relative">
                                {showDisclaimer
                                    ? this.renderDisclaimerHistory(isDesktop, disclaimerDate)
                                    : this.mapChipButtons(children, filtersKeyLabel)}

                                {moreFilters &&
                                    this.renderMoreFilters(
                                        activeEnvironment,
                                        moreFiltersOpenedKeyLabel,
                                        moreFiltersClosedKeyLabel,
                                        moreFilters,
                                        notCollapsedFilters,
                                        selected,
                                        isDesktop,
                                        isTablet,
                                        filterDownloadBtn,
                                        downloading,
                                        documents,
                                        !open,
                                        notBorderTop,
                                        false,
                                        visibleFilterBtnLabelOnOpen,
                                        isPendingTransactionList,
                                        isCreatedByMe,
                                        setIsCreatedByMe,
                                    )}
                            </Row>
                        </Box>
                    ) : (
                        <>
                            {!historic && (
                                <Box
                                    component="section"
                                    background="white"
                                    borderRadius={showDisclaimer ? "bottom-xl" : "full-xl"}
                                    className={showDisclaimer ? "mb-3" : "my-3"}>
                                    <Row gapY="0" gapX="3" className="flex-grow-1 product-filters">
                                        {!showDisclaimer
                                            ? this.mapChipButtons(children, filtersKeyLabel, alwaysShowChips)
                                            : this.renderDisclaimerHistory(isDesktop, disclaimerDate)}
                                    </Row>
                                </Box>
                            )}
                            <Box
                                className={classNames({ "mt-5": historic })}
                                component="section"
                                background="white"
                                {...(borderRadiusTop && { borderRadius: "top-xl" })}>
                                <Row gapY="0" gapX="3" className="flex-grow-1 product-filters">
                                    {moreFilters &&
                                        this.renderMoreFilters(
                                            activeEnvironment,
                                            moreFiltersOpenedKeyLabel,
                                            moreFiltersClosedKeyLabel,
                                            moreFilters,
                                            notCollapsedFilters,
                                            selected,
                                            isDesktop,
                                            isTablet,
                                            filterDownloadBtn,
                                            downloading,
                                            documents,
                                            !open,
                                            notBorderTop,
                                            false,
                                            visibleFilterBtnLabelOnOpen,
                                            isPendingTransactionList,
                                            isCreatedByMe,
                                            setIsCreatedByMe,
                                        )}
                                </Row>
                            </Box>
                        </>
                    ))}

                {!alwaysShowChips && moreFilters && (
                    <Box component="section" background="white" {...(borderRadiusTop && { borderRadius: "top-xl" })}>
                        <Row gapY="0" gapX="3" className="flex-grow-1 product-filters">
                            <Box display="contents">
                                <Tooltip
                                    className="cursor-pointer pl-2 pr-2"
                                    position="left-centered"
                                    text="accounts.movements.tooltip"
                                />
                            </Box>
                            {isDesktop && !filterDownloadBtn && this.mapChipButtons(children, filtersKeyLabel)}
                            {this.renderMoreFilters(
                                activeEnvironment,
                                moreFiltersOpenedKeyLabel,
                                moreFiltersClosedKeyLabel,
                                moreFilters,
                                notCollapsedFilters,
                                selected,
                                isDesktop,
                                isTablet,
                                filterDownloadBtn,
                                downloading,
                                documents,
                                open,
                                notBorderTop,
                                true,
                                visibleFilterBtnLabelOnOpen,
                                isPendingTransactionList,
                                isCreatedByMe,
                                setIsCreatedByMe,
                            )}
                        </Row>
                    </Box>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetchingDownload: transactionsSelectors.isFetchingDownload(state),
    isAdvanced: accountsSelectors.isAdvanced(state),
});

export default connect(mapStateToProps)(resizableRoute(withTransactionFilterContext(ProductFilters)));

import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import CampaignItem from "pages/campaigns/CampaignItem";
import { arrayOf, bool, element, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { actions, selectors } from "reducers/campaigns";
import WidgetLoading from "pages/_components/WidgetLoading";

class Campaigns extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        section: string.isRequired,
        fetchingCampaigns: bool.isRequired,
        items: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        container: element,
        isDesktop: bool,
        className: string,
        isStepZero: bool,
        isActiveCorporate: bool,
    };

    static defaultProps = {
        container: null,
        isDesktop: false,
        className: null,
        isStepZero: false,
        isActiveCorporate: false,
    };

    componentDidMount() {
        const { dispatch, section, isStepZero } = this.props;
        if (isStepZero) {
            dispatch(actions.loadCampaignAnonymous(section));
        } else {
            dispatch(actions.loadCampaign(section));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(actions.removeCampaigns());
    }

    render() {
        const {
            items,
            container,
            dispatch,
            isDesktop,
            className,
            fetchingCampaigns,
            isStepZero,
            isActiveCorporate,
        } = this.props;

        const list = items?.map((item) => (
            <CampaignItem className={className} {...item} isDesktop={isDesktop} dispatch={dispatch} />
        ));

        const campaignsOut = container ? (
            <Box className={container.className}>{list}</Box>
        ) : (
            <Fragment>{list}</Fragment>
        );

        const campaignDefaultLanding = (
            <Image
                wrapperClassName="image-wrapper"
                {...(isActiveCorporate
                    ? {
                          src: "images/campaigns/PersonasJuridicas.png",
                          externalHref: "https://www.banesco.com.pa/empresas/tarjetas/",
                      }
                    : {
                          src: "images/campaigns/connectMilesBanner.png",
                          externalHref:
                              "https://www.banesco.com.pa/tarjetas/visa-infinite-connectmiles/#solicita-tu-tarjeta",
                      })}
            />
        );

        const campaignDefault = isStepZero ? (
            <Image wrapperClassName="image-wrapper" src="images/campaigns/campaignDefault.png" />
        ) : null;

        return isStepZero ? (
            // campaignsOut //Se deja por si quieren volver
            campaignDefaultLanding
        ) : (
            <WidgetLoading loading={fetchingCampaigns}>
                {items.length > 0 ? campaignsOut : campaignDefault}
            </WidgetLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    items: selectors.getCampaigns(state),
    fetchingCampaigns: selectors.getFetching(state),
});

export default connect(mapStateToProps)(Campaigns);

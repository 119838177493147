import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import MyServicesCard from "pages/servicePayments/_components/MyServicesCard";
import React from "react";
import { Col } from "react-bootstrap";
import * as i18n from "util/i18n";
import ChipsButtonsGroup from "pages/_components/ChipsButtonsGroup";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";

export const ID_ACTIVITY = `payService.list`;

export const filterOptions = (isDesktop, buttonsGroup, handleButtonChipClick, handleFilterData) => (
    <>
        <Box borderRadius="default" background="component-background" className="mx-n-5 py-7 py-md-8 mt-3 mt-md-0 mb-5">
            <Row>
                <Col xs={12} md={8} mdOffset={2}>
                    <Box
                        display="flex"
                        alignX="between"
                        gap={isDesktop ? "7" : "5"}
                        {...(!isDesktop && { column: true })}
                        className="px-5">
                        <ChipsButtonsGroup buttonsGroup={buttonsGroup} onClickHandler={handleButtonChipClick} />

                        <TextSimpleField
                            name="searchServicePaymentList"
                            placeholder={i18n.get("global.search")}
                            onChange={(e) => handleFilterData(e.target.value)}
                            pattern="^[a-zA-Z0-9ñ@+._-]*$"
                            serarchStyle
                        />
                    </Box>
                </Col>
            </Row>
        </Box>
    </>
);

export const viewLinksNewRecharge = (numberLinks, handleSelectRecharge) => {
    const links = [];
    for (let i = 0; i < numberLinks; i++) {
        links.push(
            <Col xs={12} md={6}>
                <Box
                    onClick={() => handleSelectRecharge()}
                    display="flex"
                    alignX="center"
                    borderRadius="default"
                    border="dashed-image-border-base-color"
                    pointer
                    className="px-7 py-5 py-md-8">
                    <Text size="6" color="heading" align="center" labelKey="servicePayments.fast.recharge.add" />
                </Box>
            </Col>,
        );
    }
    return links;
};

export const viewServiceCards = (
    listFilter,
    handleFavorite,
    handleDelete,
    handleEdit,
    handleLink,
    fetchingFavorite,
    isShowAll,
    isSelectRecharge,
) =>
    listFilter.map((service) => (
        <Col xs={12} md={6} key={`col-service${service.idPaymentUser}`}>
            <MyServicesCard
                service={service}
                updateFavorite={handleFavorite}
                deleteService={handleDelete}
                linkService={handleLink}
                fetching={fetchingFavorite}
                isFastRecharge={!isShowAll}
                handleEdit={handleEdit}
                isSelectRecharge={isSelectRecharge}
            />
        </Col>
    ));

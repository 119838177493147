import { Field } from "formik";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import IndeterminateCheckbox from "pages/_components/fields/IndeterminateCheckbox";
import PermissionsAmount from "pages/administration/_components/PermissionsAmount";
import { arrayOf, func, objectOf, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";
import * as configUtils from "util/config";
import { routerActions } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { permissionsActions } from "reducers/administration/medium";

class PermissionsForm extends Component {
    static propTypes = {
        setValues: func.isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        values: objectOf(arrayOf(string)),
        products: arrayOf(
            shape({
                productType: string,
            }),
        ).isRequired,
        user: shape({}).isRequired,
        transfersPermissions: shape([]).isRequired,
        paymentsPermissions: shape([]).isRequired,
        otherPermissionsEdition: shape({}).isRequired,
        dispatch: func.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        values: {},
    };

    componentDidMount() {
        const { otherPermissionsEdition, setValues } = this.props;
        if (otherPermissionsEdition && setValues) {
            setValues(otherPermissionsEdition);
        }
    }

    dependsFromFields = ({ childrenList }, idItemToFind) =>
        childrenList.reduce((flattenedOptions, option) => {
            if (!option.childrenList.length) {
                if (option.dependsOn === idItemToFind) {
                    return [...flattenedOptions, option.idItem];
                }

                return flattenedOptions;
            }

            return [...flattenedOptions, ...this.dependsFromFields(option, idItemToFind)];
        }, []);

    setValues = ({ nextValue, setValues, dependsOn, values, idItem, parentOption }, permissionList = []) => {
        let value = { [idItem]: nextValue };

        if (!nextValue.length && dependsOn) {
            value = { [idItem]: nextValue, [dependsOn]: nextValue };
        } else if (nextValue.length && !dependsOn) {
            value = [idItem, ...this.dependsFromFields(parentOption, idItem)].reduce(
                (acc, id) => ({ ...acc, [id]: nextValue }),
                {},
            );
        }

        if (permissionList.length > 1) {
            permissionList.forEach((permission) => {
                value = { ...value, [permission.idPermission]: nextValue };
            });
        }

        setValues({ ...values, ...value });
    };

    permissionValue = (permissionList) => {
        if (permissionList && permissionList[0].productTypes) {
            return permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`);
        }

        return ["NONE"];
    };

    fillValues = ({ permissionList, childrenList, idItem }) => {
        let result = {};

        // Procesar permissionList actual
        if (permissionList.length && permissionList[0].simpleAllowProductSelection) {
            const { products } = this.props;
            return { [idItem]: products.map(({ idProduct }) => idProduct) };
        }

        // Procesar childrenList actual
        if (childrenList.length) {
            childrenList.forEach((child) => {
                result = {
                    ...result,
                    ...this.fillValues(child),
                };

                // Procesar childrenList.permissionList si existe
                if (child.permissionList && child.permissionList.length > 1) {
                    child.permissionList.forEach((subChild) => {
                        const { idPermission, mediumGroup } = subChild;
                        // const { idPermission , mediumGroup, advancedGroup simpleGroup } = subChild;
                        const isInGroup = mediumGroup !== null || mediumGroup !== undefined || mediumGroup !== "";

                        if (idPermission && isInGroup) {
                            result[idPermission] = this.permissionValue([idPermission]);
                        }
                    });
                }
            });

            return result;
        }

        return { [idItem]: this.permissionValue(permissionList) };
    };

    handleCheckClick = (values, option, setValues, selectedOptionsAmount) => {
        const filled = this.fillValues(option);

        if (selectedOptionsAmount === 0) {
            setValues({
                ...values,
                ...filled,
            });
        } else {
            const keysToRemove = Object.keys(filled);

            setValues(
                Object.entries(values).reduce(
                    (filteredValues, [key, value]) => ({
                        ...filteredValues,
                        [key]: keysToRemove.includes(key) ? [] : value,
                    }),
                    {},
                ),
            );
        }
    };

    renderProductInput = ({ idProduct, selectedOptions = [], setValues, values, idItem }) => (
        <input
            name={idProduct}
            value={idProduct}
            checked={selectedOptions.includes(idProduct)}
            onChange={() =>
                setValues({
                    ...values,
                    [idItem]: selectedOptions.includes(idProduct)
                        ? selectedOptions.filter((value) => value !== idProduct)
                        : selectedOptions.concat(idProduct),
                })
            }
            type="checkbox"
            id={idProduct}
            className="c-control-input"
        />
    );

    renderInput = ({ permissionList, selectedOptions = [], ...rest }) => (
        <input
            type="checkbox"
            id={rest.idItem}
            onChange={() => {
                this.setValues(
                    {
                        ...rest,
                        nextValue: selectedOptions.length ? [] : this.permissionValue(permissionList),
                    },
                    permissionList,
                );
            }}
            className="c-control-input"
            checked={selectedOptions.length}
        />
    );

    renderField = ({ idItem, label, idProduct, number, productAlias, productType, ...option }) => (
        <Field name={idItem} key={idProduct || idItem}>
            {({ form }) => {
                const selectedOptions = form.values[idItem];
                const accountNumber = label.match(/\*\d+/);
                const labelSplit = productType === "TC" ? label : label.split(/\*\d+/);

                return (
                    <div className="c-control c-control-block c-control--has-icon c-control--checkbox display-flex gap-3 align-items-center position-relative ml-5 py-3">
                        {!idProduct
                            ? this.renderInput({ ...option, ...form, idItem, selectedOptions })
                            : this.renderProductInput({ ...option, ...form, idItem, idProduct, selectedOptions })}
                        <label className="c-control-label m-o" htmlFor={idProduct || idItem}>
                            <div className="c-control-icons">
                                <div className="c-control-mark">
                                    <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                                </div>
                            </div>
                            {!idProduct ? (
                                <Text size="6" color="heading-color" regular>
                                    {label}
                                </Text>
                            ) : (
                                <div
                                    style={{
                                        display: "inline-flex",
                                    }}
                                    className="product-label">
                                    <Box display="flex" fullWidth>
                                        <Box className="d-grid">
                                            <Text className="mr-2" ellipsis color="heading-color">
                                                {productAlias || labelSplit}
                                            </Text>
                                        </Box>
                                        {productType === "TC" ? <></> : <Text>{accountNumber}</Text>}
                                    </Box>
                                </div>
                            )}
                        </label>
                    </div>
                );
            }}
        </Field>
    );

    renderOptions = (option, isFirstLevel) => {
        if (option.childrenList && option.childrenList.length) {
            return (
                <Fragment key={option.idItem}>
                    {!isFirstLevel && <div className="navigational-list-subtitle">{option.label}</div>}

                    {option.childrenList.map((subOption) => this.renderOptions({ ...subOption, parentOption: option }))}
                </Fragment>
            );
        }

        if (option.permissionList && option.permissionList[0].simpleAllowProductSelection) {
            const { products } = this.props;
            return products.reduce((availableProducts, product) => {
                if (option.permissionList[0].productTypes.indexOf(product.productType) === -1) {
                    return availableProducts;
                }

                return [...availableProducts, this.renderField({ ...product, idItem: option.idItem })];
            }, []);
        }

        return this.renderField(option);
    };

    getOnlyQueryPermissions = (groups, isLeft = false) => {
        const onlyQueryList = configUtils
            .get("administration.corporate.permissions.onlyQuery.category.list")
            .split("|");
        const returnGrup = [];
        groups.forEach((group) => {
            if (
                (isLeft && onlyQueryList.includes(group.idItem)) ||
                (!isLeft && !onlyQueryList.includes(group.idItem))
            ) {
                returnGrup.push(group);
            }
        });

        return returnGrup;
    };

    accordionAdditionalHandleClick = (vals, idItem, administrationSchemePath, idUser) => {
        if (idItem === "payments" || idItem === "transfers") {
            const { dispatch } = this.props;
            dispatch(permissionsActions.saveLocalPermissionEdition(vals, "other"));

            dispatch(
                routerActions.push({
                    pathname: `/administration/${administrationSchemePath}/${idUser}/permissions/${idItem}/configure`,
                }),
            );
        }
    };

    acordionItem = (groups, amountsById, totalAmountsById, values, setValues, user, dispatch, administrationScheme) => {
        const path = {
            simple: "simple/permissions",
            medium: "medium/details",
            advanced: "advanced/details",
        };
        if (!groups || !groups.length) {
            dispatch(
                routerActions.push({
                    pathname: `/administration/${path[administrationScheme]}/${user.idUser}`,
                }),
            );
        }

        return groups.map((group, index) => {
            const { idItem, label, ordinal } = group;
            let selectedOptionsAmount = amountsById[idItem] || 0;
            const { transfersPermissions, paymentsPermissions } = this.props;
            if (idItem === "transfers" && transfersPermissions) {
                const initialValue = 0;
                selectedOptionsAmount = transfersPermissions.reduce(
                    (accumulator, currentValue) => (currentValue.selected ? accumulator + 1 : accumulator),
                    initialValue,
                );
            }
            if (idItem === "payments" && paymentsPermissions) {
                const initialValue = 0;
                selectedOptionsAmount = paymentsPermissions.reduce(
                    (accumulator, currentValue) => (currentValue.selected ? accumulator + 1 : accumulator),
                    initialValue,
                );
            }
            const optionsAmount = totalAmountsById[idItem];
            const options = this.renderOptions({ ...group, parentOption: group }, true);
            const quantityText = `${selectedOptionsAmount} / ${optionsAmount}`;
            const isLinkStyle = group.idItem === "transfers" || group.idItem === "payments";

            if (options.props && typeof options.props.children === "function") {
                return (
                    <li key={idItem} className="navigational-list-item list-item--noChilds">
                        {options}
                    </li>
                );
            }

            return (
                <Col xs={12}>
                    <Accordion
                        additionalHandleClick={() =>
                            this.accordionAdditionalHandleClick(
                                values,
                                group.idItem,
                                path[administrationScheme],
                                user.idUser,
                            )
                        }
                        className="list list--permissions"
                        display="flex"
                        column
                        gap="3">
                        <Accordion.Item
                            display="flex"
                            column
                            background="component-background"
                            borderRadius="md"
                            alignY="flex-start"
                            alignX="center"
                            className="px-5"
                            contentClassName="display-flex flex-column"
                            fullWidth
                            key={idItem}
                            id={idItem}
                            number={index}
                            item={
                                <Box display="flex" alignY="center" fullWidth>
                                    <Box display="flex">
                                        <IndeterminateCheckbox
                                            id={`${idItem}${ordinal}`}
                                            selectedOptionsAmount={selectedOptionsAmount}
                                            optionsAmount={optionsAmount}
                                            onCheckClick={() =>
                                                this.handleCheckClick(values, group, setValues, selectedOptionsAmount)
                                            }
                                            disabled={isLinkStyle}
                                        />
                                    </Box>

                                    <Box display="flex" gap="3" className="mr-auto pointer-events-none">
                                        <Text component="label" className="m-0" size="6" color="heading" bold>
                                            {label}
                                        </Text>
                                        <Text size="6">{quantityText}</Text>
                                    </Box>
                                </Box>
                            }>
                            {options}
                        </Accordion.Item>
                    </Accordion>
                </Col>
            );
        });
    };

    render() {
        const {
            values,
            setValues,
            groups,
            user,
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;
        const groupsLeft = this.getOnlyQueryPermissions(groups, true);
        const groupsRight = this.getOnlyQueryPermissions(groups);

        return (
            <PermissionsAmount permissions={values}>
                {(amountsById, totalAmountsById) => (
                    <Row>
                        <Col xs={6}>
                            <Row gapY="3">
                                {this.acordionItem(
                                    groupsLeft,
                                    amountsById,
                                    totalAmountsById,
                                    values,
                                    setValues,
                                    user,
                                    dispatch,
                                    administrationScheme,
                                )}
                            </Row>
                        </Col>

                        <Col xs={6}>
                            <Row gapY="3">
                                {this.acordionItem(
                                    groupsRight,
                                    amountsById,
                                    totalAmountsById,
                                    values,
                                    setValues,
                                    user,
                                    dispatch,
                                    administrationScheme,
                                )}
                            </Row>
                        </Col>
                    </Row>
                )}
            </PermissionsAmount>
        );
    }
}

const mapStateToProps = (state) => ({
    groups: permissionsSelectors.getGroups(state),
    products: permissionsSelectors.getProducts(state),
    transfersPermissions: permissionsSelectors.getTrasnfersEditionResult(state),
    paymentsPermissions: permissionsSelectors.getPaymentsEditionResult(state),
    otherPermissionsEdition: permissionsSelectors.getOtherPermissionsEditionResult(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(PermissionsForm);

import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Welcome from "pages/_components/Welcome";
import TextField from "pages/_components/fields/TextField";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import { bool, func, node, number, shape, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as momentDayActions, selectors as momentDaySelectors } from "reducers/momentDay";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import { isAndroidPlatform, isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import imageMomentDay from "util/momentday/resources";
import * as config from "util/config";

import {
    registerPasswordFieldRevelock,
    registerTextFieldRevelock,
    registerUsernameFieldRevelock,
} from "util/revelockMobile/revelockMobile.util";
import * as Yup from "yup";

const FORM_ID = "login.step1";
const CREDENTIAL_TYPE_PASSWORD = "password";
const CREDENTIAL_TYPE_OTP = "otp";

const Step1Content = (props) => {
    const { dispatch, deviceHasFingerPrinter, updateDeviceHasFingerPrinter, isSubmitting } = props;
    const [username, setUsername] = useState(props.username);
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    useEffect(() => {
        if (props.isUserActiveCorporate !== props.isActiveCorporate) {
            const { setFieldValue } = props;
            setFieldValue("username", "");
            setFieldValue("password ", "");
        }
    }, [props.isUserActiveCorporate, props.isActiveCorporate]);

    const setCredentialsRevelock = () => {
        if (!isMobileNativeFunc()) {
            return;
        }

        const usernameInput = usernameRef.current;
        if (usernameInput) {
            registerUsernameFieldRevelock(usernameInput);
            registerTextFieldRevelock(usernameInput);
        }
        const passwordInput = passwordRef.current;
        if (passwordInput) {
            registerPasswordFieldRevelock(passwordInput);
            registerTextFieldRevelock(passwordInput);
        }
    };

    useEffect(() => {
        setCredentialsRevelock();
    }, []);

    useEffect(() => {
        dispatch(loginActions.setSubmiting(isSubmitting));
    }, [isSubmitting]);

    useEffect(() => {
        const hour = new Date().getHours();
        const defineMomentDay =
            (hour >= 6 && hour < 12 && "morning") || (hour >= 12 && hour < 18 && "afternoon") || "night";
        dispatch(momentDayActions.setMomentDay(defineMomentDay));
        if (isMobileNativeFunc()) {
            dispatch(fingerprintActions.configurationDeviceHasFingerPrinterRequest());
        }
    }, []);

    useEffect(() => {
        if (deviceHasFingerPrinter) {
            dispatch(loginActions.fingerprintLoginPre());
        }
    }, [deviceHasFingerPrinter, updateDeviceHasFingerPrinter]);

    const reset = () => {
        const { setFieldValue } = props;
        dispatch(loginActions.removeRememberedUser());
        setFieldValue("username", "");
        setUsername(null);
    };

    const updateCredentialType = (credentialType) => {
        const { setFieldValue } = props;
        setFieldValue("password", "");
        setFieldValue("otp", "");
        setFieldValue("credentialType", credentialType);
    };

    const scrollToRef = (ref) => () => {
        if (ref && ref.current) {
            if (isAndroidPlatform()) {
                setTimeout(() => {
                    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 150);
            } else {
                ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    };

    useEffect(() => {
        const scrollToUsername = scrollToRef(usernameRef);
        const scrollToPassword = scrollToRef(passwordRef);

        window.addEventListener("keyboardDidShow", scrollToUsername);
        window.addEventListener("keyboardDidShow", scrollToPassword);

        return () => {
            window.removeEventListener("keyboardDidShow", scrollToUsername);
            window.removeEventListener("keyboardDidShow", scrollToPassword);
        };
    }, []);

    const renderDesktop = () => {
        const {
            isDesktop,
            userFirstName,
            showCaptcha,
            values: { credentialType },
            values,
            fromOnboardingLoginData,
            isUserActiveCorporate,
            isActiveCorporate,
        } = props;

        const maxLengthUsername = config.getInteger("username.maxLength");

        return (
            <Box display="flex" column fullWidth className="ml-9 mr-9">
                <div className="logo__ico" />
                {(!username || isUserActiveCorporate !== isActiveCorporate) && (
                    <Box display="flex" alignX="center" className="mb-5">
                        <Welcome className="mt-0 mb-0" />
                    </Box>
                )}

                <Box display="flex" column alignX="evenly" fullHeight>
                    {username && isUserActiveCorporate === isActiveCorporate && (
                        <Box display="flex" column alignX="center" className="mt-5 mb-7">
                            <Text
                                labelKey="login.step1.welcome"
                                userFullName={userFirstName}
                                bold
                                size="1"
                                align="center"
                            />
                            <Button bsStyle="link" onClick={reset} label="login.step1.forget" block small />
                        </Box>
                    )}

                    <Box display="flex" column>
                        {(!username || isUserActiveCorporate !== isActiveCorporate) && (
                            <Box display="flex" column className="mb-5">
                                <Field
                                    hideLabel={isDesktop}
                                    idForm={FORM_ID}
                                    name="username"
                                    component={TextField}
                                    autoFocus={isDesktop}
                                    placeholder=""
                                    noMarginBottom
                                    className="mb-0"
                                    idField="usernamefield"
                                    maxLength={maxLengthUsername}
                                />

                                <Box display="flex" alignX="between" fullWidth alignY="center">
                                    <Button
                                        href="/recoverUser/step1"
                                        bsStyle="link"
                                        label="login.footer.forgotUser"
                                        className="px-0"
                                        small
                                    />

                                    <Box>
                                        <Field
                                            idForm={FORM_ID}
                                            name="rememberEmail"
                                            component={Checkbox}
                                            checked={values.rememberEmail}
                                            block={false}
                                            noMarginBottom
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        <Box display="flex" column className="mb-0">
                            {credentialType === CREDENTIAL_TYPE_PASSWORD && (
                                <Field
                                    hideLabel
                                    idForm={FORM_ID}
                                    copyEnabled={false}
                                    type="password"
                                    name="password"
                                    component={Credential}
                                    placeholder=""
                                    noMarginBottom
                                    className="mb-0"
                                />
                            )}

                            {credentialType === CREDENTIAL_TYPE_OTP && (
                                <Field
                                    hideLabel
                                    idForm={FORM_ID}
                                    copyEnabled={false}
                                    type="otp"
                                    name="otp"
                                    component={Credential}
                                    placeholder=""
                                    noMarginBottom
                                />
                            )}
                        </Box>

                        <Box display="flex" column fullWidth>
                            {credentialType === CREDENTIAL_TYPE_PASSWORD && (
                                <Box display="flex" alignX="between" fullWidth alignY="baseline">
                                    <Button
                                        href="/recoveryPassword/step1"
                                        bsStyle="link"
                                        label="login.step1.forgotPassword"
                                        className="px-0"
                                        small
                                    />
                                    <Button
                                        type="button"
                                        bsStyle="link"
                                        label="login.step1.signInOtp"
                                        className="px-0"
                                        onClick={() => {
                                            updateCredentialType(CREDENTIAL_TYPE_OTP);
                                        }}
                                        small
                                    />
                                </Box>
                            )}

                            {credentialType === CREDENTIAL_TYPE_OTP && (
                                <Box display="flex" alignX="flex-end" fullWidth alignY="baseline">
                                    <Button
                                        type="button"
                                        bsStyle="link"
                                        label="login.step1.signInPassword"
                                        className="px-0"
                                        onClick={() => {
                                            updateCredentialType(CREDENTIAL_TYPE_PASSWORD);
                                        }}
                                        small
                                    />
                                </Box>
                            )}
                        </Box>

                        {showCaptcha && <Field idForm={FORM_ID} name="captcha" component={Captcha} />}
                    </Box>

                    <Button
                        className="mt-5"
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        loading={isSubmitting}
                        block
                    />
                </Box>

                {!fromOnboardingLoginData && (
                    <Box className="border-top-1 pt-4 mb-5 mt-5" display="flex" alignX="flex-end" column>
                        <Box alignX="center" alignY="center" display="flex" {...(isDesktop && { column: true })}>
                            <Text color="text-boton" labelKey="login.footer.noUser" />

                            <Button
                                href={isActiveCorporate ? "/enrollment" : "/enrollment/document"}
                                bsStyle="link"
                                label="login.footer.createUser"
                                className="p-0 btn-black"
                                small
                                underlinedIn
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        );
    };

    const renderMobile = () => {
        const {
            isDesktop,
            userFirstName,
            showCaptcha,
            values,
            isFetchingLogin,
            isUserActiveCorporate,
            isActiveCorporate,
        } = props;

        const maxLengthUsername = config.getInteger("username.maxLength");

        return (
            <Box position="relative" display="flex" column alignX="between" fullWidth heightAuto>
                {username && isUserActiveCorporate === isActiveCorporate ? (
                    <Box display="flex" column alignX="center" className="mx-9 mt-8 mb-7">
                        <Text
                            component="h3"
                            labelKey="login.step1.welcome"
                            userFullName={userFirstName}
                            bold
                            size="1"
                            align="center"
                            className="mt-0 mb-0"
                        />
                        <Button bsStyle="link" onClick={reset} label="login.step1.forget" block small />
                    </Box>
                ) : (
                    <Box display="flex" column className="mt-3 mx-9">
                        <Field
                            idForm={FORM_ID}
                            name="username"
                            component={TextField}
                            placeholder=""
                            noMarginBottom
                            className="mb-3"
                            inputRef={usernameRef}
                            maxLength={maxLengthUsername}
                        />

                        <Box display="flex" alignX="between" fullWidth alignY="center">
                            <div />
                            <Box className="remember-mail">
                                <Field
                                    idForm={FORM_ID}
                                    name="rememberEmail"
                                    component={Checkbox}
                                    checked={values.rememberEmail}
                                    block={false}
                                    noMarginBottom
                                />
                            </Box>
                        </Box>
                    </Box>
                )}

                <Box className="mx-9">
                    <Field
                        hideLabel={isDesktop}
                        idForm={FORM_ID}
                        copyEnabled={false}
                        type="password"
                        name="password"
                        component={Credential}
                        placeholder=""
                        noMarginBottom
                        inputRef={passwordRef}
                    />
                </Box>

                <Box display="flex" column fullWidth>
                    <>
                        {!username || isUserActiveCorporate !== isActiveCorporate ? (
                            <Box className="remember-links mx-9" display="flex" alignX="between" alignY="baseline">
                                <Button
                                    href="/recoverUser/step1"
                                    bsStyle="link"
                                    label="login.footer.forgotUser"
                                    className="px-0 py-2"
                                    small
                                />

                                <Button
                                    href="/recoveryPassword/step1"
                                    bsStyle="link"
                                    label="login.step1.forgotPassword"
                                    className="px-0 py-2"
                                    small
                                />
                            </Box>
                        ) : (
                            <Box display="flex" alignX="center" alignY="baseline" className="remember-links mx-9">
                                <Button
                                    href="/recoveryPassword/step1"
                                    bsStyle="link"
                                    label="login.step1.forgotPassword"
                                    className="px-0"
                                    small
                                />
                            </Box>
                        )}
                    </>
                </Box>
                {showCaptcha && (
                    <Box className="mb-3 mx-auto" fullWidth>
                        <Field idForm={FORM_ID} name="captcha" component={Captcha} />
                    </Box>
                )}
                <Box className="mb-8 mx-9">
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        loading={isSubmitting || isFetchingLogin}
                        block
                    />
                </Box>
            </Box>
        );
    };

    const {
        isDesktop,
        className,
        isFromAmazon,
        isFromGoogle,
        isFromMessenger,
        isFromWhatsapp,
        momentDay,
        isActiveCorporate,
        showModalInfo,
    } = props;

    const imageSrc = !isDesktop ? imageMomentDay[`${momentDay}Mobile`] : imageMomentDay[momentDay];
    const imageSrcCorporate = imageMomentDay[`${momentDay}MobileCorporate`];

    return (
        <>
            <Box
                display="flex"
                column
                {...(!isDesktop && { alignX: "flex-end", component: "main" })}
                position="relative"
                fitHeight
                zIndex="9"
                className={classNames("login-background", { "mt-auto": !isDesktop })}>
                {!isDesktop && (
                    <img
                        className={classNames("moment-day-img", { "moment-day-img--corporate": isActiveCorporate })}
                        src={isActiveCorporate ? imageSrcCorporate : imageSrc}
                        alt={`${momentDay}`}
                    />
                )}

                <Form className={classNames("display-flex", className)} noValidate="novalidate">
                    {!isDesktop && (
                        <Box display="block" position="absolute" top="n-12" fullWidth>
                            <BoxErrorNotification isMobile scopeToShow="externalLayout" />
                        </Box>
                    )}
                    {!isDesktop && (isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && (
                        <AssistantAlert />
                    )}
                    {isDesktop ? renderDesktop() : renderMobile()}
                </Form>
            </Box>
            <ConfirmDialog
                showDialog={showModalInfo}
                description={i18n.get(`login.modal.info.API605W`)}
                onConfirm={() => {
                    dispatch(loginActions.showModalInfo(false));
                }}
                modalId="login.modal.info"
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    ...loginSelectors.getRememberedUser(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    fromOnboardingLoginData: loginSelectors.getFromOnboardingLoginData(state),
    showCaptcha: loginSelectors.getShowCaptcha(state),
    momentDay: momentDaySelectors.getMomentDay(state) || "",
    deviceHasFingerPrinter: fingerprintSelectors.deviceHasFingerPrinter(state),
    updateDeviceHasFingerPrinter: fingerprintSelectors.updateDeviceHasFingerPrinter(state),
    isFetchingLogin: loginSelectors.getFetching(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    showModalInfo: loginSelectors.getShowModalInfo(state),
});

Step1Content.propTypes = {
    dispatch: func.isRequired,
    isMobileNative: bool.isRequired,
    isDesktop: bool.isRequired,
    isFromAmazon: bool.isRequired,
    isFromGoogle: bool.isRequired,
    isFromWhatsapp: bool.isRequired,
    isFromMessenger: bool.isRequired,
    isSubmitting: bool.isRequired,
    className: string,
    username: string,
    userFirstName: string,
    footer: node,
    values: shape({
        username: string,
        rememberEmail: bool,
    }).isRequired,
    fromOnboardingLoginData: shape({}),
    fingerprintAvailable: shape({}),
    fingerPrintEnvironmentRestricted: bool,
    setFieldValue: func.isRequired,
    showCaptcha: bool.isRequired,
    momentDay: string,
    deviceHasFingerPrinter: bool,
    updateDeviceHasFingerPrinter: number,
    isFetchingLogin: bool,
    isUserActiveCorporate: bool.isRequired,
    isActiveCorporate: bool.isRequired,
    showModalInfo: bool,
};

Step1Content.defaultProps = {
    footer: null,
    className: null,
    username: "",
    userFirstName: "",
    fingerPrintEnvironmentRestricted: false,
    fingerprintAvailable: undefined,
    fromOnboardingLoginData: undefined,
    momentDay: "",
    deviceHasFingerPrinter: false,
    updateDeviceHasFingerPrinter: 0,
    isFetchingLogin: false,
    showModalInfo: false,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            username: props.username || "",
            password: "",
            captcha: "",
            credentialType: CREDENTIAL_TYPE_PASSWORD,
            otp: "",
            rememberEmail: false,
            isActiveCorporate: props.isActiveCorporate,
        }),
        validationSchema: ({ showCaptcha }) =>
            Yup.object().shape({
                username: Yup.string().required(i18n.get(`${FORM_ID}.username.required`)),
                password: Yup.string().when("credentialType", {
                    is: CREDENTIAL_TYPE_PASSWORD,
                    then: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                }),
                otp: Yup.string().when("credentialType", {
                    is: CREDENTIAL_TYPE_OTP,
                    then: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
                }),
                captcha: showCaptcha ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`)) : Yup.string(),
            }),
        handleSubmit: (
            { username, rememberEmail, password, captcha, credentialType, otp, isActiveCorporate },
            formikBag,
        ) => {
            formikBag.props.dispatch(
                loginActions.loginStep1(
                    username.trim(),
                    rememberEmail,
                    password.trim(),
                    captcha,
                    credentialType,
                    otp,
                    formikBag,
                    isActiveCorporate,
                ),
            );
        },
    }),
)(Step1Content);

import classNames from "classnames";
import Image from "pages/_components/Image";
import { bool, oneOf, shape, string } from "prop-types";
import React from "react";
import Gravatar from "react-gravatar";

function Avatar({ className, user, size, noImage }) {
    const userInitials = user.userFullName.match(/\b(\w)/g).join("");

    let content = null;
    if (userInitials) {
        content = noImage ? null : (
            <Image className="svg-icon" src={`images/avatarImages/avatar-${user.defaultAvatarId}.svg`} />
        );
    } else if (user.email) {
        content = (
            <figure>
                <Gravatar
                    alt={user.userFullName}
                    email={user.email}
                    default="404"
                    onError={() => userInitials}
                    className="rounder-content"
                />
            </figure>
        );
    }

    return (
        <div className={classNames("avatar", className, { "avatar-big": size === "big" })}>
            <span className="visually-hidden">{user.userFullName}</span>
            {content}
            <span className={classNames("avatar-text", { "small-text": userInitials.length > 2 })} aria-hidden>
                {userInitials}
            </span>
        </div>
    );
}

Avatar.propTypes = {
    className: string,
    size: oneOf(["normal", "big"]),
    user: shape({
        userFullName: string.isRequired,
        defaultAvatarId: string.isRequired,
    }).isRequired,
    noImage: bool,
};

Avatar.defaultProps = {
    className: null,
    size: "normal",
    noImage: false,
};

export default Avatar;

import * as session from "middleware/session";
import { push, replace } from "react-router-redux";
import { actions as desktopActions, types as desktopTypes } from "reducers/desktop";
import { selectors as fingerprintSelectors, types as fingerprintTypes } from "reducers/fingerprint";
import { actions as generalConditionActions, generalConditionsTypes } from "reducers/generalConditions";
import { actions as notificationActions } from "reducers/notification";
import { actions, types } from "reducers/session";
import { selectors as statusSelectors, types as statusTypes } from "reducers/status";
import globalTypes from "reducers/types/global";
import { actions as updateCompanyDataActions } from "reducers/updateCompanyData/updateCompanyData.reducer";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import { clearSessionDataRevelock } from "util/revelockMobile/revelockMobile.util";
import { readCreditLineList } from "./login";

const sagas = [
    takeLatest(types.LOGOUT_REQUEST, handleLogoutRequest),
    takeLatest(types.CHANGE_ENVIRONMENT_REQUEST, handleChangeEnvironmentRequest),
    takeLatest(types.EXTEND, extendSession),
    takeLatest(types.EXPIRE, expireSession),
    takeLatest(types.MASK_AMOUNT_UPDATE_REQUEST, updateMaskAmount),
];

export default sagas;

function* clearSessionRevelock() {
    if (isMobileNativeFunc()) {
        yield call(clearSessionDataRevelock);
    }
}
function* handleLogoutRequest() {
    const hasGoToLoginStep1 = yield select(statusSelectors.hasGoToLogin);
    try {
        yield call(clearSessionRevelock);
        try {
            yield call(session.logout);
        } catch (e) {
            // we do nothing cause it doesn't
            // matter if it failed
        }
        const showAgain = yield select(fingerprintSelectors.isShowAgain);

        if (showAgain) {
            yield put({ type: fingerprintTypes.FINGERPRINT_SHOW_WIDGET });
        }
    } finally {
        if (hasGoToLoginStep1) {
            yield put(replace("/loginStep1"));
        }
        yield put({ type: globalTypes.CLEAN_UP });
    }
}

export function* handleChangeEnvironmentRequest({
    idEnvironment,
    rememberEnvironment,
    formikBag,
    shouldNavigate = true,
}) {
    const response = yield call(session.changeEnvironment, idEnvironment, rememberEnvironment);

    const { code, data } = response.data;

    if (code === "COR020W") {
        const { message } = response.data;
        if (data.cantAccessByRestriction) {
            yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get(data.cantAccessByRestriction), "error", ["desktop"]),
            );
            yield put(push("/desktop"));
        } else {
            const errorMessage =
                data.idEnvironmentToChange && typeof data.idEnvironmentToChange === "string"
                    ? data.idEnvironmentToChange
                    : message;

            yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
            yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            yield put(push("/settings"));
        }
    } else if (code === "API522I") {
        yield put({
            type: generalConditionsTypes.SET_ACTION_DISPATCH,
            submitGeneralCondition: generalConditionActions.executeActionDispatch,
            submitParamGeneralCondition: { idEnvironment, rememberEnvironment, shouldNavigate },
        });

        yield put(push("/envGeneralConditions"));
    } else {
        let forms = null;
        if (data.forms) {
            forms = {};

            for (let i = 0; i < data.forms.length; i++) {
                let category = forms[data.forms[i].category];
                if (!category) {
                    category = [];
                    forms[data.forms[i].category] = category;
                }
                category.push(data.forms[i]);
            }
        }
        const environment = {
            permissions: data.permissions,
            forms,
            name: data.activeEnvironmentName,
            type: data.activeEnvironmentType,
            id: data.activeIdEnvironment,
            administrationScheme: data.administrationScheme,
            clients: data.clients,
        };

        const { environments, isAdministrator, enabledAssistant, signatureLevel } = response.data.data;
        yield fork(readCreditLineList);

        yield put({
            type: types.CHANGE_ENVIRONMENT_SUCCESS,
            environment,
            environments,
            isAdministrator,
            signatureLevel,
        });

        yield put({ type: desktopTypes.LOAD_LAYOUT_REQUEST });
        yield put({ type: types.SET_ENABLED_ASSISTANT, enabledAssistant });

        const {
            isNeedingUpdateCorporate,
            yearsSinceLastUpdateCorporate,
            dateLastUpdateCorporate,
            stateUpdateCorporate,
        } = data || {};
        if (isNeedingUpdateCorporate) {
            yield put(desktopActions.updateCorporateModalShow(yearsSinceLastUpdateCorporate));
        }
        yield put(updateCompanyDataActions.updateCorporateInfo(dateLastUpdateCorporate, stateUpdateCorporate));
        if (shouldNavigate) {
            yield put(replace("/desktop"));
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* extendSession() {
    yield call(session.extend);
    yield put({ type: types.EXTEND_SUCCESS });
}

function* expireSession({ lastHref }) {
    // dispatching action to save last href in status state
    yield put({ type: statusTypes.SAVE_LAST_HREF, lastHref });
    yield put({ type: types.LOGOUT_REQUEST });

    yield put(notificationActions.showNotification(i18n.get("session.expired"), "error", ["externalLayout"]));
}

function* updateMaskAmount({ maskAmount }) {
    yield put(actions.maskAmountUpdate(maskAmount));
    yield call(session.updateMaskAmount, maskAmount);
}

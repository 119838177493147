import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { bool, func } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";

const TokenActivationFailedDocument = ({ dispatch, isLoggedIn }) => (
    <>
        <Head title="token.activation.title" hideNavbarInMobile additionalClassName="background-primary" />
        <MainContainer classNameMainWrapper="background-menu-background" showLoader={false}>
            <Box display="flex" column alignY="center" background="white" borderRadius="md" className="mt-8" fullWidth>
                <Text
                    component="p"
                    size="1"
                    bold
                    align="center"
                    color="heading"
                    className="mx-5 mt-8 mb-0 mb-8"
                    labelKey="token.activation.document.failed.title"
                />

                <Image className="mb-9" src="images/icons/userToken.svg" />
                <Text
                    component="p"
                    align="center"
                    size="6"
                    color="heading"
                    className="mx-9 mb-12"
                    labelKey="token.activation.document.failed.description"
                />
            </Box>
            <Box display="flex" column className="mt-auto" fullWidth>
                <Button
                    label="token.activation.document.failed.button.tryAgain"
                    type="button"
                    className="btn-outline"
                    onClick={() => {
                        dispatch(
                            routerActions.replace({
                                pathname: isLoggedIn ? "/auth/tokenActivationStep1" : "/tokenActivationStep1",
                                state: {
                                    currentAttempt: 0,
                                },
                            }),
                        );
                    }}
                    block
                />
            </Box>
        </MainContainer>
    </>
);

const mapStateToProps = (state) => ({
    isLoggedIn: sessionSelectors.isLoggedIn(state),
});

TokenActivationFailedDocument.propTypes = {
    isLoggedIn: bool,
    dispatch: func,
};

TokenActivationFailedDocument.defaultProps = {
    isLoggedIn: false,
    dispatch: () => {},
};

export default connect(mapStateToProps)(TokenActivationFailedDocument);

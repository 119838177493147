import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "creditLines/LIST_REQUEST",
    LIST_FAILURE: "creditLines/LIST_FAILURE",
    LIST_SUCCESS: "creditLines/LIST_SUCCESS",
    PRODUCT_LIST_REQUEST: "creditLines/PRODUCT_LIST_REQUEST",
    PRODUCT_LIST_SUCCESS: "creditLines/PRODUCT_LIST_SUCCESS",
    PRODUCT_LIST_FAILURE: "creditLines/PRODUCT_LIST_FAILURE",
    PRODUCT_LIST_INIT: "creditLines/PRODUCT_LIST_INIT",
    SET_INIT_PRODUCT_LIST_FETCHING: "creditLines/SET_INIT_PRODUCT_LIST_FETCHING",
    FETCH: "creditLines/FETCH",
};

export const INITIAL_STATE = {
    fetching: false,
    list: [],
    total: {},
    productList: [],
    fetchingProductList: false,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingProductList: false,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        fetchingProductList: false,
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        list: action.list,
        total: action.total,
        fetchingProductList: false,
    }),
    [types.PRODUCT_LIST_REQUEST]: (state) => ({
        ...state,
        fetchingProductList: true,
    }),
    [types.PRODUCT_LIST_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        productList: action.productCreditLineList,
        fetchingProductList: false,
    }),
    [types.PRODUCT_LIST_FAILURE]: (state) => ({
        ...state,
        fetchingProductList: false,
        fetching: false,
    }),
    [types.PRODUCT_LIST_INIT]: (state) => ({
        ...state,
        productList: [],
    }),
    [types.SET_INIT_PRODUCT_LIST_FETCHING]: (state) => ({
        ...state,
        fetchingProductList: false,
    }),
    [types.FETCH]: (state) => ({
        ...state,
        fetching: true,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list", "total"),
    productListRequest: makeActionCreator(types.PRODUCT_LIST_REQUEST, "creditLineNumber"),
    productListSuccess: makeActionCreator(types.PRODUCT_LIST_SUCCESS, "productCreditLineList"),
    productListFailure: makeActionCreator(types.PRODUCT_LIST_FAILURE),
    productListInit: makeActionCreator(types.PRODUCT_LIST_INIT),
    settingProductListFetching: makeActionCreator(types.SET_INIT_PRODUCT_LIST_FETCHING),
    fetch: makeActionCreator(types.FETCH),
};

export const selectors = {
    isFetching: ({ creditLines }) => creditLines.fetching,
    getList: ({ creditLines }) => creditLines.list,
    getTotal: ({ creditLines }) => creditLines.total,
    getProductList: ({ creditLines }) => creditLines.productList,
    isFetchingProductList: ({ creditLines }) => creditLines.fetchingProductList,
};

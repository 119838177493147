import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string } from "prop-types";
import { parse } from "query-string";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import { getTransactionKind } from "util/transaction";
import { actions as freqDestActions } from "reducers/frequentDestination/frequentDestination";

const getInfoTypeChips = (activeEnvironment) => ({
    transfers: {
        internal: {
            label: "forms.transfers.internal.formName",
            path: "/formCustom/transferInternal",
            permission: activeEnvironment.permissions.transferInternal,
        },
        thirdParties: {
            label: "forms.transfers.thirdParties.formName",
            path: "/formCustom/transferLocal",
            permission:
                activeEnvironment.permissions.transferThirdParties || activeEnvironment.permissions.transferLocal,
        },
        other: {
            label: "forms.transfers.foreign.formName.pill",
            path: "/formCustom/transferForeign",
            permission: activeEnvironment.permissions.transferForeign,
        },
    },
    cardPayment: {
        internal: {
            label: "forms.creditCard.payment.localtype.label",
            path: "/formCustom/payCreditCardLocal",
            permission: activeEnvironment.permissions.payCreditCard,
        },
        thirdParties: {
            label: "forms.creditCard.payment.thirdParties.chip.label",
            path: "/formCustom/payCreditCardThird",
            permission: activeEnvironment.permissions.payCreditCardThird,
        },
        other: {
            label: "forms.creditCard.payment.financingPlan.label",
            path: "/formCustom/financingPlan",
            permission: activeEnvironment.permissions.payCreditCard,
        },
    },
    recharge: {
        internal: {
            label: "forms.creditCard.recharge.localtype.chip.label",
            path: "/formCustom/rechargeCreditCardLocal",
            permission: activeEnvironment.permissions.rechargeCreditCardLocal,
        },
        thirdParties: {
            label: "forms.creditCard.recharge.thirdParties.chip.label",
            path: "/formCustom/rechargeCreditCardThird",
            permission: activeEnvironment.permissions.rechargeCreditCardThird,
        },
    },
});

const FormsFilterChips = ({ location, dispatch, activeEnvironment, isDesktop, idActivity, showLocalRecharge }) => {
    const { pathname, search } = location;

    const isFromWally = parse(search)?.from === "wally";

    const transactionKind = getTransactionKind(idActivity);
    const infoTypeChips = getInfoTypeChips(activeEnvironment);
    const handlerChangeTransfer = (pathTransfer) => {
        if (pathTransfer !== pathname) {
            dispatch(push(pathTransfer));
            dispatch(freqDestActions.setBeneficiarySelected(null));
        }
    };

    return (
        <Box
            component="nav"
            aria-label={i18nUtils.get("global.navTypes.a11y")}
            display="flex"
            alignX={isDesktop ? "center" : "evenly"}
            background="white"
            borderRadius="default"
            className="p-5 p-lg-7 mb-7 mx-n-5 mt-3 mt-md-0">
            {infoTypeChips[transactionKind]?.internal && showLocalRecharge && (
                <Button
                    className={classNames({
                        "is-active": pathname === infoTypeChips[transactionKind]?.internal?.path,
                    })}
                    label={infoTypeChips[transactionKind]?.internal?.label}
                    onClick={() => handlerChangeTransfer(infoTypeChips[transactionKind]?.internal?.path)}
                    {...(pathname === infoTypeChips[transactionKind]?.internal?.path &&
                        isDesktop && { image: "images/check.svg" })}
                    bsStyle="chip"
                    disabled={!infoTypeChips[transactionKind]?.internal?.permission}
                />
            )}
            {infoTypeChips[transactionKind]?.thirdParties && (
                <Button
                    className={classNames("mx-3 mx-md-7", {
                        "is-active": pathname === infoTypeChips[transactionKind]?.thirdParties?.path,
                    })}
                    label={infoTypeChips[transactionKind]?.thirdParties?.label}
                    onClick={() => handlerChangeTransfer(infoTypeChips[transactionKind]?.thirdParties?.path)}
                    {...(pathname === infoTypeChips[transactionKind]?.thirdParties?.path &&
                        isDesktop && { image: "images/check.svg" })}
                    bsStyle="chip"
                    disabled={!infoTypeChips[transactionKind]?.thirdParties?.permission || isFromWally}
                />
            )}
            {infoTypeChips[transactionKind]?.other?.permission && (
                <Button
                    className={classNames({
                        "is-active": pathname === infoTypeChips[transactionKind]?.other?.path,
                    })}
                    label={infoTypeChips[transactionKind]?.other?.label}
                    onClick={() => handlerChangeTransfer(infoTypeChips[transactionKind]?.other?.path)}
                    {...(pathname === infoTypeChips[transactionKind]?.other?.path &&
                        isDesktop && { image: "images/check.svg" })}
                    bsStyle="chip"
                    disabled={!infoTypeChips[transactionKind]?.other?.permission || isFromWally}
                />
            )}
        </Box>
    );
};

FormsFilterChips.propTypes = {
    location: shape({}).isRequired,
    dispatch: func.isRequired,
    activeEnvironment: shape({}).isRequired,
    isDesktop: bool.isRequired,
    idActivity: string.isRequired,
    showLocalRecharge: bool,
};

FormsFilterChips.defaultProps = {
    showLocalRecharge: true,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default resizableRoute(compose(connect(mapStateToProps), withRouter)(FormsFilterChips));

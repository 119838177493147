import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Text from "pages/_components/Text";
import { shape } from "prop-types";
import React from "react";

const TotalAmountSection = ({ list }) => (
    <Box
        component="article"
        display="flex"
        column
        alignX="center"
        alignY="flex-start"
        background="heading-color"
        borderRadius="default"
        className="px-7 py-4 min-height-7rem"
        fullHeight>
        <Text color="inverse" size="6" uppercase className="mb-3 mb-md-1" labelKey="creditLine.total.amount.approved" />
        {list.length > 0 &&
            list.map((sum) => (
                <FormattedAmount
                    key={`TotalAmount${sum.currency}`}
                    currency={sum.currency}
                    quantity={sum.quantity}
                    color="inverse"
                    size="1"
                    bold
                />
            ))}
    </Box>
);

TotalAmountSection.propTypes = {
    list: shape({}).isRequired,
};

export default TotalAmountSection;

import classNames from "classnames";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";

class CreditCardMovement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isTablet: bool.isRequired,
        movement: shape({
            idProduct: string,
            idStatement: number,
            date: string,
            concept: string,
            sourceAmount: number,
            sourceAmountCurrency: string,
            amount: number,
        }).isRequired,
    };

    render() {
        const { movement, isDesktop, isTablet } = this.props;
        const { concept, valueDate, sourceAmount, note } = movement;
        return isDesktop ? (
            <>
                <GridTable.Data columnStart={1} alignX="flex-start">
                    <FormattedDate date={valueDate} />
                </GridTable.Data>
                <GridTable.Data className="p-0" columnStart={2} alignX="flex-start">
                    <Text ellipsis>{concept}</Text>
                </GridTable.Data>
                <GridTable.Data className="p-0" columnStart={3} alignX="flex-end">
                    <FormattedAmount
                        size="6"
                        className={classNames("data-amount justify-content-end", {
                            "is-negative": sourceAmount < 0,
                        })}
                        quantity={sourceAmount}
                        noCurrency
                    />
                </GridTable.Data>
                <GridTable.Data className="p-0" columnStart={4} alignX="flex-end">
                    {note && <Image src="images/note.svg" className={`svg-icon ${!isTablet ? "mr-5" : "mr-2"}`} />}
                    <ChevromRight />
                </GridTable.Data>
            </>
        ) : (
            <Fragment>
                {concept && (
                    <GridTable.Data
                        className="text-bold mr-4"
                        columnStart={1}
                        columnWidth={1}
                        rowStart={1}
                        alignX="flex-start">
                        <Text ellipsis>{concept}</Text>
                    </GridTable.Data>
                )}
                <GridTable.Data
                    className="mr-4"
                    columnStart={1}
                    columnWidth={1}
                    rowStart={concept ? 2 : 1}
                    rowWidth={concept ? 1 : 2}
                    alignX="flex-start">
                    <FormattedDate date={valueDate} />
                </GridTable.Data>
                <GridTable.Data columnStart={2} rowStart={1} rowWidth={2} alignY="center" alignX="flex-end">
                    {note && <Image src="images/note.svg" className={`svg-icon ${!isTablet ? "mr-5" : "mr-2"}`} />}
                    <FormattedAmount
                        size="4"
                        className={classNames("data-amount justify-content-end", {
                            "is-negative": sourceAmount < 0,
                        })}
                        quantity={sourceAmount}
                        noCurrency
                    />
                </GridTable.Data>
                <GridTable.Data columnStart={3} rowStart={1} rowWidth={2} alignY="center" alignX="flex-end">
                    <ChevromRight />
                </GridTable.Data>
            </Fragment>
        );
    }
}

export default CreditCardMovement;

import I18n from "pages/_components/I18n";
import Wizard from "pages/_components/Wizard";
import { bool, string, shape } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { groupFormDataSelectors } from "reducers/administration";

class GroupFormWizardTabs extends Component {
    static propTypes = {
        idForm: string.isRequired,
        formProps: shape({}).isRequired,
        adminGroup: bool,
        mode: string,
    };

    static defaultProps = {
        adminGroup: false,
        mode: "view",
    };

    render() {
        const { idForm, formProps, adminGroup, mode } = this.props;
        const { permissions, users } = formProps.values;

        return (
            <Wizard.Tabs>
                <Wizard.Tab>
                    <I18n id={`${idForm}.tab.basicInfo`} />
                </Wizard.Tab>
                <Wizard.Tab disabled={!Object.keys(permissions).length && mode === "view"}>
                    <I18n id={`${idForm}.tab.permissions`} />
                </Wizard.Tab>
                <Wizard.Tab disabled={adminGroup || (!users.length && mode === "view")}>
                    <I18n id={`${idForm}.tab.members`} />
                </Wizard.Tab>
            </Wizard.Tabs>
        );
    }
}

const mapStateToProps = (state) => ({
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GroupFormWizardTabs);

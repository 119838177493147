import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "transactions/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "transactions/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "transactions/LOAD_LIST_SUCCESS",
    SET_FILTERS: "transactions/SET_FILTERS",
    LOAD_MORE_TRANSACTIONS_REQUEST: "transactions/LOAD_MORE_TRANSACTIONS_REQUEST",
    LOAD_MORE_TRANSACTIONS_SUCCESS: "transactions/LOAD_MORE_TRANSACTIONS_SUCCESS",
    LOAD_MORE_HISTORIC_TRANSACTIONS_REQUEST: "transactions/LOAD_MORE_HISTORIC_TRANSACTIONS_REQUEST",
    LOAD_MORE_HISTORIC_TRANSACTIONS_SUCCESS: "transactions/LOAD_MORE_HISTORIC_TRANSACTIONS_SUCCESS",
    DELETE_DRAFT_REQUEST: "transactions/DELETE_DRAFT_REQUEST",
    DELETE_DRAFT_FAILURE: "transactions/DELETE_DRAFT_FAILURE",
    DELETE_DRAFT_SUCCESS: "transactions/DELETE_DRAFT_SUCCESS",
    REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS: "transactions/REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS",
    DOWNLOAD_LIST_REQUEST: "transactions/DOWNLOAD_LIST_REQUEST",
    DOWNLOAD_LIST_FAILURE: "transactions/DOWNLOAD_LIST_FAILURE",
    DOWNLOAD_LIST_SUCCESS: "transactions/DOWNLOAD_LIST_SUCCESS",
    LOAD_HISTORIC_LIST_REQUEST: "transactions/LOAD_HISTORIC_LIST_REQUEST",
    LOAD_HISTORIC_LIST_FAILURE: "transactions/LOAD_HISTORIC_LIST_FAILURE",
    LOAD_HISTORIC_LIST_SUCCESS: "transactions/LOAD_HISTORIC_LIST_SUCCESS",
    SET_LOAD_MIGTRATION_EXECUTION_COUNT: "transactions/SET_LOAD_MIGTRATION_EXECUTION_COUNT",
    LOAD_MIGRATION_TRANSACTION_LIST_REQUEST: "transactions/LOAD_MIGRATION_TRANSACTION_LIST_REQUEST",
    LOAD_MIGRATION_TRANSACTION_LIST_FAILURE: "transactions/LOAD_MIGRATION_TRANSACTION_LIST_FAILURE",
    LOAD_MIGRATION_TRANSACTION_LIST_SUCCESS: "transactions/LOAD_MIGRATION_TRANSACTION_LIST_SUCCESS",
    DELETE_MIGRATION_TRANSACTION_REQUEST: "transactions/DELETE_MIGRATION_TRANSACTION_REQUEST",
    DELETE_MIGRATION_TRANSACTION_FAILURE: "transactions/DELETE_MIGRATION_TRANSACTION_FAILURE",
    DELETE_MIGRATION_TRANSACTION_SUCCESS: "transactions/DELETE_MIGRATION_TRANSACTION_SUCCESS",
    LIST_CREDENTIAL_GROUPS_REQUEST: "transactions/LIST_CREDENTIAL_GROUPS_REQUEST",
    LIST_CREDENTIAL_GROUPS_SUCCESS: "transactions/LIST_CREDENTIAL_GROUPS_SUCCESS",
    RE_SCHEDULE_MIGRATION_TRANSACTION_REQUEST: "transactions/RE_SCHEDULE_MIGRATION_TRANSACTION_REQUEST",
    RE_SCHEDULE_MIGRATION_TRANSACTION_FAILURE: "transactions/RE_SCHEDULE_MIGRATION_TRANSACTION_FAILURE",
    RE_SCHEDULE_MIGRATION_TRANSACTION_SUCCESS: "transactions/RE_SCHEDULE_MIGRATION_TRANSACTION_SUCCESS",
    SET_DISPLAY_MIGRATION_MODAL_AT_INDEX: "transactions/SET_DISPLAY_MIGRATION_MODAL_AT_INDEX",
};

export const INITIAL_STATE = {
    transactions: null,
    isFetching: false,
    filters: null,
    hasMoreData: false,
    pageNumber: 1,
    isDeletingDraft: false,
    pendingTransactionsQuantity: 0,
    isFirstFetching: true,
    showFilters: false,
    isFetchingDownload: false,
    lastRegistryNumber: "0",
    historicTransactions: null,
    migrationTransactions: null,
    credentialsGroups: [],
    displayMigrationSchedulerModal: [],
    idTransactionToDelete: null,
    isFetchingCustom: false,
    previousRegistryNumber: "0",
    loadMigrationExecutionCount: 0,
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: action.filters,
        isFirstFetching: action.isFirstFetching === true,
        isFetchingDownload: false,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        transactions: action.transactions,
        hasMoreData: action.pageNumber < action.totalPages,
        isFetching: false,
        isFirstFetching: false,
        pageNumber: action.pageNumber,
        showFilters: state.isFirstFetching ? action.totalPages > 1 : state.showFilters,
    }),
    [types.LOAD_HISTORIC_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: action.filters,
        isFirstFetching: action.isFirstFetching === true,
        isFetchingDownload: false,
    }),
    [types.LOAD_HISTORIC_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LOAD_HISTORIC_LIST_SUCCESS]: (state, action) => ({
        ...state,
        historicTransactions: action.transactions,
        lastRegistryNumber: action.newLastRegistryNumber,
        hasMoreData: action.newLastRegistryNumber !== "0",
        isFetching: false,
        isFirstFetching: false,
        showFilters: state.isFirstFetching ? action.lastRegistryNumber !== "0" : state.showFilters,
        previousRegistryNumber: action.newPreviousRegistryNumber,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: action.filters,
    }),
    [types.LOAD_MORE_TRANSACTIONS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        transactions: state.transactions ? state.transactions.concat(action.transactions) : action.transactions,
        hasMoreData: action.pageNumber < action.totalPages,
        pageNumber: action.pageNumber,
    }),
    [types.LOAD_MORE_HISTORIC_TRANSACTIONS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_HISTORIC_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        historicTransactions: state.historicTransactions
            ? state.historicTransactions.concat(action.transactions)
            : action.transactions,
        lastRegistryNumber: action.newLastRegistryNumber,
        hasMoreData: action.newLastRegistryNumber !== "0",
        previousRegistryNumber: action.previousRegistryNumber,
    }),
    [types.DELETE_DRAFT_REQUEST]: (state) => ({
        ...state,
        isDeletingDraft: true,
    }),
    [types.DELETE_DRAFT_FAILURE]: (state) => ({
        ...state,
        isDeletingDraft: false,
    }),
    [types.DELETE_DRAFT_SUCCESS]: (state, action) => ({
        ...state,
        isDeletingDraft: false,
        transactions: state.transactions.filter((item) => item.transaction.idTransaction !== action.idTransaction),
    }),
    [types.REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS]: (state, action) => ({
        ...state,
        pendingTransactionsQuantity: action.pendingTransactionsQuantity,
    }),
    [types.DOWNLOAD_LIST_REQUEST]: (state) => ({
        ...state,
        isFetchingDownload: true,
    }),
    [types.DOWNLOAD_LIST_FAILURE]: (state) => ({
        ...state,
        isFetchingDownload: false,
    }),
    [types.DOWNLOAD_LIST_SUCCESS]: (state) => ({
        ...state,
        isFetchingDownload: false,
    }),
    [types.SET_LOAD_MIGTRATION_EXECUTION_COUNT]: (state, action) => ({
        ...state,
        loadMigrationExecutionCount: action.payload,
    }),
    [types.LOAD_MIGRATION_TRANSACTION_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        loadMigrationExecutionCount: state.loadMigrationExecutionCount + 1,
    }),
    [types.LOAD_MIGRATION_TRANSACTION_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LOAD_MIGRATION_TRANSACTION_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        migrationTransactions: action.transactions,
        displayMigrationSchedulerModal: action.transactions.map(() => false),
        loadMigrationExecutionCount: 3,
    }),
    [types.DELETE_MIGRATION_TRANSACTION_REQUEST]: (state) => ({
        ...state,
        // isFetching: true,
    }),
    [types.DELETE_MIGRATION_TRANSACTION_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DELETE_MIGRATION_TRANSACTION_SUCCESS]: (state, action) => {
        const index = state.migrationTransactions.findIndex((t) => t.idTransaction === action.transactionId);

        return {
            ...state,
            isFetching: false,
            migrationTransactions: state.migrationTransactions.filter((t) => t.idTransaction !== action.transactionId),
            displayMigrationSchedulerModal: state.displayMigrationSchedulerModal
                .slice(0, index)
                .concat(state.displayMigrationSchedulerModal.slice(index + 1)),
        };
    },
    [types.LIST_CREDENTIAL_GROUPS_REQUEST]: (state) => ({
        ...state,
        // isFetching: true,
        credentialsGroups: [],
    }),
    [types.LIST_CREDENTIAL_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        // isFetching: false,
        credentialsGroups: action.credentialsGroups,
    }),
    [types.RE_SCHEDULE_MIGRATION_TRANSACTION_REQUEST]: (state) => ({
        ...state,
        isFetchingCustom: true,
    }),
    [types.RE_SCHEDULE_MIGRATION_TRANSACTION_FAILURE]: (state) => ({
        ...state,
        isFetchingCustom: false,
        displayMigrationSchedulerModal: state.displayMigrationSchedulerModal.map(() => false),
    }),
    [types.RE_SCHEDULE_MIGRATION_TRANSACTION_SUCCESS]: (state, action) => {
        const index = state.migrationTransactions.findIndex((t) => t.idTransaction === action.transactionId);
        return {
            ...state,
            isFetchingCustom: false,
            migrationTransactions: state.migrationTransactions.filter((t) => t.idTransaction !== action.transactionId),
            displayMigrationSchedulerModal: state.displayMigrationSchedulerModal
                .slice(0, index)
                .concat(state.displayMigrationSchedulerModal.slice(index + 1)),
        };
    },
    [types.SET_DISPLAY_MIGRATION_MODAL_AT_INDEX]: (state, action) => ({
        ...state,
        displayMigrationSchedulerModal: state.displayMigrationSchedulerModal
            .slice(0, action.index)
            .concat([action.value], state.displayMigrationSchedulerModal.slice(action.index + 1)),
    }),
});

export const actions = {
    loadListRequest: makeActionCreator(
        types.LOAD_LIST_REQUEST,
        "filters",
        "onlyPendings",
        "onlyProcessing",
        "pendingDispatch",
        "isFirstFetching",
        "formikBag",
    ),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "transactions", "pageNumber", "totalPages"),
    loadHistoricListRequest: makeActionCreator(
        types.LOAD_HISTORIC_LIST_REQUEST,
        "filters",
        "isFirstFetching",
        "lastRegistryNumber",
        "previousRegistryNumber",
        "formikBag",
    ),
    loadHistoricListFailure: makeActionCreator(types.LOAD_HISTORIC_LIST_FAILURE),
    loadHistoricListSuccess: makeActionCreator(
        types.LOAD_HISTORIC_LIST_SUCCESS,
        "transactions",
        "newLastRegistryNumber",
        "newPreviousRegistryNumber",
    ),
    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    loadMoreTransactionsRequest: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_REQUEST,
        "filters",
        "onlyPendings",
        "onlyProcessing",
        "pendingDispatch",
    ),
    loadMoreTransactionsSuccess: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_SUCCESS,
        "transactions",
        "pageNumber",
        "totalPages",
    ),
    loadMoreHistoricTransactionsRequest: makeActionCreator(
        types.LOAD_MORE_HISTORIC_TRANSACTIONS_REQUEST,
        "filters",
        "lastRegistryNumber",
        "previousRegistryNumber",
    ),
    loadMoreHistoricTransactionsSuccess: makeActionCreator(
        types.LOAD_MORE_HISTORIC_TRANSACTIONS_SUCCESS,
        "transactions",
        "newLastRegistryNumber",
        "previousRegistryNumber",
    ),
    loadMigrationTransactionListRequest: makeActionCreator(types.LOAD_MIGRATION_TRANSACTION_LIST_REQUEST, "formikBag"),
    loadMigrationTransactionListFailure: makeActionCreator(types.LOAD_MIGRATION_TRANSACTION_LIST_FAILURE),
    loadMigrationTransactionListSuccess: makeActionCreator(
        types.LOAD_MIGRATION_TRANSACTION_LIST_SUCCESS,
        "transactions",
    ),
    deleteDraftRequest: makeActionCreator(types.DELETE_DRAFT_REQUEST, "idTransaction"),
    deleteDraftFailure: makeActionCreator(types.DELETE_DRAFT_FAILURE),
    deleteDraftSuccess: makeActionCreator(types.DELETE_DRAFT_SUCCESS, "idTransaction"),
    downloadListRequest: makeActionCreator(types.DOWNLOAD_LIST_REQUEST, "filters", "url", "lastRegistryNumber"),
    deleteMigrationTransactionRequest: makeActionCreator(
        types.DELETE_MIGRATION_TRANSACTION_REQUEST,
        "idTransaction",
        "transactionType",
        "formikBag",
        "onFinish",
    ),
    deleteMigrationTransactionFailure: makeActionCreator(types.DELETE_MIGRATION_TRANSACTION_FAILURE),
    deleteMigrationTransactionSuccess: makeActionCreator(types.DELETE_MIGRATION_TRANSACTION_SUCCESS, "transactionId"),
    listCredentialGroupsRequest: makeActionCreator(types.LIST_CREDENTIAL_GROUPS_REQUEST, "idActivity"),
    reScheduleMigrationTransactionRequest: makeActionCreator(
        types.RE_SCHEDULE_MIGRATION_TRANSACTION_REQUEST,
        "transactionId",
        "transactionType",
        "scheduler",
        "beneficiary",
        "idDebitAccount",
        "reason",
        "amount",
        "debitAccount",
        "debitAccountData",
        "creditAccount",
        "creditAccountData",
        "beneficiaryId",
        "credentials",
        "formikBag",
        "creationDateTime",
    ),
    reScheduleMigrationTransactionFailure: makeActionCreator(types.RE_SCHEDULE_MIGRATION_TRANSACTION_FAILURE),
    reScheduleMigrationTransactionSuccess: makeActionCreator(
        types.RE_SCHEDULE_MIGRATION_TRANSACTION_SUCCESS,
        "transactionId",
    ),
    setDisplayMigrationSchedulerModal: makeActionCreator(types.SET_DISPLAY_MIGRATION_MODAL_AT_INDEX, "index", "value"),
    downloadListSuccess: makeActionCreator(types.DOWNLOAD_LIST_SUCCESS),
};

export const selectors = {
    getTransactions: ({ transactions }) => transactions.transactions,
    getHistoricTransactions: ({ transactions }) => transactions.historicTransactions,
    getMigrationTransactions: ({ transactions }) => transactions.migrationTransactions,
    getFirstFetch: ({ transactions }) => transactions.isFirstFetching,
    getShowFilters: ({ transactions }) => transactions.showFilters,
    getFetching: ({ transactions }) => transactions.isFetching,
    getFetchingCustom: ({ transactions }) => transactions.isFetchingCustom,
    getHasMoreData: ({ transactions }) => transactions.hasMoreData,
    getPageNumber: ({ transactions }) => transactions.pageNumber,
    getFilters: ({ transactions }) => transactions.filters,
    isDeletingDraft: ({ transactions }) => transactions.isDeletingDraft,
    getPendingTransactionsQuantity: ({ transactions }) => transactions.pendingTransactionsQuantity,
    isFetchingDownload: ({ transactions }) => transactions.isFetchingDownload,
    getLastRegistryNumber: ({ transactions }) => transactions.lastRegistryNumber,
    getCredentialsGroups: ({ transactions }) => transactions.credentialsGroups,
    getDisplayMigrationSchedulerModal: ({ transactions }) => transactions.displayMigrationSchedulerModal,
    getPreviousRegistryNumber: ({ transactions }) => transactions.previousRegistryNumber,
    getLoadMigrationExecutionCount: ({ transactions }) => transactions.loadMigrationExecutionCount,
};

import { ID_ACTIVITY_DOWNLOAD_STATE } from "constants.js";
import { Field, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import PageLoading from "pages/_components/PageLoading";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, func, number, string, arrayOf, shape, oneOfType } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { selectors as sessionSelectors } from "reducers/session";
import { maskEmail } from "util/format";
import RadioButtonCustom from "pages/forms/customForms/_customFields/RadioButtonCustom";
import mapProps from "pages/_components/mapProps";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import { actions as creditCardActions } from "reducers/creditCard";
import * as configUtils from "util/config";
import Image from "pages/_components/Image";
import classNames from "classnames";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { useDataAmount } from "./hooks/TransferInternalHooks";

const { decimalSeparator, thousandSeparator } = numberFormat();

const ExchangePointsForm = (props) => {
    const {
        currentLang,
        dispatch,
        handleSubmit,
        isSubmitting,
        isDesktop,
        isFeatchingProduct,
        totalPoints,
        values,
        creditCardNumber,
        setFieldValue,
        isFeatchingRedeem,
        redeemOptions,
        loggedUser,
        setErrors,
        milesOptions,
        preDataForm,
    } = props;

    const [moneyValue, setMoneyValue] = useState(null);
    const [isPreviewState, setIsPreviewState] = useState(false);
    const [isPreviewMilesState, setIsPreviewMilesState] = useState(false);
    const [redeemOptionsList, setRedeemOptionsList] = useState(null);
    const [maxLengthNumberTravel, setMaxLengthNumberTravel] = useState(9);
    const [showOptions, setShowOptions] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [dataAmount] = useDataAmount(preDataForm);

    const dataAmountMiles = {
        ...dataAmount,
        decimalSeparator: " ",
        precision: 0,
        thousandsSeparator: ",",
        options: [],
    };

    const genericProps = {
        mode: "edit",
        lang: currentLang,
        idActivity: ID_ACTIVITY_DOWNLOAD_STATE,
        dispatch,
    };

    useEffect(() => {
        const newList = redeemOptions.map((option) => ({ ...option, value: 0.0 }));
        setRedeemOptionsList(newList);
        setShowOptions(false);
    }, [redeemOptions]);

    const handleClickCalculate = () => {
        const { points: pointsRef } = values;
        setErrors({});
        const points = pointsRef.quantity ? pointsRef.quantity.replace(/\s+/g, "") : "";
        if (!points || points === "" || points === " " || points === "NaN") {
            setErrors({ points: i18n.get(DEFAULT_REQUIRED) });
            setShowOptions(false);
            return false;
        }
        if (points === "0") {
            setErrors({ points: i18n.get("redeem.miles.points.error.zero") });
            setShowOptions(false);
            return false;
        }

        if (points === "" || points === "NaN") {
            setErrors({ points: i18n.get(DEFAULT_REQUIRED) });
            setShowOptions(false);
            return false;
        }

        if (points === "0") {
            setErrors({ points: i18n.get("redeem.miles.points.error.zero") });
            setShowOptions(false);
            return false;
        }

        const minimumPointsToExchange = configUtils.getInteger("redeem.minimum.pointsToExchange", 1000);
        if (minimumPointsToExchange > points) {
            setErrors({
                points: i18n.get("redeem.minimum.pointsToExchange.error", null, {
                    POINTS: minimumPointsToExchange,
                }),
            });
            setShowOptions(false);
            return false;
        }

        if (points > totalPoints) {
            setErrors({ points: i18n.get("forms.product.exchange.points.validation") });
            setMoneyValue(null);
            setFieldValue("moneyValue", null);
            setShowOptions(false);
            return false;
        }
        const newRedeemOptionsList = redeemOptionsList.map((option) => {
            const isCash = option.name.toLowerCase().includes("cash");
            const newValue = option.conversionRate * points;
            if (isCash) {
                setFieldValue("moneyValue", newValue);
                setMoneyValue(newValue);
            }

            return { ...option, value: newValue };
        });
        setRedeemOptionsList(newRedeemOptionsList);
        setShowOptions(true);
        return true;
    };

    const handleClickExchange = (isCash) => {
        if (!handleClickCalculate()) {
            setShowOptions(false);
            return;
        }
        if (typeof isCash === "boolean") {
            setFieldValue("isCashRedeem", isCash);

            if (isCash) {
                setFieldValue("optionExchange", 1);
                setIsPreviewState(true);
            } else {
                setFieldValue("optionExchange", 0);
                setIsPreviewMilesState(true);
            }
        }
    };

    function reloadValuesForm(backupValues) {
        Object.keys(backupValues).forEach((keyItem) => {
            setFieldValue(keyItem, backupValues[keyItem]);
        });
    }

    useEffect(
        () => () => {
            dispatch(productActions.hideExchangePointForm());
        },
        [],
    );

    return (
        <PageLoading loading={isSubmitting || isFeatchingProduct || isFeatchingRedeem}>
            <form
                onSubmit={handleSubmit}
                className={classNames("display-flex flex-column flex full-width pb-12", {
                    "justify-content-between": values?.miles,
                })}>
                {!isPreviewState && !isPreviewMilesState && (
                    <>
                        <Box display="flex" alignX="center" alignY="center" fullWidth>
                            <Text
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.points.text.avaible.points"
                                className="mr-4"
                            />

                            <FormattedAmount
                                quantity={totalPoints}
                                noCurrency
                                size={isDesktop ? "3" : "1"}
                                color="heading"
                                bold
                                noDecimalsWhenRound
                            />
                        </Box>

                        <Box display="flex" column fullWidth fullHeight>
                            <Row gapX="5" heightAuto className="mb-12 px-0">
                                <Col xs={10} md={8}>
                                    <Box className="form-group pt-7">
                                        <FieldLabel labelKey="forms.product.exchange.points.field.points" />
                                        <Field
                                            {...genericProps}
                                            renderLabel={false}
                                            component={FormFieldsComponents.Amount}
                                            quantityTextAlign="text-left"
                                            data={dataAmountMiles}
                                            noCurrency
                                            currencyBox={false}
                                            hidePlaceholder
                                            decimalSeparator={decimalSeparator}
                                            precision="0"
                                            thousandsSeparator={thousandSeparator}
                                            fixedDecimalScale={false}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    handleClickCalculate();
                                                }
                                            }}
                                            key="points"
                                            name="points"
                                            idField="points"
                                            isRequired
                                            inputAlign="left"
                                            autocomplete="off"
                                            absoluteStyleError
                                        />
                                    </Box>
                                </Col>
                                <Col xs={2} md={4} className="mt-auto">
                                    {isDesktop ? (
                                        <Button
                                            className="full-width"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                            label="forms.product.exchange.points.button.calculate"
                                            onClick={handleClickCalculate}
                                        />
                                    ) : (
                                        <Button
                                            ariaLabel="global.search"
                                            bsStyle="primary"
                                            image="images/search.svg"
                                            onClick={handleClickCalculate}
                                        />
                                    )}
                                </Col>
                            </Row>
                            {showOptions && (
                                <Box display="flex" column gap={isDesktop ? "6" : "5"}>
                                    {redeemOptionsList.map((option) => {
                                        const isCash = option.name.toLowerCase().includes("cash");
                                        return (
                                            <Box
                                                display="flex"
                                                alignX="between"
                                                background="menu-background"
                                                borderRadius="default"
                                                className="p-5"
                                                fullWidth>
                                                <Box display="flex" column>
                                                    <Text
                                                        {...(isDesktop && { className: "mb-2" })}
                                                        color="heading"
                                                        labelKey={`forms.product.exchange.label.${option.name}`}
                                                    />
                                                    {isCash ? (
                                                        <FormattedAmount
                                                            quantity={option.value}
                                                            currency="USD"
                                                            size={isDesktop ? "3" : "1"}
                                                            color="heading"
                                                            bold
                                                        />
                                                    ) : (
                                                        <FormattedAmount
                                                            quantity={option.value}
                                                            noCurrency
                                                            size={isDesktop ? "3" : "1"}
                                                            color="heading"
                                                            bold
                                                        />
                                                    )}
                                                </Box>
                                                <Button
                                                    label={
                                                        isCash
                                                            ? "forms.product.exchange.points.button.exchange"
                                                            : "forms.product.exchange.points.send.miles"
                                                    }
                                                    bsStyle="link"
                                                    loading={isSubmitting}
                                                    disabled={!showOptions}
                                                    onClick={() => handleClickExchange(isCash)}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                        </Box>
                    </>
                )}
                {isPreviewState && (
                    <>
                        <Box display="flex" column alignY="center" className="p-3 mb-8" fullWidth>
                            <Text
                                component="p"
                                className="m-0"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.points.preview.text.credit"
                            />

                            <Text
                                component="p"
                                className="m-0"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                defaultValue={creditCardNumber}
                                bold
                            />
                        </Box>
                        <Box display="flex" column alignY="center" className="p-3 mb-8" fullWidth>
                            <Text
                                component="p"
                                className="m-0"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.points.preview.text.amount"
                            />

                            <FormattedAmount
                                quantity={moneyValue}
                                currency="USD"
                                size={isDesktop ? "3" : "1"}
                                color="heading"
                                bold
                            />
                        </Box>

                        <Box display="flex" column alignY="center" className="p-3 mb-4" fullWidth>
                            <Text
                                component="p"
                                className="m-0"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.points.preview.text.concept"
                            />
                        </Box>

                        <Box display="flex" column fullWidth className="mt-auto mb-3">
                            <Button
                                loading={isSubmitting}
                                type="submit"
                                bsStyle="primary"
                                disabled={!moneyValue}
                                label="global.confirm"
                                block
                            />
                            <Button
                                loading={isSubmitting}
                                type="button"
                                bsStyle="outline"
                                label="global.cancel"
                                onClick={() => {
                                    dispatch(productActions.hideExchangePointForm());
                                }}
                                block
                            />
                        </Box>
                    </>
                )}

                {isPreviewMilesState && (
                    <>
                        <Box display="flex" column alignY="center" className="mb-4" fullWidth>
                            <Text
                                component="p"
                                className="m-0"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.miles.title"
                            />
                        </Box>
                        <Box display="flex" alignY="center" alignX="center" className="p-3" fullWidth>
                            <Text
                                component="p"
                                className="mr-2"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.miles.subtitle1"
                            />

                            <FormattedAmount
                                quantity={values?.points?.quantity}
                                noCurrency
                                size={isDesktop ? "3" : "1"}
                                color="primary-color"
                                bold
                                noDecimalsWhenRound
                            />

                            <Text
                                component="p"
                                className="ml-2"
                                align="center"
                                size={isDesktop ? "5" : "3"}
                                color="heading"
                                labelKey="forms.product.exchange.miles.subtitle2"
                            />
                        </Box>

                        <Box display="flex" column alignY="center" className="p-3" fullWidth>
                            <Field
                                {...genericProps}
                                component={RadioButtonCustom}
                                options={milesOptions}
                                key="miles"
                                name="miles"
                                idField="miles"
                                isRequired
                                noLabel
                                gap="4"
                                onChange={(uploadOption) => {
                                    setFieldValue("travelNumber", "");
                                    setFieldValue("miles", uploadOption);
                                    if (uploadOption === "Connectmiles" && !loadData) {
                                        setLoadData(true);
                                        setMaxLengthNumberTravel(9);
                                        dispatch(
                                            productActions.validateTravelNumberRequest(
                                                { ...values, miles: uploadOption },
                                                reloadValuesForm,
                                            ),
                                        );
                                    } else {
                                        setMaxLengthNumberTravel(11);
                                        setLoadData(false);
                                    }
                                }}
                                genericPropRender={{ column: "column", alignY: "top" }}
                            />
                        </Box>

                        {values?.miles === "Lifemiles" && (
                            <Row gapX="5" heightAuto className="mb-12 px-0 mt-4">
                                <Col xs={12} md={12}>
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.IntegerNumberField}
                                        hidePlaceholder
                                        labelText={i18n.get("forms.product.exchange.miles.travelNumber")}
                                        key="travelNumber"
                                        name="travelNumber"
                                        idField="travelNumber"
                                        isRequired
                                        disabled={!values.miles}
                                        inputAlign="left"
                                        autocomplete="off"
                                        showErrors={false}
                                        maxLength={maxLengthNumberTravel}
                                    />
                                </Col>
                            </Row>
                        )}

                        {values?.miles === "Connectmiles" && values?.travelNumber && (
                            <Row gapX="5" heightAuto className="mb-9 px-0 mt-6">
                                <Col xs={12}>
                                    <Box display="flex" column gap="6">
                                        <Text color="text-boton" size={isDesktop ? "5" : "3"}>
                                            {i18n.get("redeem.miles.travelNumber.valid.label")}
                                        </Text>
                                        <Box
                                            display="flex"
                                            className="px-9 py-2 mx-auto"
                                            background="background-secondary"
                                            fitWidth
                                            borderRadius="md">
                                            <Text color="heading-color" size="1" bold>
                                                {values.travelNumber}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Col>
                            </Row>
                        )}

                        {values?.miles === "Connectmiles" && !values?.travelNumber && (
                            <Row gapX="5" heightAuto className="mb-9 px-0 mt-6">
                                <Col xs={12} className="items-center">
                                    <Text
                                        component="p"
                                        size={isDesktop ? "3" : "1"}
                                        color="heading"
                                        bold
                                        align="center"
                                        labelKey="redeem.miles.travelNumber.invalid.title.error"
                                        className="mb-4"
                                    />
                                    <Image src="images/icons/error.svg" width="12" height="12" />
                                    <Text
                                        component="p"
                                        align="center"
                                        labelKey="redeem.miles.travelNumber.invalid.description.error"
                                        className="mt-4"
                                    />
                                    <Button
                                        className="mt-4"
                                        loading={isSubmitting}
                                        type="button"
                                        bsStyle="primary"
                                        label="global.accept"
                                        onClick={() => {
                                            dispatch(productActions.hideExchangePointForm());
                                        }}
                                        block
                                    />
                                </Col>
                            </Row>
                        )}

                        {values.miles && (values?.miles === "Lifemiles" || values?.travelNumber) && (
                            <Box display="flex" row alignY="start" alignX="left" className="p-0 mb-4" fullWidth>
                                <Field
                                    idForm="forms.product.exchange.miles"
                                    name="check"
                                    component={Checkbox}
                                    checked={values.check}
                                    noLabel
                                    hideLabel
                                    formGroup
                                    enableControlInput
                                    topAlign
                                />
                                <Text
                                    component="p"
                                    className="m-0"
                                    align="left"
                                    size={isDesktop ? "5" : "3"}
                                    color="heading">
                                    {i18n.get("forms.product.exchange.miles.check.label", null, {
                                        EMAIL: maskEmail(loggedUser.email).toLowerCase(),
                                    })}
                                </Text>
                            </Box>
                        )}

                        {values.miles && (values?.miles === "Lifemiles" || values?.travelNumber) && (
                            <Box display="flex" column fullWidth className="mt-auto mb-3">
                                <Button
                                    loading={isSubmitting}
                                    type="submit"
                                    bsStyle="primary"
                                    disabled={
                                        !values.miles ||
                                        !values.travelNumber ||
                                        values.travelNumber.replace(/\s+/g, "") === "" ||
                                        !values.check
                                    }
                                    label="global.confirm"
                                    onClick={handleClickExchange}
                                    block
                                />
                                <Button
                                    loading={isSubmitting}
                                    type="button"
                                    bsStyle="outline"
                                    label="global.cancel"
                                    onClick={() => {
                                        dispatch(productActions.hideExchangePointForm());
                                    }}
                                    block
                                />
                            </Box>
                        )}
                    </>
                )}
            </form>
        </PageLoading>
    );
};

ExchangePointsForm.propTypes = {
    currentLang: string,
    dispatch: func,
    handleSubmit: func,
    isSubmitting: bool,
    isDesktop: bool,
    isFeatchingProduct: bool,
    showEmptyList: bool.isRequired,
    creditCardNumber: number.isRequired,
    idProduct: string.isRequired,
    values: string,
    setFieldValue: func.isRequired,
    setErrors: func.isRequired,
    isFeatchingRedeem: bool,
    totalPoints: number,
    redeemOptions: arrayOf(shape({ length: number })),
    milesOptions: arrayOf(shape({ length: number })),
    loggedUser: shape({
        accessToken: string,
        defaultAvatarId: string,
        email: string,
        previousLoginInfo: arrayOf(
            shape({
                city: string,
                country: string,
                date: string,
                idUser: string,
                ip: string,
                latitude: number,
                longitude: number,
            }),
        ),
        securitySeal: oneOfType([number, string]),
        userFullName: string,
    }),
    preDataForm: shape({}),
};

ExchangePointsForm.defaultProps = {
    currentLang: "",
    dispatch: () => {},
    handleSubmit: () => {},
    isSubmitting: false,
    isDesktop: true,
    isFeatchingProduct: false,
    values: {},
    isFeatchingRedeem: false,
    totalPoints: 0,
    redeemOptions: [],
    milesOptions: [],
    loggedUser: null,
    preDataForm: shape({}),
};

const mapStateToProps = (state) => ({
    isFeatchingProduct: productSelectors.isFeatchingProduct(state) || false,
    isFeatchingRedeem: productSelectors.isFeatchingRedeem(state) || false,
    totalPoints: productSelectors.getTotalPoints(state) || 0,
    redeemOptions: productSelectors.getRedeemOptions(state) || [],
    milesOptions: productSelectors.getMilesOptions(state) || [],
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,

        validate: (values, formikBag) => {
            const errors = {};

            const { points, miles, travelNumber, optionExchange } = values;

            const pointsQuantity = points.quantity;

            if (!pointsQuantity) {
                errors.points = i18n.get(DEFAULT_REQUIRED);
            }

            if (optionExchange === 1) {
                return errors;
            }

            if (!miles) {
                errors.miles = i18n.get(DEFAULT_REQUIRED);
            }

            if (!travelNumber) {
                errors.travelNumber = i18n.get(DEFAULT_REQUIRED);
            }

            if (miles) {
                const { milesOptions } = formikBag;

                const filterSelected = milesOptions.filter((mile) => mile.key === miles);

                if (travelNumber.length !== filterSelected[0].maxLength) {
                    errors.travelNumber = i18n.get("forms.product.exchange.miles.travelNumber.invalid");
                }
            }

            return errors;
        },

        handleSubmit: (values, formikBag) => {
            const { points, moneyValue, optionExchange, miles, travelNumber } = values;
            const { idProduct, dispatch, totalPoints } = formikBag.props;

            const pointsQuantity = points.quantity;
            dispatch(creditCardActions.clearOffsetMovements());
            if (optionExchange === 1) {
                dispatch(
                    productActions.exchangePointsProduct(
                        idProduct || "",
                        formikBag,
                        pointsQuantity,
                        totalPoints,
                        moneyValue,
                    ),
                );
            } else {
                dispatch(
                    productActions.exchangePointsProductMiles(
                        idProduct || "",
                        formikBag,
                        pointsQuantity,
                        miles,
                        travelNumber,
                        totalPoints,
                    ),
                );
            }
        },
    }),
    mapProps(({ metadata, ...props }) => ({
        ...props,
        metadata,
    })),
)(ExchangePointsForm);

import React from "react";

export default function Spinner() {
    return (
        <>
            <span className="btn-loading-indicator">
                <span />
                <span />
                <span />
            </span>
            <span className="btn-loading-text">Loading</span>
        </>
    );
}

import classNames from "classnames";
import Accordion from "pages/_components/Accordion";
import Box from "pages/_components/Box";
import Text from "pages/_components/Text";
import PermissionsAmount from "pages/administration/_components/PermissionsAmount";
import { arrayOf, func, objectOf, shape, string, bool } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

class PermissionsList extends Component {
    static propTypes = {
        permissions: objectOf(arrayOf(string)),
        transfersPermissions: arrayOf(shape()),
        paymentsPermissions: arrayOf(shape()),
        products: arrayOf(
            shape({
                idProduct: string,
                number: string,
                productAlias: string,
                label: string,
            }),
        ).isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        children: func.isRequired,
        border: string,
        borderItem: string,
        borderContainer: string,
        classNameContainer: string,
        accordionBorderRadius: string,
        childrenListULClassName: string,
        childrenListLIClassName: string,
        childrenListLIInnerClassName: string,
        contentClassName: string,
        accordionClassName: string,
        buttonLiClassName: string,
        idUser: string,
        onlyRead: bool,
    };

    static defaultProps = {
        permissions: {},
        transfersPermissions: [],
        paymentsPermissions: [],
        border: null,
        borderItem: null,
        borderContainer: "bottom-background-divider-1",
        classNameContainer: "px-8",
        accordionBorderRadius: "md",
        childrenListULClassName: null,
        childrenListLIClassName: null,
        childrenListLIInnerClassName: null,
        contentClassName: "px-0",
        accordionClassName: null,
        buttonLiClassName: "pl-5 pr-7 border-bottom-background-divider-1",
        idUser: null,
        onlyRead: false,
    };

    permissionValue = (permissionList) => {
        if (permissionList && permissionList[0].productTypes) {
            return permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`);
        }

        return ["NONE"];
    };

    getPermissions = () => {
        const { groups, permissions, transfersPermissions, paymentsPermissions } = this.props;
        const permissionsListToModify = { ...permissions };

        groups
            .filter((g) => g.childrenList.length > 0)
            .forEach((groupFiltered) => {
                let amountPermissionList = [];
                if (groupFiltered.idItem === "transfers") {
                    amountPermissionList = transfersPermissions;
                }
                if (groupFiltered.idItem === "payments") {
                    amountPermissionList = paymentsPermissions;
                }
                amountPermissionList.forEach((permission) => {
                    if (permission.selected) {
                        const productList = [];
                        for (let i = 0; i < permission.accounts.length; i++) {
                            productList.push(permission.accounts[i].idProduct);
                        }
                        permissionsListToModify[permission.idPermission] = productList;
                    } else {
                        delete permissionsListToModify[permission.idPermission];
                    }
                });

                const permissionListFiltered = groupFiltered?.childrenList.filter((gf) => gf.permissionList.length > 1);
                if (permissionListFiltered?.length && permissionListFiltered?.length > 0) {
                    permissionListFiltered.forEach((permissionFiltered) => {
                        const { idItem } = permissionFiltered;

                        const idPermissions = permissionFiltered?.permissionList.map(
                            ({ idPermission }) => idPermission,
                        );

                        if (idPermissions.length > 1) {
                            let isGroupPermissionActive = false;
                            idPermissions.forEach((permission) => {
                                isGroupPermissionActive =
                                    permissionsListToModify[permission] !== null &&
                                    permissionsListToModify[permission] !== undefined &&
                                    permissionsListToModify[permission].length !== 0;
                            });

                            if (isGroupPermissionActive) {
                                permissionsListToModify[idItem] = this.permissionValue(idItem);
                            }
                        }
                    });
                }
            });

        return permissionsListToModify;
    };

    renderProducts = (option) => {
        const {
            permissions: permissionsFromProps,
            products: productFromProps,
            childrenListULClassName,
            childrenListLIClassName,
            childrenListLIInnerClassName,
        } = this.props;

        return (
            <Box component="ul" className={classNames("list", childrenListULClassName)}>
                {productFromProps.reduce((products, { idProduct, productAlias, label, productType }) => {
                    const permissions = permissionsFromProps[option.idItem];
                    const accountNumber = label.match(/\*\d+/);

                    const labelSplit = productType === "TC" ? label : label.split(/\*\d+/);
                    if (!permissions.includes(idProduct)) {
                        return products;
                    }

                    return [
                        ...products,
                        <Box
                            component="li"
                            key={idProduct}
                            className={classNames("list-item", childrenListLIClassName)}>
                            <div className={classNames("list-item-inner", childrenListLIInnerClassName)}>
                                <div
                                    style={{
                                        display: "inline-flex",
                                    }}
                                    className="product-label">
                                    <Box display="flex" fullWidth>
                                        <Box className="d-grid">
                                            <Text className="mr-2" ellipsis color="heading-color">
                                                {productAlias || labelSplit}
                                            </Text>
                                        </Box>
                                        {productType === "TC" ? <></> : <Text>{accountNumber}</Text>}
                                    </Box>
                                </div>
                            </div>
                        </Box>,
                    ];
                }, [])}
            </Box>
        );
    };

    renderItem = (option) => {
        const { childrenListLIClassName, childrenListLIInnerClassName } = this.props;
        return (
            <Box component="li" key={option.idItem} className={classNames("list-item", childrenListLIClassName)}>
                <div className={classNames("list-item-inner", childrenListLIInnerClassName)}>
                    <Text size="6" color="heading-color" ellipsis>
                        {option.label}
                    </Text>
                </div>
            </Box>
        );
    };

    loadOptions = (childrenList) =>
        childrenList.reduce((categoryOptions, option) => {
            if (option.childrenList && option.childrenList.length) {
                const subOptions = this.loadOptions(option.childrenList);

                if (!subOptions.length) {
                    return categoryOptions;
                }

                return [
                    ...categoryOptions,
                    <li className="list-item" key={option.idItem}>
                        <div className="list-item-inner">
                            <span className="navigational-list-subtitle">{option.label}</span>
                        </div>
                        <ul className="list">{subOptions}</ul>
                    </li>,
                ];
            }

            const permissionsFromProps = this.getPermissions();

            const permissions = permissionsFromProps[option.idItem] || [];

            if (permissions.length) {
                const [permission] = option.permissionList || [];

                return [
                    ...categoryOptions,
                    permission && permission.simpleAllowProductSelection ? (
                        <li className="list-item" key={option.idItem}>
                            <div className="list-item-inner">
                                <span className="navigational-list-subtitle">{option.label}</span>
                            </div>
                            {this.renderProducts(option)}
                        </li>
                    ) : (
                        this.renderItem(option)
                    ),
                ];
            }

            return categoryOptions;
        }, []);

    render() {
        const {
            children,
            groups,
            borderItem,
            borderContainer,
            classNameContainer,
            accordionBorderRadius,
            childrenListULClassName,
            contentClassName,
            accordionClassName,
            buttonLiClassName,
            idUser,
            onlyRead,
        } = this.props;

        const statePermissions = this.getPermissions();
        return (
            <PermissionsAmount permissions={statePermissions}>
                {(amountsById) => {
                    if (!Object.keys(amountsById).length) {
                        return children();
                    }

                    return children(
                        <Box
                            display="flex"
                            {...(borderContainer && { border: borderContainer })}
                            {...(classNameContainer && { className: classNameContainer })}>
                            <Accordion
                                className={classNames("list list--permissions mx-0 full-width", accordionClassName)}
                                accordionBorderRadius={accordionBorderRadius}>
                                {groups.reduce((mainCategories, option) => {
                                    const { childrenList, idItem, label, permissionList = [] } = option;
                                    const categoryOptions = this.loadOptions(childrenList);
                                    const optionsAmount = amountsById[idItem];
                                    const [permission] = permissionList;

                                    if (
                                        (permission && permission.simpleAllowProductSelection) ||
                                        categoryOptions.length
                                    ) {
                                        if (permission && permission.simpleAllowProductSelection && !optionsAmount) {
                                            return mainCategories;
                                        }

                                        return [
                                            ...mainCategories,
                                            <Accordion.Item
                                                {...(idItem === "transfers" && {
                                                    href: onlyRead
                                                        ? `/administration/medium/details/${idUser ||
                                                              "transaction"}/permissions/transfers/onlyRead`
                                                        : `/administration/medium/details/${idUser}/permissions/transfers/configure`,
                                                })}
                                                {...(idItem === "payments" && {
                                                    href: onlyRead
                                                        ? `/administration/medium/details/${idUser ||
                                                              "transaction"}/permissions/payments/onlyRead`
                                                        : `/administration/medium/details/${idUser}/permissions/payments/configure`,
                                                })}
                                                buttonLiClassName={buttonLiClassName}
                                                contentClassName={contentClassName}
                                                key={idItem}
                                                number={mainCategories.length}
                                                {...(borderItem && { borderItem })}
                                                item={
                                                    <Box
                                                        display="flex"
                                                        alignY="center"
                                                        className="mr-auto pr-5"
                                                        fullWidth>
                                                        <Box className="d-grid mr-2">
                                                            <Text
                                                                size="6"
                                                                color="heading-color"
                                                                align="left"
                                                                bold
                                                                ellipsis>
                                                                {label}
                                                            </Text>
                                                        </Box>
                                                        <Text size="6" bold align="left">
                                                            {optionsAmount}
                                                        </Text>
                                                    </Box>
                                                }>
                                                {permission && permission.simpleAllowProductSelection ? (
                                                    this.renderProducts(option)
                                                ) : (
                                                    <Box
                                                        component="ul"
                                                        className={classNames("list", childrenListULClassName)}>
                                                        {categoryOptions}
                                                    </Box>
                                                )}
                                            </Accordion.Item>,
                                        ];
                                    }

                                    if (!optionsAmount) {
                                        return mainCategories;
                                    }

                                    return [...mainCategories, this.renderItem(option)];
                                }, [])}
                            </Accordion>
                        </Box>,
                    );
                }}
            </PermissionsAmount>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: permissionsSelectors.getProducts(state),
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
    transfersPermissions: permissionsSelectors.getTrasnfersEditionResult(state),
    paymentsPermissions: permissionsSelectors.getPaymentsEditionResult(state),
});

export default connect(mapStateToProps)(PermissionsList);

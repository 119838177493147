import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { push } from "react-router-redux";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";

import Notification from "pages/_components/Notification";
import Step2Content from "pages/recoveryPassword/_components/Step2Content";
import Box from "pages/_components/Box";
import HeadSimple from "pages/_components/HeadSimple";

class RecoveryPassStep2 extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
        dispatch(push("/loginStep1"));
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Box
                display="flex"
                column
                background="white"
                fullHeight
                fullWidth
                className="px-md-7 pt-7 pt-md-0 pb-7 px-5">
                <Notification scopeToShow="recoveryPassword" />

                <HeadSimple
                    isMobile={isMobile}
                    onRightClick={this.onHeaderClose}
                    title="recoveryPassword.step2.header"
                    className="mb-4 mb-md-5"
                />

                <Box
                    {...(isMobile ? { component: "main" } : { component: "section" })}
                    display="flex"
                    fullHeight
                    fullWidth
                    className="px-md-5 safe-margin-bottom">
                    <Step2Content {...this.props} />
                </Box>
            </Box>
        );
    }
}

RecoveryPassStep2.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
};

export default connect(null)(RecoveryPassStep2);

import { Field } from "formik";
import Box from "pages/_components/Box";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Selector from "pages/_components/fields/formik/Selector";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { arrayOf, bool, func, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";

const FORM_ID = "administration.advanced.signature.modify";

class SignatureConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        schemeName: string.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        signatureLevel: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasSignatureEnabled: bool,
        match: shape({
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        userInfo: shape({ hasSignature: bool.isRequired }).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        signatureLevel: "",
        signatureLevels: [],
        hasSignatureEnabled: false,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath("/", FORM_ID);
        }
    };

    renderFormContent = () => {
        const { signatureLevels, signatureLevel, idTransaction, schemeName } = this.props;

        if (schemeName === "advanced") {
            if (!idTransaction) {
                return (
                    <Field
                        component={Selector}
                        options={signatureLevels}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        value={signatureLevel || "N"}
                        renderAs="radio"
                        inLineControl
                    />
                );
            }
            if (signatureLevel) {
                return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
            }
            return <I18n id="administration.signature.ticket.change.true" />;
        }
        return null;
    };

    renderForm = () => (
        <Container className="container--layout items-center" gridClassName="form-content container--flex-middle">
            <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                {this.renderFormContent()}
            </Col>
        </Container>
    );

    formatSubmitSignatureValue = (signatureLevel) => {
        const { hasSignatureEnabled, schemeName } = this.props;
        if (schemeName === "medium") {
            return hasSignatureEnabled ? null : "A";
        }
        return signatureLevel === "N" ? null : signatureLevel;
    };

    handleSubmit = (data, formikBag) => {
        const { actions, formActions, match, idTransaction, idActivity } = this.props;
        const { signatureLevel, ...credentials } = data;

        if (!idTransaction) {
            actions.updateSignatureRequest(
                {
                    credentials,
                    idUser: match.params.id,
                    signatureLevel: this.formatSubmitSignatureValue(signatureLevel),
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const {
            fetching,
            signatureLevel,
            user,
            credentialGroups,
            userInfo: { hasSignature },
            // schemeName,
            // hasSignatureEnabled
        } = this.props;
        return (
            <>
                <Head title={`administration.signature.configure.text.${hasSignature}`} onBack={this.handleBack} />

                <MainContainer showLoader={fetching && !Object.keys(user).length}>
                    <Row>
                        <Col xs={8} xsOffset={2} className="pb-80">
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="px-80 pt-8 pb-9"
                                fullHeight>
                                <Box display="flex" className="pb-5 mb-12" border="bottom-border-base-color-1">
                                    {/* TODO (LABEL) */}
                                    <Text
                                        component="h2"
                                        labelKey={`administration.signature.forms.confirm.credentials.${hasSignature}`}
                                        className="m-0"
                                        size="5"
                                        color="heading-color"
                                        bold
                                    />
                                </Box>
                                {/* NO BORRAR (IMPLEMENTACION PRODUCTO) */}
                                {/* {schemeName === "medium" && (
                                    <div className="transfer-data">
                                        <Container.ColumnBody columnClass="list-wrapper">
                                            <I18n id={`administration.signature.change.${hasSignatureEnabled}`} />
                                        </Container.ColumnBody>
                                    </div>
                                )} */}

                                <Box display="flex" alignX="center" className="mb-12 py-5" fullWidth>
                                    <Text labelKey="administration.channels.user" color="primary" bold addColon />
                                    <Text color="primary">{user.fullName}</Text>
                                </Box>

                                <AdministrationFormConfirmation
                                    otherFieldFunction={this.renderForm}
                                    otherInitialValues={{ signatureLevel: signatureLevel || "N" }}
                                    credentialGroups={credentialGroups}
                                    onSubmit={this.handleSubmit}
                                    cancelUrl={`/administration/medium/details/${user.idUser}`}
                                />
                            </Box>
                        </Col>
                    </Row>
                </MainContainer>
            </>
        );
    }
}

export default withRevelockPositionContext(SignatureConfirm);

import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    SET_INITIAL_STATE: "restriction/SET_INITIAL_STATE",
    GET_RESTRICTIONS_REQ: "restriction/GET_RESTRICTIONS_REQ",
    GET_RESTRICTIONS_RES: "restriction/GET_RESTRICTIONS_RES",
    GET_TIME_ZONES_REQ: "restriction/GET_TIME_ZONES_REQ",
    GET_TIME_ZONES_RES: "restriction/GET_TIME_ZONES_RES",
    GET_USER_RESTRICTION_DATA: "restriction/GET_USER_RESTRICTION_DATA",
    GET_ENV_RESTRICTION_DATA: "restriction/GET_ENV_RESTRICTION_DATA",
    SET_RESTRICTION_DATA: "restriction/SET_RESTRICTION_DATA",
    SET_MANAGE_RESTRICTIONS_REQ: "restriction/SET_MANAGE_RESTRICTIONS_REQ",
    SET_MANAGE_RESTRICTIONS_RES: "restriction/SET_MANAGE_RESTRICTIONS_RES",
    RESTRICTION_DELETE_PRE_REQUEST: "restriction/RESTRICTION_DELETE_PRE_REQUEST",
    SET_CREDENTIALS_GROUP: "restriction/SET_CREDENTIALS_GROUP",
    RESTRICTION_DELETE_REQUEST: "restriction/RESTRICTION_DELETE_REQUEST",

    MANAGE_RESTRICTIONS_CONFIRM_PRE: "restriction/MANAGE_RESTRICTIONS_CONFIRM_PRE",
    MANAGE_RESTRICTIONS_CONFIRM_PRE_FALIURE: "restriction/MANAGE_RESTRICTIONS_CONFIRM_PRE_FALIURE",
    SET_FETCHING_FALSE: "restriction/SET_FETCHING_FALSE",
    MANAGE_RESTRICTIONS_SET_DATA_TO_SAVE: "restriction/MANAGE_RESTRICTIONS_SET_DATA_TO_SAVE",
    GET_RESTRICTIONS_AVAILAVILITY_REQ: "restriction/GET_RESTRICTIONS_AVAILAVILITY_REQ",
    GET_RESTRICTIONS_AVAILAVILITY_RES: "restriction/GET_RESTRICTIONS_AVAILAVILITY_RES",
};

export const INITIAL_STATE = {
    fetching: false,
    isFirstFetching: true,
    restrictions: [],
    timeZones: [],
    days: 0,
    startTime: "00:00",
    endTime: "00:00",
    timeZone: null,
    perpetual: false,
    ipsList: [],
    calendarRestrictionId: null,
    anyIP: false,
    calendarEnabled: false,
    iPEnabled: false,
    credentialGroups: [],
    dataToSave: {},
    idTransaction: null,
    idActivity: null,
    userName: "",
    mode: "pre",
};

export default createReducer(INITIAL_STATE, {
    [types.SET_INITIAL_STATE]: (state) => ({
        ...INITIAL_STATE,
        timeZones: state.timeZones,
    }),
    [types.GET_RESTRICTIONS_REQ]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_RESTRICTIONS_RES]: (state, { restrictions, userName }) => ({
        ...state,
        fetching: false,
        restrictions,
        userName,
    }),
    [types.GET_RESTRICTIONS_AVAILAVILITY_REQ]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_RESTRICTIONS_AVAILAVILITY_RES]: (state, { calendarEnabled, iPEnabled }) => ({
        ...state,
        fetching: false,
        calendarEnabled,
        iPEnabled,
    }),
    [types.GET_TIME_ZONES_REQ]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_TIME_ZONES_RES]: (state, { timeZones }) => ({
        ...state,
        fetching: false,
        timeZones,
    }),
    [types.GET_USER_RESTRICTION_DATA]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.GET_ENV_RESTRICTION_DATA]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SET_RESTRICTION_DATA]: (state, { data, userName }) => ({
        ...state,
        fetching: false,
        isFirstFetching: false,
        calendarRestrictionId: data.idRestriction || state.calendarRestrictionId,
        days: data.days || state.days,
        startTime: data.startTime || state.startTime,
        endTime: data.endTime || state.endTime,
        timeZone: data.timeZone || state.timeZone,
        ipsList: data.ipsList || state.ipsList,
        perpetual: data.perpetual || state.perpetual,
        anyIP: data.anyIP || state.anyIP,
        userName,
    }),
    [types.SET_MANAGE_RESTRICTIONS_REQ]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SET_MANAGE_RESTRICTIONS_RES]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.RESTRICTION_DELETE_PRE_REQUEST]: (state, { idTransaction, idActivity }) => ({
        ...state,
        fetching: true,
        idTransaction,
        idActivity,
    }),
    [types.SET_CREDENTIALS_GROUP]: (state, { credentialGroups }) => ({
        ...state,
        credentialGroups,
    }),
    [types.MANAGE_RESTRICTIONS_SET_DATA_TO_SAVE]: (state, { dataToSave, mode }) => ({
        ...state,
        dataToSave,
        mode,
    }),
    [types.MANAGE_RESTRICTIONS_CONFIRM_PRE]: (state, { idTransaction, idActivity }) => ({
        ...state,
        fetching: true,
        isFirstFetching: false,
        idTransaction,
        idActivity,
    }),
    [types.RESTRICTION_DELETE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.MANAGE_RESTRICTIONS_CONFIRM_PRE_FALIURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SET_FETCHING_FALSE]: (state) => ({
        ...state,
        fetching: false,
    }),
});

export const actions = {
    setFetchingFalse: makeActionCreator(types.SET_FETCHING_FALSE),
    setInitialState: makeActionCreator(types.SET_INITIAL_STATE),
    getRestrictions: makeActionCreator(types.GET_RESTRICTIONS_REQ, "idUser"),
    getRestrictionsAvailability: makeActionCreator(types.GET_RESTRICTIONS_AVAILAVILITY_REQ),
    getUserRestrictionsData: makeActionCreator(types.GET_USER_RESTRICTION_DATA, "idUser"),
    getEnvironmentRestrictionsData: makeActionCreator(types.GET_ENV_RESTRICTION_DATA),
    manageRestrictionsPre: makeActionCreator(types.GET_TIME_ZONES_REQ),
    manageRestrictionsConfirmPre: makeActionCreator(
        types.MANAGE_RESTRICTIONS_CONFIRM_PRE,
        "dataToSave",
        "formikBag",
        "idTransaction",
        "idActivity",
    ),
    manageRestrictions: makeActionCreator(types.SET_MANAGE_RESTRICTIONS_REQ, "dataToSave", "credentials", "formikBag"),
    restrictionDeleteRequest: makeActionCreator(types.RESTRICTION_DELETE_PRE_REQUEST, "idTransaction", "idActivity"),
    deleteUserAccessRestrictions: makeActionCreator(
        types.RESTRICTION_DELETE_REQUEST,
        "idUser",
        "credentialGroups",
        "formikBag",
    ),
    manageRestrictionsDataToSave: makeActionCreator(types.MANAGE_RESTRICTIONS_SET_DATA_TO_SAVE, "dataToSave", "mode"),
};

export const selectors = {
    getRestrictions: ({ restrictions }) => restrictions.restrictions,
    getTimezones: ({ restrictions }) => restrictions.timeZones,
    getDays: ({ restrictions }) => restrictions.days,
    getStartTime: ({ restrictions }) => restrictions.startTime,
    getEndTime: ({ restrictions }) => restrictions.endTime,
    getTimeZone: ({ restrictions }) => restrictions.timeZone,
    getPerpetual: ({ restrictions }) => restrictions.perpetual,
    getIpsList: ({ restrictions }) => restrictions.ipsList,
    getCalendarRestrictionId: ({ restrictions }) => restrictions.calendarRestrictionId,
    getFetching: ({ restrictions }) => restrictions.fetching,
    getFirstFetch: ({ restrictions }) => restrictions.isFirstFetching,
    getAnyIP: ({ restrictions }) => restrictions.anyIP,
    getCalendarEnabled: ({ restrictions }) => restrictions.calendarEnabled,
    getIPEnabled: ({ restrictions }) => restrictions.iPEnabled,
    getCredentialGroups: ({ restrictions }) => restrictions.credentialGroups,
    getDataToSave: ({ restrictions }) => restrictions.dataToSave,
    getIdTransaction: ({ restrictions }) => restrictions.idTransaction,
    getIdActivity: ({ restrictions }) => restrictions.idActivity,
    getUserName: ({ restrictions }) => restrictions.userName,
    getMode: ({ restrictions }) => restrictions.mode,
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import withExchangeToken from "pages/_components/withExchangeToken";
import { push } from "react-router-redux";

import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Step3Content from "pages/recoveryPassword/_components/Step3Content";
import Box from "pages/_components/Box";
import HeadSimple from "pages/_components/HeadSimple";

class RecoveryPassStep3 extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
        dispatch(push("/loginStep1"));
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Box display="flex" column background="white" fullHeight className="px-md-7 pt-7 pt-md-0 pb-7 px-5">
                {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="recoveryPassword" />}

                <HeadSimple
                    isMobile={isMobile}
                    onRightClick={this.onHeaderClose}
                    title="recoveryPassword.step3.header"
                    className="mb-4 mb-md-5"
                />

                <Box
                    {...(isMobile ? { component: "main" } : { component: "section" })}
                    display="flex"
                    fullHeight
                    fullWidth
                    className="px-md-5 safe-margin-bottom">
                    <Step3Content {...this.props} />
                </Box>
            </Box>
        );
    }
}

RecoveryPassStep3.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep3));

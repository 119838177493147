import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/preApprovedProduct";
import * as preApprovedProduct from "middleware/preApprovedProduct";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST, getPreApprovedProductsList),
    takeLatest(types.PREAPPROVED_PRODUCT_CHANGE_STATUS, preApprovedProductChangeStatus),
    takeLatest(types.PREAPPROVED_PRODUCT_PRE_FORM_REQUEST, getPreApprovedProductFormList),
    takeLatest(types.PREAPPROVED_PRODUCT_SEND_REQUEST, preApprovedProductSendRequest),
    takeLatest(types.PREAPPROVED_PRODUCT_DECLINED, preApprovedProductChangeStatus),
];

export default sagas;

function* getPreApprovedProductsList() {
    const response = yield call(preApprovedProduct.getPreApprovedProductsList);

    if (response.type === "W") {
        yield put(actions.getPreApprovedProductsListFailed());
    } else {
        yield put(actions.getPreApprovedProductsListSuccess(response.data.data.preApprovedProductsList));
    }
}

function* preApprovedProductChangeStatus({ productType, productStatusCode, isDeclined = false }) {
    const response = yield call(preApprovedProduct.preApprovedProductChangeStatus, productType, productStatusCode);

    if (isDeclined) {
        if (response.type === "W") {
            yield put({ type: types.PREAPPROVED_PRODUCT_DECLINED_FAILED });
            yield put(
                notificationActions.showNotification(i18n.get("preApprovedProduct.drawer.error"), "error", ["desktop"]),
            );
        } else {
            yield put({ type: types.PREAPPROVED_PRODUCT_DECLINED_SUCCESS });
            yield put(
                notificationActions.showNotification(
                    i18n.get("preApprovedProduct.drawer.declined.success"),
                    "success",
                    ["desktop"],
                ),
            );
        }
    }

    yield put({
        type: types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST,
    });
}

function* getPreApprovedProductFormList() {
    const response = yield call(preApprovedProduct.getPreApprovedProductsFormList);
    if (response.type === "W") {
        yield put(actions.preApprovedProductPreFormFailed());
    } else {
        yield put(
            actions.preApprovedProductPreFormSuccess(
                response.data.data.salaryRangeList,
                response.data.data.creditCardIssuerList,
            ),
        );
    }
}

function* preApprovedProductSendRequest({ productType, salary, workPlace, cardType, productStatusCode }) {
    const response = yield call(
        preApprovedProduct.preApprovedProductsSendRequest,
        productType,
        salary,
        workPlace,
        cardType,
        productStatusCode,
    );

    if (response.type === "W") {
        yield put({
            type: types.PREAPPROVED_PRODUCT_SEND_REQUEST_FAILED,
        });
        yield put(
            notificationActions.showNotification(i18n.get("preApprovedProduct.drawer.error"), "error", ["desktop"]),
        );
    } else {
        yield put({
            type: types.PREAPPROVED_PRODUCT_SEND_REQUEST_SUCCESS,
        });
        yield put(
            notificationActions.showNotification(i18n.get("preApprovedProduct.drawer.success"), "success", ["desktop"]),
        );
        yield put({
            type: types.GET_PREAPPROVED_PRODUCTS_LIST_REQUEST,
        });
    }
}

import Image from "pages/_components/Image";
import { string } from "prop-types";
import React, { Component } from "react";

class ChevromRight extends Component {
    static propTypes = {
        color: string,
    };

    static defaultProps = {
        color: "text-disabled-color",
    };

    render() {
        const { color } = this.props;
        return (
            <div className="table-data table-data-icon">
                <Image src="images/chevromRight.svg" className="svg-icon svg-caret" ariaHiden color={color} />
            </div>
        );
    }
}

export default ChevromRight;

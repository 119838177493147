import React, { Component } from "react";
import { oneOfType, node, string, bool, func } from "prop-types";
import classNames from "classnames";

import Image from "pages/_components/Image";

class Radio extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: oneOfType([string, node]).isRequired,
        value: string.isRequired,
        className: string,
        mode: string,
        inLineControl: bool,
        name: string.isRequired,
        onChange: func.isRequired,
        id: string.isRequired,
        radioLabelClassName: string,
        disabled: bool,
        ariaLabelledby: string,
    };

    static defaultProps = {
        inLineControl: false,
        className: "",
        mode: "edit",
        radioLabelClassName: null,
        disabled: false,
        ariaLabelledby: null,
    };

    render() {
        const {
            id,
            inLineControl,
            value,
            name,
            checked,
            onChange,
            className,
            label,
            mode,
            radioLabelClassName,
            disabled,
            ariaLabelledby,
        } = this.props;

        if (mode !== "edit" && !checked) {
            return null;
        }

        if (mode !== "edit") {
            return <div>{label}</div>;
        }

        return (
            <div
                className={classNames(
                    "c-control c-control--has-icon c-control--radio display-flex gap-3 align-items-center position-relative",
                    className,
                    {
                        "c-control-block": !inLineControl,
                    },
                )}>
                <input
                    {...(ariaLabelledby && { "aria-labelledby": classNames(ariaLabelledby, id) })}
                    className="c-control-input"
                    checked={checked}
                    id={id}
                    type="radio"
                    value={value}
                    onChange={onChange}
                    name={name}
                    disabled={disabled}
                />
                <label htmlFor={id} className={classNames("c-control-label m-0", radioLabelClassName)}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/radioButtonMark.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {label}
                </label>
            </div>
        );
    }
}

export default Radio;

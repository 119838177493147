import React, { Component } from "react";
import { string, node } from "prop-types";
import Image from "pages/_components/Image";

import I18n from "pages/_components/I18n";

class NoProduct extends Component {
    static propTypes = {
        text: string.isRequired,
        imagePath: string,
        button: node,
    };

    static defaultProps = {
        imagePath: null,
        button: null,
    };

    render() {
        const { text, imagePath, button } = this.props;

        return (
            <div className="newProduct">
                <div className="newProduct-row">
                    <div
                        className="newProduct-row"
                        style={{
                            alignItems: "stretch",
                        }}>
                        <div className="newProduct-cell newProduct-cell--ellipsis">
                            <span
                                className="data-name"
                                style={{
                                    marginBottom: "1rem",
                                }}>
                                <I18n id={text} />
                            </span>
                            {button && <div>{button}</div>}
                        </div>
                        {imagePath && (
                            <div className="newProduct-cell newProduct-cell--icon">
                                <div className="illustration-wrapper">
                                    <Image src={imagePath} className="svg-big-icon" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default NoProduct;

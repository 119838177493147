import React, { Component } from "react";
import { arrayOf, string, shape } from "prop-types";
import { connect } from "react-redux";

import { groupSelectors } from "reducers/administration";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class MembersList extends Component {
    static propTypes = {
        members: arrayOf(shape({ fullName: string, idUser: string })),
        adminUsers: arrayOf(string),
    };

    static defaultProps = {
        members: [],
        adminUsers: [],
    };

    render() {
        const { members, adminUsers } = this.props;

        if (!members.length) {
            return (
                <ul className="list">
                    <li className="list-item">
                        <div className="list-item-inner">
                            <div className="data-wrapper data-wrapper-flex">
                                <I18n id="administration.members.empty" />
                            </div>
                        </div>
                    </li>
                </ul>
            );
        }

        return (
            <ul className="list">
                {members.map(({ fullName, idUser }) => (
                    <li key={idUser} className="list-item">
                        <div className="list-item-inner">
                            <div className="data-wrapper data-wrapper-flex">
                                <span className="data-desc">{fullName}</span>
                                {adminUsers.includes(idUser) && (
                                    <Image src="images/administrator.svg" className="svg-icon svg-caret" />
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    members: groupSelectors.getMembers(state),
    adminUsers: groupSelectors.getAdminUsers(state),
});

export default connect(mapStateToProps)(MembersList);

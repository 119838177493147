import { createReducer, makeActionCreator } from "util/redux";
import * as i18nUtils from "util/i18n";

const INITIAL_STATE = {
    caps: {},
    currencies: [],
    topAmount: {},
    nonRemovableChannels: [],
    enabledChannels: [],
    enabledChannelsFrequencies: [],
    fetching: true,
    dataToSubmit: {},
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    userEnvStatus: "",
};

export const selectors = {
    getCaps: ({ channels }) => channels.caps,
    getCurrencies: ({ channels }) => channels.currencies,
    getTopAmount: ({ channels }) => channels.topAmount,
    getNonRemovableChannels: ({ channels }) => channels.nonRemovableChannels,
    getEnabledChannels: ({ channels }) => channels.enabledChannels,
    getEnabledChannelsFrequencies: ({ channels }) => channels.enabledChannelsFrequencies,
    isFetching: ({ channels }) => (channels ? channels.fetching : false),
    getIdTransaction: ({ channels }) => channels.idTransaction,
    getIdActivity: ({ channels }) => channels.idActivity,
    getCredentialGroups: ({ channels }) => channels.credentialGroups,
    getUserEnvStatus: ({ channels }) => channels.userEnvStatus,
};

export default (name) => {
    const types = {
        LOAD_CHANNELS_REQUEST: `${name}/LOAD_CHANNELS_REQUEST`,
        LOAD_CHANNELS_FAILURE: `${name}/LOAD_CHANNELS_FAILURE`,
        LOAD_CHANNELS_SUCCESS: `${name}/LOAD_CHANNELS_SUCCESS`,
        UPDATE_CHANNELS_PREVIEW: `${name}/UPDATE_CHANNELS_PREVIEW`,
        UPDATE_CHANNELS_PREVIEW_SUCCESS: `${name}/UPDATE_CHANNELS_PREVIEW_SUCCESS`,
        UPDATE_CHANNELS_REQUEST: `${name}/UPDATE_CHANNELS_REQUEST`,
        UPDATE_CHANNELS_REQUEST_SUCCESS: `${name}/UPDATE_CHANNELS_REQUEST_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_CHANNELS_REQUEST]: (state) => ({ ...state, fetching: true }),
            [types.LOAD_CHANNELS_FAILURE]: (state) => ({ ...state, fetching: false }),
            [types.LOAD_CHANNELS_SUCCESS]: (state, { data }) => ({
                ...state,
                caps: data.caps.reduce(
                    (caps, { channel, maximum, frequency }) => ({
                        ...caps,
                        [channel]: {
                            amount: maximum,
                            currency: data.currency,
                            frequency,
                        },
                    }),
                    {},
                ),
                // for now we only have one currency
                currencies: [
                    {
                        id: data.currency,
                        value: data.currency,
                        label: i18nUtils.get(`currency.label.${data.currency}`),
                    },
                ],
                topAmount: data.topAmount,
                nonRemovableChannels: data.nonRemovableChannels || [],
                enabledChannels: data.enabledChannels ? data.enabledChannels : [],
                enabledChannelsFrequencies:
                    data.enabledChannelsFrequencies &&
                    data.enabledChannelsFrequencies.map((channel) => ({
                        value: channel,
                        label: i18nUtils.get(`administration.channels.${channel}`),
                    })),
                fetching: false,
                userEnvStatus: data.userEnvStatus,
            }),
            [types.UPDATE_CHANNELS_PREVIEW_SUCCESS]: (
                state,
                { caps: { topAmount, ...caps }, credentialGroups, idTransaction, idActivity },
            ) => ({
                ...state,
                caps: Object.entries(caps).reduce(
                    (capsWithCurrencies, [cap, values]) => ({
                        ...capsWithCurrencies,
                        [cap]: { ...values, currency: state.currencies[0].value },
                    }),
                    {},
                ),
                credentialGroups,
                idTransaction,
                idActivity,
            }),
            [types.UPDATE_CHANNELS_REQUEST_SUCCESS]: (state) => ({ ...state, fetching: true }),
        }),
        actions: {
            loadChannelsRequest: makeActionCreator(types.LOAD_CHANNELS_REQUEST, "id"),
            loadChannelsFailure: makeActionCreator(types.LOAD_CHANNELS_FAILURE),
            loadChannelsSuccess: makeActionCreator(types.LOAD_CHANNELS_SUCCESS, "data"),
            updateChannelsPreview: makeActionCreator(types.UPDATE_CHANNELS_PREVIEW, "data", "setSubmitting"),
            updateChannelsPreviewSuccess: makeActionCreator(
                types.UPDATE_CHANNELS_PREVIEW_SUCCESS,
                "caps",
                "credentialGroups",
                "idTransaction",
                "idActivity",
            ),
            updateChannelsRequest: makeActionCreator(types.UPDATE_CHANNELS_REQUEST, "data", "formikBag"),
            updateChannelsRequestSuccess: makeActionCreator(types.UPDATE_CHANNELS_REQUEST_SUCCESS),
        },
    };
};

import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import PageLoading from "pages/_components/PageLoading";

import Text from "pages/_components/Text";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";

const CreditLinesDetailsTable = ({ dispatch, isDesktop, movementList, tableType, parentId, fetching }) => {
    const columnsTemplate = () => {
        switch (tableType) {
            case "LOANS_LIMITS_NON_REVOLVING":
            case "LOANS_LIMITS_REVOLVING":
                return isDesktop ? "1fr 1fr 1fr 1fr 1fr" : "1.3fr 1fr 1.5rem";
            case "CREDIT_LETTER":
                return isDesktop ? "1fr 1fr 1fr 1fr 1fr" : "1.3fr 1fr";
            case "CONTRACTED_OVERTURNING":
                return isDesktop ? "1fr 1fr 1fr 1fr 1fr 1fr" : "1.3fr 1fr";
            case "CONTROL_LINE_GLOBAL":
            case "CONTROL_LINE_MASTER":
                return isDesktop ? "1fr 1fr 1fr 1fr 1fr 1fr" : "1.3fr 1fr 1.5rem";
            default:
                return null;
        }
    };
    const renderHeaderList = () => (
        <>
            {(tableType === "LOANS_LIMITS_REVOLVING" || tableType === "LOANS_LIMITS_NON_REVOLVING") && (
                <GridTable.Header>
                    {isDesktop ? (
                        <GridTable.Container className="gapX-3" columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.reference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.description" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={3} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.amount" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={4} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.monthlyPayment" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={5} alignX="center" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.action" />
                            </GridTable.Data>
                        </GridTable.Container>
                    ) : (
                        <GridTable.Container
                            padding="pl-5 pr-3 py-2"
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text align="left" labelKey="creditLine.detail.table.header.descriptionReference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.amountLetter" />
                            </GridTable.Data>
                        </GridTable.Container>
                    )}
                </GridTable.Header>
            )}
            {tableType === "CREDIT_LETTER" && (
                <GridTable.Header>
                    {isDesktop ? (
                        <GridTable.Container
                            className={classNames({ "gapX-3": isDesktop })}
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.reference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.description" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={3} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.amount" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={4} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.issueDate" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={5} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.expirationDate" />
                            </GridTable.Data>
                        </GridTable.Container>
                    ) : (
                        <GridTable.Container
                            padding="px-5 py-2"
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text align="left" labelKey="creditLine.detail.table.header.descriptionReference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.amount" />
                            </GridTable.Data>
                        </GridTable.Container>
                    )}
                </GridTable.Header>
            )}
            {tableType === "CONTRACTED_OVERTURNING" && (
                <GridTable.Header>
                    {isDesktop ? (
                        <GridTable.Container
                            className={classNames({ "gapX-3": isDesktop })}
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.reference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.description" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={3} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.amount" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={4} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.issueDate" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={5} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.expirationDate" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={6} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.accountNumber" />
                            </GridTable.Data>
                        </GridTable.Container>
                    ) : (
                        <GridTable.Container
                            padding="px-5 py-2"
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text align="left" labelKey="creditLine.detail.table.header.descriptionReference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.amount" />
                            </GridTable.Data>
                        </GridTable.Container>
                    )}
                </GridTable.Header>
            )}
            {(tableType === "CONTROL_LINE_GLOBAL" || tableType === "CONTROL_LINE_MASTER") && (
                <GridTable.Header>
                    {isDesktop ? (
                        <GridTable.Container
                            className={classNames({ "gapX-3": isDesktop })}
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.reference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-start" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.description" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={3} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.totalBalance" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={4} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.availableBalance" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={5} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.expirationDate" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={6} alignX="flex-end" alignY="center" inHeader />
                        </GridTable.Container>
                    ) : (
                        <GridTable.Container
                            padding="pl-5 pr-3 py-2"
                            columnsTemplate={columnsTemplate(tableType, isDesktop)}>
                            <GridTable.Data columnStart={1} alignX="flex-start" alignY="center" inHeader>
                                <Text align="left" labelKey="creditLine.detail.table.header.descriptionReference" />
                            </GridTable.Data>
                            <GridTable.Data columnStart={2} alignX="flex-end" alignY="center" inHeader>
                                <Text labelKey="creditLine.detail.table.header.totalAvailable" />
                            </GridTable.Data>
                        </GridTable.Container>
                    )}
                </GridTable.Header>
            )}
        </>
    );
    const renderBodyList = () => {
        const movements = [];
        if (!movementList || fetching) {
            return movements;
        }
        if (tableType === "LOANS_LIMITS_REVOLVING" || tableType === "LOANS_LIMITS_NON_REVOLVING") {
            // Asegurarse de que movementBlockedList esté definido en algún lugar accesible
            movementList.forEach((movement) =>
                movements.push(
                    <GridTable.Container
                        {...(!isDesktop && {
                            padding: "pl-5 pr-3 py-3",
                            onClick: () => {
                                dispatch(push(`/loans/${movement?.productId}`));
                            },
                        })}
                        columnsTemplate={columnsTemplate(tableType, isDesktop)}
                        key={movement.acctReference}>
                        {isDesktop ? (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Text>{movement.referenceNumber}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-start" alignY="center">
                                    <Text>{movement.description}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-end" alignY="center">
                                    <FormattedAmount
                                        currency={movement.amount.currency}
                                        quantity={movement.amount.quantity}
                                    />
                                </GridTable.Data>
                                <GridTable.Data columnStart={4} alignX="flex-end" alignY="center">
                                    <FormattedAmount
                                        currency={movement.monthlyPayment.currency}
                                        quantity={movement.monthlyPayment.quantity}
                                    />
                                </GridTable.Data>
                                <GridTable.Data columnStart={5} alignX="center" alignY="center">
                                    <Button
                                        bsStyle="link"
                                        onClick={() => {
                                            dispatch(push(`/loans/${movement?.productId}`));
                                        }}>
                                        {movement.acctReference}
                                    </Button>
                                </GridTable.Data>
                            </>
                        ) : (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Box display="flex" column alignY="flex-start">
                                        <Text align="left" bold>
                                            {movement.description}
                                        </Text>
                                        <Text align="left">{movement.reference}</Text>
                                        <Box display="flex" alignX="flex-end" alignY="center">
                                            <Button bsStyle="link">
                                                <Text size={5}>{movement?.acctReference}</Text>
                                            </Button>
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-end" alignY="center">
                                    <Box display="flex" column alignY="flex-end">
                                        <FormattedAmount
                                            currency={movement.amount.currency}
                                            quantity={movement.amount.quantity}
                                            bold
                                        />
                                        <FormattedAmount
                                            currency={movement.monthlyPayment.currency}
                                            quantity={movement.monthlyPayment.quantity}
                                            bold
                                        />
                                    </Box>
                                </GridTable.Data>
                            </>
                        )}
                    </GridTable.Container>,
                ),
            );
        }

        if (tableType === "CREDIT_LETTER") {
            // Asegurarse de que movementBlockedList esté definido en algún lugar accesible
            movementList.forEach((movement) =>
                movements.push(
                    <GridTable.Container
                        {...(!isDesktop && { padding: "px-5 py-3" })}
                        columnsTemplate={columnsTemplate(tableType, isDesktop)}
                        key={movement.referenceNumber}>
                        {isDesktop ? (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Text>{movement.reference}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-start" alignY="center">
                                    <Text>{movement.description}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-end" alignY="center">
                                    <FormattedAmount
                                        currency={movement.principalAmount.currency}
                                        quantity={movement.principalAmount.quantity}
                                    />
                                </GridTable.Data>
                                <GridTable.Data columnStart={4} alignX="flex-end" alignY="center">
                                    <FormattedDate date={movement.openDate} anotherFormat="DD/MM/YYYY" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={5} alignX="flex-end" alignY="center">
                                    <FormattedDate date={movement.expirationDate} anotherFormat="DD/MM/YYYY" />
                                </GridTable.Data>
                            </>
                        ) : (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Box display="flex" column alignY="flex-start">
                                        <Text align="left" bold>
                                            {movement.description}
                                        </Text>
                                        <Text align="left">{movement.reference}</Text>
                                        <Box alignX="left" alignY="center">
                                            <Text align="left" labelKey="creditLine.issueDate" addColon />
                                            <FormattedDate date={movement.openDate} anotherFormat="DD/MM/YYYY" />
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-end" alignY="center">
                                    <Box display="flex" column alignY="flex-end">
                                        <FormattedAmount
                                            currency={movement.principalAmount.currency}
                                            quantity={movement.principalAmount.quantity}
                                            bold
                                        />
                                        <Box alignX="flex-end" alignY="center">
                                            <Text align="left" labelKey="creditLine.expirationDate" addColon />
                                            <FormattedDate date={movement.expirationDate} anotherFormat="DD/MM/YYYY" />
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                            </>
                        )}
                    </GridTable.Container>,
                ),
            );
        }
        if (tableType === "CONTRACTED_OVERTURNING") {
            movementList.forEach((movement) =>
                movements.push(
                    <GridTable.Container
                        {...(!isDesktop && { padding: "px-5 py-3" })}
                        columnsTemplate={columnsTemplate(tableType, isDesktop)}
                        key={movement.referenceNumber}>
                        {isDesktop ? (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Text>{movement.reference}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-start" alignY="center">
                                    <Text>{movement.description}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-end" alignY="center">
                                    <FormattedAmount
                                        currency={movement.amount.currency}
                                        quantity={movement.amount.quantity}
                                    />
                                </GridTable.Data>
                                <GridTable.Data columnStart={4} alignX="flex-end" alignY="center">
                                    <FormattedDate date={movement?.effDate} anotherFormat="DD/MM/YYYY" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={5} alignX="flex-end" alignY="center">
                                    <FormattedDate date={movement?.expDate} anotherFormat="DD/MM/YYYY" />
                                </GridTable.Data>
                                <GridTable.Data columnStart={6} alignX="flex-end" alignY="center">
                                    <Button
                                        bsStyle="link"
                                        onClick={() => {
                                            dispatch(push(`/accounts/${movement?.productId}`));
                                        }}>
                                        {movement.acctReference}
                                    </Button>
                                </GridTable.Data>
                            </>
                        ) : (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Box display="flex" column alignY="flex-start">
                                        <Text align="left" bold>
                                            {movement?.description}
                                        </Text>
                                        {/* TODO */}
                                        <Text align="left">{movement?.reference}</Text>
                                        {/* <Text align="left">001</Text> */}
                                        <Box display="flex">
                                            <Text align="left" labelKey="creditLine.issueDate" addColon />
                                            <FormattedDate date={movement?.effDate} anotherFormat="DD/MM/YYYY" />
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-end" alignY="center">
                                    <Box display="flex" column alignY="flex-end">
                                        <FormattedAmount
                                            currency={movement?.amount?.currency}
                                            quantity={movement?.amount?.quantity}
                                            bold
                                        />
                                        <Box display="flex">
                                            <Text align="flex-end" labelKey="creditLine.expirationDate" addColon />
                                            <FormattedDate date={movement?.expDate} anotherFormat="DD/MM/YYYY" />
                                        </Box>
                                        <Box display="flex" alignX="flex-end" alignY="center">
                                            <Button
                                                bsStyle="link"
                                                onClick={() => {
                                                    dispatch(push(`/accounts/${movement?.productId}`));
                                                }}>
                                                <Text size={5}>{movement?.acctReference}</Text>
                                            </Button>
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                            </>
                        )}
                    </GridTable.Container>,
                ),
            );
        }
        if (tableType === "CONTROL_LINE_GLOBAL" || tableType === "CONTROL_LINE_MASTER") {
            // Asegurarse de que movementBlockedList esté definido en algún lugar accesible
            movementList.forEach((movement) =>
                movements.push(
                    <GridTable.Container
                        {...(!isDesktop && {
                            padding: "pl-5 pr-3 py-3",
                            onClick: () => {
                                dispatch(push(`/creditLines/${parentId}/${movement?.id}`));
                            },
                        })}
                        columnsTemplate={columnsTemplate(tableType, isDesktop)}
                        key={movement.id}>
                        {isDesktop ? (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Text>{movement?.id}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-start" alignY="center">
                                    <Text ellipsis>{movement?.typeDescription}</Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-end" alignY="center">
                                    <FormattedAmount
                                        currency={movement?.onlineAmount?.currency}
                                        quantity={movement?.onlineAmount?.quantity}
                                    />
                                </GridTable.Data>
                                <GridTable.Data columnStart={4} alignX="flex-end" alignY="center">
                                    <FormattedAmount
                                        currency={movement?.availableAmount?.currency}
                                        quantity={movement?.availableAmount?.quantity}
                                    />
                                </GridTable.Data>

                                <GridTable.Data columnStart={5} alignX="flex-end" alignY="center">
                                    <FormattedDate date={movement?.expirationDate} anotherFormat="DD/MM/YYYY" />
                                </GridTable.Data>

                                <GridTable.Data columnStart={6} alignX="center" alignY="center">
                                    <Button
                                        bsStyle="link"
                                        label="creditLine.detail.button"
                                        onClick={() => {
                                            dispatch(push(`/creditLines/${parentId}/${movement?.id}`));
                                        }}
                                    />
                                </GridTable.Data>
                            </>
                        ) : (
                            <>
                                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                                    <Box display="flex" column alignY="flex-start" withEllipsis>
                                        <Text align="left" bold ellipsis>
                                            {movement?.typeDescription}
                                        </Text>
                                        <Text align="left">{movement?.id}</Text>
                                        <Box display="flex">
                                            <Text align="left" labelKey="creditLine.expirationDate" addColon />
                                            <FormattedDate date={movement?.expirationDate} anotherFormat="DD/MM/YYYY" />
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-end" alignY="center">
                                    <Box display="flex" column alignY="flex-end">
                                        <FormattedAmount
                                            currency={movement?.onlineAmount?.currency}
                                            quantity={movement?.onlineAmount?.quantity}
                                            bold
                                        />
                                        <FormattedAmount
                                            currency={movement?.availableAmount?.currency}
                                            quantity={movement?.availableAmount?.quantity}
                                            bold
                                        />
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-end" alignY="center">
                                    <ChevromRight color="text-color" />
                                </GridTable.Data>
                            </>
                        )}
                    </GridTable.Container>,
                ),
            );
        }

        return movements;
    };

    const getTotalSumFromAmount = () => {
        const totalSum = movementList?.reduce(
            (acumulador, element) => acumulador + (element.amount?.quantity || element.principalAmount?.quantity),
            0,
        );
        return totalSum;
    };

    return (
        <Box
            display="flex"
            column
            background="component-background"
            borderRadius="default"
            overflow="hidden"
            position="relative">
            <Box display="flex" alignY="center" className="px-5 min-height-11">
                <Text sieze="4" bold labelKey={`creditLine.detail.table.info.${tableType?.toLowerCase()}`} />
            </Box>
            <Box>
                <Container className={classNames("container--layout movements-container", { scrollable: !fetching })}>
                    <GridTable>
                        <Box className="min-height-10rem">
                            <PageLoading loading={fetching}>
                                {renderHeaderList(tableType)}
                                <GridTable.Body>
                                    {renderBodyList(tableType)}
                                    {(movementList?.length === 0 || !movementList) && !fetching && (
                                        <Box
                                            display="flex"
                                            fullWidth
                                            alignX="center"
                                            alignY="center"
                                            className="no-more-data min-height-7-5rem"
                                            key="noMoreMovements"
                                            fullHeight>
                                            <Text
                                                labelKey="creditLine.detail.table.empty"
                                                component="p"
                                                align="center"
                                                size="5"
                                                color="text"
                                                className="px-5"
                                            />
                                        </Box>
                                    )}
                                </GridTable.Body>
                                {tableType !== "CONTRACTED_OVERTURNING" &&
                                    tableType !== "CONTROL_LINE_GLOBAL" &&
                                    tableType !== "CONTROL_LINE_MASTER" && (
                                        <Box
                                            display="flex"
                                            position="absolute"
                                            bottom="0"
                                            right="0"
                                            className="mr-5 mr-md-9 mb-4 mb-md-5">
                                            <Text
                                                component="label"
                                                className="my-0"
                                                regular
                                                addColon
                                                color="heading-color"
                                                labelKey={`creditLine.detail.table.total.${tableType?.toLowerCase()}`}
                                            />
                                            <FormattedAmount
                                                currency="USD"
                                                quantity={movementList ? getTotalSumFromAmount() : 0}
                                                size="5"
                                                color="heading-color"
                                                bold
                                            />
                                        </Box>
                                    )}
                            </PageLoading>
                        </Box>
                    </GridTable>
                </Container>
            </Box>
        </Box>
    );
};

CreditLinesDetailsTable.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    movementList: shape({}).isRequired,
    tableType: string.isRequired,
    parentId: string.isRequired,
    fetching: bool.isRequired,
};

export default connect()(CreditLinesDetailsTable);

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors } from "reducers/administration";
import { detailsActions } from "reducers/administration/advanced";
import { actions, selectors as administrationUsersSelector } from "reducers/administration/users";
import { selectors as administrationChannelsSelector } from "reducers/administration/common/channels";
import { selectors as administrationDetailsSelector } from "reducers/administration/common/details";
import { actions as restrictionActions } from "reducers/administration/restrictions";

import Details from "pages/administration/_components/Details";

const mapStateToProps = (state) => {
    const user = detailsSelectors.getUser(state);
    const userInfo = administrationUsersSelector.getUserInfo(state, user);
    const fetchingUser = detailsSelectors.isFetching(state);
    const fetchingUserInfo = administrationUsersSelector.isFetching(state);
    const fetchingChannels = administrationChannelsSelector.isFetching(state.administration.advanced);
    const fetchingDetails = administrationDetailsSelector.isFetching(state.administration.advanced.details);

    return {
        user,
        userInfo,
        fetching: fetchingUser || fetchingUserInfo || fetchingChannels || fetchingDetails,
        signatureLevel: detailsSelectors.getSignatureLevel(state),
        hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
        userEnvStatus: detailsSelectors.getUserEnvStatus(state),
        userTokenStatus: detailsSelectors.getUserTokenStatus(state),
        schemeName: "advanced",
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...actions, ...detailsActions, ...restrictionActions }, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);

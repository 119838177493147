import Box from "pages/_components/Box";
import FeatureFlag from "pages/_components/FeatureFlag";
import Text from "pages/_components/Text";
import Signatures from "pages/forms/_components/Signatures";
import { bool, shape } from "prop-types";
import React, { Component } from "react";

const rename = (transformation, obj) =>
    Object.keys(obj).reduce((acc, key) => {
        const newKey = transformation[key] ? transformation[key] : key;
        acc[newKey] = obj[key];
        return acc;
    }, {});

class FormSignatures extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
        parentTransaction: shape({}).isRequired,
        usesJointAccount: bool,
        isInTicket: bool,
    };

    static defaultProps = {
        usesJointAccount: false,
        isInTicket: false,
    };

    hasDispatcher = () => {
        const {
            transaction: { dispatcher },
        } = this.props;
        return dispatcher;
    };

    authorizerSignature = (signature) => {
        const extendedData = JSON.parse(signature.extendedData || "{}");

        const hasjointCreationTime = Object.keys(extendedData).length !== 0;

        if (!hasjointCreationTime) {
            const onlyDateString = signature.creationDateTime.substring(0, 10);
            return {
                fullName: signature.userFullName,
                date: onlyDateString,
                signatureLevel: signature.signatureLevel,
                isValid: signature.valid,
            };
        }

        return {
            fullName: signature.userFullName,
            date: extendedData.jointCreationTime,
            signatureLevel: signature.signatureLevel,
            isValid: signature.valid,
        };
    };

    dispatcherSignature = (dispatcher) => ({
        lastName: dispatcher.split(" ")[1],
        firstName: dispatcher.split(" ")[0],
    });

    render() {
        const { transaction, parentTransaction, usesJointAccount, isInTicket } = this.props;
        const { signatures } = transaction;

        const creatorSignature = rename(
            {
                userCreatorLastName: "lastName",
                userCreatorFirstName: "firstName",
                creationDateTime: "date",
            },
            usesJointAccount && parentTransaction ? parentTransaction : transaction,
        );
        creatorSignature.isValid = true;

        const modifyerSignature = rename(
            {
                userEditorLastName: "lastName",
                userEditorFirstName: "firstName",
                modificationDateTime: "date",
            },
            transaction,
        );
        modifyerSignature.isValid = true;

        return (
            <Box
                display="flex"
                column
                className="my-3"
                fullWidth
                {...(usesJointAccount && { border: "border-base-color", borderRadius: "md" })}>
                {!usesJointAccount && (
                    <Text
                        component="h4"
                        labelKey="forms.transaction.ticket.title"
                        className="data-title"
                        {...(!isInTicket && { className: "data-title" })}
                        {...(isInTicket && { color: "primary", size: "5", bold: true })}
                    />
                )}
                <Signatures
                    title="forms.transaction.joint.ticket.createdBy"
                    list={[creatorSignature]}
                    usesJointAccount={usesJointAccount}
                />

                <Signatures
                    title={
                        usesJointAccount
                            ? "forms.transaction.joint.ticket.authorizedBy"
                            : "forms.transaction.ticket.authorizedBy"
                    }
                    list={signatures.map(this.authorizerSignature)}
                    usesJointAccount={usesJointAccount}
                />

                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                    {this.hasDispatcher() && (
                        <Signatures
                            title="forms.transaction.ticket.dispatchedBy"
                            list={[creatorSignature]}
                            usesJointAccount={usesJointAccount}
                        />
                    )}
                </FeatureFlag>
            </Box>
        );
    }
}

export default FormSignatures;

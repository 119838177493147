import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { Collapse as BSCollapse, Row, Col } from "react-bootstrap";
import classNames from "classnames";

import * as i18n from "util/i18n";
import { actions } from "reducers/desktop";

class Filter extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    state = {
        open: false,
        selectedCurrency: null,
    };

    handleCollapse = () => {
        const { open } = this.state;

        this.setState({ open: !open });
    };

    handleCurrencyFilter = (currency) => {
        const { dispatch } = this.props;

        this.setState({
            selectedCurrency: currency,
        });
        dispatch(actions.loadCorporateGroupDesktopRequest({ currency }));
    };

    render() {
        const { open, selectedCurrency } = this.state;

        const currencyFilter = [
            { id: null, label: i18n.get("desktop.corporateGroup.clientData.filter.allCurrencies") },
            { id: "USD", label: "$" },
            { id: "EUR", label: "€" },
            { id: "CAD", label: "CAD" },
        ];

        return (
            <div>
                <ul className="tabs">
                    {currencyFilter.map(({ id, label }) => (
                        <li key={id} className="tab">
                            <button
                                onClick={() => {
                                    this.handleCurrencyFilter(id);
                                }}
                                type="button"
                                className={classNames("btn btn-tab", {
                                    "is-active": selectedCurrency === id,
                                })}>
                                {label}
                            </button>
                        </li>
                    ))}
                </ul>
                <BSCollapse in={open}>
                    <form action="">
                        <Row className="filters">
                            <Col className="col-12" md="2">
                                <div className="form-group">
                                    <span className="control-label" htmlFor="field">
                                        Client
                                    </span>
                                    <div className="input-group">
                                        <input className="form-control" type="text" id="field" />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" md="2">
                                <div className="form-group">
                                    <span className="control-label" htmlFor="field">
                                        Client
                                    </span>
                                    <div className="input-group">
                                        <input className="form-control" type="text" id="field" />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" md="2">
                                <div className="form-group">
                                    <span className="control-label" htmlFor="field">
                                        Client
                                    </span>
                                    <div className="input-group">
                                        <input className="form-control" type="text" id="field" />
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-12" md="2">
                                <span className="form-group">
                                    <span className="control-label" htmlFor="field">
                                        Client
                                    </span>
                                    <div className="input-group">
                                        <input className="form-control" type="text" id="field" />
                                    </div>
                                </span>
                            </Col>
                            <Col className="col-12" md="2">
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary">
                                        Filter
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </BSCollapse>
            </div>
        );
    }
}

export default connect()(Filter);

import { getDeviceVersionPlatform, isAndroidPlatform, isIOSPlatform, isMobileNativeFunc } from "util/device";

export const CAMERA_PERMISSION_GRANTED = "GRANTED";
export const CAMERA_PERMISSION_DENIED = "DENIED";

const validatePostNotificationAndroid = () =>
    new Promise((resolve) => {
        const permissions = window?.cordova?.plugins?.permissions;
        if (!permissions) {
            resolve(false);
            return;
        }

        permissions.hasPermission(permissions.POST_NOTIFICATIONS, (status) => {
            if (!status.hasPermission) {
                permissions.requestPermission(
                    permissions.POST_NOTIFICATIONS,
                    () => {
                        // eslint-disable-next-line no-console
                        console.log("Push post android permission is allowed");
                        resolve(true);
                    },
                    () => {
                        // eslint-disable-next-line no-console
                        console.log("Push post android permission is denied");
                        resolve(true);
                    },
                );
            }
        });
    });

export const validatePermissionPushIOS = () =>
    new Promise((resolve) => {
        if (!window.cordova.plugins.firebase.messaging.requestPermission) {
            resolve(false);
        } else {
            window.cordova.plugins.firebase.messaging.requestPermission({ forceShow: false }).then(
                () => {
                    // eslint-disable-next-line no-console
                    console.log("Push permission is allowed");
                    resolve(true);
                },
                (e) => {
                    // eslint-disable-next-line no-console
                    console.log("Push permission is denied", e);
                    resolve(false);
                },
            );
        }
    });

const validatePermissionsIOS = async () => {
    const responsePermission = await Promise.all([validatePermissionPushIOS()]);
    // eslint-disable-next-line no-console
    console.log("Response permission", responsePermission);
};

const validatePermissionsAndroid = async () => {
    const googlePlayData = await window.app.getAppPlayServices();
    if (
        googlePlayData?.code &&
        googlePlayData.code === "0000" &&
        googlePlayData?.data &&
        googlePlayData.data === "HMS"
    ) {
        return;
    }

    const androidVersion = getDeviceVersionPlatform();
    if (!androidVersion) {
        return;
    }

    if (parseInt(androidVersion, 10) >= 13) {
        const responsePostNotification = await validatePostNotificationAndroid();
        // eslint-disable-next-line no-console
        console.log(`Post notification permission: ${responsePostNotification || false}`);
    }
};

export const validatePermissionMobile = async () => {
    try {
        if (!isMobileNativeFunc()) {
            return;
        }

        if (isIOSPlatform()) {
            validatePermissionsIOS();
            return;
        }

        if (isAndroidPlatform()) {
            validatePermissionsAndroid();
        }

        // eslint-disable-next-line no-empty
    } catch (e) {}
};

const validateCameraPermissioniOS = (resolve) => {
    if (!window?.cordova?.plugins?.CameraPermissionPlugin?.validatePermission) {
        // eslint-disable-next-line no-console
        console.log("Camera permission plugin not available");
        resolve(CAMERA_PERMISSION_DENIED);
        return;
    }

    window.cordova.plugins.CameraPermissionPlugin.validatePermission((permission) => {
        resolve(permission || CAMERA_PERMISSION_DENIED);
    });
};

const validateCameraPermissionAndroid = (resolve) => {
    if (!window?.cordova?.plugins?.permissions?.requestPermission) {
        // eslint-disable-next-line no-console
        console.log("Camera permission plugin not available");
        resolve(CAMERA_PERMISSION_DENIED);
        return;
    }

    window.cordova.plugins.permissions.requestPermission(window.cordova.plugins.permissions.CAMERA, (result) => {
        const resultPermission =
            result?.hasPermission && result?.hasPermission === true
                ? CAMERA_PERMISSION_GRANTED
                : CAMERA_PERMISSION_DENIED;
        resolve(resultPermission);
    });
};

export const validateCameraPermission = () =>
    new Promise((resolve) => {
        try {
            if (!isMobileNativeFunc()) {
                resolve(CAMERA_PERMISSION_DENIED);
                return;
            }

            if (isIOSPlatform()) {
                validateCameraPermissioniOS(resolve);
                return;
            }

            if (isAndroidPlatform()) {
                validateCameraPermissionAndroid(resolve);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log("Error validate permission camera plugin", e);
            resolve(CAMERA_PERMISSION_DENIED);
        }
    });

import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import MessageModal from "pages/_components/modal/MessageModal";
import LeftPanel from "pages/communications/_components/LeftPanel";
import List from "pages/communications/_components/List";
import MessageHeader from "pages/communications/_components/MessageHeader";
import { arrayOf, bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { selectors as communicationSelectors } from "reducers/communication";
import { actions as communicationsActions, selectors as communicationsSelector } from "reducers/communications";

class CommunicationMain extends Component {
    state = {
        isComposing: false,
    };

    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        detail: oneOfType([shape({}), arrayOf(shape({}))]),
        isFetching: bool.isRequired,
    };

    static defaultProps = {
        detail: null,
    };

    constructor(...args) {
        super(...args);

        this.state = {
            loadMore: false,
            messageModalShow: false,
            modalShow: false,
            theMessageTitle: "",
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(communicationsActions.listTypesRequest());
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    }

    handleComposeClick = (isReply) => {
        const { isDesktop, dispatch } = this.props;

        if (isDesktop) {
            if (isReply) {
                dispatch(communicationsActions.showReplyPanel());
            } else {
                dispatch(communicationsActions.showComposePanel());
            }
        } else {
            this.setState({
                modalShow: true,
            });
        }
    };

    handleComposeCloseClick = () => {
        const { dispatch, detail } = this.props;
        if (detail) {
            dispatch(communicationsActions.showReadPanel());
        } else {
            dispatch(communicationsActions.hideAllPanels());
        }
    };

    handleBackClick = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    headerTitle = (title) => {
        this.setState({
            theMessageTitle: title,
        });
    };

    handleMessageModalShow = () => {
        this.setState({
            messageModalShow: true,
        });
    };

    handleMessageModalClose = () => {
        this.setState({
            messageModalShow: false,
        });
    };

    render() {
        const { isDesktop, isFetching } = this.props;
        const { isComposing, modalShow, loadMore, messageModalShow, theMessageTitle } = this.state;

        return (
            <>
                <Notification scopeToShow="communications" />
                {!isDesktop && (
                    <MessageModal
                        modalShow={modalShow}
                        onCloseClick={() => {
                            this.setState({
                                modalShow: false,
                            });
                        }}
                    />
                )}
                <Head
                    title="chat.title"
                    backLinkTo="/desktop"
                    isDesktop={isDesktop}
                    onBack={this.handleBackClick}
                    {...(isDesktop && { addButtonBigPadding: true })}
                    // MVP2
                    // {...(isDesktop
                    //     ? { onAdd: this.handleComposeClick, addLinkToLabel: "communications.compose.message.new" }
                    //     : {
                    //           isComposing: false,
                    //           actionButtonImage: "/images/icons/circlePlus.svg",
                    //           actionButtonImageMd: true,
                    //           actionButton: () => {
                    //               this.setState({
                    //                   modalShow: true,
                    //               });
                    //           },
                    //       })}
                />

                <MainContainer
                    showLoader={isFetching && !loadMore}
                    {...(isDesktop && { className: "content-height-no-header" })}>
                    {isDesktop ? (
                        <Row gapX="4" fullHeight>
                            <Col xs={12} md={6} className="scrollable-content pr-4 pb-10 pb-md-7">
                                <List
                                    isDesktop={isDesktop}
                                    handleComposeCloseClick={this.handleComposeCloseClick}
                                    handleLoadMoreState={() => {
                                        this.setState({
                                            loadMore: true,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6} className="scrollable-content">
                                <LeftPanel
                                    compose={isComposing}
                                    close={this.handleComposeCloseClick}
                                    reply={this.handleComposeClick}
                                    isDesktop={isDesktop}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <List
                                handleMessageModalShow={this.handleMessageModalShow}
                                headerTitle={this.headerTitle}
                                isDesktop={isDesktop}
                                handleLoadMoreState={() => {
                                    this.setState({
                                        loadMore: true,
                                    });
                                }}
                            />

                            <Modal show={messageModalShow} className="drawer">
                                <Box display="flex" background="menu-background" column fullHeight fullWidth>
                                    <MessageHeader title={theMessageTitle} onClose={this.handleMessageModalClose} />
                                    <Box display="flex" fullHeight fullWidth className="px-5 scroll">
                                        <LeftPanel
                                            compose={isComposing}
                                            close={this.handleComposeCloseClick}
                                            reply={this.handleComposeClick}
                                            isDesktop={isDesktop}
                                            handleMessageModalClose={this.handleMessageModalClose}
                                        />
                                    </Box>
                                </Box>
                            </Modal>
                        </>
                    )}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    types: communicationsSelector.listTypes(state),
    detail: communicationSelectors.detail(state),
    isFetching: communicationsSelector.isFetching(state),
});

export default connect(mapStateToProps)(CommunicationMain);

import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import TextField from "pages/_components/fields/TextField";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as i18n from "util/i18n";
import { TRANSFER_SUPPORT_AMENDMENT_TYPE } from "util/transferSupport.util";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import * as configUtils from "util/config";
import {
    actions as supportTransferActions,
    selectors as supportTransferSelectors,
    actions as transferSupportActions,
} from "reducers/transferSupport/transferSupport.reducer";
import { COUNTRY_BENEFICIARY_MX } from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import { selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import useCustomLocationSetter from "hooks/useLocationSetter";

const FORM_ID = "transfer.foreign.tracking.modal.drawer";
const TRANSFER_SUPPORT_TYPE_BENEFICIARY_LIST = [TRANSFER_SUPPORT_AMENDMENT_TYPE];
const DEFAULT_MAX_LENGTH = 35;

const InternationalTransferTrackingModal = ({
    handleCloseModal,
    isDesktop,
    isFetching,
    showModal,
    transferSupportType,
    transferSupportData,
    dispatch,
    callbackSuccess,
    callbackError,
    scopeError,
    showWarningModal,
    showWarningModalTransferSupport,
    currentRevelockPosition,
}) => {
    useCustomLocationSetter(showModal, currentRevelockPosition, FORM_ID);

    const handleSubmit = (values) => {
        const { beneficiaryName, beneficiaryAccount, beneficiaryDescription } = values || {};
        const beneficiaryData = {
            name: beneficiaryName,
            account: beneficiaryAccount,
            description: beneficiaryDescription,
        };

        dispatch(
            supportTransferActions.sendTransferSupport(
                transferSupportType,
                transferSupportData.transactionId,
                beneficiaryData,
                transferSupportData.accountId,
                callbackSuccess,
                callbackError,
                scopeError,
            ),
        );
    };

    const accountMaxLength = useMemo(() => {
        if (!transferSupportData?.originalData?.beneficiary) {
            return DEFAULT_MAX_LENGTH;
        }
        let accountMaxLengthRef =
            configUtils.getInteger("forms.transfers.foreign.field.account.length.generic.max") || 34;

        if (transferSupportData?.originalData?.beneficiary?.country?.id === COUNTRY_BENEFICIARY_MX) {
            accountMaxLengthRef = configUtils.getInteger("forms.transfers.foreign.field.account.length.mx.max") || 18;
        }
        return accountMaxLengthRef;
    }, [transferSupportData, transferSupportType]);

    const hasBeneficiaryForm = useMemo(() => TRANSFER_SUPPORT_TYPE_BENEFICIARY_LIST.includes(transferSupportType), [
        transferSupportType,
    ]);

    const commissionValue = useMemo(() => {
        try {
            const commissionRef = configUtils.get(
                `transfers.foreign.support.type.${(transferSupportType || "").toLowerCase()}.valueCommission`,
            );
            const percentageCommissionRef = configUtils.get(
                `transfers.foreign.support.type.${(transferSupportType || "").toLowerCase()}.percentageCommission`,
            );
            if (!commissionRef || commissionRef === "" || !percentageCommissionRef) {
                return "-";
            }

            const percentage = Number(percentageCommissionRef);
            let commissionVal = Number(commissionRef);
            commissionVal += commissionVal * (percentage / 100);
            commissionVal = commissionVal.toFixed(2);
            return commissionVal;
        } catch (e) {
            return "-";
        }
    }, [transferSupportType]);

    const validateBeneficiaryName = (errors, values) => {
        const errorsRef = { ...errors };

        const isInValidCharacteres = values?.beneficiaryName
            .trim()
            .split(" ")
            .some((item) => item.length < 2);
        if (isInValidCharacteres) {
            errorsRef.beneficiaryName = i18n.get(`forms.transfers.foreign.beneficiary.name.pattern.message`);
            return errorsRef;
        }
        const isInValidLength = values?.beneficiaryName.split(" ").length < 3;
        if (isInValidLength) {
            errorsRef.beneficiaryName = i18n.get(`forms.transfers.foreign.beneficiary.name.pattern.message`);
            return errorsRef;
        }

        return errorsRef;
    };

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={() => {
                handleCloseModal();
            }}
            show={showModal}
            className={classNames({ drawer: true })}>
            <div className="modal-container">
                <Modal.Header closeButton className="mb-7">
                    <Box display="flex" alignX="center" column>
                        <Text
                            id="modalTitleID"
                            component="h4"
                            labelKey={`transfers.foreign.support.type.${(
                                transferSupportType || ""
                            ).toLowerCase()}.title`}
                            className="mt-0 mb-3"
                            size="3"
                            color="heading-color"
                            bold
                            align="center"
                        />
                        <Text
                            component="h5"
                            labelKey={`transfers.foreign.support.type.${(
                                transferSupportType || ""
                            ).toLowerCase()}.subtitle`}
                            color="primary"
                            className="m-0"
                            align="center"
                            regular
                            size="5"
                        />
                    </Box>
                </Modal.Header>
                <Modal.Body>
                    {showWarningModal && showWarningModalTransferSupport && (
                        <Box
                            display="flex"
                            column
                            alignX="between"
                            alignY="between"
                            className="scrollable-content px-0 px-md-9"
                            fullWidth
                            fullHeight>
                            <Box display="flex" className="pb-9 pb-md-12" column>
                                <Text
                                    component="h5"
                                    labelKey={`transfers.foreign.support.modal.text.${(
                                        transferSupportType || ""
                                    ).toLowerCase()}`}
                                    className="m-0"
                                    align="center"
                                    regular
                                    size="5"
                                />
                            </Box>
                            <Box display="flex" className="pb-9 pb-md-12" column>
                                <Button
                                    block
                                    label="transfers.foreign.support.request.submit.button"
                                    bsStyle="primary"
                                    onClick={() => {
                                        dispatch(transferSupportActions.setShowSupportTransferWarningModal(false));
                                    }}
                                />
                            </Box>
                        </Box>
                    )}

                    {!(showWarningModal && showWarningModalTransferSupport) && (
                        <Box
                            display="flex"
                            column
                            alignX="between"
                            alignY="between"
                            className="scrollable-content px-0 px-md-9"
                            fullWidth
                            fullHeight>
                            <Formik
                                {...(hasBeneficiaryForm && {
                                    initialValues: {
                                        beneficiaryName: "",
                                        beneficiaryAccount: "",
                                        beneficiaryDescription: "",
                                    },
                                })}
                                {...(hasBeneficiaryForm && {
                                    validate: (values) => {
                                        let errors = {};
                                        const requiredMessage = i18n.get(DEFAULT_REQUIRED);
                                        const { beneficiaryName, beneficiaryAccount, beneficiaryDescription } =
                                            values || {};

                                        if (!beneficiaryName || beneficiaryName === "") {
                                            errors.beneficiaryName = requiredMessage;
                                        }
                                        if (!beneficiaryAccount || beneficiaryAccount === "") {
                                            errors.beneficiaryAccount = requiredMessage;
                                        }
                                        if (!beneficiaryDescription || beneficiaryDescription === "") {
                                            errors.beneficiaryDescription = requiredMessage;
                                        }

                                        errors = validateBeneficiaryName(errors, values);
                                        return errors;
                                    },
                                })}
                                onSubmit={handleSubmit}>
                                <Form className="full-height">
                                    <Box display="flex" column alignX="between" className="mb-auto" fullHeight>
                                        {hasBeneficiaryForm && (
                                            <Box display="flex" column className="mb-3 mb-md-7">
                                                <Field
                                                    idForm={FORM_ID}
                                                    component={TextField}
                                                    name="beneficiaryName"
                                                    placeholder=""
                                                    labelNoMarginTop
                                                    pattern="^[a-zA-Z0-9 ]*$"
                                                    maxLength={configUtils.getInteger(
                                                        "transfers.foreign.support.form.beneficiary.name.maxLength",
                                                        70,
                                                    )}
                                                    className="mb-5"
                                                />
                                                <Field
                                                    idForm={FORM_ID}
                                                    component={TextField}
                                                    name="beneficiaryAccount"
                                                    placeholder=""
                                                    labelNoMarginTop
                                                    pattern="^[a-zA-Z0-9]*$"
                                                    className="mb-5"
                                                    maxLength={accountMaxLength || DEFAULT_MAX_LENGTH}
                                                />
                                                <Field
                                                    idForm={FORM_ID}
                                                    component={TextField}
                                                    name="beneficiaryDescription"
                                                    placeholder=""
                                                    labelNoMarginTop
                                                    maxLength={configUtils.getInteger(
                                                        "transfers.foreign.support.form.beneficiary.description.maxLength",
                                                        140,
                                                    )}
                                                    pattern="^[a-zA-Z0-9 ]*$"
                                                />
                                                <Disclaimer
                                                    alignY="center"
                                                    className="pt-3 pr-0 mt-6"
                                                    padding="p-0"
                                                    labelKey="transfers.foreign.support.request.data.disclaimer"
                                                    textSize="small"
                                                    textColor="info-color"
                                                />
                                            </Box>
                                        )}
                                        <Box
                                            display="flex"
                                            alignX="center"
                                            column
                                            gapY="3"
                                            className="mb-3 mb-md-7"
                                            fullHeight>
                                            <Box
                                                background="menu-background"
                                                className="py-3 px-5"
                                                borderRadius="default">
                                                <Box
                                                    display="flex"
                                                    alignX="between"
                                                    className="py-md-2 mb-2 mb-md-0"
                                                    gap="5">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        regular
                                                        labelKey="transfers.foreign.support.request.amount.detail"
                                                        size="6"
                                                        color="heading-color"
                                                    />
                                                    {transferSupportData?.amount && transferSupportData?.currency ? (
                                                        <FormattedAmount
                                                            quantity={transferSupportData.amount}
                                                            currency={transferSupportData.currency}
                                                            size="6"
                                                            color="heading-color"
                                                        />
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    alignX="between"
                                                    className="py-md-2 mb-2 mb-md-0"
                                                    gap="5">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        regular
                                                        labelKey="transfers.foreign.support.request.date.detail"
                                                        size="6"
                                                        color="heading-color"
                                                    />
                                                    {transferSupportData?.date ? (
                                                        <FormattedDate
                                                            date={transferSupportData.date}
                                                            anotherFormat="DD/MM/YYYY"
                                                            size="6"
                                                            color="heading-color"
                                                        />
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    alignX="between"
                                                    className="py-md-2 mb-2 mb-md-0"
                                                    gap="5">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        regular
                                                        size="6"
                                                        labelKey="transfers.foreign.support.request.reference.detail"
                                                        color="heading-color"
                                                    />
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        regular
                                                        size="6"
                                                        color="heading-color"
                                                        uppercase
                                                        ellipsis
                                                        align="right">
                                                        {transferSupportData?.tracking || "-"}
                                                    </Text>
                                                </Box>
                                                <Box display="flex" alignX="between" className="py-md-2" gap="5">
                                                    <Box display="flex" flex1>
                                                        <Text
                                                            component="label"
                                                            className="m-0"
                                                            regular
                                                            size="6"
                                                            labelKey="transfers.foreign.support.request.account.detail"
                                                            color="heading-color"
                                                        />
                                                    </Box>
                                                    <Box display="flex" alignX="flex-end" flex1>
                                                        <Box className="d-grid">
                                                            <Text
                                                                component="label"
                                                                className="m-0"
                                                                regular
                                                                size="6"
                                                                color="heading-color"
                                                                ellipsis>
                                                                {transferSupportData?.accountLabel || "-"}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box
                                                background="menu-background"
                                                className="py-3 px-5"
                                                borderRadius="default">
                                                <Box display="flex" alignX="between" className="py-3 py-md-2" gap="5">
                                                    <Text
                                                        component="label"
                                                        className="m-0"
                                                        regular
                                                        size="6"
                                                        labelKey={`transfers.foreign.support.type.${(
                                                            transferSupportType || ""
                                                        ).toLowerCase()}.labelComission`}
                                                        color="heading-color"
                                                    />
                                                    <Box display="flex">
                                                        <FormattedAmount
                                                            quantity={commissionValue}
                                                            currency="USD"
                                                            size={isDesktop ? "6" : "5"}
                                                            color="heading-color"
                                                            bold
                                                        />
                                                        <Tooltip
                                                            className="cursor-pointer pl-2 pr-0 mr-0"
                                                            position="bottom-right"
                                                            text="transfers.foreign.support.request.commision.tip"
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box display="flex" className="pb-9 pb-md-12" column>
                                            <Disclaimer
                                                alignY="flex-start"
                                                className="pt-3 pr-0 mb-5"
                                                padding="p-0"
                                                labelKey="transfers.foreign.support.request.schedule.disclaimer"
                                                textSize="small"
                                                textColor="info-color"
                                            />
                                            <Button
                                                block
                                                type="submit"
                                                label="transfers.foreign.support.request.submit.button"
                                                bsStyle="primary"
                                                loading={isFetching}
                                                disabled={false}
                                            />
                                        </Box>
                                    </Box>
                                </Form>
                            </Formik>
                        </Box>
                    )}
                </Modal.Body>
            </div>
        </Modal>
    );
};

InternationalTransferTrackingModal.propTypes = {
    handleCloseModal: func,
    isDesktop: bool,
    isFetching: bool,
    showModal: bool.isRequired,
    transferSupportType: string.isRequired,
    transferSupportData: shape({}).isRequired,
    dispatch: func.isRequired,
    callbackSuccess: func,
    callbackError: func,
    scopeError: arrayOf(string),
    showWarningModal: bool.isRequired,
    showWarningModalTransferSupport: bool.isRequired,
    currentRevelockPosition: string.isRequired,
};

InternationalTransferTrackingModal.defaultProps = {
    handleCloseModal: () => {},
    isDesktop: false,
    isFetching: false,
    callbackSuccess: () => {},
    callbackError: () => {},
    scopeError: undefined,
};

const mapStateToProps = (state) => ({
    isFetching: supportTransferSelectors.isFetchingSend(state),
    showWarningModalTransferSupport: supportTransferSelectors.isShowingWarningModal(state),
    currentRevelockPosition: revelockSelectors.getPosition(state),
});

export default connect(mapStateToProps)(resizableRoute(InternationalTransferTrackingModal));

import React from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { bool, shape } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { actions as recoveryPasswordActions, selectors } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";
import * as utilIdentification from "util/recoveryUser";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Text from "pages/_components/Text";
import Document from "pages/_components/fields/Document";

const FORM_ID = "recoveryPassword.step2";

const Step2Content = (props) => {
    const { isSubmitting, isMobile, preData } = props;

    return (
        <Form className="full-width">
            <Box display="flex" column fullWidth {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                <Box display="flex" className="pb-7">
                    <Text
                        labelKey="recoveryPassword.step2.title"
                        align="center"
                        size={`${isMobile ? "5" : "6"}`}
                        component="p"
                        className="mt-0 mb-5"
                        color="text"
                    />
                </Box>
                <Box display="flex" column className="my-auto pb-7">
                    <Field
                        countries={preData.countryList}
                        documentCountryMap={preData.documentTypeList}
                        idNumberMap={preData.idNumberMap1}
                        idNumber2Map={preData.idNumberMap2}
                        component={Document}
                        name="documentUser"
                        reducedColumnGap
                        {...(isMobile && { idNumberStyle2: true })}
                    />
                </Box>
                <Box display="flex" className="mb-7 mb-md-0 mt-auto">
                    <Button label="global.continue" type="submit" bsStyle="primary" loading={isSubmitting} block />
                </Box>
            </Box>
        </Form>
    );
};

Step2Content.propTypes = {
    isSubmitting: bool,
    isMobile: bool.isRequired,
    preData: shape({}),
};

Step2Content.defaultProps = {
    isSubmitting: false,
    preData: null,
};

const mapStateToProps = (state) => ({
    preData: selectors.getPreData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            documentUser: {
                documentCountry: "",
                documentType: "",
                documentNumber1: "",
                documentNumber2: "",
                idNumber1: "",
                idNumber2: "",
            },
        }),
        validationSchema: () =>
            Yup.object().shape({
                documentUser: Yup.object().shape({
                    documentCountry: Yup.string().required(i18n.get(`${FORM_ID}.documentCountry.required`)),
                    documentType: Yup.string().required(i18n.get(`${FORM_ID}.documentType.required`)),
                    documentNumber1: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                }),
            }),
        handleSubmit: ({ documentUser }, formikBag) => {
            const { documentCountry, documentType } = documentUser;
            const documentNumber = utilIdentification.generateIdentificationToSend(documentUser);
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep2(documentCountry, documentType, documentNumber, formikBag),
            );
        },
    }),
)(Step2Content);

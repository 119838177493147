import * as communicationMiddleware from "middleware/communication";
import * as fileMiddleware from "middleware/file";
import { actions, selectors, types } from "reducers/communication";
import { actions as communicationsActions, selectors as communicationsSelectors } from "reducers/communications";
import { actions as notificationActions } from "reducers/notification";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { isMobileNativeFunc } from "util/device";
import { download, downloadMobileFile } from "util/download";
import * as i18n from "util/i18n";
import b64toBlob from "b64-to-blob";

const sagas = [
    takeLatest([types.DETAIL_REQUEST], detailRequest),
    takeLatest([types.DELETE_REQUEST], deleteRequest),
    takeLatest([types.SEND_REQUEST], sendRequest),
    takeLatest([types.DOWNLOAD_ATTACHMENT_REQUEST], downloadAttachment),
    takeLatest([types.MARK_AS_READ_REQUEST], markAsReadRequest),
    takeLatest([types.MARK_AS_UNREAD_REQUEST], markAsUnReadRequest),
    takeLatest([types.REPLY_REQUEST], replyRequest),
    takeLatest([types.SELECT_ATTACHMENT], selectAttachment),
];

export default sagas;

function* detailRequest({ idCommunication, index, userRead }) {
    const response = yield call(communicationMiddleware.detailRequest, { idCommunication });

    if (response.type === "W") {
        yield put(actions.detailFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { communicationThread, attachments } = response.data.data;
        yield put(actions.detailSuccess(communicationThread, attachments, index));
        if (!userRead) {
            yield markAsReadUnread(true);
        }
    }
}

function* deleteRequest({ idCommunication, index, userRead }) {
    const response = yield call(communicationMiddleware.deleteRequest, { idCommunication });

    if (response.type === "W") {
        yield put(actions.deleteFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.deleteSuccess());
        yield put(communicationsActions.removeFromTray(index));
        yield put(
            notificationActions.showNotification(i18n.get("communications.delete.success"), "success", [
                "communications",
            ]),
        );
        if (!userRead) {
            yield markAsReadUnread(true);
        }
    }
}

function* sendRequest({ idCommunicationTray, subject, body, files, isDesktop }) {
    try {
        const idFileList = yield uploadFiles(files, "communications.send");

        const response = yield call(
            communicationMiddleware.sendRequest,
            idCommunicationTray,
            subject,
            body,
            idFileList,
        );
        if (response.type === "W") {
            yield put(actions.sendFailure);
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                    "communications",
                ]),
            );
        } else {
            yield put(actions.sendSuccess());
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.sent"), "success", [
                    "communications",
                ]),
            );
            yield put(communicationsActions.listRequest({}));
            if (isDesktop) {
                yield put(communicationsActions.hideAllPanels());
            }
        }
    } catch (e) {
        yield put(actions.sendFailure);
        yield put(
            notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                "communications",
            ]),
        );
    }
}

function* replyRequest({ idCommunicationTray, subject, body, idCommunication, communicationPriority, files }) {
    try {
        const idFileList = yield uploadFiles(files, "communications.send");
        const response = yield call(
            communicationMiddleware.replyRequest,
            idCommunicationTray,
            subject,
            body,
            idFileList,
            idCommunication,
            communicationPriority,
        );

        if (response.type === "W") {
            yield put(actions.replyFailure());
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                    "communications",
                ]),
            );
        } else {
            yield put(actions.replySuccess());
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.sent"), "success", [
                    "communications",
                ]),
            );
            yield put(communicationsActions.listRequest({}));
            yield put(actions.detailRequest(idCommunication));
            yield put(communicationsActions.showReadPanel());
        }
    } catch (e) {
        yield put(actions.replyFailure());
        yield put(
            notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                "communications",
            ]),
        );
    }
}

function* uploadFiles(files, idActivity) {
    if (!files) {
        return [];
    }

    const responses = yield files.map((f) => call(fileMiddleware.upload, f.content, f.name, "", idActivity));
    const result = responses.filter((r) => r.type === "W");

    if (result.length > 0) {
        throw new Error("failed to upload filed");
    }

    return responses.map((r) => r.data.data.file.idFile);
}

function* downloadAttachment({ idFile, idCommunication, communicationType }) {
    const response = yield call(fileMiddleware.download, idFile, idCommunication);

    if (response.type === "W") {
        yield put(actions.sendFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { fileName, content, contentType } = response.data.data;

        if (isMobileNativeFunc() && content && contentType) {
            const fileBlob = b64toBlob(content, contentType);
            downloadMobileFile(fileBlob, fileName || "default.jpeg", contentType);
        } else {
            download(fileName, content);
        }

        if (communicationType === "accountFileDownload") {
            yield put(actions.deleteSuccess());
            yield put(communicationsActions.removeByIdCommunication(idCommunication));
            yield put(
                notificationActions.showNotification(i18n.get("communications.delete.success"), "success", [
                    "communications",
                ]),
            );
        }

        yield put(actions.downloadAttachmentSuccess());
    }
}

function* markAsReadRequest({ idCommunication }) {
    const response = yield call(communicationMiddleware.markAsReadRequest, idCommunication);

    if (response.type === "W") {
        yield put(actions.markAsReadFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield markAsReadUnread(true);
        yield put(actions.markAsReadSuccess());
    }
}

function* markAsUnReadRequest({ idCommunication }) {
    const response = yield call(communicationMiddleware.markAsUnReadRequest, idCommunication);

    if (response.type === "W") {
        yield put(actions.markAsReadFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield markAsReadUnread(false);
        yield put(actions.markAsUnReadSuccess());
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
    });
};

function* selectAttachment({ file }) {
    const selectedAttachmentsResult = yield select(selectors.selectedAttachments);
    const exitingFiles = selectedAttachmentsResult.filter((attachment) => attachment.name === file.name);

    if (exitingFiles.length > 0) {
        yield put(
            notificationActions.showNotification(i18n.get("communications.attachment.selected"), "error", [
                "communications",
            ]),
        );
    } else {
        const result = yield readFileAsDataUrl(file);
        yield put(actions.setAttachment({ name: file.name, size: file.size, content: result.split(",")[1] }));
    }
}

function* markAsReadUnread(read) {
    const currentUnreadCommunicationsIds = yield select(communicationsSelectors.getUnreadCommunicationsIds);
    const currentUnreadCommunications = yield select(communicationsSelectors.getUnreadCommunications);

    if (read) {
        yield put(
            communicationsActions.setLastestCommunications(
                currentUnreadCommunications > 0 ? currentUnreadCommunications - 1 : 0,
                currentUnreadCommunicationsIds,
            ),
        );
    } else {
        yield put(
            communicationsActions.setLastestCommunications(
                currentUnreadCommunications + 1,
                currentUnreadCommunicationsIds,
            ),
        );
    }
}

import classNames from "classnames";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { bool, node, string } from "prop-types";
import React from "react";

const GridTableBody = React.forwardRef(({ children, className, loading, ...props }, ref) => {
    const childrenArray = React.Children.toArray(children);
    const modifiedChildren = childrenArray.map((child, i) => {
        if (React.isValidElement(child) && i === childrenArray.length - 1) {
            return React.cloneElement(child, { isLastElement: true });
        }
        return child;
    });

    return (
        <tbody ref={ref} className={classNames("grid-table-body", className)} {...props}>
            {loading ? (
                <Box display="flex" alignX="center" alignY="center" className="min-height-10rem" fullWidth fullHeight>
                    <PageLoading loading={loading} />
                </Box>
            ) : (
                modifiedChildren
            )}
        </tbody>
    );
});

GridTableBody.propTypes = {
    children: node.isRequired,
    className: string,
    loading: bool,
};

GridTableBody.defaultProps = {
    className: null,
    loading: false,
};

export default GridTableBody;

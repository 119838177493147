import { call, put, takeLatest } from "redux-saga/effects";
import { routerActions, push } from "react-router-redux";
import { types as restrictionTypes, actions as restrictionActions } from "reducers/administration/restrictions";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as dateUtils from "util/date";
import { adjustIdFieldErrors, credentialsToUnderscoreFormat } from "util/form.js";
import * as form from "middleware/form";
import * as restrictionsMiddleware from "middleware/administration/restrictions";

const sagas = [
    takeLatest(restrictionTypes.GET_RESTRICTIONS_REQ, getRestrictions),
    takeLatest(restrictionTypes.GET_TIME_ZONES_REQ, manageRestrictionsPre),
    takeLatest(restrictionTypes.GET_USER_RESTRICTION_DATA, getUserRestrictionsData),
    takeLatest(restrictionTypes.GET_ENV_RESTRICTION_DATA, getEnvironmentRestrictionsData),
    takeLatest(restrictionTypes.SET_MANAGE_RESTRICTIONS_REQ, manageRestrictions),
    takeLatest(restrictionTypes.RESTRICTION_DELETE_PRE_REQUEST, restrictionsDeletePre),
    takeLatest(restrictionTypes.RESTRICTION_DELETE_REQUEST, restrictionsDeleteRequest),
    takeLatest(restrictionTypes.MANAGE_RESTRICTIONS_CONFIRM_PRE, manageRestrictionsConfirmPre),
    takeLatest(restrictionTypes.GET_RESTRICTIONS_AVAILAVILITY_REQ, getRestrictionsAvailability),
];

export default sagas;

function* getRestrictionsAvailability() {
    const response = yield call(restrictionsMiddleware.getRestrictionsAvailability);

    if (response) {
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["restrictions"]),
            );
        } else {
            const { calendarEnabled, iPEnabled } = response.data.data;

            yield put({
                type: restrictionTypes.GET_RESTRICTIONS_AVAILAVILITY_RES,
                calendarEnabled,
                iPEnabled,
            });
        }
    }
}

function* getUserRestrictionsData(params) {
    const response = yield call(restrictionsMiddleware.getRestrictions, params);

    if (response) {
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["restrictions"]),
            );
        } else {
            const { restrictions, userName } = response.data.data;
            const calendarUserData = restrictions.filter((i) => i.type === "Calendar" && i.idUser === params.idUser);
            const ipsList = restrictions
                .filter((i) => i.type === "IP" && i.idUser === params.idUser && i.ip !== "ALL")
                .map((i) => i.ip);
            const anyIP =
                restrictions.filter((i) => i.type === "IP" && i.idUser === params.idUser && i.ip === "ALL").length > 0;
            let data = {
                ipsList,
                anyIP,
            };
            if (calendarUserData.length > 0) {
                data = {
                    ...data,
                    ...calendarUserData[0],
                };
            }
            yield put({
                type: restrictionTypes.SET_RESTRICTION_DATA,
                data,
                userName,
            });
        }
    }
}

function* getEnvironmentRestrictionsData() {
    const response = yield call(restrictionsMiddleware.getRestrictions);

    if (response) {
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["restrictions"]),
            );
        } else {
            const { restrictions, userName } = response.data.data;
            const calendarData = restrictions.filter((i) => i.type === "Calendar");
            const ipsList = restrictions.filter((i) => i.type === "IP" && i.ip !== "ALL").map((i) => i.ip);
            const anyIP = restrictions.filter((i) => i.type === "IP" && i.ip === "ALL").length > 0;
            let data = {
                ipsList,
                anyIP,
            };
            if (calendarData.length > 0) {
                data = {
                    ...data,
                    ...calendarData[0],
                };
            }
            yield put({
                type: restrictionTypes.SET_RESTRICTION_DATA,
                data,
                userName,
            });
        }
    }
}

function* getRestrictions(params) {
    const response = yield call(restrictionsMiddleware.getRestrictions, params);

    if (response) {
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["restrictions"]),
            );
        } else {
            const { restrictions, userName } = response.data.data;

            yield put({
                type: restrictionTypes.GET_RESTRICTIONS_RES,
                restrictions,
                userName,
            });
        }
    }
}

function* restrictionsDeletePre() {
    const responseCredentials = yield call(
        form.listCredentialsGroups,
        null,
        "administration.restrictions.user.delete.send",
    );
    const credentialGroups = responseCredentials.data.data.groups;

    yield put({
        type: restrictionTypes.SET_CREDENTIALS_GROUP,
        credentialGroups,
    });
}

function* restrictionsDeleteRequest({ idUser, credentialGroups, formikBag }) {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentialGroups);
    const params = { idUser, ...credentialsWithUnderscore };

    const { setSubmitting, setErrors } = formikBag;
    setSubmitting(false);
    const response = yield call(restrictionsMiddleware.restrictionsDeleteRequest, params);
    if (response.type === "W") {
        setErrors(response.data.data);
        const errorMessage = response.data.message || i18n.get("global.unexpectedError");
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("administration.restrictions.userDelete.snackbar.success"),
                "success",
                ["administration"],
            ),
        );
        yield put(routerActions.goBack());
    }
    yield put({
        type: restrictionTypes.SET_FETCHING_FALSE,
    });
}

function* manageRestrictionsPre() {
    const response = yield call(restrictionsMiddleware.manageRestrictionsPre);

    if (response) {
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["restrictions"]),
            );
        } else {
            yield put({
                type: restrictionTypes.GET_TIME_ZONES_RES,
                timeZones: response.data.data.timeZones,
            });
        }
    }
}

function* manageRestrictionsConfirmPre({ dataToSave, formikBag, idTransaction }) {
    const responseCredentials = yield call(form.listCredentialsGroups, null, "administration.restrictions.manage.send");
    const credentialGroups = responseCredentials.data.data.groups;

    yield put({
        type: restrictionTypes.SET_CREDENTIALS_GROUP,
        credentialGroups,
    });
    yield put({
        type: restrictionTypes.MANAGE_RESTRICTIONS_SET_DATA_TO_SAVE,
        dataToSave,
        mode: "editing",
    });

    if (!idTransaction) {
        const params = {
            ...dataToSave,
            startTime: dateUtils.stringTimeToMinutes(dataToSave.startTime),
            endTime: dateUtils.stringTimeToMinutes(dataToSave.endTime),
        };

        const response = yield call(restrictionsMiddleware.manageRestrictionsPreview, params);

        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield put({
                type: restrictionTypes.MANAGE_RESTRICTIONS_CONFIRM_PRE_FALIURE,
            });
        } else {
            yield put(push(`/administration/restrictions/manage/confirmation`));
        }
    }
}

function* manageRestrictions({ dataToSave, credentials, formikBag }) {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const { setSubmitting, setErrors } = formikBag;
    const params = {
        ...dataToSave,
        ...credentialsWithUnderscore,
        startTime: dateUtils.stringTimeToMinutes(dataToSave.startTime),
        endTime: dateUtils.stringTimeToMinutes(dataToSave.endTime),
    };
    setSubmitting(false);
    const response = yield call(restrictionsMiddleware.manageRestrictions, params);
    if (response) {
        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                setErrors(adjustIdFieldErrors(response.data.data));
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["restrictions"]),
                );
            }
        } else {
            let notScope = "administration";
            let administrationType = "environment";
            if (typeof dataToSave.idUser === "undefined") {
                notScope = "restrictions";
            } else {
                administrationType = "user";
            }
            const messageSnackbar = i18n.get(
                `administration.restrictions.${administrationType}${dataToSave.actionRestrinction}.snackbar.success`,
            );
            yield put(notificationActions.showNotification(messageSnackbar, "success", [notScope]));
            yield put({
                type: restrictionTypes.SET_MANAGE_RESTRICTIONS_RES,
            });
            yield put(restrictionActions.getUserRestrictionsData(dataToSave.idUser));
            if (dataToSave.idUser) {
                yield put(routerActions.go(-2));
            } else {
                yield put(routerActions.goBack());
            }
        }
    }
    yield put({
        type: restrictionTypes.SET_FETCHING_FALSE,
    });
}

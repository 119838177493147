import classNames from "classnames";
import { bool, func } from "prop-types";
import React from "react";
import { actions as desktopActions, selectors as desktopSelectors } from "reducers/desktop";
import { connect } from "react-redux";

class ToggleSidebar extends React.Component {
    static propTypes = {
        isSidebarCollapsed: bool.isRequired,
        dispatch: func.isRequired,
    };

    handleToggleSidebar = () => {
        const { isSidebarCollapsed, dispatch } = this.props;
        dispatch(desktopActions.changeStatusSidebarCollapsed(!isSidebarCollapsed));
        dispatch(desktopActions.toggleMenuPressed(true));
    };

    render() {
        const { isSidebarCollapsed } = this.props;
        return (
            <button
                id="toggleSidebar"
                type="button"
                className={classNames("navbar-toggle", {
                    collapsed: isSidebarCollapsed,
                })}
                onClick={this.handleToggleSidebar}>
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    isSidebarCollapsed: desktopSelectors.isSidebarCollapsed(state),
});

export default connect(mapStateToProps)(ToggleSidebar);

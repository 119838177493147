import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { ERROR_IMAGE_BY_CODE_MAP } from "./Error";

class ServerError extends Component {
    static propTypes = {
        hasActiveSession: bool,
        timesConfigFailed: number,
        timesI18nFailed: number,
        lang: string,
        isDesktop: bool.isRequired,
        location: shape({}),
    };

    static defaultProps = {
        hasActiveSession: false,
        timesConfigFailed: 0,
        lang: "es",
        timesI18nFailed: 0,
        location: {},
    };

    componentDidMount() {
        document.body.style.backgroundColor = "white";
    }

    getContent = () => {
        const { lang, isDesktop, location } = this.props;

        // Se realiza la traducción acá ya que puede estar caida la traducción
        const errorTitle = {
            es: "¡Lo sentimos!",
            en: "We are sorry!",
        };

        const errorDescription = {
            es:
                "En este momento estamos presentando intermitencia en nuestro sistema y no podemos mostrar tu consulta.",
            en: "At this moment we are presenting intermittence in our system and we cannot show your query.",
        };

        return (
            <Box display="flex" column alignX="center" alignY="center" className="pt-md-0 white-floor" fullHeight>
                <Box display="flex" column alignX="center" alignY="center" fullHeight zIndex="1">
                    <Text
                        component="h1"
                        align="center"
                        color="heading"
                        size={isDesktop ? "big" : "extra-big"}
                        bold
                        className="mt-0 mb-5">
                        {errorTitle[lang]}
                    </Text>
                    <Box display="flex" alignX="center" className="mb-3 mb-md-3 img-floor" fullWidth>
                        <Image
                            src={
                                ERROR_IMAGE_BY_CODE_MAP.get(location?.lastResponseCode) ||
                                "images/icons/errors/noConection.svg"
                            }
                        />
                    </Box>
                    <Row>
                        <Col xs={12} md={4} mdOffset={4}>
                            <Text
                                component="p"
                                align="center"
                                color="heading"
                                size={isDesktop ? "5" : "3"}
                                className="mt-0 mb-8 mb-md-9 px-6">
                                {location?.lastResponseMessage || errorDescription[lang]}
                            </Text>
                        </Col>
                    </Row>
                </Box>
            </Box>
        );
    };

    render() {
        const { hasActiveSession, timesConfigFailed, timesI18nFailed } = this.props;

        if (timesConfigFailed === 0 && timesI18nFailed === 0) {
            return hasActiveSession ? <Redirect to="/desktop" /> : <Redirect to="/" />;
        }

        return this.getContent();
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    timesConfigFailed: configSelectors.getTimesFailed(state),
    lang: i18nSelectors.getLang(state),
    timesI18nFailed: i18nSelectors.getTimesFailed(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(ServerError)));
